import { __, EventTrack, parsers } from 'common-services';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AllProductUnitsArray } from '../../../domain/product';
import { copyToClipboard } from '../../../util/utils';
import { RowContainer, Select } from '../../atoms';
import InputWithLabel from '../InputWithLabel';

import FileDropzone from './FileDropzone.component';
import FormatSelectedCard from './FormatSelectedCard.component';
import * as S from './OrderMagicImport.styled';
import TextArea from './TextArea.component';

import type { IUploadOrdersProps } from './TypesAndConstants';
import type { IReduxState } from '../../../reducers';
import type { PRODUCT_UNIT } from 'common-services';

const UploadOrders: React.FC<IUploadOrdersProps> = ({
  client,
  customConf,
  files,
  formats,
  formatSelected,
  importOrders,
  disabled,
  setParentState,
  parentState,
  setFiles,
  text,
}) => {
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const catalogs = useSelector((state: IReduxState) => state.catalog.catalogs);
  const catalogIdSelected = catalogs[workspaceId as unknown as number].id;
  const [mailCopied, setMailCopied] = React.useState(false);
  const orderFormatSelected = formatSelected ? formats.find(of => of.slug === formatSelected) : undefined;
  if (!orderFormatSelected) return null;
  const isTextFormat = orderFormatSelected.accepted_doc_types.includes('.txt');
  return (
    <>
      <FormatSelectedCard
        formats={formats}
        formatSelected={formatSelected}
        setParentState={setParentState}
        parentState={parentState}
      />
      {formatSelected === 'custom' ? (
        <S.CustomContainer>
          <S.TextSubtitle>{__('MagicOrders.customFormat.title')}</S.TextSubtitle>
          <S.TextLightGrey>{__('MagicOrders.customFormat.subtitle')}</S.TextLightGrey>
          <RowContainer margin="18px 0 0 0">
            <InputWithLabel isRequired={true} label={__('MagicOrders.customFormat.productCode')}>
              <Select
                name="productCode"
                value={customConf?.productCode ?? ''}
                options={Array.from(Array(26))
                  .map((e, i) => i + 65)
                  .map(x => String.fromCharCode(x))}
                onChange={(n, v) =>
                  setParentState({
                    customConf: { ...customConf, productCode: v },
                  })
                }
                containerMargin="4px 24px 4px 0"
                width="220px"
              />
            </InputWithLabel>
            <InputWithLabel isRequired={true} label={__('MagicOrders.customFormat.description')}>
              <Select
                name="description"
                value={customConf?.description ?? ''}
                options={Array.from(Array(26))
                  .map((e, i) => i + 65)
                  .map(x => String.fromCharCode(x))}
                onChange={(n, v) =>
                  setParentState({
                    customConf: { ...customConf, description: v },
                  })
                }
                containerMargin="4px 0px"
                width="220px"
              />
            </InputWithLabel>
          </RowContainer>
          <RowContainer>
            <InputWithLabel isRequired={true} label={__('MagicOrders.customFormat.quantity')}>
              <Select
                name="quantity"
                value={customConf?.quantity ?? ''}
                options={Array.from(Array(26))
                  .map((e, i) => i + 65)
                  .map(x => String.fromCharCode(x))}
                onChange={(n, v) => setParentState({ customConf: { ...customConf, quantity: v } })}
                containerMargin="4px 24px 4px 0"
                width="220px"
              />
            </InputWithLabel>
            <InputWithLabel isRequired={true} label={__('MagicOrders.customFormat.sellUnit')}>
              <Select
                name="sellUnit"
                value={customConf?.sellUnit ?? ''}
                options={AllProductUnitsArray.map(x => ({
                  value: x,
                  label: parsers.getUnitText(x, catalogs[catalogIdSelected].defaultWeightUnit),
                }))}
                onChange={(n, value: PRODUCT_UNIT) =>
                  setParentState({
                    customConf: { ...customConf, sellUnit: value },
                  })
                }
                containerMargin="4px 0px"
                width="220px"
              />
            </InputWithLabel>
          </RowContainer>
          <InputWithLabel isRequired={false} label={__('MagicOrders.customFormat.priceUnit')}>
            <Select
              name="priceUnit"
              value={customConf?.unitPrice ?? ''}
              options={Array.from(Array(26))
                .map((e, i) => i + 65)
                .map(x => String.fromCharCode(x))}
              onChange={(n, v) => setParentState({ customConf: { ...customConf, unitPrice: v } })}
              containerMargin="4px 0 0 0"
              width="220px"
            />
          </InputWithLabel>
        </S.CustomContainer>
      ) : null}
      <S.UploadOrdersContainer>
        <S.TextSubtitleWithMargin>
          {isTextFormat ? __('Components.OrdersImport.import_title') : __('Components.OrdersImport.upload_orders')}
        </S.TextSubtitleWithMargin>
        {isTextFormat ? (
          <S.FileContainer>
            <FileDropzone
              files={files}
              formats={formats}
              formatSelected={formatSelected}
              importOrders={importOrders}
              setFiles={setFiles}
              disabled={disabled}
            />
            <TextArea text={text} importOrders={importOrders} setParentState={setParentState} disabled={disabled} />
          </S.FileContainer>
        ) : (
          <S.FileContainer>
            <FileDropzone
              files={files}
              formats={formats}
              formatSelected={formatSelected}
              importOrders={importOrders}
              setFiles={setFiles}
              disabled={disabled}
            />
          </S.FileContainer>
        )}
      </S.UploadOrdersContainer>

      {formatSelected === 'custom' ? null : (
        <S.UploadOrdersContainer>
          <S.TextSubtitleEmail>
            <S.InfoMailIcon name="Info" disableHover={true} />
            {__('MagicOrders.email_title')}
          </S.TextSubtitleEmail>
          {__('MagicOrders.email_description')
            .split('\n')
            .map(s => (
              <S.TextLight key={s}>{s}</S.TextLight>
            ))}
          <S.TextEmail>
            {client?.orderIncomingEmail}
            {mailCopied ? (
              <S.ButtonCopy type="skip" iconName="Check" withoutPadding={true} iconSize="16px">
                {__('Components.Header.LinkCopied')}
              </S.ButtonCopy>
            ) : (
              <S.ButtonCopy
                withoutPadding={true}
                iconName="Clipboard"
                type="link"
                iconSize="14px"
                onClick={() => {
                  setMailCopied(true);
                  copyToClipboard(client.orderIncomingEmail);
                  EventTrack.track('import_orders_copy_email', {
                    contact_id: client?.userId,
                  });
                }}
              >
                {__('MagicOrders.copy')}
              </S.ButtonCopy>
            )}
          </S.TextEmail>
        </S.UploadOrdersContainer>
      )}
    </>
  );
};

export default UploadOrders;

import { __ } from 'common-services';
import * as React from 'react';

import * as S from './Switch.styled';

export interface IProps {
  className?: string;
  containerMargin?: string;
  dataSelector?: string;
  isChecked: boolean;
  isDisabled?: boolean;
  name?: string;
  onChange: (name: string, value: boolean) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  selectable?: boolean; // If true print a border of 2px when focused
  tabIndex?: number;
}

const Switch: React.FC<IProps> = ({
  className,
  containerMargin = '0',
  dataSelector,
  isChecked,
  isDisabled = false,
  name = '',
  onChange,
  onClick = () => null,
  onKeyDown,
  disabled = false,
  selectable = true,
  tabIndex,
}) => {
  const [activated, setActivated] = React.useState(isChecked);
  React.useEffect(() => {
    setActivated(isChecked);
  }, [isChecked, setActivated]);
  if (disabled) {
    const displayText = isChecked ? __('Components.Switch.yes') : __('Components.Switch.not');
    return <S.ReadOnly margin={containerMargin}>{displayText}</S.ReadOnly>;
  }

  return (
    <S.SwitchLabel margin={containerMargin} selectable={selectable} className={className} isDisabled={isDisabled}>
      <S.SwitchInput
        id={name}
        type="checkbox"
        checked={activated}
        onChange={e => {
          if (!isDisabled) handleChange(e.target.checked);
        }}
        onClick={isDisabled ? undefined : onClick}
        tabIndex={tabIndex}
        onKeyDown={isDisabled ? undefined : onKeyDown}
        data-selector={dataSelector}
      />
      <S.SwitchSlider isDisabled={isDisabled} />
    </S.SwitchLabel>
  );

  /**
   * On change the switch, change the internal state and call the onChange property.
   */
  function handleChange(checked: boolean): void {
    if (!disabled) {
      setActivated(!activated);
      onChange(name, checked);
    }
  }
};
export default React.memo(Switch);

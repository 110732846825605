import styled from '../../../../../styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const Text = styled.span`
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.5;
`;

export const TextLinkInline = styled.span`
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

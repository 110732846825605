import { IMessage, IProduct, parsers } from 'common-services';
import * as React from 'react';

import ProductCard from '../../../ProductCard';
import * as S from './ProductMessage.styled';

export interface IProps {
  message: IMessage;
  touchProduct: (ownerId: number, search?: string, productId?: number, productHash?: string) => void;
}

const ProductMessage: React.FC<IProps> = ({ message, touchProduct }) => {
  const product = message.extraData as IProduct;
  return (
    <S.Container
      className="Product-message-container"
      onClick={() => touchProduct(product.userId, undefined, product.id, product.hashId)}
    >
      <ProductCard
        onSelectItem={() => touchProduct(product.userId, undefined, product.id, product.hashId)}
        data={parsers.productToGenericProduct(product)}
        fromMessage={true}
        navigate={() => touchProduct(product.userId, undefined, product.id, product.hashId)}
        hideFeatured={true}
        showShare={false}
        orderItemId={0}
      />
    </S.Container>
  );
};

export default ProductMessage;

import * as Sentry from '@sentry/react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route as RouterRoute, Router, Switch } from 'react-router-dom';

import config from '../bindings/config';
import PrivateRoute from './components/atoms/PrivateRoute';
import { LoadingModal } from './components/molecules';
import WorkspaceCostImport from './components/organisms/ProductsImport/WorkspaceCostImport';
import WorkspaceOfferToOrderPreparation from './components/organisms/WorkspaceOfferToOrderPreparation';
import WorkspaceReferenceListDetail from './components/organisms/WorkspaceReferenceList/ReferenceDetail/WorkspaceReferenceListDetail';
import WorkspaceReferenceList from './components/organisms/WorkspaceReferenceList/WorkspaceReferenceList.component';
import { ROUTE_PATHS } from './constants';
import history from './history';
import Channels from './layout-channels';
import { IReduxState } from './reducers';
import * as S from './screens';

// Add sentry tracking to routes
const Route = Sentry.withSentryRouting(RouterRoute);
const Suspense = React.Suspense;

const Routes: React.FC = () => {
  const workspaceType = useSelector((state: IReduxState) => state.nav.workspaceType);
  return (
    <Suspense fallback={<LoadingModal loadingTheme="dark" />}>
      <Switch>
        <Route exact={true} path={[ROUTE_PATHS.ROOT, ROUTE_PATHS.LOGIN]} component={S.LoginLanding} />
        <PrivateRoute
          exact={true}
          path={[ROUTE_PATHS.WORKSPACE_CLIENT_REFERENCES, ROUTE_PATHS.WORKSPACE_REFERENCES]}
          component={S.WorkspaceClientReferences}
        />
        <PrivateRoute
          exact={true}
          path={[
            ROUTE_PATHS.WORKSPACE_CLIENT_NEW_REFERENCE_DETAILS,
            ROUTE_PATHS.WORKSPACE_NEW_REFERENCE_DETAILS,
            ROUTE_PATHS.WORKSPACE_CLIENT_EXISTING_REFERENCE_DETAILS,
            ROUTE_PATHS.WORKSPACE_EXISTING_REFERENCE_DETAILS,
          ]}
          component={S.WorkspaceClientReferenceDetails}
        />
        <PrivateRoute
          exact={true}
          path={[
            ROUTE_PATHS.WORKSPACE_CLIENT_MAGIC_ORDERS,
            ROUTE_PATHS.CONTACT_MAGIC_ORDERS,
            ROUTE_PATHS.CONTACT_MAGIC_ORDERS,
            ROUTE_PATHS.WORKSPACE_MAGIC_ORDERS,
            ROUTE_PATHS.WORKSPACE_MAGIC_ORDERS_NEW,
            ROUTE_PATHS.CONTACT_MAGIC_ORDERS_NEW,
          ]}
          component={S.WorkspaceMagicOrders}
        />
        <PrivateRoute exact={true} path={ROUTE_PATHS.PRODUCTS} component={S.WorkspaceProducts} />
        <PrivateRoute exact={true} path={ROUTE_PATHS.PURCHASES_UNREAD} component={S.PurchasesUnread} />
        <PrivateRoute
          exact={true}
          path={[ROUTE_PATHS.PURCHASES, ROUTE_PATHS.PURCHASES_WORKSPACE]}
          component={S.Purchases}
        />
        <PrivateRoute
          exact={true}
          path={[
            ROUTE_PATHS.PURCHASES_AGGREGATE,
            ROUTE_PATHS.PURCHASES_AGGREGATE_WORKSPACE,
            ROUTE_PATHS.WORKSPACE_PURCHASES_AGGREGATE,
          ]}
          component={S.OrderAggregate}
        />
        <PrivateRoute exact={true} path={ROUTE_PATHS.SALES_UNREAD} component={S.SalesUnread} />
        <PrivateRoute exact={true} path={[ROUTE_PATHS.SALES, ROUTE_PATHS.SALES_WORKSPACE]} component={S.Sales} />
        <PrivateRoute
          exact={true}
          path={[
            ROUTE_PATHS.SALES_AGGREGATE,
            ROUTE_PATHS.SALES_AGGREGATE_WORKSPACE,
            ROUTE_PATHS.WORKSPACE_SALES_AGGREGATE,
          ]}
          component={p => <S.OrderAggregate {...p} asSeller={true} />}
        />
        <PrivateRoute
          exact={true}
          path={[ROUTE_PATHS.PRODUCTS, ROUTE_PATHS.WORKSPACE_PRODUCTS]}
          component={S.WorkspaceProducts}
        />
        <PrivateRoute exact={true} path={ROUTE_PATHS.WORKSPACE_CARTS} component={S.MultipleCarts} />
        <PrivateRoute exact={true} path={ROUTE_PATHS.WORKSPACE_CARTS_DETAIL} component={S.MultipleCarts} />
        <PrivateRoute
          exact={true}
          path={[ROUTE_PATHS.WORKSPACE_PRODUCTS_BUYER, ROUTE_PATHS.WORKSPACE_PRODUCTS_BUYER_PRODUCT]}
          component={S.WorkspaceProductsBuyer}
        />
        <PrivateRoute
          exact={true}
          path={[ROUTE_PATHS.WORKSPACE_OFFER_TO_ORDER_PREPARATION]}
          component={WorkspaceOfferToOrderPreparation}
        />

        <PrivateRoute
          exact={true}
          path={[ROUTE_PATHS.WORKSPACE_OFFER_EDIT]}
          component={workspaceType === 'buyer' ? null : S.WorkspaceOfferSeller}
        />
        <PrivateRoute
          exact={true}
          path={[ROUTE_PATHS.WORKSPACE_REFERENCE_LIST]}
          component={workspaceType === 'buyer' ? null : WorkspaceReferenceList}
        />
        <PrivateRoute
          exact={true}
          path={[ROUTE_PATHS.WORKSPACE_REFERENCE_LIST_SELECT]}
          component={workspaceType === 'buyer' ? null : WorkspaceReferenceListDetail}
        />
        <PrivateRoute
          exact={true}
          path={[ROUTE_PATHS.WORKSPACE_OFFERS]}
          component={workspaceType === 'buyer' ? S.WorkspaceOffersBuyer : S.WorkspaceOffersListSeller}
        />

        <PrivateRoute exact={true} path={[ROUTE_PATHS.WORKSPACE_REFERENTIAL]} component={S.WorkspaceReferential} />
        <PrivateRoute
          exact={true}
          path={[ROUTE_PATHS.WORKSPACE_REFERENCE_SCREEN]}
          component={S.WorkspaceReferenceScreen}
        />

        <PrivateRoute exact={true} path={ROUTE_PATHS.WORKSPACE_PRICE_GROUPS} component={S.WorkspacePriceGroups} />
        <PrivateRoute exact={true} path={ROUTE_PATHS.WORKSPACE_PDF_FORMATS} component={S.WorkspacePdfFormat} />
        <PrivateRoute
          exact={true}
          path={ROUTE_PATHS.WORKSPACE_PRICE_GROUP_EDIT}
          component={S.WorkspacePriceGroupEdit}
        />
        <PrivateRoute
          exact={true}
          path={[ROUTE_PATHS.WORKSPACE_CLIENTS, ROUTE_PATHS.WORKSPACE_CLIENT_EDIT, ROUTE_PATHS.WORKSPACE_CLIENT_SELL]}
          component={S.WorkspaceClients}
        />
        <PrivateRoute exact={true} path={ROUTE_PATHS.WORKSPACE_SECTIONS} component={S.WorkspaceSections} />
        <PrivateRoute exact={true} path={ROUTE_PATHS.WORKSPACE_SETTINGS_BUYER} component={S.WorkspaceSettingsBuyer} />
        <PrivateRoute
          exact={true}
          path={[
            ROUTE_PATHS.WORKSPACE_SUPPLIERS,
            ROUTE_PATHS.WORKSPACE_SUPPLIER_EDIT,
            ROUTE_PATHS.WORKSPACE_SUPPLIER_BUY,
            ROUTE_PATHS.WORKSPACE_SUPPLIER_CONTRACT_EDIT,
          ]}
          component={S.WorkspaceSuppliers}
        />
        <PrivateRoute exact={true} path={ROUTE_PATHS.WORKSPACE_PREPARE_ORDERS} component={S.WorkspacePrepareOrders} />
        <PrivateRoute
          exact={true}
          path={ROUTE_PATHS.WORKSPACE_UNREAD_PURCHASES}
          component={p => <S.WorkspacePurchases {...p} onlyUnreads={true} />}
        />
        <PrivateRoute
          exact={true}
          path={[
            ROUTE_PATHS.WORKSPACE_PURCHASES,
            ROUTE_PATHS.WORKSPACE_PURCHASES_BUY,
            ROUTE_PATHS.CONTACT_PURCHASES,
            ROUTE_PATHS.WORKSPACE_SUPPLIER_PURCHASES,
            ROUTE_PATHS.WORKSPACE_PURCHASES_SUPPLIER,
          ]}
          component={S.WorkspacePurchases}
        />
        <PrivateRoute
          exact={true}
          path={ROUTE_PATHS.WORKSPACE_UNREAD_SALES}
          component={p => <S.WorkspaceSales {...p} onlyUnreads={true} />}
        />
        <PrivateRoute
          exact={true}
          path={[
            ROUTE_PATHS.WORKSPACE_SALES,
            ROUTE_PATHS.WORKSPACE_SALES_SELL,
            ROUTE_PATHS.CONTACT_SALES,
            ROUTE_PATHS.WORKSPACE_CLIENT_SALES,
            ROUTE_PATHS.WORKSPACE_SALES_CLIENT,
            ROUTE_PATHS.WORKSPACE_CLIENT_SELL_ITEMS,
            ROUTE_PATHS.WORKSPACE_SALES_SELL_ITEMS,
          ]}
          component={S.WorkspaceSales}
        />
        <PrivateRoute
          exact={true}
          path={ROUTE_PATHS.WORKSPACE_PREPARE_ORDER_DETAILS}
          component={S.WorkspacePrepareOrders}
        />
        <PrivateRoute exact={true} path={ROUTE_PATHS.WORKSPACE_SETTINGS_SELLER} component={S.WorkspaceSettingsSeller} />
        <PrivateRoute
          exact={true}
          path={[
            ROUTE_PATHS.WORKSPACES,
            ROUTE_PATHS.WORKSPACES_NEW,
            ROUTE_PATHS.WORKSPACES_BUYER,
            ROUTE_PATHS.WORKSPACES_SELLER,
          ]}
          component={S.Workspaces}
        />
        <PrivateRoute
          exact={true}
          path={[ROUTE_PATHS.WORKSPACE_COLLEAGUES_SELLER]}
          component={S.WorkspaceColleaguesSeller}
        />
        <PrivateRoute
          exact={true}
          path={[ROUTE_PATHS.WORKSPACE_COLLEAGUES_BUYER]}
          component={S.WorkspaceColleaguesBuyer}
        />
        <PrivateRoute exact={true} path={ROUTE_PATHS.PRODUCTS_IMPORT} component={S.ProductsImport} />
        <PrivateRoute exact={true} path={ROUTE_PATHS.COST_IMPORT} component={WorkspaceCostImport} />
        <PrivateRoute
          exact={true}
          path={[
            ROUTE_PATHS.BROADCAST_INFO,
            ROUTE_PATHS.BROADCAST,
            ROUTE_PATHS.CHAT,
            ROUTE_PATHS.CHAT_LIST,
            ROUTE_PATHS.CONTACT_BUY,
            ROUTE_PATHS.CONTACT_CART_BUY,
            ROUTE_PATHS.CONTACT_CART_BUY_ITEMS,
            ROUTE_PATHS.CONTACT_CART_SELL,
            ROUTE_PATHS.CONTACT_CART_SELL_ITEMS,
            ROUTE_PATHS.CONTACT_INFO,
            ROUTE_PATHS.CONTACT_ORDERS,
            ROUTE_PATHS.CONTACT_SELL,
            ROUTE_PATHS.CONTACT,
            ROUTE_PATHS.GROUP_INFO,
            ROUTE_PATHS.GROUP,
            ROUTE_PATHS.PUBLIC_CHANNEL_INFO,
            ROUTE_PATHS.PUBLIC_CHANNEL,
            ROUTE_PATHS.PUBLIC_CHANNEL_POST,
          ]}
          component={props => <Channels {...props} Children={getChannelSection(props)} />}
        />
        <PrivateRoute
          exact={true}
          path={ROUTE_PATHS.CHAT_UNREAD}
          component={props => <Channels {...props} Children={S.ChatUnread} isUnread={true} />}
        />
        <PrivateRoute exact={true} path={ROUTE_PATHS.SETTINGS} component={S.Settings} />
        <Redirect exact={true} from={ROUTE_PATHS.ORDER_DETAILS_SELLER} to={ROUTE_PATHS.SALES} />
        <Redirect exact={true} from={ROUTE_PATHS.ORDER_DETAILS_BUYER} to={ROUTE_PATHS.PURCHASES} />
        <PrivateRoute exact={true} path={ROUTE_PATHS.ORDER_LIST} component={S.OrdersDashboard} />
        {config.TOGGLE_ATHINA.enabled ? (
          <PrivateRoute exact={true} path={ROUTE_PATHS.ATHINA_ANALYZE} component={S.Athina} />
        ) : null}
        <Route exact={true} path={ROUTE_PATHS.ORDER_PUBLIC} component={S.OrderPublic} />
        <Route component={S.LoginLanding} />
      </Switch>
    </Suspense>
  );
};

/**
 * Returns section component in chat
 */
function getChannelSection(props: any) {
  if (props.showInviteOnboarding) return S.InviteOnboarding;
  switch (props.match.path) {
    case ROUTE_PATHS.BROADCAST_INFO:
      return (p: any) => <S.ChatInfo {...p} isBroadcast={true} />;
    case ROUTE_PATHS.BROADCAST:
      return props.match.params.channelId ? S.ChatBroadcast : S.ChatBroadcastInfo;
    case ROUTE_PATHS.CONTACT_BUY:
    case ROUTE_PATHS.CONTACT_CART_BUY:
    case ROUTE_PATHS.CONTACT_CART_BUY_ITEMS:
      return S.ShowroomBuy;
    case ROUTE_PATHS.CONTACT_INFO:
      return S.ContactInfo;
    case ROUTE_PATHS.CONTACT_ORDERS:
      return S.ContactOrders;
    case ROUTE_PATHS.CONTACT_SELL:
    case ROUTE_PATHS.CONTACT_CART_SELL:
    case ROUTE_PATHS.CONTACT_CART_SELL_ITEMS:
      return S.ShowroomSell;
    case ROUTE_PATHS.CONTACT:
    case ROUTE_PATHS.GROUP:
    case ROUTE_PATHS.CHAT:
    case ROUTE_PATHS.CHAT_LIST:
      return S.Chat;
    case ROUTE_PATHS.PUBLIC_CHANNEL:
      return S.ChatPublic;
    case ROUTE_PATHS.PUBLIC_CHANNEL_POST:
      return S.ChatPublicSinglePost;
    case ROUTE_PATHS.PUBLIC_CHANNEL_INFO:
      return S.ChatPublicInfo;
    case ROUTE_PATHS.GROUP_INFO:
      return S.ChatInfo;
  }
}

export default () => (
  <Router history={history}>
    <Routes />
  </Router>
);

import { ORDER_ORIGIN, ORDER_STATUS, __, orderUtils, productService, utils } from 'common-services';
import * as React from 'react';

import config from '../../../../../../../../../bindings/config';
import { IMAGES } from '../../../../../../../../assets';
import { IMAGE_DEFAULT } from '../../../../../../../../constants';
import { ColumnContainer, TagBubble } from '../../../../../../../atoms';
import { ContactCell } from '../../../../../../../molecules';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';
import type { modalActions, IOrderItem, IOrderIssue, IOrder, IWorkspace, IUser, IProdType } from 'common-services';

export const column: OrderItemColumnSpec = {
  id: 'title-with-image',
  rank: 0,
  isVisible: true,
  getTitle: () => __('Components.Cart.items.product'),
  minWidth: '160px',
  width: '20%',
  columnValue: ({
    order,
    catalog,
    prodTypes,
    amEditor,
    weAreSeller,
    editAfterAccept,
    isItemDeletable,
    showProductsModal,
    discardLine,
    onEnableItem,
    openIssuesModal,
    modalOpen,
    orderItemStopDiscarding,
    orderItemRecover,
    onUpdateItem,
    me,
  }) => ({
    getElement: item => {
      const issues = order?.issues.filter(issue => issue.orderItemId === item.id);
      const isCanceled = orderUtils.isCanceled(order);
      const canEdit = amEditor && weAreSeller && order?.status !== ORDER_STATUS.CANCELED;

      const typeVariety = productService.getProductTypeVarietyDisplay(
        item.type,
        prodTypes[item.type]?.name || '',
        item.title,
      );

      const productTitle = item.productTitle || `${typeVariety} ${item.size}`;
      const notFoundIssue = issues.find(i =>
        ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
      );
      const unavailableIssue = issues.find(i => i.type === 'product-unavailable');
      const itemIssue = issues.find(i => i.orderItemId === item.id);
      const productIssue = [
        'no-pieces-per-box',
        'no-boxes-per-pallet',
        'no-box-weight',
        'multi-boxes-per-pallet-without-buyer',
      ].includes(itemIssue?.type);

      // Product Not Found
      if (notFoundIssue && !item.alwaysIgnored) {
        return (
          <ProductNotFound
            item={item}
            issue={notFoundIssue}
            canEdit={canEdit}
            onShowProducts={e => showProductsModal(item, e, undefined, notFoundIssue.code)}
            onDiscard={e => discardLine(e, item)}
          />
        );
      }

      // Product Unavailable
      if (unavailableIssue) {
        return (
          <ProductUnavailable
            item={item}
            issue={unavailableIssue}
            title={productTitle}
            canEdit={canEdit}
            onEnable={() => onEnableItem(item, unavailableIssue)}
            onDiscard={e => discardLine(e, item)}
          />
        );
      }

      // Product with Issues
      if (
        productIssue &&
        itemIssue &&
        (config.TOGGLE_ORDER_ITEM_ISSUE.enabled ||
          (config.TOGGLE_BOXES_PER_PALLET.enabled && catalog?.mandatoryPalletizationByClient))
      ) {
        return (
          <ProductWithIssue
            item={item}
            issue={itemIssue}
            title={productTitle}
            canEdit={canEdit}
            onOpenIssues={() => openIssuesModal(item)}
          />
        );
      }

      // Active Product
      const isActiveProduct =
        (item.servedQuantity > 0 && !item.alwaysIgnored) ||
        (!item.amount && [ORDER_ORIGIN.IMPORT_UI, ORDER_ORIGIN.EMAIL].includes(order?.origin) && !item.alwaysIgnored);
      if (isActiveProduct) {
        return (
          <ActiveProduct
            item={item}
            productTitle={productTitle}
            status={order?.status}
            isCanceled={isCanceled}
            prodTypes={prodTypes}
          />
        );
      }

      // Deleted Product
      if (isItemDeletable && order?.status !== ORDER_STATUS.INCOMPLETE) {
        return <S.TextGrey>{productTitle}</S.TextGrey>;
      }

      return (
        <S.CellContainer>
          <DeletedProduct
            item={item}
            title={productTitle}
            order={order}
            catalog={catalog}
            me={me}
            canUndo={amEditor && weAreSeller && editAfterAccept}
            onUndo={() => onUpdateItem({ ...item, servedQuantity: item.amount })}
            modalOpen={modalOpen}
            orderItemStopDiscarding={orderItemStopDiscarding}
            orderItemRecover={orderItemRecover}
            onUpdateItem={onUpdateItem}
          />
        </S.CellContainer>
      );
    },
  }),
};

// Helper Components
const ProductError: React.FC<{
  message: string | React.ReactNode;
  actions?: React.ReactNode;
}> = React.memo(({ message, actions }) => (
  <S.ProductErrorRow>
    <S.WarningIcon name="Warning" disableHover />
    <ColumnContainer>
      {typeof message === 'string' ? <S.Text>{message}</S.Text> : message}
      {actions}
    </ColumnContainer>
  </S.ProductErrorRow>
));

ProductError.displayName = 'ProductError';

const ProductNotFound: React.FC<{
  item: IOrderItem;
  issue: IOrderIssue;
  canEdit: boolean;
  onShowProducts: (e: React.MouseEvent) => void;
  onDiscard: (e: React.MouseEvent) => void;
}> = React.memo(({ item, issue, canEdit, onShowProducts, onDiscard }) => {
  const isMultiCode = issue.type === 'many-internal-codes-for-external';
  const message = __(
    isMultiCode ? 'Components.ProductDetails.product_mapping_duplicated' : 'Components.ProductDetails.product_missing',
    { name: item.title, code: issue.code },
  );

  return (
    <ProductError
      message={utils.formatText(message, (s, index) => <S.TextBold key={s + index}>{s}</S.TextBold>) as React.ReactNode}
      actions={
        canEdit && (
          <S.Text>
            {`${__('Components.ProductDetails.product_missing_actions.0')} `}
            <S.TextLink onClick={onShowProducts}>
              {__('Components.ProductDetails.product_missing_actions.1')}
            </S.TextLink>
            {' ' + __('Components.ProductDetails.product_missing_actions.2') + ' '}
            <S.TextLink onClick={onDiscard}>{__('Components.ProductDetails.product_missing_actions.3')}</S.TextLink>
          </S.Text>
        )
      }
    />
  );
});

ProductNotFound.displayName = 'ProductNotFound';

const ProductUnavailable: React.FC<{
  item: IOrderItem;
  issue: IOrderIssue;
  title: string;
  canEdit: boolean;
  onEnable: () => void;
  onDiscard: (e: React.MouseEvent) => void;
}> = React.memo(({ title, canEdit, onEnable, onDiscard }) => (
  <ProductError
    message={<S.ItemTitle>{utils.firstToUpperCase(title)}</S.ItemTitle>}
    actions={
      <>
        <TagBubble color="error" label={__('Components.ProductCard.out_of_stock')} disabled showIcon />
        {canEdit && (
          <S.Text>
            {__('Components.ProductDetails.product_unavailable_actions.0')}{' '}
            <S.TextLink onClick={onEnable}>{__('Components.ProductDetails.product_unavailable_actions.1')}</S.TextLink>
            {' ' + __('Components.ProductDetails.product_unavailable_actions.2') + ' '}
            <S.TextLink onClick={onDiscard}>{__('Components.ProductDetails.product_unavailable_actions.3')}</S.TextLink>
          </S.Text>
        )}
      </>
    }
  />
));

ProductUnavailable.displayName = 'ProductUnavailable';

const ProductWithIssue: React.FC<{
  item: IOrderItem;
  issue: IOrderIssue;
  title: string;
  canEdit: boolean;
  onOpenIssues: () => void;
}> = React.memo(({ title, canEdit, onOpenIssues, issue }) => {
  const completeInfo =
    {
      'no-pieces-per-box': 'Components.ProductDetails.complete_info_pieces_box',
      'no-boxes-per-pallet': 'Components.ProductDetails.complete_info_boxes_pallet',
      'multi-boxes-per-pallet-without-buyer': 'Components.ProductDetails.complete_info_boxes_pallet',
      'no-box-weight': 'Components.ProductDetails.complete_info_box_weight',
    }[issue.type] || 'Components.ProductDetails.complete_info_default';

  return (
    <ProductError
      message={<S.ItemTitle>{utils.firstToUpperCase(title)}</S.ItemTitle>}
      actions={
        canEdit && (
          <S.ProductErrorRow>
            <S.PencilIcon disableHover name="Edit" />
            <S.TextLink onClick={onOpenIssues}> {__(completeInfo)}</S.TextLink>
          </S.ProductErrorRow>
        )
      }
    />
  );
});

ProductWithIssue.displayName = 'ProductWithIssue';

const ActiveProduct: React.FC<{
  item: IOrderItem;
  productTitle: string;
  status: ORDER_STATUS;
  isCanceled: boolean;
  prodTypes: Record<string, IProdType>;
}> = React.memo(({ item, productTitle, status, isCanceled, prodTypes }) => {
  const productImage = (item.images?.[0]?.image.url || prodTypes[item.type]?.defaultImageUrl || IMAGE_DEFAULT).replace(
    '/f_auto/',
    '/f_auto,w_400,c_limit/',
  );

  return (
    <S.CellContainer>
      <ContactCell
        status={status}
        avatar={productImage}
        imgText="product image"
        primaryText={productTitle}
        showGrey={isCanceled}
      />
    </S.CellContainer>
  );
});

ActiveProduct.displayName = 'ActiveProduct';

const DeletedProduct: React.FC<{
  item: IOrderItem;
  title: string;
  order: IOrder;
  catalog: IWorkspace;
  me: IUser;
  canUndo: boolean;
  onUndo: () => void;
  modalOpen: typeof modalActions.modalOpen;
  orderItemStopDiscarding: (catalogId: number, userId: number, eanCode: string) => void;
  orderItemRecover: (
    catalogId: number,
    userId: number,
    orderId: number,
    itemId: number,
    callback: (order: IOrder) => void,
  ) => void;
  onUpdateItem: (item: IOrderItem) => void;
}> = React.memo(
  ({
    item,
    title,
    order,
    catalog,
    me,
    canUndo,
    onUndo,
    modalOpen,
    orderItemStopDiscarding,
    orderItemRecover,
    onUpdateItem,
  }) => {
    if (config.TOGGLE_DISCARD_MO.enabled) {
      return (
        <S.DiscardWrapper>
          <S.Deleted>
            {item.alwaysIgnored
              ? __('Components.ProductDetails.discarded_always')
              : __('Components.ProductDetails.discarded')}
          </S.Deleted>
          <S.DiscardContainer>
            <S.GreyText>{title}</S.GreyText>
            {order?.status === ORDER_STATUS.ACCEPTED ? (
              item.alwaysIgnored ? (
                <S.TextLink
                  onClick={e => {
                    e.stopPropagation();
                    modalOpen(
                      __('Components.ProductDetails.recover_modal.title'),
                      () => {
                        orderItemStopDiscarding(catalog.id, me.id, item.eanCode);
                        onUpdateItem({
                          ...item,
                          alwaysIgnored: false,
                        });
                      },
                      {
                        text2: __('Components.ProductDetails.recover_modal.text'),
                        buttonText: __('Components.ProductDetails.recover_modal.cta'),
                        showCancelButton: true,
                        icon: IMAGES.informativePineapple,
                      },
                      'nice',
                    );
                  }}
                >
                  {__('Components.ProductDetails.stop_discarding')}
                </S.TextLink>
              ) : null
            ) : !orderUtils.isCanceled(order) ? (
              <S.TextLink
                onClick={e => {
                  e.stopPropagation();
                  orderItemRecover(catalog.id, me.id, order?.id, item.id, o => {
                    const orderIdx = o.items.findIndex(i => i.id === item.id);
                    if (orderIdx !== -1) {
                      order.items[orderIdx] = {
                        ...order?.items[orderIdx],
                        alwaysIgnored: false,
                      };
                    }
                  });
                }}
              >
                {__('Components.ProductDetails.recover')}
              </S.TextLink>
            ) : null}
            {canUndo && <S.Undo onClick={onUndo}>{__('Components.ProductDetails.undo')}</S.Undo>}
          </S.DiscardContainer>
        </S.DiscardWrapper>
      );
    }

    return (
      <S.DeleteContainer>
        <S.Deleted>
          {item.alwaysIgnored
            ? __('Components.ProductDetails.discarded_always')
            : __('Components.ProductDetails.deleted')}
        </S.Deleted>
        <S.TextGrey>{title}</S.TextGrey>
        {canUndo && <S.Undo onClick={onUndo}>{__('Components.ProductDetails.undo')}</S.Undo>}
      </S.DeleteContainer>
    );
  },
);

DeletedProduct.displayName = 'DeletedProduct';

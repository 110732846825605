import styled from '../../../../../../../../styled-components';
import { ColumnContainer } from '../../../../../../../atoms';

import type { ITheme } from '../../../../../../../../styled-components';

interface IStyledProps {
  theme: ITheme;
  selected?: boolean;
  disabled?: boolean;
  isImage?: boolean;
}

export const AttachmentsCol = styled(ColumnContainer)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

import * as React from 'react';

import { OrderContext } from './OrderContext.provider';

import type { OrderContextValue } from './OrderContext.types';

const useOrderContext = (): OrderContextValue => {
  const context = React.useContext(OrderContext);
  if (!context) {
    throw new Error('useOrderContext must be used within OrderProvider');
  }
  return context;
};

export default useOrderContext;

import { ORDER_STATUS, EventTrack, parsers, sellerWorkspaceService } from 'common-services';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import ProductInfo from '../../../../ProductInfo';
import { useOrderContext } from '../../../context';

import * as S from './ProductInfoModal.styled';

import type { IOrderItem } from 'common-services';

export const ProductInfoModal: React.FC = () => {
  const history = useHistory(); // TODO:3 remove this line

  const {
    me,
    order,
    catalog,
    weAreBuyer,
    weAreSeller,
    channelId,
    contact,
    clients,
    countries,
    prodTypes,
    breadcrumb,
    updateBreadcrumb,
    handleItemUpdate: onUpdateItem,
    closeProductPage: onClose,
    sendMessage,
    modalOpen,
    modalClose,
    touchImage,
    pricePrecision,
    productPageProps: { deletable, item, product, priceMode },
  } = useOrderContext();

  // TODO:1 refactor many things to hook

  // Get contact ID based on buyer/seller status
  const contactId = weAreBuyer ? order?.sellerId : order?.buyerId;

  // Get contact name
  const contactName = React.useMemo(
    () => contact?.name || clients?.find(c => c.userId === contactId)?.name || '',
    [contact, clients, contactId],
  );

  // Get catalog ID
  const catalogId = React.useMemo(() => {
    if (order?.buyerId !== me.id || contact?.isUnregistered) {
      return contact?.mySellerWorkspaceId || me.sellerWorkspaceId;
    }
    return contact?.theirSellerWorkspaceId;
  }, [order?.buyerId, me.id, contact, me.sellerWorkspaceId]);

  // Check if component is disabled
  const isDisabled = React.useMemo(
    () =>
      order?.status !== 'pending' ||
      (![order?.sellerId, order?.buyerId].includes(me.id) &&
        sellerWorkspaceService.getRole(catalog, me.id) === 'viewer'),
    [order, me.id, catalog],
  );

  // Handle item update
  const handleItemUpdate = React.useCallback(
    (cId: number, itemToUpdate: IOrderItem) => {
      onUpdateItem(itemToUpdate);

      if (updateBreadcrumb && breadcrumb) {
        const newBreadcrumb = {
          parentSections: breadcrumb.parentSections.slice(0, -1),
          title: breadcrumb.parentSections.slice(-1)[0].label,
        };
        updateBreadcrumb(newBreadcrumb);
      }
    },
    [onUpdateItem, updateBreadcrumb, breadcrumb],
  );

  // Handle message sending
  const handleSendMessage = React.useCallback(
    (text: string) => {
      if (!text || !channelId || !sendMessage) return;

      const message = text.replace(/(?:\r\n|\r|\n)/, '\\n');
      sendMessage(me.id, {
        channelId,
        createdAt: new Date().getTime() + 60000,
        extraData: {},
        message,
        messageId: 0,
        messageType: 'text',
        reactions: {},
        senderId: me.id,
      });

      EventTrack.track('message_send', {
        channelId: 'order_' + order?.id,
        type: 'order',
        messageType: 'text',
        length: message.length,
      });
    },
    [channelId, sendMessage, me.id, order?.id],
  );

  return (
    <S.Modal>
      <ProductInfo
        // Base props
        cartUpdateItem={handleItemUpdate}
        contactId={contactId}
        contact={contact}
        contactName={contactName}
        isContactUnregistered={contact?.isUnregistered}
        isServedFlow={order?.status === ORDER_STATUS.ACCEPTED && order?.servedFlowEnabled}
        catalogId={catalogId}
        close={onClose}
        countries={countries}
        deletable={deletable}
        forceSetPrice={!weAreSeller && contact?.isUnregistered}
        from="order"
        history={history}
        // User related
        amSeller={weAreSeller}
        me={me}
        // Product related
        item={item}
        product={{
          ...parsers.orderItemToGenericProduct(item),
          updatedAt: product.updatedAt,
        }}
        priceMode={priceMode}
        pricePrecision={pricePrecision}
        prodTypes={prodTypes}
        // State and actions
        disabled={isDisabled}
        modalClose={modalClose}
        modalOpen={modalOpen}
        sendMessage={channelId ? handleSendMessage : undefined}
        touchImage={touchImage}
        // UI configuration
        showBack={!breadcrumb}
        showDefaultPicture={false}
        showFeatured={true}
        showShare={true}
      />
    </S.Modal>
  );
};

export default React.memo(ProductInfoModal);

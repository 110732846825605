import { __ } from 'common-services';
import * as React from 'react';

import { IMAGES } from '../../../../../../assets';
import { EmptyListResource } from '../../../../../molecules';
import { useOrderContext } from '../../../context';

import { CustomItems } from './CustomItems';
import * as S from './ItemsList.styled';
import { OrderItems } from './OrderItems';

export const ItemsList: React.FC = React.memo(() => {
  const {
    hasItems,
    navShowroom: navigateToShowroom,
    canEdit,
    customItemsCount,
    customItems,
    isBuyerOfferingView,
  } = useOrderContext();
  return (
    <S.Container>
      {hasItems ? (
        <S.CardItem>
          <OrderItems />
        </S.CardItem>
      ) : (
        <S.CenterContainer>
          <EmptyListResource
            text={__('Components.OrderDetails.empty_cart.title')}
            showButton={true}
            buttonAction={navigateToShowroom}
            buttonText={__('Components.OrderDetails.empty_cart.cta')}
            imageUrl={IMAGES.emptyBox}
          />
        </S.CenterContainer>
      )}
      {canEdit && customItems && !isBuyerOfferingView ? (
        <S.CustomItemsContainer>
          <S.SectionTitleCustomItems>
            {__('Components.Cart.custom_items.title') + (customItemsCount !== '' ? ' ' + `(${customItemsCount})` : '')}
            <S.Tooltip
              position="bottom"
              text={__('Components.Cart.custom_items.tooltip')}
              width="200px"
              className="tooltip-add-line"
              themeMode="dark"
            >
              <S.InfoIcon name="Info" disableHover={true} />
            </S.Tooltip>
          </S.SectionTitleCustomItems>
          <CustomItems />
        </S.CustomItemsContainer>
      ) : null}
    </S.Container>
  );
});

ItemsList.displayName = 'ItemsList';

export default ItemsList;

import * as React from 'react';

import type { ColumnConfig, ColumnOptions, ColumnSpec } from './columns.types';
import type { IColumn } from './Table.component';

/**
 * Generic hook for processing and managing table columns with optimized memoization
 * @param useContextHook - Hook to access the context needed for column props
 * @param columnConfig - Array of column configuration
 * @param specs - Array of column specifications
 * @param columnPropsHook - Hook to get column props
 * @returns Array of processed columns
 */
export function useTableColumns<
  TContext,
  TTitleProps,
  TConditionProps,
  TOptionsProps,
  TOptions extends ColumnOptions<TValueInput, TElementInput, TDataCellInput>,
  TColumnConfig extends ColumnConfig,
  TColumnSpec extends ColumnSpec<
    TOptions,
    TTitleProps,
    TConditionProps,
    TOptionsProps,
    TValueInput,
    TElementInput,
    TDataCellInput
  >,
  TValueInput,
  TElementInput,
  TDataCellInput,
>(
  columnConfig: Array<TColumnConfig>,
  specs: Array<TColumnSpec>,
  useContextHook: () => TContext,
  columnPropsHook: (useOrderContext: () => TContext) => {
    columnProps: TOptionsProps;
    titleProps: TTitleProps;
    conditionProps: TConditionProps;
  },
): Array<IColumn> {
  const { columnProps, titleProps, conditionProps } = columnPropsHook(useContextHook);

  /**
   * Helper function to find the next available rank
   */
  const findNextAvailableRank = React.useCallback((existingRanks: Array<number>, desiredRank: number): number => {
    if (!existingRanks.includes(desiredRank)) {
      return desiredRank;
    }

    let nextRank = desiredRank;
    while (existingRanks.includes(nextRank)) {
      nextRank++;
    }
    return nextRank;
  }, []);

  // Process and filter specs based on configuration
  const filteredSpecs = React.useMemo(() => {
    const fixedRanks = specs.filter(spec => spec.rank !== undefined).map(spec => spec.rank as number);
    const assignedRanks: Array<number> = [...fixedRanks];

    return columnConfig.reduce<Array<TColumnSpec>>((result, config) => {
      const spec = specs.find(s => s.id === config.name);

      if (spec) {
        const finalRank =
          spec.rank !== undefined
            ? spec.rank
            : config.order !== undefined
            ? findNextAvailableRank(assignedRanks, config.order)
            : findNextAvailableRank(assignedRanks, 999);

        assignedRanks.push(finalRank);
        result.push({ ...spec, rank: finalRank });
      } else {
        console.warn(`Column spec not found for ${config.name}`);
      }

      return result;
    }, []);
  }, [columnConfig, specs, findNextAvailableRank]);

  // Sort columns based on rank and title
  const sortColumns = React.useCallback(
    (a: TColumnSpec, b: TColumnSpec) => {
      if (a.rank === undefined && b.rank === undefined) {
        return a.getTitle(titleProps).localeCompare(b.getTitle(titleProps));
      }
      return (a.rank ?? 999) - (b.rank ?? 999);
    },
    [titleProps],
  );

  const sortedColumns = React.useMemo(() => {
    return [...filteredSpecs].sort(sortColumns);
  }, [filteredSpecs, sortColumns]);

  // Filter columns based on conditions
  const filteredByCondition = React.useMemo(() => {
    return sortedColumns.filter(spec => !spec.condition || spec.condition(conditionProps));
  }, [sortedColumns, conditionProps]);

  // Process column values
  const processColumnValue = React.useCallback(
    (spec: TColumnSpec) => {
      if (!spec.columnValue) return {};

      const columnValue = spec.columnValue(columnProps);
      return {
        ...(columnValue.getValue && { value: columnValue.getValue }),
        ...(columnValue.getElement && { element: columnValue.getElement }),
        ...(columnValue.getDataCell && { getDataCell: columnValue.getDataCell }),
      };
    },
    [columnProps],
  );

  // Generate final columns
  return React.useMemo(() => {
    return filteredByCondition.map((spec): IColumn => {
      const baseColumn: IColumn = {
        id: spec.id,
        title: spec.getTitle(titleProps),
        onClick: spec.onClick,
        width: spec.width,
        minWidth: spec.minWidth,
        showOver: spec.showOver,
        align: spec.align,
        cellClassName: spec.cellClassName,
      };

      return {
        ...baseColumn,
        ...processColumnValue(spec),
      };
    });
  }, [filteredByCondition, titleProps, processColumnValue]);
}

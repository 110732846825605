import { __, utils, productService, userSelectors } from 'common-services';
import * as React from 'react';
import { useSelector } from 'react-redux';

import config from '../../../../../../../bindings/config';
import { Ribbon } from '../../../../../atoms';
import { useOrderContext } from '../../../context';

import LostLines from './LostLines';
import * as S from './PricingIssuesRibbon.styled';

export const PricingIssuesRibbon: React.FC = React.memo(() => {
  const { order, prodTypes, weAreSeller } = useOrderContext();

  // Get the toggle value
  const togglePriceMismatchEnable = useSelector(userSelectors.hasToggleEnabled(config.TOGGLE_PRICE_MISMATCH));

  // Get mismatch prices count
  const mismatchPricesCount = React.useMemo(
    () => order?.issues.filter(err => err.type === 'no-pricegroup-matching').length || 0,
    [order?.issues],
  );

  // Get lost lines
  const lostLines = React.useMemo(() => order?.issues.find(err => err.type === 'kind-loss-of-line'), [order?.issues]);

  // Get item with mismatch
  const itemMismatchRelated = React.useMemo(() => {
    if (mismatchPricesCount !== 1) return undefined;

    const mismatchIssue = order?.issues.find(err => err.type === 'no-pricegroup-matching');
    return order?.items.find(item => item.id === mismatchIssue?.orderItemId);
  }, [mismatchPricesCount, order?.issues, order?.items]);

  // Get product name
  const productName = React.useMemo(() => {
    if (!itemMismatchRelated) return '';

    const typeVariety = productService.getProductTypeVarietyDisplay(
      itemMismatchRelated.type,
      prodTypes[itemMismatchRelated.type]?.name || '',
      itemMismatchRelated.title,
    );

    return utils.firstToUpperCase(
      `${itemMismatchRelated.productTitle || typeVariety + ' ' + itemMismatchRelated.size}`,
    );
  }, [itemMismatchRelated, prodTypes]);

  if (!togglePriceMismatchEnable || !weAreSeller || (!mismatchPricesCount && !lostLines)) {
    return null;
  }

  return (
    <Ribbon
      type="info"
      text={
        <S.Container>
          {mismatchPricesCount > 0 && (
            <S.Text>
              {
                utils.formatText(
                  __('Components.Cart.errors.mismatch_price', {
                    count: mismatchPricesCount,
                    productName,
                  }),
                  (text, i) => <S.TextBold key={text + i}>{text}</S.TextBold>,
                ) as any // eslint-disable-line @typescript-eslint/no-explicit-any
              }
            </S.Text>
          )}
          {lostLines && <LostLines lostLines={lostLines} />}
        </S.Container>
      }
    />
  );
});

PricingIssuesRibbon.displayName = 'PricingIssuesRibbon';

export default PricingIssuesRibbon;

import styled from '../../../../../../../../../styled-components';
import { ColumnContainer, RowContainer } from '../../../../../../../../atoms';

import type { ITheme } from '../../../../../../../../../styled-components';

interface IStyledProps {
  theme: ITheme;
  align?: string;
  baseMarginPercentage?: number;
  enabled?: boolean;
  hasError?: boolean;
  hasServedChange?: boolean;
  isCancelled?: boolean;
  isChecked?: boolean;
  isClickable?: boolean;
  isIssue?: boolean;
  margin?: string;
  marginPercentage?: number;
  rightPosition?: string;
  servedQuantity?: boolean;
  showGrey?: boolean;
  width?: string;
  disabled?: boolean;
}

export const FooterRow = styled(RowContainer)`
  float: right;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const PriceFooter = styled(RowContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  justify-content: flex-end;
`;

export const PriceColumn = styled(ColumnContainer)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TotalPriceRow = styled(RowContainer)`
  float: right;
`;

const TextSpan = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  text-align: ${(p: IStyledProps) => (p.align ? p.align : 'left')};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  &:last-child {
    padding-right: ${(p: IStyledProps) => p.theme.paddingSize(6)};
    @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
      padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
    }
  }
`;

const UpperSpan = styled(TextSpan)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const TotalSpan = styled(UpperSpan)`
  text-align: right;
`;

export const PriceSpan = styled(UpperSpan)`
  text-align: right;
`;

export const PriceText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const ResumePrice = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TotalResumePrice = styled(ResumePrice)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
`;

export const MarginText = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const MarginTextPercentage = styled.span`
  display: inline;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

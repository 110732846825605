import { __, orderBuyerPreparationActions } from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { unitTranslator } from '../../../../util/unit';
import { ExpansionPanel } from '../../../atoms';
import { OrderPreparationContext } from '../OrderPreparationContext';

import GroupedLines from './GroupedLines.component';
import * as S from './OrderPreparationTable.styled';

import type { IGroupedLinesBySeller, IOrderBuyerPreparationGroup, IOrderBuyerPreparationLine } from 'common-services';
import type { Dispatch } from 'redux';

export interface IProps {
  workspaceId: number;
  deleteLines: (lines: Array<number>) => void;
}

const OrderPreparationTable: React.FC<IProps> = ({ workspaceId, deleteLines }) => {
  const dispatch = useDispatch<Dispatch<any>>(); // eslint-disable-line @typescript-eslint/no-explicit-any

  const { orderBuyerPreparationId, groupedOrderPreparationLines } = React.useContext(OrderPreparationContext);

  const [groupedLinesBySeller, setGroupedLinesBySeller] = React.useState<{
    [referenceId: number]: Array<IGroupedLinesBySeller>;
  }>({});

  React.useEffect(() => {
    if (!groupedOrderPreparationLines?.length) return;
    const initialGroupedLines: { [key: number]: Array<IGroupedLinesBySeller> } = groupedOrderPreparationLines.reduce(
      (acc, group) => {
        acc[group.referenceId] = groupLinesBySeller(group.lines);
        return acc;
      },
      {},
    );

    setGroupedLinesBySeller(initialGroupedLines);
  }, [groupedOrderPreparationLines]);

  const calculateAffectedQuantitiesSingleUnit = React.useMemo(() => {
    return (linesBySeller: Array<IGroupedLinesBySeller>) => {
      const warehouseQuantities: {
        [key: number]: {
          buyerWarehouseName: string;
          affectedQuantity: number;
        };
      } = {};

      const allLines = linesBySeller?.flatMap(seller => seller.lines) || [];

      allLines.forEach(line => {
        if (!warehouseQuantities[line.buyerWarehouseId]) {
          warehouseQuantities[line.buyerWarehouseId] = {
            buyerWarehouseName: line.buyerWarehouseName,
            affectedQuantity: 0,
          };
        }
        warehouseQuantities[line.buyerWarehouseId].affectedQuantity += line.orderedQuantity || 0;
      });

      return warehouseQuantities;
    };
  }, []);
  const calculateAffectedQuantities = React.useMemo(() => {
    return (linesBySeller: Array<IGroupedLinesBySeller> | null | undefined) => {
      const warehouseQuantities: {
        [key: number]: {
          buyerWarehouseName: string;
          affectedQuantities: {
            [unit: string]: number;
          };
        };
      } = {};

      const allLines = linesBySeller?.flatMap(seller => seller.lines) || [];

      allLines.forEach(line => {
        if (line.buyerWarehouseId !== null) {
          if (!warehouseQuantities[line.buyerWarehouseId]) {
            warehouseQuantities[line.buyerWarehouseId] = {
              buyerWarehouseName: line.buyerWarehouseName || '',
              affectedQuantities: {},
            };
          }
          // If there is offeredQuantity, quantityUnit is used, if not totalQuantityUnit
          const unit = line.offeredProduct.offeredQuantity
            ? line.offeredProduct?.quantityUnit
            : line.offeredProduct?.totalQuantityUnit;
          const warehouse = warehouseQuantities[line.buyerWarehouseId];

          // Initialize unit if not exists
          if (!warehouse.affectedQuantities[unit]) {
            warehouse.affectedQuantities[unit] = 0;
          }

          // Add ordered quantity for specific unit
          warehouse.affectedQuantities[unit] += line.orderedQuantity ?? 0;
        }
      });

      return warehouseQuantities;
    };
  }, []);

  const updateBuyPreparationLine = React.useCallback(
    (
      referenceId: number,
      line: IOrderBuyerPreparationLine,
      commentOrQuantity: 'quantity' | 'comment',
      value: string | number,
    ) => {
      const numberValue = +value || 0;

      const body = {
        departurePrice: line?.departurePrice,
        deliveredPrice: line?.deliveredPrice,
        orderedQuantity: commentOrQuantity === 'quantity' ? numberValue : line?.orderedQuantity,
        comment: commentOrQuantity === 'comment' ? value.toString() : line?.comment,
      };

      setGroupedLinesBySeller(prevState => {
        const updatedGroupedLines = { ...prevState };

        if (updatedGroupedLines[referenceId]) {
          updatedGroupedLines[referenceId] = updatedGroupedLines[referenceId].map(groupedSeller =>
            groupedSeller.sellerId === line.seller.id
              ? {
                  ...groupedSeller,
                  lines: groupedSeller.lines.map(existingLine =>
                    existingLine.id === line.id
                      ? {
                          ...existingLine,
                          // Update only the relevant field based on commentOrQuantity
                          ...(commentOrQuantity === 'quantity' && { orderedQuantity: numberValue }),
                          ...(commentOrQuantity === 'comment' && { comment: value.toString() }),
                        }
                      : existingLine,
                  ),
                }
              : groupedSeller,
          );
        }

        return updatedGroupedLines;
      });
      dispatch(orderBuyerPreparationActions.lineUpdate(workspaceId, orderBuyerPreparationId, body, line.id));
    },
    [dispatch, orderBuyerPreparationId, workspaceId],
  );

  if (!Object.keys(groupedLinesBySeller).length) {
    return null;
  }
  const sortedGroupedOrderPreparationLines = groupedOrderPreparationLines.sort((a, b) => {
    if (a.referenceName > b.referenceName) {
      return 1;
    }
    if (a.referenceName < b.referenceName) {
      return -1;
    }
    return 0;
  });
  return (
    <S.Container style={{ overflow: 'scroll' }}>
      {sortedGroupedOrderPreparationLines.map((group, _) =>
        group.lines.length ? renderGroup(group, group.referenceId) : null,
      )}
    </S.Container>
  );

  /**
   * Returns a group component of OrderBuyerPreparationLine list
   */
  function renderGroup(o: IOrderBuyerPreparationGroup, key: number): JSX.Element {
    const linesBySeller = groupedLinesBySeller[key];

    const warehouses = getWarehouseList(o);
    if (!linesBySeller) return null;
    return (
      <S.ItemsPanel key={key} className="group-row">
        <ExpansionPanel
          contentWithLeftPadding={false}
          title={o.referenceName}
          defaultExpanded={true}
          iconPosition="left"
          closedDescription={
            <S.TableOrders style={{ position: 'absolute', background: 'transparent' }}>
              <S.Body>
                <tr>
                  <S.Col />
                  <S.Col />
                  <S.Col />
                  <S.Col />
                  <S.Col />
                  <S.Col />
                  <S.Col />
                  <S.ClosedCol />
                  <S.ClosedCol />
                  <S.ClosedCol />
                </tr>
              </S.Body>
            </S.TableOrders>
          }
        >
          <GroupedLines
            linesBySeller={linesBySeller}
            warehouses={warehouses}
            footerChildren={renderSubtotal(o, key)}
            updateBuyerPreparationLine={(line, commentOrQuantity, value) =>
              updateBuyPreparationLine(key, line, commentOrQuantity, value)
            }
            orderBuyerPreparationId={orderBuyerPreparationId}
            onDelete={deleteLines}
          />
        </ExpansionPanel>
      </S.ItemsPanel>
    );
  }

  /**
   * Render subtotal line for an aggregation group
   */
  function renderSubtotal(o: IOrderBuyerPreparationGroup, key: number): JSX.Element {
    const warehouses = getWarehouseList(o);
    const linesBySeller = groupedLinesBySeller[key];

    // Use the memoized calculation function
    const affectedQuantities = calculateAffectedQuantities(linesBySeller || []);
    const affectedQuantity = calculateAffectedQuantitiesSingleUnit(linesBySeller || []);

    return (
      <tfoot>
        <S.SubTotalTr key={key + '-subtotal'} className="orders-subtotal">
          <S.AutoCol colSpan={3} />
          <S.SubTotalCol>{__('Components.OffersList.order_preparation.table.total_quantity')}</S.SubTotalCol>
          {warehouses.map(warehouse => {
            const currentLine = o.lines.find(
              line => line.offeredProduct.buyerWarehouseId === warehouse.buyerWarehouseId,
            );

            if (
              affectedQuantities[warehouse.buyerWarehouseId] &&
              Object.keys(affectedQuantities[warehouse.buyerWarehouseId]?.affectedQuantities).length > 1
            ) {
              return (
                <S.BoldCol key={warehouse.buyerWarehouseId}>
                  <S.Row>
                    {Object.keys(affectedQuantities[warehouse.buyerWarehouseId]?.affectedQuantities).map(unit => (
                      <S.BoldText key={warehouse.buyerWarehouseId + '-' + unit}>
                        {`
                ${affectedQuantities[warehouse.buyerWarehouseId]?.affectedQuantities[unit]}
                ${unitTranslator(unit, affectedQuantities[warehouse.buyerWarehouseId]?.affectedQuantities[unit])}
                  `}
                      </S.BoldText>
                    ))}
                  </S.Row>
                </S.BoldCol>
              );
            }

            return (
              <S.BoldCol key={warehouse.buyerWarehouseId}>
                {`
                ${affectedQuantity[warehouse.buyerWarehouseId]?.affectedQuantity}
                  ${unitTranslator(
                    currentLine.offeredProduct.totalQuantityUnit,
                    affectedQuantity[warehouse.buyerWarehouseId]?.affectedQuantity,
                  )}
                  `}
              </S.BoldCol>
            );
          })}
        </S.SubTotalTr>
      </tfoot>
    );
  }
};

function getWarehouseList(
  group: IOrderBuyerPreparationGroup,
): Array<{ buyerWarehouseId: number; buyerWarehouseName: string }> {
  const warehouses: { [key: number]: string } = {};

  group.lines.forEach(line => {
    if (!warehouses[line.buyerWarehouseId]) {
      warehouses[line.buyerWarehouseId] = line.buyerWarehouseName;
    }
  });

  return Object.keys(warehouses)
    .map(warehouseId => ({
      buyerWarehouseId: +warehouseId,
      buyerWarehouseName: warehouses[warehouseId],
    }))
    .sort((a, b) => a.buyerWarehouseName.localeCompare(b.buyerWarehouseName)); // Sort by name
}

// function getAffectedQuantitiesByWarehouse(group: IOrderBuyerPreparationGroup): {
//   [key: number]: { buyerWarehouseName: string; affectedQuantity: number };
// } {
//   const warehouseQuantities: { [key: number]: { buyerWarehouseName: string; affectedQuantity: number } } = {};

//   group.lines.forEach(line => {
//     if (!warehouseQuantities[line.buyerWarehouseId]) {
//       warehouseQuantities[line.buyerWarehouseId] = {
//         buyerWarehouseName: line.buyerWarehouseName,
//         affectedQuantity: 0,
//       };
//     }
//     warehouseQuantities[line.buyerWarehouseId].affectedQuantity += line.orderedQuantity || 0;
//   });

//   return warehouseQuantities;
// }

function groupLinesBySeller(lines: Array<IOrderBuyerPreparationLine>): Array<IGroupedLinesBySeller> {
  const groupedLines: { [key: string]: IGroupedLinesBySeller } = {};

  lines.forEach(line => {
    const sellerName = line.seller.name;
    const sellerId = line.seller.id;

    if (!groupedLines[sellerId]) {
      groupedLines[sellerId] = {
        sellerName,
        sellerId,
        lines: [],
      };
    }
    groupedLines[sellerId].lines.push(line);
  });

  return Object.values(groupedLines);
}

export default OrderPreparationTable;

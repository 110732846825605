import { __ } from 'common-services';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'packaging',
  isVisible: true,
  getTitle: () => __('Components.ProductsList.Table.Packaging'),
  minWidth: '70px',
  width: '9%',
};

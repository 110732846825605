import * as React from 'react';

import FontIcon from '../../atoms/FontIcon';

import * as S from './SimpleSearch.styled';

export interface IProps {
  autoFocus?: boolean;
  className?: string;
  id: string;
  name?: string;
  onChange: (filter: string) => void;
  onFocus?: () => void;
  placeHolder?: string;
  query?: string;
  RightButton?: () => JSX.Element;
}

const Search: React.FC<IProps> = ({
  autoFocus,
  className,
  id,
  name,
  onChange,
  onFocus,
  placeHolder,
  query,
  RightButton,
}) => {
  const [searchText, setSearchText] = React.useState(query || '');
  React.useEffect(() => {
    setSearchText(query || '');
    // eslint-disable-next-line
  }, [id, name]);
  return (
    <S.Container className={className}>
      <S.FontIconWrapper className="search-icon">
        <FontIcon name="Search" disableHover={true} />
      </S.FontIconWrapper>
      <S.Input
        id={id}
        name={name || id}
        onChange={e => handleTextChange(e.target.value)}
        placeholder={placeHolder}
        value={searchText}
        autoFocus={autoFocus}
        onFocus={onFocus}
        size={placeHolder?.length}
      />
      {searchText.length ? (
        <>
          {RightButton ? <RightButton /> : null}
          <S.FontIconWrapperRight hasRightButton={!!RightButton} onClick={() => handleTextChange('')}>
            <FontIcon name="Close" />
          </S.FontIconWrapperRight>
        </>
      ) : null}
    </S.Container>
  );

  /**
   * Callback when input text is updated
   */
  function handleTextChange(text: string) {
    onChange(text);
    setSearchText(text);
  }
};

export default React.memo(Search);

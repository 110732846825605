import { __, colors, EventTrack, orderService } from 'common-services';
import * as React from 'react';

import { EmptyListResource, SimpleSearch } from '..';
import { IMAGES } from '../../../assets';
import { FontIcon } from '../../atoms';

import { formatTextColor, trackFormatSearch } from './Functions';
import * as S from './OrderMagicImport.styled';

import type { IStateOrdersMagicImport } from './TypesAndConstants';
import type { IContact, IFormatsResponse, IFormat } from 'common-services';

const FormatsList: React.FC<{
  contact: IContact;
  container?: React.RefObject<HTMLDivElement>;
  formats: IFormatsResponse;
  formatSelected: string;
  navigateToAskFormat: (searchText: string) => void;
  setGlobalState: (state: unknown, cb?: () => void) => void;
  setParentState: (newState: Partial<IStateOrdersMagicImport>, callback?: () => void) => void;
  updateClientInformation: (format: string) => (err?: Error) => void;
}> = ({
  contact,
  container,
  formats,
  formatSelected,
  navigateToAskFormat,
  setGlobalState,
  setParentState,
  updateClientInformation,
}) => {
  const [formatsToShow, setFormatsToShow] = React.useState<'all' | 'recommended' | 'recent'>('all');
  const [searchText, setSearchText] = React.useState('');
  const filteredFormats = formats[formatsToShow]
    .map((f: IFormat | string) => f as IFormat)
    .sort((a, b) => a.label.localeCompare(b.label))
    .filter((of: IFormat) => {
      if (!of.accepted_doc_types || !(of.accepted_doc_types.length > 0)) return false; // TODO: check with backend if this is the correct way to filter disabled formats
      if (searchText) return of.label.toLocaleLowerCase().search(searchText.toLocaleLowerCase()) !== -1;
      return true;
    });

  // Tracking events
  React.useEffect(() => {
    EventTrack.track('import_orders_filter_formats', {
      contact_id: contact?.id,
      filter: formatsToShow,
    });
  }, [formatsToShow, contact]);
  React.useEffect(() => trackFormatSearch(contact, searchText), [searchText, contact]);
  return (
    <>
      <SimpleSearch
        id="search-products"
        query={searchText}
        placeHolder={__('MagicOrders.search.placeholder')}
        onChange={setSearchText}
      />
      {searchText ? null : (
        <S.OrderFormatRow>
          <S.ButtonTab type="skip" selected={formatsToShow === 'all'} onClick={() => setFormatsToShow('all')}>
            {__('MagicOrders.Filters.all', { count: formats.all.length })}
          </S.ButtonTab>
          {formats.recent.length ? (
            <S.ButtonTab type="skip" selected={formatsToShow === 'recent'} onClick={() => setFormatsToShow('recent')}>
              {__('MagicOrders.Filters.recent', {
                count: formats.recent.length,
              })}
            </S.ButtonTab>
          ) : null}
        </S.OrderFormatRow>
      )}
      <S.OrderFormatRow>
        {filteredFormats.length ? (
          filteredFormats.map((orderFormat: IFormat, _: number) => {
            const { label, slug, logo_url, accepted_doc_types, tooltip_url } = orderFormat;
            const isSelected = slug === formatSelected;
            return (
              <S.OrderFormatContainer key={slug}>
                <S.OrderFormatSquare
                  id={'format-' + slug}
                  isSelected={isSelected}
                  onClick={() => {
                    setParentState({ formatSelected: slug, showAllFormats: false }, updateClientInformation(slug));
                  }}
                >
                  <S.OrderFormatLogo src={logo_url} />
                  {isSelected ? (
                    <>
                      <S.OverlayGrey />
                      <S.CheckIconWrapper id="icon-check">
                        <FontIcon name="Check" disableHover={true} />
                      </S.CheckIconWrapper>
                    </>
                  ) : null}
                </S.OrderFormatSquare>
                {tooltip_url ? (
                  <S.TooltipIconWrapper>
                    <S.Tooltip
                      position="bottom"
                      container={container}
                      image={tooltip_url}
                      width="470px"
                      className="tooltip-importfile"
                      themeMode="dark"
                    >
                      <S.InfoIcon name="Info" disableHover={true} />
                    </S.Tooltip>
                  </S.TooltipIconWrapper>
                ) : null}
                <S.TextLight>{label}</S.TextLight>
                <S.Text>{orderService.getFormatTypeLabel(accepted_doc_types.join(',') || '')}</S.Text>
              </S.OrderFormatContainer>
            );
          })
        ) : (
          <S.CenterContainer>
            <EmptyListResource
              buttonText={__('MagicOrders.zero_formats.cta')}
              imageUrl={IMAGES.productsNoResult.replace('f_auto', 'c_scale,w_260')}
              showButton={true}
              text={__('MagicOrders.zero_formats.title', {
                search: searchText,
              })}
              text2={__('MagicOrders.zero_formats.subtitle')}
              buttonAction={() => navigateToAskFormat(searchText)}
            />
          </S.CenterContainer>
        )}
      </S.OrderFormatRow>
      {filteredFormats.length ? (
        <>
          <S.RequestInfo>
            {formatTextColor(__('MagicOrders.ask'), colors.blue1, () => setGlobalState({ tabSelected: 'ask-format' }))}
          </S.RequestInfo>
          <S.ButtonHow
            iconName="Info"
            type="link"
            iconSize="22px"
            onClick={() => setGlobalState({ hideOrdersMagicZero: false })}
          >
            {__('MagicOrders.how')}
          </S.ButtonHow>
        </>
      ) : null}
    </>
  );
};

export default FormatsList;

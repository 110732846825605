import styled from '../../../../../../../styled-components';
import { Button, FontIcon, RowContainer, TagBubble, TextLabel, Tooltip as TooltipAtom } from '../../../../../../atoms';

import type { ITheme } from '../../../../../../../styled-components';
import type { ORDER_STATUS } from 'common-services';

interface IStyledProps {
  theme: ITheme;
  margin?: string;
  enabled?: boolean;
  color?: string;
  status?: ORDER_STATUS;
}

export const TextBlack = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  display: inline;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const Text = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.color || p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  text-transform: initial;
  width: auto;
  display: flex;
`;

export const Tag = styled(TagBubble)`
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const CourseTag = styled(Tag)`
  background-color: ${(p: IStyledProps) => p.theme.colors.green3} !important;
  color: ${(p: IStyledProps) => p.theme.colors.white} !important;

  span {
    color: ${(p: IStyledProps) => p.theme.colors.white};
  }
`;

export const TagContainer = styled(RowContainer)`
  align-items: center;
  flex-wrap: wrap;
`;

export const Shortcuts = styled(RowContainer)`
  align-items: center;
  flex-wrap: wrap;
`;

export const CommentsButton = styled(Button)`
  padding: 0;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  min-width: 0;
`;

export const UpdatesButton = styled(CommentsButton)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  border-left: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export const Tooltip = styled(TooltipAtom)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;

export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 11px;
  height: 11px;
  width: 11px;
`;

export const EditLink = styled(FontIcon)`
  color: inherit;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const IssueText = styled(TextLabel)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
  display: flex;
  cursor: pointer;
`;

export const SectionTitle = styled.h2`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  font-weight: ${({ theme }) => theme.fontWeight.Bold};
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const Row = styled(RowContainer)`
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

import styled, { css } from '../../../styled-components';
import TextLabel from '../TextLabel';

import type { ITheme } from '../../../styled-components';

interface IStyledProps {
  disabled?: boolean;
  isDisabled?: boolean;
  margin?: string;
  selectable?: boolean;
  theme: ITheme;
}

export const SwitchLabel = styled.label`
  margin: ${(p: IStyledProps) => p.margin || '0'};
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  border-radius: 25px;

  ${(p: IStyledProps) =>
    p.selectable &&
    !p.isDisabled &&
    css`
      &:focus-within {
        input + span:before {
          display: block;
        }
      }
    `}

  input:checked + span {
    border: 1px solid ${(p: IStyledProps) => (p.isDisabled ? p.theme.colors.green2 : p.theme.colors.green1)};
    background-color: ${(p: IStyledProps) => (p.isDisabled ? p.theme.colors.green2 : p.theme.colors.green1)};
  }

  input:checked + span:before {
    transform: translateX(16px);
  }

  input:checked + span:after {
    transform: translateX(16px);
  }
`;

export const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const SwitchSlider = styled.span`
  position: absolute;
  cursor: ${(p: IStyledProps) => (p.isDisabled ? 'not-allowed' : 'pointer')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid ${(p: IStyledProps) => (p.isDisabled ? p.theme.colors.grey3 : p.theme.colors.grey2)};
  background-color: ${(p: IStyledProps) => (p.isDisabled ? p.theme.colors.grey3 : p.theme.colors.grey2)};
  transition: 0.4s;
  border-radius: 25px;
  min-width: 36px;

  &:after {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: 1px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  &:before {
    position: absolute;
    content: '';
    height: 27px;
    width: 27px;
    left: -5px;
    bottom: -4px;
    background-color: ${(p: IStyledProps) => p.theme.colors.black};
    border-radius: 50%;
    transition: 0.4s;
    opacity: 0.3;
    display: none;
  }
`;

export const ReadOnly = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-family: inherit;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  flex: 1;
  background: inherit;
  position: relative;
  justify-content: flex-start;
`;

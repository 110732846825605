import { __, currency as currencyServices } from 'common-services';
import * as React from 'react';

import * as S from '../CustomItems.styled';

import type { ColumnConditionProps, ColumnOptionsProps, ColumnTitleProps } from './_props';
import type { ColumnSpec, ColumnOptions } from '../../../../../../../molecules/Table';
import type { ICustomItem } from 'common-services';

type CustomItemsOptions = ColumnOptions<ICustomItem, ICustomItem, ICustomItem>;

export type CustomItemsSpec = ColumnSpec<
  CustomItemsOptions,
  ColumnTitleProps,
  ColumnConditionProps,
  ColumnOptionsProps,
  ICustomItem,
  ICustomItem,
  ICustomItem
>;

export const columnSpecs: Array<CustomItemsSpec> = [
  {
    id: 'close',
    isVisible: true,
    getTitle: () => '',
    width: '30px',
    condition: ({ canEdit }) => canEdit,
    columnValue: ({ customItemDelete }) => ({
      getElement: (item, index) => (
        <S.FlexRow>
          <S.DeleteIcon name="Close" onClick={() => customItemDelete(item, index)} />
        </S.FlexRow>
      ),
    }),
  },
  {
    id: 'description',
    isVisible: true,
    getTitle: () => __('Components.Cart.custom_items.description'),
    width: '80%',
    columnValue: () => ({
      getElement: item => (
        <S.FlexRow>
          <S.Text>{item.title}</S.Text>
        </S.FlexRow>
      ),
    }),
  },
  {
    id: 'cost',
    isVisible: true,
    getTitle: () => __('Components.Cart.custom_items.value'),
    width: '20%',
    align: 'center',
    columnValue: ({ order, priceMode, setSelectedCustomItem, canEdit }) => ({
      getElement: item => (
        <S.ValueRow>
          <S.Text>
            {item.price && priceMode !== 'none'
              ? currencyServices.getPrice(order?.currency, item.price, order?.pricePrecision)
              : '-'}
          </S.Text>
          {canEdit && (
            <S.EditContainer>
              <S.EditIcon name="Edit" disableHover={true} onClick={() => setSelectedCustomItem(item)} />
            </S.EditContainer>
          )}
        </S.ValueRow>
      ),
    }),
  },
];

import styled from '../../../../../../../../../styled-components';
import { Button, FontIcon, RowContainer, SimpleDropdown, TextLabel } from '../../../../../../../../atoms';

import type { ITheme } from '../../../../../../../../../styled-components';

interface IStyledProps {
  theme: ITheme;
  align?: string;
  baseMarginPercentage?: number;
  enabled?: boolean;
  hasError?: boolean;
  hasServedChange?: boolean;
  isCancelled?: boolean;
  isChecked?: boolean;
  isClickable?: boolean;
  isIssue?: boolean;
  margin?: string;
  marginPercentage?: number;
  rightPosition?: string;
  servedQuantity?: boolean;
  showGrey?: boolean;
  width?: string;
  disabled?: boolean;
}

export const Text = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  width: 100%;
`;

export const FavoriteContainer = styled(RowContainer)`
  margin: 10px ${(p: IStyledProps) => p.theme.paddingSize(3)};
  align-items: center;
  justify-content: flex-start;
`;

export const TableHeadWrapper = styled.div`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const LeftRow = styled(RowContainer)`
  align-items: center;
`;

export const SortByDropdown = styled(SimpleDropdown)`
  align-self: flex-start;
`;

const ButtonGroupItem = styled(Button)`
  min-width: 0;
  padding-left: 0;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const SortButton = styled(ButtonGroupItem)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export const SortBlackText = styled(Text)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const SortGreyText = styled(Text)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 16px;
  height: 16px;
  width: 16px;
  line-height: 16px;
`;

import { productService } from 'common-services';
import * as React from 'react';

import type { IOrder, IUser } from 'common-services';

interface UseCleanupProps {
  order?: IOrder;
  previewMode?: boolean;
  weAreBuyer: boolean;
  me: IUser;
  cartClean: (key: string) => void;
  orderDelete: (order: IOrder) => void;
}

const useCleanup = ({ order, previewMode, weAreBuyer, me, cartClean, orderDelete }: UseCleanupProps): void => {
  React.useEffect(() => {
    return () => {
      if (!order || previewMode) return;
      if (order?.id) {
        cartClean(productService.getKey(weAreBuyer ? order?.sellerId : me.id, order?.buyerId));
      } else {
        orderDelete(order);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useCleanup;

import styled from '../../../styled-components';
import { ColumnContainer } from '../../atoms';

export const Container = styled(ColumnContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
`;

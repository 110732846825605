import { GenericProduct, IFeaturedType, IOrderItem, IPrice, IPriceMode, IProduct, parsers } from 'common-services';
import { History } from 'history';
import * as React from 'react';

import { ProductCard as PC } from '../../../../molecules';

interface IProps {
  amSeller: boolean;
  cartItem?: IOrderItem;
  cartUpdateItem: (item: IOrderItem) => void;
  color: string;
  featuredToggle?: (type: IFeaturedType, productHashId: string) => void;
  hideFeatured: boolean;
  history?: History<any>;
  isFavorite: boolean;
  isRecent: boolean;
  isRecommended: boolean;
  isSelected?: boolean;
  isServedFlow: boolean;
  navigateToProduct?: (i: IOrderItem, product: IProduct) => void;
  openShareProduct: (product: GenericProduct, productName: string) => void;
  originLot?: number;
  price: IPrice;
  priceMode: IPriceMode;
  pricePrecision: number;
  product: IProduct;
  productMappingStrategy?: 'ean_code' | 'sku';
  selectMode?: 'integration' | 'multiple' | 'offers';
  showProductReference?: boolean; // SKU or EAN
  showShare: boolean;
}

const ProductCard: React.FC<IProps> = ({
  amSeller,
  cartItem,
  cartUpdateItem,
  color,
  featuredToggle,
  isFavorite,
  isRecent,
  isRecommended,
  isSelected,
  isServedFlow,
  navigateToProduct,
  openShareProduct,
  price,
  priceMode,
  pricePrecision,
  product,
  productMappingStrategy,
  selectMode,
  hideFeatured,
  showShare,
  originLot,
  history,
}) => {
  const productData = { ...product, ...price };
  const data = parsers.productToGenericProduct(productData);
  return (
    <PC
      amount={cartItem ? cartItem.amount : 0}
      amSeller={amSeller}
      onSelectItem={cartUpdateItem}
      color={color}
      data={data}
      featuredToggle={featuredToggle}
      hideFeatured={hideFeatured}
      history={history}
      ipadMargin="140px"
      isFavorite={isFavorite}
      isRecent={isRecent}
      isRecommended={isRecommended}
      isSelected={isSelected}
      isServedFlow={isServedFlow}
      key={'_' + data.productId}
      navigate={item => navigateToProduct && navigateToProduct(item, productData)}
      openShareProduct={openShareProduct}
      orderItemId={cartItem?.id}
      originLot={originLot}
      price={cartItem?.price || data.price}
      priceMode={priceMode === 'edit' ? 'read' : priceMode}
      pricePrecision={pricePrecision}
      productMappingStrategy={productMappingStrategy}
      saleUnit={cartItem?.saleUnit || data.saleUnits[0]}
      selectMode={selectMode}
      servedQuantity={cartItem?.servedQuantity}
      showProductReference={true}
      showShare={showShare}
    />
  );
};

export default React.memo(ProductCard);

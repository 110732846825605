import * as React from 'react';

import FontIcon from '../FontIcon';
import Loading from '../Loading';

import S from './Button.styled';

import type { IButtonType, IIconType } from './Button.styled';
import type { IFontIconKeys } from '../FontIcon';

export interface IComponentProps {
  children?: React.ReactNode;
  onClick?: (e?: React.SyntheticEvent) => undefined | Promise<void> | unknown;
}

export interface IButtonProps {
  className?: string;
  disabled?: boolean;
  iconName?: IFontIconKeys;
  iconSize?: string;
  iconUrl?: string;
  iconType?: IIconType;
  id?: string;
  loading?: boolean;
  type?: IButtonType;
  withoutPadding?: boolean;
  selected?: boolean;
  title?: string;
  badgeCount?: number;
}

export type IProps = IComponentProps & IButtonProps;

const Button: React.FC<IProps> = React.memo(
  ({
    badgeCount,
    children,
    className,
    disabled,
    iconName,
    iconSize,
    iconType,
    iconUrl,
    id,
    loading,
    onClick,
    selected,
    type,
    withoutPadding,
    title = '',
  }) => {
    return (
      <S.Button
        disabled={disabled}
        className={className}
        id={id}
        data-testid={id}
        data-cy={id}
        onClick={e => handleButtonClick(e)}
        buttonType={type}
        selected={selected}
        withoutPadding={withoutPadding}
        title={title}
      >
        {renderIcon()}
        {loading ? <Loading sizeRatio={0.7} /> : children}
      </S.Button>
    );

    /**
     * Render left icon from iconName prop
     */
    function renderIcon(): JSX.Element | null {
      if (iconName) {
        return (
          <S.IconWrapper
            buttonType={type}
            className="button-icon"
            size={iconSize}
            iconType={iconType}
            disabled={disabled}
            badgeCount={badgeCount}
          >
            <FontIcon name={iconName} onClick={() => null} disableHover={true} />
          </S.IconWrapper>
        );
      }
      if (iconUrl) return <S.IconImage size={iconSize} src={iconUrl} />;
      return null;
    }

    /**
     * Handle click.
     * No action performed if disabled or loading.
     */
    function handleButtonClick(e: React.SyntheticEvent): void {
      if (disabled || loading) return;
      if (onClick) onClick(e);
    }
  },
);

Button.displayName = 'Button';

export default Button;

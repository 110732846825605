import { __, modalActions, orderBuyerPreparationActions, utils } from 'common-services';
import { formatLongDate } from 'common-services/dist/utils/date';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { IMAGES } from '../../../assets';
import { ROUTE_PATHS } from '../../../constants';
import getPath from '../../../util/routes';
import { EmptyListResource } from '../../molecules';
import PreparationFilter from '../../molecules/PreparationFilter';
import Workspace from '../Workspace/Workspace.component';

import OrderPreparationCart from './OrderPreparationCard';
import { OrderPreparationContext } from './OrderPreparationContext';
import OrderPreparationTable from './OrderPreparationTable';
import * as S from './WorkspaceOfferToOrderPreparation.styled';

import type { IOrderBuyerPreparation, IOrderBuyerPreparationGroup } from 'common-services';
import type { RouteComponentProps } from 'react-router-dom';
import type { Dispatch } from 'redux';

export type IRouteProps = RouteComponentProps<{
  workspaceId?: string;
}>;

export type IProps = IRouteProps;

const WorkspaceOfferToOrderPreparation: React.FC<IProps> = () => {
  interface OrderPreparationLineFilters {
    types: Array<number>;
    suppliers: Array<number>;
    warehouses: Array<number>;
    comments: boolean | null;
  }
  const dispatch = useDispatch<Dispatch<any>>(); // eslint-disable-line @typescript-eslint/no-explicit-any
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const date = searchParams.get('date');
  const params = useParams<{ workspaceId: string }>();
  const workspaceId = params.workspaceId;

  const [orderBuyerPreparations, setOrderBuyerPreparations] = React.useState<Array<IOrderBuyerPreparation>>(null);
  const [groupedOrderPreparationLines, setGroupedOrderPreparationLines] = React.useState<
    Array<IOrderBuyerPreparationGroup>
  >([]);

  const [showFilters, setShowFilters] = React.useState<boolean>(false);

  // Get initial view from url query param 'view' // TODO: improve this
  const queryParams = new URLSearchParams(location.search);
  const urlView = queryParams.get('view');
  const initialView = ['platform', 'supplier'].includes(urlView) ? urlView : 'platform';

  const [view, setView] = React.useState<'platform' | 'supplier'>(initialView as 'platform' | 'supplier');

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('view', view);
    history.push({ search: searchParams.toString() });
  }, [view, history]);

  const [searchState, setSearchState] = React.useState<OrderPreparationLineFilters>({
    types: [],
    suppliers: [],
    warehouses: [],
    comments: null,
  });

  React.useEffect(() => {
    dispatch(
      orderBuyerPreparationActions.orderBuyerPreparationList(Number(workspaceId), date, res => {
        setOrderBuyerPreparations(res);
      }),
    );
  }, [date, workspaceId, dispatch]);

  React.useEffect(() => {
    if (orderBuyerPreparations && orderBuyerPreparations.length > 0) {
      dispatch(
        // TODO: check if the orderBuyerPreparations[0]?.id is the correct id to use
        orderBuyerPreparationActions.groupedLinesList(
          Number(workspaceId),
          orderBuyerPreparations[0]?.id,
          searchState,
          res => {
            setGroupedOrderPreparationLines(res);
          },
        ),
      );
    }
  }, [workspaceId, orderBuyerPreparations, searchState, dispatch, view]);

  const formattedDate = utils.firstToUpperCase(formatLongDate(date, undefined, undefined, 'EEEE, dd MMM yyyy'));

  function getFiltersCount(): number {
    const { comments, suppliers, types, warehouses } = searchState;
    return (comments !== null ? 1 : 0) + (suppliers?.length || 0) + (types?.length || 0) + (warehouses?.length || 0);
  }
  function deleteLines(lines: Array<number>): void {
    dispatch(
      modalActions.modalOpen(
        __('Components.OffersList.order_preparation.table.delete_lines.title'),
        () => {
          try {
            dispatch(
              orderBuyerPreparationActions.orderBuyerPreparationDeleteLines(
                +workspaceId,
                orderBuyerPreparations[0]?.id,
                lines,
              ),
            );
            dispatch(
              orderBuyerPreparationActions.groupedLinesList(
                Number(workspaceId),
                orderBuyerPreparations[0]?.id,
                searchState,
                res => {
                  setGroupedOrderPreparationLines(res);
                },
              ),
            );
          } catch (error) {
            console.error('Error during deletion:', error);
          }
        },
        {
          showCancelButton: true,
          buttonCancelText: __('Components.OffersList.order_preparation.table.delete_lines.cancel'),
          text2: __('Components.OffersList.order_preparation.table.delete_lines.text'),
          buttonText: __('Components.OffersList.order_preparation.table.delete_lines.cta'),
          icon: IMAGES.informativePineapple,
        },
        'nice',
      ),
    );
  }
  if (!orderBuyerPreparations) {
    return null;
  }
  return (
    <OrderPreparationContext.Provider
      value={{
        orderBuyerPreparationId: orderBuyerPreparations[0]?.id || null,
        groupedOrderPreparationLines,
      }}
    >
      <Workspace
        title={__('Components.OffersList.orderPreparation')}
        subtitle=""
        tabSelected="offers"
        workspaceId={Number(workspaceId)}
        isBuyerWorkspace={true}
        parentSections={[
          {
            label: __('Components.ProductsList.Tabs.offers'),
            action: () => {
              history.push(
                getPath({
                  path: ROUTE_PATHS.WORKSPACE_OFFERS,
                  workspaceId,
                }),
              );
            },
          },
          {
            label: formattedDate,
            action: () => {
              history.push(
                getPath({
                  path: ROUTE_PATHS.WORKSPACE_OFFERS,
                  workspaceId,
                }) + `?date=${date}`,
              );
            },
          },
        ]}
      >
        {orderBuyerPreparations?.length > 0 ? (
          <S.Row>
            {showFilters ? (
              <PreparationFilter
                changeSearchState={v => setSearchState({ ...searchState, ...v })}
                clearFilters={() => {
                  setSearchState({
                    types: [],
                    suppliers: [],
                    warehouses: [],
                    comments: null,
                  });
                }}
                numberOfHeaders={3}
                searchState={searchState as any} // eslint-disable-line @typescript-eslint/no-explicit-any
                onHide={() => null}
                preparations={groupedOrderPreparationLines}
                showClosed={!showFilters}
              />
            ) : null}
            <S.WsWrapper>
              <S.HeaderDashboards>
                <S.HeaderFilters>
                  <S.LeftFiltersRow>
                    <S.FilterButton
                      filtersOpened={showFilters}
                      filtersSelected={getFiltersCount()}
                      onClick={() => setShowFilters(!showFilters)}
                    />
                    <S.SimpleSearch
                      id="search-references"
                      placeHolder={__('Components.Referential.reference.search')}
                      onChange={() => null}
                    />
                  </S.LeftFiltersRow>

                  <S.Switch
                    className="breakdown-filter"
                    keySelected={view}
                    onSelectOption={key => setView(key as 'platform' | 'supplier')}
                    options={[
                      { key: 'platform', value: __('Components.OffersList.order_preparation.by_platform') },
                      { key: 'supplier', value: __('Components.OffersList.order_preparation.by_supplier') },
                    ]}
                  />
                </S.HeaderFilters>
                <S.GreyText>{__('Components.OffersList.order_preparation.saved')}</S.GreyText>
              </S.HeaderDashboards>

              {view === 'platform' && (
                <S.TitleText>
                  {
                    utils.formatText(__('Components.OffersList.order_preparation.date', { date: formattedDate }), s => (
                      <S.TitleTextBold key={s}>{s}</S.TitleTextBold>
                    )) as any // eslint-disable-line @typescript-eslint/no-explicit-any
                  }
                </S.TitleText>
              )}
              {view === 'platform' ? (
                <OrderPreparationTable
                  // orderBuyerPreparationId={orderBuyerPreparations[0]?.id}
                  // groupedOrderPreparationLines={groupedOrderPreparationLines}
                  workspaceId={Number(workspaceId)}
                  deleteLines={deleteLines}
                />
              ) : (
                <OrderPreparationCart
                  workspaceId={Number(workspaceId)}
                  deliveryDate={formattedDate}
                  changeTab={() => setView('platform')}
                />
              )}
            </S.WsWrapper>
          </S.Row>
        ) : (
          <EmptyListResource
            text={__('Components.OffersList.order_preparation.zero_case_title')}
            text2={__('Components.OffersList.order_preparation.zero_case_text')}
            imageUrl="https://media.consentio.co/image/upload/v1641830463/ilustrations/Wholesale.png"
            showButton={true}
            buttonText={__('Components.OffersList.order_preparation.cta')}
          />
        )}
      </Workspace>
    </OrderPreparationContext.Provider>
  );
};

export default React.memo(WorkspaceOfferToOrderPreparation);

import styled from 'styled-components';

import { FontIcon, RowContainer, TextLabel } from '../../../atoms';

import type { ITheme } from '../../../../styled-components';

interface IStyledProps {
  disabled?: boolean;
  theme: ITheme;
  toggleCantRepeatNorDraftNotHistory?: number;
}

export const Container = styled.div`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  height: auto;
  overflow: auto;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  -webkit-overflow-scrolling: touch;
`;

export const Text = styled(TextLabel)`
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;
export const TextHeader = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
`;
export const AddressIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
export const NameText = styled(TextBlack)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;
export const IconWrapper = styled.div`
  width: auto;
  padding: 0 3px;
  font-size: 15px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  align-items: center;
  display: flex;
  top: 0;
  align-self: center;
`;
export const RemoveIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const TableOrders = styled.table`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-collapse: separate;
  border-spacing: 0 5px;
  width: 100%;
`;

export const Body = styled.tbody`
  height: 100%;
`;
// Generic styles for each column (header + rows)

export const Col = styled.td`
  vertical-align: middle;
  padding-right: ${(p: IStyledProps) => p.theme.paddingObject};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  min-width: 70px;
  max-width: 170px;
`;
export const ClosedCol = styled.td`
  vertical-align: middle;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  width: 140px;
`;

export const CenterContainer = styled(RowContainer)`
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
`;

export const BoldCol = styled(Col)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;

export const SubTotalCol = styled(Col)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
`;
export const AutoCol = styled(Col)`
  width: auto;
`;

export const ItemsPanel = styled.div`
  width: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  justify-items: center;
`;

export const SubTotalTr = styled.tr`
  height: 56px;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow: hidden;
  border: none;
`;

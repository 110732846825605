import { __ } from 'common-services';
import * as React from 'react';

import { Input } from '../../../../../atoms';
import { ActionsModal } from '../../../../../molecules';
import { useOrderContext } from '../../../context';

import * as S from './BoxesPerPalletModal.styled';

const BoxesPerPalletModal: React.FC = () => {
  const { selectedItem, closeBoxesPerPalletModal: onClose, onSubmitBoxesPerPalletModal: onSubmit } = useOrderContext();

  const [boxesValue, setBoxesValue] = React.useState<string>('');

  const handleSubmit = (): void => {
    if (boxesValue) {
      onSubmit(selectedItem, Number(boxesValue));
      handleClose();
    }
  };

  const handleClose = (): void => {
    setBoxesValue('');
    onClose();
  };

  return (
    <ActionsModal
      minHeight="300px"
      width="500px"
      onClose={handleClose}
      title={__('Components.Cart.boxesPerPallet.modal.title')}
      subtitle={__('Components.Cart.boxesPerPallet.modal.description')}
      overFlowVisible={true}
    >
      <S.InputTitle>{__('Components.OrderDetails.issues_modal.boxes_per_pallet')}</S.InputTitle>

      <Input
        name="boxes-per-pallet"
        type="number"
        width="100%"
        value={boxesValue}
        onChange={(_, value) => setBoxesValue(String(value))}
        variableTextPlural={__('Components.Cart.boxesPerPallet.modal.placeholder')}
      />

      <S.ModalRow>
        <S.ModalButton type="secondary" onClick={handleClose}>
          {__('Components.OrderDetails.issues_modal.cancel')}
        </S.ModalButton>

        <S.CtaButton disabled={!boxesValue} onClick={handleSubmit}>
          {__('Components.OrderDetails.issues_modal.cta')}
        </S.CtaButton>
      </S.ModalRow>
    </ActionsModal>
  );
};

export default BoxesPerPalletModal;

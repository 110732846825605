import styled from '../../../../../../styled-components';
import { Button, RowContainer, TextLabel } from '../../../../../atoms';

import type { ITheme } from '../../../../../../styled-components';

interface IStyledProps {
  enabled?: boolean;
  disabled?: boolean;
  margin?: string;
  numHeaders?: number;
  theme: ITheme;
}

export const ModalButton = styled(Button)`
  width: 45%;
`;

export const InputTitle = styled(TextLabel)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;
export const ModalRow = styled(RowContainer)`
  justify-content: space-between;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  align-items: center;
`;
export const CtaButton = styled(ModalButton)`
  background-color: ${(p: IStyledProps) => (p.disabled ? p.theme.colors.green2 : p.theme.colors.green1)};
`;

import { __, associateReferenceActionsApi, sellerWorkspaceSelectors, userSelectors } from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../constants';
import getPath from '../../../util/routes';
import { SearchProductModal, SelectClientModal } from '../../molecules';
import Workspace from '../Workspace/Workspace.component';
import { Dispatch } from 'redux';
import ReferenceList from './ReferenceList';
import { IGroupedDataReferenceListPageable } from 'common-services/dist/associate-reference/interface';

export type IProps = RouteComponentProps<{
  workspaceId?: string;
  clientId?: string;
}>;

const WorkspaceReferenceList: React.FC<IProps> = ({
  location,
  match: {
    params: { clientId, workspaceId },
  },
}) => {
  const history = useHistory();
  const me = useSelector(userSelectors.getUser);
  const catalog = useSelector(sellerWorkspaceSelectors.getCatalog(Number(workspaceId)));

  const [associateReferenceList, setAssociateReferenceList] = React.useState<IGroupedDataReferenceListPageable>({
    elements: [],
    is_first_page: true,
    is_last_page: false,
    page: 1,
    total_pages: 0,
    total_elements: 0,
  });
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  const [showClientsModal, setShowClientsModal] = React.useState(false);
  const dispatch = useDispatch<Dispatch<any>>();
  const [showSearchProductModal, setShowSearchProductModal] = React.useState(false);
  const [contactId, setContactId] = React.useState<number>(0);

  React.useEffect(() => {
    if (workspaceId) {
      setLoading(true);
      dispatch(
        associateReferenceActionsApi.getAssociateReferences(
          workspaceId,
          res => {
            setAssociateReferenceList(prevState => ({
              ...res,
              elements: [
                ...prevState.elements,
                ...res.elements.filter(
                  newElement => !prevState.elements.some(existingElement => existingElement.sku === newElement.sku),
                ),
              ],
            }));
            setLoading(false);
          },
          {
            page,
            offset: 0,
            order: 'desc',
            size: 50,
            sort_by: 'created_at',
          },
        ),
      );
    }
  }, [workspaceId, page]);

  const handleLoadMore = () => {
    if (!loading && !associateReferenceList.is_last_page) {
      setPage(prevPage => prevPage + 1);
    }
  };

  return (
    <Workspace
      subtitle={''}
      title={__('Components.ProductsList.Tabs.referenceList')}
      tabSelected={'offers'}
      workspaceId={Number(workspaceId)}
      isBuyerWorkspace={false}
      parentSections={[
        {
          label: __('Components.ProductsList.Tabs.offers'),
          action: () => {
            history.push(
              getPath({
                path: ROUTE_PATHS.WORKSPACE_OFFERS,
                workspaceId,
              }),
            );
          },
        },
      ]}
    >
      <ReferenceList
        associateReferenceList={associateReferenceList.elements || []}
        setShowClientsModal={setShowClientsModal}
        onLoadMore={handleLoadMore}
      />
      {showClientsModal ? openSelectClientModal() : null}
      {showSearchProductModal ? openSearchProductsModal() : null}
    </Workspace>
  );

  function openSelectClientModal() {
    return (
      <SelectClientModal
        title={__('Components.SellerOffer.modal.title')}
        subtitle={__('Components.SellerOffer.modal.subtitle')}
        onClose={() => setShowClientsModal(false)}
        isPurchase={false}
        selectContact={(contactId: number) => {
          setContactId(contactId);
          setShowClientsModal(false);
          setShowSearchProductModal(true);
        }}
        catalogId={Number(workspaceId)}
        me={me}
      />
    );
  }
  function openSearchProductsModal() {
    return (
      <SearchProductModal
        amSeller={true}
        cartClean={undefined}
        catalog={catalog}
        close={() => setShowSearchProductModal(false)}
        contactId={Number(clientId)}
        featured={{}}
        me={me}
        name={''}
        originLot={1}
        onSubmitSelectedProducts={p => {
          history.push(
            getPath({
              path: ROUTE_PATHS.WORKSPACE_OFFER_EDIT,
              workspaceId,
              offerId: 'new',
            }),
            {
              products: p,
              clientId: contactId,
            },
          );
        }}
        priceMode={'read'}
        selectMode="offers"
        showFeatured={false}
        status="active"
        prices={{}}
      />
    );
  }
};

export default WorkspaceReferenceList;

import * as React from 'react';

import {
  ScrollSection,
  useDownload,
  useOrderActions,
  useOrderComments,
  useOrderIssues,
  useOrderItems,
  useOrderModals,
  useOrderState,
  useOrderUpdates,
  useScrollToSection,
} from '../hooks';

import type { OrderContextValue, OrderProviderProps } from './OrderContext.types';
import type { CommentSectionType } from '../hooks/useOrderComments';

export const OrderContext = React.createContext<OrderContextValue | null>(null);

export const OrderProvider: React.FC<OrderProviderProps> = ({ children, ...props }) => {
  const contextProps = {
    ...props,
    clients: props.clientsOld, // that's a trick just to avoid using ugly `clientsOld` in the code
  };
  const orderState = useOrderState();
  const orderActions = useOrderActions(contextProps);
  const orderItems = useOrderItems({
    ...contextProps,
    ...orderState,
    orderItemFixIssue: orderActions.debouncedOrderItemFixIssue,
    orderFixIssue: orderActions.debouncedOrderFixIssue,
  });
  const orderComments = useOrderComments(contextProps);
  const orderModals = useOrderModals({
    ...contextProps,
    ...orderState,
    handleItemUpdate: orderItems.handleItemUpdate,
    handleBoxesPerPalletChange: orderItems.handleBoxesPerPalletChange,
  });
  const orderUpdates = useOrderUpdates({
    ...contextProps,
    ...orderState,
    setShowReferenceErrorTooltip: orderModals.setShowReferenceErrorTooltip,
    orderItemFixIssue: orderActions.debouncedOrderItemFixIssue,
  });
  const orderIssues = useOrderIssues({ ...contextProps, ...orderState });
  const orderDownload = useDownload(contextProps);
  const commentsScroll = useScrollToSection({
    sectionId: ScrollSection.COMMENTS,
    callback: (section: CommentSectionType) => orderComments.setCommentsSection(section),
    deps: [],
  });

  // const debugCartSet: typeof actions.cartSet = (
  //   key: string,
  //   order: IOrder,
  //   contactId: number,
  // ): {
  //   key: string;
  //   order: IOrder;
  //   contactId: number;
  //   type: CART.SET;
  // } => {
  //   return props.cartSet(key, order, contactId);
  // };

  // Memoize the context value to prevent unnecessary re-renders
  const value = React.useMemo(
    () => ({
      ...props,
      ...orderState,
      ...orderActions,
      ...orderUpdates,
      ...orderItems,
      ...orderIssues,
      ...orderComments,
      ...orderModals,
      ...orderDownload,
      commentsScroll: {
        ...commentsScroll,
      },
      // cartSet: debugCartSet,
    }),
    [
      props,
      orderState,
      orderActions,
      orderUpdates,
      orderItems,
      orderIssues,
      orderComments,
      orderModals,
      commentsScroll,
      orderDownload,
    ],
  );

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
};

import { __, currency } from 'common-services';
import * as React from 'react';

import DebugModal from '../../../../../../../../atoms/Debug/DebugModal.component';

import * as S from './PriceDisplay.styled';

import type { CURRENCY_CODES, IOrder, IPriceMode } from 'common-services';

export interface PriceDisplayProps {
  /** Mode */
  mode: 'header' | 'footer';
  /** Total price formatted as string */
  totalPrice: string;
  /** Optional margin information */
  totalMargin?: {
    margin: string;
    marginPercentage: string;
  };
  /** Price display mode */
  priceMode: IPriceMode;
  /** Current order */
  order: IOrder;
  /** Whether to hide the total */
  hideTotal?: boolean;
  /** Whether in prepare mode */
  isPrepare?: boolean;
  /** Shipping cost information */
  shipping?: {
    cost: number;
    currency: CURRENCY_CODES;
  };
  /** Price precision for formatting */
  pricePrecision: number;
  isBuyerOfferingView?: boolean; // TODO: this is a temporary fix for isBuyerOfferingView
}

/**
 * PriceDisplay component shows total price, margins, and shipping costs
 */
export const PriceDisplay = React.memo<PriceDisplayProps>(
  ({
    mode,
    totalPrice,
    totalMargin,
    priceMode,
    order,
    hideTotal = false,
    isPrepare = false,
    shipping,
    pricePrecision,
    isBuyerOfferingView,
  }) => {
    /**
     * Renders price title section with optional margin
     */
    const renderPriceTitle = (): JSX.Element | null => {
      if ((priceMode === 'none' && !isBuyerOfferingView) || !order?.price) {
        // TODO: this is a temporary fix for isBuyerOfferingView
        return null;
      }

      const hasPendingIssues = order?.issues.some(i => i.status === 'pending' || i.status === 'unknown');

      return (
        <S.PriceColumn data-testid="price-column">
          <S.PriceSpan>
            <S.PriceText>{__('Components.Cart.total')}</S.PriceText>
            <S.TotalResumePrice>{totalPrice}</S.TotalResumePrice>
          </S.PriceSpan>

          {totalMargin && totalMargin.margin !== '-' && !hasPendingIssues && (
            <S.PriceSpan>
              <S.MarginText>
                {__('Components.Cart.items.margin') + ': ' + totalMargin.margin}
                <S.MarginTextPercentage>{` (${totalMargin.marginPercentage})`}</S.MarginTextPercentage>
              </S.MarginText>
            </S.PriceSpan>
          )}
        </S.PriceColumn>
      );
    };

    /**
     * Renders shipping cost section if applicable
     */
    const renderShippingCost = (): JSX.Element | null => {
      if (!shipping || shipping.cost <= 0) {
        return null;
      }

      return (
        <S.FooterRow data-testid="shipping-row">
          <S.TotalSpan>
            <S.PriceText>{__('Components.OrderDetails.transport.transport_cost')}</S.PriceText>
          </S.TotalSpan>
          <S.PriceSpan>
            <S.ResumePrice>{currency.getPrice(shipping.currency, shipping.cost, pricePrecision)}</S.ResumePrice>
          </S.PriceSpan>
        </S.FooterRow>
      );
    };

    // Don't render anything if hiding total or in prepare mode
    if (hideTotal || isPrepare) {
      return null;
    }

    return (
      <>
        {mode === 'header' && <S.TotalPriceRow data-testid="total-price-row">{renderPriceTitle()}</S.TotalPriceRow>}
        {mode === 'footer' && (
          <>
            {renderShippingCost()}
            <S.PriceFooter data-testid="price-footer">{renderPriceTitle()}</S.PriceFooter>
          </>
        )}
      </>
    );
  },
);

PriceDisplay.displayName = 'PriceDisplay';

import { __, currency, orderService, orderUtils } from 'common-services';
import * as React from 'react';

import config from '../../../../../../../../../bindings/config';
import { priceUnitTranslate } from '../../../../../../../../util/utils';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'margin',
  rank: 1005,
  isVisible: true,
  getTitle: () => __('Components.Cart.items.margin'),
  minWidth: '80px',
  width: '9%',
  condition: ({ isQuoterMode }): boolean => isQuoterMode,
  columnValue: ({ order, pricePrecision }) => ({
    getElement: item => {
      const { margin, marginPercentage, totalItemMargin } = orderService.getProductItemMargin(item);
      const issuesRelated = order?.issues.filter(issue => issue.orderItemId === item.id);
      const hasNotFoundIssue = !!issuesRelated?.find(i =>
        ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
      );
      const isDiscarded = item.servedQuantity === 0;
      return (
        <S.PriceColumn>
          <S.ItemPrice showGrey={hasNotFoundIssue || isDiscarded || orderUtils.isCanceled(order)}>
            {margin
              ? currency.getPricePerUnit(
                  item.currency,
                  config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId)
                    ? priceUnitTranslate(item.priceUnit)
                    : item.priceUnit,
                  item.weightUnit,
                  margin,
                  pricePrecision,
                )
              : '-'}
          </S.ItemPrice>
          {totalItemMargin ? (
            <S.ItemPrice showGrey={hasNotFoundIssue || isDiscarded || orderUtils.isCanceled(order)}>
              {currency.getPrice(item.currency, totalItemMargin, pricePrecision) + ' '}
              <S.MarginPercentage
                baseMarginPercentage={item.baseMarginPercentage}
                marginPercentage={marginPercentage}
              >{`(${marginPercentage.toFixed(2)}%)`}</S.MarginPercentage>
            </S.ItemPrice>
          ) : null}
        </S.PriceColumn>
      );
    },
  }),
};

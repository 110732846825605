import { column as deleteColumn } from './-1.delete';
import { column as productColumn } from './0.product';
import { column as orderedQuantityColumn } from './1000.orderedQuantity';
import { column as servedQuantityColumn } from './1000.servedQuantity';
import { column as quantityColumn } from './1001.quantity';
import { column as costColumn } from './1002.cost';
import { column as unitPriceColumn } from './1003.unitPrice';
import { column as priceColumn } from './1004.price';
import { column as marginColumn } from './1005.margin';
import { column as statusColumn } from './1006.status';
import { column as skuColumn } from './2.sku';
import { column as actionsColumn } from './2000.actions';
import { column as boxesPerPalletColumn } from './boxesPerPallet';
import { column as boxWeightColumn } from './boxWeight';
import { column as brandColumn } from './brand';
import { column as categoryColumn } from './category';
import { column as codeColumn } from './code';
import { column as contractsColumn } from './contracts';
import { column as debugColumn } from './debug';
import { column as deliveryPriceColumn } from './deliveryPrice';
import { column as departurePriceColumn } from './departurePrice';
import { column as eanColumn } from './ean';
import { column as imagesColumn } from './images';
import { column as loadColumn } from './load';
import { column as originColumn } from './origin';
import { column as packagingColumn } from './packaging';
import { column as referenceColumn } from './reference';
import { column as referenceSellerColumn } from './referenceSeller';
import { column as regionColumn } from './region';
import { column as sizeColumn } from './size';
import { column as totalPriceColumn } from './totalPrice';
import { column as typeOfProductionColumn } from './typeOfProduction';
import { column as unitPerBoxColumn } from './unitPerBox';
import { column as updatedColumn } from './updated';
import { column as varietyColumn } from './variety';
import { column as warehouseColumn } from './warehouse';
import { column as weightColumn } from './weight';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const defaultColumnSpecs: Array<OrderItemColumnSpec> = [
  // debugColumn,
  deleteColumn,
  productColumn,
  weightColumn,
  categoryColumn,
  varietyColumn,
  unitPerBoxColumn,
  boxWeightColumn,
  boxesPerPalletColumn,
  contractsColumn,
  sizeColumn,
  regionColumn,
  brandColumn,
  originColumn,
  eanColumn,
  imagesColumn,
  warehouseColumn,
  loadColumn,
  actionsColumn,
  costColumn,
  marginColumn,
  priceColumn,
  unitPriceColumn,
  quantityColumn,
  statusColumn,
  servedQuantityColumn,
  orderedQuantityColumn,
  packagingColumn,
  updatedColumn,
  typeOfProductionColumn,
  skuColumn,
];
export const buyerOfferingColumnSpecs: Array<OrderItemColumnSpec> = [
  debugColumn,
  codeColumn,
  referenceColumn,
  originColumn,
  quantityColumn,
  boxesPerPalletColumn,
  departurePriceColumn,
  deliveryPriceColumn,
  totalPriceColumn,
];
export const sellerOfferingColumnSpecs: Array<OrderItemColumnSpec> = [
  debugColumn,
  productColumn,
  skuColumn,
  referenceSellerColumn,
  originColumn,
  quantityColumn,
  boxesPerPalletColumn,
  departurePriceColumn,
  deliveryPriceColumn,
  totalPriceColumn,
];

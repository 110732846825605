import { __ } from 'common-services';
import * as React from 'react';

import { CheckBox } from '../../../../../../../atoms';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'status',
  rank: 1006,
  isVisible: true,
  getTitle: () => __('Components.Cart.items.prepare_title'),
  onClick: e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  },
  minWidth: '140px',
  width: '12%',
  align: 'left',
  condition: ({ isPrepare, editAfterAccept }) => isPrepare && !editAfterAccept,
  columnValue: ({ me, onUpdateItem, orderUpdatePrepared }) => ({
    getElement: item => {
      return (
        <S.AmountRow onClick={e => e.stopPropagation()}>
          <S.Option>
            <CheckBox
              isChecked={item.isPrepared}
              onClick={val => {
                orderUpdatePrepared(me.id, item.orderId, item.id, item.catalogId, item.servedWeight, val, () => {
                  onUpdateItem({ ...item, isPrepared: val });
                });
              }}
              size={20}
            />
            <S.OptionText isChecked={item.isPrepared}>
              {item.isPrepared ? __('Components.Cart.items.prepared') : __('Components.Cart.items.prepare')}
            </S.OptionText>
          </S.Option>
        </S.AmountRow>
      );
    },
  }),
};

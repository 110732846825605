import { __, chatActions, chatService, date, modalActions, throttle, userSelectors, utils } from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import theme from '../../../theme';
import {
  PostReactionActions,
  PostReactionsPreview,
  PostReactionsSummary,
} from '../PostMessage/Fragments/Reactions.component';

import * as S from './PostComment.styled';

import type { IReduxState } from '../../../reducers';
import type { IItem } from '../../atoms/SimpleDropdown/SimpleDropdown.component';
import type { Dispatch } from 'redux';

interface IProps {
  avatar: string;
  avatarColor: IAvatarColor;
  className?: string;
  companyName: string;
  lang?: LOCALE;
  message: IMessage;
  name: string;
  offlineAction?: () => void;
  offlineMode: boolean;
}

const PostComment = ({
  avatar,
  avatarColor,
  className,
  companyName,
  lang,
  message,
  name,
  offlineMode,
  offlineAction,
}: IProps) => {
  const me = useSelector(userSelectors.getUser);
  const dispatch = useDispatch<Dispatch<any>>();
  const { createdAt, isEdited, messageId, parentMessageId, senderId } = message;
  const parentMessage = useSelector((state: IReduxState) =>
    state.chat.messages[message.channelId]?.find(m => m.messageId === parentMessageId),
  );

  const isPostAuthor = parentMessage ? senderId === parentMessage.senderId : false;
  const amSender = senderId === me.id;

  const [seeMore, setSeeMore] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [showSeeMore, setShowSeeMore] = React.useState(false);
  const [textEdited, setTextEdited] = React.useState(message.message);
  const textRef = React.useRef<HTMLSpanElement>();

  React.useEffect(() => {
    if (textRef.current?.clientHeight > 66) {
      setShowSeeMore(true);
    }
  }, []);

  const text = message.message;
  const showMoreLink = showSeeMore && !seeMore;

  return (
    <S.Container className={className}>
      <S.Header>
        <S.Avatar text={name} img={avatar || ''} avatarColor={avatarColor} size={27} />
        <S.HeaderContent>
          <S.RowName>
            <S.TextName>{name}</S.TextName>
            {isPostAuthor ? <S.RoleBubble>{__('ChatPublic.author', { locale: lang })}</S.RoleBubble> : null}
          </S.RowName>
          <S.TextSubtitle>
            {`${companyName ? companyName + ' · ' : ''}${date.getCommentDate(createdAt, me.settings.hourFormat)}${
              isEdited ? ' · ' + __('ChatPublic.edited', { locale: lang }) : ''
            } `}
          </S.TextSubtitle>
        </S.HeaderContent>
      </S.Header>

      {text && !editMode ? (
        <S.ContentBody>
          <S.TextRegular showSeeMore={showSeeMore} seeMore={seeMore} ref={textRef}>
            {utils.splitTextUrls(text).map((t, i) => {
              if (!t.text) return <br key={i + ''} />;
              if (t.isLink) {
                const { url } = utils.analyzeUrl(t.text);
                return (
                  <S.Link key={i + url} href={url} target="_blank">
                    {url}
                  </S.Link>
                );
              }

              return t.text.split(/\B(#[a-zA-Z0-9_-À-ÖØ-öø-ÿ]+)\b/).map((ts, index) => {
                if (ts.match(/(#[a-zA-Z0-9_-À-ÖØ-öø-ÿ])/))
                  return (
                    <S.TextBold key={ts + '_' + index} display="inline">
                      {ts}
                    </S.TextBold>
                  );
                return utils.formatText(ts, (s: string) => (
                  <S.TextBold key={s} display="inline">
                    {s}
                  </S.TextBold>
                ));
              });
            })}
          </S.TextRegular>
        </S.ContentBody>
      ) : null}
      {editMode ? (
        <S.EditInputContainer>
          <S.TextAreaInput
            name={'editComment-' + messageId}
            value={textEdited}
            onChange={(n, val) => setTextEdited(val + '')}
            showCharactersCount={false}
            rows={4}
          />
          <S.EditActionsContainer>
            <S.EditAction
              type="link"
              onClick={() => {
                dispatch(
                  chatActions.editMessage(me.id, {
                    ...message,
                    message: textEdited.trim(),
                  }),
                );
                setEditMode(false);
              }}
              disabled={textEdited === text || !textEdited}
              withoutPadding={true}
            >
              {__('ChatPublic.save', { locale: lang })}
            </S.EditAction>
            <S.EditAction type="skip" onClick={() => setEditMode(false)} withoutPadding={true}>
              {__('ChatPublic.cancel', { locale: lang })}
            </S.EditAction>
          </S.EditActionsContainer>
        </S.EditInputContainer>
      ) : null}

      <S.BottomActions>
        {editMode ? null : (
          <CommentReactions
            className="comment-reactions"
            lang={lang}
            me={me}
            message={message}
            offlineAction={offlineAction}
            offlineMode={offlineMode}
          />
        )}
        {showMoreLink && !editMode ? (
          <S.SeeMoreContainer>
            <S.TextLink onClick={() => setSeeMore(!seeMore)}>
              {(seeMore
                ? __('ChatPublic.see_less', { locale: lang })
                : __('ChatPublic.see_more', { locale: lang })
              ).replace('... ', '')}
            </S.TextLink>
          </S.SeeMoreContainer>
        ) : null}
      </S.BottomActions>

      {amSender ? (
        <S.OptionsDropDown
          onSelect={(key: string) => {
            if (key === 'delete') {
              dispatch(
                modalActions.modalOpen(
                  __('ChatPublic.comment_delete_modal.title', { locale: lang }),
                  () => {
                    dispatch(chatActions.deleteMessage(me.id, message));
                    dispatch(modalActions.modalClose());
                  },
                  {
                    showCancelButton: true,
                    actionType: 'dangerous',
                    text2: __('ChatPublic.comment_delete_modal.description', { locale: lang }),
                    buttonText: __('ChatPublic.comment_delete_modal.cta', { locale: lang }),
                  },
                  'nice',
                ),
              );
            }
            if (key === 'edit') {
              setEditMode(true);
            }
          }}
          options={
            offlineMode
              ? []
              : ([
                  { key: 'edit', value: __('ChatPublic.comment_options.edit', { locale: lang }), icon: 'Edit' },
                  {
                    key: 'delete',
                    value: __('ChatPublic.comment_options.delete', { locale: lang }),
                    icon: 'Trash',
                    color: theme.colors.red1,
                  },
                ] as Array<IItem>)
          }
        >
          <S.KebabIcon name="Kebab" />
        </S.OptionsDropDown>
      ) : null}
    </S.Container>
  );
};

/**
 * Reactions to a comment
 */
const CommentReactions = ({ className, lang, me, message, offlineMode, offlineAction }) => {
  const dispatch = useDispatch<Dispatch<any>>();

  let currentReaction: PostReaction;
  let totalReactions = 0;
  Object.keys(message.reactions).forEach(key => {
    if (message.reactions[key].includes(me.id + '')) {
      currentReaction = key as PostReaction;
    }
    totalReactions += message.reactions[key].length;
  });

  const addReaction = React.useCallback(
    throttle((reaction: PostReaction) => {
      if (currentReaction || currentReaction === reaction) {
        dispatch(
          chatActions.removeMessageReaction(
            me.id,
            message.channelId,
            message.messageId,
            currentReaction,
            message.parentMessageId,
          ),
        );
      }
      if (currentReaction !== reaction) {
        dispatch(
          chatActions.addMessageReaction(
            me.id,
            message.channelId,
            message.messageId,
            reaction,
            message.parentMessageId,
          ),
        );
      }
    }, 500),
    [me, message, dispatch],
  );

  return (
    <S.ReactionsRow className={className}>
      <S.ReactionsPickerRow>
        <S.TextGreySmall
          hasReactions={totalReactions > 0}
          reaction={currentReaction}
          onClick={() => (offlineMode ? offlineAction?.() : addReaction(currentReaction || 'like'))}
        >
          {currentReaction
            ? chatService.getPostReactionLiteral(currentReaction, lang)
            : __('Messages.ChatPublic.reactions.like', { locale: lang })}
        </S.TextGreySmall>
        <S.Popup className="comment-reactions-popup">
          <PostReactionActions className="comment-reactions-picker" addReaction={addReaction} lang={lang} />
        </S.Popup>
      </S.ReactionsPickerRow>
      {totalReactions > 0 ? <S.TextMiddlePoint>{' · '}</S.TextMiddlePoint> : null}
      {totalReactions > 0 ? (
        <S.PreviewContainer>
          <PostReactionsSummary message={message} totalReactions={totalReactions} iconSize={14} />
          <S.PopupPreview className="comment-reactions-popup">
            <PostReactionsPreview message={message} />
          </S.PopupPreview>
        </S.PreviewContainer>
      ) : null}
    </S.ReactionsRow>
  );
};

export default React.memo(PostComment);

import { __, currency, date, orderService, parsers, PRODUCT_UNIT, utils } from 'common-services';
import { getCartLoadSummaryAltUnits, getCartLoadSummaryText } from 'common-services/dist/order/service';
import * as React from 'react';

import config from '../../../../../../bindings/config';
import { AVAILABILITY } from '../../../../../constants';
import { sizes } from '../../../../../theme';
import { priceUnitTranslate } from '../../../../../util/utils';
import { FontIcon, Input, Select } from '../../../../atoms';
import * as SP from '../../ProductInfo.styled';

import * as S from './AddToCart.styled';

import type { GenericProduct, IOrderItem, IPriceMode, modalActions } from 'common-services';

export interface IProps {
  address?: IAddress;
  cartUpdateItem: (item: IOrderItem, stay?: boolean) => void;
  contactName?: string;
  deletable?: boolean;
  forceSetPrice?: boolean;
  from: 'order' | 'product' | 'pricelist';
  isServedFlow: boolean;
  amSeller: boolean;
  item?: IOrderItem;
  disabled?: boolean;
  priceMode?: IPriceMode;
  pricePrecision: number;
  product: GenericProduct;
  isPublic?: boolean;
  modalClose?: typeof modalActions.modalClose;
  modalOpen?: typeof modalActions.modalOpen;
  getCartLoadSummaryText?: typeof orderService.getCartLoadSummaryText;
  sendMessage?: (text: string) => void;
  showInIpad?: boolean;
  typeVariety?: string;
}

const AddToCartComponent: React.FC<IProps> = props => {
  const {
    address,
    cartUpdateItem,
    contactName,
    deletable,
    forceSetPrice,
    from,
    isServedFlow,
    amSeller,
    item: propItem,
    disabled,
    priceMode,
    pricePrecision,
    product,
    isPublic,
    modalClose,
    modalOpen,
    sendMessage,
    showInIpad,
    typeVariety,
  } = props;

  const [item, setItem] = React.useState<IOrderItem>();
  const [priceEdit, setPriceEdit] = React.useState(false);
  const toggleNewSalesUnitsEnable = config.TOGGLE_NEW_SALES_UNITS.organizations.includes(item?.catalogId);

  React.useEffect(() => {
    // Determine whether to enter price edit mode based on certain conditions
    // 1. There's no served price for the item
    // 2. There's no default price for the product
    // 3. The current price mode is set to 'edit'
    // If all these conditions are true, enable price editing
    // console.log('item', item, 'priceMode', priceMode, 'product.price', product.price, 'priceEdit', priceEdit);
    setPriceEdit(!(item?.servedPrice || product.price) && priceMode === 'edit');
  }, [item, priceMode, product.price]);

  React.useEffect(() => {
    if (propItem !== undefined) {
      setItem({ ...propItem, servedQuantity: propItem.servedQuantity || 1 });
    } else {
      setItem(parsers.genericProductToOrderItem(product, product.price, 0, product.baseMarginPercentage, false));
    }
  }, [product, propItem]);

  const [isValid, setIsValid] = React.useState(
    propItem.servedQuantity && (propItem.servedPrice || (!amSeller && !forceSetPrice) || from === 'pricelist'),
  );

  React.useEffect(() => {
    if (item)
      setIsValid(item?.servedQuantity && (item?.servedPrice || (!amSeller && !forceSetPrice) || from === 'pricelist'));
  }, [item]);

  const changeSaleUnit = (key: string, value: any, error?: string) => {
    setItem(prevItem => ({
      ...prevItem,
      saleUnit: value,
      servedSaleUnit: value,
    }));
  };

  const remove = () => {
    setItem(prevItem => ({ ...prevItem, servedQuantity: 0, amount: 0 }));
    cartUpdateItem({ ...item, servedQuantity: 0, amount: 0 });
  };

  const priceToShow = (precision: number) => {
    if (priceMode === 'none') return '-';
    if (item?.servedPrice) {
      return currency.getPrice(item?.currency, item?.servedPrice, precision);
    }
    return 'P.O.R';
  };
  const pToShow = priceToShow(pricePrecision);

  const renderSelectSaleUnit = () => {
    return (
      <Select
        containerMargin="4px 0"
        hasError={false}
        name="saleUnit"
        onChange={changeSaleUnit}
        options={item?.saleUnits.map(el => ({
          value: el,
          label: parsers.getUnitText(el, item?.weightUnit, item?.servedQuantity),
        }))}
        disabled={disabled}
        value={item?.saleUnit || ''}
        width="100px"
        dropUp={true}
        noBorder={typeof window !== 'undefined' ? window.innerWidth <= sizes.mobile : false}
      />
    );
  };

  const renderPrice = () => {
    if (priceEdit) {
      return (
        <S.PriceRow>
          <S.Title>{__('Components.ProductDetails.price.title')}</S.Title>
          <Input
            name="price"
            onBlur={(key: string, value: string, error: string) => {
              const v = Number(value);
              if (!error && v > 0 && v !== item?.servedPrice) {
                setItem({
                  ...item,
                  ...(isServedFlow
                    ? {}
                    : { amount: item?.servedQuantity || 1, servedQuantity: item?.servedQuantity || 1 }),
                  price: isServedFlow ? item?.servedPrice : v,
                  isPor: false,
                  totalPrice: 0,
                  servedPrice: v,
                });
                setPriceEdit(false);
              }
            }}
            value={item?.servedPrice || 0}
            minValue={0}
            variableTextSingular={currency.getPricePerUnit(item?.currency, item?.priceUnit, item?.weightUnit)}
            precision={pricePrecision}
            width="100px"
            type="number"
            textAlign="right"
            autoFocus={true}
            hasError={item?.servedPrice ? false : true}
          />
        </S.PriceRow>
      );
    }
    if (item?.servedPrice) {
      return (
        <S.PriceEditRow
          enabled={priceMode === 'edit' && !disabled}
          onClick={() => priceMode === 'edit' && !disabled && setPriceEdit(true)}
        >
          <S.Price enabled={!!item?.servedPrice}>
            {currency.getPrice(item?.currency, item?.servedPrice, pricePrecision)}
          </S.Price>
          <S.PriceUnit>
            /
            {parsers.getUnitText(
              toggleNewSalesUnitsEnable ? priceUnitTranslate(item?.priceUnit) : item?.priceUnit,
              item?.weightUnit,
              1,
            )}
          </S.PriceUnit>
          {priceMode === 'edit' && !disabled ? (
            <S.IconEditWraper>
              <FontIcon name="Edit" />
            </S.IconEditWraper>
          ) : null}
        </S.PriceEditRow>
      );
    }
    if (priceMode !== 'none') {
      return (
        <S.PricePORRow>
          <S.POR>{__('Components.ProductDetails.por_on')}</S.POR>
          {priceMode === 'edit' && !disabled ? (
            <S.IconEditWraper>
              <FontIcon name="Edit" onClick={() => setPriceEdit(true)} />
            </S.IconEditWraper>
          ) : null}
        </S.PricePORRow>
      );
    }
    return null;
  };

  const renderAmount = (item: IOrderItem) => {
    return (
      <S.AmountRow>
        <S.Row>
          <S.BoldTitle>{__('Components.ProductDetails.amount_title')}</S.BoldTitle>
        </S.Row>
        <S.Row padding="6px 0 0" justifyContent={disabled ? 'flex-start' : 'space-between'}>
          <Input
            name="servedQuantity"
            id="servedQuantity-input"
            onBlur={(key: string, value: string | number, error?: string | undefined) => {
              const servedQuantity =
                propItem?.servedSaleUnit !== PRODUCT_UNIT.KG ? Math.abs(Number(value)) : Number(value);
              setItem({
                ...item,
                ...(isServedFlow ? {} : { amount: servedQuantity }),
                servedQuantity,
                totalPrice: 0,
              });
            }}
            onChange={(key: string, value: string | number, error?: string | undefined) => {
              const servedQuantity =
                propItem?.servedSaleUnit !== PRODUCT_UNIT.KG ? Math.abs(Number(value)) : Number(value);
              setItem({
                ...item,
                ...(isServedFlow ? {} : { amount: servedQuantity }),
                servedQuantity,
                totalPrice: 0,
              });
            }}
            value={item?.servedQuantity || 0}
            minValue={0}
            type="number"
            precision={item?.saleUnit === PRODUCT_UNIT.KG ? 2 : 0}
            autoFocus={!priceEdit}
            textAlign="right"
            width={disabled ? 'min-content' : '100px'}
            disabled={disabled}
          />
          <Select
            containerMargin="4px 0"
            hasError={false}
            name="priceUnit"
            onChange={changeSaleUnit}
            options={item?.saleUnits
              ?.filter(e => (toggleNewSalesUnitsEnable ? e !== PRODUCT_UNIT.KG : e))
              .map(el => ({
                value: el,
                label: parsers.getUnitText(
                  toggleNewSalesUnitsEnable ? priceUnitTranslate(el) : el,
                  item?.weightUnit,
                  item?.servedQuantity,
                ),
              }))}
            disabled={disabled}
            value={item?.saleUnit || ''}
            width="100px"
          />
        </S.Row>
      </S.AmountRow>
    );
  };

  const renderAddress = () => {
    if (address) {
      return (
        <S.AddressRow>
          <S.AddressColumn>
            <FontIcon name="Address" disableHover={true} />
          </S.AddressColumn>
          <S.AddressColumn>
            <S.AddressText>
              {__('Components.ProductDetails.delivery', {
                street: address.displayName,
                zip: address.zip,
                city: address.city,
                country: address.country,
              })}
            </S.AddressText>
          </S.AddressColumn>
        </S.AddressRow>
      );
    }
  };

  return (
    <S.FakeContainer showInIpad={showInIpad}>
      <S.RightContainerSmall
        showInIpad={showInIpad}
        id="product-left-container-small"
        onSubmit={e => {
          e.stopPropagation();
          e.preventDefault();
          cartUpdateItem(item, true);
        }}
      >
        {item && item.servedQuantity ? (
          <S.AmountRow onClick={e => e.stopPropagation()}>
            <S.SmallPriceRow>
              {pToShow && '-' !== pToShow && 'P.O.R' !== pToShow ? (
                <React.Fragment>
                  <S.Price>{pToShow}</S.Price>
                  <S.PriceUnit>{'/' + parsers.getUnitText(item.priceUnit, item.weightUnit)}</S.PriceUnit>
                </React.Fragment>
              ) : null}
              {'P.O.R' === pToShow ? (
                <S.POR>{utils.firstToUpperCase(__('Components.ProductDetails.por_on').toLowerCase())}</S.POR>
              ) : null}
            </S.SmallPriceRow>
            <S.Amount>{item.servedQuantity}</S.Amount>
            {product.saleUnits.length > 1 ? (
              renderSelectSaleUnit()
            ) : (
              <S.Amount> {parsers.getUnitText(item.servedSaleUnit, product.weightUnit, item.servedQuantity)}</S.Amount>
            )}
            <S.IncDecButtons>
              <S.IncDecButton
                amount={item.servedQuantity}
                onClick={e => {
                  e.stopPropagation();
                  cartUpdateItem(
                    parsers.genericProductToOrderItem(
                      { ...product, saleUnit: item.servedSaleUnit },
                      item.servedPrice || product.price,
                      item.servedQuantity - 1,
                      product.baseMarginPercentage,
                      isServedFlow,
                      item?.id,
                    ),
                    true,
                  );
                }}
              >
                {item.servedQuantity === 1 ? (
                  <S.TrashIcon name="Trash" disableHover={true} />
                ) : (
                  <S.IncDecText>-</S.IncDecText>
                )}
              </S.IncDecButton>
              <S.IncDecButton
                onClick={e => {
                  e.stopPropagation();
                  cartUpdateItem(
                    parsers.genericProductToOrderItem(
                      { ...product, saleUnit: item.servedSaleUnit },
                      item.servedPrice || product.price,
                      item.servedQuantity + 1,
                      product.baseMarginPercentage,
                      isServedFlow,
                      item?.id,
                    ),
                    true,
                  );
                }}
              >
                <S.IncDecText>+</S.IncDecText>
              </S.IncDecButton>
            </S.IncDecButtons>
          </S.AmountRow>
        ) : (
          <S.Add>
            {!disabled ? (
              <S.AddButton
                id="add-cart-item"
                onClick={e => {
                  e.stopPropagation();
                  cartUpdateItem(
                    parsers.genericProductToOrderItem(
                      { ...product, saleUnit: item.servedSaleUnit },
                      item.servedPrice || product.price,
                      1,
                      product.baseMarginPercentage,
                      isServedFlow,
                      item?.id,
                    ),
                    true,
                  );
                }}
              >
                {__('Components.ProductDetails.add_item')}
              </S.AddButton>
            ) : null}
          </S.Add>
        )}
      </S.RightContainerSmall>

      <S.RightContainerFull
        showInIpad={showInIpad}
        isPublic={isPublic}
        id="product-left-container"
        onSubmit={e => {
          e.preventDefault();
          if (isValid) cartUpdateItem({ ...item, totalPrice: orderService.getTotalPriceItem(item) });
        }}
      >
        {renderPrice()}

        {item?.boxWeight && !toggleNewSalesUnitsEnable ? (
          <S.GreyText>
            {toggleNewSalesUnitsEnable
              ? __('Components.ProductDetails.load_shelf', { weight: item?.boxWeight })
              : __('Components.ProductDetails.load', { weight: item?.boxWeight })}
            {item?.price
              ? ` | ${item?.price} ${currency.getPricePerUnit(item?.currency, item?.priceUnit, item?.weightUnit)}`
              : null}
          </S.GreyText>
        ) : null}
        {renderAddress()}
        <SP.StatusText status={product.status}>
          {item
            ? item.status
              ? item.status === 'active'
                ? __('Components.ProductDetails.available')
                : __('Components.ProductDetails.unavailable')
              : null
            : null}
        </SP.StatusText>
        {product.status === AVAILABILITY.UNAVAILABLE && product.availableSoon?.availableFrom ? (
          <S.TextGray>
            {__('Components.ProductCard.estimated_date', {
              date: date.formatLongDate(product.availableSoon.availableFrom, undefined, undefined, 'EEE, dd MMM yyyy'),
            })}
          </S.TextGray>
        ) : null}

        {product.status === AVAILABILITY.UNAVAILABLE ? (
          amSeller ? null : (
            <S.BlackText>
              {__('Components.ProductDetails.know_more')}
              <S.LinkButton
                type="link"
                withoutPadding={true}
                onClick={() =>
                  modalOpen(
                    __('Components.ProductDetails.ask_seller'),
                    (text: string) => {
                      modalClose();
                      sendMessage(text);
                    },
                    {
                      text2: __('Components.ProductDetails.ask_availability', {
                        productName: typeVariety + ' ' + product.size,
                        name: contactName,
                      }),
                      buttonText: __('Components.ProductDetails.send_message'),
                    },
                    'message' as IModalName,
                  )
                }
              >
                {__('Components.ProductDetails.ask_seller')}
              </S.LinkButton>
            </S.BlackText>
          )
        ) : (
          <>
            {renderAmount(item)}
            <S.ItemLoadContainer>
              <S.SubText>{__('Components.ProductDetails.order_load') + ':'}</S.SubText>
              <S.SubText>
                {item
                  ? toggleNewSalesUnitsEnable
                    ? getCartLoadSummaryAltUnits([item])
                    : getCartLoadSummaryText([item])
                  : ''}
              </S.SubText>
            </S.ItemLoadContainer>
            <S.Add>
              {priceMode !== 'none' && item?.servedPrice && item?.servedQuantity ? (
                <S.SubtotalRow>
                  <S.UnitText>{__('Components.ProductDetails.subtotal')}</S.UnitText>
                  <S.ResumePrice>
                    {currency.getPrice(item?.currency, orderService.getTotalPriceItem(item), pricePrecision)}
                  </S.ResumePrice>
                </S.SubtotalRow>
              ) : null}
              {!disabled ? (
                <React.Fragment>
                  <S.AddButton disabled={!isValid} id="update-cart-item" type="principal">
                    {propItem && propItem?.servedQuantity
                      ? __('Components.ProductDetails.update_item')
                      : __('Components.ProductDetails.add_item')}
                  </S.AddButton>
                  {propItem?.servedQuantity && deletable ? (
                    <S.RemoveButton type="secondary" onClick={remove} id="remove-cart-item">
                      {__('Components.ProductDetails.remove')}
                    </S.RemoveButton>
                  ) : null}
                </React.Fragment>
              ) : null}
            </S.Add>
          </>
        )}
      </S.RightContainerFull>
    </S.FakeContainer>
  );
};

export const AddToCart = React.memo(AddToCartComponent);

export default {
  Global: {
    Commons: {
      client: {
        one: 'client',
        other: 'clients',
      },
      loadDate: {
        one: 'load date',
        other: 'load dates',
      },
      deliveryDate: {
        one: 'Delivery date',
        other: 'Delivery dates',
      },
      Actions: {
        processing: 'Processing...',
      },
    },
    Product: {
      Units: {
        box: {
          one: 'box',
          other: 'boxes',
        },
        shelf: {
          one: 'shelf',
          other: 'shelves',
        },
        weight: 'weight',
        kg: 'kg',
        lbs: 'lbs',
        piece: {
          one: 'ud',
          other: 'uds',
        },
        pallet: {
          zero: 'plt',
          one: 'plt',
          other: 'plts',
        },
        cart: {
          zero: 'CC',
          one: 'CC',
          other: 'CC',
        },
      },
      UnitAbbreviations: {
        piece: 'un',
        kg: 'kg',
        lbs: 'lbs',
        box: 'box',
        pallet: 'plt',
        shelf: 'shlf',
        cart: 'CC',
      },
    },
  },
  Components: {
    OrderPreparation: {
      add_reference: 'Add reference',
      create_pre_order: 'Create pre-order',
      create_modal: {
        title: 'Are you sure?',
        description:
          "By accepting this action, an 'in progress' will be created and shared with the seller. The seller will be informed of any changes made to the order after it has been shared.",
        cta: 'Create',
        cancel: 'Cancel',
      },
      save_modal: {
        description: 'By accepting this action, the order will be modified and the seller will be informed.',
        cta: 'Save',
      },
      see_order: 'See order',
      save_changes: 'Save changes',
      to_confirm: 'To confirm the order, go to the',
      current_order: 'order in progress',
      notification: {
        created_title: 'The order has been successfully created',
        created_text: 'You will find it in the orders section',
        updated_title: 'The order has been successfully updated',
      },
      create_order: 'Create',
      Table: {
        Columns: {
          reference: 'Reference',
          code: 'Code',
          boxes_per_pallet: 'Boxes per pallet',
          quantity: 'Quantity',
          starting_price: 'Departure price',
          delivered_price: 'Delivered price',
          total_price: 'Total price',
        },
      },
      Cart: {
        Info: {
          Columns: {
            supplier: 'Supplier',
            delivery_address: 'Delivery address',
            transport_details: 'Transport details',
          },
        },
      },
    },
    ContactsSelected: {
      default: '0 contacts added. Select who you want to add.',
    },
    Alert: {
      Connecting: 'Connecting',
    },
    Form: {
      optional: 'Optional',
      recommended: 'Recommended',
      tooltip_text:
        'This will be the main name your clients will see. You can access the rest of the characteristics in the product detail.',
    },
    Broadcast: {
      modal_remove: {
        title: 'Remove recipient',
        description: "Do you want to remove {{memberName}} from broadcast '{{channelName}}'?",
        cta: 'Delete recipient',
        cta_cancel: 'Cancel',
      },
      add_new_broadcast: 'New broadcast',
      create: 'Create Broadcast',
      name_placeholder: 'Broadcast name',
      name_label: 'Broadcast name:',
      new: 'New Broadcast',
      new_link: 'NEW BROADCAST',
      title: 'Broadcast list:',
      message_error: {
        button: 'ACCEPT',
        limit_button: 'CONTACT',
        text: 'An error has occurred sending the message.',
        limit_text:
          'You have exceeded the daily limit of broadcast messages. For more information, please contact support.',
      },
      members: 'Recipients',
      members_number: {
        one: '{{count}} Recipient',
        other: '{{count}} Recipients',
      },
      activity: {
        title: 'Other options',
        description: 'You can archive this broadcast and it will no long appear in the chat list.',
      },
      archive: 'Archive broadcast',
      unarchive: 'Unarchive broadcast',
      delete_member: 'Remove from broadcast',
      zerocase_title: 'Send messages to several contacts at once',
      zerocase_subtitle: "Share your company's news, promote a line of products, or announce an event!",
    },
    Channel: {
      title: 'Channels:',
      create: 'Create group',
      create_broadcast: 'Create broadcast',
      new: 'New Group',
      members_added: 'Participants added:',
      added: 'Added ({{numItems}})',
      all: 'All ({{numItems}})',
      admin: 'Group admin',
      group: 'Group',
      broadcast: 'Broadcast',
      not_active: 'You do not belong to this group anymore. As a consequence you cannot interact with it.',
    },
    Forward: {
      title: 'Forward message to...',
    },
    AddNewAddress: {
      external_id: 'External ID',
      transport_cost: 'Transport cost',
      transport_cost_unit: 'Transport cost unit',
      title: 'Add new address',
      title_edit: 'Edit address',
      add_address: 'Add this address',
      cancel: 'Cancel',
      country: 'Country',
      postal_code: 'Postal code',
      address: 'Address',
      province: 'City',
      display_name: 'Display name',
      save: 'Save changes',
      modal_remove_text: 'Are you sure you want to delete this address?',
      modal_remove_cta: 'Delete',
      empty_addresses: "You haven't created addresses for this client yet.",
      error_generic: "We couldn't finish the operation, please try later.",
    },
    Onboarding: {
      LoginPhoneEmail: {
        description_phone_register:
          'Select your phone prefix and then enter your mobile phone number to start with register.',
        description_phone_login: 'Select your phone prefix and then enter your mobile phone number to begin a session.',
        description_email_register: 'Enter your email address to start with register.',
        description_email_login: 'Enter your email address to begin a session.',
        description_phone_login_with_public_email:
          'You already have an account with this email but it is not verified. Enter the phone number and the password to log-in.',
        description_2fa: 'For security reasons, you need to log-in again.',
        link_email: 'Use email',
        link_phone: 'Use phone',
        or: 'or',
        cta: 'Next',
        create_account: 'Create account',
        init_session: 'Begin session',
        email_placeholder: 'Enter here your email address',
        email_validation: 'The email is not valid',
        email_empty: 'Please enter an address email',
        country_empty: 'Please enter the phone prefix to begin',
        phone_empty: 'Please enter a valid cell phone number to begin',
        phone_validation: 'Please check the phone number',
        phoneNumber_placeholder: '123 456 789',
        country_placeholder: 'Country code',
        im_not: 'You are not {{username}}?',
        change_account: 'Change account',
        initiate_session_with_email: 'Start again a new session with your email:',
        initiate_session_with_phone: 'Start again a new session with your phone number:',
        error_email_not_validated:
          'The email entered has not been validated. Begin session with your phone, and once logged, validate your email into your account.',
        error_login_phone_not_existent:
          'The phone number you’ve entered doesn’t match any account. Sign up for an account.',
        error_login_email_not_existent: 'The email you’ve entered doesn’t match any account. Sign up for an account.',
        error_login_email_oauth_empty: 'The email you provided through third party is empty.',
      },
      LoginPassword: {
        title: 'Enter password',
        subtitle: 'Enter your password to begin a new session.',
        subtitle_email_public: 'You already have an account with this email, enter your password to log-in.',
        cta: 'Next',
        forget_password: 'Did you forget your password?',
        forget_password_link_sms: 'Receive an SMS',
        forget_password_link_email: 'Receive an email',
        forget_password_description: ' to log-in again',
      },
      VerifyCode: {
        title: 'Enter the verification code',
        title_recover_phone: 'SMS code',
        title_recover_email: 'Email code',
        description_phone: "Enter the code you've just received at **{{phoneNumber}}**.",
        description_phone_2fa_verified:
          'A text message with a code has been sent  to your phone number **{{phoneNumber}}**. Insert the verification code.',
        description_phone_2fa:
          'Enter the security code we have sent to the phone number ending with **{{phoneNumber}}**.',
        description_email: "Enter the code you've just received at **{{email}}**.",
        description_recover_phone: "Create a new password with the code you've just received at **{{phoneNumber}}**.",
        description_recover_email: "Create a new password with the code you've just received at **{{email}}**.",
        no_receive_code: "Didn't receive the code? ",
        resend_sms: 'Re-send SMS',
        resend_email: 'Re-send email',
        validation: 'The code is not valid',
        cta: 'Next',
      },
      Register: {
        title: 'Complete your information',
        name: 'First and last name',
        name_placeholder: 'Write here your first and last name',
        companyName: 'Company name',
        companyName_placeholder: 'Write here your company name',
        password: 'Create password',
        password_placeholder: 'Write your password',
        email: 'Email address',
        email_placeholder: 'Write here your email address',
        phone: 'Phone number',
        cta: 'Next',
        cta_finish: 'Finish register',
        empty_fields: 'All fields should be filled',
        email_validation: 'The email is not valid',
        password_validation:
          'Password must have: 8 or more characters, uppercase and lowercase letters and at least one number',
        error_email_already_taken:
          'This email is already used by another verified account. Please enter a different email.',
        error_phone_already_taken:
          'This phone number is already used by another verified account. Please enter a different one.',
        recommended: 'Recommended',
        skip: 'Skip this step',
      },
      ResetPassword: {
        title: 'New password',
        label: 'Create a new password',
        cta: 'Create',
        placeholder: 'Write your password',
      },
      Welcome: {
        title: 'Streamline your operations in one platform',
        title_public_shop:
          'Create an account or begin session to contact with {{name}}. You will also be able to create and manage your own workspace!',
      },
      error_generic: 'Something went wrong. Please try later.',
      error_user_registred: 'User already registered!',
      error_email_already_taken_1:
        'This email is already used by another verified account. Enter a different email or ',
      error_email_already_taken_link: 'contact with client support',
      error_email_already_taken_2: ' to get help.',
      error_login_password_phone: 'Login error. Please check your phone number / password and try again.',
      error_login_password_email: 'Login error. Please check your email / password and try again.',
      error_login_password_attempts:
        'Login error. Too many wrong login attempts, to access again please contact with support.',
      error_login_password_blocked: 'User temporarily blocked. Please contact with support.',
    },
    Cart: {
      client_view_title_draft: "Here's what your client will see",
      client_view_title: "Here's what your client sees",
      preview_unsend: 'What will my client see?',
      preview: 'What is my client able to see?',
      date_order_issue: 'Add the order creation date',
      delivery_date_issue: 'Add the delivery date of the order',
      pickup_date_issue: 'Add the pickup date of the order',
      address_issue: "Choose an address for '{{code}}'",
      cta_add_products: 'Add new products',
      cta_quantities: 'Modify quantities',
      clients_title: 'Which client do you want to assign the order to?',
      client_name: '{{name}} from {{company}}',
      not_defined: 'Undefined',
      boxesPerPallet: {
        add_option: 'Add option',
        modal: {
          title: 'Add new option for boxes per pallet',
          description:
            'This information will be added both in the current order and in the product card for future orders.',
          placeholder: 'Boxes per pallet',
        },
        confirmation_modal: {
          text: 'Do you want to change the selected boxes per pallet from {{oldValue}} to {{newValue}}?',
          text_add: 'Do you want to set the boxes per pallet to {{newValue}}?',
          checkbox: 'Change only for this order',
          cta: 'Change and save',
        },
      },
      clients_number: {
        one: '1 client in the group',
        other: '{{count}} clients in the group',
      },
      invalid_hash: 'This reference is already associated with another order.',
      numComments: {
        one: '{{count}} comment',
        other: '{{count}} comments',
      },
      updates: {
        one: '{{count}} update',
        other: '{{count}} updates',
      },
      system_id: 'System ID',
      add_external_id: 'Add system ID',
      add_external_id_subtitle: 'Enter the code that this order has in your system / ERP.',
      write_extrernal_id: 'Write the identifier here',
      add_external: 'Add',
      comments: 'Comments and updates',
      comments_old: 'Comments',
      unread_comments: {
        one: 'You have 1 new comment from {{name}}!',
        other: 'You have {{count}} new comments!',
      },
      unread_updates: {
        one: '1 product has been updated',
        other: '{{count}} products have been updated',
      },
      unread_update_price: {
        one: 'The price of {{product}} has been updated.',
        other: 'The price of {{count}} products has been updated.',
      },
      unread_update_quantity: {
        one: 'The final quantity of {{product}} has been updated.',
        other: 'The final quantity of {{count}} products has been updated.',
      },
      unread_update_custom: {
        one: 'The additional line {{custom}} has been updated',
        other: 'There are updates on the additional lines',
      },
      unread_update_logistic: 'There are updates in the transport',
      unread_updates_all: 'There are new updates in the order',
      see_comments: {
        one: 'See comment',
        other: 'See comments',
      },
      see_updates: 'See details',
      inactive_catalog_seller: 'To repeat or modify this order you must put the products of {{catalog}} up for sale',
      inactive_catalog_buyer: 'Check the availability of these products with the supplier to enable them',
      skip: 'No',
      cancel_order: 'Yes',
      add: 'Add more products',
      modify_quantities: 'Modify quantities and prices',
      title_order: 'Order',
      back_order: 'Back to order summary',
      back_orders: 'Back to orders list',
      back_products: "Back to {{name}}'s products",
      back_my_products: 'Back to product list',
      back_to_chat: 'Back to messages',
      title_new: 'Cart',
      ref: 'Ref {{hashId}}',
      buyer: 'Client: ',
      seller: 'Supplier: ',
      total: 'Total',
      items: {
        amount: 'Amount',
        title: 'Items',
        quantity: 'Quantity',
        ordered_quantity: 'Ordered quantity',
        served_quantity: 'Updated quantity',
        price: 'Price',
        proposal: 'Proposal',
        unit_price: 'Unit price',
        cost: 'Cost',
        margin: 'Margin',
        prepare_title: 'Product status',
        prepare: 'To be prepared',
        prepared: 'Prepared!',
        product: 'Product',
        sku: 'SKU',
        weight: 'Weight',
        modified_after_prepared: {
          one: 'The quantity of 1 product has been modified',
          other: 'The quantity of {{count}} products has been modified',
        },
      },
      tooltip_cost:
        'This is the cost that has been added for your product from your ERP. If you need to change any data, do it from the ERP and it will be automatically updated for your product in Consentio.',
      custom_items: {
        title: 'Additional lines',
        description: 'Description',
        value: 'Cost',
        total: 'Total',
        add_line: 'Add additional line',
        tooltip: 'Additional lines are used to add markups or discounts to the order',
      },
      info: 'Order Information',
      creationDate: 'Creation date: ',
      acceptedOrder: {
        editableUntil: 'Order editable until: ',
        tooltip: 'The edition of the order will be available up to 7 days after the delivery date.',
        noLimitDate: 'Set a delivery date',
      },
      load: 'Load: ',
      incoterms: 'Incoterms: ',
      prepare: {
        save: 'Save changes',
        save_send: 'Save and send',
        title: 'Prepare order',
        description:
          "Update the weight of the products and save the changes. When you have everything ready press the 'Save and send' button and we will notify to {{contact}}",
      },
      search_product: {
        title: 'Search in products {{name}}',
      },
      por_product: {
        title: "Add price to '{{name}}'",
        subtitle: 'Fill the missing price in order to complete the order',
        input_text: 'Introduce the price for this line',
        checkbox: 'Update product price',
        accept: 'Update line',
        accept_with_checkbox: 'Update line and product',
      },
      edi_code: 'EDI code: {{code}}',
      no_edi_code: 'No EDI code added',
      errors: {
        lost_line: {
          one: 'In reference to the last order #{{hashId}}, the customer has not ordered **"{{name}}"**',
          other:
            'There are **{{count}} products** that the customer has **not ordered** in reference to the last order #{{hashId}}.',
        },
        no_client: 'Select the **client** to whom this order corresponds',
        no_client_code: "Select a client for '{{code}}'",
        date_create: 'The order creation date is missing.',
        date_delivery: 'The order delivery date is missing.',
        date_pickup: 'The order pickup date is missing.',
        delivery_address_not_found: 'Delivery address is missing.',
        delivery_address_not_assigned: "Choose an address for '{{code}}'",
        pickup_address_not_found: 'Pickup address is missing.',
        pickup_address_not_assigned: "Choose an address for '{{code}}'",
        unavailable_products: {
          one: "'{{name}}' is listed as 'Out of stock' in your catalog.",
          other: "{{count}} products are listed as 'Out of stock' in your catalog.",
        },
        missing_products: {
          one: 'One product code imported is missing and cannot be found within your Consentio products.',
          other: '{{count}} product codes imported are missing and cannot be found within your Consentio products.',
        },
        invalid_amount: {
          one: 'There is a quantity that has not been imported correctly.',
          other: 'There are {{count}} quantities that have not been imported correctly.',
        },
        product_issue: 'Complete the missing data of {{name}}',
        invalid_price: {
          one: 'There is a price that has not been imported correctly.',
          other: 'There are {{count}} prices that have not been imported correctly.',
        },
        unexpected: {
          one: 'Unexpected error.',
          other: '{{count}} unexpected errors.',
        },
        unknown: {
          one: 'Unknown error.',
          other: '{{count}} unknown errors.',
        },
        mismatch_price: {
          one: "The **price** of **'{{productName}}'** does **not match** the price set in the price group for this client.",
          other:
            'The **prices** of **{{count}} products** do **not match** the price established in the price groups for this client.',
        },
        send_manual_erp:
          'The order has not yet been sent to the ERP, you must send it manually. For more information go to the **workspace settings**.',
      },
      discard: {
        message: 'Are you sure you want to discard this item?',
        cta: 'Discard',
        checkbox: 'Always discard',
      },
      update_confirmation: {
        title: "Do you want to assign '{{name}}' with the selected product?",
        check: 'Assign only once',
        cta: 'Always assign',
        cancel: 'Cancel',
        cta2: 'Assign',
      },
      download_dropdown_test: 'Download order',
      format_pdf: 'PDF format',
      format_xls: 'XLS format',
      format_original: 'Original file',
      tags: {
        email: 'Via email',
        edi: 'Via EDI',
        'import-ui': 'Via import',
        'public-shop': 'Public shop',
        internal: 'Via Consentio',
        offers: 'Via offers',
      },
      sent_to_erp: 'Sent to ERP',
    },
    ContactDetails: {
      delivery_footer_text: "{{name}} sends orders to this client's warehouse",
      pickup_footer_text: '{{name}} picks up orders from my warehouses',
      delivery_mode_label: 'Delivery options',
      delivery_mode_delivery: "Sending orders to client's warehouses",
      delivery_mode_pickup: 'Pick up orders from my warehouse',
      currency_label: 'Currency for selling to {{contact_name}}',
      prices: 'Prices',
      edit_prices_title: 'Show and allow edit',
      read_prices_title: 'Only show',
      none_prices_title: "Don't show",
      edit_prices: '{{contact_name}} can see my prices and make offers with different prices',
      read_prices: '{{contact_name}} can see my prices but can’t make offers with different prices',
      none_prices: '{{contact_name}} can’t see my prices',
      price_adjust_title: 'Price adjustment',
      price_adjust: 'Percentage of adjustment on the base price',
      price_adjust_title_margin: 'Client Margin',
      price_adjust_margin:
        "Add a generic margin to add to all products for this client. You can add a specific one per product from 'margins'.",
      rates_applied: 'Price groups applied',
      add_rate: {
        zero: 'Apply one price group',
        one: 'Apply another price group',
        other: 'Apply another price group',
      },
      empty_rates: 'There are no price groups applied to this client yet.',
      margins_applied: 'Margins applied',
      empty_margins: 'There are no margins applied to this client yet.',
      price_adjust_zero: 'There is no specific price adjustment for this client',
      price_adjust_zero_margin: 'There is no general price margin for this client.',
      transport_cost_title: 'Transport surcharge',
      transport_cost: 'Add a surcharge to the base price of the products (per kg or pallet) you sell to this client.',
      transport_cost_cart: 'Add a surcharge to the base price of the products (per kg or CC) you sell to this client.',
      transport_cost_zero: 'There is no transport cost added yet',
      adjust_markup: 'Markup (in %)',
      adjust_margin: 'Margin (in %)',
      adjust_discount: 'Discount (in %)',
      transport_kg: 'Surcharge per {{weightUnit}} ({{currency}})',
      transport_pallet: 'Surcharge per pallet ({{currency}})',
      transport_cart: 'Surcharge per CC ({{currency}})',
      other: 'Other',
      counterpart_label: 'ERP connection',
      counterpart_zero: 'There is no identifier specified yet',
      counterpart_description:
        'If you have an order integration between Consentio and your ERP, you must fill here the unique identifier {{name}} has in your system.',
      counterpart_placeholder: 'Client ID',
      edifactcode_label: 'EDI code',
      edifactcode_description: 'Complete the AECOC code for this customer in your EDI orders here.',
      edifactcode_placeholder: 'EDI Code',
      Header: {
        chat: 'Messages',
        info: 'Information',
        orders: 'Orders',
        magic_orders: 'Magic orders',
      },
      unblock_contact: {
        modal: {
          title: 'Unblock {{name}}',
          subtitle: 'By unblocking this contact, you will be able to interact with',
          text: 'If you unblock this contact, they can: \n- Send you messages. \n- Send you orders. \n- See your products.',
          button: 'Unblock',
        },
        button: 'Unblock contact',
      },
      block_contact: {
        modal: {
          title: 'Block {{name}}',
          subtitle: 'By blocking this contact, they will not be able to interact with you',
          text: 'If you block this contact, **they will no longer be able to**: \n- Send you messages. \n- Send you orders. \n- See your products.',
          button: 'Block',
        },
        button: 'Block contact',
      },
      report_contact: {
        modal: {
          title: 'Report {{name}}',
          text: 'Report inappropriate behavior so we can review it. If it is urgent, contact support.',
          button: 'Report',
          button2: 'Contact support',
        },
        button: 'Report contact',
      },
      block_client_shop: {
        modal: {
          title: '**Do not allow** {{name}} to order',
          subtitle: "This client will not be able to order products from '{{teamName}}'",
          text: '{{name}} will no longer be able to order from anyone, from this workspace, including you, but he will be able to:\n- Chat with you\n- Chat with your colleagues',
          button: 'Understood',
        },
      },
      unblock_client_shop: {
        modal: {
          title: '**Allow** {{name}} to order',
          subtitle: "This client will be able to order products from '{{teamName}}'",
          text: '{{name}} will be able to place orders containing products from this workspace to all members who give or have given the permissions.',
          button: 'Understood',
        },
      },
    },
    IE11Modal: {
      title: 'Internet Explorer Support',
      dear: 'Dear user,',
      introduction:
        'Thank you for using Consentio. Please note that you use a web browser, Internet Explorer, that has limited or no support by Microsoft and that is not adequate for having the best experience with modern website.',
      microsoft_support_1: 'As you can see here on the official ',
      microsoft_support_2: 'Microsoft website',
      microsoft_support_3: ' Microsoft recommends to avoid using Internet Explorer ',
      microsoft_support_4: 'and has stopped further improvement and updates of Microsoft Internet Explorer.',
      microsoft_edge:
        'Microsoft and Consentio recommends you to use the latest (Free) web browser of Microsoft called Edge that works with Windows 10.',
      modern_browsers:
        'If you prefer and/or have an older version of Windows, we strongly recommend you to download and install Google Chrome or Mozilla Firefox.',
      help_1: 'If you need assistance, please contact us at ',
      help_2: ' explaining your issue.',
      it_support:
        'We are happy to talk with your IT department in order to explain them and assist them the best way we can in getting you installed a modern web browser.',
      consentio_support:
        'At Consentio we like to provide you the most modern technology and this involves having the best and most modern Free web browsers available.',
      acknowledgements: 'Thank you for helping us making you have a great experience with Consentio!',
      greetings: 'Best,',
      consentio_team: 'Consentio Team',
    },
    ProductListShare: {
      share_search: 'Send',
      share_showroom: 'Send\ncatalog',
      current: 'Current',
      frequent: 'Frequent',
      recent_chats: 'Recent chats',
    },
    ProductCard: {
      estimated_date: 'Est. availability: {{date}}',
      check_price: 'Check price',
      add: 'Add',
      edit_units: '{{amount}} {{saleUnit}}',
      sku: 'SKU',
      eanCode: 'EAN Code (GTIN)',
      select: 'Select',
      selected: 'Selected',
      code_missing: 'Missing EAN code (GTIN)',
      sku_missing: 'Missing SKU code',
      sku_missing_short: 'Missing SKU',
      code_missing_short: 'Missing EAN (GTIN)',
      favorite_tooltip: 'Product saved as favorite',
      recent_tooltip: 'Product recently traded',
      recommended_tooltip: 'Product specially chosen for you',
      favorite_mark: 'Mark as favorite',
      favorite_unmark: 'Unmark favorite',
      favorite: 'Favorite product!',
      recommended: 'Recommended!',
      out_of_stock: 'Out of stock',
      out_of_stock_tooltip:
        'This product is out of stock. You can view its details but you cannot add it to your order.',
      recommended_mark: 'Recommend!',
      recommended_unmark: 'Stop recommending',
      add_product: {
        one: 'Add {{count}} product',
        other: 'Add {{count}} products',
      },
      add_products_to_price_group: {
        title: 'Add products to price group',
        margin: 'Add products to margin',
        subtitle: 'You will be able to change prices once you added them',
      },
      sustainability: {
        title: '**1kg** is equivalent to ...',
        co2: '**{{co2}}** kg of CO2',
        water: '**{{water}}** L of water',
        plastic: '**{{plastic}}** g of plastic',
      },
    },
    Sidebar: {
      teams: {
        one: 'workspace',
        other: 'workspaces',
      },
      chat: 'Chats',
      settings: 'Settings',
      orders: 'My Orders',
      sales: 'Sales',
      purchases: 'Purchases',
      help: 'Help',
    },
    Header: {
      Login: 'Login',
      Logout: 'Logout',
      Workspaces: 'Workspaces',
      WorkspacesSeller: 'Seller workspaces',
      WorkspacesBuyer: 'Buyer workspaces',
      WorkspaceClients: 'Clients',
      WorkspacePriceGroups: 'Price groups',
      WorkspaceProducts: 'Products',
      WorkspacePrepareOrders: 'Prepare orders',
      WorkspaceSections: 'Manage sections',
      WorkspaceSettings: 'Settings',
      WorkspaceSuppliers: 'Suppliers',
      Orders: 'Orders',
      Sales: 'Sales',
      Products: 'Products for {{name}}',
      LinkCopied: 'Copied!',
      confirm_log_out: 'Are you sure you want to exit?',
      confirm_log_out_description: 'Please confirm that you want to close session.',
      My: 'My',
      Profile: 'Profile',
      Aggregation: 'Grouped orders',
      Config: 'Settings',
      Addresses: 'Addresses',
    },
    OrderCard: {
      see_order: 'See order',
      price_amount: 'Amount: ',
    },
    OrderDetails: {
      main: {},
      parts: {
        body: {
          comments: {
            title: 'Comments and updates',
          },
        },
      },
      // old
      issues_modal: {
        title: 'Complete the information for {{product}}',
        text: 'This information will be added both in the current order and in the product card for future orders.',
        weight_per_box: 'Weight per box',
        boxes_per_pallet: 'Boxes per pallet',
        units_per_box: 'Units per box',
        cta: 'Save',
        cancel: 'Cancel',
        kg: 'kg',
        unit: 'Units',
        box: 'Boxes',
        success: 'Information saved successfully',
      },
      notification: {
        success: 'Order saved successfully',
        success_description: 'The information was successfully saved to the order.',
        error: 'The order could not be saved',
        error_description: 'An error occurred while saving the order. Please try again.',
      },
      setOtherConfirmationModal: {
        title: {
          one: 'The code {{code}} is already assigned to {{name}}',
          other: 'The code {{code}} is already assigned to other products',
        },
        title_client: {
          one: `Do you want to assign the code '{{code}}' to '{{name}}' for '{{client}}'?`,
          other: `The code '{{code}}' is already assigned to other products`,
        },
        description: 'Do you want to assign it also to {{name}}?',
        check: 'Assign only for the current line',
        cta: 'Assign always',
        cta2: 'Assign',
        cancel: 'Choose another product',
      },
      setOtherConfirmationModal2: {
        title: "Assign '{{code}}' to {{name}}",
        text1:
          'From now on, the reference **{{code}}** will be assigned to multiple products, so you will have to **manually** select which one it refers to every time you import an order containing it.',
        text2: 'This will not apply to **orders** that already have a product selected for this code.',
        cta: 'Confirm and assign',
        cancel: 'Back',
      },
      setMappingConfirmationModal: {
        title: '{{name}} already has other references assigned to it',
        description: 'Do you also want to map {{code}} to it?',
        check: 'Assign only for the current line',
        cta: 'Assign always',
        cta2: 'Assign',
        cancel: 'Choose another product',
      },
      productMappingModal: {
        title: 'The code {{code}} is assigned to the following products.',
        viewAll: 'View all my products',
      },
      priceModal: {
        title: 'Price visibility',
        text: `Currently, your client can't see the prices of your products, do you want to show them?`,
        cancel: 'No, keep hidden',
        cta: 'Yes, show prices',
      },
      update_hash: 'The order reference has been successfully changed',
      success_serve_update: {
        title: 'Changes saved and sent correctly',
      },
      file_type: {
        invoice: 'Invoice',
        quality_control: 'Quality control',
        delivery_note: 'Delivery note',
        other: 'Other',
        placeholder: 'Select a category',
        all: 'All',
      },
      send_to: 'Send to order: {{orderHash}}',
      prepare_order: 'Prepare order',
      modify: 'Modify',
      other_options: 'Other options',
      favorite_confirm: 'Save products as favorites',
      options: {
        cancel: 'Cancel order',
        reset: 'Cancel',
        clone: 'Repeat order',
        send_to_erp: 'Send to ERP',
        save_draft: 'Save as draft',
        duplicate_draft: 'Duplicate draft',
        delete_draft: 'Delete draft',
      },
      draft_notification: {
        title: 'Draft saved successfully',
        description: 'You will be able to find it in your sales',
      },
      draft_delete_notification: {
        title: 'Draft deleted successfully',
      },
      send_erp_tooltip: {
        issues: 'Fill in the missing fields in the order to be able to send it to the ERP.',
        pending: 'To send the order to the ERP, it has to be accepted. Ask your client to review and accept it.',
      },
      send_erp_notification: {
        success: 'The order has been sent to the ERP',
        error: 'The order could not be sent to the ERP',
      },
      send_erp_confirmation: {
        title: 'ERP send confirmation',
        description:
          'The order can only be sent once to the ERP. All changes made after that, you will not be able to send them, do you confirm to send?',
        cancel: 'Cancel',
        confirm: 'Confirm and send',
      },
      comments_placeholder: 'Write your comment here',
      confirm_cancel: 'Are you sure you want to cancel this order?',
      confirm_delete_draft: 'Are you sure you want to delete this draft?',
      transport: {
        address_title: 'Choose the address',
        address_subtitle: 'Select the address that corresponds to "{{code}}" or add a new one',
        title: 'Transport',
        not_included: 'Unspecified transport',
        pickup: 'Pickup',
        shipping: 'Shipping',
        transport_cost: 'Transport cost: ',
        incoterms: 'Incoterms: ',
        estimated_date: 'Estimated date: ',
        search_delivery_addresses: 'Search delivery addresses',
        search_pickup_addresses: 'Search pickup addresses',
        create_address: 'Create address',
        update_confirmation: {
          title: "Do you want to assign address '{{name}}' with this order?",
          check: 'Assign only once',
          cta: 'Always assign',
          cta2: 'Assign ',
        },
        add_edit_information: 'Add or modify information',
        transport: 'Transport',
        Pickup: {
          address: 'Pickup address',
          date: 'Pickup date',
          time: 'Pickup time',
        },
        Delivery: {
          address: 'Delivery address',
          date: 'Delivery date',
          time: 'Delivery time',
        },
        Load: {
          date: 'Load date',
        },
      },
      por_message: "Some items of the order don't have price, enter them to send back the order.",
      autoaccept: {
        title_auto: 'Automatically Accept Orders',
        title_manual: 'Manually Accept Orders',
        body: "Do you want to automatically accept next {{contact_name}}'s orders?",
        cta: 'Yes, I want',
        cancel: "No, I'll do it manually",
        body_auto:
          "You've configured that all next {{contact_name}}'s orders will be AUTOMATICALLY accepted.\n\nYou can change it anytime from contact details.",
        body_manual:
          "You'll continue accepting MANUALLY orders with {{contact_name}}.\n\nYou can change it anytime from contact details.",
        cta_feedback: 'Got it!',
      },
      success_create: {
        title: 'Order {{hashId}} sent',
        description: "Congratulations! You've just sent a new order to {{name}}.",
        cta: 'OK!',
      },
      success_update: {
        title: 'Order {{hashId}} updated',
        description: "Congratulations! You've just updated the {{name}}'s order.",
        cta: 'OK!',
      },
      ribbon_relaunch: 'The order could not be uploaded to the ERP because {{error_code}}. {{solution_code}} and',
      ribbon_no_relaunch: 'The order could not be uploaded to the ERP because {{code}}.',
      ribbon_prices_hidded: 'This client is not able to see the prices of your products or orders.',
      ribbon_action_show_prices: 'Configure price visibility',
      ribbon_action: 'upload this order to your system',
      export_notification: {
        title: 'Your order has been successfully uploaded',
        subtitle: 'You can find your order within your system now',
      },
      empty_cart: {
        title: "You haven't added any product yet",
        cta: 'Go to the products',
      },
      sort_products: {
        title: 'Sort products',
        alphabetical: 'Alphabetically',
        sku: 'SKU',
        default: 'Date added',
      },
      tooltip: {
        edit_text: 'To apply this sort to all your orders, you can change it from the **workspace settings**.',
        read_text:
          'To apply this sort to all your orders, ask a **colleague** (manager or editor) to do it from the **settings** of the workspace.',
      },
    },
    OrderLogistics: {
      title: 'Transport options',
      pickup_details: 'Pickup details',
      pickup_details_select_address: 'Select a pickup address or add a new one.',
      pickup_details_add_address: 'Add a new pickup address.',
      shipping_details: 'Shipping details',
      shipping_details_subtitle: 'Select a shipping address or add a new one.',
      add_new_address: 'Add new address',
      save: 'Save',
      incoterms: 'incoterms',
      incoterms_subtitle: 'Indicate the rate and responsability degree of this delivery.',
      transport_cost: 'Transport cost',
      transport_cost_subtitle: 'Select the cost type for this order and its corresponding price',
      select_option: 'Select one option',
      delivery_date: 'Estimated delivery date',
      delivery_date_subtitle: 'Select day and time slot for the delivery',
      pickup_date: 'Estimated pickup date',
      pickup_date_subtitle: 'Select day and time slot for the pickup',
      select_date: 'Select a date',
    },
    ProductDetails: {
      delivery: 'Send to {{street}}, {{zip}}, {{city}}, {{country}}',
      clone: "Finish configuring the product data and click on 'Create product' to save it",
      clone_notification: 'Product duplicated',
      clone_title: 'Duplicate product',
      complete_info_box_weight: 'Complete box weight information',
      complete_info_pieces_box: 'Complete units per box information',
      complete_info_boxes_pallet: 'Complete boxes per pallet information',
      complete_info_default: 'Complete product information',
      clone_description: "Do you want to **duplicate '{{name}}'**? ",
      clone_cta: 'Duplicate product',
      discarded: 'Product discarded',
      discarded_always: 'Product forever discarded',
      recover: 'Recover product',
      stop_discarding: 'Stop discarding',
      recover_modal: {
        title: 'Do you want to recover the product?',
        text: 'The product will be recovered starting from the next order. You will have the option to reject it again or assign it to another product.',
        cta: 'Recover',
        cancel: 'Cancel',
      },
      search: {
        placeholder: 'Search price group',
        placeholder_margin: 'Search margin',
      },
      add_to_pricegroups_cta: 'Add to {{count}} price groups',
      add_to_pricegroups: 'Add product to other price groups',
      add_to_margins_cta: 'Add to {{count}} margins',
      add_to_margins: 'Add product to other margins',
      base_pice: 'Base Price',
      back_edit_product: 'Back to edit product',
      pricegroups_applied: "Price groups applied to '{{name}}'",
      margins_applied: "Margins applied to '{{name}}'",
      deleted: 'Deleted.',
      load: 'Box of {{weight}} kg approx.',
      load_shelf: 'Shelf of {{weight}} kg approx.',
      price_kg: ' | {{price}} / kg',
      undo: 'Undo',
      share: 'Send\nproduct',
      available: 'Available product',
      unavailable: 'Out of stock product',
      hidden: 'Hidden product',
      information: 'Product information',
      know_more: 'Do you want to know when it will be available again?',
      ask_seller: 'Ask the seller',
      ask_availability:
        'Hi {{name}}, I\'m interested in "{{productName}}". Could you please let me know when will it be available again?',
      product: {
        name: 'Display name',
        title: 'Characteristics',
        type: 'Type of product',
        variety: 'Variety',
        brand: 'Brand',
        size: 'Size',
        category: 'Grade',
        origin: 'Origin',
        organic: 'Type of production',
        region: 'Region',
        tare: 'Box tare',
        unique_id: 'Consentio ID',
      },
      price: {
        title: 'Price',
        price: 'price',
        priceUnit: 'Price unit',
      },
      sale_units: {
        title: 'Sales units',
      },
      distribution: {
        title: 'Distribution',
        piecesPerBox: 'Units per box',
        boxWeight: 'Box weight',
        boxesPerPallet: 'Boxes per pallet',
        boxType: 'Box type',
        shelfType: 'Shelf type',
        cartType: 'CC type',
        packaging: 'Packaging',
        palletType: 'Pallet type',
        palletHeight: 'Pallet height',
        piecesPerShelf: 'Units per shelf',
        shelfWeight: 'Shelf weight',
        shelvesPerCart: 'Shelves per CC',
        cartHeight: 'CC height',
        warehouse: 'Warehouse where the product is located',
        warehouse_info: 'Product is in',
      },
      lot: {
        assign_new: 'Associate lot to a new product',
        assign_existing: 'Associate lot to an existing product',
        modal: {
          new: {
            title: 'Assign lot of origin to a new product',
            text: 'Choose which sales workspace you want the product you are going to create to belong to and associate it with this lot of origin.',
            cta: 'Create product',
          },
          existing: {
            title: 'Assign lot of origin to an existing product',
            text: 'Choose the sales workspace that the product you want to associate to this lot as lot of origin belongs to',
            cta: 'Choose a product',
          },
          select: 'Select workspace',
          cancel: 'Cancel',
        },
      },
      more_info: {
        title: 'More information',
        placeholder: 'Additional info',
      },
      metadata: {
        title: 'Other information',
        sku: 'SKU - Stock Keeping Unit',
        eanCodeOld: 'EAN Code (GTIN)',
        eanCode: 'EAN (GTIN) / PLU / UPC',
      },
      create: 'Create product',
      product_name: 'Product',
      save: 'Save',
      edit: 'Edit',
      back: "Back to {{name}}'s products",
      back_my: 'Back to product list',
      confirm_exit_changes: "You have pending changes that haven't been saved. Do you want to exit anyway?",
      confirm_remove: 'Do you confirm deleting this product?',
      confirm_remove_picture: 'Do you confirm deleting this picture?',
      errors: 'These fields are incomplete or incorrect:',
      no_photo: 'No photo added.',
      request_photo: 'Request a photo',
      add_more_images: 'Add more photos',
      max_photos: 'You can add up to 4 photos',
      allowed_format: 'Allowed formats: png, jpg, jpeg',
      request_more_info_text: "Something's missing?",
      request_more_info: 'Ask the seller',
      amount_title: 'Quantity',
      add_item: 'Add to cart',
      update_item: 'Update Order',
      subtotal: 'Subtotal:',
      updated_at: 'Updated date',
      remove: 'Remove from order',
      remove_action: 'Delete product',
      clone_action: 'Duplicate product',
      other_options: 'Other options',
      por: 'pricing mode',
      por_on: 'Price on request',
      por_off: 'Price set',
      order_load: 'Item load',
      success_update: 'Product updated successfully',
      success_create: 'Product created successfully',
      ask_title_photo: 'Ask {{name}} for photos',
      ask_title_info: 'Ask {{name}} for info',
      ask_message_photo: 'Hi {{name}}, I\'m interested in "{{productName}}". Could you please send me some photos?',
      ask_message_info: 'Hi {{name}}, I\'m interested in "{{productName}}". Could you please send me more details?',
      send_message: 'Send message',
      tag: {
        title: 'Tags',
        description:
          'Categorize the product with some tags. You can create new ones or use some you created previously.',
        input_label: 'Search to add the product to new or existing sections.',
        error_max_length: 'Tag can be only up to {{maxLength}} characters',
        placeholder: 'Search for a section',
        recommended: 'Recommended by the seller',
        recommended_mobile: 'Recommended',
        recent: 'Recently purchased',
      },
      product_missing: "Product **'{{name}}'** with code **'{{code}}'** doesn't exist in Consentio.",
      product_mapping_duplicated:
        "There are several products associated with the product **'{{name}}'** with the code **'{{code}}'**.",
      product_missing_actions: {
        '0': 'You can',
        '1': 'search in products',
        '2': 'or',
        '3': 'discard line',
      },
      product_unavailable_actions: {
        '0': 'You can',
        '1': 'make it available for this order',
        '2': 'or',
        '3': 'discard line',
      },
      price_missing: 'Price is missing on this item.',
      price_missing_no_product: 'First, select the product',
      no_data: 'Missing data',
      price_missing_actions: {
        '0': 'You can',
        '1': 'fill the price',
        '2': 'or',
        '3': 'discard line',
      },
      mismatch_price: {
        tooltip: 'The price of this product does not match the price established in the price group for this client.',
        price: 'Price from price group: ',
      },
      box_per_pallet: {
        add_option: 'Add another box per pallet option',
        delete_option: 'Delete option',
        predefined: 'Predefined',
        make_predefined: 'Make predefined',
        tooltip:
          'These are the boxes per pallet that will appear selected by default for all your clients in your orders. You can manually choose any of the other options.',
      },
    },
    Referential: {
      zero_case: {
        title: 'Manage your references',
        subtitle: 'Please add here the references with which you have agreed to work with your suppliers.',
        cta: 'Add references',
      },
      table: {
        name: 'Reference name',
        type: 'Type',
        code: 'Reference code',
        sellers: 'Suppliers with associated products',
      },
      reference: {
        search: 'Search product',
        add: 'Add reference',
        menu: 'Reference',
        new: 'Create new reference',
        title: 'Which reference do you want to create?',
        title_detail: 'Reference specifications',
        description: 'This name will be visible to all your suppliers, enabling them to bid on this reference.',
        providers: 'No supplier has associated products with this reference yet.',
        name: 'Reference name',
        name_placeholder: 'Enter the reference name',
        product: 'Product type',
        select: 'Select type',
        code: 'Reference code',
        code_description: 'EAN (GTIN) / PLU / UPC/ SKU',
        code_placeholder: 'Enter the reference here',
        delete: 'Delete reference',
        sellers: 'Suppliers',
        sellers_title: 'Suppliers with associated products',
        delete_title: 'Are you sure you want to delete this reference?',
        delete_description:
          'This reference will no longer be featured in the summary table, and suppliers cannot submit direct offers for it.',
        delete_success_title: 'Reference deleted successfully',
        delete_success_description: 'The reference will no longer be visible in the table.',
      },
    },
    OffersList: {
      search: 'Search references',
      supplier: 'Supplier',
      address: 'Address',
      productKind: 'Type',
      noWarehouse: 'No address',
      reference: 'Reference',
      code: 'Code',
      viaOffers: 'Via offers',
      noLimit: 'No limit',
      offerFor: 'offer for',
      ribbon: `It is necessary to **associate all the products to clients reference** to share the offer`,
      orderPreparation: 'Order preparation',
      table: {
        warning: 'Choose only one product linked to {{reference}}',
        ribbon: '{{number}} products are linked with {{reference}}. Choose only one to be able to share the offer.',
        cancelText: 'Cancel selection',
        selection: 'Confirm selection ({{selection}})',
        tooltip: 'This is the name your client will see in the offer.',
        tooltipBoxPerPallet: 'Boxes per pallet:  {{boxesPerPallet}}',
        associate_reference: 'Associate reference',
        remove_ref_title: 'Are you sure you want to unlink this reference from this product?',
        remove_ref_text:
          'There will be no further link between this reference and the product in past or future offers.',
        references: 'References',
        price: 'Price',
        availability: 'Avail',
        bpp: 'BPP',
        total_availability: 'Total availability',
        departurePrice: 'Departure price',
        deliveredPrice: 'Delivered price',
        unit_price: 'Unit price',
        daily_availability: 'Availability (per day)',
        articles: 'Articles ({{count}})',
        add_products: 'Add products',
        no_address: 'Without address',
        no_address_added: 'The client has not yet added delivery addresses. Contact them to add them.',
        no_address_selected: '**Select the addresses** to set prices and availability accordingly.',
        unlimited: 'Unlimited',
        add_address: 'Select client address',
        address_subtitle:
          'Here are the addresses added by the client. Select the address to complete the specific information of each one.',
        modal: {
          title: 'Associate the product to the reference of {{supplier}}',
          text: 'Select the reference name to associate the product to a reference.',
          input_title: 'Reference name',
          cta: 'Save',
          cancel: 'Cancel',
          zero_case_title: 'No references available yet',
          zero_case_text: 'Ask your client to add them',
          loading_case_title: 'We are loading references',
          loading_case_text: 'Please wait a moment',
          cta_chat: 'Chat',
        },
      },
      availability: {
        total: 'Total availability',
        address: 'Availability by address',
      },
      price: {
        departureAndDeliveredPrice: 'Departure and delivered price',
        departurePrice: 'Departure price',
        deliveredPrice: 'Delivered price',
      },
      zero_case: {
        title: 'No offers found for the date selected',
        subtitle: 'Please select a different date',
      },
      order_preparation: {
        zero_case_title: 'Prepare your orders here',
        zero_case_text:
          'In this space, you will be able to prepare your orders by specifying the quantities you want to order.',
        cta: 'Go to offers',
        date: '**Delivery date:** {{date}}',
        cart: 'Cart by suppliers',
        saved: 'Saved in real time',
        by_platform: 'Breakdown by platform',
        by_supplier: 'Summary by supplier',
        table: {
          supplier: 'Supplier',
          price: 'Price',
          quantity: 'Quantity',
          total_quantity: 'Total quantity',
          remaining: 'Remaining: {{remaining}} {{unit}}',
          comment: 'Add a comment',
          comments: 'Comments',
          no_address: 'No address',
          delete_comment: {
            title: 'Are you sure you want to delete this comment?',
            text: 'This action cannot be undone.',
            cta: 'Delete',
            cancel: 'Cancel',
          },
          delete_lines: {
            title: 'Delete this line',
            text: 'Are you sure you want to remove this line for this reference?',
            cta: 'Delete',
            cancel: 'Cancel',
          },
          with_comments: 'With comments',
          without_comments: 'Without comments',
        },
      },
      save: 'Save draft',
      share: 'Share offer',
      repeat: 'Repeat offer',
      delete: 'Delete offer',
      notification: {
        share: 'Offer shared successfully',
        repeat: 'Offer repeated successfully',
        draft: 'Draft saved successfully',
        description: 'You will find it in your offers list',
      },
      states: {
        progress: 'In progress',
        draft: {
          name: 'Draft',
          color: 'draft',
        },
        accepted: {
          name: 'Accepted',
          color: 'accepted',
        },
        published: {
          name: 'Shared',
          color: 'accepted',
        },
        counterOffered: {
          name: 'Counter offered',
          color: 'pending',
        },
        expired: {
          name: 'Expired',
          color: 'error',
        },
        denied: {
          name: 'Denied',
          color: 'canceled',
        },
      },
    },
    validationErrors: {
      quantity: 'The quantity must be greater than 0',
      quantityUnit: 'The quantity unit must be set',
      price: 'The price must be greater than 0',
      totalQuantity: 'The total quantity must be greater than 0',
      totalQuantityUnit: 'The total quantity unit must be set',
    },
    ProductsList: {
      ManageSections: 'Manage sections',
      Tabs: {
        products: 'Products',
        offers: 'Offers',
        products_buyer: 'Search products',
        rates: 'Price groups',
        margins: 'Margins',
        prepare_orders: 'Prepare orders',
        clients: 'Clients',
        settings: 'Settings',
        suppliers: 'Suppliers',
        referential: 'Referential',
        referenceList: 'Association list',
        tooltip:
          'Add your company logo, a short summary of what it does, and much more! This way, your clients can get to know you better.',
        tooltipTitle: 'Finish setting up your workspace!',
      },
      TeamOf: 'You are seeing:',
      Remove: 'Remove',
      AddPartners: 'Add colleagues',
      CreateProduct: 'Create Product',
      search: {
        placeholder: 'Search products',
      },
      Table: {
        TypeVariety: 'Product',
        Origin: 'Origin',
        Category: 'Grade',
        BoxWeight: 'Box weight',
        BoxType: 'Box type',
        BoxesPerPallet: 'Boxes/pallet',
        unique_id: 'Consentio ID',
        UnitsPerBox: 'Units per box',
        Packaging: 'Packaging',
        Images: 'Images',
        Supplier: 'Supplier',
        PalletType: 'Pallet type',
        PalletHeight: 'Pallet height',
        Size: 'Size',
        Price: 'Price',
        Visible: 'Status',
        variety: 'Variety',
        type: 'Type',
        non_visible: 'Non available',
        UpdatedAt: 'Updated',
        POR: 'Price on request',
        shareTooltip: "You have marked this product as 'non-available'. Mark it as 'available' to be able to share it.",
        Region: 'Region',
        Brand: 'Brand',
        TypeOfProduction: 'Type of production',
        Sku: 'SKU',
        Ean: 'EAN (GTIN)',
        Warehouse: 'Warehouse',
        Load: 'Load',
        LoadDate: 'Load date',
        modal: {
          title: 'Customize the columns',
          subtitle: {
            zero: 'Choose which columns are displayed on the screen.',
            one: 'Choose which columns are displayed on the screen. You have to choose at least 1.',
            other: 'Choose which columns are displayed on the screen. You have to choose at least {{count}}.',
          },
          placeholder: 'Choose which column to add',
          rank: 'Rank',
          info: 'Information displayed',
          save: 'Save',
          cancel: 'Cancel',
          custom_info: '*You cannot move or delete these columns',
        },
      },
      EmptyResults: {
        buyer: 'There are no products available',
        zero_case: 'Create and customize your products and share it with your clients',
        zero_case_cta: 'Create product',
      },
      QueryEmptyResults: 'Sorry! No results found for "{{search}}"',
      EmptyResultsTryAgain: 'Try again with other criteria',
      Filter: {
        All: 'All',
        Actives: 'Published',
        Inactives: 'Hidden',
      },
      Sort: {
        relevance: 'Relevance',
        'timestamp-desc': 'Updated desc',
        'timestamp-asc': 'Updated asc',
        desc: 'Alphabetic desc',
        asc: 'Alphabetic asc',
      },
      SearchStats: '{{hits}} results in {{seconds}}s',
      ShowMore: 'Show more',
      Import: 'Import CSV catalog',
      Export: 'Export catalog in CSV',
      costImport: 'Import costs in CSV',
      costExport: 'Export costs in CSV',
      custom_columns: {
        no_columns_available: 'No columns available',
        error_min_columns: 'You must select at least five columns',
        success_title: 'Custom columns saved',
        success_description: 'The columns you selected have been saved correctly',
      },
    },
    OrdersImport: {
      inactive_ribbon:
        'You cannot import orders for {{name}} since it is not a PRO workspace. To be able to import orders,',
      inactive_ribbon_action: 'assign another workspace to this client.',
      title: 'Import orders',
      select_order_type: 'Select the order format',
      upload_orders: 'Upload orders:',
      add_orders: 'Add orders',
      add_orders_description: 'Drag here the file you want to import or select it from your computer',
      select: 'Select',
      files_added_count: {
        one: '1 file enclosed',
        other: '{{count}} files enclosed',
      },
      format: {
        text: 'Text',
      },
      cancel: 'Cancel',
      import_time: 'The estimated import time is usually about 5 minutes',
      show_all_formats: 'Show all formats',
      upload_file: {
        one: 'Upload file',
        other: 'Upload files',
      },
      insert_text: 'Insert text here',
      import: 'Import',
      paste_order: "Paste order's text",
      import_title: 'How would you like to import the order?',
      import_jobs: 'Imports',
      import_job: 'Import',
      see_import: 'See import',
      see_all: 'See all',
      errors_count: {
        one: 'We have detected one error',
        other: 'We have detected {{count}} errors',
      },
      orders_processed_count: {
        one: 'One order has been generated with success',
        other: '{{count}} orders have been generated with success',
      },
      email_title: 'Receive your orders by email',
      email_description:
        'Ask your clients to include this address in the email where they send their orders.\n In this way we can import it directly into Consentio, where you have everything you need.',
      email: 'Email:',
      catalog_select: 'Choose the workspace you wish to assign to this order',
      importing_orders_error: 'The file import has failed',
      importing_orders_success: 'The file has been processed correctly',
    },
    OrdersImportHistory: {
      title: 'Imports history',
      back: 'Back to files import',
      history_reference_mapping: 'Reference list',
      EmptyResults: {
        title: "You don't have any import jobs for those criteria",
        description: 'Change the date filters',
        cta: 'Back to files import',
      },
    },
    MultipleCarts: {
      remove_cart: 'Remove cart',
      edit_cart: 'Edit cart',
      title: 'Cart',
      provider: 'Provider',
      client: 'Client',
      items: 'Items',
      transportation: 'Transportation',
      comments: 'Comments',
      total_cost: 'Total cost',
      order_cta: 'Send order',
      offer_cta: 'Send sale',
      unsent_cta: 'Cart',
      empty: {
        title: 'You have sent all the products in the cart!',
        subTitle: 'Add new products to the cart to make new orders',
        cta: 'Search products',
      },
    },
    SellerOffer: {
      zero_case: {
        title: 'In this space you can view and create offers',
        subtitle: 'Offers allow you to inform your clients about the pricing and quantities available of your products',
        text_cta: 'Create a new offer',
      },
      new_offer_cta: 'Create a new offer',
      listOfReferences: 'Association list',
      search: 'Search offers',
      columns: {
        name: 'Name',
        client: 'Client',
        products: 'Products',
        status: 'Status',
        delivery_date: 'Delivery date',
        last_update: 'Last update',
      },
      modal: {
        title: 'Select client',
        subtitle: 'Choose which client you want to send the offer to',
      },
      referenceList: {
        zeroCase: {
          title: 'Manage reference associations',
          subtitle: "Start associating products with your clients' references by creating your first offer.",
          buttonText: 'New offer',
        },
        table: {
          productDescription: 'Product description',
          SKU: 'SKU',
          associateReference: 'Associated reference',
          clients: 'Clients',
          lastModify: 'Last modified',
        },
        referenceListSelected: {
          productSection: {
            title: 'Product',
            productDescription: 'Description',
            SKU: 'SKU',
          },
          associatedReferenceSection: {
            title: 'Associated reference',
            clientsReferences: 'Client references',
            type: 'Type',
            clients: 'Clients',
            associateReference: 'Associate reference',
            deleteAssociation: 'Delete association',
            addAssociatedReference: 'Associate product to reference',
          },
          selectReferenceModal: {
            associateProduct: 'Associate the product with the reference',
            selectReference: 'Select the reference name to associate the product with a reference.',
            referenceName: 'Reference name',
            cancel: 'Cancel',
            accept: 'Associate',
          },
          confirmModal: {
            title: 'Do you want to delete the association?',
            message: 'The reference "{{reference}}" is already associated with this SKU. Do you want to replace it?',
            accept: 'Yes',
            cancel: 'No',
          },
          deleteReferenceModal: {
            title: 'Do you want to delete the association?',
            cancel: 'Cancel',
            remove: 'Remove',
            message:
              'Deleting the association will dissolve all connections between the product and the reference "{{productReference}}". You can re-associate the reference later.',
          },
        },
      },
    },
    OrdersList: {
      lost_line_info: 'The number of products has changed in comparison to the previous order with this client.',
      sale_cta: 'New sale',
      buy_cta: 'New purchase',
      magic_orders: 'Magic orders',
      importedFile: 'Imported file',
      modal: {
        sale: {
          title: 'New sale',
          subtitle: 'Choose which client you want to sell to',
        },
        buy: {
          title: 'New purchase',
          subtitle: 'Choose which supplier you want to buy from',
        },
        contacts_found: {
          zero: 'No contacts found',
          one: '{{count}} contact found',
          other: '{{count}} contacts found',
        },
        cta: 'Next',
      },
      requests: 'Requests',
      my_sales: 'My sales',
      my_purchases: 'My purchases',
      mark_all_as_read: 'Mark all as read',
      mark_as_read: 'Mark as read',
      mark_as_unread: 'Mark as unread',
      num_unreads: 'You have {{count}} unread orders!',
      view_unreads: 'View unread orders',
      title_sales: 'Sales',
      title_purchases: 'Purchases',
      title_unreads: 'Unread',
      import: 'Import order files',
      back_order: 'Back to the order',
      back_orders: 'Back to orders',
      search: 'Search for an order by its reference',
      Table: {
        Date: 'Date',
        Hash: 'Order ref.',
        Contact: 'Done by',
        Load: 'Load',
        Status: 'Status',
        Shipping: 'Delivery',
        Price: 'Price',
        product: 'Product',
        category: 'Grade',
        origin: 'Origin',
        size: 'Size',
        quantity: 'Quantity',
        unitPrice: 'Unit Price',
        totalPrice: 'Total Price',
        provider: 'Supplier',
        packaging: 'Packaging',
        client: 'Client',
      },
      TableDashboard: {
        Seller: 'Seller',
        Buyer: 'Buyer',
        Team: 'Workspace',
        Team_and_seller: 'Workspace & seller',
        Status: 'Status',
        Sales: 'Sales',
        Contact: 'Contact',
        Purchases: 'Purchases',
        Company: 'Company',
        Phone: 'Phone',
        Email: 'Email',
        EmailValidation: 'Email Validation',
        Workspace: 'Workspace',
        Date: 'Date',
        Client: 'Client',
        Supplier: 'Supplier',
        Delivery: 'Delivery',
        Articles: 'Items',
        Price: 'Price',
        order_view: 'Orders',
        delivery_view: 'Delivery view',
        request_view: 'Requests',
        Reference: 'Reference',
        Created: 'Created',
        Colleagues: 'Colleagues',
        Colleague: 'Colleague',
      },
      results: {
        zero: 'no results found',
        one: '{{count}} result found',
        other: '{{count}} results found',
      },
      Subtotal: 'Subtotal',
      unmatched_item: 'Item(s) not matched with product catalogue.',
      Aggregate: {
        Cta: 'Group',
      },
      Footer: {
        Orders: 'Selected orders',
        Load: 'Load',
        TotalPrice: 'Total price',
      },
      Results: ' Results for this selection',
      orders_found: ' Results found · ',
      from: 'From ',
      to: 'to',
      EmptyResults: {
        title: "You don't have any orders for those criteria",
        title_delivery: "You don't have any products delivered with these criteria",
        description: 'Start trading with your contacts to generate new orders or change filters',
        cta: 'Go to chat list',
      },
      emptySearch: {
        title: `We're sorry! We could not find the order '{{hashId}}'`,
        subtitle_sell: 'Try again with other criteria or make a new sale',
        subtitle_buy: 'Try again with other criteria or make a new order',
        cta: 'New sale',
      },
      noProducts: {
        title: 'Sorry! No results found for "{{product}}"',
        text: 'Try again with other criteria or talk to your providers so they can put it up for sale',
        cta: 'Go to my suppliers',
      },
      back: 'Back to my orders',
      field_incomplete: 'Incomplete',
      requests_description:
        'This view shows the requests from non registered users of Consentio that visit the public shop',
      request: {
        EmptyResults: {
          title: 'You don’t have any request that matches this criteria',
          description: 'Share your catalog and start getting new contacts!',
          cta: 'Share catalog',
        },
      },
      mismatch_price_tooltip:
        'This order has products with prices that do not match the price established in the price group.',
      download_PDF: 'Download PDF',
    },
    Modal: {
      loading: 'Loading...',
      accept: 'Accept',
      cancel: 'Cancel',
      delete: 'Delete',
    },
    Picture: {
      cancel: 'Cancel',
      save: 'Save',
      Dropzone: {
        subtitle: 'Add your Image',
        text: 'Drag here the file you want to import or select it from your computer',
        sizes: '(Recommended dimensions {{width}}x{{height}})',
        select: 'Select',
      },
      placeholder: {
        avatar: 'Add profile picture',
        product: 'Add product picture',
        group: 'Add group picture',
      },
    },
    Switch: {
      active: 'active',
      inactive: 'inactive',
      available: 'Available',
      unavailable: 'Out of stock',
      hidden: 'Hidden',
      yes: 'Yes',
      not: 'No',
    },
    Chat: {
      unread_comments: {
        one: '{{count}} Comment',
        other: '{{count}} Comments',
      },
      unread_updates: {
        one: '{{count}} Update not read',
        other: '{{count}} Updates not read',
      },
      unread_comments_updates: '{{count_comments}} Comments and {{count_updates}} Updates not read',
      send_to: 'Send to {{name}}',
      placeholder: 'Type a message',
      max_size_exceeded: 'Unable to upload file (Maximum size: {{max}}MB)',
      non_register: "{{name}} hasn't yet registered in Consentio.",
      image: 'Image',
      video: 'Video',
      imBlocking: 'You have blocked {{name}}, you must unblock it in order to send messages.',
      imBlockingLink: 'Unblock',
      you: 'You',
      reply: 'Reply',
      forward: 'Forward',
      tutorial: {
        title: 'Start interacting with {{contact_name}}!',
        text_buy: '\nPress BUY to place a purchase request',
        text_sell: '\n\nPress SELL to send a commercial offer',
      },
      resend_invite: 'Invite {{name}} to chat',
      invite_sent: 'Invitation sent.',
      delete: 'Delete',
      delete_message: {
        title: 'Delete message',
        description: "Are you sure you want to remove this message? This action can't be undone",
      },
      message_deleted: 'This message has been deleted',
      edit: 'Edit',
      edited: '(edited)',
      group_header: {
        title: 'Cannot find who you are looking for?',
        description: 'Invite people to participate in the group. You are unstoppable!',
        add_member: 'Add member',
        link_invite: 'Group invite link',
      },
      link_invite_modal: {
        description: 'Anyone in Consentio can join this group through the link. Share it only with people you trust.',
        send_link: 'Send link through Consentio',
        copy_link: 'Copy link',
      },
      invite_message: 'Open this link to join my group on Consentio: {{link}}',
      send_invite_title: 'Choose who you want to send the link to',
      create_contact_success: 'User added to your contacts',
      create_contact_success_sub: 'You can find the contact in your chats',
      emoji_search_placeholder: 'Search emoji',
    },
    ChatList: {
      tooltips: {
        workspace:
          'In this section you will have quick access to clients and buy-sell groups related to the selected workspace.',
        general: 'This section displays contacts not assigned as workspace clients, groups and broadcasting lists.',
        buySell:
          'Collaborate with your colleagues in a group where all participants can see the orders of the clients included in the group and send them new offers.',
      },
      workspace_chats: '{{name}} chats',
      general_chats: 'General chats',
      buy_sell_groups: 'Buy-sell groups',
      client_chats: 'Clients',
      supplier_chats: 'Suppliers',
      new_broadcast_created: 'Broadcast created successfully',
      new_group_created: 'Group created successfully',
      invite: {
        addressbook: {
          title: 'Invite your contacts from your phonebook',
          clients_title: 'Invite your clients from your phonebook',
          suppliers_title: 'Invite your suppliers from your phonebook',
          subtitle: 'Choose the contacts you want to invite to Consentio',
          cta_next: {
            one: 'Invite {{count}}',
            other: 'Invite {{count}}',
            zero: 'Invite',
          },
          cta_other: 'Invite by Email or SMS',
        },
        other: {
          title: 'Invite your contacts to Consentio',
          clients_title: 'Invite your clients to Consentio',
          suppliers_title: 'Invite your suppliers to Consentio',
          cta_addressbook: 'Invite from your phonebook',
        },
        cta: 'Invite to Consentio',
        clients: 'Invite clients',
        suppliers: 'Invite suppliers',
      },
      invited: 'Invited',
      invited_days: {
        one: '({{count}} day)',
        other: '({{count}} days)',
        zero: '(Today)',
      },
      resend_invitation: 'Resend invitation',
      sent_invitation: 'Invitation sent',
      group: 'Group',
      subtitle: {
        one: '{{amount}} pending order',
        other: '{{amount}} pending orders',
        zero: '',
      },
      search: {
        placeholder: 'Find by name, company name or phone',
      },
      blocked: 'Blocked',
      create_group: 'New group',
      notification: {
        title: 'Keep me updated, always',
        link: 'Activate desktop notifications',
      },
      invite_card: {
        title: 'Keep inviting your contacts',
        subtitle: 'Increase your network as other 7.000 users',
        cta: 'Invite to Consentio',
      },
      options: {
        archive: 'Archive',
        unarchive: 'Unarchive',
        mute: 'Mute notifications',
        unmute: 'Unmute notifications',
      },
      archived_chats: 'Archived chats',
      archived: 'Archived',
      new: 'New',
      working_status: {
        label: 'Current working status',
        link: 'More settings about working status',
      },
      invite_card_contacts: {
        title: '**Missing** your contacts?',
        description: 'Invite your contacts to join our community.',
        cta: 'Invite contacts',
      },
      zero_case: {
        empty: "'{{search}}' hasn't been found in your chats",
        phonebook_contacts_found: 'Found in your contacts',
      },
      groups: {
        title: 'Groups',
        cta: 'Create group / broadcast',
      },
      direct_messages: {
        old_title: 'Direct messages',
        title: 'Contacts',
        cta: 'Invite contacts',
      },
      public: {
        title: 'Channels',
        cta: 'Create channel',
      },
      unread: { title: 'Unread messages' },
    },
    SmallScreen: {
      title: 'For a better experience please use the app.',
      subtitle: 'You can download it for free:',
      cta: 'Download the mobile app',
      pricelist: {
        title: 'To start negociating with **{{contactName}}** from **{{companyName}}**, please download the app.',
      },
      MO: {
        title:
          'Magic orders is a webapp only functionality, for a better experience access the platform on your computer or tablet through',
      },
    },
    Order: {
      comments: 'Comments',
    },
    InviteOnboarding: {
      '1': {
        title: 'Welcome to Consentio, {{name}}!',
        text_seller1: '- Manage all your sales in the **same place**',
        text_seller2: '- **Real-time** information delivered to your clients',
        text_seller3: '- Access from **any device**',
        text_buyer1: '- Manage all your purchases on the **same site**',
        text_buyer2: '- Access from **any device**',
        text_buyer3: '- **Save time** in administration',
        next: 'Continue',
      },
      '2': {
        title: 'Consentio is better with contacts!',
        subtitle: 'Expand your network and streamline your processes',
        invite_sms: 'Invite by SMS',
        invite_email: 'Invite by email',
        email_placeholder: 'Eg. example@consentio.co',
        name_placeholder: 'Name (optional)',
        add_more: 'Add more',
        copy_link: 'Copy link',
        copy_link_2: 'to invite',
        skip: "I'll do it later",
        cta: 'Invite',
        other_ways: 'Alternatively you can invite via:',
        contacts_invite: 'Contacts invite',
        invited: {
          zero: 'The contact is already in Consentio',
          one: '{{count}} contact invited successfully',
          other: '{{count}} contacts invited successfully',
        },
        subject_share_message: 'Join me in Consentio!',
        share_message:
          'I collect all the orders and messages from my business in Consentio. If you also want to save time, it only takes a few seconds to download the app:',
      },
      step: 'Step {{step}} of {{total}}',
      skip_modal: {
        title: 'Continue without inviting my contacts',
        description: {
          buyer: 'To be able to buy products within the platform you need to have contacts in Consentio.',
          seller: 'To be able to sell products within the platform you need to have contacts in Consentio.',
        },
        invite: 'Invite',
        skip: 'Skip this step',
      },
    },
    ImageGallery: {
      count_separator: 'of',
      right_arrow_title: 'Show next image',
      left_arrow_title: 'Show previous image',
      close_title: 'Close gallery',
    },
    Settings: {
      length_unit: {
        label: 'Length',
        description:
          'Choose the unit you use to measure the length of boxes or pallets. This unit will be visible to your clients.',
        modal: {
          title: 'Change unit of length',
          description:
            'When modifying the length unit no conversions will be made, only the unit will be changed keeping the specified length.',
          cta: 'Accept and continue',
          cancel: 'Cancel',
        },
      },
      Menu: {
        profile: {
          title: 'Basic info',
          description: 'User settings',
        },
        config: {
          title: 'Use of Consentio',
          description: 'Public store settings',
        },
        notifications: {
          title: 'Notification management',
          description: 'Configure the way you are notified',
        },
        addresses: {
          title: 'My addresses',
          description: 'Manage or create addresses',
        },
        security: {
          title: 'Security and account access',
          description: 'Manage your account security',
        },
      },
      team_name: 'Name of your workspace',
      team_name_description: 'Set a name to easily identify your workspace',
      team_name_placeholder: 'your workspace name',
      orders_email: 'Send a copy of the orders by email',
      orders_email_description: 'Enter an email address to receive all the orders from this workspace',
      orders_email_placeholder: 'Ex. example@consentio.co',
      orders_email_zerocase: 'Email undefined',
      profile: 'Basic Info',
      name: 'Name',
      name_placeholder: 'your visible name',
      company_placeholder: 'your company name',
      email_placeholder: 'a valid email address',
      email_not_verified_1: 'Email has not been validated yet. Please check your email inbox or ',
      email_not_verified_link: 'resend me a verification email',
      email_verification_sent: 'The verification email has just been sent to {{email}}',
      email_verification_already_sent: 'The verification email has already been sent to {{email}}',
      error_email_empty: 'Email cannot be empty',
      error_email_valid: 'Email format is not valid',
      company: 'Company',
      company_logo: 'Logo',
      company_logo_zero: 'Drag or select file',
      company_image: 'Main image',
      company_image_zero: 'This workspace has no main image yet.',
      company_color: 'Corporate color',
      company_color_zero: 'The corporate color has not been defined for this workspace.',
      company_logo_description: 'Upload your company logo.',
      company_image_description: 'Upload the image you want to appear in your online store.',
      company_color_description: 'Select your corporate color by clicking on the box below.',
      email: 'E-mail',
      phoneNumber: 'Phone',
      about_you: {
        zero: 'There is no additional information added.',
      },
      language: {
        label: 'Language',
      },
      timezone: {
        label: 'Time zone',
        description:
          'You can choose which timezone will be used in communications (i.e. emails). App and webapp timezone configuration will be retrieved from device instead of this setting.',
      },
      dateFormat: {
        label: 'Date format',
        description: 'Choose how you would like to see dates',
        footer: 'Ex. Today is {{date}}',
      },
      hourFormat: {
        label: 'Hour format',
        H24: '24 hour clock (13:00)',
        H12: '12 hour clock (1:00pm)',
      },
      hidePhone: {
        info: 'Phone number only visible for you. **Change**',
        description: 'Nobody can see your phone number',
        switch: 'Visible phone number for my contacts',
      },
      showPhone: {
        info: 'Visible phone number for all your contacts. **Change**',
        description: 'Your contacts are able to see your phone number',
        switch: 'Visible phone number for my contacts',
      },
      remove_secondary_currency_modal: {
        title: 'Remove {{currency}} support',
        body: 'You are going to remove {{currency}} support.\n\nIf you continue, all your clients who are currently using {{currency}} with you, will see your products in {{defaultCurrency}}.',
      },
      default_currency: {
        label: 'Main currency',
        description:
          'The currency used for your prices. Your clients will see prices in this currency (unless you configure them to use another currency).',
        modal: {
          title: 'Currency change',
          cta: 'Ok, continue',
          cancel: 'Cancel',
          description:
            'If you change your currency to {{currency}}, all your products prices will be assumed in {{currency}}.',
          description_exchange:
            'Additionally, since your main currency changed, you will need to re-enter the exchange rates for the additional currencies you have.',
        },
      },
      weight_unit: {
        label: 'Weight',
        description:
          'Choose the unit you use to measure the weight of the products. This will be the unit that your clients will see.',
        modal: {
          title: 'Change weight unit',
          description:
            'When modifying the weight unit, no conversions will be made, only the unit will be changed while maintaining the specified weight.',
          cta: 'Ok, continue',
          cancel: 'Cancel',
        },
      },
      secondary_currencies: {
        add: 'Add more currencies',
        label: 'Additional currencies',
        description:
          'You can set a specific currency for each of your clients. To enable an additional currency, please enter the exchange rate from {{defaultCurrency}}.',
      },
      pricelist: {
        title: 'Online Shop',
        description:
          'Make your online shop visible to everyone by sharing the link. Prices can only be seen by clients explicitly authorised in your client settings.',
        label: 'Public catalog',
      },
      config: 'How you use Consentio',
      address: {
        name: 'Name',
        country: 'Country',
        zip: 'Postal code',
        city: 'City',
        rest: 'Address line',
        transportCost: 'Transport cost',
        EmptyResults: {
          text: "You don't have any addresses yet.\nStart adding a new address here.",
          cta: 'Add new address',
        },
      },
      edit_address: 'Edit address',
      delete_address: 'Delete address',
      make_default_address: 'Make as default address',
      default: 'Default',
      default_address_tooltip:
        'This is the address we will use by default in the offers you send to this client. You will be able to change it in the order.',
      notifications: {
        title: 'Notification management',
        subtitle: 'Choose the information you get from email and push',
        push: 'Push notifications',
        email: 'Email notifications',
        chat_private_message: 'Chat messages',
        chat_group_message: 'Group messages',
        order_new: 'New order',
        order_accepted: 'Accepted order',
        order_incomplete: 'Incomplete Magic Order imported by email',
        order_canceled: 'Canceled order',
        order_comment: 'Comments in orders',
        order_attachment: 'Attachments in orders',
      },
      error: 'Sorry an error occurred: {{error}}\nPlease try again',
      saved: 'Changes saved successfully',
      saved_subtitle: 'The user data have been updated successfully',
      sections: {
        title: 'Product sections',
        description:
          'Choose how you want to display your product catalog. You can show sections or the products by themselves.',
        switch_label: 'Display sections in my catalog',
        input_label: 'Select a tag to create a new section',
        input_placeholder: 'Search for a tag',
        input_description: 'Order and customize your sections adding images and/or translations.',
        empty_section: "You don't have any sections yet. Start selecting a tag to create a new one.",
        empty_tag: "You don't have any sections yet. Go to your products and start assigning them new tags.",
        tag: 'Section name',
        no_tag_available: 'No tags available',
        translate: 'Translate',
        confirmation_delete: "Are you sure you want to remove this section? This won't appear in your catalog anymore",
        confirmation_delete_language:
          'If you remove this language, you will loose all the related translations, are you sure to proceed?',
      },
      business_type_section: {
        business_types: {
          wholesaler: 'Wholesaler',
          grower: 'Producer',
          supermarket: 'Supermarket',
          point_of_sale: 'Hospitality and groceries',
          consumer: 'Consumer',
          unknown: 'Unknown',
        },
        consentio_for: 'Consentio for {{company_use}}',
        company_use: {
          sell: 'sellers',
          buy: 'buyers',
          both: 'buyers & sellers',
        },
        description: 'By identifying as a {{business_type}}, you have joined {{consentio_for}}.',
        disclaimer: "If this does not reflect your company's activity, please, contact support to change your choice.",
      },
      working_status: 'Status and availability',
      working_status_subtitle: 'Inform others about your availibity',
      return_availability: 'To be available again in',
      return_availability_description: "Choose how long you want your contacts to see you as 'available' again.",
      mute_notifications: 'Pause push notifications',
      notification_message: 'Notification to whoever interacts with you',
      notifications_delegate: 'Person who will receive your order notifications',
      delegate_description: 'Only your colleagues with editing permission will be able to interact with the order',
      security: {
        '2fa': 'Two-Factor authentication',
        subtitle_enabled:
          'Protect your account against unauthorized access using a text message (SMS) as an additional authentication method besides your Consentio password.',
        subtitle_disabled:
          "Protect your account against unauthorized access by using a SMS as an additional authentication method in addition to your Consentio password. To activate Two-Factor authentication, we'll send a verification code to **{{phone}}**.",
        subtitle_disabled_no_phone:
          "Protect your account against unauthorized access by using a SMS as an additional authentication method in addition to your Consentio password. To activate Two-Factor authentication, we'll use a phone number that will be linked to your Consentio account.",
        activated: 'Activated.',
        deactivated: 'Deactivated.',
      },
    },
    FileMessage: {
      Download: 'Download',
    },
    ProductsImport: {
      subtitle: 'Import CSV catalog',
      Header: {
        title: 'Import products',
        subtitle:
          'Upload a CSV file with your products to update your list. To make it work you need to use Consentio format.',
        more_info: 'More information',
      },
      UploadButton: 'Update product list',
      FileDescription: {
        subtitle: 'Add your file',
        file_count: '1 file enclosed',
        cancel: 'Cancel',
      },
      Dropzone: {
        subtitle: 'Add your file',
        text: 'Drag here the file you want to import or select it from your computer',
        select: 'Select',
        change: 'Change',
      },
      Notification: {
        title: 'Product list updated successfully',
        subtitle: 'Your colleages and buyers can now see the changes',
      },
      Checkbox: {
        text: 'Replace all my products with the imported ones',
      },
      Issues: {
        title: 'Import incidents',
        ribbon:
          'There have been some issues with the import. **Review** them and upload your file with the saved changes.',
        subtitle: 'Do you have any doubt about how to solve an issue?',
        subtitle_link: 'Access to Consentio University',
        product_sku: 'Product with SKU: **{{sku}}**',
        product_ean: 'Product with EAN: **{{eanCode}}**',
        product_type: 'Product with type: **{{typeVariety}}**',
      },
    },
    AddNonRegisteredUser: {
      name: 'Name',
      phone: 'Phone',
      email: 'Email',
      email_placeholder: 'new_user@mail.com',
      cta: 'Add',
      title_add_client: 'Add new client',
      title_add_client_supplier: 'Add new client / supplier',
      title_add_supplier: 'Add new supplier',
      subtitle: 'Enter some details for the user you want to add.',
      country_placeholder: 'Country code',
      phoneNumber_placeholder: '123 456 789',
      username_placeholder: 'Full name',
    },
    AddressAutoComplete: {
      display_name: 'Address name',
      display_name_placeholder: 'Write a name to identify the address',
      address: 'Address',
      address_placeholder: 'Write here your address',
      address_additional_info: 'Additional information',
      address_additional_info_placeholder: 'Write additional information about the address',
      external_id: 'External ID',
      external_id_placeholder: 'Write the ID from your ERP',
      transport_cost: 'Transport cost',
      add_address: 'Add address',
      save: 'Save changes',
      cancel: 'Cancel',
      error_autocomplete: 'Please click on input above and select one of the suggested address',
      view_all_fields: 'View all fields',
      rest: 'Address line',
      rest_placeholder: 'Street, Avenue, nº, etc.',
      zip_code: 'ZIP code',
      city: 'City',
      city_placeholder: 'Write here the city',
      country: 'Country',
      country_placeholder: 'Select country',
      latitude: 'Latitude',
      longitude: 'Longitude',
      latlong_placeholder: '0.0000000',
    },
    costImport: {
      header: {
        title: 'Import costs',
        subtitle:
          'Upload a CSV file with your products to update your costs. To make it work you need to use Consentio format.',
        moreInfo: 'More information',
      },
      uploadButton: 'Update costs',
      fileDescription: {
        subtitle: 'Add your file',
        fileCount: '1 file attached',
        cancel: 'Cancel',
      },
      dropzone: {
        subtitle: 'Add your file',
        text: 'Drag the file you wish to import here or select it from your computer',
        select: 'Select',
        change: 'Change',
      },
      notification: {
        title: 'Costs updated successfully',
        subtitle: 'Your team can now see the changes',
      },
      checkbox: {
        text: 'Replace all my costs with the imported ones',
      },
      issues: {
        title: 'Import Issues',
        ribbon: 'Some issues occurred during the import. **Review them** and upload your file with the saved changes.',
        subtitle: 'Do you have questions on how to resolve an issue?',
      },
      formatError: {
        title: 'File format not allowed',
        description: 'Allowed format: .csv',
      },
    },
  },
  Showroom: {
    changeAddressCart: 'If you change the delivery address, your will lose the products in your cart',
    addressModalTitle: 'Choose delivery address',
    addressModalDescription: 'Select a delivery address to view the exact prices of the products',
    addressSeleceted: 'Prices for:',
    buy: 'BUY',
    sell: 'SELL',
    sale_cta: 'New sale',
    items: {
      one: 'item',
      other: 'items',
    },
    back_sections: 'Back to products home',
    sections: 'Product sections',
    products: {
      one: '{{count}} item',
      other: '{{count}} items',
    },
    no_tag: 'Other products',
    SendExcel: {
      fileNamePreffix: 'Pricelist',
      modalTitle: 'Send Excel **pricelist**',
      modalSubTitle: 'The client will receive the pricelist, specially chosen for them',
      modalBody: 'Send tailored pricelist in excel format to {{name}} from {{company}}, to email **{{email}}**',
      modalBodyNoCompany: 'Send tailored pricelist in excel format to {{name}}, to email **{{email}}**',
      modalCTA: 'Send',
      dropdown_send: 'Send pricelist by email',
      dropdown_download: 'Download pricelist',
      dropdown_subtitle: 'Excel xlsx format',
      notification_title: 'Pricelist email has been sent!',
      notification_subtitle: "We've just sent an excel file of your prices by email",
    },
    featured: {
      favorite: 'Favorites',
      recommended: 'Recommended',
      recent: 'Recent',
      zero_favorite_seller: "Here you'll see all products saved as **favorites** from the buyer",
      zero_favorite_buyer: "Here you'll see all products you saved as **favorites**",
      zero_recommended: "Here you'll see all products you **recommended** to the buyer",
      zero_recent_seller: 'Do your first order to see your products sold **history**',
      zero_recent_buyer: 'Do your first order to see your products purchased **history**',
    },
    back_to_chat: 'Back to the chat with {{name}}',
    change: 'Change',
  },
  Pricelist: {
    for_ios: 'IPhone',
    for_android: 'Android',
    consentio:
      'Download your Consentio application for FREE: Make and manage your orders with {{name}} whenever and wherever you want, easily and quickly.',
    language: 'Language',
    chat_with: 'Chat with {{name}}',
    go_my_products: 'Go to my products',
    back: 'Go back to the product list',
    close: {
      title: 'Sorry!\nThis page is not available',
      description: 'Ask the seller to publish his catalog or check it through Consentio',
      cta: 'Got it',
    },
  },
  order: {
    closed: {
      canceled: 'Cancelled: ',
      accepted: 'Accepted: ',
    },
    product: {
      boxWeight: 'Box weight',
      box_type: 'Box',
      boxesPerPallet: 'Boxes / pallet',
      brand: 'Brand',
      category: 'Grade',
      eanCode: 'EAN Code (GTIN)',
      organic: 'Organic',
      origin: 'Origin',
      packaging: 'Packaging',
      pallet_type: 'Pallet',
      pieces_per_box: 'Units / box',
      sku: 'SKU',
    },
    details: 'See order',
    info: 'Order Information Nº',
    creationDate: 'Creation date: ',
    total: 'Load: ',
    priceUnit: 'Price: ',
    totalPrice: 'Total order price:',
    articlesTitle: 'Items',
    infoTitle: 'Info',
    transportTitle: 'Transport',
  },
  deep_link: {
    title: 'Consentio',
    description: 'Open this link or download the app Consentio',
    pricelist: {
      title: "{{name}}'s Online Shop ({{company}})",
      description: 'Check the products that {{name}} has for sale, and place orders easily through their Online Shop.',
    },
    pricelist_product: {
      title: 'Check out my product {{productName}}! | {{companyName}} | Consentio',
      description: 'Visit the online store of {{name}} ({{companyName}}) and discover many more products!',
      variety: 'Variety: {{variety}}',
      origin: 'Origin: {{origin}}',
      category: 'Category: {{category}}',
      size: 'Size: {{size}}',
    },
  },
  LoginLanding: {
    title: 'The revolution for all professionals in the fresh produce industry',
    subtitle_1: 'Buying and selling in bulk has never been easier and faster.',
    subtitle_2: 'All your messages, products and orders in one place! Access from anywhere and any device.',
    footer: {
      what_is_consentio: 'About Consentio',
      create_account: 'Create account',
      begin_session: 'Log in',
      about_us: 'About us',
      contact_support: 'Contact with support',
      ask_demo: 'Request a demo',
      social_networks: 'You can also find us on our networks',
    },
    item_1: 'All your business KPIs in one place',
    item_2: 'Buy and sell from anywhere',
    item_3: 'Save time and speed up your sales',
    item_4: 'Simplify your business operations',
  },
  modal_error: {
    text: 'Sorry, there was an error in the update, try again or contact support',
    accept: 'ACCEPT',
  },
  email_verification: {
    title_success: 'Email verified',
    body_success: 'Your email {{email}} has been marked as verified.',
    title_error: 'Invalid link',
    body_error: "The link you used seems to be invalid and email couldn't be verified.",
    cta_success: 'Got it!',
    cta_error: 'Contact support',
  },
  ProductListShare: {
    copied: 'Copied to Clipboard!',
    header_text: 'Send or share',
    header_text_primary: ' all your products',
    header_text_primary_search: " the search:'{{search}}'",
    header_text_primary_product: ' the product',
    share1: 'Share ',
    share_open: 'Open link',
    share_copy: 'Copy link',
    product: 'product',
    catalog: 'catalog',
    search: 'search',
    cta: 'Send to {{number}}',
    search_placeholder: 'Search by name',
    email_subject: 'Look at my products',
    email_title: 'Hi there,\nPlease find here the product(s) that I want to share with you:',
    not_pricelist_buyer:
      'Products from {{sellerName}} are not visible outside of Consentio. To share, ask this person to allow it.',
    not_pricelist_seller:
      'You must make the catalog visible in order to share it. Prices will only be seen by authorized persons',
    open_catalog: 'Visible catalog',
    share_qr: 'QR code',
    qr_download: 'Save code as image',
    own_catalog: 'My catalog of products',
    other_catalog: "{{name}}'s catalog of products",
    own_product: 'My product: {{productName}}',
    other_product: "{{name}}'s product: {{productName}}",
    qr_description: 'Show this QR code to anyone so that he can scan it and see the products instantly',
  },
  modal_notification: {
    title: 'Enable notifications',
    body: "To be aware about what's happening in Consentio, please enable the notifications. Check additionally that your browser is allowed to show them.",
    cta: 'Got it!',
  },
  tutorials: {
    share: {
      step0: {
        title: 'There are new features!',
        description: 'Now you can **share** and **send** your products to whoever you want.',
        cancelText: 'Don’t show again',
        ctaText: 'View',
      },
      step1: {
        title: 'Send or share **catalog**',
        description: 'Send or share the catalog with **all your products** to as many contacts as you like.',
        cancelText: 'Exit',
        ctaText: 'Next',
      },
      step2: {
        title: 'Send or share **search**',
        description: 'Just type in a keyword and send or share the results.',
        cancelText: 'Exit',
        ctaText: 'Next',
      },
      step3: {
        title: 'Send or share **product**',
        description: 'Send or share **a product from your catalog** to any of your contacts.',
        cancelText: 'Exit',
        ctaText: 'Understood',
      },
    },
    tags: {
      step0: {
        title: 'Add tags',
        description: 'Create and assign tags to your products (new or existing) to customize your catalog.',
        cancelText: "Don't show again",
        ctaText: 'Next',
      },
      step1: {
        title: 'Catalog View',
        description: 'Choose how you want to show your catalog: with or without sections.',
        cancelText: 'Exit',
        ctaText: 'Next',
      },
      step2: {
        title: 'Add sections',
        description: 'Categories are created from tags. Choose a tag to add all associated products to a category.',
        cancelText: 'Exit',
        ctaText: 'Next',
      },
      step3: {
        title: 'Sort your sections',
        description:
          'Drag sections so they appear in the desired order in the catalog. Add images and translations to increase engagement.',
        cancelText: 'Exit',
        ctaText: 'Next',
      },
      step4: {
        title: 'Catalog with sections',
        description: 'This is how your catalog will be shown.',
        cancelText: 'Exit',
        ctaText: 'Understood',
      },
    },
  },
  Onboard: {
    Business: {
      Title: 'How do you best define your activity?',
      ButtonA: 'Producer',
      ButtonB: 'Wholesaler',
      ButtonC: 'Supermarket',
      ButtonD: 'Hospitality and groceries',
      ButtonE: 'Consumer',
      SubtitleA: 'Grower, Farmer, Cooperative',
      SubtitleB: 'Importer, Exporter, Broker, Trader',
      SubtitleC: '',
      SubtitleD: 'HORECA, Grocery shop',
      SubtitleE: '',
    },
  },
  SelectSearch: {
    no_options: 'No options available',
    create_value: 'Create new tag "{{inputValue}}"',
  },
  Widgets: {
    OrderSummary: {
      title_sale_all: 'Total accepted sales',
      title_purchase_all: 'Total accepted purchases',
      title_sale_today: "Total current today's sales",
      title_purchase_today: "Total current today's purchases",
      title_sale_yesterday: "Total current yesterday's sales",
      title_purchase_yesterday: "Total current yesterday's purchases",
      title_sale_week: "Total current week's sales",
      title_purchase_week: "Total current week's purchases",
      title_sale_month: "Total current month's sales",
      title_purchase_month: "Total current month's purchases",
      title_sale_year: "Total current year's sales",
      title_purchase_year: "Total current year's purchases",
      title_sale: 'Sales total',
      title_purchase: 'Purchases total',
      count_orders: {
        one: '{{count}} order',
        other: '{{count}} orders',
      },
      change_variation_today: '{{percentage}}% more than the yesterday',
      change_variation_yesterday: '{{percentage}}% more than the day before yesterday',
      change_variation_week: '{{percentage}}% more than the past week',
      change_variation_month: '{{percentage}}% more than the past month',
      change_variation_year: '{{percentage}}% more than the past year',
      change_variation_custom: '{{percentage}}% more than the past period',
      previous_gmv_day_before: 'The day before yesterday: {{amount}}',
      previous_gmv_yesterday: 'Yesterday: {{amount}}',
      previous_gmv_past_week: 'The past week: {{amount}}',
      previous_gmv_past_month: 'The past month: {{amount}}',
      previous_gmv_past_year: 'The past year: {{amount}}',
      previous_gmv_past_period: 'The past period: {{amount}}',
    },
    TopUsers: {
      top_clients: 'Top clients',
      top_suppliers: 'Top suppliers',
    },
    TopProducts: {
      title_sale: 'Top products sold',
      title_purchase: 'Top products purchased',
      part_total_sales: '{{percentage}}% of the total',
      part_total_purchases: '{{percentage}}% of the total',
    },
    BarChart: {
      week_abbreviation: 'W',
      title_sale: 'Sales evolution',
      title_purchase: 'Purchases evolution',
      total: 'Total:',
    },
  },
  Catalog: {
    add_members: {
      title: 'Add colleagues',
      role_label: 'What can your colleagues do? **You can change it later**',
      add: {
        one: 'Add {{count}}',
        other: 'Add {{count}}',
        zero: 'Add',
      },
      zero_case: 'All your contacts have been added',
    },
    update_members: {
      title: 'Colleagues in this workspace',
      remove: 'Are you sure to remove {{name}} from the workspace?',
    },
  },
  Workspaces: {
    change_status_title: 'Product visibility changed successfully!',
    active_subtitle: "Now your clients will be able to see {{name}}'s products.",
    inactive_subtitle: '{{name}} products are no longer visible to your clients.',
    Label: 'Which workspace do you want to access?',
    by_default: 'By default',
    default_tooltip:
      'Products visible by default for your contacts. You can specify this setting for each contact from Chats > Information',
    seller: {
      subtitle: 'Product visibility settings are personal.',
      active: {
        title: 'Workspaces with products for sale',
        description:
          'Products for sale by default are shown to your new contacts and to your contacts without custom setting.',
      },
      inactive: {
        title: 'Workspaces with hidden products',
        zero_case: "You don't have any workspace with hidden products",
      },
      active_section: {
        title: 'Your contacts can see the products of this workspace',
        description: 'Each workspace member has a personal configuration for clients visibility',
      },
      inactive_section: {
        title: 'Your contacts CANNOT see the products of these workspace',
        description: 'This configuration is unique for each account, it is not related to your colleagues',
        zero_case: "You don't have any workspace with hidden products",
      },
      members_using: {
        zero: 'Nobody is showing these products',
        one: '{{count}}/{{count_all}} of your colleagues is showing these products',
        other: '{{count}}/{{count_all}} of your colleagues are showing these products',
      },
      one_member_using: 'Your colleague is showing these products',
      members_using_including_me: {
        zero: 'You are showing these products',
        one: 'You and {{count}}/{{count_all}} of your colleagues are showing these products',
        other: 'You and {{count}}/{{count_all}} of your colleagues are showing these products',
      },
      one_member_using_including_me: 'You and your colleague are showing these products',
      enable_catalog: 'Enable products to sale',
      disable_catalog: 'Hide products from your clients',
      default_catalog: 'Activate products for sale by default',
      status: {
        active: 'Products on sale',
        inactive: 'Hidden products',
      },
      confirm_modal: {
        description:
          'Contacts without a custom setting will see the products in this workspace when you make a new purchase.',
        cta: 'OK',
        cancel_cta: 'Cancel',
        title: 'Sell by default the products of ‘{{name}}’',
      },
      confirm_modal_old: {
        description:
          'Currently you can only purchase with a single workspace. Do you want to enable the selected workspace?',
        cta: 'Activate workspace',
        cancel_cta: 'Cancel',
        title: 'Buy from "{{team_name}}"',
        subtitle: 'Activate this option so that your new orders are assigned to this workspace',
      },
    },
    buyer: {
      members_using: {
        zero: 'Nobody is buying from this workspace',
        one: '{{count}}/{{count_all}} of your colleagues is buying from this workspace',
        other: '{{count}}/{{count_all}} of your colleagues are buying from this workspace',
      },
      members_using_including_me: {
        zero: '',
        one: 'You and {{count}}/{{count_all}} colleagues',
        other: 'You and {{count}}/{{count_all}} colleagues',
      },
      active_section: {
        title: 'Buy from this workspace',
        description: 'The purchases you make will be assigned to this workspace.',
      },
      inactive_section: {
        title: 'Other workspaces',
        description: '',
        zero_case: "You don't have any other buyer workspace",
      },
      status: {
        active: 'Buy from this workspace',
        inactive: 'Not buying from this workspace',
      },
      zero_case: {
        title: 'Create your buyer workspace',
        description: 'Collect and share products, orders and much more with your colleagues',
        cta: 'Create workspace',
        description_old:
          'Do your first purchase in order to create your workspace and start working with your colleagues!',
        cta_old: 'Go to chats',
      },
      one_member_using_including_me: 'You and your colleague are buying from this workspace',
      enable_catalog: 'Buy from this workspace',
      confirm_modal_old: {
        title: 'Show products of “{{team_name}}”',
        subtitle: 'Show these products so that your contacts can buy them',
        description:
          'Currently you can only have one workspace with visible products. Do you want to enable the selected workspace?',
        cta: 'Yes, change',
        cancel_cta: "No, don't change",
      },
    },
    no_active_workspace: "You don't have any active workspace",
    products: {
      one: 'Product',
      other: 'Products',
    },
    clients: {
      one: 'Related client',
      other: 'Related clients',
    },
    providers: {
      one: 'Associated supplier',
      other: 'Associated suppliers',
    },
    notification: {
      title: 'Visible products',
      subtitle: 'Your products from "{{team_name}}" are now visible',
    },
  },
  WorkspaceSettings: {
    Menu: {
      Palletization: {
        title: 'Palletization',
        description:
          'To adapt to variations in palletization with your customers, activate the multicase per pallet option.',
        switch_label: 'Multiboxes per pallet activated',
        switch_on:
          'A single product with SKU/EAN can have multiple palletization configurations, allowing you to assign the appropriate option to your customers.',
        switch_off: 'A single product with SKU/EAN may only have a single configuration for cases per pallet.',
        support: 'If you wish to activate this option, **please contact support.**',
      },
      MagicOrder: {
        title: 'Magic Order Order email notification',
        switch_label: 'Incomplete order email notifications',
        switch_on:
          'In case of Magic Order sent via email, receive a notification when your order stays in Incomplete status. This applies per default to all colleagues of the Workspace',
      },
      ShippingOrder: {
        title: 'Shipping orders',
        description: 'Shipping days, hours, etc.',
      },
      Team: {
        title: 'General',
        description: 'Workspace settings',
      },
      ShopOnline: {
        title: 'Online shop',
        description: 'Public store settings',
      },
      Currency: {
        title: 'Currency preferences',
        description: 'Currency exchange',
      },
      Prices: {
        title: 'Prices preferences',
        description: 'Configure how do you want to manage prices',
      },
      Measures: {
        title: 'Units of measure',
        description: 'Weight and length preference',
      },
      Product: {
        title: 'Product preferences',
      },
      Pdf: {
        title: 'Orders in PDF',
        description: 'PDF download settings',
      },
      PdfConfig: {
        title: 'Orders',
        description: 'Adjust the XLS and PDF',
      },
      Magic: {
        title: 'Magic orders',
        description: 'Orders import',
      },
      Addresses: {
        title: 'Delivery',
        description: 'Addresses and schedule',
      },
      PickupAddresses: {
        title: 'Pickup addresses',
        description: 'Where your clients will pick up the orders',
      },
      Order: {
        title: 'Orders',
      },
    },
    Distribution: {
      label: 'Distribution of products',
      description: 'Configure the types of boxes and pallets you use for your products.',
      description_alt_units: 'Configure the types of shelves and CC you use for your products.',
      boxes: {
        title: 'Boxes ({{count}})',
        table_empty: 'You have not added any box type yet',
        modal_title: 'Add box type',
        modal_description: 'Create a new box model and add the corresponding information.',
        modal_title_edit: 'Edit box type',
        modal_description_edit:
          'Edit the information of your box so that we can use it correctly. By modifying it, it will be changed in all the products that use it.',
        name: 'Name',
        cost: 'Cost',
        tare: 'Tare',
        external_id: 'External ID',
        cancel: 'Cancel',
        save: 'Save changes',
        create_cta: 'Add box type',
        cost_tooltip: 'Add the price your company paid for each box. It will not affect the price of the product.',
        notification_success: 'Box type created successfully',
        edit: 'Edit box',
        delete: 'Delete box',
        modal_delete: {
          title: "Delete '{{name}}'",
          description:
            'When you delete this type of box, you will have to choose a new one for those products that had it selected.',
          cta: 'Delete box',
        },
        notification_delete: 'Box type deleted successfully',
      },
      shelves: {
        title: 'Shelves ({{count}})',
      },
      pallets: {
        title: 'Pallets ({{count}})',
        create_cta: 'Add pallet type',
        table_empty: 'You have not added any pallet type yet',
        modal_title: 'Add pallet type',
        modal_description: 'Create a new pallet model and add the corresponding information.',
        modal_title_edit: 'Edit pallet type',
        modal_description_edit:
          'Edit the information of your pallet so that we can use it correctly. By modifying it, it will be changed in all the products that use it.',
        cost_tooltip: 'Add the price your company paid for each pallet. It will not affect the price of the product.',
        notification_success: 'Pallet type created successfully',
        edit: 'Edit pallet',
        delete: 'Delete pallet',
        modal_delete: {
          title: "Delete '{{name}}'",
          description:
            'When you delete this type of pallet, you will have to choose a new one for those products that had it selected.',
          cta: 'Delete pallet',
        },
        notification_delete: 'Pallet type deleted successfully',
      },
      carts: {
        title: 'CC ({{count}})',
      },
    },
    Members: {
      title: 'Colleagues in this workspace',
      subtitle: 'Access to see and manage your colleagues of {{workspace}}',
      link: 'View or manage colleagues',
    },
    ShippingOrder: {
      description: 'Set the shipping settings for your workspace orders.',
      days: { label: 'Order shipping days', footer: 'Order shipping days: ' },
      days_range: 'from {{from}} to {{to}}',
      all_days: 'All days',
      time: {
        label: 'Transport time',
        description:
          'Set the time it takes for orders to reach their destination from when you send them. You can specify another in the delivery addresses of your clients.',
        values: {
          zero: 'Less than 24 hours',
          one: '{{count}} day',
          other: '{{count}} days',
        },
      },
      cutoffTime: {
        label: 'Time limit',
        description: `Set the 'cut-off' time for today's deliveries. Orders received after this time will be shipped on the next working day`,
        values: '{{count}} hours',
      },
    },
    Decimals: {
      label: 'Number of decimals you would like to show',
      description:
        'It will apply on all product prices of this workspace. It will be visible for your clients and colleagues',
      footer2: 'Example: 2.35',
      footer3: 'Example: 2.350',
      decimal: {
        one: 'decimal',
        other: 'decimals',
      },
    },
    OnlineShop: {
      switch: 'Online shop visible',
    },
    Pdf: {
      description:
        'The orders in PDF format will contain the price of the products and the total price as long as they can be displayed.',
      switch: 'Include prices in the PDF',
    },
    PdfConfig: {
      description: 'Customize the information you want to appear when you download an order in PDF or Excel.',
      Add: 'New format',
    },
    Order: {
      download_pdf: {
        title: 'Download in Excel and PDF',
        description: 'Customize your download and print formats for individual orders and preparation',
      },
      sort_items: {
        title: 'Order of products',
        description:
          'Choose in which order you want the products to appear in your orders. It will be applied for uploading to your ERP and for downloading to Excel and PDF.',
        description_buyer:
          'Choose in which order you want the products to appear in your orders. It will be applied for downloading to Excel and PDF.',
        modal_title: "Set order '{{option}}' for products in all your orders",
        modal_description:
          'This includes, in addition to the orders from Consentio, the PDF export, Excel or upload to your ERP.',
        modal_cta: 'Change',
      },
      order_erp: {
        title: 'Validation of ERP submission',
        description: 'Choose whether accepted orders are sent to the ERP automatically or with a manual validation.',
        automatic: 'Send automatically',
        manual: 'Validate manually to send',
        footer_manual:
          "When an order goes to 'Accepted' status, you will have the option to send it to the ERP whenever you want.",
        footer_auto: "We will automatically send all orders to your ERP once they reach 'Accepted' status.",
        modal: {
          manual: {
            title: 'Switch to manual ERP submission',
            subtitle:
              "From now on, you will have to submit every order that goes to 'Accepted' status manually to the ERP.",
          },
          automatic: {
            title: 'Switch to automatic submission to ERP',
            subtitle:
              'As long as this option is selected, accepted orders that have not yet been sent to the ERP cannot be sent automatically.',
          },
          cta: 'Accept and change',
          cancel: 'Cancel',
        },
      },
    },
    Magic: {
      label: 'Product reference for import',
      description: 'Choose the type of code you use when importing your orders.',
      ean_code: 'EAN (GTIN)',
      sku: 'SKU',
    },
    PdfFormat: {
      label: 'PDF Format to download',
      comfortable: 'Comfortable',
      compact: 'Compact',
    },
    error: 'Something went wrong',
    error_description: 'We could not update the settings',
    success: 'Settings changes saved',
    success_description: 'Settings have been updated successfully',
    pickup_addresses: 'Pickup addresses',
    no_pickup_addresses: 'No pickup addresses',
    zero_case: `You haven't added your warehouses addresses yet.`,
    delivery_addresses: 'Delivery addresses',
    no_delivery_addresses: "You haven't created any delivery address yet.",
    missing_information: 'Missing information',
    empty_field: 'Empty field',
    shop: {
      company_brief: {
        title: 'Company brief',
        description: 'Tell us a little bit about your business and the products you usually buy or sell',
        placeholder: 'Write here your company information',
      },
      working_structure: {
        title: 'Working structure',
        description:
          'Give us more details about how you work, your working hours, delivery days, delivery areas, deadlines, etc',
        placeholder: 'More detailed info about your working structure',
      },
      additional_info: {
        title: 'Additional information',
        description: 'Write any additional information you want to publish to your contacts',
        placeholder: 'More detailed info about the company, products or items',
      },
    },
  },
  WorkspaceSections: {
    Menu: {
      Show: {
        title: 'Show',
        description: 'Show sections to the buyer',
      },
      Manage: {
        title: 'Manage',
        description: 'Create and manage sections',
      },
    },
    error: 'Something went wrong',
    error_description: 'We could not update the sections',
    success: 'Sections changes saved',
    success_description: 'Sections have been updated successfully',
  },
  WorkspacePriceGroups: {
    remove_action: 'Delete price group',
    new_price_group: 'New price group',
    search_placeholder: 'Search price group...',
    price_group_found: {
      one: '1 price group found',
      other: '{{count}} price groups found',
    },
    table: {
      name: 'Price group name',
      products: 'Products',
      clients: 'Included clients',
      last_update: 'Last update',
      status: 'Status',
    },
    rank: {
      title: 'Rank',
      active: 'Price list ranking active',
      inactive: 'Price list ranking inactive',
      text_inactive:
        'Activate the functionality to define the sequence of products in the downloadable Excel from the catalog.',
      text_active: `The rank specified in the 'rank' column will determine the sequence of products in the downloadable Excel from the catalog. This applies only when the clients has only one Price Group`,
      modal: {
        title: 'Would you like to remove the current sorting?',
        text: 'When you confirm, the order of the rank column will be prioritized, and the products will be displayed accordingly.',
        cta: 'Remove',
        cancel: `Don't remove`,
      },
    },
    products: {
      zero: 'Not added yet',
      one: '1 product',
      other: '{{count}} products',
    },
    sync_pricegroups: {
      button_text: 'Upload prices',
      notification: {
        title: 'Uploading prices',
        subtitle: 'You will see them in a few moments',
      },
      modal: {
        title: 'Upload prices',
        subtitle: 'Do you want to upload the prices from your system into Consentio?',
        button: 'Upload prices',
      },
    },
    options: {
      import: 'Import prices from CSV',
      export: 'Export prices to CSV',
    },
    dropzone: {
      title: 'Update prices',
      text: '**Download your price groups in CSV**, edit and upload them to have your prices updated.',
      cta: 'Upload price groups',
      success: 'Price groups imported',
      success_description: 'Prices have been successfully imported',
      error_description: 'There was an error importing prices',
    },
  },
  WorkspaceMargins: {
    title: 'Margins',
    cta: 'New margin',
    remove_action: 'Delete margin',
    delete_from_margin: 'Delete from margin',
    search_placeholder: 'Search by margin name',
    margin_found: {
      one: '1 margin found',
      other: '{{count}} margins found',
    },
    table: {
      name: 'Margin name',
    },
  },
  WorkspaceMarginEdit: {
    title_create: 'Create margin',
    title_edit: 'Edit margin',
    back: 'Back to the margins',
    status: {
      description: 'You can edit a deactivated margin and activate it when everything is ready for your clients',
      active: 'Margin activated',
      inactive: 'Margin deactivated',
    },
    clients: {
      title: 'Clients related to this margin ({{count}})',
      description: 'Decide which clients you want to apply the margin to.',
      clients_count: {
        zero: 'You do not have any client added to this margin',
        one: 'Currently you have {{count}} client added to this margin',
        other: 'Currently you have {{count}} clients added to this margin',
      },
      clients_count_read_only: {
        zero: 'No clients in this margin',
        one: '{{count}} client in this margin',
        other: '{{count}} clients in this margin',
      },
    },
    name: {
      title: 'Margin name',
      description: 'Give a name to the margin so that you and your team can quickly identify it.',
      input_placeholder: 'Write here the name for the margin',
    },
    products: {
      title: 'Products selected for this margin ({{count}})',
      description: 'Select products to include them and customize their prices in this margin.',
      products_count: {
        zero: 'There is no product in this margin',
        one: 'There is {{count}} product in this margin',
        other: 'There are {{count}} products in this margin',
      },
    },
    modal_remove: {
      title: 'Remove from margin',
      description:
        'Are you sure you want to remove this product from this margin?\n The price adjustment will be lost.',
      cta: 'Yes, remove',
      cta_cancel: 'No, keep it',
    },
    modal_remove_rate: {
      title: 'Remove margin',
      subtitle: 'When deleting it, you will have to create it again in case you want to recover it.',
      text: 'By eliminating the margin, the specified prices will no longer apply to your clients throughout your workspace',
      cta: 'Delete margin',
    },
    delete_notification: {
      title: 'Margin removed',
    },
  },
  WorkspacePriceGroupEdit: {
    title_create: 'Create price group',
    title_edit: 'Edit price group',
    back: 'Back to my price groups',
    modify: 'Modify settings',
    delete: 'Remove from price group',
    modal: {
      title: 'Modify several settings',
      subtitle: 'Set a new setting to apply to the products you have selected',
      cancel: 'Cancel',
      cta: 'Change on selected ones',
    },
    clients: {
      title: 'Clients related to this price group ({{count}})',
      description: 'Decide which clients you want to apply the price group to.',
      clients_count: {
        zero: 'You do not have any client added to this price group',
        one: 'Currently you have {{count}} client added to this price group',
        other: 'Currently you have {{count}} clients added to this price group',
      },
      clients_count_read_only: {
        zero: 'No clients in this price group',
        one: '{{count}} client in this price group',
        other: '{{count}} clients in this price group',
      },
      cta_add: 'Add clients',
      cta_edit: 'Add or edit clients',
    },
    name: {
      title: 'Price group name',
      description: 'Give a name to the price group so that you and your team can quickly identify it.',
      input_placeholder: 'Write here the name for the price group',
    },
    products: {
      title: 'Products selected for this price group ({{count}})',
      description: 'Select products to include them and customize their prices in this price group.',
      cta_add: 'Select products',
      cta_edit: 'Select more products',
      products_count: {
        zero: 'There is no product in this price group',
        one: 'There is {{count}} product in this price group',
        other: 'There are {{count}} products in this price group',
      },
      sort: {
        updated_at: 'Last updated',
        price_updated_at: 'Last price update',
        alphabetical: 'Alphabetical',
      },
    },
    status: {
      title: 'Activate price group and configure transport cost',
      title_read_only: 'Price group',
      subtitle: 'Activate/deactivate price group',
      description: 'You can edit a deactivated price group and activate it when everything is ready for your clients',
      active: 'Price group activated',
      inactive: 'Price group deactivated',
    },
    status_and_transport: {
      active_and_transport_cost_included: 'Active price group and transport cost included',
      active_and_transport_cost_not_included: 'Active price group and transport cost not included',
      inactive_and_transport_cost_included: 'Inactive price group and transport cost included',
      inactive_and_transport_cost_not_included: 'Inactive price group and transport cost not included',
    },
    include_transport_cost: {
      title: 'Transport cost',
      subtitle: 'Choose whether or not this price group includes the transport cost for the selected clients',
      included: 'Transport cost included',
      not_included: 'Transport cost not included',
      description:
        'When the price group does not include the transport cost, we will apply to each client their own or the one related to the address',
    },
    pricegroup_info: "What's a price group?",
    cta_create: 'Save and select products',
    add_clients: 'Add clients',
    table: {
      ProductVariety: 'Product Variety',
      ProductType: 'Product Type',
      product: 'Product',
      packaging: 'Packaging',
      category: 'Grade',
      origin: 'Origin',
      box_weight: 'Box weight',
      pricegroup: 'Price group',
      sku: 'SKU',
      clients: 'Clients',
      pp: 'PP',
      price_base: 'Base price',
      cost: 'Cost',
      adjustment_type: 'Adjustment type',
      adjustment: 'Adjustment',
      margin: 'Margin',
      price: 'Final price',
      remove: 'Remove',
      variation: 'Variation',
    },
    remove: 'Remove',
    modal_remove: {
      title: 'Remove from price group',
      title_many: 'Remove from price group',
      description:
        'Are you sure you want to remove this product from this price group?\n The price adjustment will be lost.',
      description_many:
        'Are you sure you want to remove these products from this price group?\n The price adjustment will be lost.',
      cta: 'Yes, remove',
      cta_cancel: 'No, keep it',
    },
    select_all: 'Select all',
    select_all_search: {
      one: 'Select and add {{count}} result found',
      other: 'Select and add all {{count}} results found',
    },
    unselect_all: 'Unselect all',
    modal_remove_rate: {
      title: 'Remove price group',
      subtitle: 'When deleting it, you will have to create it again in case you want to recover it.',
      text: 'By eliminating the price group, the specified prices will no longer apply to your clients throughout your workspace',
      cta: 'Delete price group',
    },
    realtime_save: 'Saved in real time',
    delete_notification: {
      title: 'Price group removed',
      subtitle: '‘{{name}}’ has been removed successfully',
    },
    tooltips: {
      base_price:
        "This is the base price of the product. To change it, you can go to your team's products and edit it.",
      adjustment_type:
        'Choose what type of adjustment you want to apply. To modify the price, enter the number you want.',
      price:
        "This column shows the product's price and the percentage of discount or markup applied to the base price.",
      price_group:
        'A price group is a custom price list that you create for your clients. Apply the same to as many buyers as you want.',
    },
  },
  ClientsList: {
    client_extra_info: '(Client of {{workspace}})',
    tooltip:
      'This client is currently seeing the products of the {{oldCatalog}} workspace catalog. A client can only have one catalog assigned.',
    add_as_client: "Add to my {{newCatalog}}'s clients",
    match_catalog: 'Sell products from {{newCatalog}}',
    sell_title: 'Sell to {{name}}',
    back_clients: 'Back to the clients list',
    go_to_chat: 'Go to chat',
    blocked: 'Blocked',
    create_contact_success: 'Client added to your contacts',
    create_contact_success_sub: 'You can find the contact in your chats',
    empty: 'This workspace has no clients yet',
    contact: 'Contact',
    client: 'Client',
    price: 'Prices',
    rates: 'Price groups',
    transport: 'Transport cost',
    currency: 'Currency',
    reference_id: 'Client ID',
    price_adjust: 'Price adjustment',
    margin: 'Margin',
    accept: {
      title: 'Accept orders',
      automatic: 'Automatic',
      manually: 'Manually',
    },
    add_contact: 'Add contact',
    add_clients: 'Add clients',
    add_clients_consentio: 'Add clients from Consentio',
    error: {
      one: 'An error occured when adding a client',
      other: 'An error occured when adding some clients',
    },
    error_description: {
      one: 'We could not add new client',
      other: 'We could not add new clients',
    },
    success: {
      one: 'Client added successfully',
      other: 'Clients added successfully',
    },
    success_description: {
      one: 'Your settings are now shared within your workspace',
      other: 'Your settings are now shared within your workspace',
    },
  },
  WorkspaceClientEdit: {
    client_sales: {
      title: 'Sales with this client',
      text: 'Access the orders you have with this client or create new ones',
      link: 'Go to sales made to this client',
      create_contact: {
        title: 'Add to my contacts',
        subtitle: 'To send an offer to this client, you have to add them to your contacts first',
        cta: 'Add to my contacts',
      },
    },
    reference_list: {
      title: 'Mapping list',
      text: `The mappings between a document reference and a product are created when importing Magic Orders to this client`,
      cta: 'See mappings',
    },
    read_only: 'Reading mode.',
    blocked: "This client's orders are blocked, so you will not be able to perform transactions.",
    unblock: 'Unblock',
    Menu: {
      client: {
        title: 'Client',
      },
      prices: {
        title: 'Prices',
        subtitle: 'The configuration for this client will be shared with your colleagues in this workspace.',
      },
      orders: {
        title: 'Orders',
        notifications: {
          label: 'Notify client of new orders',
          notify:
            'This client will receive a notification to {{email}} when you place or import a new order to Consentio.',
          not_notify: 'We will not notify your client when you place or import a new order to Consentio.',
        },
      },
      integration: {
        title: 'Integration',
      },
      addresses: {
        title: 'Orders delivery',
      },
      security: {
        title: 'Privacy and security',
        subtitle: 'Prevent this client from placing any order to me nor to any member of the workspace.',
      },
      privacy: {
        title: 'Remove from client list',
        description: '',
        subtitle: `If you remove this client, it will no longer be shown on your list. You will still be able to find the past transactions in this workspace's sales.`,
        remove_button: 'Remove client',
        cancel: 'Cancel',
        modal_description: `Do you want to delete {{client}} from the list? If there are any transactions with this client, you will find them in this workspace's sales.`,
        notification: {
          title: 'Client removed from list',
          subtitle: 'The client has been removed successfully',
        },
      },
    },
    delivery_addresses: 'Delivery addresses',
    back_magic_orders: 'Back to Magic Orders',
    back_sales: 'Back to sales',
    magic_modal_text: 'Choose which client you want to import the file to',
    for_team: 'Prevent this client from placing orders to the {{workspace}} workspace',
    for_team_block: 'This client can order from the {{workspace}} workspace',
    back_my: 'Back to clients list',
    back_contact_info: "Back to contact's info",
    order_accept_label: 'Automatically accept orders from {{contact_name}}',
    order_accept_auto: 'Yes, Accept automatically',
    order_accept_manual: 'No, Accept manually',
    order_accept_auto_text: 'New Orders from {{contact_name}} will be accepted automatically',
    order_accept_manual_text: 'You will need to manually accept each new order from {{contact_name}}',
    error: 'Something went wrong',
    error_description: 'We could not update the changes of this client',
    success: 'Client changes saved',
    success_description: 'Your client settings have been updated successfully',
    actions_modal: {
      title: 'Add price groups to the client',
      title_quoter: 'Add margins to the client',
      button: 'Add {{count}} to the client',
      button_zero_case: 'Add to the client',
    },
    pricegroup_strategy: {
      label: 'How are prices calculated?',
      cheapest: {
        label: 'Cheapest price',
        subtitle: 'If a product belongs to multiple price groups, the client will see the cheapest price.',
        subtitle_margin: 'If a product belongs to multiple margins, the client will see the cheapest price.',
      },
      sorted: {
        label: 'Sorted price groups',
        subtitle:
          'If a product belongs to multiple price groups, the client will see the price according to the established order.',
        add_button: 'Apply additional price groups to this client',
        no_more_available: 'All available price groups are being applied',
      },
      sorted_margins: {
        label: 'Sorted margins',
        subtitle:
          'If a product belongs to multiple margins, the client will see the price according to the established order.',
        add_button: 'Apply additional margins to this client',
        no_more_available: 'All available margins are being applied',
      },
    },
  },
  PriceGroupSelectTable: {
    search_placeholder: 'Search price groups...',
    price_group_found: {
      one: '1 price group found',
      other: '{{count}} price groups found',
    },
    search_placeholder_quoter: 'Search margins...',
    margin_found: {
      one: '1 margin found',
      other: '{{count}} margins found',
    },
    columns: {
      name: 'Name',
      products: 'Products',
      clients: 'Clients',
      transport: 'Transport',
      status: 'Status',
    },
    PriceGroupRow: {
      not_added: 'Not added yet',
      products: {
        one: '1 product',
        other: '{{count}} products',
      },
    },
  },
  MappingCodeList: {
    removedProducts: {
      one: "**'{{name}}'** has been removed, so its mapping are no longer assigned to any products.",
      other: '**{{count}} products have been removed**, so their mapping are no longer assigned.',
    },
    removedAddresses: {
      one: "**'{{name}}'** has been removed, so its mapping are no longer assigned to any address.",
      other: '**{{count}} addresses have been removed**, so their mapping are no longer assigned.',
    },
    go_to_details: 'Go to details',
    back: 'Back to files import',
    Ribbon: {
      address: 'There is 1 address code which does not correspond to any in Consentio.',
      addresses: 'There are {{errored_addresses}} address codes which do not correspond to any in Consentio.',
      product: 'There is 1 product code which does not correspond to any in Consentio.',
      products: 'There are {{errored_products}} product codes which do not correspond to any in Consentio.',
    },
    EmptyResource: {
      both: "You haven't saved any references yet",
      product: 'No products found yet',
      address: 'No addresses found yet',
      title: 'Product/Address',
      import_button: 'Import order files',
    },
    ProductTable: {
      title: 'Products',
      product_field: 'Product Description',
      external_reference_field: {
        '1': 'Reference',
        '2': 'in document',
      },
      internal_reference_field: {
        '1': 'Reference',
        '2': 'in Consentio',
      },
      ean_code: 'EAN (GTIN)',
      client_reference: "Client's ref. in document",
      search: 'Search by product or reference',
      tooltip_client_reference: "This column shows the reference that appears in the client's document.",
      last_update: 'Last update',
    },
    AddressTable: {
      title_pickup: 'Pickup addresses',
      title_delivery: 'Delivery addresses',
      title_address: 'Addresses',
      address_field: 'Address Description',
      external_reference_field: {
        '1': 'Address ID',
        '2': 'in document',
      },
      internal_reference_field: {
        '1': 'Address ID',
        '2': 'in Consentio',
      },
      external_id: 'External ID',
      search: 'Search by address or reference',
    },
    ChangeAddress: {
      title: 'Select address',
      subtitle: 'Select the address you want to link the reference {{reference}} with',
    },
    AddressConfirmation: {
      title: 'Are you sure about changing the reference?',
      subtitle: "The address '{{address_title}}' will be linked to the reference '{{reference}}'",
      confirm: 'Change',
    },
    ProductConfirmation: {
      title: 'Are you sure about changing the reference?',
      subtitle: "The product '{{product_title}}' will be linked to the reference '{{reference}}'",
      confirm: 'Change',
    },
    ReferenceRemoval: {
      title: 'Are you sure about removing the mapping?',
      subtitle_product: 'If you import a product with the same mapping you will have to link it manually',
      subtitle_address: 'If you import an address with the same mapping you will have to link it manually',
      confirm: 'Remove',
    },
    Add: {
      cta: 'Add Mapping',
    },
  },
  ContactInfo: {
    Menu: {
      contact: {
        title: 'Contact information',
      },
      orders: {
        title: 'Purchases acceptance',
        description: 'This will only affect orders where you are a buyer. It does not affect sales workspaces.',
      },
      teams: {
        title: 'Client on these workspaces',
        description: "Add this contact as a client from the 'clients' section of the workspace you want them to be on.",
      },
      security: {
        title: 'Security',
        subtitle:
          "With this option the user will not be able to access your catalogs or your chat. If you want it to be blocked for your colleagues as well, choose 'block store'.",
      },
      workspace: {
        title: 'Products you sell to this client',
        description: 'Choose the workspace that contains the products you sell to this client',
        footer: 'Client details in {{workspace_name}}',
        no_client: 'This contact is not a client of {{workspace}} yet.',
        no_client_add: 'Add as a client.',
        client_add_title: 'Client added',
        client_add_text: 'Client added successfully',
        inactive: '{{name}} products are hidden. Meanwhile, you will see the products of {{default_name}}.',
        modal: {
          title: 'Show {{client}} the products of {{workspaceNew}}',
          title_many: 'More than one selected contact is already a client in another workspace',
          text: '{{client}} is a client of {{workspaceOld}}. Each contact can only be in one workspace at a time. Would you like to show {{workspaceNew}} products to this contact?',
          text_many:
            'Each contact can only be in one workspace at a time. By changing it, you will lose your current assignment and you will be able to see the products of the new workspace. Each contact can only be in one workspace at a time. By changing it, they will lose their current assignment and will be able to see the products of the new workspace. Would you like to show {{workspaceNew}} products to these contacts? ',
          cta: 'OK',
          cancel: 'Cancel',
        },
      },
      activity: {
        title: 'Activity',
        description: 'You can archive this chat and it will no long appear in the chat list.',
      },
    },
    TeamLink: 'View client settings for this workspace',
    TeamZero: 'This contact is not yet a client of any of your workspace',
    AddedClient: 'Added as a client {{date}}',
    order_accept_label: 'Accept orders automatically',
    order_accept_auto: 'Yes, Accept automatically',
    order_accept_manual: 'No, Accept manually',
    order_accept_auto_text: "If you choose automatically, you won't need to accept each order from this client.",
    archive: 'Archive chat',
    unarchive: 'Unarchive chat',
  },
  ConsentioProModal: {
    sidebarTitle: 'Why Consentio Pro?',
    CTA: 'Ask for more information',
    CTASubtitle: 'A member of our sales team will contact you to present Consentio Pro',
    pricelist: {
      sidebarTitle: 'Create your price groups',
      title: 'Price groups',
      subtitle: 'You can create specific price groups, tailored per product per client to grow sales',
      benefit1Title: 'Customize your prices',
      benefit1Description: 'Given your clients and usecase, create custom price groups',
      benefit2Title: 'Manage your clients',
      benefit2Description: 'Assign a price group to as many clients as you want',
      benefit3Title: 'Apply multiple price groups',
      benefit3Description: 'Assign as many price groups as you want to the same client',
    },
    margin: {
      sidebarTitle: 'Create your margins',
      title: 'Margins',
      subtitle:
        'You can apply the margins to your clients so that we can recommend prices to you when sending an offer.',
      benefit1Title: 'Customize your prices',
      benefit1Description: 'Given your clients and products, create custom margins',
      benefit2Title: 'Manage your clients',
      benefit2Description: 'Assign a margin to as many clients as you want',
      benefit3Title: 'Apply multiple margins',
      benefit3Description: 'Assign as many margins as you want to the same client',
    },
    orderimport: {
      sidebarTitle: 'Magic orders',
      title: 'Magic orders',
      subtitle: 'Upload your orders into Consentio - save time and streamline everything into one platform',
      benefit1Title: 'Streamline orders',
      benefit1Description: 'Collect all orders in the same format, and in one platform',
      benefit2Title: 'Scalable',
      benefit2Description: 'No matter the formats you use, we integrate them to adapt to your needs',
      benefit3Title: 'Easy to use',
      benefit3Description: "You even can send the orders via email - we'll do the rest",
    },
    teams: {
      sidebarTitle: 'Team collaboration',
      title: 'Add colleagues',
      subtitle: 'Allow your team to access and share important business information',
      benefit1Title: 'Work as a team',
      benefit1Description: 'Coordinate your activities with your colleagues in the same workspace',
      benefit2Title: 'Save time',
      benefit2Description: 'Create your own sales space and manage it with your colleagues',
      benefit3Title: 'Complete the orders',
      benefit3Description: 'Comment on the orders with relevant information ',
    },
    dashboards: {
      sidebarTitle: 'Monitor your activity',
      title: 'Dashboards',
      subtitle: 'All your activity shown in real-time graphs',
      benefit1Title: 'Control your activity',
      benefit1Description: 'Tailored graphs easy to understand',
      benefit2Title: 'Measure your results',
      benefit2Description: 'Top clients, most sold products... and more!',
      benefit3Title: 'Access with one click',
      benefit3Description: "All your relevant information in bird's-eye view",
      moreInfo: 'Learn more about dashboards',
    },
  },
  Facets: {
    packaging: 'Packaging',
    box_type: 'Box Type',
    pallet_type: 'Pallet Type',
    preparation_state: 'Preparation state',
    prepared: 'Prepared',
    to_be_prepared: 'To be prepared',
    sections: 'Sections',
    availability: 'Product availability',
    active: 'Available',
    inactive: 'Hidden',
    unavailable: 'Out of stock',
    clear: 'Clear',
    filter_by: 'Filter by',
    status: 'Status',
    range: 'Creation date',
    shippingAddress: 'Delivery address',
    unspecified: 'Unspecified',
    currency: 'Currency',
    catalog: 'Workspace',
    seller: 'Seller',
    client: 'Client',
    provider: 'Supplier',
    colleague: 'Colleague',
    format_type: 'Format type',
    count_orders: {
      one: '{{count}} order',
      other: '{{count}} orders',
    },
    count_mappings: {
      one: '{{count}} reference',
      other: '{{count}} references',
    },
    count_files: {
      one: '{{count}} files',
      other: '{{count}} files',
    },
    type: 'Type',
    organic: 'Type of production',
    origin: 'Origin',
    order_origin: 'Order Source',
    category: 'Grade',
    you: '(You)',
    variety: 'Variety',
    brand: 'Brand',
    size: 'Size',
    apply_filters: 'Apply filters',
    unknown: 'Unknown',
    dateError: 'The end date must be greater than the start date',
  },
  NotificationUpload: {
    step: {
      processing: 'Processing image...',
      uploading: 'Uploading image...',
    },
  },
  ModalAccessBlocked: {
    title: 'Access temporarily blocked',
    description:
      'For security reasons, we have blocked access to your account until you can contact with us. To unlock your account, please contact with support.',
    cta: 'Accept',
  },
  ProductMapping: {
    product_info: {
      text_1: 'The product ',
      text_2: " doesn't exist. Select a product to link it",
    },
    main_action: {
      change_product: 'Change product',
      search_product: 'Search product',
    },
  },
  AddressMapping: {
    address_info: {
      text_1: 'The code ',
      text_2: " doesn't exist. Select an address to link it",
    },
    main_action: {
      change_address: 'Change address',
      search_address: 'Search addresss',
    },
  },
  Channel: {
    info_tooltip: 'Click here for contact details',
    modal_remove: {
      title: 'Remove participant',
      description: "Do you want to remove {{memberName}} from group '{{channelName}}'?",
      cta: 'Delete participant',
      cta_cancel: 'Cancel',
    },
    workspace: {
      title: 'Buy-sell in the group',
      subtitle:
        'Allows buying and selling in the group so that your workspace colleagues can sell to the clients who are in the group.',
      switch_label: 'Allow buying - selling in the group',
      select_label: 'Workspace where clients will see the products from',
      select_description:
        'Choose the workspace that contains the products that you and your colleagues sell in this group.',
      seller_label: 'Main Seller',
      seller_description:
        'Choose a colleague from your workspace who will be assigned to the purchases made by the clients of this group.',
      buyer_label: 'Main client',
      buyer_description:
        'Choose a client from your workspace who will be assigned to the sales made by the suppliers of this group.',
    },
    general: 'General',
    general_subtitle: 'Change group name and icon',
    mute_notifications: 'Mute chat',
    unmute_notifications: 'Unmute chat',
    members: 'Participants',
    members_subtitle: 'Manage group participants',
    members_number: {
      one: '{{count}} participant',
      other: '{{count}} participants',
    },
    add_contact: 'Add from Consentio',
    copy_share: 'Copy share link',
    invite: 'Invite contact',
    see_all: 'See all',
    leave_group: 'Leave group',
    leave: 'Leave group',
    leave_channel: 'Leave channel',
    archive_channel: 'Archive channel',
    unarchive_channel: 'Unarchive channel',
    archive: 'Archive group',
    unarchive: 'Unarchive group',
    name_placeholder: 'Group name',
    name_label: 'Group name',
    admin: 'Group admin.',
    owner: 'Group creator',
    do_admin: 'Make admin.',
    undo_admin: 'Discard admin.',
    send_message: 'Send private message',
    contact_info: 'Contact information',
    add_client: 'Add to my clients',
    delete_member: 'Remove from group',
    add_as_contact: 'Add as contact',
    invite_all: 'All group participants can invite others',
    leave_confirmation: 'Are you sure you want to leave this group?',
    leave_channel_confirmation: 'Are you sure you want to leave this channel?',
    invite_modal: {
      subtitle_consentio: 'Continue inviting contacts that are already in Consentio to join your group',
      title_consentio: 'Add contacts from Consentio',
      addresbook: {
        title: 'Invite contacts from your phonebook',
        subtitle: 'Send an invite to contacts in your phonebook to register and join your group',
      },
    },
    share_message:
      '{{name}} ({{company}}) invites you to join the group ‘{{group_name}}’ in Consentio. Here you’ll be able to exchange messages, pictures, documents and much more! {{link}}',
    activity: {
      title: 'Activity',
      description:
        'You can archive this group and it will no long appear in the chat list. If you leave the group you will no longer receive messages.',
      description_no_leave: 'You can archive this group and it will no long appear in the chat list.',
      description_channel:
        'You can archive this channel and it will no long appear in the chat list. If you leave the channel you will no longer receive messages.',
      description_no_leave_channel: 'You can archive this channel and it will no long appear in the chat list.',
    },
  },
  ChatGroupCreate: {
    title: 'Create a new group',
    title_community: 'Start your community!',
    subtitle: 'Add a name and image that identifies this group',
    nameGroup: "{{name}}'s group",
  },
  Invite: {
    phonebook: 'from my phonebook',
    consentio: 'Add from Consentio',
    email: 'by email',
    sms: 'by SMS',
    copy: 'Copy invite link',
  },
  ActionsModal: {
    steps: 'Step {{step}} of {{stepsNumber}}',
    skip: 'Skip',
    clients: {
      zero_case: {
        title: 'All your contacts are clients of this workspace!',
        description: 'To continue adding clients to this workspace, invite them in another way',
        cta: 'Invite by Email or SMS',
      },
    },
    agenda: {
      zero_case: {
        title: 'All the contacts in your phonebook are already in Consentio!',
        description: 'To continue growing your network, you can invite them in other ways',
        cta: 'Invite by Email or SMS',
      },
    },
    general: {
      zero_case: {
        title: 'You have already added all your Consentio contacts!',
        description: 'To continue adding contacts, invite them in another way',
        cta: 'Invite by Email or SMS',
      },
    },
    next: 'Next',
  },
  ClipboardInvite: {
    title: 'Link successfully copied on clipboard',
    subtitle: 'Paste it and share it in your own way',
  },
  ClipboardError: {
    title: "We couldn't copy the link on clipboard",
    subtitle: 'Please retry in a bit.',
  },
  BannerLanding: {
    install_cta: 'INSTALL',
    banner_title: 'Consentio',
    free_app_store: 'Free in the App Store',
    free_play_store: 'Free in the Google Play',
    sign_up_free: 'Sign up for free',
    pricelist_invite: '**{{name}}** invites you to connect on Consentio',
  },
  AddCustomLineModal: {
    title_add: 'Add additional line',
    title_edit: 'Edit additional line',
    subtitle: 'You can add a new line with its respective price',
    description: 'Description',
    description_placeholder: 'Line description',
    cost: 'Cost',
    cta_add: 'Add line',
    cta_edit: 'Save changes',
    cost_options: {
      discount: 'Discount',
      markup: 'Markup',
    },
  },
  ReceiveNotificationsModal: {
    title: "Notifications for working status 'not available'",
    subtitle: 'You will receive notifications when you change back your working status',
    description:
      'Choose if you want to keep receiving notifications when configuring your working status as ‘Not available’.',
    cta_accept: 'Mute notifications',
    cta_cancel: 'Keep receiving',
  },
  PublicShowroom: {
    Tabs: {
      home: 'Home',
      about: 'About us',
      sections: 'Sections',
      lang: 'Language: {{lang}}',
      lang_mobile: 'Language',
      see_all_sections: 'See all sections',
      see_all_products: 'See all products',
    },
    Titles: {
      sections: 'Sections',
      all_products: 'All products',
      all_products_with_count: 'All products ({{count}})',
      recommended: 'Recommended',
      favorite: 'Favorite',
      recent: 'Recent',
      search: "Results for '{{text}}'",
      cart: 'Cart',
    },
    About: {
      company_brief: 'Company brief',
      working_structure: 'Working structure',
      additional_info: 'Additional info',
    },
    show: 'Show',
    see_all: 'See all ({{count}})',
    download: 'Download for free',
    download_title: 'You are almost there! Download the app to start chatting with **{{name}}**.',
    banner_text:
      "**{{name}}'s** full catalog is in Consentio. Download the app to see this person's prices, discounts and more!",
    back_text: 'Back to home page',
    back_products: 'Back to products',
    select_lang: 'Select your language',
    filter: 'Filter',
    sort_by: 'Sort by',
    list_view: 'List view',
    go_to_chat: 'Go to chat',
    request_prices: 'Request prices',
    articles: 'Items ({{count}})',
    name: '{{name}} from {{companyName}}',
    cart_empty: 'Your cart is empty. Start adding products you want to buy.',
    cart_empty_cta: 'Go to all the products',
    empty_cart: 'Clear cart',
    empty_cart_confirmation: 'Are you sure you want to empty the cart?',
    cart: 'Cart ({{count}})',
    email_description: 'The email address you have added is **{{email}}**. ',
    email_change: 'Change email',
  },
  MemberSelector: {
    unselected: 'Unselected',
  },
  ConfirmOrderModal: {
    form: {
      title: 'Hello! Add your email so the seller can send you the prices you requested.',
      title_update: 'Enter the email where you want the seller to contact you',
      title_b2c: 'Hello! Add your email to see and save the products of your cart!',
      email_placeholder: 'Write your email',
      have_account: 'Already have an account?',
      log_in: 'Log in',
      cta_change: 'Change email',
    },
    success_unregistered: {
      title: 'Almost there! Verify your email',
      description:
        'Please verify and confirm the details we have sent to your email to request the prices of the products selected to {{name}}.',
      ok: 'OK',
    },
  },
  ConfirmOrderLanding: {
    title: 'Congratulations! Your request has been sent successfully',
    title_sent: 'Your information was sent correctly.',
    description_sent:
      'The seller will get in touch with you soon. In the meantime you can create an account in Consentio to have all your work in one place and in any device.',
    description_b2c: 'You will receive an email at {{email}} as soon as the seller accepts your order',
    description_unregistered:
      'Fill in your contact details in case there is any problem so that {{name}} can get in touch with you faster.',
    cta_create: 'Create account',
    cta_go_catalog: 'Go to catalog',
    cta_download: 'Download app',
    cta_send_info: 'Send information',
    company_name: 'Company name',
  },
  OrderRequestDetails: {
    back: 'Back to request list',
    ribbon_confirmed:
      '**{{email}}** has requested prices for this products through the public shop, get in contact with your client for more information or send an invite to join Consentio and start trading.',
    ribbon_not_confirmed:
      'This price request was made through the public shop but is not confirmed yet. Get in contact with your client to confirm **{{email}}** is still interested.',
    title: 'Price request',
    creation_date: 'Creation date',
    company: 'Company',
    email: 'Email',
    phone: 'Phone',
    seller: 'Seller',
    articles: 'Items ({{count}})',
    email_confirmed: 'Email confirmed',
    email_not_confirmed: 'Email not confirmed',
    request_company: 'Request company name',
    request_phone: 'Request phone number',
    table: {
      product: 'Product',
      amount: 'Quantity',
    },
    invite: 'Invite to Consentio',
    notification_invite: {
      title: 'Contact invited',
      description: '{{name}} invited successfully to Consentio',
    },
    notification_copy: {
      title: 'Email successfully copied',
      description: 'Paste it and send an email to this person',
    },
    request: {
      company: {
        title: 'Request company name',
        description: 'Send company name request to {{email}}',
      },
      phone: {
        title: 'Request phone number',
        description: 'Send phone number request to {{email}}',
      },
      cta: 'Send',
    },
    notification_ask_info: {
      title: 'Request sent successfully',
    },
    requested: 'Requested',
  },
  OrderRequests: {
    confirmed: 'Confirmed',
    not_confirmed: 'Not confirmed',
  },
  AddInfoOrderLanding: {
    title: 'Share some information!',
    description: 'Fill in more data so {{name}} can process your request.',
  },
  SuppliersList: {
    buy_title: 'Buy to {{name}}',
    supplier_company: 'Supplier and company',
    accept_purchases: 'Accept purchases',
    colleagues: 'Colleagues',
    products: 'Recently purchased products',
    notes: 'Notes',
    add_suppliers: 'Add Suppliers',
    add_suppliers_consentio: 'Add suppliers from Consentio',
    error: 'Something went wrong',
    error_description: 'We could not update the changes of this supplier',
    success: 'Supplier changes saved',
    success_description: 'Your supplier settings have been updated successfully',
    view_products: 'View products',
    add_contact: 'Add contact',
    no_teammates: 'No colleagues',
    no_products: 'No products',
    create_contact_success: 'Supplier added to your contacts',
    create_contact_success_sub: 'You can now find the contact in your chats',
  },
  WorkspaceSupplierEdit: {
    contracts: {
      title: 'Contracts',
      name: 'Contract {{name}}',
      delete: 'Delete contract',
      create: 'Create contract',
      save: 'Save',
      add_products: 'Add products',
      new: 'Add contract',
      ref: 'Contract reference',
      add_ref: 'Add reference number here',
      products: 'Products',
      info_title: 'Contract information',
      products_included: 'Products included',
      products_included_subtitle: 'Select the products of your supplier that are included in your contract.',
      add_more: 'Select more products',
      file_label: 'File',
      file_description: 'Import the file containing your format.',
      add_contract: 'Add contract with this supplier',
      success_update: 'Contract updated',
      success_create: 'Contract created',
      error_update: 'Error updating contract',
      error_create: 'Error creating contract',
      contracts_count: {
        zero: 'There is no product related to this contract',
        one: 'There is {{count}} product related to this contract',
        other: 'There are {{count}} products related to this contract',
      },
    },
    supplier_orders: {
      title: 'Orders made',
      text: 'Access the orders you have with this supplier',
      link: 'Go to the orders you have with this supplier',
      new_order: 'New order',
    },
    Menu: {
      contracts: {
        title: 'Product Contracts',
        zero_case: 'You have not yet added any product contracts with this supplier.',
        edit_contract: 'Edit contract',
        delete_contract: 'Delete contract',
        ref: 'Contract ref.',
        see_more: 'And {{count}} more contracts',
      },
      supplier: {
        title: 'Supplier',
        description: 'Supplier details',
      },
      purchases: {
        title: 'Accept orders',
        description: 'Acceptance of orders',
      },
      privacy: {
        title: 'Remove from supplier list',
        subtitle:
          'If you remove this supplier, it will no longer appear on your list. You will still find transactions made on purchases from this workspace.',
        remove_button: 'Remove supplier',
        modal_description:
          'Do you want to remove {{supplier}} from the list? If there is any transaction with this supplier, you can find it in the purchases of the workspace.',
        notification: {
          title: 'Supplier removed from the list',
          subtitle: 'Supplier successfully removed',
        },
      },
      products: {
        title: 'Recently purchased',
        description: 'Products purchased within your workspace',
      },
      colleagues: {
        title: 'Colleagues',
        description: 'Colleagues clients',
      },
      delivery: {
        title: 'Default delivery',
        description: 'Address and schedule',
      },
      security: {
        title: 'Security',
        description: 'Block supplier',
        subtitle:
          'By blocking new transactions, they will not be able to send offers to you or your workspace colleagues.',
      },
    },
    go_to_chat: 'Go to chat',
    back_my: 'Back to suppliers list',
    back_contact: 'Back to {{contact}}',
    for_team: 'Block new orders from this supplier',
    for_team_block: 'This supplier can order from the workspace',
    colleagues_title: 'Colleagues also buying from this supplier',
    no_colleagues: 'None of your colleagues is buying from this supplier',
    last_purchase: 'Last purchase: {{date}} · {{hour}}',
    products_title: 'Recently purchased products',
    no_products: 'Do your first purchase so that they can appear in this section',
    order_accept_manual_text: 'In case the supplier sends you an order, you will need to accept it mannually.',
    order_accept_auto_text: 'In case the supplier sends you an order, it will be accepted automatically.',
  },
  MagicOrders: {
    zeroCase: {
      title: "No results found for '{{text}}'",
      subtitle: 'Try again with other search criteria or go to all imported files.',
    },
    backToAllJobs: 'Go to all files',
    customFormat: {
      label: '+ Custom',
      title: 'Customize your format',
      subtitle: 'Indicate in which columns of the file you are going to import are the following product data.',
      productCode: 'Product code',
      description: 'Description',
      quantity: 'Quantity',
      sellUnit: 'Sell unit',
      priceUnit: 'Unit price',
    },
    ask: "Can't see the format you need? **Ask for it!**",
    how: 'What is Magic Orders for?',
    failed: 'Failed process',
    completing: '{{count}}% complete',
    completed: 'Completed',
    Tabs: {
      new: 'New import',
      list: 'Imported files',
      refs: 'Product codes mappings',
      edi: 'EDI orders',
    },
    Filters: {
      all: 'All ({{count}})',
      recommended: 'Recommended',
      recent: 'Recently used ({{count}})',
    },
    catalog_select: 'Associate the order with a workspace',
    change_format: 'Change format',
    email_title: 'Did you know?',
    email_description:
      "You can automate your orders 100%: faster, less worries for you.\nSend the client's files to the email we give you and they will be automatically uploaded to both Consentio and your ERP if it is integrated.",
    add_orders: 'Upload your files',
    add_orders_description: 'Drag the file or select it from your computer',
    Table: {
      client: 'Client',
      colleague: 'Colleague',
      Status: 'Status',
      TimeSaved: 'Time saved',
      Workspace: ' Workspace',
      Reference: 'Reference',
      Delivery: 'Delivery',
      Created: 'Created',
      Items: 'Items',
      Price: 'Price',
      field_incomplete: 'Complete info',
    },
    JobDescription:
      '{{label}} {{typeLabel}} · Uploaded on {{date}} at {{hour}} by **{{authorName}}** to **{{catalog}}**',
    JobDescriptionEDI: '{{label}} {{typeLabel}} · Uploaded on {{date}} at {{hour}} to **{{catalog}}**',
    copy: 'Copy',
    catalog_selected_footer:
      'You will be able to view the orders contained in the file from Sales > My Sales / {{catalogName}}.',
    orders_added: {
      one: '{{count}} attached file',
      other: '{{count}} attachments',
    },
    select_more: 'Select other',
    cta: 'Send',
    zero_formats: {
      title: 'No results for ‘{{search}}’',
      subtitle: "Can't see the format you need? Ask for it",
      cta: 'Request format',
    },
    Zero: {
      1: {
        title: 'Your **new** trick',
        text: 'Save time and avoid mistakes by uploading your orders. Get in a couple of clicks what used to take hours!',
        info: 'Average monthly data of Consentio users *',
        cta: 'Start!',
      },
      2: {
        title: 'The secret of the **PRO**',
        text: "We already have more than 30 formats, and many more on the way. If you can't find yours, don't hesitate to ask us.",
        cta: 'View formats',
      },
      3: {
        title: 'Orders in your system **instantly**',
        text: 'Integrate your system with Consentio and you will only have to send the orders to an email that we will give you to be able to import them instantly. No more manual work.',
        cta: 'Start!',
      },
      4: {
        title: 'Stop having doubts',
        sections: {
          1: {
            title: 'What is Magic Orders?',
            text: `Magic Orders is a Consentio PRO functionality that allows you to import external orders and introduce them directly into your system if it is integrated with Consentio’s platform.
This incredible functionality automates all the work that you normally do manually.`,
          },
          2: {
            title: 'What are the benefits of Magic Orders?',
            text: `Save time and all the troubles that manual data entry entitles, automating the import of orders. If your ERP is integrated with Consentio, all the Consentio orders will be imported automatically.
Transform all your orders into the same format. You will be able to modify the quantities and prices in the platform and notify your clients at the same time.`,
          },
          3: {
            title: 'Is the format I need available?',
            text: 'Currently we have more than 30 different formats that you can use to import your orders. These formats have been requested by our clients depending on their necessities. If you are looking for a specific one, we recommend you using the **format finder** to check if it is available in Consentio.',
          },
          4: {
            title: 'What should I do if I can’t find the format I am looking for?',
            text: `If you do not find the format that you are looking for, simply request it to us and we will work in its integration to have it available as soon as possible.
We recommend you to send us 10 examples of the format you want us to implement as it will help us to do it better. We will also need the name of the company to which the format belongs to. You can request your new format filling in **this form**.`,
          },
          '5': {
            title: 'What price will I see in Consentio for the order I have imported?',
            text: 'When using Magic Orders to import your orders into Consentio, we will use the following order to get the prices for the order:\n-**If the order has prices, we will always use them,** unless you have specified otherwise in the format creation conditions.\n -If the order does not have prices, we will check to see if you have created any pricegroups for the customer you are importing the order for. If the customer is on a pricegroup that contains the products, **we will take the prices from that pricegroup(s).**\n-If the order does not have prices, and your customer has not created pricegroups for the customer you are importing the order to, **we will use the prices from your catalog.**',
          },
        },
      },
      support:
        'If you have any questions, you can **ask for assistance** and we will offer a personalized attention to you.',
    },
    search: {
      placeholder: 'Search the order format you want to import',
    },
    no_results: 'There are no results for the filters applied',
    clear_filters: 'Clear filters',
    zero_case: {
      import_title: 'Import your files to see here the orders they contain',
      import_text:
        'From **new import**, select the format of this client and upload the files you want, we will transform them into orders!',
      edi_title: 'Access all your orders in EDI format from here',
      edi_text:
        'To be able to see your EDI orders in Consentio, please **contact support** and we will start the process!',
    },
  },
  OrderImportWidget: {
    hours: 'h',
    minutes: {
      one: 'min',
      other: 'mins',
    },
    'time-saved': 'Saved time',
    errors: 'Avoided errors',
    'imported-orders': 'Imported orders',
    mean: "Other companies' average at Consentio: {{value}}",
    tooltip: 'The metrics displayed are approximative.',
    format: {
      text: 'Text',
    },
  },
  OrdersMagicFormatAsk: {
    title: 'Ask for an order format',
    buyer_name: "Buyer's name",
    orders_files: {
      title: 'Examples of order files',
      subtitle:
        'This information is essential to be able to study the cases and to evaluate the creation of the format',
    },
    success_modal: {
      title: 'Thank you for sending us your files!',
      subtitle: 'We have received your request successfully, we will go back to you as soon as possible.',
      cta: 'Close',
    },
    back: 'Back to Magic Orders',
  },
  TwoFAModal: {
    AddPhone: {
      title: 'Enter your phone number',
      subtitle:
        'Activate two-step verification by adding your phone number to receive a code by text message (SMS) and verify your identity.',
      error_existing_phone: 'This phone number is already used in another account.',
      error_add_phone: 'An error ocurred when trying to add this phone number.',
      contact_support: 'Contact with support',
    },
    Password: {
      title: 'Enter password',
      subtitle: 'Enter password to verify your identity',
      cta: 'Verify',
      forget_password_description: ' to recover password',
      error: 'Incorrect password',
    },
    Success: {
      title: 'Two factor authentication activated',
      subtitle:
        'To keep your account secure, we will send you a text message with the confirmation code every time that your identity needs to be verified.',
      cta: 'Got it',
    },
  },
  LoginRegisterFlow: {
    step: '{{step}} of {{total}}',
    Name: {
      title: 'Welcome! What’s your name?',
      subtitle: 'Tell us your name so that your contacts know who you are',
      placeholder: 'Write your name here',
      cta: 'Next',
    },
    Company: {
      title: 'What company do you work for?',
      subtitle: 'Tell us the name of the company where you work',
      placeholder: 'Write here the name of your company',
      cta: 'Next',
      skip: 'I am consumer',
      modal_consumer: {
        title: 'Are you a consumer?',
        description: 'As a consumer, you will be able to buy fresh products from your known suppliers.',
        cta: 'I am consumer',
      },
    },
    Business: {
      title: 'What is {{companyName}}’s business activity?',
      subtitle: 'We ask for the activity to personalize your account. This is private information.',
      cta: 'Next',
    },
    Email: {
      title: 'Enter your email',
      subtitle: 'Finally, tell us your email address to finish setting up your account.',
      placeholder: 'Write here your email address',
      cta: 'Finish',
    },
    Verify: {
      title: 'Verify your identity and protect your account',
      subtitle_phone: 'Enter the code we have sent to **{{phoneNumber}}**',
      subtitle_email: 'Enter the code we have sent to **{{email}}**',
      cta: 'Next',
    },
    ResetPassword: {
      notification_title: 'Change password',
      notification_subtitle: 'Password has been changed successfully.',
    },
  },
  WelcomeLogin: {
    title: 'Log in',
    subtitle: 'No account yet? **Create Account**',
    logIn: 'Log in',
    password_label: 'Enter your password',
    google: 'Sign in with Google',
    facebook: 'Sign in with Facebook',
    microsoft: 'Sign in with Microsoft',
    user_not_exists_phone: 'There is no account associated with this number.',
    user_not_exists_email: 'There is no account associated with this email.',
    user_not_exists_link: 'Create Account',
  },
  WelcomeRegister: {
    pretitle: 'START FOR FREE',
    title: 'Create account',
    subtitle: 'Do you have an account? **Log in**',
    register: 'Join Consentio',
    phone: 'Phone',
    email: 'Email',
    createAccount: 'Create account',
    description:
      "By clicking the ‘Create account’ button, you are creating a Consentio account and you agree to Consentio's **Terms of Use**, **Privacy Policy**, **Cookies Policy**, **Data Processing Agreement** and **Legal Notice**.",
    description_old:
      'By clicking the ‘Create my account’ button, you are creating a Consentio account and you agree to Consentio’s **terms of use** and **privacy policy**.',
    password_label: 'Create your password',
    google: 'Create with Google',
    facebook: 'Create with Facebook',
    microsoft: 'Create with Microsoft',
    lower_case: '1 lowercase character',
    upper_case: '1 uppercase character',
    number: '1 number',
    min_length: '8 characters minimum',
  },
  WorkspaceCreate: {
    title: 'Create workspace',
    name: 'Workspace name',
    name_placeholder: 'Write here the name of the workspace',
    cta: 'Create workspace',
    success: 'Workspace created successfully',
    error: 'Error when creating workspace',
    subtitle: 'Your workspace is the space where you and your colleagues gather and share your work.',
    title1: 'Customize your workspace',
    logo_placeholder: 'Add workspace logo',
    subtitle1: 'Add a name for your workspace and a logo. This way, you and your colleagues can easily identify it.',
    seller: {
      title: 'Create sales workspace',
      subtitle: 'Create your catalog, price groups, list of clients, orders…',
    },
    buyer: {
      title: 'Create purchase workspace',
      subtitle: 'Find the products you need, create your list of suppliers, your orders…',
    },
  },
  ProductEdit: {
    additionalFiles: {
      title: 'Additional files',
      subtitle: 'Add files containing product information so that your clients can download them.',
      files: {
        one: '{{seller}} has added 1 additional file',
        several: '{{seller}} has added {{count}} additional files',
      },
      link: 'Add additional files',
      modal: {
        title: 'Add additional files',
        subtitle:
          'Choose the files you want to add to this product and upload them so that your clients can have access to them.',
        cancel: 'Cancel',
        cta: 'Save',
      },
      delete: {
        title: 'Delete attachment',
        text: 'Are you sure you want to delete this attachment? This action can not be undone.',
        cta: 'Delete',
      },
      format_error: {
        title: 'File format not allowed',
        description: 'File format(s) allowed: {{formats}}',
      },
    },
    Traceability: {
      title: 'Do you want to know where your product comes from?',
      subtitle:
        'Learn more about the traceability of your product. Access to the entire product journey within Consentio. ',
      cta: 'Access the traceability information',
      back: 'Back to the product information',
      countries: 'Countries of origin',
      products: 'Products of origen',
      tooltip: 'Click for more information',
    },
    Lot: {
      Lot: 'Product lot',
      Cta: 'Create new lot',
      Traceability_cta: 'Where does my product come from?',
      Associated: 'Associated Lots',
    },
    Sustainability: {
      no_data: 'The selected product type does not yet have data that we can display. Do you have your own data?',
      add_cta: 'Add them!',
      title: 'Environmental impact (per kg)',
      left_title: 'Environmental impact',
      green_subtitle: 'Sustainability',
      plastic: {
        title: 'Plastic',
        cta: 'Modify product plastic',
        cta_add: 'Add product plastic',
        data: 'Each {{plastic_unit}} of product contains **{{plastic_amount}}g** of {{plastic_type}}.',
        modal_title: 'Environmental impact of {{name}}',
        plastic_type: '🥤 Plastic type',
        plastic_unit: 'Each',
        plastic_amount: 'Contains',
      },
      co2: {
        title: 'CO2',
        subtitle:
          'Fill in the type of product and we calculate the kg of CO2 that your product consumes during its production!',
        data: '1 kg of **{{product}}** consumes between **{{co2Min}} and {{co2Max}} kgs of CO2** in its production.',
        similar_data: '1 kg of **{{product}}** consumes approximately **{{co2Max}} kgs of CO2** in its production.',
      },
      water: {
        title: 'Water',
        subtitle:
          'Complete the type of product so that we can calculate the liters of water that your product consumes during its production',
        data: '1 kg of **{{product}}** consumes between **{{waterMin}} and {{waterMax}} liters of water** in its production.',
        similar_data:
          '1 kg of **{{product}}** consumes approximately **{{waterMax}} liters of water** in its production.',
      },
      own_data: 'Do you have your own data?',
      modify_cta: 'Modify',
      modal: {
        title: 'Modify environmental information',
        text: 'In order to modify this information, we need you to send us the certificates that validate it. To do this, contact support and we will give you personalized attention.',
        cta: 'Contact support',
      },
      data_origin: 'Measurement provided by **ADEME** applying the **ISO 14.044** standard',
    },
    Visibility: {
      title: 'Multimedia elements',
      subtitle: 'Your clients will be able to see and buy this product.',
      subtitle_inactive: `Your clients cannot see this product nor can you share it while it is hidden.`,
      subtitle_out_of_stock: `Your clients will be able to see the product, but they won't be able to buy it.`,
      images: 'Pictures',
      availability: 'Product status',
      estimated_date: 'Estimated date of availability',
      checkbox_label: 'Make available after',
      date_unavailable: 'After this date, the status of the product will change to available.',
      date_available: 'After this date, the product will be available.',
      tooltip: `Inform your customers when you estimate that you will have this product available again. They won't be able to buy it until it's back in stock.`,
    },
    Characteristics: {
      title: 'Characteristics',
      subtitle: 'Generic data about the product',
    },
    unique_id: {
      title: 'Consentio unique ID',
      tooltip:
        'This is the product number that Consentio provides you with. It is a unique and immutable number. Fill in your own codes in the SKU and EAN (GTIN) / PLU / UPC. fields.',
    },
    Distribution: {
      title: 'Distribution',
      subtitle: 'Pallet details, boxes, weight',
    },
    Tags: {
      title: 'Sections',
      subtitle: 'Categorize your product',
    },
    Price: {
      title: 'Price',
      subtitle: 'Price type and sale unit',
    },
    NoPriceGroups: {
      description: "You don't have any price groups created yet.",
      link: 'Create a price group',
    },
    PriceGroups: {
      title: 'Price groups',
      subtitle: 'Manage price groups',
      product_price_groups: {
        zero: 'Product price groups (0)',
        one: 'Price group where you can find this product (1)',
        other: 'Price groups where you can find this product ({{count}})',
      },
      description: {
        zero: 'This product is not included in any price group. You can add it to the existing ones.',
        one: 'Edit the price of the product for the different price groups in which it is included or add it to existing ones.',
        other:
          'Edit the price of the product for the different price groups in which it is included or add it to existing ones.',
      },
      link: {
        zero: 'Add this product to your price groups',
        one: 'Go to the price groups related with this product',
        other: 'Go to the price groups related with this product',
      },
    },
    NoMargins: {
      description: "You don't have any margins created yet.",
      link: 'Create a margin',
    },
    Margins: {
      title: 'Margins',
      subtitle: 'Manage margins',
      product_margins: {
        zero: 'Product margins (0)',
        one: 'Margin where you can find this product (1)',
        other: 'Margins where you can find this product ({{count}})',
      },
      description: {
        zero: 'This product is not included in any margin. You can add it to the existing ones.',
        one: 'Edit the price of the product for the different margins in which it is included or add it to existing ones.',
        other:
          'Edit the price of the product for the different margins in which it is included or add it to existing ones.',
      },
      link: {
        zero: 'Add this product to your margins',
        one: 'Go to the margins related with this product',
        other: 'Go to the margins related with this product',
      },
    },
    MoreInfo: {
      title: 'More information',
      subtitle: 'SKU, EAN and more information',
    },
  },
  InviteLanding: {
    general: {
      title: '{{name}} is waiting for you at Consentio, **The leap your business was waiting for**',
      title_meta: '{{nameWithCompany}} is waiting for you at Consentio',
      description_meta:
        'The must-have tool for buying and selling fresh produce. It brings together everything you need: orders, clients, suppliers and much more!',
      section: {
        title: 'The new definition of buying and selling.',
        description:
          '{{nameWithCompany}} wants you to join Consentio, the must-have tool for buying and selling fresh produce. It brings together everything you need: orders, clients, suppliers and much more!',
        cta: 'Activate your account',
      },
    },
    client: {
      title: '{{inviterName}} has created his catalog just for you, **are you going to miss it?**',
      title_with_name:
        'Hello {{inviteeName}}! {{inviterName}} has created his catalog just for you, **are you going to miss it?**',
      title_meta: '{{inviterName}} has a unique offer for you, are you really going to miss it?',
      description_meta: 'Check the catalog that {{nameWithCompany}} has prepared in Consentio just for you.',
      section: {
        title: 'Your favorite products at the best price.',
        description:
          'Find what you need, when you need it! Always have the best offer from {{companyName}} at hand. View and compare your suppliers’ catalogues as often as you like. Choose the products that best suit your needs.',
        cta: "See {{companyName}}'s catalog",
      },
    },
    supplier: {
      title: '{{inviterCompanyName}} wants to buy your products at Consentio, **in just a few minutes!**',
      title_with_name:
        'Hello {{inviteeName}}! {{inviterCompanyName}} wants to buy your products at Consentio, **in just a few minutes!**',
      title_meta: 'Are you going to lose a sale? {{inviterName}} wants to buy your products!',
      description_meta:
        '{{nameWithCompany}} wants to buy your products on Consentio. Add your products and quickly perform your first sale!',
      section: {
        featureSubtitle: 'Online catalog',
        title: 'Your best products for your star clients.',
        description:
          'Add your products to create your public shop in seconds. {{inviterName}} will be able to buy them easily, having a quick access to your products, prices and any special offers. Digitalize your work and increase your sales!',
        cta: 'Send an offer to {{inviterName}}',
      },
    },
  },
  OrderLogistics: {
    delivery_type: {
      title: 'Delivery type',
      pickup_label: 'Pickup',
      delivery_label: 'Delivery',
    },
    delivery_order: {
      title: 'Delivery of the order',
      delivery_address: 'Delivery address',
      delivery_date: 'Estimated delivery date',
      range: 'Add delivery time',
    },
    pickup_order: {
      title: 'Order pickup',
      pickup_date: 'Pickup Date',
      pickup_address: 'Pickup address',
      range: 'Add pickup time',
    },
    transport_details: {
      title: 'Transport details',
      label: 'Write below all the necessary information to know about the transport.',
      placeholder: 'Write here the number of the truck, loading dock, etc.',
    },
    incoterms: {
      title: 'incoterms',
      label: 'Indicate the rate and degree of responsibility for this delivery.',
    },
    time_from: 'Since',
    time_to: 'Until',
  },
  ChatUnread: {
    title: 'Unread messages',
    zero_case: {
      title: 'There are no unread messages',
      description: 'Start a new conversation or carry on with the open ones clicking on the left.',
    },
  },
  OrderPrepare: {
    hashId: 'ORDER #{{hashId}}',
    contact: 'Sales contact',
    deliveryTo: 'Delivery to',
    transportation: 'Transportation details',
    open_chat: 'Open chat',
  },
  ChatPublic: {
    posts: 'Posts',
    comments: {
      one: '1 comment',
      other: '{{count}} comments',
    },
    members: {
      one: '1 member',
      other: '{{count}} members',
    },
    members_title: {
      one: 'Member',
      other: 'Members',
    },
    invite: 'Invite',
    edit_channel: 'Edit channel',
    post: 'Post',
    image: 'Image',
    poll: 'Poll',
    create_post: 'Create a post in this channel',
    zero_case: {
      title: 'Make your first post',
      description: 'Generate a positive change in society by sharing ideas, thoughts and everything you want.',
    },
    role: {
      owner: 'Owner',
      admin: 'Admin',
    },
    owners_admin: 'Owners and admins',
    other_members: 'Other members',
    create_post_modal: {
      title: 'Create post',
      title_edit: 'Edit post',
      subtitle: 'Post for #{{channelName}}',
      placeholder: "What's in your mind?",
      cta: 'Publish',
      cta_edit: 'Save changes',
    },
    create_poll_modal: {
      title: 'Create poll',
      placeholder: 'What do you want to ask? Ask a question',
      option: 'Option',
      add_option: 'Add option',
    },
    see_more: '... see more',
    see_less: '... see less',
    add_hashtag: 'Add hashtag',
    add_emoji: 'Add emoji',
    options: {
      edit: 'Edit post',
      delete: 'Delete post',
      share: 'Share post',
    },
    like: 'Like',
    comment: 'Comment',
    see_more_comments: 'See more comments',
    author: 'Author',
    comment_options: {
      edit: 'Edit comment',
      delete: 'Delete comment',
    },
    comment_delete_modal: {
      title: 'Are you sure you want to delete this comment?',
      description: 'If you delete this comment, no one will be able to see it anymore.',
      cta: 'Delete',
    },
    post_delete_modal: {
      title: 'Are you sure you want to delete this post?',
      description: 'If you delete this post, no one will be able to see it anymore.',
      cta: 'Delete',
    },
    save: 'Save changes',
    cancel: 'Cancel',
    edited: 'Edited',
    link_invite: {
      title: 'Channel invite link',
      description: 'Anyone in Consentio can join this channel through the link. Share it only with people you trust.',
      message: 'Open this link to join this channel in Consentio: {{link}}',
      external: 'Open this link to join this channel in Consentio',
    },
    post_link_invite: {
      title: 'Share post',
      description:
        'Anyone in Consentio can join this channel and see the post through the link. Share it only with people you trust.',
      message: 'Open this link to see the post in Consentio: {{link}}',
      external: 'Open this link to see the post in Consentio',
    },
    drag: 'Drag and drop a file here',
    offline_description: 'To see more comments or to add one, please ',
    log_in: 'log in',
    offline_see_content: 'To keep reading join the Consentio Community',
    offline_cta: 'Keep reading',
    votes: {
      one: '{{count}} vote',
      other: '{{count}} votes',
    },
    option_placeholder: 'Write your option here',
  },
  ChatPublicInfo: {
    channel_description: 'Channel description',
    modal: {
      title: 'Discover more content in the app',
      text: `Don't miss everything the channel #{{channelName}} has to offer and many more news!`,
      cta: 'Open the app',
      cancel: 'Not now',
    },
  },
  ChatPublicEdit: {
    title: 'Edit channel',
    name: {
      label: 'Channel name',
      placeholder: 'Write here the name of the channel',
    },
    description: {
      label: 'Channel description',
      placeholder: 'Write here the description of the channel',
    },
    save: 'Save changes',
  },
  ChatPublicSinglePost: {
    back: 'Back to the posts',
  },
  ChatPublicPostLanding: {
    title: 'Post from',
    see_channel: 'See channel',
  },
  PdfFormat: {
    title: 'Edit format',
    title_new: 'Create format',
    back_my: 'Back to workspace settings',
    name: {
      title: 'Format name',
      description: 'Write a name so that you and your colleagues will recognize this format.',
      placeholder: 'Ex. delivery note, order prepare, invoices…',
    },
    type: {
      title: 'Format type',
    },
    columns: {
      title: 'Customize columns',
      subtitle: 'Customize the columns that will appear in orders export.',
      label: 'Add a new column',
      sublabel: 'Select the information that the new column will have to add it.',
      description: 'Product description',
      unitPrice: 'Unit price',
      tax: 'Tax',
      ean: 'EAN (GTIN)',
      totalPrice: 'Amount',
      tare: 'Tare',
      new: 'New Fields',
    },
    config: {
      title: 'More info to show (PDF)',
      load: 'Load',
      transportDetails: 'Transport details',
      incoterms: 'Incoterms',
      totalPrice: 'Total price',
      orderComments: 'Order comments',
      showBorders: 'Dividing lines',
      fontSize: 'Font-size',
    },
    newColumn: {
      title: 'Create new field',
      display_name_placeholder: 'Name to show',
      display_name_label: 'Column name',
      type_label: 'What will appear in the column',
      add: 'Add',
      emptyColumn: 'Empty column (big)',
      emptyColumnSmall: 'Empty column (small)',
    },
    remove: 'Delete format',
    confirm_remove: 'Are you sure you want to remove this format?',
  },
  GameLanding: {
    quiz: {
      question_pagination: 'Question {{index}} of {{total}}',
      next: 'Next',
      submit: 'Submit',
      fruits: {
        banana: 'Banana',
        apple: 'Apple',
        kiwi: 'Kiwi',
        peach: 'Peach',
        pineapple: 'Pineapple',
      },
      test_result: 'Test results',
      your_result: 'Your result:',
      results: {
        apple: {
          title: 'Apple',
          description:
            'If you were a fruit, you would be an apple! \n\nYou are an intelligent person who is always looking to learn. People love talking to you, they always discover new things and you bring wisdom wherever you go. \n\n A conversation with you is healthy, healthy, like an apple!',
        },
        kiwi: {
          title: 'Kiwi',
          description:
            'If you were a fruit, you would be a kiwi! \n\nYou are a person who does not make a stitch without thread. Nothing escapes you and nothing goes unnoticed by you. \n\nNobody can deny that the kiwi is a fruit that people eat when they are interested... in those moments of... hum.',
        },
        peach: {
          title: 'Peach',
          description:
            "If you were a fruit, you would be a peach! \n\nYou are an open and very cheerful person who brings energy to others. Always willing to listen to others... especially if they have fresh news! \n\nYou know how to make someone feel good, who doesn't love a good peach?",
        },
        pineapple: {
          title: 'Pineapple',
          description:
            "If you were a fruit, you would be a pineapple! \n\nYou are a tropical being who knows how to squeeze life to the fullest. Always the life of the party! Would you like a caipirinha? You don't need to say anything, we know your answer! 😉",
        },
        banana: {
          title: 'Banana',
          description:
            "If you were a fruit, you would be a banana! \n\nYou are a delicate person. If you get into trouble, it's like keeping a banana in your bag, it turns black! You like tranquility and have everything under control. \n\nYou like to be given love and the attention you deserve!",
        },
      },
    },
  },
  NonProviders: {
    title: 'Create your supplier list',
    description:
      'Invite your suppliers to be able to configure their settings, view their products and place new orders',
    products: {
      title: 'Add your suppliers',
      description: 'Invite your suppliers to see their products and start buying',
    },
    link1: 'Add provider',
    link2: 'Consentio invitation link',
  },
  NonClients: {
    title: 'Create your client list',
    description:
      'Invite your clients to be able to configure their settings, add them to your price groups and place new offers',
    link1: 'Add client',
    link2: 'Consentio invitation link',
  },
  BuyerSearchProducts: {
    title_workspace_disabled: 'Enable purchase from {{name}}',
    workspace_disabled: 'To be able to buy products from this workspace, enable new purchases to be saved here',
    cta_workspace_disabled: 'Buy from this workspace',
  },
  MainHeader: {
    configuration: 'Configuration and privacy',
    help: 'Help and assistance',
    notification: 'Notification management',
    log_out: 'Log out from Consentio',
    terms_and_conditions_old: 'Access to the **Terms of Use** and **Privacy Policy**.',
    terms_and_conditions:
      'Access to the **Terms of Use**, **Privacy Policy**, **Cookies Policy**, **Data Processing Agreement** and the **Legal Notice**.',
    workspace_modal: {
      title: 'You no longer have access to this workspace',
      text_create_workspace:
        'To continue using the platform, create a new workspace. For any questions please ##contact support.##',
      text_choose_workspace:
        'To continue using the platform, change workspace or create a new one. If you have any questions, please ##contact support.##',
      create: 'Create workspace',
      change: 'Change workspace',
    },
    notification_center: {
      alert: 'Alert',
      show_comment: 'Show comment',
      hide_comment: 'Hide comment',
      title: 'Notifications',
      settings: 'Notifications settings',
      mark_unread: 'Mark all as read',
      zero_case_title: 'You are up to date!',
      zero_case_text:
        'In this section we will notify you of everything that happens in Consentio. Requests, comments or any other news.',
      tabs: {
        alert: 'Alerts',
        informative: 'Informative',
        all: 'All',
        unread: 'Unread',
      },
      ribbon: {
        workspace_seller: 'You are currently viewing the notifications of the seller workspace **{{workspace}}**.',
        workspace_buyer: 'You are currently viewing the notifications of the buyer workspace **{{workspace}}**.',
      },
    },
    workspace_select: {
      open: 'Open',
      pro_subscription: 'Your company is subscribed to Consentio PRO. **Discover how to take advantage of it!**',
      basic_subscription: 'Become an expert and **discover how to get the most out of your account!**',
      settings: 'Settings of {{workspaceName}}',
      colleagues: 'Colleagues ({{count}})',
      add: 'Add workspace',
      manage: 'Manage your workspaces',
    },
  },
  WorkspaceColleagues: {
    access_link: 'Manage your colleagues',
    title: 'Colleagues',
    subtitle_buyer: 'Manage from here the access that your colleagues will have for this workspace.',
    subtitle_seller: {
      one: 'Manage your workspace members and their role. Currently your plan does not include colleagues, if you want to add them, **contact support**',
      other:
        'Manage your workspace members and their role. Currently your plan includes up to {{count}} colleagues, if you want to extend it, **contact support**.',
    },
    subtitle_viewer: 'You can see here the colleagues of your workspace and the role they have.',
    colleagues_added_buyer: {
      one: '1 colleague added.',
      other: '{{count}} colleagues added.',
    },
    colleagues_added_seller: {
      one: '1 of {{total}} colleague added.',
      other: '{{count}} of {{total}} colleagues added.',
    },
    search_placeholder: 'Search by name, email or phone number',
    table: {
      colleague: 'Colleague',
      permissions: 'Permissions',
    },
    back: 'Back to the settings',
  },
  BroadcastCreate: {
    title: 'Create a new broadcast',
    subtitle: 'Add a name and image that identifies this broadcast',
    name_label: 'Broadcast name',
    name_placeholder: 'Enter the name of the broadcast',
    title_consentio: 'Add recipients to the broadcast',
    subtitle_consentio: 'Add recipients from Consentio contacts',
  },
  WorkspaceClientReferenceDetails: {
    configuration: 'Configuration',
    reference_document: 'Reference in document',
    reference_tooltip:
      'Add the reference that appears in the documents you upload to Consentio so that we can do the import properly.',
    remove_reference: 'Remove mapping',
    remove_placeholder: 'Deleting a mapping is available only via Clients > [Client name] > Mapping list',
    reference_type: 'Mapping type',
    reference_type_placeholder: 'Select the mapping type you want to add.',
    product: 'Product',
    delivery_address: 'Delivery address',
    pickup_address: 'Pickup address',
    reference_consentio: 'Product reference in Consentio',
    select_address: 'Select address',
    select_product: 'Select product',
    select_format: 'Select format',
    reference_document_placeholder: 'Write here the reference to map in the document',
    reference_document_placeholder_edi: 'Write the LIN or PIA reference here',
    client: 'Client',
    notification_success: {
      saved: 'Changes saved',
      deleted: 'Reference deleted',
      added: 'Reference added',
    },
    notification_error: {
      title: 'Error saving the reference',
      description: 'Please try again later.',
    },
    modify_reference: 'Modify mapping',
    magic_order_format: 'Format used by this reference',
    magic_order_format_tooltip:
      "Magic Order format where you can find this reference. It is likely that each client's Magic Order has a different reference.",
    magic_order_format_footer:
      'Complete this field preferably with the LIN code, if you find it at 0, complete it with the PIA.',
  },
  FormatsModal: {
    title: 'Magic Orders formats',
    subtitle: "Choose your client's format that will come with this reference",
    cancel: 'Cancel',
    add: 'Add',
    recent: 'Used recently',
    all: 'All formats',
    recommended: 'Recommended',
  },
  SelectClientModal: {
    title: "Whose client's address is it?",
    subtitle: 'Choose a client to see their address list.',
    topic: {
      references: {
        title: 'Select the client',
        subtitle: 'Choose the client you want to associate your product with.',
      },
    },
    next: 'Next',
    cancel: 'Cancel',
    addresses: {
      one: '1 address',
      other: '{{count}} addresses',
    },
    tooltip_client:
      'This client does not have any addresses created. You can create them **from their client page** and then configure the external references.',
  },
  AddressModal: {
    missing_external_id: '(Missing external ID)',
    empty: {
      title_client: 'This client does not have any addresses created yet',
      title: 'You do not have any addresses created yet',
      cta: 'Create new address',
    },
    cta: 'Select',
  },

  new_navigation_communication:
    '**The new Consentio design is now available!** Enjoy a more intuitive and streamlined navigation, along with the notification center. For any inquiries, please ##contact support##.',

  order_pdf: {
    downloaded: 'Downloaded on {{date}}',
    page: 'Page {{current}} of {{total}}',
    hash_title: 'ORDER #{{hashId}}',
    sales: 'Sales responsable',
    client: 'Client',
    deliveryTo: 'Delivery to',
    pickupFrom: 'Pickup from',
    transport: 'Transportation details',
    title: 'Order information #{{hashId}}',
    seller: 'Seller',
    buyer: 'Buyer',
    pickup_from: 'Pickup',
    delivery: 'Delivery',
    hash: 'Nº order: #{{hashId}}',
    created: 'Created on {{date}}',
    table: {
      provider: 'Supplier',
      client: 'Client',
      delivery: 'Delivery',
      status: 'Status',
      ref: 'Ref.',
      product_description: 'Product description',
      unit_price: 'Unit price',
      amount: 'Quantity',
      price: 'Cost',
      notes: 'Notes',
      description: 'Description',
      unitPrice: 'Unit price',
      tax: 'Tax',
      totalPrice: 'Price',
      tare: 'TARE',
      piecesPerBox: 'Units per box',
      boxWeight: 'Box weight',
      boxesPerPallet: 'Boxes / pallet',
      boxType: 'Box',
      packaging: 'Packaging',
      palletType: 'Pallet',
      brand: 'Brand',
      size: 'Size',
      category: 'Grade',
      origin: 'Origin',
      organic: 'Organic',
      region: 'Region',
      quantity: 'Qty ordered',
      weight: 'Weight',
      servedQuantity: 'Quantity',
    },
    custom_line: 'Additional line',
    shipping_cost: 'Shipping cost',
    shipping_included: '(Included in total cost)',
    total_price: 'Total cost',
    load: 'Load',
    vat: 'V.A.T. is not included',
    page_count: 'Page {{current}} of {{total}}',
    footer:
      'Consentio is a technology platform and is neither the seller, buyer nor the transport company and is not responsible for accuracies or claims, being the buyer and seller in charge of all the due diligence and compliance of any purchase and sale of products, and regulations.',
    access_updates: 'Access to the latest updates!',
    comments: 'COMMENTS ({{count}})',
    delivery_date: 'Delivery on {{date}}',
  },
};

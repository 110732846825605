import { __ } from 'common-services';
import * as React from 'react';

import { ActionsModal, ContactsList, SimpleSearch } from '../../../../../molecules';
import ClientsList from '../../../../../molecules/ClientsList';
import { useOrderContext } from '../../../context';

import * as S from './ClientsModal.styled';

import type { IClient } from 'common-services';

export const ClientsModal: React.FC = () => {
  const {
    me,
    order,
    clients: clients,
    membersAsClient: members,
    contacts,
    setShowClientsModal,
    updateOrder: onUpdateOrder,
    orderUpdateIssues,
    orderClientFixIssue,
  } = useOrderContext();

  // Local state for search filter
  const [filter, setFilter] = React.useState('');

  // Memoize handlers
  const handleContactSelect = React.useCallback(
    (contactId: number) => {
      onUpdateOrder({ ...order, buyerId: contactId });
      setShowClientsModal(false);
    },
    [order, onUpdateOrder, setShowClientsModal],
  );

  const handleClientSelect = React.useCallback(
    (clientId: number) => {
      const onUpdateIssue = (cId: number): void => {
        const issue = order?.issues.find(i => i.type === 'client-code-not-found');
        orderUpdateIssues({ ...issue, status: 'solved' }, order?.id, me.id, o =>
          orderClientFixIssue(me.id, o.id, cId, issue.id),
        );
      };
      onUpdateIssue(clientId);
      setShowClientsModal(false);
    },
    [setShowClientsModal, order, orderUpdateIssues, orderClientFixIssue, me.id],
  );

  return (
    <ActionsModal
      minHeight="450px"
      onClose={() => setShowClientsModal(false)}
      title={__('Components.Cart.clients_title')}
    >
      {/* Search Section */}
      {clients?.length ? (
        <S.SearchContainer>
          <SimpleSearch
            onChange={setFilter}
            placeHolder={__('Components.ChatList.search.placeholder')}
            id="input_search_contacts_addressbook"
          />
        </S.SearchContainer>
      ) : null}

      {/* List Section */}
      {members ? (
        <ContactsList
          contacts={members.map(m => contacts[m.id])}
          contactSelected={cId => order?.buyerId === cId}
          onSelectContact={handleContactSelect}
          action="radio"
          search={filter}
        />
      ) : (
        <ClientsList
          clients={clients as Array<IClient>}
          clientSelected={cId => order?.buyerId === cId}
          onSelectClient={handleClientSelect}
          action="radio"
          search={filter}
        />
      )}
    </ActionsModal>
  );
};

export default React.memo(ClientsModal);

import styled from '../../../../../../../styled-components';
import {
  ArrowPercentage as AP,
  Button,
  ColumnContainer,
  FontIcon,
  RowContainer,
  TextLabel,
  Tooltip as TooltipAtom,
} from '../../../../../../atoms';

import type { ITheme } from '../../../../../../../styled-components';

interface IStyledProps {
  theme: ITheme;
  align?: string;
  baseMarginPercentage?: number;
  enabled?: boolean;
  hasError?: boolean;
  hasServedChange?: boolean;
  isCancelled?: boolean;
  isChecked?: boolean;
  isClickable?: boolean;
  isIssue?: boolean;
  margin?: string;
  marginPercentage?: number;
  rightPosition?: string;
  servedQuantity?: boolean;
  showGrey?: boolean;
  width?: string;
  disabled?: boolean;
}

export const Text = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  width: 100%;
`;

export const NoDataText = styled(Text)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const CardItem = styled(ColumnContainer)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  width: 100%;
`;

export const CellContainer = styled.div`
  vertical-align: middle;
  text-align: left;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.showGrey ? p.theme.colors.grey2 : p.theme.colors.black)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
`;

export const ActionLink = styled(Button)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const VerticalDivider = styled.div`
  margin: 12px ${(p: IStyledProps) => p.theme.paddingSize(2)};
  width: 1px;
  height: 18px;
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
`;

export const SectionTitle = styled.div`
  display: inline-flex;
  align-items: center;
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;

export const ArrowPercentage = styled(AP)<IStyledProps>`
  right: ${(p: IStyledProps) => p.rightPosition};
`;

export const TooltipContainer = styled(ColumnContainer)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
`;

export const InfoIconBlue = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

export const TooltipPrice = styled(TooltipAtom)`
  position: relative;
  top: -1px;
`;

export const Row = styled(RowContainer)`
  justify-content: space-between;
  width: 100%;
  flex: 1;
  max-height: 100%;
`;

export const TableContainer = styled.div`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow: visible;
  height: 100%;
`;

import styled from '../../../styled-components';
import { ColumnContainer, FilterSwitch, RowContainer, TextLabel } from '../../atoms';
import { FilterButton as FB } from '../../molecules';
import SimpleSearchComponent from '../../molecules/SimpleSearch';

import type { ITheme } from '../../../styled-components';

interface IStyledProps {
  disableHover?: boolean;
  hiddenAtScreenWidth?: string;
  isSearch?: boolean;
  overlay?: boolean;
  selected?: boolean;
  theme: ITheme;
}
const Text = styled(TextLabel)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
`;
export const TitleText = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  display: inline;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  overflow: unset;
`;
export const GreyText = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  display: inline;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
export const TitleTextBold = styled(TitleText)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const WsWrapper = styled(ColumnContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  
`;

export const Row = styled(RowContainer)`
  flex: 1;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow: hidden;
  max-width: 100vw;
`;

export const HeaderDashboards = styled(RowContainer)`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  justify-content: space-between;
  align-items: center;
`;

export const HeaderFilters = styled(RowContainer)`
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const LeftFiltersRow = styled(RowContainer)`
  align-items: center;
`;
export const FilterButton = styled(FB)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: 40px;
`;

export const SimpleSearch = styled(SimpleSearchComponent)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  min-width: 235px;
  max-width: 492px;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    min-width: 185px;
  }
`;
export const Switch = styled(FilterSwitch)`
  height: 37px;
`;

import * as React from 'react';
import { __, IOrderItem, IUser, IWorkspace, LOCALE, utils } from 'common-services';

import { IMAGES } from '../../../assets';
import { getLanguageLiteral, ROUTE_PATHS, ShowroomPage } from '../../../constants';
import { getDeepLink } from '../../../services/link';
import getPath from '../../../util/routes';
import { BackButton } from '../../atoms';
import * as S from './ShowroomHeader.styled';
import { useHistory } from 'react-router-dom';

export interface IShowRoomHeaderProps {
  hideCompanyMobile?: boolean;
  selected?: string;
  clientId?: string;
  supplierId?: string;
  key?: string;
  path?: string;
  params?: any;
  channelId?: string;
  cart: Array<IOrderItem>;
  catalog: IWorkspace;
  companyName: string;
  from: 'showroom' | 'pricelist';
  initQuery: string;
  lang: LOCALE;
  me: IUser;
  name: string;
  onCartClick: () => void;
  onChangeLang?: (locale: LOCALE) => void;
  onChatClick: () => void;
  onNavigate: (page: ShowroomPage, key?: string) => void;
  onSearch: (query: string) => void;
  ShareLink?: React.ReactNode;
  showLangSelector?: boolean;
}

const ShowroomHeader: React.FC<IShowRoomHeaderProps> = ({
  cart,
  catalog,
  channelId,
  clientId,
  companyName,
  from,
  hideCompanyMobile,
  initQuery,
  lang,
  params,
  me,
  name,
  onCartClick,
  onChangeLang,
  onChatClick,
  onNavigate,
  onSearch,
  path,
  selected,
  showLangSelector = true,
  ShareLink,
  supplierId,
}) => {
  const [showMenuMobile, setShowMenuMobile] = React.useState(false);
  const [showLangs, setShowLangs] = React.useState(false);
  const overlayRef = React.useRef<HTMLDivElement>(null);
  const handleMenuMobileClick = () => setShowMenuMobile(!showMenuMobile);
  const history = useHistory();

  const handleCartClick = () => {
    if (cart.filter(c => c.amount).length === 0) return;
    onCartClick();
    if (channelId) {
      const targetPath: any = path.includes('sell')
        ? ROUTE_PATHS.CONTACT_CART_SELL_ITEMS
        : ROUTE_PATHS.CONTACT_CART_BUY_ITEMS;
      history?.push(
        getPath({
          path: targetPath,
          channelId,
        }),
      );
    } else {
      if (path.includes('sell') || path.includes('suppliers') || path.includes('/carts/')) {
        const targetPath: any = path.includes('sell')
          ? path.includes('/clients')
            ? ROUTE_PATHS.WORKSPACE_CLIENT_SELL_ITEMS
            : ROUTE_PATHS.WORKSPACE_SALES_SELL
          : path.includes('/suppliers')
          ? ROUTE_PATHS.WORKSPACE_SUPPLIER_BUY
          : path.includes('/carts/')
          ? ROUTE_PATHS.WORKSPACE_CARTS_DETAIL
          : ROUTE_PATHS.WORKSPACE_PURCHASES_BUY;

        history?.push(
          getPath({
            path: targetPath,
            workspaceId:
              path.includes('sell') || path.includes('sales') ? catalog.id.toString() : me.buyerWorkspaceId.toString(),
            clientId,
            supplierId,
            key: params.key,
          }),
        );
      }
    }
  };

  /**
   * Parser for title and body to change **whatever** for a featured text.
   */
  const formatText = (text: string, renderHighlightText: (s: string) => void) => {
    return text.split('**').map((str, index) => (index % 2 ? renderHighlightText(str) : str));
  };

  return (
    <S.Header from={from}>
      <S.MobileHeader>
        <S.Hamburguer name="Hamburger-menu" onClick={handleMenuMobileClick} />
        <S.Search
          autoFocus={!!initQuery}
          from={from}
          id="public-shop-search-mobile"
          onChange={onSearch}
          placeHolder={__('Components.ProductsList.search.placeholder')}
          query={initQuery as string}
        />
        <S.IconBadge badgeCount={cart.filter(c => c.amount).length}>
          <S.CartDesktop data-cy="cart" name="Cart" onClick={handleCartClick} />
        </S.IconBadge>
      </S.MobileHeader>
      <S.HeaderRow hidden={hideCompanyMobile} from={from}>
        <S.Logo onClick={() => onNavigate('home')} from={from}>
          {catalog?.companyLogo ? (
            <S.ImageLogo src={catalog.companyLogo} from={from} />
          ) : (
            <S.LettersAvatar
              avatarColor={utils.getAvatarColor(companyName)}
              from={from}
              iconName="Enterprise"
              img=""
              size={from === 'pricelist' ? 75 : 55}
              text={companyName}
            />
          )}
        </S.Logo>
        <S.HeaderTexts from={from}>
          <S.Title from={from}>{companyName}</S.Title>
          <S.Subtitle from={from}>{name}</S.Subtitle>
        </S.HeaderTexts>
        <S.ChatLink iconSize="18px" type="link" iconName="Chat" withoutPadding={true} onClick={onChatClick}>
          {__('PublicShowroom.go_to_chat')}
        </S.ChatLink>
        <S.DesktopHeader>
          <S.Search
            autoFocus={!!initQuery}
            from={from}
            id="public-shop-search"
            onChange={onSearch}
            placeHolder={__('Components.ProductsList.search.placeholder')}
            query={initQuery as string}
          />
          {ShareLink ? <S.ShareContainer>{ShareLink}</S.ShareContainer> : null}
          <S.Tabs
            selected={selected}
            tabs={[
              {
                id: 'home',
                label: __('PublicShowroom.Tabs.home'),
                url: 'home',
              },
              {
                id: 'about',
                label: __('PublicShowroom.Tabs.about'),
                url: 'about',
              },
              ...(catalog?.sectionsEnabled && catalog?.sections?.length
                ? [
                    {
                      id: 'sections',
                      label: __('PublicShowroom.Tabs.sections'),
                      action: key => onNavigate('sections', key),
                      menu: [
                        ...catalog?.sections
                          ?.sort((a, b) => a.position - b.position)
                          .map(s => ({ key: s.tag, value: s.translations[lang] || s.tag })),
                        {
                          key: 'see_all_sections',
                          value: __('PublicShowroom.Tabs.see_all_sections'),
                          action: () => onNavigate('sections'),
                          isLink: true,
                        },
                        {
                          key: 'see_all_products',
                          value: __('PublicShowroom.Tabs.see_all_products'),
                          action: () => onNavigate('products'),
                          isLink: true,
                        },
                      ],
                    },
                  ]
                : []),
              showLangSelector
                ? {
                    id: 'lang',
                    label: __('PublicShowroom.Tabs.lang', { lang: lang.toUpperCase() }),
                    action: key => onChangeLang?.(key as LOCALE),
                    menu: Object.keys(LOCALE)
                      .sort()
                      .map(l => ({ key: LOCALE[l], value: `${l} - ` + getLanguageLiteral(LOCALE[l]) })),
                  }
                : undefined,
            ]}
            history={{ push: (url: string) => onNavigate(url as ShowroomPage) }}
          />
          <S.IconBadge badgeCount={cart.filter(c => c.amount || c.servedQuantity).length}>
            <S.CartDesktop id="cart" name="Cart" onClick={handleCartClick} />
          </S.IconBadge>
        </S.DesktopHeader>
      </S.HeaderRow>
      <S.MenuMobileOverlay
        ref={overlayRef}
        show={showMenuMobile}
        onClick={e => {
          if (overlayRef.current === (e.target as any)) {
            setShowMenuMobile(false);
            setShowLangs(false);
          }
        }}
      >
        <S.MenuMobile>
          <S.CloseIcon name="Close" onClick={() => setShowMenuMobile(false)} id="public-shop-header-menu-close" />
          <S.MenuMobileBanner>
            <S.ConsentioLogo src={IMAGES.consentioLogoC.replace('/f_auto/', '/f_auto,w_400,c_limit/')} />
            <S.MenuMobileBannerText>
              {
                formatText(__('PublicShowroom.banner_text', { name }), (text: string) =>
                  text ? <S.MenuMobileBannerTextBold key={text}>{text}</S.MenuMobileBannerTextBold> : null,
                ) as any // TYPEERROR
              }
            </S.MenuMobileBannerText>
          </S.MenuMobileBanner>
          <S.DownloadButton type="principal" onClick={() => (window.location.href = getDeepLink('/'))}>
            {__('PublicShowroom.download')}
          </S.DownloadButton>
          <S.MenuItem
            onClick={() => {
              setShowMenuMobile(false);
              onNavigate('home');
            }}
          >
            <S.MenuItemIcon name="Home" />
            <S.MenuItemText>{__('PublicShowroom.Tabs.home')}</S.MenuItemText>
          </S.MenuItem>
          <S.MenuItem
            onClick={() => {
              setShowMenuMobile(false);
              onNavigate('about');
            }}
          >
            <S.MenuItemIcon name="Enterprise" />
            <S.MenuItemText>{__('PublicShowroom.Tabs.about')}</S.MenuItemText>
          </S.MenuItem>
          <S.MenuItem
            onClick={() => {
              setShowMenuMobile(false);
              onNavigate('sections');
            }}
            isLast={true}
          >
            <S.MenuItemIcon name="Grid" />
            <S.MenuItemText>{__('PublicShowroom.Tabs.sections')}</S.MenuItemText>
          </S.MenuItem>
          {catalog?.sections ? (
            <>
              {catalog?.sections?.map(s => (
                <S.MenuSubItem
                  key={s.tag}
                  onClick={() => {
                    setShowMenuMobile(false);
                    onNavigate('sections', s.tag);
                  }}
                >
                  <S.MenuSubItemText>{s.translations[lang] || s.tag}</S.MenuSubItemText>
                </S.MenuSubItem>
              ))}
              <S.MenuSubItem
                onClick={() => {
                  setShowMenuMobile(false);
                  onNavigate('sections');
                }}
              >
                <S.MenuSubItemText>{__('PublicShowroom.Tabs.see_all_sections')}</S.MenuSubItemText>
                <S.MenuItemChevron name="Right" />
              </S.MenuSubItem>
              <S.MenuSubItem
                onClick={() => {
                  setShowMenuMobile(false);
                  onNavigate('products');
                }}
              >
                <S.MenuSubItemText>{__('PublicShowroom.Tabs.see_all_products')}</S.MenuSubItemText>
                <S.MenuItemChevron name="Right" />
              </S.MenuSubItem>
            </>
          ) : null}
          <S.MenuItem
            onClick={() => {
              setShowLangs(true);
            }}
            isLast={true}
          >
            <S.MenuItemLetters>{lang.toUpperCase()}</S.MenuItemLetters>
            <S.MenuItemText>{__('PublicShowroom.Tabs.lang_mobile')}</S.MenuItemText>
            <S.MenuItemLang>{getLanguageLiteral(lang)}</S.MenuItemLang>
          </S.MenuItem>
        </S.MenuMobile>
        {showLangs ? (
          <S.MenuMobileLang>
            <BackButton onClick={() => setShowLangs(false)} text={__('PublicShowroom.select_lang')} />
            <S.LangWrapper>
              {Object.keys(LOCALE)
                .sort()
                .map(l => (
                  <S.LangItem
                    key={l}
                    onClick={() => {
                      setShowLangs(false);
                      setShowMenuMobile(false);
                      onChangeLang(LOCALE[l]);
                    }}
                  >
                    <S.MenuSubItemText>{getLanguageLiteral(LOCALE[l])}</S.MenuSubItemText>
                    {LOCALE[l] === lang ? <S.MenuItemLangIcon name="Check" /> : null}
                  </S.LangItem>
                ))}
            </S.LangWrapper>
          </S.MenuMobileLang>
        ) : null}
      </S.MenuMobileOverlay>
    </S.Header>
  );
};

export default ShowroomHeader;

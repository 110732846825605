import { __ } from 'common-services';
import * as React from 'react';

import { Tooltip } from '../../../../../atoms';
import { useOrderContext } from '../../../context';

import * as S from './EditOrderReference.styled';

interface EditOrderReferenceProps {
  showEditExternal: boolean;
  setShowEditExternal: (show: boolean) => void;
}

export const EditOrderReference: React.FC<EditOrderReferenceProps> = React.memo(
  ({ showEditExternal, setShowEditExternal }) => {
    const {
      order,
      orderReferenceId,
      showReferenceErrorTooltip,
      setShowReferenceErrorTooltip,
      handleUpdateExternal: onUpdate,
    } = useOrderContext();

    const hashId = order?.hashId;

    const [newId, setNewId] = React.useState(orderReferenceId || '');

    const handleUpdate = (): void => {
      if (hashId || newId) {
        onUpdate(newId);
        setShowEditExternal(false);
        setShowReferenceErrorTooltip(false);
      }
    };

    const inputElement = (
      <S.InputWrapper hasError={showReferenceErrorTooltip}>
        <S.EditInput
          value={newId}
          placeholder={orderReferenceId || hashId ? '#' + hashId : '#ABCDEFGHIJ'}
          onChange={(_, value) => {
            setNewId(value as string);
            setShowReferenceErrorTooltip(false);
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleUpdate();
            } else if (e.key === 'Escape') {
              setShowEditExternal(false);
              setNewId(orderReferenceId || '');
              setShowReferenceErrorTooltip(false);
            }
          }}
        />
      </S.InputWrapper>
    );

    if (!showEditExternal) {
      return <S.EditIcon name="Edit" id="edit_reference_button" onClick={() => setShowEditExternal(true)} />;
    }

    return (
      <S.Container>
        {showReferenceErrorTooltip ? (
          <Tooltip position="bottom" text={__('Components.Cart.invalid_hash')} width="200px" showed={true}>
            {inputElement}
          </Tooltip>
        ) : (
          inputElement
        )}

        <S.ActionButtons>
          <S.AddButton name="Checkmark" enabled={!!hashId || !!newId} onClick={handleUpdate} />
          <S.CloseButton
            name="Close"
            onClick={() => {
              setShowEditExternal(false);
              setNewId(orderReferenceId || '');
              setShowReferenceErrorTooltip(false);
            }}
          />
        </S.ActionButtons>
      </S.Container>
    );
  },
);

EditOrderReference.displayName = 'EditOrderReference';

import * as React from 'react';

import { type StateProps, type DispatchProps, type HeritageProps } from './OrderDetails.props';

/**
 * Configuration type for the logger
 */
interface LoggerConfig {
  includeActions?: Array<string>;
  excludeActions?: Array<string>;
  formatLog?: (actionName: string, args: Array<unknown>) => string;
  includeTimestamp?: boolean;
}

/**
 * Helper type to get parameter types of a function
 */
type Parameters<T> = T extends (...args: infer P) => unknown ? P : never;

/**
 * Helper type to get return type of a function
 */
type ReturnType<T> = T extends (...args: Array<unknown>) => infer R ? R : never;

/**
 * Creates wrapped versions of dispatch props with logging
 */
const wrapDispatchProps = (
  dispatchProps: Partial<DispatchProps>,
  config: LoggerConfig = {},
): Partial<DispatchProps> => {
  const wrappedProps: Partial<DispatchProps> = {};

  const shouldLog = (actionName: string): boolean => {
    if (config.includeActions?.length) {
      return config.includeActions.includes(actionName);
    }
    if (config.excludeActions?.length) {
      return !config.excludeActions.includes(actionName);
    }
    return true;
  };

  // Type-safe iteration through dispatch props
  (Object.keys(dispatchProps) as Array<keyof DispatchProps>).forEach(key => {
    const originalFn = dispatchProps[key];

    if (typeof originalFn === 'function') {
      // Create a type-safe wrapper function that preserves the original function's signature
      wrappedProps[key] = function (
        this: unknown,
        ...args: Parameters<typeof originalFn>
      ): ReturnType<typeof originalFn> {
        if (shouldLog(key)) {
          const timestamp = config.includeTimestamp ? `[${new Date().toISOString()}] ` : '';

          const logMessage = config.formatLog ? config.formatLog(key, args) : `${timestamp}Action Called: ${key}`;

          console.log(logMessage); // eslint-disable-line no-console
          if (args.length) {
            console.log('Arguments:', args); // eslint-disable-line no-console
          }

          // Add component stack trace for better debugging
          console.log('Component Stack:', new Error().stack?.split('\n').slice(2).join('\n')); // eslint-disable-line no-console
        }

        return originalFn.apply(this, args);
      };
    }
  });

  return wrappedProps;
};

/**
 * HOC that wraps a component to log its dispatch actions
 */
export function withActionLogger<P extends StateProps & DispatchProps & HeritageProps>(
  WrappedComponent: React.ComponentType<P>,
  config: LoggerConfig = {},
): React.FC<P> {
  const WithActionLogger: React.FC<P> = props => {
    // Only wrap the dispatch props, leave other props unchanged
    const wrappedDispatchProps = wrapDispatchProps(props, config);

    const enhancedProps = {
      ...props,
      ...wrappedDispatchProps,
    };

    return <WrappedComponent {...(enhancedProps as P)} />;
  };

  WithActionLogger.displayName = `WithActionLogger(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return WithActionLogger;
}

/**
 * Helper function to create a configured logger HOC
 */
export const createActionLogger = (config: LoggerConfig) => {
  return <P extends StateProps & DispatchProps & HeritageProps>(
    WrappedComponent: React.ComponentType<P>,
  ): React.FC<P> => withActionLogger(WrappedComponent, config);
};

import { __ } from 'common-services';
import * as React from 'react';
import ActionsModal from '../ActionsModal';
import * as S from './FormModal.styled';

export interface IProps {
  cancelText?: string;
  children: React.ReactNode;
  className?: string;
  contentGrow?: boolean;
  ctaDisabled?: boolean;
  ctaText: string;
  onClose: () => void;
  onSubmit: () => void;
  subtitle?: string;
  title: string;
  closeOnClickOutside?: boolean;
}

/**
 * Render form modal with two buttons: Accept / Cancel
 */
const FormModal: React.FC<IProps> = ({
  cancelText = __('Components.Modal.cancel'),
  children,
  className,
  contentGrow = true,
  ctaDisabled = false,
  ctaText,
  onClose,
  onSubmit,
  subtitle,
  title,
  closeOnClickOutside,
}) => {
  return (
    <ActionsModal
      className={className}
      contentGrow={contentGrow}
      minHeight="450px"
      onClose={onClose}
      subtitle={subtitle}
      title={title}
      closeOnClickOutside={closeOnClickOutside}
    >
      {children}
      <S.FooterContainer>
        <S.CTA type="secondary" onClick={onClose}>
          {cancelText}
        </S.CTA>
        <S.CTA type="principal" onClick={onSubmit} disabled={ctaDisabled}>
          {ctaText}
        </S.CTA>
      </S.FooterContainer>
    </ActionsModal>
  );
};

export default React.memo(FormModal);

import { __, currency, CURRENCY_CODES, modalActions, PRODUCT_UNIT, WEIGHT_UNIT } from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { IMAGES } from '../../../assets';
import { Input, TextArea } from '../../atoms';
import * as S from './AddressCell.styled';
import { unitTranslator } from '../../../util/unit';

interface IProps {
  changeQuantity: (value: any) => void;
  saveComment: (value: any) => void;
  orderedQuantity: number;
  remainingQuantity: number;
  price: number | string;
  hasComment: boolean;
  priceUnit: string;
  quantityUnit: string;
  comment?: string;
}

const AddressCell: React.FC<IProps> = React.memo(
  ({
    changeQuantity,
    saveComment,
    orderedQuantity,
    remainingQuantity,
    price,
    hasComment,
    priceUnit,
    quantityUnit,
    comment,
  }) => {
    const dispatch = useDispatch<Dispatch<any>>();
    const [newComment, setNewComment] = React.useState(comment || '');
    const [showComment, setShowComment] = React.useState(false);
    const hasChanges = newComment !== comment;

    React.useEffect(() => {
      if (showComment) {
        setNewComment(comment || '');
      }
    }, [showComment, comment]);

    const handleCommentChange = React.useCallback(value => {
      setNewComment(value);
    }, []);

    const handleSaveComment = React.useCallback(() => {
      saveComment(newComment);
      setShowComment(false);
    }, [saveComment, newComment]);

    const handleDeleteComment = React.useCallback(() => {
      dispatch(
        modalActions.modalOpen(
          __('Components.OffersList.order_preparation.table.delete_comment.title'),
          () => {
            saveComment('');
            setShowComment(false);
          },
          {
            showCancelButton: true,
            buttonCancelText: __('Components.OffersList.order_preparation.table.delete_comment.cancel'),
            text2: __('Components.OffersList.order_preparation.table.delete_comment.text'),
            buttonText: __('Components.OffersList.order_preparation.table.delete_comment.cta'),
            icon: IMAGES.informativePineapple,
          },
          'nice',
        ),
      );
    }, [saveComment]);

    return (
      <S.FlexRow>
        <S.FlexColumn>
          <S.Input
            name={'ordered-quantity'}
            width="100px"
            type={'number'}
            onBlur={(key, value) => {
              changeQuantity(value);
            }}
            minValue={0}
            precision={0}
            value={orderedQuantity || 0}
            variableTextSingular={unitTranslator(quantityUnit, orderedQuantity)}
            notEmpty={false}
          />
          <S.TextSmall>
            {__('Components.OffersList.order_preparation.table.remaining', {
              remaining: remainingQuantity,
              unit: unitTranslator(quantityUnit, orderedQuantity),
            })}
          </S.TextSmall>
        </S.FlexColumn>
        <S.FlexColumn>
          <S.TextBlack>
            {typeof price === 'number'
              ? currency.getPricePerUnit(CURRENCY_CODES.EUR, priceUnit as PRODUCT_UNIT, WEIGHT_UNIT.KG, price)
              : price}
          </S.TextBlack>
        </S.FlexColumn>
        <S.FlexColumn>
          <S.TextBlack>{hasComment}</S.TextBlack>
          <S.CommentIcon
            hasComment={hasComment}
            name="Message"
            disableHover={true}
            onClick={() => setShowComment(!showComment)}
          />
        </S.FlexColumn>
        {showComment && (
          <S.CommentBox>
            <TextArea
              name={'comment'}
              autoFocus={true}
              containerMargin={'4px 0'}
              width={'100%'}
              value={newComment}
              maxLength={47}
              onChange={(key, value) => handleCommentChange(value + '')}
              onBlur={() => {
                setTimeout(() => {
                  setShowComment(false);
                  setNewComment(comment || '');
                }, 200);
              }}
              disabled={false}
              rows={3}
              placeholder={__('Components.OffersList.order_preparation.table.comment')}
              showCharactersCount={false}
            />
            <S.IconsRow>
              {hasComment ? <S.RemoveIcon name="Trash" onClick={handleDeleteComment} /> : null}
              <S.SaveIcon name="Send-solid" hasChanges={hasChanges} onClick={handleSaveComment} />
            </S.IconsRow>
          </S.CommentBox>
        )}
      </S.FlexRow>
    );
  },
);

export default React.memo(AddressCell);

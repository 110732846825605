import { __ } from 'common-services';
import * as React from 'react';

import { Button, FontIcon } from '../../../../../atoms';
import { useOrderContext } from '../../../context';
import { ScrollSection } from '../../../hooks';

import { Comments } from './Comments';
import * as S from './CommentsSection.styled';

// Main component
const CommentsSection: React.FC = React.memo(() => {
  const { unreadsCount, isAutoScrollEnabled, setIsAutoScrollEnabled } = useOrderContext();

  return (
    <S.Container>
      {/* Comments Header */}
      <S.Header id={ScrollSection.COMMENTS}>
        <S.Title>{__('Components.OrderDetails.parts.body.comments.title')}</S.Title>
      </S.Header>

      {/* Comments List */}
      <Comments />

      {/*  commenting this until we know it's purpose */}

      {/* Auto-scroll Toggle */}
      {/* {(unreadsCount?.unread_comments || 0) > 0 && (
        <Button type="secondary" onClick={() => setIsAutoScrollEnabled(!isAutoScrollEnabled)}>
          <FontIcon name={isAutoScrollEnabled ? 'Account' : 'Adaptable'} />
          {isAutoScrollEnabled
            ? __('Components.OrderDetails.comments.pause_auto_scroll')
            : __('Components.OrderDetails.comments.resume_auto_scroll')}
        </Button>
      )} */}
    </S.Container>
  );
});

// Type checking in development
CommentsSection.displayName = 'CommentsSection';

export default CommentsSection;

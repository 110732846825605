import styled from '../../../../../../styled-components';
import {
  ColumnContainer,
  RowContainer,
  FontIcon,
  TextLabel,
  Input,
  Button,
  Tooltip as TooltipAtom,
} from '../../../../../atoms';

import type { ITheme } from '../../../../../../styled-components';

export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: 16px;
  height: 16px;
  width: 16px;
  line-height: 16px;
`;

export const Tooltip = styled(TooltipAtom)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const CustomItemsContainer = styled(ColumnContainer)``;

export const CardItem = styled.div`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const SectionTitle = styled.span`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  width: auto;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} ${(p: IStyledProps) => p.theme.paddingSize(3)} 0 0;
`;

export const SectionTitleCustomItems = styled(SectionTitle)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;
interface IStyledProps {
  enabled?: boolean;
  disabled?: boolean;
  margin?: string;
  numHeaders?: number;
  theme: ITheme;
}

export const ItemContainer = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
`;

export const ItemHeader = styled(RowContainer)`
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const ProductInfo = styled.div`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const ItemActions = styled(RowContainer)`
  align-items: center;
`;

export const ActionButton = styled(Button)`
  background: transparent;
  border: none;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(1)};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  &:hover {
    color: ${(p: IStyledProps) => p.theme.colors.black};
  }
`;

export const ItemDetails = styled(RowContainer)`
  justify-content: space-between;
`;

export const LeftDetails = styled(ColumnContainer)`
  flex: 1;
`;

export const RightDetails = styled(ColumnContainer)`
  flex: 1;
  align-items: flex-end;
`;

export const DetailRow = styled(RowContainer)`
  justify-content: space-between;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(1)} 0;
`;

export const DetailLabel = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const DetailValue = styled(TextLabel)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const QuantityInput = styled(Input)`
  width: 60px;
  text-align: right;
`;

export const Container = styled(ColumnContainer)`
  width: 100%;
  max-height: 100%;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: ${(p: IStyledProps) => p.theme.colors.white};
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

// export const Modal = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   background: ${(p: IStyledProps) => p.theme.colors.white};
//   width: 100%;
//   height: 100%;
//   display: flex;
//   flex: 1;
//   justify-content: flex-start;
//   align-items: flex-start;
//   flex-direction: column;
//   overflow-y: auto;
//   overflow-x: hidden;
// `;

export const CenterContainer = styled(RowContainer)`
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(6)} 0;
`;

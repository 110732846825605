import { __, productService, utils } from 'common-services';
import * as React from 'react';

import config from '../../../../../../../bindings/config';
import { IMAGES } from '../../../../../../assets';
import { ActionsModal, UpdateConfirmationModal } from '../../../../../molecules';

import * as S from './SetOtherConfirmationModal.styled';

import type { IClient, IProduct, IProdType } from 'common-services';

const SetOtherConfirmationModal: React.FC<{
  onClose: () => void;
  onCancel: () => void;
  client?: IClient;
  defaultCheck?: boolean;
  disableCheck?: boolean;
  mappedProducts: Array<IProduct>;
  productName: string;
  externalCode: string;
  onConfirm: (isChecked: boolean) => void;
  prodTypes: Record<string, IProdType>;
}> = ({
  defaultCheck,
  onClose,
  onCancel,
  client,
  disableCheck,
  onConfirm,
  productName,
  externalCode,
  mappedProducts,
  prodTypes,
}) => {
  const [showStep2, setShowStep2] = React.useState(false);
  if (!mappedProducts) return null;
  const {
    type: { type, variety },
  } = mappedProducts[0];
  return showStep2 ? (
    <ActionsModal
      title={__('Components.OrderDetails.setOtherConfirmationModal2.title', {
        count: mappedProducts.length,
        name:
          mappedProducts[0].productTitle ||
          productService.getProductTypeVarietyDisplay(type, prodTypes[type] ? prodTypes[type].name : '', variety),
        code: externalCode,
      })}
      onClose={onClose}
      minHeight="min-content"
    >
      <S.List>
        <S.ItemList>
          {
            utils.formatText(
              __('Components.OrderDetails.setOtherConfirmationModal2.text1', { name: productName, code: externalCode }),
              (text: string) => <S.TextBold key={text}>{text}</S.TextBold>,
            ) as any // eslint-disable-line @typescript-eslint/no-explicit-any
          }
        </S.ItemList>
        <S.ItemList>
          {
            utils.formatText(__('Components.OrderDetails.setOtherConfirmationModal2.text2'), (text: string) => (
              <S.TextBold key={text}>{text}</S.TextBold>
            )) as any // eslint-disable-line @typescript-eslint/no-explicit-any
          }
        </S.ItemList>
      </S.List>
      <S.ButtonsContainer>
        <S.ModalButton type="secondary" onClick={() => setShowStep2(false)}>
          {__('Components.Cart.update_confirmation.cancel')}
        </S.ModalButton>
        <S.ModalButton onClick={() => onConfirm(false)}>
          {__('Components.OrderDetails.setOtherConfirmationModal2.cta')}
        </S.ModalButton>
      </S.ButtonsContainer>
    </ActionsModal>
  ) : (
    <UpdateConfirmationModal
      defaultCheck={defaultCheck}
      onClose={onClose}
      onCancel={onCancel}
      disableCheck={disableCheck}
      onConfirm={checked => {
        if (checked) {
          return onConfirm(true);
        }
        if (config.TOGGLE_MAPPINGS_PER_CLIENT.enabled) {
          onConfirm(false);
        } else setShowStep2(true);
      }}
      title={
        config.TOGGLE_MAPPINGS_PER_CLIENT.enabled
          ? __('Components.OrderDetails.setOtherConfirmationModal.title_client', {
              count: mappedProducts.length,
              name:
                mappedProducts[0].productTitle ||
                productService.getProductTypeVarietyDisplay(type, prodTypes[type] ? prodTypes[type].name : '', variety),
              code: externalCode,
              client: client.name,
            })
          : __('Components.OrderDetails.setOtherConfirmationModal.title', {
              count: mappedProducts.length,
              name:
                mappedProducts[0].productTitle ||
                productService.getProductTypeVarietyDisplay(type, prodTypes[type] ? prodTypes[type].name : '', variety),
              code: externalCode,
            })
      }
      description={
        !config.TOGGLE_MAPPINGS_PER_CLIENT.enabled
          ? __('Components.OrderDetails.setOtherConfirmationModal.description', {
              name: productName,
              code: externalCode,
            })
          : null
      }
      image={IMAGES.notificationsPineapple}
      checkLabel={__('Components.OrderDetails.setOtherConfirmationModal.check')}
      cancelCTA={__('Components.Cart.update_confirmation.cancel')}
      ctaNotChecked={__('Components.OrderDetails.setOtherConfirmationModal.cta')}
      ctaChecked={__('Components.OrderDetails.setOtherConfirmationModal.cta2')}
    />
  );
};

export default SetOtherConfirmationModal;

import { __, parsers } from 'common-services';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'type-of-production',
  isVisible: true,
  getTitle: () => __('Components.ProductsList.Table.TypeOfProduction'),
  minWidth: '70px',
  width: '9%',
  columnValue: () => {
    return {
      getValue: item => parsers.getOrganicText(item.organic),
    };
  },
};

import { __, ORDER_ORIGIN } from 'common-services';
import * as React from 'react';

import { getOrderSourceText } from '../../../../../../../constants';

import * as S from './OrderInfo.styled';

import type { BubbleColor } from '../../../../../../atoms/TagBubble/TagBubble.component';
import type { IOrder } from 'common-services';

export const Tag: React.FC<{
  showStatusTag: boolean;
  statusLabel: { status: string };
  order: IOrder;
  weAreSeller: boolean;
}> = React.memo(({ showStatusTag, statusLabel, order, weAreSeller }) => (
  <S.TagContainer>
    {showStatusTag && <S.Tag label={statusLabel.status} disabled={true} color={order?.status as BubbleColor} />}
    {order?.origin !== ORDER_ORIGIN.INTERNAL && <S.Tag label={getOrderSourceText(order?.origin)} disabled={true} />}
    {weAreSeller && order?.sentToERP && (
      <S.Tag label={__('Components.Cart.sent_to_erp')} disabled={true} color="sent" />
    )}
  </S.TagContainer>
));

Tag.displayName = 'Tag';

import {
  __,
  addresses as addressUtils,
  constants,
  countrySelectors,
  currency,
  modalActions,
  ORDER_STATUS,
  orderService,
  parsers,
  prodTypeSelectors,
  PRODUCT_UNIT,
  productService,
  sellerWorkspaceActions,
  sellerWorkspaceService,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import config from '../../../../../../bindings/config';
import { IMAGE_DEFAULT, orderDetailBuyerColumns, orderDetailColumns } from '../../../../../constants';
import { IReduxState } from '../../../../../reducers';
import { navSelectors } from '../../../../../selectors';
import { Input, Select, Tooltip } from '../../../../atoms';
import { ContactCell } from '../../../../molecules';
import { IColumn, IDataCell, TYPE_CELL } from '../../../../molecules/Table/Table.component';
import * as S from './ItemsCard.styled';
import { priceUnitTranslate } from '../../../../../util/utils';

const ItemsCard: React.FC<{
  cartUpdateItem: (item: IOrderItem, issue?: IOrderIssue) => void;
  cart: ICart;
  me: IUser;
  priceMode: IPriceMode;
  showCustomColumns?: boolean;
  weAreSeller?: boolean;
}> = ({ cartUpdateItem, cart, me, priceMode, showCustomColumns, weAreSeller }) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const anyPriceEmpty = !!cart.items.find(i => !i.price);
  const workspaceSelected = useSelector(navSelectors.getSelectedWorkspace);
  const columnConfig = useSelector((state: IReduxState) => state.catalog.columnConfig[workspaceSelected.id]);
  const prodTypes = useSelector(prodTypeSelectors.getProdTypes);
  const countries = useSelector(countrySelectors.getCountries);
  const addresses = useSelector((state: IReduxState) => state.workspace.addresses[me.buyerWorkspaceId]);
  const [sortedItems, setSortedItems] = React.useState<Array<IOrderItem>>(getItemsSorted());
  const [priceEdit, setPriceEdit] = React.useState<boolean>(false);
  const pricePrecision = weAreSeller
    ? workspaceSelected
      ? workspaceSelected?.numberOfDecimalsShowed
      : constants.PRICE_PRECISION
    : constants.PRICE_PRECISION; // if we are buyer, the workspaceSelected is the buyer workspace, which does not have numberOfDecimalsShowed in its settings. Setting default value for now.

  React.useEffect(() => {
    if (workspaceSelected?.id)
      dispatch(
        sellerWorkspaceActions.tableVisibilityConfigGet(
          me.id,
          workspaceSelected.id,
          weAreSeller ? 'order_detail' : 'order_detail_buyer',
        ),
      );
  }, []);

  React.useEffect(() => {
    setSortedItems(getItemsSorted());
  }, [cart.items]);

  React.useEffect(() => {
    if (workspaceSelected?.id) {
      dispatch(
        sellerWorkspaceActions.tableVisibilityConfigGet(
          me.id,
          workspaceSelected.id,
          weAreSeller ? 'order_detail' : 'order_detail_buyer',
        ),
      );
    }
  }, [workspaceSelected, weAreSeller]);

  const isQuoterMode =
    (config.TOGGLE_MARGINS.enabled && weAreSeller && workspaceSelected?.plan?.addons?.quoterMarginsEnabled) || false;

  const columns = getColumns(isQuoterMode).filter(c => c);
  return (
    <S.ItemsTable
      isReadRow={() => false}
      values={sortedItems}
      onClickRow={(el: IOrderItem) => null}
      emptyText={''}
      columns={columns}
      selectable={false}
      fixedColumns={[
        'product',
        'quantity',
        'price',
        'unit-price',
        'served-quantity',
        'ordered-quantity',
        'status',
        'margin',
        'cost',
      ]}
      showCustomColumns={showCustomColumns}
      configId={weAreSeller ? 'order_detail' : 'order_detail_buyer'}
      productColumns={weAreSeller ? orderDetailColumns(isQuoterMode) : orderDetailBuyerColumns(isQuoterMode)} // orderDetailBuyerOfferColumns
    />
  );

  /**
   * Get items sorted according to filter
   */
  function getItemsSorted() {
    const itemsCopy = cart.items.filter(i => i.amount);

    if (anyPriceEmpty)
      itemsCopy.sort((a, b) => {
        if (!a.price) return -1;
        if (!b.price) return 1;
        return 0;
      });

    return itemsCopy.sort(orderService.sortOrderItems(prodTypes, workspaceSelected?.sortItemsBy));
  }

  function getColumns(isQuoterMode: boolean) {
    const configId = weAreSeller ? 'order_detail' : 'order_detail_buyer';

    const result = [
      getColumnField(
        { name: 'delete', order: -1, visible: true },
        {
          getElement: (data: IOrderItem) => {
            return (
              <>
                {data.servedQuantity ? (
                  <S.IconWrapper>
                    <S.RemoveIcon
                      name="Close"
                      onClick={e => {
                        e.stopPropagation();
                        cartUpdateItem({
                          ...data,
                          servedQuantity: 0,
                          amount: 0,
                        });
                      }}
                    />
                  </S.IconWrapper>
                ) : (
                  <S.Close>
                    <S.DeleteContainer>
                      <S.Deleted>{__('Components.ProductDetails.deleted')}</S.Deleted>
                      {weAreSeller ? (
                        <S.Undo onClick={() => cartUpdateItem({ ...data, servedQuantity: data.amount })}>
                          {__('Components.ProductDetails.undo')}
                        </S.Undo>
                      ) : null}
                    </S.DeleteContainer>
                  </S.Close>
                )}
              </>
            );
          },
        },
      ),
      getColumnField(
        { name: 'product', order: 0, visible: true },
        {
          getElement: (data: IOrderItem) => {
            const typeVariety = productService.getProductTypeVarietyDisplay(
              data.type,
              prodTypes[data.type] ? prodTypes[data.type].name : '',
              data.title,
            );

            return data.servedQuantity ? (
              <ContactCell
                status={ORDER_STATUS.DRAFT}
                avatar={(
                  (data.images && data.images.length && data.images[0].image.url) ||
                  (prodTypes[data.type] && prodTypes[data.type].defaultImageUrl) ||
                  IMAGE_DEFAULT
                ).replace('/f_auto/', '/f_auto,w_400,c_limit/')}
                imgText={'image text'}
                primaryText={data.productTitle || typeVariety + ' ' + data.size}
                showGrey={false}
              />
            ) : (
              <S.Close>
                <S.TextGrey>{data.productTitle || typeVariety + ' ' + data.size}</S.TextGrey>
              </S.Close>
            );
          },
        },
      ),
    ];
    const orderDetailColumnConfig = columnConfig?.find(c => c.view === configId);
    const configCol = orderDetailColumnConfig
      ? (orderDetailColumnConfig?.columns as Array<IColumnConfig>)
      : sellerWorkspaceService.getDefaultColumnConfig(configId);
    configCol
      .sort((a, b) => a.order - b.order)
      .map((column, columnIndex, arr) => {
        switch (column.name) {
          case 'category':
            result.push(
              getColumnField(column, {
                getValue: (data: IOrderItem) => {
                  return data.category;
                },
              }),
            );
            break;
          case 'variety':
            result.push(
              getColumnField(column, {
                getValue: (data: IOrderItem) => {
                  return data.variety;
                },
              }),
            );
            break;
          case 'units-per-box':
            result.push(
              getColumnField(column, {
                getValue: (data: IOrderItem) => {
                  return data.piecesPerBox;
                },
              }),
            );
            break;
          case 'contracts':
            result.push(
              getColumnField(column, {
                getElement: (data: IOrderItem) => {
                  const displayedRefs = data.contracts?.length > 2 ? 1 : 2;
                  return (
                    <S.ReferenceCol>
                      {data.contracts?.slice(0, displayedRefs).map(c => (
                        <>
                          <S.Reference>{c.reference}</S.Reference>
                        </>
                      ))}
                      {data.contracts?.length > 2 ? (
                        <Tooltip
                          component={
                            <S.ReferenceCol>
                              {data.contracts?.slice(1).map(c => (
                                <>
                                  <S.Reference>{'· ' + c.reference}</S.Reference>
                                </>
                              ))}
                            </S.ReferenceCol>
                          }
                          themeMode="dark"
                        >
                          <S.BlueText>
                            {__('WorkspaceSupplierEdit.Menu.contracts.see_more', {
                              count: data.contracts?.length - 1,
                            })}
                          </S.BlueText>
                        </Tooltip>
                      ) : null}
                    </S.ReferenceCol>
                  );
                },
              }),
            );
            break;
          case 'size':
            result.push(
              getColumnField(column, {
                getValue: (data: IOrderItem) => {
                  return data.size;
                },
              }),
            );
            break;
          case 'images':
            result.push(
              getColumnField(column, {
                getDataCell: (data: IOrderItem) => {
                  const emptyImages: Array<ISizeImage> = [];
                  const images = data.images || emptyImages;
                  return {
                    type: TYPE_CELL.IMAGE,
                    images: images.filter(i => i.image.url).map(img => img.image.url),
                    onImageClick: index =>
                      dispatch(
                        modalActions.touchImage(
                          images.map(img => ({ src: img.image.url, caption: img.image.comment })),
                          index,
                        ),
                      ),
                    count: images.filter(i => i.image.url).length,
                  };
                },
              }),
            );
            break;

          case 'warehouse':
            result.push(
              getColumnField(column, {
                getValue: (data: IOrderItem) => {
                  const address =
                    data.warehouseId && addresses ? addresses.find(a => a.id === data.warehouseId) : undefined;
                  return address ? addressUtils.getAddressName(address, countries) : '';
                },
              }),
            );
            break;

          case 'load':
            result.push(
              getColumnField(column, {
                getElement: (item: IOrderItem) => {
                  return <S.SubText>{item ? orderService.getCartLoadSummaryText([item]) : ''}</S.SubText>;
                },
              }),
            );
            break;
          default:
            result.push(getColumnField(column));
            break;
        }
      });
    result.push(
      getColumnField(
        { name: 'quantity', order: 17, visible: true },
        {
          getElement: (data: IOrderItem) => {
            return (
              <S.AmountRow onClick={e => e.stopPropagation()}>
                <S.QuantityRow>
                  <>
                    <S.AmountInput
                      id={`amount-${data.id}`}
                      name="amount"
                      onBlur={(key: string, value: string, error: string) => {
                        const v = Number(value);
                        cartUpdateItem({ ...data, amount: v, servedQuantity: v });
                      }}
                      value={data.servedQuantity || 0}
                      minValue={0}
                      precision={data.saleUnit === PRODUCT_UNIT.KG ? 2 : 0}
                      width="70px"
                      type="number"
                      textAlign="right"
                      autoFocus={false}
                      hasError={!data.servedQuantity}
                      containerMargin="0 6px 0 0"
                    />
                    <Select
                      containerMargin="0"
                      value={data.servedSaleUnit}
                      name={`served-sale-unit-${data.id}`}
                      options={data.saleUnits.map(s => ({
                        value: s,
                        label: config.TOGGLE_NEW_SALES_UNITS.organizations.includes(cart.catalogId)
                          ? parsers.getUnitText(priceUnitTranslate(s), data.weightUnit, 0)
                          : parsers.getUnitText(s, data.weightUnit, 0),
                      }))}
                      onChange={(n, v) => {
                        cartUpdateItem({
                          ...data,
                          saleUnit: v as PRODUCT_UNIT,
                          servedSaleUnit: v as PRODUCT_UNIT,
                        });
                      }}
                      width="100px"
                    />
                  </>
                </S.QuantityRow>
              </S.AmountRow>
            );
          },
        },
      ),
    );
    if (isQuoterMode) {
      result.push(
        getColumnField(
          { name: 'cost', order: 18, visible: true },
          {
            getElement: (data: IOrderItem) => {
              const { totalCost } = data;
              const totalItemCost = orderService.getTotalItemCost(data);
              return (
                <S.ProductPriceRow>
                  {totalCost ? (
                    <S.TooltipContainer>
                      <Tooltip position="top" text={__('Components.Cart.tooltip_cost')} themeMode="dark" width="200px">
                        <S.InfoIconBlack disableHover={true} name="Info" />
                      </Tooltip>
                    </S.TooltipContainer>
                  ) : null}
                  <S.PriceColumn>
                    <S.ItemPrice>
                      {totalCost
                        ? currency.getPricePerUnit(
                            data.currency,
                            data.priceUnit,
                            data.weightUnit,
                            totalCost,
                            pricePrecision,
                          )
                        : '-'}
                    </S.ItemPrice>
                    {totalItemCost ? (
                      <S.ItemPrice>{currency.getPrice(data.currency, totalItemCost, pricePrecision)}</S.ItemPrice>
                    ) : null}
                  </S.PriceColumn>
                </S.ProductPriceRow>
              );
            },
          },
        ),
      );
    }
    result.push(
      getColumnField(
        { name: 'unit-price', order: 19, visible: true },
        {
          getElement: (data: IOrderItem, index: number) => {
            return renderPrice(data, index);
          },
        },
        isQuoterMode,
      ),
    );

    if (isQuoterMode) {
      result.push(
        getColumnField(
          { name: 'margin', order: 21, visible: true },
          {
            getElement: (data: IOrderItem) => {
              const { margin, marginPercentage, totalItemMargin } = orderService.getProductItemMargin(data);
              return (
                <S.PriceColumn>
                  <S.ItemPrice>
                    {margin
                      ? currency.getPricePerUnit(data.currency, data.priceUnit, data.weightUnit, margin, pricePrecision)
                      : '-'}
                  </S.ItemPrice>
                  {totalItemMargin ? (
                    <S.ItemPrice>
                      {currency.getPrice(data.currency, totalItemMargin, pricePrecision) + ' '}
                      <S.MarginPercentage
                        baseMarginPercentage={data.baseMarginPercentage}
                        marginPercentage={marginPercentage}
                      >{`(${marginPercentage.toFixed(2)}%)`}</S.MarginPercentage>
                    </S.ItemPrice>
                  ) : null}
                </S.PriceColumn>
              );
            },
          },
        ),
      );
    }

    result.push(
      getColumnField(
        { name: 'price', order: 20, visible: true },
        {
          getElement: (data: IOrderItem) => {
            const price = orderService.getTotalPriceItem(data);
            return (
              <S.ItemPriceTotal isCancelled={false} showGrey={!data.servedQuantity}>
                {priceMode !== 'none' && price ? currency.getPrice(data.currency, price, pricePrecision) : '-'}
              </S.ItemPriceTotal>
            );
          },
        },
      ),
    );

    return result;
  }

  /**
   * Render price display or input
   */
  function renderPrice(item: IOrderItem, index: number) {
    const priceDisplay = priceToShow(item);
    const price = item.price;

    if (priceMode !== 'edit' || !item.servedQuantity) {
      return (
        <S.PriceGroupColumn>
          <S.PriceRow>
            <S.PriceGroupContainer>
              <S.PriceRow>
                <S.ItemPrice showGrey={!item.servedQuantity} isCancelled={false} hasServedChange={false}>
                  {priceDisplay}
                </S.ItemPrice>
              </S.PriceRow>
            </S.PriceGroupContainer>
          </S.PriceRow>
        </S.PriceGroupColumn>
      );
    }
    if (priceEdit || !item.price) {
      return (
        <S.PriceColumn>
          <S.PriceRow>
            <Input
              id={`price-${item.id}`}
              name={'price'}
              onBlur={(key, value, error) => updateItemPrice(item, key, value, error)}
              value={price || 0}
              minValue={currency.getPriceInputMinimumValue(pricePrecision)}
              variableTextSingular={currency.getPricePerUnit(item.currency, item.priceUnit, item.weightUnit)}
              precision={pricePrecision}
              width="120px"
              type="number"
              textAlign="right"
              autoFocus={index === 0}
              hasError={!item.price}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </S.PriceRow>
        </S.PriceColumn>
      );
    }
    return (
      <S.PriceEditRow enabled={priceMode === 'edit'} onClick={() => priceMode === 'edit' && setPriceEdit(true)}>
        <Input
          id={`price-${item.id}`}
          name={'price'}
          onBlur={(key, value, error) => updateItemPrice(item, key, value, error)}
          value={price || 0}
          minValue={currency.getPriceInputMinimumValue(pricePrecision)}
          variableTextSingular={currency.getPricePerUnit(item.currency, item.priceUnit, item.weightUnit)}
          precision={pricePrecision}
          width="120px"
          type="number"
          textAlign="right"
          autoFocus={index === 0}
          hasError={!item.price}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      </S.PriceEditRow>
    );
  }

  /**
   * Get price formated or 'P.O.R.' text.
   */
  function priceToShow(item: IOrderItem) {
    const price = item.price;
    if (price === 0) return '-';
    if (price) {
      return currency.getPricePerUnit(item.currency, item.priceUnit, item.weightUnit, price, pricePrecision);
    }
    return __('Components.ProductDetails.por_on');
  }

  /**
   * Update POR item price
   * key: price or servedPrice
   */
  function updateItemPrice(item: IOrderItem, key?: string, value?: number | string, error?: string) {
    const v = Number(value);
    if (!error && v > 0) {
      cartUpdateItem({ ...item, [key]: v, isPor: false, servedPrice: v });
    }
    setPriceEdit(false);
  }
};

/**
 * Get column according to a custom config
 */
function getColumnField(
  columnConfig: IColumnConfig,
  options?: {
    getValue?: (data: IOrderItem, idx: number, rowIdx: number) => string | number;
    getElement?: (data: IOrderItem, idx: number, rowIdx: number) => React.ReactElement;
    getDataCell?: (data: IOrderItem, idx: number, rowIdx: number) => IDataCell;
  },
  isQuoterMode?: boolean,
): IColumn {
  const { getValue, getElement, getDataCell } = options || {};

  switch (columnConfig.name) {
    case 'actions':
      return {
        id: 'actions',
        title: '',
        element: getElement,
        width: '7%',
      };
    case 'box-weight':
      return {
        id: 'box-weight',
        title: __('Components.ProductsList.Table.BoxWeight'),
        value: (data: IOrderItem) => (data.boxWeight ? `${data.boxWeight} ${data.weightUnit}` : ''),
        minWidth: '70px',
        width: '9%',
      };
    case 'brand':
      return {
        id: 'brand',
        title: __('Components.ProductsList.Table.Brand'),
        minWidth: '70px',
        width: '9%',
      };
    case 'category':
      return {
        id: 'category',
        title: __('Components.ProductsList.Table.Category'),
        minWidth: '70px',
        width: '9%',
        value: (data: IProduct) =>
          parsers.getCategoryText(data.category as (typeof constants.PRODUCT_CATEGORIES)[number]),
      };
    case 'contracts':
      return {
        id: 'contracts',
        title: __('WorkspaceSupplierEdit.Menu.contracts.ref'),
        minWidth: '70px',
        width: '9%',
        element: getElement,
      };
    case 'cost':
      return {
        id: 'cost',
        title: __('Components.Cart.items.cost'),
        element: getElement,
        minWidth: '70px',
        width: '8%',
      };
    case 'delete':
      return {
        id: 'delete',
        title: '',
        element: getElement,
        width: '5%',
      };
    case 'ean':
      return {
        id: 'ean',
        title: __('Components.ProductsList.Table.Ean'),
        minWidth: '70px',
        width: '9%',
        value: (data: IProduct) => data.eanCode,
      };
    case 'images':
      return {
        id: 'images',
        title: __('Components.ProductsList.Table.Images'),
        getDataCell,
        minWidth: '70px',
        width: '9%',
      };
    case 'margin':
      return {
        id: 'margin',
        title: __('Components.Cart.items.margin'),
        element: getElement,
        minWidth: '80px',
        width: '9%',
      };
    case 'load':
      return {
        id: 'load',
        title: __('Components.ProductsList.Table.Load'),
        minWidth: '120px',
        width: '10%',
        element: getElement,
      };
    case 'product':
      return {
        id: 'product',
        title: __('Components.Cart.items.product'),
        element: getElement,
        minWidth: '160px',
        width: '20%',
      };

    case 'price':
      return {
        id: 'price',
        title: __('Components.Cart.items.price'),
        element: getElement,
        onClick: e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        },
        minWidth: '120px',
        width: '10%',
      };
    case 'unit-price':
      return {
        id: 'unit-price',
        title: isQuoterMode ? __('Components.Cart.items.proposal') : __('Components.Cart.items.unit_price'),
        element: getElement,
        minWidth: '70px',
        width: '8%',
      };
    case 'quantity':
      return {
        id: 'quantity',
        title: __('Components.Cart.items.quantity'),
        element: getElement,
        minWidth: '220px',
        width: '12%',
      };
    case 'status':
      return {
        id: 'status',
        title: __('Components.Cart.items.prepare_title'),
        onClick: e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        },
        minWidth: '140px',
        width: '12%',
        align: 'left',
        element: getElement,
      };
    case 'weight':
      return {
        id: 'weight',
        title: __('Components.Cart.items.weight'),
        element: getElement,
        minWidth: '70px',
        width: '9%',
      };
    case 'served-quantity':
      return {
        id: 'served-quantity',
        title: __('Components.Cart.items.served_quantity'),
        element: getElement,
        minWidth: '70px',
        width: '8%',
      };
    case 'ordered-quantity':
      return {
        id: 'ordered-quantity',
        title: __('Components.Cart.items.ordered_quantity'),
        element: getElement,
        minWidth: '70px',
        width: '8%',
      };
    case 'size':
      return {
        id: 'size',
        title: __('Components.ProductsList.Table.Size'),
        minWidth: '70px',
        value: (data: IOrderItem) => data.size,
        width: '9%',
      };
    case 'origin':
      return {
        id: 'origin',
        title: __('Components.ProductsList.Table.Origin'),
        value: (data: IOrderItem) => data.origin,
        minWidth: '50px',
        width: '9%',
      };

    case 'packaging':
      return {
        id: 'packaging',
        title: __('Components.ProductsList.Table.Packaging'),
        minWidth: '70px',
        width: '9%',
      };
    case 'updated':
      return {
        id: 'updated',
        title: __('Components.ProductsList.Table.UpdatedAt'),
        value: getValue,
        minWidth: '60px',
        width: '8%',
      };
    case 'region':
      return {
        id: 'region',
        title: __('Components.ProductsList.Table.Region'),
        minWidth: '70px',
        width: '9%',
      };
    case 'type-of-production':
      return {
        id: 'type-of-production',
        title: __('Components.ProductsList.Table.TypeOfProduction'),
        minWidth: '70px',
        width: '9%',
        value: (data: IProduct) => parsers.getOrganicText(data.organic),
      };
    case 'sku':
      return {
        id: 'sku',
        title: __('Components.ProductsList.Table.Sku'),
        minWidth: '70px',
        width: '9%',
      };
    case 'warehouse':
      return {
        id: 'warehouse',
        title: __('Components.ProductsList.Table.Warehouse'),
        minWidth: '120px',
        width: '10%',
        value: getValue,
      };
    case 'variety':
      return {
        id: 'variety',
        title: __('Components.ProductsList.Table.variety'),
        minWidth: '70px',
        width: '9%',
      };
    case 'units-per-box':
      return {
        id: 'units-per-box',
        title: __('Components.ProductsList.Table.UnitsPerBox'),
        minWidth: '70px',
        width: '9%',
        value: getValue,
      };

    default:
      return undefined;
  }
}

export default ItemsCard;

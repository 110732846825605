import { ORDER_ACCEPT_TYPE, __, productService, parsers } from 'common-services';
import * as React from 'react';

import config from '../../../../../bindings/config';
import { IMAGES } from '../../../../assets';
import { api } from '../../../../store';

import type { ItemWithIssue } from './hooksTypes';
import type {
  IOrder,
  IOrderItem,
  IContact,
  IClient,
  ISupplier,
  IUser,
  IProdType,
  IPriceMode,
  IOrderIssue,
  INotification,
  IOrderUser,
  buyerWorkspaceActions,
  modalActions,
  sellerWorkspaceActions,
  GenericProduct,
} from 'common-services';

interface UseOrderModalsProps {
  order?: IOrder;
  me: IUser;
  buyer: IOrderUser;
  contact?: IContact;
  clients?: Array<IClient>;
  suppliersOld?: Array<ISupplier>;
  weAreSeller: boolean;
  weAreBuyer: boolean;
  previewMode?: boolean;
  prodTypes: Record<string, IProdType>;
  priceMode?: IPriceMode;
  breadcrumb?: {
    parentSections: Array<IBreadcrumb>; // eslint-disable-line no-undef
    title: string;
  };
  setSelectedItem: (item?: IOrderItem) => void;
  updateBreadcrumb?: (newCtx: { parentSections: Array<IBreadcrumb>; title: string }) => void; // eslint-disable-line no-undef
  handleItemUpdate: (item: IOrderItem, issue?: IOrderIssue, updateProduct?: boolean) => void;
  handleBoxesPerPalletChange: (orderItem: IOrderItem, newValue: number, applyOnlyToOrder: boolean) => void;
  notificationShow: (notification: INotification, duration?: number) => void;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  clientUpdate: typeof sellerWorkspaceActions.clientUpdate;
  supplierUpdate: typeof buyerWorkspaceActions.supplierUpdate;
  setItemWithIssue: (item?: ItemWithIssue) => void;
}

interface ProductPageProps {
  contactId: unknown;
  deletable: boolean;
  item: IOrderItem;
  priceMode: IPriceMode;
  product: GenericProduct;
  saleUnits: unknown;
  weAreSeller: unknown;
}

export interface UseOrderModalsReturn {
  showClientsModal: boolean;
  showReferenceErrorTooltip: boolean;
  showLogistic: boolean;
  showPriceModal: boolean;
  showClientViewModal: boolean;
  showIssuesModal: boolean;
  showBoxesPerPalletModal: boolean;
  showNewMbppValueInput: boolean;
  setShowClientsModal: (show: boolean) => void;
  setShowReferenceErrorTooltip: (show: boolean) => void;
  setShowLogistic: (show: boolean) => void;
  setShowClientViewModal: (show: boolean) => void;
  setShowIssuesModal: (show: boolean) => void;
  setShowNewMbppValueInput: (show: boolean) => void;
  showAutoAcceptModal: () => void;
  showProductFromItem: (item: IOrderItem) => void;
  productPageProps?: ProductPageProps;
  closeProductPage: () => void;
  openIssuesModal: (orderItem: IOrderItem) => void;
  showSuccessUpdateModal: (order: IOrder) => void;
  openBoxesPerPalletModal: (orderItem: IOrderItem) => void;
  closeBoxesPerPalletModal: () => void;
  onSubmitBoxesPerPalletModal: (orderItem: IOrderItem, newValue: number) => void;
  openBoxesPerPalletConfirmationModal: (orderItem: IOrderItem, newValue?: number, onConfirm?: () => void) => void;
  openPriceModal: (item: IOrderItem) => void;
  closePriceModal: () => void;
  onSubmitPriceModal: (item: IOrderItem, updateProduct?: boolean) => void;
  showSelectWorkspaceModal: boolean;
  setShowSelectWorkspaceModal: (show: boolean) => void;
  openSearchProductsModal: (item: IOrderItem, originLot?: number, mappingCode?: string) => void;
  closeSearchProductsModal: () => void;
  showSearchProductsModal: boolean;
}

/**
 * Hook to handle order modals state and functionality
 */
const useOrderModals = ({
  order,
  me,
  buyer,
  contact,
  clients,
  suppliersOld,
  weAreSeller,
  weAreBuyer,
  previewMode,
  prodTypes,
  priceMode,
  breadcrumb,
  updateBreadcrumb,
  handleItemUpdate,
  modalOpen,
  modalClose,
  clientUpdate,
  supplierUpdate,
  setSelectedItem,
  handleBoxesPerPalletChange,
  setItemWithIssue,
  notificationShow,
}: // setItemWithIssue,
UseOrderModalsProps): UseOrderModalsReturn => {
  // State
  const [showClientsModal, setShowClientsModal] = React.useState(false);
  const [showReferenceErrorTooltip, setShowReferenceErrorTooltip] = React.useState(false);
  const [showLogistic, setShowLogistic] = React.useState(false);
  const [showPriceModal, setShowPriceModal] = React.useState(false);
  const [productPageProps, setProductPageProps] = React.useState<ProductPageProps | undefined>();
  const [showClientViewModal, setShowClientViewModal] = React.useState(false);
  const [showIssuesModal, setShowIssuesModal] = React.useState(false);
  const [showBoxesPerPalletModal, setShowBoxesPerPalletModal] = React.useState(false);
  const [onlyApplyToCurrentOrder, setOnlyApplyToCurrentOrder] = React.useState(false);
  const [showNewMbppValueInput, setShowNewMbppValueInput] = React.useState(false);
  const [showSelectWorkspaceModal, setShowSelectWorkspaceModal] = React.useState<boolean>(false);
  const [showSearchProductsModal, setShowSearchProductsModal] = React.useState(false);

  // Callbacks

  /**
   * Close search product modal
   */
  const closeSearchProductsModal = React.useCallback(() => {
    setShowSearchProductsModal(false);
    setItemWithIssue();
  }, [setShowSearchProductsModal, setItemWithIssue]);

  /**
   * Show search product modal
   */
  const openSearchProductsModal = React.useCallback(
    async (item: IOrderItem, originLot?: number, mappingCode?: string) => {
      try {
        let productsMapped;
        if (mappingCode) {
          const productMappings = await api.mapping.getProductMappingsForExternalRef(
            me.id,
            order?.catalogId,
            mappingCode,
            config.TOGGLE_MAPPINGS_PER_CLIENT.enabled ? buyer.id : null,
          );
          if (productMappings && productMappings.length > 0) {
            productsMapped = productMappings;
          }
        }
        setItemWithIssue({
          item,
          originLot,
          mappingCode,
          productsMapped,
        });
        setShowSearchProductsModal(true);
      } catch (error) {
        console.error('Error loading product mappings:', error);
        notificationShow({
          style: 'error',
          title: __('Error.generic'),
          subtitle: __('Error.generic_description'),
          closable: true,
        });
      }
    },
    [order, notificationShow, me, setItemWithIssue, buyer?.id],
  );

  /**
   * Show auto accept feedback modal
   */
  const showAutoAcceptFeedback = React.useCallback(
    (type: ORDER_ACCEPT_TYPE) => {
      const contactName = contact?.name || clients?.find(c => c.userId === contact?.id)?.name || '';
      const image = type === ORDER_ACCEPT_TYPE.AUTOMATIC ? IMAGES.acceptAuto : IMAGES.acceptManual;
      const title =
        type === ORDER_ACCEPT_TYPE.MANUAL
          ? __('Components.OrderDetails.autoaccept.title_manual')
          : __('Components.OrderDetails.autoaccept.title_auto');
      const body =
        type === ORDER_ACCEPT_TYPE.MANUAL
          ? __('Components.OrderDetails.autoaccept.body_manual', { contact_name: contactName })
          : __('Components.OrderDetails.autoaccept.body_auto', { contact_name: contactName });

      modalOpen(
        title,
        modalClose,
        {
          icon: image,
          text2: body,
          buttonText: __('Components.OrderDetails.autoaccept.cta_feedback'),
        },
        'nice',
      );
    },
    [contact, clients, modalOpen, modalClose],
  );

  /**
   * Show auto accept modal
   */
  const showAutoAcceptModal = React.useCallback(() => {
    if (weAreSeller && clients && contact) {
      const client = clients.find(c => c.userId === contact?.id);
      if (client?.orderAccept === ORDER_ACCEPT_TYPE.NONE) {
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        const automaticFn = () =>
          clientUpdate(
            me.id,
            order?.catalogId,
            { ...client, orderAccept: ORDER_ACCEPT_TYPE.AUTOMATIC },
            (err?: Error) => {
              if (!err) {
                showAutoAcceptFeedback(ORDER_ACCEPT_TYPE.AUTOMATIC);
              }
            },
          );

        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        const manualFn = () =>
          clientUpdate(me.id, order?.catalogId, { ...client, orderAccept: ORDER_ACCEPT_TYPE.MANUAL }, (err?: Error) => {
            if (!err) {
              showAutoAcceptFeedback(ORDER_ACCEPT_TYPE.MANUAL);
            }
          });

        modalOpen(
          __('Components.OrderDetails.autoaccept.title_auto'),
          automaticFn,
          {
            showCancelButton: true,
            icon: IMAGES.acceptAuto,
            text2: __('Components.OrderDetails.autoaccept.body', {
              contact_name: client.name,
            }),
            buttonText: __('Components.OrderDetails.autoaccept.cta'),
            cancelAction: manualFn,
            buttonCancelText: __('Components.OrderDetails.autoaccept.cancel'),
          },
          'nice',
        );
      }
    } else {
      const supplier = suppliersOld?.find(c => c.userId === contact?.id);

      if (supplier && supplier?.orderAccept === ORDER_ACCEPT_TYPE.NONE) {
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        const automaticFn = () =>
          supplierUpdate(
            me.id,
            order?.buyerWorkspaceId,
            { ...supplier, orderAccept: ORDER_ACCEPT_TYPE.AUTOMATIC },
            (err?: Error) => {
              if (!err) {
                showAutoAcceptFeedback(ORDER_ACCEPT_TYPE.AUTOMATIC);
              }
            },
          );

        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        const manualFn = () =>
          supplierUpdate(
            me.id,
            order?.buyerWorkspaceId,
            { ...supplier, orderAccept: ORDER_ACCEPT_TYPE.MANUAL },
            (err?: Error) => {
              if (!err) {
                showAutoAcceptFeedback(ORDER_ACCEPT_TYPE.MANUAL);
              }
            },
          );

        modalOpen(
          __('Components.OrderDetails.autoaccept.title_auto'),
          automaticFn,
          {
            showCancelButton: true,
            icon: IMAGES.acceptAuto,
            text2: __('Components.OrderDetails.autoaccept.body', {
              contact_name: supplier.name,
            }),
            buttonText: __('Components.OrderDetails.autoaccept.cta'),
            cancelAction: manualFn,
            buttonCancelText: __('Components.OrderDetails.autoaccept.cancel'),
          },
          'nice',
        );
      }
    }
  }, [
    weAreSeller,
    clients,
    suppliersOld,
    contact,
    me.id,
    order?.catalogId,
    order?.buyerWorkspaceId,
    clientUpdate,
    supplierUpdate,
    modalOpen,
    showAutoAcceptFeedback,
  ]);

  /**
   * Opens the modal to select products
   */
  // const openProductSelectionModal = React.useCallback(
  //   (item: IOrderItem, e?: React.MouseEvent): void => {
  //     setShowSelectWorkspaceModal(false);
  //     showProductsModal(item, e);
  //   },
  //   [showProductsModal, setShowSelectWorkspaceModal],
  // );

  const openIssuesModal = React.useCallback(
    (orderItem: IOrderItem) => {
      setShowIssuesModal(true);
      setSelectedItem(orderItem);
    },
    [setShowIssuesModal, setSelectedItem],
  );

  /**
   * Show price modal
   */
  const openPriceModal = React.useCallback(
    (item: IOrderItem) => {
      setSelectedItem(item);
      setShowPriceModal(true);
    },
    [setShowPriceModal, setSelectedItem],
  );

  /**
   * Close price modal
   */
  const closePriceModal = React.useCallback(() => {
    setSelectedItem();
    setShowPriceModal(false);
  }, [setShowPriceModal, setSelectedItem]);

  /**
   * Handle price modal submit
   */
  const onSubmitPriceModal = React.useCallback(
    (newItem: IOrderItem, updateProduct?: boolean) => {
      const issueRelated = order?.issues.find(
        issue => issue.orderItemId === newItem.id && issue.type === 'product-no-price',
      );

      if (!issueRelated) {
        console.error('No issue found for product-no-price for item', newItem);
      }

      handleItemUpdate(newItem, issueRelated, updateProduct);
      closePriceModal();
    },
    [handleItemUpdate, order?.issues, closePriceModal],
  );

  /**
   * Show success update modal
   */
  const showSuccessUpdateModal = React.useCallback(
    (updatedOrder: IOrder) => {
      const contactName = contact?.name || clients?.find(c => c.userId === contact?.id)?.name || '';

      modalOpen(
        __('Components.OrderDetails.success_update.title', {
          hashId: updatedOrder.externalIdSeller || order?.externalIdBuyer || '#' + updatedOrder.hashId,
        }),
        modalClose,
        {
          text2: __('Components.OrderDetails.success_update.description', {
            name: contactName,
          }),
          buttonText: __('Components.OrderDetails.success_update.cta'),
          icon: IMAGES.checkOK,
        },
        'nice',
      );
    },
    [order, contact, clients, modalOpen, modalClose],
  );

  /**
   * Open & close boxes per pallet modal
   */
  const openBoxesPerPalletModal = React.useCallback(
    (orderItem: IOrderItem) => {
      setSelectedItem(orderItem);
      setShowBoxesPerPalletModal(true);
    },
    [setShowBoxesPerPalletModal, setSelectedItem],
  );

  const closeBoxesPerPalletModal = React.useCallback(() => {
    setSelectedItem();
    setShowBoxesPerPalletModal(false);
  }, [setShowBoxesPerPalletModal, setSelectedItem]);

  /**
   * Show boxes per pallet confirmation modal
   */
  const openBoxesPerPalletConfirmationModal = React.useCallback(
    (orderItem: IOrderItem, newValue?: number, onConfirm?: () => void) => {
      if (!onConfirm) {
        onConfirm = () => {
          handleBoxesPerPalletChange(orderItem, newValue, onlyApplyToCurrentOrder);
          setSelectedItem();
          setOnlyApplyToCurrentOrder(false);
        };
      }
      modalOpen(
        prodTypes[orderItem.type]?.name + ' ' + orderItem.variety || orderItem.title,
        () => {
          onConfirm?.();
          modalClose();
        },
        {
          text2: !orderItem.boxesPerPallet
            ? __('Components.Cart.boxesPerPallet.confirmation_modal.text_add', {
                newValue,
              })
            : __('Components.Cart.boxesPerPallet.confirmation_modal.text', {
                oldValue: orderItem.boxesPerPallet,
                newValue,
              }),
          buttonText: __('Components.Cart.boxesPerPallet.confirmation_modal.cta'),
          showCancelButton: true,
          icon: IMAGES.informativePineapple,
          checkBox: __('Components.Cart.boxesPerPallet.confirmation_modal.checkbox'),
          checkBoxAction: () => {
            setOnlyApplyToCurrentOrder(!onlyApplyToCurrentOrder);
          },
        },
        'nice',
      );
    },
    [modalOpen, modalClose, prodTypes, handleBoxesPerPalletChange, onlyApplyToCurrentOrder, setSelectedItem],
  );

  const onSubmitBoxesPerPalletModal = React.useCallback(
    (orderItem: IOrderItem, newValue: number) => {
      if (orderItem.boxesPerPallet !== newValue) {
        openBoxesPerPalletConfirmationModal(orderItem, newValue);
      }
    },
    [openBoxesPerPalletConfirmationModal],
  );

  /**
   * Show product info page (modal) from item
   * @param item
   */
  const showProductFromItem = React.useCallback(
    (item: IOrderItem) => {
      // Early return if in preview mode
      if (previewMode) return;

      // Get product information
      const product = parsers.orderItemToGenericProduct(item);

      // Generate product title
      const title = productService.getProductTypeVarietyDisplay(
        item?.type,
        prodTypes[item?.type]?.name || '',
        item?.title,
      );

      // Update breadcrumb if needed
      if (updateBreadcrumb && breadcrumb?.title !== title) {
        updateBreadcrumb({
          parentSections: [
            ...(breadcrumb.parentSections || []),
            {
              label: breadcrumb.title,
              action: () => {
                updateBreadcrumb(breadcrumb);
                setProductPageProps(undefined);
              },
            },
          ],
          title,
        });
      }

      // Set product page props
      setProductPageProps({
        contactId: weAreBuyer ? order?.sellerId : order?.buyerId,
        deletable: order?.items.filter(oi => oi.amount).length > 1,
        item,
        priceMode,
        product,
        saleUnits: product.saleUnits,
        weAreSeller,
      });
    },
    [
      setProductPageProps,
      breadcrumb,
      weAreBuyer,
      order?.sellerId,
      order?.buyerId,
      order?.items,
      priceMode,
      prodTypes,
      previewMode,
      weAreSeller,
      updateBreadcrumb,
    ],
  );

  /**
   * Close product info page (modal)
   */
  const closeProductPage = React.useCallback(() => {
    setProductPageProps(undefined);
    if (updateBreadcrumb && breadcrumb) {
      updateBreadcrumb(breadcrumb);
    }
  }, [setProductPageProps, updateBreadcrumb, breadcrumb]);

  return {
    showClientsModal,
    showReferenceErrorTooltip,
    showLogistic,
    showClientViewModal,
    showIssuesModal,
    showBoxesPerPalletModal,
    showProductFromItem,
    productPageProps,
    closeProductPage,
    showNewMbppValueInput,
    setShowClientsModal,
    setShowReferenceErrorTooltip,
    setShowLogistic,
    setShowClientViewModal,
    setShowIssuesModal,
    setShowNewMbppValueInput,
    showAutoAcceptModal,
    showSuccessUpdateModal,
    openBoxesPerPalletModal,
    closeBoxesPerPalletModal,
    onSubmitBoxesPerPalletModal,
    openBoxesPerPalletConfirmationModal,
    openPriceModal,
    showPriceModal,
    closePriceModal,
    onSubmitPriceModal,
    openIssuesModal,
    showSelectWorkspaceModal,
    setShowSelectWorkspaceModal,
    openSearchProductsModal, // eslint-disable-line @typescript-eslint/no-misused-promises
    closeSearchProductsModal,
    showSearchProductsModal,
  };
};

export default useOrderModals;

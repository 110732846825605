import { __ } from 'common-services';
import * as React from 'react';

import { OrderProvider } from './context';
import { useOrderInitialization, useOrderCleanup } from './hooks';
import OrderContent from './OrderContent.component';

import type { OrderDetailsProps } from './OrderDetails.props';

/**
 * OrderDetails Component
 *
 * Handles the display and management of order details, including initialization,
 * cleanup, and error states.
 */
const OrderDetails: React.FC<OrderDetailsProps> = React.memo(props => {
  // Effect cleanup on unmount
  useOrderCleanup(props);

  // Initialize order data and handle loading states
  const { ...initData } = useOrderInitialization(props);

  // Render main content when all conditions are met
  return (
    <OrderProvider {...props} {...initData}>
      <OrderContent />
    </OrderProvider>
  );
});

OrderDetails.displayName = 'OrderDetails';

export default OrderDetails;

import styled, { css } from '../../../../../../styled-components';
import { Input, FontIcon } from '../../../../../atoms';

import type { ITheme } from '../../../../../../styled-components';

interface IStyledProps {
  enabled?: boolean;
  disabled?: boolean;
  margin?: string;
  numHeaders?: number;
  theme: ITheme;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const EditIcon = styled(FontIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  transition: color 0.2s ease;
  color: ${({ theme }) => theme.colors.black};
  padding: 1px 4px;
  font-size: 0.8125rem;
  background: rgb(230, 230, 230);
  border-radius: 5px;
  &:hover {
    color: ${({ theme }) => theme.colors.black};
    background: rgb(230, 230, 230);
  }
`;

export const EditInput = styled(Input)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize(2)};
  min-width: 174px;
  max-width: 174px;
`;

export const InputWrapper = styled.div<{ hasError?: boolean }>`
  ${({ hasError, theme }) =>
    hasError &&
    css`
      input {
        border-color: ${theme.colors.red1};

        &:focus {
          border-color: ${theme.colors.red1};
          box-shadow: 0 0 0 2px ${theme.colors.red2};
        }
      }
    `}
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 4px;
`;

/**
 * Base action button component that serves as a foundation for other button variants
 */
export const ActionButton = styled(FontIcon)<{ enabled?: boolean }>`
  cursor: pointer;
  color: ${({ theme, enabled = true }) => (enabled ? theme.colors.black : theme.colors.grey2)};
  transition: color 0.2s ease;
  display: inline-flex;
  padding: 5px 7px;
  border-radius: ${p => p.theme.borderRadius};
  margin-right: ${p => p.theme.paddingSize(2)};
`;

/**
 * Add button variant with green background
 */
export const AddButton = styled(ActionButton)`
  color: ${p => p.theme.colors.white};
  font-size: ${p => p.theme.fontSize.big};
  background: ${p => (p.enabled ? p.theme.colors.green1 : p.theme.colors.green2)};
  border: 1px solid ${p => (p.enabled ? p.theme.colors.green1 : p.theme.colors.green2)};
  cursor: ${p => (p.enabled ? 'pointer' : 'not-allowed')};
`;

/**
 * Close button variant with white background and black border
 */
export const CloseButton = styled(AddButton)`
  color: ${p => p.theme.colors.black};
  border: 1px solid ${p => p.theme.colors.black};
  background: ${p => p.theme.colors.white};
`;

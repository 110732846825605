import styled, { ITheme } from '../../../styled-components';
import { Button, CheckBox as CB, ColumnContainer, TextLabel } from '..';

interface IStyledProps {
  theme: ITheme;
  twoCTA?: boolean;
}

export const Container = styled(ColumnContainer)`
  align-items: flex-start;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  flex: 1;
  width: 100%;
  min-height: 50vh;
`;

export const Content = styled(ColumnContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  width: 100%;

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding: 0;
  }
`;

export const Title = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  align-self: center;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const TitlePurple = styled(Title)`
  display: inline;
  padding: 0;
  color: ${(p: IStyledProps) => p.theme.colors.purple1};
`;

export const SubTitle = styled(Title)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const Row = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  max-width: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;
export const CheckBoxRow = styled.div`
  flex-direction: row;
  align-self: start;
  align-items: end;
  display: flex;
  justify-content: center;
  max-width: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const Col = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
`;

export const RowBody = styled(Row)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} 0 ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
`;

export const TextBody = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  text-align: left;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  line-height: 1.6;
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    text-align: center;
    padding-top: 0;
  }
`;

export const BodyBold = styled(TextBody)`
  display: inline;
  padding: 0;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;
export const BodyBlue = styled(TextBody)`
  display: inline;
  padding: 0;
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  cursor: pointer;
`;

export const RowCTA = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: ${(p: IStyledProps) => (p.twoCTA ? 'space-between' : 'center')};
  max-width: 100%;
  width: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;

  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    padding: 0;
    padding-top: ${(p: IStyledProps) => p.theme.paddingSize(2)};
    flex-direction: column-reverse;
  }
`;

export const CTA = styled(Button)`
  flex: 1;
  max-width: 49%;
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    width: 100%;
    max-width: 100%;
  }
`;
export const LinkText = styled(TextLabel)`
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  text-align: left;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  line-height: 1.6;
  @media only screen and (max-width: ${(p: IStyledProps) => p.theme.sizes.mobile}) {
    text-align: center;
  }
`;
export const CheckBox = styled(CB)`
  padding-bottom: 2px;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  margin: 0 auto;
`;

export const CheckBoxLabel = styled.label`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const TextLink = styled.span`
  color: ${({ theme }) => theme.colors.blue1};
  display: inline;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const Pre = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: monospace;
  font-size: 12px;
  line-height: 1.5;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.grey4};
  border-radius: 4px;
  max-height: 500px;
  overflow-y: auto;
`;

export const SectionHeader = styled.div`
  display: flex;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey3};
  }
`;

export const SectionTitle = styled.h3`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.H3};
  margin-left: 8px;
`;

export const SectionContent = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  padding-top: 8px;
`;

export const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

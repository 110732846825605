import { __ } from 'common-services';
import * as React from 'react';

import { BackButton } from '../../../../../atoms';

import * as S from './BackNavigation.styled';

interface BackNavigationProps {
  text?: string;
  onBack: () => void;
  showConfirmation?: boolean;
  confirmationMessage?: string;
  confirmationTitle?: string;
  modalType?: 'warning' | 'danger' | 'info';
}

export const BackNavigation: React.FC<BackNavigationProps> = React.memo(
  ({
    text,
    onBack,
    showConfirmation = false,
    // confirmationMessage = __('Components.ProductDetails.confirm_exit_changes'),
    // confirmationTitle = __('Components.ProductDetails.confirm_exit_title'),
    // modalType = 'warning',
  }) => {
    const [showModal, setShowModal] = React.useState(false); // eslint-disable-line @typescript-eslint/no-unused-vars

    const handleBack = React.useCallback(() => {
      if (showConfirmation) {
        setShowModal(true);
      } else {
        onBack();
      }
    }, [onBack, showConfirmation]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleConfirm = React.useCallback(() => {
      setShowModal(false);
      onBack();
    }, [onBack]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleCancel = React.useCallback(() => {
      setShowModal(false);
    }, []);

    return (
      <>
        <S.Container>
          <BackButton onClick={handleBack} text={text || __('Components.Cart.back')} />
        </S.Container>

        {/* {showModal && (
          <ConfirmationModal
            isOpen={showModal}
            title={confirmationTitle}
            message={confirmationMessage}
            confirmLabel={__('Common.yes')}
            cancelLabel={__('Common.no')}
            type={modalType}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )} */}
      </>
    );
  },
);

BackNavigation.displayName = 'BackNavigation';

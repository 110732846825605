import {
  __,
  buyerWorkspaceActions,
  constants,
  debounce,
  EventTrack,
  ISuppliersSearch,
  LOCALE,
  modalActions,
  orderActions,
  parsers,
  productActions,
  productService,
  qs,
  RenderTrack,
  sellerWorkspaceService,
  throttle,
  utils,
} from 'common-services';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import config from '../../../../bindings/config';
import * as navActions from '../../../actions/nav';
import { IMAGES } from '../../../assets';
import { getListModeOptions, ROUTE_PATHS } from '../../../constants';
import { api } from '../../../store';
import { sizes } from '../../../theme';
import getPath from '../../../util/routes';
import { FilterSwitchIcon, LettersAvatar } from '../../atoms';
import {
  EmptyListResource,
  NonProviders,
  ProductCard,
  ProductFilter,
  ProductTable,
  SimpleSearch,
  ZeroCase,
} from '../../molecules';
import ProductInfo from '../ProductInfo/ProductInfo.component';
import Workspace from '../Workspace/Workspace.component';
import * as S from './WorkspaceProductsBuyer.styled';

interface IAddProductModalProps {
  amSeller: boolean;
  contactId: number;
  deletable: boolean;
  forceSetPrice?: boolean;
  item: IOrderItem;
  priceMode?: IPriceMode;
  saleUnits?: Array<PRODUCT_UNIT>;
}

export interface IStateProps {
  address?: IAddress;
  carts: Record<string, ICart>;
  contacts: { [id: number]: IContact };
  countries: { [k: string]: ICountry };
  featured: {
    favorite: { [key: string]: Array<string> };
    recommended: { [key: string]: Array<string> };
    recent: { [key: string]: Array<string> };
  };
  me: IUser;
  prodTypes: { [key: string]: IProdType };
  workspace: IWorkspace;
  workspaceAssignedId: number;
  workspaces: Record<string, IWorkspace>;
  filterBuyerProducts?: ISearch;
}

export interface IDispatchProps {
  buyerWorkspaceAssign: typeof buyerWorkspaceActions.workspaceAssign;
  cartUpdateItem: typeof orderActions.cartUpdateItem;
  featuredToggle: typeof productActions.featuredToggle;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  touchImage: typeof modalActions.touchImage;
  workspaceAddressesGet: typeof buyerWorkspaceActions.workspaceAddressesGet;
  setFilterBuyerProducts: typeof navActions.setFilterBuyerProducts;
}

type IProps = IStateProps &
  IDispatchProps &
  RouteComponentProps<{ workspaceId: string; productId: string; supplierId: string }>;

interface IState {
  facets: IFacets;
  facetsGlobal: IFacets;
  hasMore?: boolean;
  hideFilters?: boolean;
  hideList?: boolean;
  isSearched?: boolean;
  listMode: 'card' | 'list';
  prices: Record<number, IPrice>;
  prodTypes: { [key: string]: IProdType };
  products: Array<IProduct>;
  searchId?: string;
  searchState: ISearch;
  sortMode: string;
  totalResults: number;
  suppliers?: Array<ISuppliersSearch>;
  showroomSelected?: boolean;
  addProductModalProps?: IAddProductModalProps & { product: IProduct };
}

class WorkspaceProducts extends React.PureComponent<IProps, IState> {
  private t: number;
  private sendSearch: () => void = debounce(() => {
    const { history, me, location, setFilterBuyerProducts, workspace } = this.props;
    const { searchState } = this.state;
    setFilterBuyerProducts(workspace?.id, searchState);
    history.replace(location.pathname + qs.stringify({ query: searchState.text }));
    this.setState({ isSearched: false });
    productService
      .productSearch(searchState, searchState.language, config.SEARCH_PREFIX, me.id, api, true)
      .then(this.onReceiveProducts);
  }, 200);

  private scrollSearch = throttle(() => {
    const { searchId } = this.state;
    productService.productScrollSearch(searchId, api).then(this.onReceiveProducts);
  }, 200);

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();

    const defaultListMode =
      typeof window !== 'undefined' && ['card', 'list'].includes(window.localStorage.getItem('products_buyer_display'))
        ? (window.localStorage.getItem('products_buyer_display') as 'card' | 'list')
        : 'list';

    this.state = {
      listMode: defaultListMode,
      prodTypes: props.prodTypes,
      sortMode: '',
      searchState: props.filterBuyerProducts || {
        text: (qs.parse(window.location.search, ['query']) as { query: string })?.query || '',
        language: props.me.settings.language as LOCALE,
        index: '',
        status: ['active', 'unavailable'],
        sort: '',
      },
      totalResults: 0,
      prices: {},
      products: [],
      facets: {},
      facetsGlobal: {},
      hideFilters: typeof window !== 'undefined' && window.innerWidth > sizes.ipad,
    };
  }

  public componentDidMount() {
    RenderTrack.track('WorkspaceProductsBuyer', {
      renderTime: this.t,
    });
    const {
      me,
      history,
      match: {
        params: { workspaceId, productId },
      },
      workspaceAddressesGet,
    } = this.props;
    api.buyerWorkspace.suppliersSearchGet(me.id, Number(workspaceId)).then(suppliers => this.setState({ suppliers }));

    workspaceAddressesGet(me.id, Number(workspaceId));
    if (productId) {
      history.push(
        getPath({
          path: ROUTE_PATHS.WORKSPACE_PRODUCTS_BUYER,
          workspaceId,
        }),
      );
    }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const {
      workspaceAssignedId,
      workspace,
      carts,
      match: {
        params: { productId, supplierId },
      },
      me,
    } = this.props;
    const { searchId, suppliers, products, prices, searchState, sortMode } = this.state;
    if (
      sortMode !== prevState.sortMode ||
      workspaceAssignedId !== prevProps.workspaceAssignedId ||
      workspace?.hashId !== prevProps.workspace?.hashId ||
      suppliers !== prevState.suppliers
    ) {
      const indexes = suppliers?.reduce((aux: Record<string, Array<string>>, s) => {
        aux[s.workspaceHashId] = s.pricegroups;
        return aux;
      }, {});
      this.setState({
        searchState: {
          ...searchState,
          indexes,
          sort: sortMode ? sortMode.split(' ')[0] : '',
          sortOrder: (sortMode ? sortMode.split(' ')[1] : 'desc') as 'asc' | 'desc',
        },
      });
    }
    if (searchState !== prevState.searchState) {
      this.sendSearch();
    }
    if (searchId && prevState.searchId !== searchId) {
      this.setState({ isSearched: true });
    }
    if (productId !== prevProps.match.params.productId) {
      if (productId) {
        const product = products?.find(p => p.id === Number(productId));
        if (product) {
          const key = productService.getKey(Number(supplierId), me.id);
          const productItem: IOrderItem = parsers.genericProductToOrderItem(
            parsers.productToGenericProduct(product),
            prices?.[product.id]?.price || product.price,
            0,
            prices?.[product.id]?.baseMarginPercentage || product.baseMarginPercentage,
            false,
            carts[key]?.items.find(i => i.childId === product.id)?.id,
          );
          this.setState({
            addProductModalProps: {
              product,
              item: productItem,
              amSeller: false,
              contactId: Number(supplierId),
              priceMode: prices?.[product.id]?.priceMode || 'none',
              saleUnits: product.saleUnits,
              deletable: carts[key]?.items.length > 1,
            },
          });
        }
      } else {
        this.setState({
          addProductModalProps: undefined,
        });
      }
    }
  }

  public render() {
    const {
      buyerWorkspaceAssign,
      carts,
      contacts,
      featured,
      history,
      location,
      match,
      me,
      touchImage,
      workspaceAssignedId,
      workspaces,
    } = this.props;
    const {
      addProductModalProps,
      facets,
      facetsGlobal,
      hideFilters,
      hideList,
      isSearched,
      listMode,
      prices,
      products,
      searchState,
      suppliers,
      totalResults,
    } = this.state;
    const workspace = this.props.workspace || workspaces[me.buyerWorkspaceId];
    const title = this.getTitle(addProductModalProps?.product);
    const subtitle = this.getSubTitle(addProductModalProps?.product);
    const isZeroCase = !products.length && !searchState.text && !this.getFiltersCount();
    return (
      <Workspace
        parentSections={
          addProductModalProps
            ? [
                {
                  label: __('Components.ProductsList.Tabs.products_buyer'),
                  action: () => this.setState({ addProductModalProps: undefined }),
                },
              ]
            : undefined
        }
        subtitle={subtitle}
        title={title}
        tabSelected={'products'}
        workspaceId={workspaceAssignedId}
        isBuyerWorkspace={true}
      >
        {workspaceAssignedId !== me.buyerWorkspaceId ? (
          <S.CenterContainer>
            <EmptyListResource
              text={__('BuyerSearchProducts.title_workspace_disabled', {
                name: sellerWorkspaceService.getCatalogName(workspace, contacts, me),
              })}
              text2={__('BuyerSearchProducts.workspace_disabled')}
              imageUrl={IMAGES.productsNoResult.replace('f_auto', 'c_scale,w_260')}
              showButton={true}
              buttonText={__('BuyerSearchProducts.cta_workspace_disabled')}
              buttonAction={() => buyerWorkspaceAssign(me.id, workspaceAssignedId)}
              buttonType="principal"
            />
          </S.CenterContainer>
        ) : hideList ? null : (
          <S.Row>
            <ProductFilter
              changeSearchState={s => this.setState({ searchState: s })}
              clearFilters={() => {
                EventTrack.track('workspace_products_filter_clear', {
                  workspace_id: workspace.id,
                });
                this.setState({
                  searchState: {
                    text: '',
                    language: me.settings.language as LOCALE,
                    index: '',
                    status: ['active', 'unavailable'],
                    indexes: searchState.indexes,
                    sort: searchState.sort,
                    sortOrder: searchState.sortOrder,
                  },
                });
              }}
              facets={facets}
              facetsGlobal={facetsGlobal}
              me={me}
              numberOfHeaders={3}
              searchState={searchState}
              onHide={h => this.setState({ hideFilters: h })}
              showClosed={hideFilters}
              showOver="only-ipad"
              hideStatus={true}
              suppliers={suppliers}
              contacts={contacts}
            />
            <S.Column>
              {this.renderProductsSubheader()}
              <S.Group onScroll={this.onScroll}>
                {isSearched || (suppliers && !suppliers?.length) ? (
                  <NonProviders hasHits={!!suppliers?.length} fromProducts={true} workspaceId={workspace?.id}>
                    <>
                      {totalResults === 0 ? (
                        <ZeroCase
                          title={__('Components.OrdersList.noProducts.title', { product: this.state.searchState.text })}
                          subtitle={__('Components.OrdersList.noProducts.text')}
                          link={IMAGES.ordersNoResult}
                          hasButton={true}
                          buttonText={__('Components.OrdersList.noProducts.cta')}
                          action={() =>
                            history.push(
                              getPath({
                                path: ROUTE_PATHS.WORKSPACE_SUPPLIERS,
                                workspaceId: workspace?.id + '',
                              }),
                            )
                          }
                        />
                      ) : listMode === 'card' ? (
                        <>
                          {totalResults ? (
                            <S.Stats>{__('Components.OrdersList.results', { count: totalResults || 0 })}</S.Stats>
                          ) : null}
                          <S.GridGroup>
                            {products.map(p => {
                              const supplier = suppliers.find(s => s.workspaceId === p.catalogId);
                              const key = productService.getKey(supplier.userId, me.id);
                              const catalogKey = productService.getKey(p.catalogId, me.id);
                              const cartItem = carts[key]?.items.find(c => c.childId === p.id);
                              return (
                                <S.ProductCardWrap key={'_' + p.id}>
                                  <S.FlexRow>
                                    <S.ClientImagesRow>
                                      <LettersAvatar
                                        size={32}
                                        text={supplier.name}
                                        img={supplier.companyLogo}
                                        avatarColor={utils.getAvatarColor(supplier.name)}
                                      />
                                    </S.ClientImagesRow>
                                    <S.ClientNameColumn>
                                      <S.TextBlackBold>{supplier.companyName}</S.TextBlackBold>
                                      <S.TextBlack> {supplier.name}</S.TextBlack>
                                    </S.ClientNameColumn>
                                  </S.FlexRow>
                                  <ProductCard
                                    amount={cartItem?.amount || 0}
                                    amSeller={false}
                                    onSelectItem={(i: IOrderItem) => this.cartUpdateItem(i)}
                                    data={parsers.productToGenericProduct(p)}
                                    featuredToggle={this.featuredToggle}
                                    hideFeatured={false}
                                    isFavorite={featured.favorite[catalogKey]?.includes(p.hashId)}
                                    isRecent={featured.recent[catalogKey]?.includes(p.hashId)}
                                    isRecommended={featured.recommended[catalogKey]?.includes(p.hashId)}
                                    isSelected={false}
                                    isServedFlow={false}
                                    navigate={() => this.navigateToProduct(p)}
                                    orderItemId={cartItem?.id || 0}
                                    price={cartItem?.price || p.price}
                                    priceMode={prices?.[p.id]?.priceMode || 'none'}
                                    pricePrecision={constants.PRICE_PRECISION}
                                    saleUnit={cartItem?.saleUnit || p.saleUnits[0]}
                                    servedQuantity={cartItem?.servedQuantity || 0}
                                    showShare={true}
                                  />
                                </S.ProductCardWrap>
                              );
                            })}
                          </S.GridGroup>
                        </>
                      ) : (
                        <ProductTable
                          carts={carts}
                          disabled={true}
                          cartUpdateItem={this.cartUpdateItem}
                          catalog={workspace}
                          clickProduct={this.navigateToProductFromId}
                          configId="product_list_buyer"
                          me={me}
                          onScroll={this.onScroll}
                          precision={constants.PRICE_PRECISION}
                          products={products}
                          role={sellerWorkspaceService.getRole(workspace, me.id)}
                          searchState={searchState}
                          showCustomColumns={true}
                          suppliers={suppliers}
                          totalResults={totalResults}
                          touchImage={touchImage}
                          updateSortMode={this.updateSortMode}
                        />
                      )}
                    </>
                  </NonProviders>
                ) : null}
              </S.Group>
            </S.Column>
          </S.Row>
        )}
        {addProductModalProps ? this.renderProductInfoPage() : null}
      </Workspace>
    );
  }
  private updateSortMode = (sortMode: string) => {
    const { workspace } = this.props;
    this.setState({ sortMode });
    EventTrack.track('workspace_products_buyer_sort', { workspace_id: workspace.id, sort: sortMode });
  };

  /**
   * Render product info page.
   */
  private renderProductInfoPage() {
    const { addProductModalProps, suppliers } = this.state;
    if (!addProductModalProps) return null;
    const { cartUpdateItem, countries, contacts, featured, history, me, modalClose, modalOpen, prodTypes, touchImage } =
      this.props;
    const { item, priceMode, amSeller, deletable, product } = addProductModalProps!;
    const supplier = suppliers.find(s => s.workspaceId === item.catalogId);
    const key = productService.getKey(supplier.userId, me.id);
    return (
      <S.Modal>
        <ProductInfo
          cartUpdateItem={(contactId: number, i: IOrderItem) => cartUpdateItem(key, contactId, i, false, priceMode)}
          contactId={supplier.userId}
          contactName={supplier.name}
          contacts={contacts}
          isContactUnregistered={false}
          catalogId={supplier.workspaceId}
          countries={countries}
          deletable={deletable}
          from="product"
          amSeller={amSeller}
          isFavorite={featured.favorite[productService.getKey(product.catalogId, me.id)]?.includes(product.hashId)}
          isRecent={featured.recent[productService.getKey(product.catalogId, me.id)]?.includes(product.hashId)}
          isRecommended={featured.recommended[productService.getKey(product.catalogId, me.id)]?.includes(
            product.hashId,
          )}
          item={item}
          history={history}
          close={() => this.setState({ addProductModalProps: undefined })}
          modalClose={modalClose}
          modalOpen={modalOpen}
          me={me}
          priceMode={priceMode}
          pricePrecision={constants.PRICE_PRECISION}
          prodTypes={prodTypes}
          product={parsers.productToGenericProduct(product)}
          touchImage={touchImage}
          showBack={false}
          showDefaultPicture={false}
          showFeatured={true}
          showShare={true}
        />
      </S.Modal>
    );
  }

  /**
   *  Render products management subheader
   */
  private renderProductsSubheader = () => {
    const { workspace } = this.props;
    const { hideFilters, listMode, searchState } = this.state;
    return (
      <S.SubheaderCustom>
        <S.SubheaderLeftSearch>
          <S.FilterButton
            filtersOpened={!hideFilters}
            filtersSelected={this.getFiltersCount()}
            onClick={() => this.setState({ hideFilters: !hideFilters })}
          />
          <SimpleSearch
            id="search-products"
            query={searchState.text}
            placeHolder={__('Components.ProductsList.search.placeholder')}
            onChange={(t: string) => this.setState({ searchState: { ...searchState, text: t } })}
          />
        </S.SubheaderLeftSearch>
        <S.SubheaderRight>
          <FilterSwitchIcon
            options={getListModeOptions()}
            keySelected={listMode}
            onSelectOption={(key: string) => {
              this.setState({ listMode: key as 'card' | 'list' });
              window.localStorage.setItem('products_buyer_display', key);
              EventTrack.track('products_change_display', {
                workspace_id: workspace.id,
                display: key,
                type: 'buyer-search',
              });
            }}
          />
        </S.SubheaderRight>
      </S.SubheaderCustom>
    );
  };

  private getTitle = (product?: IProduct) => {
    const { prodTypes } = this.props;
    if (!product) return __('Components.ProductsList.Tabs.products_buyer');
    return product?.id
      ? product.type.type !== '-'
        ? prodTypes[product.type.type].name
        : ''
      : __('Components.ProductDetails.create');
  };

  private getSubTitle = (product: IProduct) => {
    return !product?.id ? '' : product.type.variety;
  };

  /**
   * Get product's filters count
   */
  private getFiltersCount = () => {
    const { searchState } = this.state;
    const { brand, category, organic, origin, size, type, sections, catalogId, packaging, variety } = searchState;
    return (
      (origin?.length || 0) +
      (catalogId?.length || 0) +
      (organic?.length || 0) +
      (brand?.length || 0) +
      (type?.length || 0) +
      (size?.length || 0) +
      (sections?.length || 0) +
      (category?.length || 0) +
      (variety?.length || 0) +
      (packaging?.length || 0)
    );
  };

  /**
   * Receive data for search with elastic
   */
  private onReceiveProducts = (res: ISearchData<IProduct>) => {
    if (!res) return;
    const { data, toReplace, facets, facetsGlobal } = res;
    const { me, workspace } = this.props;
    const { prices, searchState } = this.state;
    const filters = productService.getTrackedFilters(searchState);
    if (filters.length) {
      EventTrack.track('workspace_products_filter', {
        workspace_id: workspace.id,
        results: data.totalResults,
        filters,
      });
    }
    if (data.hits?.length) {
      api.buyerWorkspace
        .pricesSearchGet(
          me.id,
          data.hits.map(p => p.id),
        )
        .then(p => {
          const newPrices = toReplace ? p : { ...prices, ...p };
          this.setState({ prices: newPrices });
          const products = data.hits.map(product => ({ ...product, ...(newPrices?.[product.id] || {}) }));
          if (toReplace) {
            this.setState({
              products,
              searchId: data.searchId,
              totalResults: data.totalResults,
              hasMore: data.hasMore!,
              facets,
              facetsGlobal,
            });
          } else {
            this.setState({
              products: [...this.state.products, ...products],
              searchId: data.searchId,
              totalResults: data.totalResults,
              hasMore: data.hasMore!,
            });
          }
        });
    }
  };

  /**
   * handle the scroll event for sticky the searchbar
   */
  private onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { hasMore } = this.state;
    if (hasMore && e.currentTarget.scrollTop + e.currentTarget.offsetHeight > e.currentTarget.scrollHeight - 180) {
      this.scrollSearch();
    }
  };

  /**
   * handle update cart item
   */
  private cartUpdateItem = (item: IOrderItem) => {
    const { cartUpdateItem, me, address } = this.props;
    const { suppliers, prices } = this.state;
    EventTrack.cartAddItem({
      quantity: item.amount,
      unit: item.saleUnit,
      price: item.price,
      sku: item.sku,
      eanCode: item.eanCode,
      productId: item.childId,
      productType: item.type,
      role: 'buyer',
    });
    const contactId = suppliers.find(s => s.workspaceId === item.catalogId)?.userId;
    const key = productService.getKey(contactId, me.id!);

    cartUpdateItem(key, contactId, item, false, prices?.[item.childId]?.priceMode || 'none', address);
  };

  private navigateToProductFromId = (productId: number) => {
    const { products } = this.state;
    const product = products.find(p => p.id === productId);
    if (product) {
      this.navigateToProduct(product);
    }
  };

  /**
   * handle click in product card
   */
  private navigateToProduct = (product: IProduct) => {
    const { me, history } = this.props;
    history.push(
      getPath({
        path: ROUTE_PATHS.WORKSPACE_PRODUCTS_BUYER_PRODUCT,
        workspaceId: me.buyerWorkspaceId.toString(),
        productId: product.id.toString(),
        supplierId: product.catalogId.toString(),
      }),
    );
  };

  /**
   * handle toggle featured product
   */
  private featuredToggle = (type: IFeaturedType, productHashId: string, catalogId: number) => {
    const { featuredToggle, me } = this.props;
    featuredToggle(type, catalogId, me.id, me.id, productHashId);
  };
}

export default WorkspaceProducts;

import { constants, __, utils, orderUtils } from 'common-services';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE_PATHS } from '../../../../../constants';
import getPath from '../../../../../util/routes';
import { Ribbon } from '../../../../atoms';
import { useOrderContext } from '../../context';
import { CommentSectionType } from '../../hooks/useOrderComments';

import { CommentsRibbon } from './CommentsRibbon';
import { useRibbonStates } from './hooks';
import { IssuesRibbon } from './IssuesRibbon';
import * as S from './OrderRibbons.styled';
import { PricingIssuesRibbon } from './PricingIssuesRibbon';
import { UpdatesRibbon } from './UpdatesRibbon';

export const OrderRibbons: React.FC = React.memo(() => {
  const {
    me,
    order,
    catalog,
    weAreSeller,
    prodTypes,
    unreadsCount,
    commentsRibbon,
    updatesRibbon,
    isCatalogInactive,
    itemsWithEmptyPrices: pricesEmpty,
    supportAction: onSupportAction,
    relaunchOrderExport: onRelaunchOrderExport,
    commentsScroll,
  } = useOrderContext();

  const { navigateToSection } = commentsScroll;

  const history = useHistory();

  // Get computed ribbon states
  const { hasPendingIssues, showExportErrorRibbon, showSendToERPRibbon, exportErrorSelected } = useRibbonStates(
    order,
    order?.issues,
    catalog,
    weAreSeller,
  );

  // Render functions
  const renderExportErrorRibbon = (): JSX.Element => {
    const canRelaunch = constants.exportErrorsRelaunchList.includes(exportErrorSelected);

    return (
      <Ribbon
        type="warning"
        text={
          exportErrorSelected === 'unknown'
            ? constants.getExportErrorText(exportErrorSelected)
            : canRelaunch
            ? __('Components.OrderDetails.ribbon_relaunch', {
                error_code: constants.getExportErrorText(exportErrorSelected),
                solution_code: constants.getExportErrorSolution(exportErrorSelected),
              })
            : __('Components.OrderDetails.ribbon_no_relaunch', {
                code: constants.getExportErrorText(exportErrorSelected),
              })
        }
        actionText={
          exportErrorSelected === 'unknown'
            ? constants.getExportErrorSolution(exportErrorSelected)
            : canRelaunch
            ? __('Components.OrderDetails.ribbon_action')
            : null
        }
        onClickAction={() =>
          exportErrorSelected === 'unknown' ? onSupportAction?.() : onRelaunchOrderExport?.(me.id, order?.id)
        }
      />
    );
  };

  const renderSendToERPRibbon = (): JSX.Element => (
    <Ribbon
      type="info"
      text={
        <S.Text>
          {
            utils.formatText(__('Components.Cart.errors.send_manual_erp'), (text, i) => (
              <S.TextLinkInline
                onClick={() =>
                  history.push(
                    getPath({
                      path: ROUTE_PATHS.WORKSPACE_SETTINGS_SELLER,
                      workspaceId: catalog.id + '',
                      tab: 'order',
                    }),
                  )
                }
                key={text + i}
              >
                {text}
              </S.TextLinkInline>
            )) as any // eslint-disable-line @typescript-eslint/no-explicit-any
          }
        </S.Text>
      }
    />
  );

  const renderInactiveCatalogRibbon = (): JSX.Element => (
    <Ribbon
      type="warning"
      text={
        weAreSeller
          ? __('Components.Cart.inactive_catalog_seller', {
              catalog: catalog?.name,
            })
          : __('Components.Cart.inactive_catalog_buyer')
      }
    />
  );

  return (
    <S.Container data-testid="order-ribbons">
      {showExportErrorRibbon && renderExportErrorRibbon()}

      {hasPendingIssues && !orderUtils.isCanceled(order) && (
        <IssuesRibbon
          issues={order?.issues}
          items={order?.items}
          prodTypes={prodTypes}
          pricesEmpty={pricesEmpty}
          catalog={catalog}
          weAreSeller={weAreSeller}
        />
      )}

      {/* Pricing Issues Ribbon */}
      <PricingIssuesRibbon />

      {showSendToERPRibbon && renderSendToERPRibbon()}

      {unreadsCount.unread_updates > 0 && (
        <UpdatesRibbon
          count={unreadsCount.unread_updates}
          message={updatesRibbon}
          onNavigateToComments={navigateToSection(CommentSectionType.Activity)} // eslint-disable-line @typescript-eslint/no-misused-promises
        />
      )}

      {unreadsCount.unread_comments > 0 && (
        <CommentsRibbon
          count={unreadsCount.unread_comments}
          message={commentsRibbon}
          onNavigateToComments={navigateToSection(CommentSectionType.Messages)} // eslint-disable-line @typescript-eslint/no-misused-promises
        />
      )}

      {isCatalogInactive && renderInactiveCatalogRibbon()}
    </S.Container>
  );
});

OrderRibbons.displayName = 'OrderRibbons';

export default OrderRibbons;

import { __, utils } from 'common-services';
import * as React from 'react';

import { IMAGES } from '../../../assets';
import ClientSelectable from '../../atoms/ClientSelectable';
import EmptyListResource from '../EmptyListResource';

import * as S from './ClientsList.styled';

import type { IClient } from 'common-services';

interface IProps {
  action?: 'none' | 'remove' | 'switch' | 'radio';
  className?: string;
  clients?: Array<IClient>;
  clientSelected?: (cId: number) => boolean;
  onSelectClient?: (cId: number, isSelected: boolean) => void;
  search?: string;
}

const ClientsList: React.FC<IProps> = ({ action, className, clients, clientSelected, onSelectClient, search }) => {
  return (
    <S.Contacts className={className}>
      <ListClients
        action={action}
        clients={clients}
        clientSelected={clientSelected}
        onSelectClient={onSelectClient}
        f={search}
      />
    </S.Contacts>
  );
};

const ListClients: React.FC<{
  action?: 'none' | 'remove' | 'switch' | 'radio';
  clients: Array<IClient>;
  clientSelected: (cId: number) => boolean;
  f?: string;
  onSelectClient?: (cId: number, isSelected: boolean) => void;
}> = ({ action, clients, clientSelected, f, onSelectClient }) => {
  const contactsFiltered = clients?.filter(person => {
    return (
      person?.name &&
      !(
        f &&
        !utils.toLocaleLowerCaseNormalized(person.name).includes(f) &&
        !utils.toLocaleLowerCaseNormalized(person.company).includes(f)
      )
    );
  });
  if (contactsFiltered === undefined || contactsFiltered.length < 1)
    return <EmptySearch search={f} className="empty-search-clients" />;

  return (
    <>
      {contactsFiltered
        .sort((a, b) => {
          const aName = utils.toLocaleLowerCaseNormalized(a.name);
          const bName = utils.toLocaleLowerCaseNormalized(b.name);
          if (aName > bName) return 1;
          if (aName < bName) return -1;
          return 0;
        })
        .reduce((acc: Array<JSX.Element>, c: IClient, idx: number, arr: Array<IClient>) => {
          const initialLetter = utils.toLocaleLowerCaseNormalized(c.name).substring(0, 1).toUpperCase();
          const isSelected = clientSelected ? clientSelected(c.userId) : true;
          if (
            !idx ||
            utils
              .toLocaleLowerCaseNormalized(arr[idx - 1].name)
              .substring(0, 1)
              .toUpperCase() !== initialLetter
          ) {
            acc.push(<S.LetterRow key={`${initialLetter}-${c.userId}`}>{initialLetter}</S.LetterRow>);
          }
          acc.push(
            <ClientSelectable
              action={action}
              className="contacts-list-contact-selectable"
              client={c}
              isLast={idx === arr.length - 1}
              isSelected={isSelected}
              onSelectClient={() => onSelectClient(c.userId, isSelected)}
              key={c.userId + '_' + c.name + '_' + c.company}
            />,
          );

          return acc;
        }, [])}
    </>
  );
};

const EmptySearch = ({ className, search }: { className?: string; search: string }): JSX.Element => {
  return (
    <S.CenterContainer className={className}>
      <EmptyListResource
        text={__('Components.ProductsList.QueryEmptyResults', { search })}
        text2={__('Components.ProductsList.EmptyResultsTryAgain')}
        showButton={false}
        imageUrl={IMAGES.productsNoResult.replace('f_auto', 'c_scale,w_260')}
      />
    </S.CenterContainer>
  );
};

export default React.memo(ClientsList);

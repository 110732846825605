import styled from '../../../../../../styled-components';

export const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 100;
`;
//   z-index: ${props => props.theme.zIndex.modal};

export const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
`;
//   z-index: ${props => props.theme.zIndex.modalOverlay};

// Optional: Additional styled components for enhanced UI
export const ErrorBoundary = styled.div`
  padding: ${props => props.theme.paddingSize(4)};
  text-align: center;
  color: ${props => props.theme.colors.red1};
`;

export const Header = styled.div`
  padding: ${props => props.theme.paddingSize(3)};
  border-bottom: 1px solid ${props => props.theme.colors.grey3};
`;

export const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: ${props => props.theme.paddingSize(3)};
`;

export const Footer = styled.div`
  padding: ${props => props.theme.paddingSize(3)};
  border-top: 1px solid ${props => props.theme.colors.grey3};
  display: flex;
  justify-content: flex-end;
  gap: ${props => props.theme.paddingSize(2)};
`;

import { __, orderService, orderUtils } from 'common-services';
import * as React from 'react';

import config from '../../../../../../../../../bindings/config';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'load',
  isVisible: true,
  getTitle: (): string => __('Components.ProductsList.Table.Load'),
  minWidth: '120px',
  width: '10%',
  columnValue: ({ order }) => ({
    getElement: item => {
      const issuesRelated = order?.issues.filter(issue => issue.orderItemId === item.id);
      const hasNotFoundIssue = !!issuesRelated?.find(i =>
        ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
      );
      const isDiscarded = item.servedQuantity === 0;
      return (
        <S.SubText showGrey={hasNotFoundIssue || isDiscarded || orderUtils.isCanceled(order)}>
          {item
            ? config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId)
              ? orderService.getCartLoadSummaryAltUnits(order?.items)
              : orderService.getCartLoadSummaryText(order?.items)
            : ''}
        </S.SubText>
      );
    },
  }),
};

import styled from '../../../../../../../styled-components';
import { ColumnContainer, RowContainer } from '../../../../../../atoms';

import type { ITheme } from '../../../../../../../styled-components';

interface IStyledProps {
  numHeaders?: number;
  theme: ITheme;
  selected?: boolean;
  isDragActive?: boolean;
  hasText?: boolean;
}

export const Container = styled(ColumnContainer)`
  max-width: 800px;
  width: 100%;
  align-self: flex-start;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
  min-height: ${(p: IStyledProps) =>
    `calc(${p.theme.contentHeightWithBreadcrumb} - ${p.numHeaders} * ${p.theme.headerHeight})`};
`;

export const MessagesContainer = styled(ColumnContainer)`
  width: 100%;
`;

export const MoreWrapper = styled.div`
  width: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  justify-content: center;
  display: flex;
`;

export const Link = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.green1};
  cursor: pointer;
  text-decoration: underline;
`;

export const NavigationTabsContainer = styled(RowContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

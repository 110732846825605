import { __ } from 'common-services';
import * as React from 'react';

import config from '../../../../../../../bindings/config';
import { Input, Select } from '../../../../../atoms';
import DebugModal from '../../../../../atoms/Debug/DebugModal.component';
import { ActionsModal } from '../../../../../molecules';
import { useOrderContext } from '../../../context';

import * as S from './IssuesModal.styled';

import type { IOrderItem } from 'common-services';

export const IssuesModal: React.FC = () => {
  const {
    order,
    catalog,
    mbppDropdownOptions: dropdownOptions,
    handleItemUpdate: onConfirm,
    selectedItem,
    setSelectedItem,
    selectedIssue,
    productAddAttributeValue,
    setShowIssuesModal,
    notificationShow,
  } = useOrderContext();

  const onClose = (): void => {
    setShowIssuesModal(false);
    setSelectedItem(undefined);
  };

  // Local state
  const [showNewMbppValueInput, setShowNewMbppValueInput] = React.useState(false);

  // Get input title based on issue type
  const getInputTitle = (): string => {
    switch (selectedIssue?.type) {
      case 'no-box-weight':
        return __('Components.OrderDetails.issues_modal.weight_per_box');
      case 'no-pieces-per-box':
        return __('Components.OrderDetails.issues_modal.units_per_box');
      default:
        return __('Components.OrderDetails.issues_modal.boxes_per_pallet');
    }
  };

  // Handle value change
  const handleValueChange = (value: string | number): void => {
    const numValue = Number(value);
    const updates: Partial<IOrderItem> = {};

    switch (selectedIssue?.type) {
      case 'no-box-weight':
        updates.boxWeight = numValue;
        break;
      case 'no-pieces-per-box':
        updates.piecesPerBox = numValue;
        break;
      default:
        updates.boxesPerPallet = numValue;
        break;
    }

    setSelectedItem({
      ...selectedItem,
      ...updates,
    });
  };

  // Handle dropdown change
  const handleDropdownChange = (_: string, value: string): void => {
    // TODO:1 Check if this is the correct type for the first argument
    if (value === 'add_option') {
      setShowNewMbppValueInput(true);
    } else {
      setSelectedItem({
        ...selectedItem,
        boxesPerPallet: Number(value),
      });
    }
  };
  // Handle confirm
  const handleConfirm = (): void => {
    onConfirm(selectedItem, selectedIssue, true);

    if (selectedIssue?.type === 'multi-boxes-per-pallet-without-buyer') {
      try {
        productAddAttributeValue?.(
          selectedItem.childId,
          'boxes-per-pallet',
          {
            value: selectedItem.boxesPerPallet,
            client_id: order?.buyerId,
          },
          (res, err) => {
            if (err) {
              notificationShow?.({
                title: __('Components.OrderDetails.notification.error'),
                subtitle: __('Components.OrderDetails.notification.error_description'),
                closable: true,
                style: 'error',
              });
            }
          },
        );
      } catch (error) {
        console.error('Error updating product attribute:', error);
      }
    }

    onClose();
  };

  // Show boxes per pallet dropdown
  const showBppDropdown =
    config.TOGGLE_BOXES_PER_PALLET.enabled &&
    catalog?.mandatoryPalletizationByClient &&
    selectedIssue?.type === 'multi-boxes-per-pallet-without-buyer' &&
    !showNewMbppValueInput;

  return (
    <ActionsModal
      minHeight="300px"
      width="500px"
      onClose={onClose}
      title={__('Components.OrderDetails.issues_modal.title', {
        product: selectedItem.title,
      })}
      subtitle={__('Components.OrderDetails.issues_modal.text')}
      overFlowVisible={true}
    >
      <S.InputTitle>{getInputTitle()}</S.InputTitle>
      {showBppDropdown ? (
        <Select
          name="productCode"
          value={selectedItem?.boxesPerPallet?.toString() || ''}
          options={dropdownOptions || []}
          onChange={handleDropdownChange}
          containerMargin="4px 24px 4px 0"
          width="100%"
        />
      ) : (
        <Input
          name="issue"
          onChange={(_, value) => handleValueChange(value)} // TODO:1 Check if this is the correct type for the first argument
          type="number"
          width="100%"
          variableTextPlural={
            selectedIssue?.type === 'no-box-weight'
              ? __('Components.OrderDetails.issues_modal.kg')
              : selectedIssue?.type === 'no-pieces-per-box'
              ? __('Components.OrderDetails.issues_modal.unit')
              : __('Components.OrderDetails.issues_modal.box')
          }
        />
      )}

      <S.ModalRow>
        <S.ModalButton type="secondary" onClick={onClose}>
          {__('Components.OrderDetails.issues_modal.cancel')}
        </S.ModalButton>
        {}
        <S.CtaButton disabled={false} onClick={handleConfirm}>
          {__('Components.OrderDetails.issues_modal.cta')}
        </S.CtaButton>
      </S.ModalRow>
    </ActionsModal>
  );
};

export default React.memo(IssuesModal);

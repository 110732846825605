import { __, constants, currency, PRODUCT_UNIT } from 'common-services';
import * as React from 'react';
import { useSelector } from 'react-redux';

import config from '../../../../../../bindings/config';
import { orderDetailBuyerColumns, orderDetailColumns } from '../../../../../constants';
import { calculatePriceServedUnit } from '../../../../../domain/order';
import { navSelectors } from '../../../../../selectors';
import { unitTranslator } from '../../../../../util/unit';

import * as S from './ItemsCard.styled';

import type { IColumn } from '../../../../molecules/Table/Table.component';
import type { CartData, CartItem } from '../../OrderPreparationCarts.types';
import type { IOrderItem, IColumnConfig, IOrderIssue } from 'common-services';

const ItemsCard: React.FC<{
  cartUpdateItem: (item: CartItem) => void;
  cart: CartData;
  showCustomColumns?: boolean;
  weAreSeller?: boolean;
}> = ({ cartUpdateItem, cart, showCustomColumns, weAreSeller }) => {
  const workspaceSelected = useSelector(navSelectors.getSelectedWorkspace);
  const pricePrecision = weAreSeller
    ? workspaceSelected
      ? workspaceSelected?.numberOfDecimalsShowed
      : constants.PRICE_PRECISION
    : constants.PRICE_PRECISION; // if we are buyer, the workspaceSelected is the buyer workspace, which does not have numberOfDecimalsShowed in its settings. Setting default value for now.

  const isQuoterMode =
    (config.TOGGLE_MARGINS.enabled && weAreSeller && workspaceSelected?.plan?.addons?.quoterMarginsEnabled) || false;

  const columns = getColumns().filter(c => c);
  return (
    <S.ItemsTable
      isReadRow={() => false}
      values={cart.items}
      onClickRow={() => null}
      emptyText=""
      columns={columns}
      selectable={false}
      fixedColumns={[
        'product',
        'code',
        'boxes-per-pallet',
        'quantity',
        'starting-price',
        'delivered-price',
        'total-price',
      ]}
      showCustomColumns={showCustomColumns}
      configId={weAreSeller ? 'order_detail' : 'order_detail_buyer'}
      productColumns={weAreSeller ? orderDetailColumns(isQuoterMode) : orderDetailBuyerColumns(isQuoterMode)}
    />
  );

  function getColumns(): Array<IColumn> {
    const result = [
      //  X (delete)
      getColumnField(
        { name: 'delete', order: -1, visible: true },
        undefined,
        undefined,
        cartUpdateItem
      ),

      getColumnField(
        { name: 'product', order: 0, visible: true },
        {
          getValue: (data: CartItem) => data.title || '-',
        },
      ),

      getColumnField(
        { name: 'code', order: 1, visible: true },
        {
          getValue: (data: CartItem) => data.code || '-',
        },
      ),

      getColumnField(
        { name: 'boxes-per-pallet', order: 2, visible: true },
        {
          getValue: (data: CartItem) => `${data.boxesPerPallet}`,
        },
      ),

      getColumnField(
        { name: 'quantity', order: 3, visible: true },
        {
          getElement: (data: CartItem) => (
            <S.AmountRow onClick={e => e.stopPropagation()}>
              <S.QuantityRow>
                <S.AmountInput
                  id={`amount-${data.id}`}
                  name="amount"
                  onChange={(key: string, value: string) => {
                    const v = Number(value);
                    cartUpdateItem({
                      ...data,
                      amount: v,
                      servedQuantity: v,
                    });
                  }}
                  value={data.servedQuantity || 0}
                  minValue={0}
                  width="120px"
                  type="number"
                  textAlign="right"
                  hasError={false}
                  containerMargin="0 6px 0 0"
                  variableTextSingular={unitTranslator(data.offeredQuantityUnit, data.servedQuantity)}
                />
              </S.QuantityRow>
            </S.AmountRow>
          ),
        },
        undefined,
        cartUpdateItem,
      ),

      getColumnField(
        { name: 'starting-price', order: 4, visible: true },
        {
          getValue: (data: CartItem) =>
            data.offeredDeparturePrice ? `${data.offeredDeparturePrice}€/${data.priceUnit}` : '-',
        },
      ),

      getColumnField(
        { name: 'delivered-price', order: 5, visible: true },
        {
          getValue: (data: CartItem) => (data.offeredPrice ? `${data.offeredPrice}€/${data.priceUnit}` : '-'),
        },
      ),

      getColumnField(
        { name: 'total-price', order: 6, visible: true },
        {
          getValue: (data: CartItem) =>
            currency.getPrice(
              data.currency,
              calculatePriceServedUnit(data as unknown as IOrderItem, data.boxesPerPallet), // TODO: fix this
              pricePrecision,
            ),
        },
        pricePrecision,
      ),
    ];

    return result;
  }
};

const calculateTotalPrice = (item, boxesPerPallet = 0): number => {
  const calculatedTotal = calculatePriceServedUnit(
    {
      ...item,
      servedSaleUnit: item.saleUnit || PRODUCT_UNIT.PIECE,
      priceUnit: item.priceUnit || PRODUCT_UNIT.PIECE,
      servedPrice: item.offeredPrice || item.offeredDeparturePrice,
      servedQuantity: item.servedQuantity,
      boxWeight: item.boxWeight || 0,
      piecesPerBox: item.piecesPerBox || 0,
    },
    boxesPerPallet || item.boxesPerPallet || 0,
  );

  return calculatedTotal;
};

/**
 * Get column according to a custom config
 */
function getColumnField(
  columnConfig: IColumnConfig,
  options?: {
    getValue?: (data: CartItem) => string | number;
    getElement?: (data: CartItem) => React.ReactElement;
  },
  pricePrecision?: number,
  cartUpdateItem?: (item: CartItem, issue?: IOrderIssue) => void,
): IColumn {
  switch (columnConfig.name) {
    case 'delete':
      return {
        id: 'delete',
        title: '',
        element: (data: CartItem) => (
          <S.IconWrapper>
            <S.RemoveIcon
              name="Close"
              onClick={e => {
                e.stopPropagation();
                if (cartUpdateItem) {
                  const updatedItem = {
                    ...data,
                    servedQuantity: 0,
                    amount: 0,
                    totalPrice: 0
                  };
                  cartUpdateItem(updatedItem);
                }
              }}
            />
          </S.IconWrapper>
        ),
        minWidth: '30px',
        width: '30px'
      };
      
    case 'product':
      return {
        id: 'product',
        title: __('Components.OrderPreparation.Table.Columns.reference'),
        value: (data: CartItem) => data.title || '-',
        minWidth: '200px',
        width: '25%',
      };

    case 'code':
      return {
        id: 'code',
        title: __('Components.OrderPreparation.Table.Columns.code'),
        value: (data: CartItem) => data.code || '-',
        minWidth: '100px',
        width: '10%',
      };

    case 'boxes-per-pallet':
      return {
        id: 'boxes-per-pallet',
        title: __('Components.OrderPreparation.Table.Columns.boxes_per_pallet'),
        value: (data: CartItem) => data.boxesPerPallet || '-',
        minWidth: '120px',
        width: '12%',
      };



    case 'quantity':
      return {
        id: 'quantity',
        title: __('Components.OrderPreparation.Table.Columns.quantity'),
        element: (data: CartItem) => (
          <S.AmountRow onClick={e => e.stopPropagation()}>
            <S.QuantityRow>
              <S.AmountInput
                id={`amount-${data.id}`}
                name="amount"
                onBlur={(key: string, value: string) => {
                  const v = Number(value);
                  if (cartUpdateItem) {
                    const updatedItem = {
                      ...data,
                      amount: v,
                      servedQuantity: v,
                      totalPrice: calculatePriceServedUnit(
                        {
                          ...data,
                          servedQuantity: v,
                        } as unknown as IOrderItem, // TODO: fix this
                        data.boxesPerPallet,
                      ),
                    };
                    cartUpdateItem(updatedItem);
                  }
                }}
                value={data.servedQuantity || 0}
                minValue={0}
                width="120px"
                type="number"
                textAlign="right"
                hasError={false}
                containerMargin="0 6px 0 0"
                variableTextSingular={unitTranslator(data.offeredQuantityUnit, data.servedQuantity)}
              />
            </S.QuantityRow>
          </S.AmountRow>
        ),
        minWidth: '120px',
        width: '15%',
      };

    case 'starting-price':
      return {
        id: 'starting-price',
        title: __('Components.OrderPreparation.Table.Columns.starting_price'),
        value: (data: CartItem) =>
          data.offeredDeparturePrice
            ? `${data.offeredDeparturePrice}€/${unitTranslator(data.priceUnit, data.servedQuantity)}`
            : '-',
        minWidth: '100px',
        width: '12%',
      };

    case 'delivered-price':
      return {
        id: 'delivered-price',
        title: __('Components.OrderPreparation.Table.Columns.delivered_price'),
        value: (data: CartItem) =>
          data.offeredPrice ? `${data.offeredPrice}€/${unitTranslator(data.priceUnit, data.servedQuantity)}` : '-',
        minWidth: '100px',
        width: '12%',
      };

    case 'total-price':
      return {
        id: 'total-price',
        title: __('Components.OrderPreparation.Table.Columns.total_price'),
        value: (data: CartItem) => {
          const total = calculateTotalPrice(
            {
              ...data,
              servedQuantity: data.servedQuantity || 0,
            },
            data.boxesPerPallet,
          );

          return currency.getPrice(data.currency, total, pricePrecision);
        },
        minWidth: '100px',
        width: '12%',
      };
    default:
      return undefined;
  }
}
export default ItemsCard;

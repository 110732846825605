import { ORDER_ORIGIN, PRODUCT_UNIT, __, orderUtils, parsers } from 'common-services';
import * as React from 'react';

import config from '../../../../../../../../../bindings/config';
import { priceUnitTranslate } from '../../../../../../../../util/utils';
import { Select } from '../../../../../../../atoms';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';



export const column: OrderItemColumnSpec = {
  id: 'ordered-quantity',
  rank: 1000,
  isVisible: true,
  getTitle: (): string => __('Components.Cart.items.ordered_quantity'),
  minWidth: '70px',
  width: '8%',
  condition: ({ showServedColumn }) => showServedColumn,
  columnValue: ({ order, amEditor, isPendingOrder, canServeOrder, editAfterAccept, onUpdateItem }) => ({
    getElement: item => {
      const issuesRelated = order?.issues.filter(issue => issue.orderItemId === item.id);
      const invalidAmountIssue = issuesRelated?.find(i => i.type === 'amount-not-valid');
      const editable = amEditor && (isPendingOrder || (canServeOrder && editAfterAccept));
      // const hasPendingIssue = issuesRelated.find(issue => issue.status === 'pending');

      const hasNotFoundIssue = !!issuesRelated?.find(i =>
        ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
      );

      const isDiscarded = item.servedQuantity === 0;
      return (
        <S.AmountRow onClick={e => e.stopPropagation()}>
          <S.QuantityRow>
            {invalidAmountIssue ? <S.WarningIcon name="Warning" disableHover={true} /> : null}
            {(editable && !editAfterAccept) ||
            (!item.amount && (order?.origin === ORDER_ORIGIN.IMPORT_UI || order?.origin === ORDER_ORIGIN.EMAIL)) ? (
              <>
                <S.AmountInput
                  id={`amount-${item.id}`}
                  name="amount"
                  onBlur={(key: string, value: string) => {
                    const v = Number(value);
                    onUpdateItem(
                      { ...item, amount: v, servedQuantity: v },
                      invalidAmountIssue?.type === 'amount-not-valid' ? invalidAmountIssue : undefined,
                    );
                  }}
                  value={item.servedQuantity || 0}
                  minValue={0}
                  precision={item.saleUnit === PRODUCT_UNIT.KG ? 2 : 0}
                  width="80px"
                  type="number"
                  textAlign="right"
                  // autoFocus={false}
                  hasError={!item.servedQuantity}
                  containerMargin="0 6px 0 0"
                  isIssue={!!invalidAmountIssue}
                />
                <Select
                  containerMargin="0"
                  value={item.servedSaleUnit}
                  name={`served-sale-unit-${item.id}`}
                  options={item.saleUnits.map(s => ({
                    value: s,
                    label: parsers.getUnitText(
                      config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId)
                        ? priceUnitTranslate(s)
                        : s,
                      item.weightUnit,
                      0,
                    ),
                  }))}
                  onChange={(n, v) => {
                    onUpdateItem({
                      ...item,
                      saleUnit: v as PRODUCT_UNIT,
                      servedSaleUnit: v as PRODUCT_UNIT,
                    });
                  }}
                  width="100px"
                />
              </>
            ) : (
              <>
                <S.Amount showGrey={hasNotFoundIssue || isDiscarded || orderUtils.isCanceled(order)}>
                  {item.amount}
                </S.Amount>
                <S.Amount showGrey={hasNotFoundIssue || isDiscarded || orderUtils.isCanceled(order)}>
                  {' '}
                  {parsers.getUnitText(
                    config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId)
                      ? priceUnitTranslate(item.saleUnit)
                      : item.saleUnit,
                    item.weightUnit,
                    item.amount,
                  )}
                </S.Amount>
              </>
            )}
          </S.QuantityRow>
        </S.AmountRow>
      );
    },
  }),
};

import styled from '../../../../../../styled-components';
import { Button, RowContainer, TextLabel } from '../../../../../atoms';

import type { ITheme } from '../../../../../../styled-components';

interface IStyledProps {
  theme: ITheme;
}

const Text = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  width: 100%;
`;

export const TextBold = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  display: inline;
`;

export const ButtonsContainer = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const List = styled.ul`
  border-left: 3px solid ${(p: IStyledProps) => p.theme.colors.orange1};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;
export const ItemList = styled.li`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  list-style: disc;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
`;
export const ModalButton = styled(Button)`
  width: 45%;
`;

import { ImageGalleryObject, IModalExtraInfo, IProFeature, ModalActions } from 'common-services';
import * as React from 'react';

import { AlertModal, ErrorModal, ImageGallery, InputModal, LoadingModal, NiceModal } from '../../molecules';
import ConsentioProModal from '../../molecules/ConsentioProModal';

export interface IProps {
  acceptAction?: () => void;
  acceptButtonText?: string;
  cancelAction?: () => void;
  cancelButtonText?: string;
  description?: string;
  errors?: Array<string>;
  extra: IModalExtraInfo;
  images: Array<ImageGalleryObject>;
  imageSelected: number;
  loadingTheme?: 'dark' | 'light';
  toShow: boolean;
  type: 'loading' | 'error' | 'generic' | 'image' | 'nice' | 'message' | 'consentiopro';
  closeOnClickOutside?: boolean;
}

interface DispatchProps {
  modalOpen: (text: string, action: any, extra?: IModalExtraInfo) => ModalActions;
  modalClose: () => ModalActions;
}

type IConnectedProps = IProps & DispatchProps;

const GlobalModal: React.FC<IConnectedProps> = ({
  acceptAction,
  acceptButtonText,
  cancelAction,
  cancelButtonText,
  description = '',
  errors = [],
  extra,
  images = [],
  imageSelected = 0,
  loadingTheme,
  modalClose,
  toShow = false,
  type,
  closeOnClickOutside,
}) => {
  if (!toShow) return null;
  if (type === 'loading') return <LoadingModal loadingTheme={loadingTheme} text={description} extra={extra} />;
  if (type === 'consentiopro')
    return (
      <ConsentioProModal
        section={description as IProFeature}
        handleRequestInfo={acceptAction}
        modalClose={modalClose}
        closeOnClickOutside={closeOnClickOutside}
      />
    );
  if (type === 'nice')
    return (
      <NiceModal
        text={description}
        modalAction={acceptAction || modalClose}
        modalClose={cancelAction || modalClose}
        extra={extra}
        isOpen={true}
        closeOnClickOutside={closeOnClickOutside}
      />
    );
  if (type === 'generic')
    return (
      <AlertModal
        text={description}
        acceptAction={acceptAction || modalClose}
        cancelAction={cancelAction || modalClose}
        acceptButtonText={acceptButtonText}
        cancelButtonText={cancelButtonText}
        closeModalAction={modalClose}
        extra={extra}
        closeOnClickOutside={closeOnClickOutside}
      />
    );
  if (type === 'error') return <ErrorModal errors={errors} closeOnClickOutside={closeOnClickOutside} />;
  if (type === 'image')
    return <ImageGallery images={images} isOpen={true} onClose={modalClose} initialImage={imageSelected} />;
  if (type === 'message')
    return (
      <InputModal
        text={description}
        acceptAction={acceptAction || modalClose}
        cancelAction={cancelAction || modalClose}
        acceptButtonText={acceptButtonText}
        cancelButtonText={cancelButtonText}
        closeModalAction={modalClose}
        showCancelAction={!!acceptAction}
        extra={extra}
        closeOnClickOutside={closeOnClickOutside}
      />
    );

  return null;
};

export default React.memo(GlobalModal);

import styled from '../../../../styled-components';
import { FontIcon, TextLabel } from '../../../atoms';

import type { ITheme } from '../../../../styled-components';

interface IStyledProps {
  enabled?: boolean;
  disabled?: boolean;
  margin?: string;
  numHeaders?: number;
  theme: ITheme;
}

export const IssueText = styled(TextLabel)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
  display: flex;
`;

export const IssueIcon = styled(FontIcon)`
  font-size: 9px;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const IssueCTA = styled(IssueText)`
  cursor: pointer;
`;

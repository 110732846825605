import { __, orderService } from 'common-services';
import * as React from 'react';

import type { SortItemFilter, IOrderItem, IWorkspace, IProdType } from 'common-services';

interface UseSortedItemsProps {
  items: Array<IOrderItem>;
  catalog?: IWorkspace;
  prodTypes: { [key: string]: IProdType };
}

interface UseSortedItemsReturn {
  sortItemsFilter: SortItemFilter;
  setSortItemsFilter: React.Dispatch<React.SetStateAction<SortItemFilter>>;
  sortOptions: Array<{ key: string; value: string }>;
  sortedItems: Array<IOrderItem>;
  selectedSortLabel: string;
}

export function useSortedItems({ items, catalog, prodTypes }: UseSortedItemsProps): UseSortedItemsReturn {
  const [sortItemsFilter, setSortItemsFilter] = React.useState<SortItemFilter>(catalog?.sortItemsBy);

  const sortOptions = React.useMemo(() => {
    const result = [
      { key: 'added', value: __('Components.OrderDetails.sort_products.default') },
      { key: 'alphabetic', value: __('Components.OrderDetails.sort_products.alphabetical') },
    ];

    const hasSku = items.some(item => item.sku);
    if (hasSku) {
      result.push({ key: 'sku', value: __('Components.OrderDetails.sort_products.sku') });
    }

    return result;
  }, [items]);

  const sortedItems = React.useMemo(() => {
    return [...items]
      .filter(c => c.amount || c.servedQuantity)
      .sort(orderService.sortOrderItems(prodTypes, sortItemsFilter));
  }, [items, sortItemsFilter, prodTypes]);

  const selectedSortLabel = React.useMemo(() => {
    switch (sortItemsFilter) {
      case 'alphabetic':
        return __('Components.OrderDetails.sort_products.alphabetical');
      case 'sku':
        return __('Components.OrderDetails.sort_products.sku');
      default:
        return __('Components.OrderDetails.sort_products.default');
    }
  }, [sortItemsFilter]);

  return {
    sortItemsFilter,
    setSortItemsFilter,
    sortOptions,
    sortedItems,
    selectedSortLabel,
  };
}

import { __ } from 'common-services';

import { TYPE_CELL } from '../../../../../../../molecules/Table/Table.component';

import type { IDataCell } from '../../../../../../../molecules/Table/Table.component';
import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';
import type { ISizeImage, IOrderItem } from 'common-services';

export const column: OrderItemColumnSpec = {
  id: 'images',
  isVisible: true,
  getTitle: () => __('Components.ProductsList.Table.Images'),
  minWidth: '70px',
  width: '9%',
  columnValue: ({ touchImage }) => ({
    getDataCell: (item: IOrderItem): IDataCell => {
      const emptyImages: Array<ISizeImage> = [];
      const images = item.images || emptyImages;
      return {
        type: TYPE_CELL.IMAGE,
        images: images.filter(i => i.image.url).map(img => img.image.url),
        onImageClick: index =>
          touchImage(
            images.map(img => ({ src: img.image.url, caption: img.image.comment })),
            index,
          ),
        count: images.filter(i => i.image.url).length,
      };
    },
  }),
};

import { __ } from 'common-services';
import * as React from 'react';

import { IMAGES } from '../../../../../assets';
import { EmptyListResource } from '../../../../molecules';
import OrderLogistics from '../../../OrderLogistics';
import { useOrderContext } from '../../context';
import { OrderHeader } from '../Header';

import { CommentsSection } from './CommentSection';
import { GeneralInfo } from './GeneralInfo/GeneralInfo.component';
import { ItemsList } from './Items/ItemsList.component';
import * as S from './OrderBody.styled';

const OrderBody: React.FC = React.memo(() => {
  const {
    order,
    contact,
    previewMode,
    match, // TODO:2 Remove this prop
    weAreSeller,
    showLogistic,
    setShowLogistic,
    hasItems,
    navShowroom: onNavigateToShowroom,
  } = useOrderContext();

  // State to control the visibility of the sidebar
  const [isVisible, setIsVisible] = React.useState(false);

  // Effect to handle the animation when showLogistic changes
  React.useEffect(() => {
    if (showLogistic) {
      // When opening, make visible immediately
      setIsVisible(true);
    }
  }, [showLogistic]);

  // Handle closing with animation
  const handleClose = React.useCallback(() => {
    // Start closing animation
    setIsVisible(false);

    // Wait for animation to complete before hiding
    setTimeout(() => {
      setShowLogistic(false);
    }, 300); // Match transition duration
  }, [setShowLogistic]);

  // Get custom items
  // const customItems = React.useMemo(
  //   () => cart.customItems?.filter(ci => ci.type === 'custom') || [],
  //   [cart.customItems],
  // );

  const renderEmptyState = React.useCallback(() => {
    return (
      <S.EmptyState>
        <EmptyListResource
          text={__('Components.OrderDetails.empty_cart.title')}
          showButton={true}
          buttonAction={onNavigateToShowroom}
          buttonText={__('Components.OrderDetails.empty_cart.cta')}
          imageUrl={IMAGES.emptyBox}
        />
      </S.EmptyState>
    );
  }, [onNavigateToShowroom]);

  return (
    <S.Container data-testid="order-body">
      <S.Row>
        <S.ScrollContainer id="order-body-scroll">
          <OrderHeader />
          <S.Content>
            <S.MainSection>
              <GeneralInfo />
              {hasItems ? <ItemsList /> : renderEmptyState()}
            </S.MainSection>

            {/* Comments Section */}
            {hasItems && !previewMode && <CommentsSection />}
          </S.Content>
        </S.ScrollContainer>

        {/* Logistics Panel */}
        {showLogistic && (
          <S.RightCard isVisible={isVisible}>
            <OrderLogistics
              match={match}
              orderId={order?.id}
              contact={contact}
              weAreSeller={weAreSeller}
              navChannelAction={() => handleClose()} // TODO:1 check this prop
              navOrderAction={() => handleClose()} // TODO:1 check this prop
            />
          </S.RightCard>
        )}
      </S.Row>
    </S.Container>
  );
});

OrderBody.displayName = 'OrderBody';

export default OrderBody;

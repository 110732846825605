import { __, ORDER_ORIGIN, userService } from 'common-services';
import { differenceInCalendarDays } from 'date-fns';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { Attachments } from '..//Attachments';
import { downloadFile as downloadFileFromData } from '../../../../../../../../services/file';
import { FileModal, Message } from '../../../../../../../molecules';
import * as S from '../Comments.styled';
import { UnreadMessage } from '../Unread';

import type * as userWebActions from '../../../../../../../../actions/user';
import type {
  ORDER_FILE_TYPE,
  IAttachment,
  IFile,
  IMember,
  IMessage,
  orderActions,
  modalActions,
  IUser,
  IOrder,
  IWorkspace,
  IContact,
  IChannel,
  IProdType,
  IClient,
} from 'common-services';

interface UseRendersProps {
  me: IUser;
  order?: IOrder;
  catalog?: IWorkspace;
  messages: Array<IMessage>;
  contact?: IContact;
  contacts: { [key: number]: IContact };
  amSeller: boolean;
  isAll: boolean;
  commentsSection: string;
  getChannelMembers: (messages: Array<IMessage>) => Array<IMember>;
  getSender: (senderId: number) => {
    avatar: string;
    avatarColor: { text: string; background: string };
    name: string;
    companyName: string;
  };
  showBannerUnread: number | undefined;
  lastReadContactAt: number;
  commentsChannel?: IChannel;
  touchImage: typeof modalActions.touchImage;
  orderAttachmentDownload: typeof orderActions.orderAttachmentDownload;
  downloadFile: () => void;
  supportAction: typeof userWebActions.supportAction;
  prodTypes?: { [key: string]: IProdType };
  unreadsCount: {
    unread_comments?: number;
    unread_updates?: number;
  };
  getMessagesDebounced: (createdAt: number) => void;
  filePreview: IFile | undefined;
  setFilePreview: (file: IFile | undefined) => void;
  attachments: Array<IAttachment>;
  clients?: Array<IClient>;
  uploadFile: (file: IFile, fileType: ORDER_FILE_TYPE) => void;
}

interface UseRendersReturn {
  renderAttachmentModal: () => JSX.Element | null;
  renderActiveSection: () => JSX.Element | null;
}

export const useRenders = ({
  me,
  order,
  catalog,
  messages,
  contact,
  contacts,
  amSeller,
  isAll,
  commentsSection,
  getChannelMembers,
  getSender,
  showBannerUnread,
  lastReadContactAt,
  commentsChannel,
  touchImage,
  orderAttachmentDownload,
  downloadFile,
  supportAction,
  prodTypes,
  unreadsCount,
  getMessagesDebounced,
  filePreview,
  setFilePreview,
  attachments,
  clients,
  uploadFile,
}: UseRendersProps): UseRendersReturn => {
  const history = useHistory();

  // Render functions
  const renderComments = React.useCallback((): JSX.Element | null => {
    const showPrice = amSeller || order?.priceMode !== 'none';

    const filteredMessages = messages.reduce((acc, message) => {
      const {
        extraData: { code, content },
      } = message;

      // Handle price visibility
      if (showPrice) {
        acc.push(message);
        return acc;
      } else if (code !== 'updated_order') {
        acc.push(message);
        return acc;
      }

      // Filter price-related content when price should not be shown
      const filteredContent = content.filter(c => {
        const { type } = c;
        return ![
          'price_changed',
          'price_update',
          'custom_item_added',
          'custom_item_removed',
          'custom_item_changed',
        ].includes(type);
      });

      if (filteredContent.length > 0) {
        acc.push({
          ...message,
          extraData: {
            ...message.extraData,
            content: [...filteredContent],
          },
        });
      }
      return acc;
    }, [] as Array<IMessage>);

    const members = getChannelMembers(filteredMessages);

    return (
      <>
        {filteredMessages?.map((message, index, list) => {
          const previous = list[index - 1];
          const later = list[index + 1];
          const isFirst = !previous || differenceInCalendarDays(message.createdAt, previous.createdAt) !== 0;
          const sender = getSender(message.senderId);

          // Check if this is the first unread message
          const firstUnread =
            showBannerUnread &&
            ((!later && showBannerUnread < message.createdAt) ||
              (later &&
                showBannerUnread >= later.createdAt &&
                showBannerUnread < message.createdAt &&
                message.senderId !== me.id));

          // Handle imported orders
          if (
            order?.origin !== ORDER_ORIGIN.INTERNAL &&
            order?.origin !== ORDER_ORIGIN.OFFERS &&
            message.messageType === 'admin' &&
            message.extraData.code === 'created_order'
          ) {
            message.extraData.content = {
              isImported: true,
              link: __('Constants.download_file'),
            };
          }

          // Determine sender name display
          let senderName =
            message.messageType !== 'admin' || amSeller
              ? userService.getDisplayName(sender.name, sender.companyName)
              : sender.companyName;

          // Handle automatic updates
          const isOrderUpdate = ['updated_order', 'order_external_id_updated', 'order_sent_to_erp'].includes(
            message.extraData?.code,
          );

          if (isOrderUpdate && message.extraData?.content?.[0]?.is_automatic) {
            senderName = `${sender.companyName} ${__('Messages.Admin.automatic')}`;
          }

          return (
            <React.Fragment key={`${message.messageId}-${message.message}`}>
              {/* Render date separator if needed */}
              {isFirst && (
                <Message
                  history={history}
                  isDate={true}
                  key={`${message.messageId}-${message.createdAt}`}
                  message={message}
                  senderAvatar={sender.avatar}
                  senderAvatarColor={sender.avatarColor}
                  senderName={senderName}
                  isComment={true}
                />
              )}

              {/* Render message if it's not an attachment or if we're showing all */}
              {message.messageType !== 'attachment' || commentsSection === 'all' ? (
                <Message
                  history={history}
                  isFirst={true}
                  isOrderUpdate={isOrderUpdate}
                  isRead={!contact?.isUnregistered && lastReadContactAt?.[commentsChannel?.id] >= message.createdAt}
                  key={`${message.messageId}-${message.message}`}
                  me={me}
                  message={message}
                  navigateToShowroom={() => null}
                  senderAvatar={message.messageType === 'admin' ? catalog?.companyLogo : sender.avatar}
                  senderAvatarColor={sender.avatarColor}
                  senderName={senderName}
                  touchImage={touchImage}
                  touchFile={(): any => // eslint-disable-line @typescript-eslint/no-explicit-any
                    message.extraData.id
                      ? orderAttachmentDownload(me.id, order?.id, message.extraData.id, dataFile =>
                          downloadFileFromData(dataFile, message.extraData.mimeType, message.extraData.filename),
                        )
                      : downloadFileFromData(
                          message.extraData.data,
                          message.extraData.mimeType,
                          message.extraData.filename,
                        )
                  }
                  action={
                    message.extraData.content?.isImported
                      ? downloadFile
                      : message.extraData.code === 'price_disclaimer'
                      ? supportAction
                      : undefined
                  }
                  members={members}
                  prodTypes={prodTypes}
                  isComment={true}
                />
              ) : null}

              {/* Render unread message banner if needed */}
              {firstUnread ? (
                <UnreadMessage
                  unreadCommentsCount={unreadsCount.unread_comments}
                  unreadUpdatesCount={unreadsCount.unread_updates}
                />
              ) : null}
            </React.Fragment>
          );
        })}

        {/* Show "Load More" button if needed */}
        {!isAll && (
          <S.MoreWrapper>
            <S.Link onClick={() => getMessagesDebounced(messages[messages.length - 1].createdAt)}>
              {__('Components.ProductsList.ShowMore')}
            </S.Link>
          </S.MoreWrapper>
        )}
      </>
    );
  }, [
    amSeller,
    contact?.isUnregistered,
    getChannelMembers,
    getSender,
    getMessagesDebounced,
    history,
    lastReadContactAt,
    me,
    messages,
    order?.id,
    order?.origin,
    orderAttachmentDownload,
    downloadFile,
    prodTypes,
    showBannerUnread,
    supportAction,
    touchImage,
    unreadsCount,
    catalog?.companyLogo,
    commentsChannel?.id,
    isAll,
    order?.priceMode,
    commentsSection,
  ]);

  const renderAttachmentModal = (): JSX.Element | null => {
    if (!filePreview) return null;

    return (
      <FileModal
        orderHash={order?.externalIdSeller || order?.externalIdBuyer || '#' + order?.hashId}
        file={filePreview}
        modalClose={() => setFilePreview(undefined)}
        sendFile={(fileType: ORDER_FILE_TYPE) => uploadFile(filePreview, fileType)}
      />
    );
  };

  /**
   * Renders the active commentsSection content based on the current commentsSection state
   * This includes messages, files, and activity sections
   */
  const renderActiveSection = React.useCallback(() => {
    const showCommentSection =
      commentsSection === 'all' || commentsSection === 'messages' || commentsSection === 'activity';
    const showFilesSection = commentsSection === 'files' || (!(messages?.length > 0) && attachments?.length > 0);

    return (
      <S.MessagesContainer>
        {/* Render comments commentsSection if applicable */}
        {showCommentSection && renderComments()}

        {/* Render files commentsSection if applicable */}
        {showFilesSection && (
          <Attachments
            attachments={attachments}
            clients={clients}
            commentsChannel={commentsChannel}
            contacts={contacts}
            me={me}
            order={order}
            orderAttachmentDownload={orderAttachmentDownload}
            touchImage={touchImage}
          />
        )}
      </S.MessagesContainer>
    );
  }, [
    commentsSection,
    messages,
    attachments,
    clients,
    commentsChannel,
    contacts,
    me,
    order,
    orderAttachmentDownload,
    touchImage,
    renderComments,
  ]);
  return {
    renderAttachmentModal,
    renderActiveSection,
  };
};

import { __ } from 'common-services';
import * as React from 'react';

import * as S from './CommentsRibbon.styled';

interface CommentsRibbonProps {
  count: number;
  message?: string;
  onNavigateToComments: () => void;
}

const CommentsRibbon: React.FC<CommentsRibbonProps> = React.memo(({ count, message, onNavigateToComments }) => {
  if (!message) return null;

  return (
    <S.Container>
      <S.IconWrapper>
        <S.CommentIcon name="Comment" disableHover aria-hidden="true" />
      </S.IconWrapper>

      <S.MessageText>{message}</S.MessageText>

      <S.NavigationButton
        onClick={onNavigateToComments}
        type="button"
        aria-label={__('Components.Cart.see_comments_aria_label', { count })}
      >
        {__('Components.Cart.see_comments', { count })}
      </S.NavigationButton>
    </S.Container>
  );
});

// For debugging and dev tools
CommentsRibbon.displayName = 'CommentsRibbon';

export default CommentsRibbon;

import { __, IProFeature, RenderTrack } from 'common-services';
import * as React from 'react';

import { IMAGES } from '../../../assets';
import { Modal } from '../../atoms';
import { IFontIconKeys } from '../../atoms/FontIcon';
import * as S from './ConsentioProModal.styled';

export interface IProps {
  section: IProFeature;
  handleRequestInfo: () => void;
  modalClose: () => void;
  closeOnClickOutside?: boolean;
}

const FEATURES: Array<{
  feature: IProFeature;
  sidebarImage: string;
  sidebarImageBN: string;
  mainImage: string;
  benefit1Icon: IFontIconKeys;
  benefit2Icon: IFontIconKeys;
  benefit3Icon: IFontIconKeys;
}> = [
  {
    feature: 'pricelist' as IProFeature,
    sidebarImage: IMAGES.consentioProSidebarPricelist,
    sidebarImageBN: IMAGES.consentioProSidebarPricelistBN,
    mainImage: IMAGES.consentioProPricelistMain,
    benefit1Icon: 'Price-group',
    benefit2Icon: 'Add-group',
    benefit3Icon: 'Multiple-price-group',
  },
  {
    feature: 'margin' as IProFeature,
    sidebarImage: IMAGES.consentioProSidebarPricelist,
    sidebarImageBN: IMAGES.consentioProSidebarPricelistBN,
    mainImage: IMAGES.consentioProPricelistMain,
    benefit1Icon: 'Price-group',
    benefit2Icon: 'Add-group',
    benefit3Icon: 'Multiple-price-group',
  },
  {
    feature: 'orderimport' as IProFeature,
    sidebarImage: IMAGES.consentioProSidebarOrderImport,
    sidebarImageBN: IMAGES.consentioProSidebarOrderImportBN,
    mainImage: IMAGES.consentioProOrderImportMain,
    benefit1Icon: 'Globe',
    benefit2Icon: 'Adaptable',
    benefit3Icon: 'Send-email',
  },
  {
    feature: 'teams' as IProFeature,
    sidebarImage: IMAGES.consentioProSidebarTeams,
    sidebarImageBN: IMAGES.consentioProSidebarTeamsBN,
    mainImage: IMAGES.consentioProTeamsMain,
    benefit1Icon: 'Team-icon',
    benefit2Icon: 'Clock',
    benefit3Icon: 'Comment',
  },
  {
    feature: 'dashboards' as IProFeature,
    sidebarImage: IMAGES.consentioProSidebarDashboards,
    sidebarImageBN: IMAGES.consentioProSidebarDashboardsBN,
    mainImage: IMAGES.consentioProDashboardsMain,
    benefit1Icon: 'Chart',
    benefit2Icon: 'Analysis',
    benefit3Icon: 'Click',
  },
];

interface IState {
  selectedSection: IProFeature;
}

export default class ConsentioProModal extends React.PureComponent<IProps, IState> {
  private t: number;
  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    this.state = {
      selectedSection: props.section,
    };
  }

  public componentDidMount() {
    const { section } = this.props;
    RenderTrack.track('ConsentioProModal', { renderTime: this.t, section });
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { selectedSection } = this.state;
    if (prevState.selectedSection !== selectedSection) {
      RenderTrack.track('ConsentioProModal', { renderTime: Date.now(), section: selectedSection });
    }
  }

  public render() {
    const { handleRequestInfo, modalClose, closeOnClickOutside } = this.props;
    const { selectedSection } = this.state;
    return (
      <Modal
        showCloseIcon={true}
        toShow={true}
        onCloseClick={modalClose}
        minWidth={'1128px'}
        maxWidth={'1128px'}
        padding={'0px'}
        closeOnClickOutside={closeOnClickOutside}
      >
        <S.Container>
          <S.SideBar>
            <S.SideBarTitle>{__('ConsentioProModal.sidebarTitle')}</S.SideBarTitle>
            {FEATURES.map(f => (
              <S.SideBarFeature
                key={f.feature}
                selected={selectedSection === f.feature}
                onClick={() => this.setState({ selectedSection: f.feature })}
              >
                <S.FeatureImageContainer>
                  <S.FeatureImage src={selectedSection === f.feature ? f.sidebarImage : f.sidebarImageBN} />
                </S.FeatureImageContainer>
                <S.FeatureNameContainer>
                  <S.FeatureName selected={selectedSection === f.feature}>{getSidebarTitle(f.feature)}</S.FeatureName>
                </S.FeatureNameContainer>
              </S.SideBarFeature>
            ))}
          </S.SideBar>
          <Content
            ctaLiteral={__('ConsentioProModal.CTA')}
            handleCTA={handleRequestInfo}
            selectedSection={selectedSection}
            showCTA={true}
          />
        </S.Container>
      </Modal>
    );
  }
}

/**
 * Content with info + picture to explain the feature
 */
export const Content = React.memo(
  ({
    ctaLiteral = __('ConsentioProModal.CTA'),
    handleCTA,
    selectedSection,
    showCTA,
  }: {
    ctaLiteral?: string;
    handleCTA: () => void;
    selectedSection: IProFeature;
    showCTA: boolean;
  }) => {
    const current = FEATURES.filter(f => f.feature === selectedSection)[0];
    const literals = getFeaturesLiterals(current.feature);
    return (
      <S.Content>
        <S.TitleContainer>
          <S.TitleText>{literals.title}</S.TitleText>
          <S.TitleBadge>Consentio Pro</S.TitleBadge>
        </S.TitleContainer>
        <S.SubtitleContainer>
          <S.SubtitleText>{literals.subtitle}</S.SubtitleText>
        </S.SubtitleContainer>
        <S.MainImageContainer>
          <S.MainImage src={current.mainImage} />
        </S.MainImageContainer>
        <S.Benefits>
          <S.Benefit>
            <S.BenefitIconWrapper>
              <S.BenefitIcon name={current.benefit1Icon} disableHover={true} />
            </S.BenefitIconWrapper>
            <S.BenefitTitle>{literals.benefit1Title}</S.BenefitTitle>
            <S.BenefitDescription>{literals.benefit1Description}</S.BenefitDescription>
          </S.Benefit>
          <S.Benefit>
            <S.BenefitIconWrapper>
              <S.BenefitIcon name={current.benefit2Icon} disableHover={true} />
            </S.BenefitIconWrapper>
            <S.BenefitTitle>{literals.benefit2Title}</S.BenefitTitle>
            <S.BenefitDescription>{literals.benefit2Description}</S.BenefitDescription>
          </S.Benefit>
          <S.Benefit>
            <S.BenefitIconWrapper>
              <S.BenefitIcon name={current.benefit3Icon} disableHover={true} />
            </S.BenefitIconWrapper>
            <S.BenefitTitle>{literals.benefit3Title}</S.BenefitTitle>
            <S.BenefitDescription>{literals.benefit3Description}</S.BenefitDescription>
          </S.Benefit>
        </S.Benefits>
        <S.ActionWrapper>
          {showCTA ? (
            <React.Fragment>
              <S.CTA onClick={handleCTA}>{ctaLiteral}</S.CTA>
              <S.CTASubtitle>{__('ConsentioProModal.CTASubtitle')}</S.CTASubtitle>
            </React.Fragment>
          ) : null}
        </S.ActionWrapper>
      </S.Content>
    );
  },
);

/**
 * Get features literals according to the feature
 */
function getFeaturesLiterals(feature: IProFeature): {
  title: string;
  subtitle: string;
  benefit1Title: string;
  benefit1Description: string;
  benefit2Title: string;
  benefit2Description: string;
  benefit3Title: string;
  benefit3Description: string;
} {
  let title;
  let subtitle;
  let benefit1Title;
  let benefit1Description;
  let benefit2Title;
  let benefit2Description;
  let benefit3Title;
  let benefit3Description;

  switch (feature) {
    case 'pricelist':
      title = __('ConsentioProModal.pricelist.title');
      subtitle = __('ConsentioProModal.pricelist.subtitle');
      benefit1Title = __('ConsentioProModal.pricelist.benefit1Title');
      benefit1Description = __('ConsentioProModal.pricelist.benefit1Description');
      benefit2Title = __('ConsentioProModal.pricelist.benefit2Title');
      benefit2Description = __('ConsentioProModal.pricelist.benefit2Description');
      benefit3Title = __('ConsentioProModal.pricelist.benefit3Title');
      benefit3Description = __('ConsentioProModal.pricelist.benefit3Description');
      break;
    case 'margin':
      title = __('ConsentioProModal.margin.title');
      subtitle = __('ConsentioProModal.margin.subtitle');
      benefit1Title = __('ConsentioProModal.margin.benefit1Title');
      benefit1Description = __('ConsentioProModal.margin.benefit1Description');
      benefit2Title = __('ConsentioProModal.margin.benefit2Title');
      benefit2Description = __('ConsentioProModal.margin.benefit2Description');
      benefit3Title = __('ConsentioProModal.margin.benefit3Title');
      benefit3Description = __('ConsentioProModal.margin.benefit3Description');
      break;
    case 'orderimport':
      title = __('ConsentioProModal.orderimport.title');
      subtitle = __('ConsentioProModal.orderimport.subtitle');
      benefit1Title = __('ConsentioProModal.orderimport.benefit1Title');
      benefit1Description = __('ConsentioProModal.orderimport.benefit1Description');
      benefit2Title = __('ConsentioProModal.orderimport.benefit2Title');
      benefit2Description = __('ConsentioProModal.orderimport.benefit2Description');
      benefit3Title = __('ConsentioProModal.orderimport.benefit3Title');
      benefit3Description = __('ConsentioProModal.orderimport.benefit3Description');
      break;
    case 'teams':
      title = __('ConsentioProModal.teams.title');
      subtitle = __('ConsentioProModal.teams.subtitle');
      benefit1Title = __('ConsentioProModal.teams.benefit1Title');
      benefit1Description = __('ConsentioProModal.teams.benefit1Description');
      benefit2Title = __('ConsentioProModal.teams.benefit2Title');
      benefit2Description = __('ConsentioProModal.teams.benefit2Description');
      benefit3Title = __('ConsentioProModal.teams.benefit3Title');
      benefit3Description = __('ConsentioProModal.teams.benefit3Description');
      break;
    case 'dashboards':
      title = __('ConsentioProModal.dashboards.title');
      subtitle = __('ConsentioProModal.dashboards.subtitle');
      benefit1Title = __('ConsentioProModal.dashboards.benefit1Title');
      benefit1Description = __('ConsentioProModal.dashboards.benefit1Description');
      benefit2Title = __('ConsentioProModal.dashboards.benefit2Title');
      benefit2Description = __('ConsentioProModal.dashboards.benefit2Description');
      benefit3Title = __('ConsentioProModal.dashboards.benefit3Title');
      benefit3Description = __('ConsentioProModal.dashboards.benefit3Description');
      break;
  }
  return {
    title,
    subtitle,
    benefit1Title,
    benefit1Description,
    benefit2Title,
    benefit2Description,
    benefit3Title,
    benefit3Description,
  };
}

/**
 * Get side bar title literal
 */
function getSidebarTitle(feature: IProFeature) {
  switch (feature) {
    case 'pricelist':
      return __('ConsentioProModal.pricelist.sidebarTitle');
    case 'orderimport':
      return __('ConsentioProModal.orderimport.sidebarTitle');
    case 'teams':
      return __('ConsentioProModal.teams.sidebarTitle');
    default:
      return __('ConsentioProModal.dashboards.sidebarTitle');
  }
}

import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE_PATHS } from '../../../../../../../../constants';
import { logError } from '../../../../../../../../services/log';
import getPath from '../../../../../../../../util/routes';

import type { IOrderItem, IWorkspace } from 'common-services';

interface UseHandlersProps {
  catalogs: { [key: string]: IWorkspace };
  setShowSelectWorkspaceModal: (show: boolean) => void;
  setNewOrExistingLot: (value: string) => void;
  openSearchProductsModal: (item: IOrderItem, originLot?: number, mappingCode?: string) => void;
}

interface UseHandlersReturn {
  handleLotOptions: (op: string, item: IOrderItem) => void;
}

export const useHandlers = ({
  catalogs,
  setShowSelectWorkspaceModal,
  setNewOrExistingLot,
  openSearchProductsModal,
}: UseHandlersProps): UseHandlersReturn => {
  const history = useHistory();

  const handleLotOptions = React.useCallback(
    (op: string, item: IOrderItem): void => {
      switch (op) {
        case 'new':
          if (Object.keys(catalogs).length > 1) {
            setShowSelectWorkspaceModal(true);
            setNewOrExistingLot('new');
          } else {
            history.push(
              getPath({
                path: ROUTE_PATHS.WORKSPACE_PRODUCTS,
                workspaceId: Object.values(catalogs)[0].id + '',
                productId: 'new',
              }) + `?originLot=${item.id}`,
            );
          }
          break;
        case 'existing':
          if (Object.keys(catalogs).length > 1) {
            setShowSelectWorkspaceModal(true);
            setNewOrExistingLot('existing');
          } else {
            openSearchProductsModal(item);
          }
          break;
        default:
          logError(new Error('unknown option: ' + op), 'extended.options');
          break;
      }
    },
    [catalogs, history, openSearchProductsModal, setNewOrExistingLot, setShowSelectWorkspaceModal],
  );
  return {
    handleLotOptions,
  };
};

// OrderButton.tsx
import { ORDER_STATUS, __, orderActions } from 'common-services';
import { debounce } from 'lodash';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import * as S from './ActionsCard.styled';

import type { AxiosError } from 'axios';
import type { ICart, IMessage, IOrder, IUser } from 'common-services';

interface OrderButtonProps {
  order: IOrder;
  me: IUser;
  cart: ICart;
  contactId?: number;
  previousComments: Array<IMessage>;
  closeCart: () => void;
  ctaScreen: React.ReactNode;
  orderNew: typeof orderActions.orderNew;
  orderUpdate: (order: IOrder) => void;
  showSuccessModal(newOrder: IOrder): void;
}

/**
 * OrderButton Component handles order creation and updates with debouncing and loading states
 * to prevent multiple submissions
 */
const OrderButton: React.FC<OrderButtonProps> = ({
  order,
  me,
  cart,
  contactId,
  closeCart,
  ctaScreen,
  previousComments,
  orderNew,
  orderUpdate,
  showSuccessModal,
}) => {
  const dispatch = useDispatch<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  // Handle API success response
  const handleSuccess = React.useCallback(
    (newOrder?: IOrder) => {
      setIsLoading(false);
      if (contactId) {
        closeCart();
      }
      if (newOrder) {
        showSuccessModal(newOrder);
      }
    },
    [contactId, closeCart, showSuccessModal],
  );

  // Handle API error response
  const handleError = React.useCallback(
    (err?: AxiosError) => {
      setIsLoading(false);
      setError(err?.message || 'An error occurred');
      console.error(error);
    },
    [error],
  );

  // Debounced order creation function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOrderNew = React.useCallback(
    debounce(async (orderData: IOrder, userId: number) => {
      try {
        const result = await dispatch(
          orderNew(
            {
              ...orderData,
              externalIdBuyer: cart.externalIdBuyer,
              externalIdSeller: cart.externalIdSeller,
            },
            userId,
            (o?: IOrder, err?: AxiosError) => {
              if (err) {
                handleError(err);
              } else {
                handleSuccess(o);
              }
            },
          ),
        );
        return result;
      } catch (err) {
        handleError(err as AxiosError);
      }
    }, 300), // 300ms debounce time
    [dispatch, cart, handleSuccess, handleError, orderNew],
  );

  // Debounced order update function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOrderUpdate = React.useCallback(
    debounce((orderData: IOrder) => {
      try {
        orderUpdate(orderData);
        handleSuccess();
      } catch (err) {
        handleError(err as AxiosError);
      }
    }, 300),
    [handleSuccess, handleError, orderUpdate],
  );

  // Click handler
  const handleClick = React.useCallback(async () => {
    // Prevent multiple clicks while loading
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setError(null);

    if (order?.id) {
      if (order?.status === ORDER_STATUS.DRAFT) {
        dispatch(
          orderActions.orderDraftSend(order, me.id, () => {
            if (contactId) closeCart();
            setIsLoading(false);
          }),
        );
      } else {
        await debouncedOrderUpdate(order);
      }
    } else {
      const orderData = {
        ...order,
        initialComments: previousComments,
      };
      await debouncedOrderNew(orderData, me.id);
    }
  }, [
    isLoading,
    order,
    me.id,
    contactId,
    closeCart,
    debouncedOrderUpdate,
    debouncedOrderNew,
    previousComments,
    dispatch,
  ]);

  return (
    <S.ActionButton
      id="cta-action-button"
      type="principal"
      onClick={handleClick}
      disabled={isLoading} // Disable button while loading
      aria-busy={isLoading}
    >
      {isLoading ? __('Global.Commons.Actions.processing') : ctaScreen}
    </S.ActionButton>
  );
};

export default React.memo(OrderButton);

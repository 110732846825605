import styled from '../../../../../styled-components';
import { Button, FontIcon } from '../../../../atoms';

import type { ITheme } from '../../../../../styled-components';

interface IStyledProps {
  enabled?: boolean;
  disabled?: boolean;
  margin?: string;
  numHeaders?: number;
  theme: ITheme;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const Title = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
`;

export const TitleSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Reference = styled.span`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H2};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
`;

export const ActionsSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const DownloadButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  height: 36px;
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey1};
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1.5)};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
`;

export const DownloadText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme }) => theme.colors.black};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const Divider = styled.div`
  width: 1px;
  height: 20px;
  background: ${({ theme }) => theme.colors.grey1};
`;

export const ArrowIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  width: ${(p: IStyledProps) => p.theme.fontSize.normal};
  height: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
`;

export const PreviewButton = styled(Button)`
  padding-right: 24px;
  position: absolute;
  top: 95px;
  right: 65px;
  z-index: 1;
`;
export const ModifyButton = styled(Button)``;

export const DownloadIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;

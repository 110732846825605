import { __, debounce, notificationsActions, sellerWorkspaceActions, userSelectors } from 'common-services';
import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';

import config from '../../../../bindings/config';
import theme from '../../../theme';
import { LoadingSkeleton, Select, TableHeader, Tooltip } from '../../atoms';
import ProductSectionLine from '../../atoms/ProductSectionLine/ProductSectionLine.component';
import ActionsModal from '../ActionsModal';
import DNDRow from '../DNDRow';

import * as S from './Table.styled';

import type { IReduxState } from '../../../reducers';
import type { ColumnConfigType } from 'common-services';

export interface IColumn extends ITableColumn {
  element?: (data: Record<string, any>, idx: number, rowIdx: number) => React.ReactElement;
  getColor?: (data: Record<string, any>, idx: number, rowIdx: number) => string;
  value?: (data: Record<string, any>, idx: number, rowIdx: number) => string | number;
  onClick?: (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => void;
  minWidth?: string;
  getDataCell?: (data: any, idx: number, rowIdx: number) => IDataCell;
  showOver?: boolean;
  cellClassName?: string;
  withLeftBorder?: boolean;
  noRightPadding?: boolean;
}
/**
 * Unified table component
 */
const Table = ({
  className,
  columns,
  draggable,
  headerColumns,
  secondHeaderColumns,
  configId,
  emptyText,
  fixedColumns,
  footerChildren,
  isQuoterMode,
  isReadRow,
  nonRemovableColumns,
  onClickRow,
  removeSelection,
  rowCursor,
  scrollClassName,
  selectable,
  selectAll,
  selected,
  showCustomColumns,
  showLoading,
  showStickyHeader,
  sort,
  sortOrder,
  updateSortMode,
  values,
  productColumns,
  onUpdateRows,
  onDrop,
  getDragRowId,
}: {
  className?: string;
  columns: Array<IColumn>;
  draggable?: boolean;
  headerColumns?: Array<any>;
  secondHeaderColumns?: Array<any>;
  configId?: ColumnConfigType;
  emptyText: string;
  fixedColumns?: Array<string>;
  footerChildren?: React.ReactElement;
  isQuoterMode?: boolean;
  isReadRow?: (data: any) => boolean;
  nonRemovableColumns?: Array<string>;
  onClickRow?: (data: Record<string, any>, e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
  removeSelection?: () => void;
  rowCursor?: string;
  scrollClassName?: string;
  selectable?: boolean;
  selectAll?: () => void;
  selected?: 'all' | 'any' | 'none';
  showCustomColumns?: boolean;
  showLoading?: number;
  showStickyHeader?: boolean;
  sort?: string;
  sortOrder?: 'asc' | 'desc';
  updateSortMode?: (sortBy: string, sortDirection: 'desc' | 'asc') => void;
  values: Array<Record<string, any>>;
  productColumns: Array<any>;
  onUpdateRows?: (rows: Array<number> | Array<Record<string, any>>) => void;
  onDrop?: () => void;
  getDragRowId?: (row: string) => string;
}) => {
  const togglePriceGroupRanksEnable = useSelector(userSelectors.hasToggleEnabled(config.TOGGLE_RANK_PRICEGROUPS));

  const [showColumnsModal, setShowColumnsModal] = React.useState(false);

  const [rows, setRows] = React.useState(showLoading ? Array.from(Array(showLoading).keys()) : values.filter(v => v));
  const headerColumnRef = React.useRef(null);
  const [height, setHeight] = React.useState(0);
  React.useLayoutEffect(() => {
    if (headerColumns && headerColumnRef.current) {
      setHeight(headerColumnRef.current.clientHeight);
    }
  }, [headerColumns, headerColumnRef]);

  // This is so sorting by product keeps working
  React.useEffect(() => {
    setRows(values.filter(v => v));
  }, [values]);

  const moveRow = (id: string, hoverIndex: number) => {
    if (!getDragRowId) return;

    // Create a shallow copy of the rows array to avoid mutating the original array
    const copyRows = [...rows] as Array<any>;

    // Find the row with the corresponding sku
    const draggedRow = copyRows.find(r => getDragRowId(r) === id);

    // Put the row with the corresponding sku at the hoverIndex
    copyRows.splice(copyRows.indexOf(draggedRow), 1);
    copyRows.splice(hoverIndex, 0, draggedRow);

    // Update the rows array with the new order
    onUpdateRows(copyRows as any);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <S.Table className={className}>
        {rows.length ? (
          <>
            {secondHeaderColumns ? (
              <S.TableHeaderOverSecond
                headerRef={headerColumnRef}
                columns={secondHeaderColumns}
                hasLeftAction={selectable}
                onSettingsClick={() => setShowColumnsModal(true)}
                removeSelection={removeSelection}
                selectable={selectable}
                selectAll={selectAll}
                selected={selected || 'none'}
                showCustomColumns={showCustomColumns}
                showStickyHeader={showStickyHeader}
                scrollClassName={scrollClassName}
                updateSortMode={updateSortMode}
                sort={sort}
                sortOrder={sortOrder}
              />
            ) : null}
            {headerColumns ? (
              <S.TableHeaderOver
                headerRef={headerColumnRef}
                columns={headerColumns}
                hasLeftAction={selectable}
                onSettingsClick={() => setShowColumnsModal(true)}
                removeSelection={removeSelection}
                selectable={selectable}
                selectAll={selectAll}
                selected={selected || 'none'}
                showCustomColumns={showCustomColumns}
                showStickyHeader={showStickyHeader}
                scrollClassName={scrollClassName}
                updateSortMode={updateSortMode}
                sort={sort}
                sortOrder={sortOrder}
              />
            ) : null}
            <TableHeader
              columns={selectable ? columns.slice(1) : columns}
              hasLeftAction={selectable}
              onSettingsClick={() => setShowColumnsModal(true)}
              removeSelection={removeSelection}
              selectable={selectable}
              selectAll={selectAll}
              selected={selected || 'none'}
              showCustomColumns={showCustomColumns}
              showStickyHeader={showStickyHeader}
              headerHeight={headerColumns ? (height ? height : 0) : 0}
              scrollClassName={scrollClassName}
              updateSortMode={updateSortMode}
              sort={sort}
              sortOrder={sortOrder}
            />
          </>
        ) : null}
        <S.Body>
          {rows.length ? (
            rows.map((v, idx) => (
              <React.Fragment key={v.id + '_' + idx}>
                {draggable && togglePriceGroupRanksEnable ? (
                  <DNDRow
                    id={getDragRowId(v)}
                    index={idx}
                    isReadRow={isReadRow ? isReadRow(v as any) : true}
                    key={v.id + '_' + idx}
                    data={v}
                    columns={columns}
                    onClick={onClickRow}
                    rowIdx={idx}
                    cursor={rowCursor}
                    isLoading={!!showLoading}
                    selectable={selectable}
                    onDrag={moveRow}
                    onDrop={onDrop}
                    onDelete={() => null}
                    name={v.id}
                    editMode={true}
                  />
                ) : (
                  <Row
                    isReadRow={isReadRow ? isReadRow(v as any) : true}
                    key={v.id + '_' + idx}
                    data={v}
                    columns={columns}
                    onClick={onClickRow}
                    rowIdx={idx}
                    cursor={rowCursor}
                    isLoading={!!showLoading}
                    selectable={selectable}
                  />
                )}
              </React.Fragment>
            ))
          ) : (
            <S.EmptyTr>
              <S.Col colSpan={columns.length}>
                <S.TextEmpty>{emptyText}</S.TextEmpty>
              </S.Col>
            </S.EmptyTr>
          )}
        </S.Body>

        {footerChildren ? footerChildren : null}
      </S.Table>
      {showColumnsModal && configId ? (
        <ConfigColumnsModal
          columns={columns}
          configId={configId}
          fixedColumns={fixedColumns}
          isQuoterMode={isQuoterMode}
          nonRemovableColumns={nonRemovableColumns}
          onClose={() => setShowColumnsModal(false)}
          productColumns={productColumns}
        />
      ) : null}
    </DndProvider>
  );
};

export default Table;

/**
 * Returns a table's row component
 */
const Row = React.memo(
  ({
    data,
    onClick,
    columns,
    cursor,
    rowIdx,
    isReadRow,
    isLoading,
    selectable,
  }: {
    data: object;
    cursor?: string;
    onClick?: (data: Record<string, any>, e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
    columns: Array<IColumn>;
    rowIdx: number;
    isReadRow?: boolean;
    isLoading?: boolean;
    selectable: boolean;
  }) => {
    return (
      <S.Tr isReadRow={isReadRow} onClick={e => (isLoading || !onClick ? undefined : onClick(data, e))} cursor={cursor}>
        {React.useMemo(
          () =>
            columns.map((c, i) => {
              const key = `${c.id}_${i}`;
              return (
                <Cell
                  key={key}
                  isLoading={isLoading}
                  columns={columns}
                  c={c}
                  data={data}
                  selectable={selectable}
                  idx={i}
                  rowIdx={rowIdx}
                />
              );
            }),
          [columns, isLoading, data, selectable, rowIdx],
        )}
      </S.Tr>
    );
  },
);

Row.displayName = 'Row';

interface CellProps {
  isLoading: boolean;
  c: IColumn;
  idx: number;
  data: object;
  selectable: boolean;
  columns: Array<IColumn>;
  rowIdx: number;
}

export const Cell: React.FC<CellProps> = React.memo(({ isLoading, c, idx, data, selectable, columns, rowIdx }) => {
  let columnContent;
  if (isLoading) {
    columnContent = <LoadingSkeleton width="calc(100% - 36px)" height="13px" />;
  } else {
    columnContent = c.element ? (
      c.element(data, idx, rowIdx)
    ) : c.getDataCell ? (
      getCell(c.getDataCell(data, idx, rowIdx))
    ) : config.TOGGLE_PRODUCT_TOOLTIP.enabled ? (
      <Tooltip
        text={c.value ? c.value(data, idx, rowIdx) : data[c.id]}
        themeMode="dark"
        position="right"
        disabled={!c.showOver}
        keepPropagation={true}
      >
        <S.Text color={c.getColor?.(data, idx, rowIdx) || c.color}>
          {c.value ? c.value(data, idx, rowIdx) : data[c.id]}
        </S.Text>
      </Tooltip>
    ) : (
      <S.Text color={c.getColor?.(data, idx, rowIdx) || c.color}>
        {c.value ? c.value(data, idx, rowIdx) : data[c.id]}
      </S.Text>
    );
  }
  return (
    <S.Col
      className={c.cellClassName}
      selectable={selectable}
      key={c.id + '_' + idx}
      isFirst={!idx}
      isLast={idx === columns.length - 1 && !c.align}
      onClick={isLoading ? undefined : c.onClick}
      width={c.width}
      minWidth={c.minWidth}
      hiddenAtScreenWidth={c.hiddenAtScreenWidth}
      withLeftBorder={c.withLeftBorder}
      noRightPadding={c.noRightPadding}
    >
      {columnContent}
    </S.Col>
  );
});

Cell.displayName = 'Cell';

export enum TYPE_CELL {
  DROPDOWN = 'dropdown',
  AVATAR = 'avatar',
  IMAGE = 'image',
}

export type IDataCell =
  | {
      type: TYPE_CELL.DROPDOWN;
      isLast?: boolean;
      customOption?: (
        innerProps: any,
        data: any,
        isSelected?: boolean,
      ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
      onChange: (name: string, val: string) => void;
      value: string;
      options: Array<any>;
      disabled?: boolean;
      placeHolder?: string;
      showStatusBubble?: boolean;
      footer?: string;
    }
  | {
      type: TYPE_CELL.AVATAR;
      isLast?: boolean;
    }
  | {
      type: TYPE_CELL.IMAGE;
      images: Array<string>;
      onImageClick?: (idx: number) => void;
      isLast?: boolean;
      count: number;
    };

function getCell(data: IDataCell) {
  switch (data.type) {
    case TYPE_CELL.DROPDOWN:
      return (
        <S.SelectContainer isLast={data.isLast}>
          <Select
            name="table-dropdown"
            CustomOption={data.customOption}
            onChange={data.onChange}
            options={data.options}
            placeholder={data.placeHolder}
            value={data.value}
            containerMargin="0"
            innerPadding="0 0 0 30px"
            isSearchable={false}
            fontSize={theme.fontSize.normal}
            disabled={data.disabled}
          />
          {data.showStatusBubble ? <S.StatusBubbleAbsolute status={data.value} disabled={data.disabled} /> : null}
          {data.footer ? <S.FooterText>{data.footer}</S.FooterText> : null}
        </S.SelectContainer>
      );
    case TYPE_CELL.AVATAR:
      return null;
    case TYPE_CELL.IMAGE:
      return (
        <S.MiniImages
          className="table-images"
          images={data.images}
          onImageClick={data.onImageClick}
          count={data.count}
          from="table"
          isLast={data.isLast}
        />
      );
    default:
      return null;
  }
}

/**
 * Returns the modal for the column customization
 */
const ConfigColumnsModal: React.FC<{
  columns: Array<IColumn>;
  configId: ColumnConfigType;
  fixedColumns?: Array<string>;
  isQuoterMode?: boolean;
  nonRemovableColumns?: Array<string>;
  onClose?: () => void;
  productColumns?: Array<any>;
}> = ({ onClose, columns, configId, fixedColumns, isQuoterMode, nonRemovableColumns, productColumns }) => {
  const [savedColumns, setSavedColumns] = React.useState<Array<IColumnConfig>>();
  const [hasChanges, setHasChanges] = React.useState(false);
  const [error, setError] = React.useState<string>('');

  const dispatch = useDispatch<any>();
  const me = useSelector(userSelectors.getUser);
  const workspaceId = useSelector((state: IReduxState) => state.nav.workspaceSelected);
  let numberOfColumns;
  switch (configId) {
    case 'order_detail':
    case 'order_detail_buyer':
      numberOfColumns = 0;
      break;
    case 'client_list':

    default:
      numberOfColumns = 1;
      break;
  }
  return (
    <ActionsModal
      minHeight="450px"
      width="600px"
      onClose={onClose}
      title={__('Components.ProductsList.Table.modal.title')}
      subtitle={
        nonRemovableColumns?.length
          ? ''
          : __('Components.ProductsList.Table.modal.subtitle', { count: numberOfColumns })
      }
    >
      <Manage
        error={error}
        columns={columns}
        configId={configId}
        fixedColumns={fixedColumns}
        isQuoterMode={isQuoterMode}
        nonRemovableColumns={nonRemovableColumns}
        onUpdateColumns={debounce((columnsUpdated: Array<IColumn>) => {
          if (columnsUpdated?.length >= numberOfColumns || nonRemovableColumns?.length) {
            setError(undefined);
            setSavedColumns(
              columnsUpdated
                .filter(c => !fixedColumns?.includes(c.id))
                .map((c, i) => ({ name: c.id, visible: true, order: i })),
            );
            setHasChanges(true);
          } else {
            setError('min-columns-number');
          }
        }, 100)}
        productColumns={productColumns}
      />
      {fixedColumns ? <S.BottomText> {__('Components.ProductsList.Table.modal.custom_info')}</S.BottomText> : false}
      <S.CTARow>
        <S.CTACancel type="secondary" onClick={onClose}>
          {__('Components.ProductsList.Table.modal.cancel')}
        </S.CTACancel>
        <S.CTAOk
          disabled={!!error || !hasChanges}
          type="principal"
          onClick={() => {
            dispatch(
              sellerWorkspaceActions.saveTableVisibilityConfig(
                me.id,
                workspaceId,
                {
                  columns: savedColumns,
                  workspaceId,
                  view: configId,
                },
                responseError => {
                  if (!responseError) {
                    dispatch(sellerWorkspaceActions.tableVisibilityConfigGet(me.id, workspaceId, configId));
                    dispatch(
                      notificationsActions.notificationShow({
                        title: __('Components.ProductsList.custom_columns.success_title'),
                        subtitle: __('Components.ProductsList.custom_columns.success_description'),
                        closable: true,
                        style: 'success',
                      }),
                    );
                  }
                },
              ),
            );
            onClose?.();
          }}
        >
          {__('Components.ProductsList.Table.modal.save')}
        </S.CTAOk>
      </S.CTARow>
    </ActionsModal>
  );
};

const Manage = React.memo(
  ({
    columns,
    configId,
    error,
    fixedColumns,
    isQuoterMode,
    manageRef,
    nonRemovableColumns,
    onUpdateColumns,
    productColumns,
  }: {
    columns: Array<IColumn>;
    configId: ColumnConfigType;
    error?: string;
    fixedColumns?: Array<string>;
    isQuoterMode?: boolean;
    manageRef?: React.RefObject<HTMLDivElement>;
    onUpdateColumns: (columns: Array<IColumn>) => void;
    nonRemovableColumns?: Array<string>;
    productColumns?: Array<any>;
  }) => {
    const [newColumns, setNewColumns] = React.useState<Array<IColumn>>([]);
    const columnIds = newColumns.map(col => col.id);
    const columnsToAdd = productColumns.filter(c => !columnIds.includes(c.value));
    React.useEffect(() => {
      const filteredColumns = columns.filter(col => col?.title?.length > 0);
      setNewColumns(filteredColumns);
    }, [columns]);

    const sortColumnsToAdd = arr => {
      return arr.sort((a, b) => {
        const valueA = Object.values(a)[0];
        const valueB = Object.values(b)[0];

        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      });
    };
    const sortedColumnsToAdd = sortColumnsToAdd(columnsToAdd);
    return (
      <S.FormSectionCustom
        containerPadding={1}
        hasOverflowX={false}
        id="manage"
        sectionRef={manageRef}
        withoutPaddingTop={true}
      >
        <S.SectionsInfoCol>
          <Select
            name="product-columns"
            containerMargin="0 0 15px 0"
            width="auto"
            value="?"
            options={sortedColumnsToAdd}
            noOptionsMessage={__('Components.ProductsList.custom_columns.no_columns_available')}
            disabled={false}
            hasError={!!error}
            onChange={(name, value, _, label) => {
              addColumn(value, label);
            }}
            placeholder={__('Components.ProductsList.Table.modal.placeholder')}
          />
          {error === 'min-columns-number' ? (
            <S.TextError>{__('Components.ProductsList.custom_columns.error_min_columns')}</S.TextError>
          ) : null}
        </S.SectionsInfoCol>
        {newColumns.length ? (
          <S.SectionsTabCol>
            <S.SectionRow padding={`5px 0 5px '68px'  `}>
              <S.HeaderCell>
                <S.RankText>{__('Components.ProductsList.Table.modal.rank')}</S.RankText>
                <S.InfoText>{__('Components.ProductsList.Table.modal.info')}</S.InfoText>
              </S.HeaderCell>
              <S.ImageCell />
            </S.SectionRow>
            <DndProvider backend={HTML5Backend}>
              {newColumns.map((column, index) => {
                const editMode = !fixedColumns?.includes(column.id);
                const isRemovable = !nonRemovableColumns?.includes(column.id);
                return (
                  <ProductSectionLine
                    editMode={editMode}
                    index={index}
                    isRemovable={isRemovable}
                    key={column.title}
                    moveSection={moveColumn}
                    onAddImage={() => null}
                    onDelete={() => isRemovable && onDeleteColumn(index)}
                    onTranslate={() => null}
                    section={column}
                  />
                );
              })}
            </DndProvider>
          </S.SectionsTabCol>
        ) : null}
      </S.FormSectionCustom>
    );

    /**
     * Add column
     */
    function addColumn(value: string, label: string) {
      const copyColumns = [...newColumns];
      if (fixedColumns?.length) {
        copyColumns.splice(1, 0, { id: value, title: label });
      } else {
        copyColumns.push({ id: value, title: label });
      }
      setNewColumns(copyColumns);
      onUpdateColumns(copyColumns);
    }

    /**
     * Delete a column
     */
    function onDeleteColumn(index: number) {
      const copyColumns = [...newColumns];
      const result = copyColumns.filter((c, i) => i !== index);
      setNewColumns(result);
      onUpdateColumns(result);
    }

    /**
     * Move a column by dragging
     */
    function moveColumn(dragIndex: number, hoverIndex: number) {
      const copyColumns = [...newColumns];
      copyColumns.splice(hoverIndex, 0, copyColumns.splice(dragIndex, 1)[0]);
      setNewColumns(copyColumns);
      onUpdateColumns(copyColumns);
    }
  },
);

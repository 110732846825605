import { __ } from 'common-services';
import * as React from 'react';

import { OrderDetails } from '../../..';
import { ActionsModal } from '../../../../../molecules';
import { useOrderContext } from '../../../context';

export const ClientViewModal: React.FC = () => {
  const ctx = useOrderContext();

  const { order, setShowClientViewModal } = ctx;

  return (
    <ActionsModal
      minHeight="450px"
      onClose={() => setShowClientViewModal(false)}
      title={order.id ? __('Components.Cart.client_view_title_draft') : __('Components.Cart.client_view_title')}
      width="100%"
      closeOnClickOutside={true}
    >
      {/* Order details */}
      <OrderDetails
        {...ctx}
        weAreSeller={false}
        amEditor={true}
        priceMode={order?.priceMode}
        previewMode={true}
        hideBack={true}
        acceptOrder={() => null}
        addMessageReaction={() => null}
        cancelOrder={() => null}
        cartClean={() => null}
        cartUpdateCustomItems={() => null}
        cartCleanIfNeeded={() => null}
        cartSet={() => null}
        cartUpdateInitialComments={() => null}
        cartUpdateItem={() => null}
        clientAddressAdd={() => null}
        clientsGet={() => null}
        createNewContact={() => null}
        getExcelOrder={() => null}
        getMessages={() => null}
        getPrices={() => null}
        modalClose={() => null}
        modalOpen={() => null}
        navigateChannelBySection={() => null}
        notificationShow={() => null}
        orderAddressFixIssue={() => null}
        orderAttachmentDownload={() => null}
        orderAttachmentsGet={() => null}
        orderAttachmentUpload={() => null}
        orderChannelGet={() => null}
        orderClone={() => null}
        orderCommentSend={() => null}
        orderCustomPdforExcelDownload={() => null}
        orderFakeNew={() => null}
        orderFixIssue={() => null}
        orderGet={() => null}
        orderItemFixIssue={() => null}
        orderNew={() => null}
        orderOriginalFileDownload={() => null}
        orderPdfDownload={() => null}
        orderSendToERP={() => null}
        orderToggleReadStatus={() => null}
        orderUpdate={() => null}
        orderUpdateComments={() => null}
        orderUpdateCustomItems={() => null}
        orderUpdateExternal={() => null}
        orderUpdateIssues={() => null}
        orderUpdatePrepared={() => null}
        orderUpdateProducts={() => null}
        productGet={() => null}
        productShare={() => null}
        relaunchOrderExport={() => null}
        removeMessageReaction={() => null}
        sendMessage={() => null}
        setCommentsRead={() => null}
        tableVisibilityConfigGet={() => null}
        orderDateFixIssue={() => null}
        suppliersGet={() => null}
        touchFile={() => null}
        touchImage={() => null}
        workspaceAddressAdd={() => null}
        closeCart={() => null}
        goBack={() => null}
        navCloneAction={() => null}
        navShowroom={() => null}
        updateBreadcrumb={() => null}
      />
    </ActionsModal>
  );
};

export default React.memo(ClientViewModal);

import { __, orderUtils } from 'common-services';
import * as React from 'react';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'reference',
  isVisible: true,
  getTitle: () => __('Components.OffersList.reference'),
  minWidth: '70px',
  width: '9%',
  columnValue: ({ order }) => ({
    getElement: item => {
      return (
        <S.ReferenceRow>
          <S.RefContainer showGrey={false}>{item?.buyerReferenceName || '-'}</S.RefContainer>
        </S.ReferenceRow>
      );
    },
  }),
};

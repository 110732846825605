import { __ } from 'common-services';
import * as React from 'react';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'code',
  rank: 1,
  isVisible: true,
  getTitle: () => __('Components.OffersList.code'),
  minWidth: '70px',
  width: '9%',
  columnValue: () => ({
    // (reference.buyer_tag)
    getElement: item => {
      return <S.CodeCell showGrey={false}>{item?.buyerReferenceCode || '-'}</S.CodeCell>;
    },
  }),
};

import { __, ORDER_STATUS, orderActions, productService, sellerWorkspaceSelectors, to } from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import config from '../../../../../../../bindings/config';
import { IMAGES } from '../../../../../../assets';
import { api } from '../../../../../../store';
import { SearchProductModal, UpdateConfirmationModal } from '../../../../../molecules';
import { useOrderContext } from '../../../context';
import MappedProductModal from '../MappedProductModal';
import SetOtherConfirmationModal from '../SetOtherConfirmationModal';

import type { IOrderItem, IMapping } from 'common-services';

const SearchProducts: React.FC = () => {
  const dispatch = useDispatch();

  const {
    me,
    order,
    catalog,
    weAreSeller,
    weAreBuyer,
    prodTypes,
    priceMode,
    featured,
    prices,
    priceGroupIds,
    itemWithIssue,
    setItemWithIssue,
    handleProductChange: changeProductItem,
  } = useOrderContext();

  const [showSearchProduct, setShowSearchProduct] = React.useState<boolean>();
  const [showSetOtherConfirmation, setShowSetOtherConfirmation] = React.useState<IOrderItem>();
  const [itemUpdated, setItemUpdated] = React.useState<IOrderItem>();
  const [showSetMappingConfirmation, setShowSetMappingConfirmation] = React.useState<Array<IMapping>>();
  const [showUpdateConfirmation, setShowUpdateConfirmation] = React.useState<boolean>(false);
  const [showCustomUpdateModal, setShowCustomUpdateModal] = React.useState<boolean>(false);
  const client = useSelector(sellerWorkspaceSelectors.getClient(Number(catalog.id), Number(order?.buyerId)));

  const onClose = (): void => setItemWithIssue(undefined);

  /**
   * Select product item
   */
  const selectProductItem = (item: IOrderItem): void => {
    setShowUpdateConfirmation(true);
    setItemUpdated(item);
  };
  const renderCustomUpdateModal = (item: IOrderItem): void => {
    setShowCustomUpdateModal(true);
    setItemUpdated(item);
  };
  const showSearchProductModal = (): void => {
    setShowSearchProduct(true);
  };
  /**
   * Render update product confirmation modal (to fix product issue)
   */
  if (showUpdateConfirmation) {
    const myRole = catalog.members.find(member => member.userId === me.id)?.role;
    const amViewer = myRole === 'viewer' ? true : false;
    return (
      <UpdateConfirmationModal
        disableCheck={amViewer}
        defaultCheck={amViewer}
        checkLabel={__('Components.Cart.update_confirmation.check')}
        onClose={onClose}
        onCancel={() => setShowUpdateConfirmation(false)}
        ctaChecked={__('Components.Cart.update_confirmation.cta2')}
        ctaNotChecked={__('Components.Cart.update_confirmation.cta')}
        onConfirm={isChecked => changeProductItem(!isChecked, itemUpdated)}
        title={__('Components.Cart.update_confirmation.title', { name: itemWithIssue.item.title })}
      />
    );
  }
  /**
   * Render update product confirmation modal (to fix product issue for a specific client)
   */
  if (showCustomUpdateModal) {
    const externalCode = order?.issues.find(i => i.orderItemId === itemWithIssue.item.id).code;
    return (
      <UpdateConfirmationModal
        checkLabel={__('Components.Cart.update_confirmation.check')}
        onClose={() => setShowCustomUpdateModal(false)}
        ctaChecked={__('Components.Cart.update_confirmation.cta2')}
        ctaNotChecked={__('Components.Cart.update_confirmation.cta')}
        image={IMAGES.notificationsPineapple}
        onConfirm={isChecked => {
          changeProductItem(isChecked ? false : true, itemUpdated);
          onClose();
        }}
        title={__('Components.OrderDetails.setOtherConfirmationModal.title_client', {
          count: 1,
          name: itemWithIssue.item.title,
          code: externalCode,
          client: client.name,
        })}
      />
    );
  }
  /**
   * Render update product confirmation modal (to fix product issue)
   */
  if (showSetOtherConfirmation) {
    const myRole = catalog.members.find(member => member.userId === me.id)?.role;
    const amViewer = myRole === 'viewer' ? true : false;
    return (
      <SetOtherConfirmationModal
        disableCheck={amViewer}
        defaultCheck={amViewer}
        client={client}
        onClose={onClose}
        onConfirm={isChecked => changeProductItem(!isChecked, itemUpdated)}
        productName={
          config.TOGGLE_MAPPINGS_PER_CLIENT.enabled
            ? showSetOtherConfirmation?.productTitle ||
              showSetOtherConfirmation.type + ' ' + showSetOtherConfirmation.variety
            : itemWithIssue.item.title
        }
        onCancel={() => setShowSetOtherConfirmation(undefined)}
        externalCode={order?.issues.find(i => i.orderItemId === itemWithIssue.item.id).code}
        mappedProducts={itemWithIssue.productsMapped}
        prodTypes={prodTypes}
      />
    );
  }
  /**
   * Render update product confirmation modal (to fix product issue)
   */
  if (showSetMappingConfirmation) {
    const myRole = catalog.members.find(member => member.userId === me.id)?.role;
    const amViewer = myRole === 'viewer' ? true : false;
    const externalCode = order?.issues.find(i => i.orderItemId === itemWithIssue.item.id).code;
    return (
      <UpdateConfirmationModal
        defaultCheck={amViewer}
        onClose={onClose}
        onCancel={() => setShowSetMappingConfirmation(undefined)}
        disableCheck={amViewer}
        onConfirm={checked => changeProductItem(!checked, itemUpdated)}
        title={__('Components.OrderDetails.setMappingConfirmationModal.title', {
          name: itemUpdated.title,
          code: externalCode,
        })}
        description={__('Components.OrderDetails.setMappingConfirmationModal.description', {
          name: itemUpdated.title,
          code: externalCode,
        })}
        image={IMAGES.notificationsPineapple}
        checkLabel={__('Components.OrderDetails.setMappingConfirmationModal.check')}
        cancelCTA={__('Components.OrderDetails.setMappingConfirmationModal.cancel')}
        ctaNotChecked={__('Components.OrderDetails.setMappingConfirmationModal.cta')}
        ctaChecked={__('Components.OrderDetails.setMappingConfirmationModal.cta2')}
      />
    );
  }

  /**
   * Render search product page
   */
  if (itemWithIssue.productsMapped?.length && !showSearchProduct) {
    return (
      <MappedProductModal
        amSeller={weAreSeller}
        catalog={catalog}
        close={onClose}
        featured={featured}
        myId={me.id}
        name={itemWithIssue?.mappingCode || ''}
        priceMode={priceMode}
        item={itemWithIssue.item}
        products={itemWithIssue.productsMapped}
        setProductSelected={item => {
          if (config.TOGGLE_MAPPINGS_PER_CLIENT.enabled) {
            renderCustomUpdateModal(item);
          } else changeProductItem(false, item);
        }}
        viewAll={showSearchProductModal}
      />
    );
  }

  return (
    <SearchProductModal
      amSeller={weAreSeller}
      addressId={order?.deliverToId}
      cartClean={(notUpdate?: boolean) =>
        dispatch(
          orderActions.cartClean(
            productService.getKey(weAreBuyer ? order?.sellerId : me.id, order?.buyerId),
            notUpdate,
          ),
        )
      }
      catalog={catalog}
      close={onClose}
      contactId={weAreBuyer ? order?.sellerId : order?.buyerId}
      featured={featured}
      isServedFlow={order?.status === ORDER_STATUS.ACCEPTED && order?.servedFlowEnabled}
      me={me}
      name={itemWithIssue?.item.title || ''}
      priceMode={priceMode}
      prices={prices}
      priceGroupIds={priceGroupIds}
      selectMode="integration"
      selectProductItem={async item => {
        if (itemWithIssue.productsMapped?.length) {
          setShowSetOtherConfirmation(item);
          setItemUpdated(item);
          return;
        }
        const { data } = await to(
          api.mapping.getProductMappingsForInternalRef(
            me.id,
            order?.catalogId,
            catalog?.productMappingStrategy === 'sku' ? item.sku : item.eanCode,
          ),
        );
        if (data?.length) {
          setShowSetMappingConfirmation(data);
          setItemUpdated(item);
          return;
        }
        selectProductItem(item);
      }}
      showFeatured={false}
      status="active"
      originLot={itemWithIssue.originLot}
    />
  );
};

export default SearchProducts;

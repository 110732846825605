import { ORDER_STATUS, ORDER_ORIGIN, __, constants, sellerWorkspaceService } from 'common-services';
import * as React from 'react';

import { downloadBinaryFile, downloadFile } from '../../../../../services/file';
import { type IFontIconKeys } from '../../../../atoms/FontIcon';

import type * as orderLocalActions from '../../../../../actions/order';
import type { IItem } from '../../../../atoms/SimpleDropdown/SimpleDropdown.component';
import type { IOrder, IUser, IWorkspace, orderActions, IOrderDownloadOptions } from 'common-services';

// Input props interface
interface UseDownloadFormatsProps {
  order: IOrder;
  me: IUser;
  catalogs: { [key: string]: IWorkspace };
  downloadFormats: Array<IOrderDownloadOptions>;
  weAreSeller: boolean;
  editAfterAccept: boolean;
  previewMode: boolean;
  // Actions
  getExcelOrder: typeof orderLocalActions.getExcelOrder;
  orderCustomPdforExcelDownload: typeof orderActions.orderCustomPdforExcelDownload;
  orderPdfDownload: typeof orderActions.orderPdfDownload;
  orderOriginalFileDownload: typeof orderActions.orderOriginalFileDownload;
}

export const useDownloadFormats = ({
  order,
  me,
  catalogs,
  downloadFormats,
  weAreSeller,
  editAfterAccept,
  previewMode,
  getExcelOrder,
  orderCustomPdforExcelDownload,
  orderPdfDownload,
  orderOriginalFileDownload,
}: UseDownloadFormatsProps): Array<IItem> => {
  return React.useMemo(() => {
    if (editAfterAccept || previewMode) return [];

    const sellerWorkspace = catalogs[order?.catalogId];
    const mySellerRole = weAreSeller ? sellerWorkspaceService.getRole(sellerWorkspace, me.id) : undefined;

    const items: Array<IItem> = [];

    // Handle accepted orders with PDF/Excel formats
    if (order?.status === ORDER_STATUS.ACCEPTED) {
      if (mySellerRole && downloadFormats.length) {
        items.push(
          ...downloadFormats.reduce((acc, p) => {
            if (p.isExcel) {
              acc.push({
                key: `excel_${p.id}`,
                value: p.name,
                action: () => {
                  orderCustomPdforExcelDownload(me.id, order?.id, p.id, true, false, data => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                      'download',
                      `order-${
                        order?.externalIdSeller || order?.externalIdBuyer || order?.hashId
                      }-${new Date().getTime()}.xlsx`,
                    );
                    document.body.appendChild(link);
                    link.click();
                  });
                },
                image: constants.getLogoFromMime('excel'),
              });
            }
            if (p.isPdf) {
              acc.push({
                key: `pdf_${p.id}`,
                value: p.name,
                action: () => {
                  orderCustomPdforExcelDownload(me.id, order?.id, p.id, false, true, data => {
                    downloadFile(
                      data,
                      'application/pdf',
                      `order-${
                        order?.externalIdSeller || order?.externalIdBuyer || order?.hashId
                      }-${new Date().getTime()}.pdf`,
                    );
                  });
                },
                image: constants.getLogoFromMime('application/pdf'),
              });
            }
            return acc;
          }, [] as Array<IItem>),
        );
      } else {
        items.push(
          {
            key: 'pdf',
            value: __('Components.Cart.format_pdf'),
            action: () => {
              orderPdfDownload(me.id, order?.id, data => {
                downloadFile(
                  data,
                  'application/pdf',
                  `order-${
                    order?.externalIdSeller || order?.externalIdBuyer || order?.hashId
                  }-${new Date().getTime()}.pdf`,
                );
              });
            },
            image: constants.getLogoFromMime('application/pdf'),
          },
          {
            key: 'excel',
            value: __('Components.Cart.format_xls'),
            action: () => {
              getExcelOrder(
                order?.id,
                me.id,
                `proforma-${order?.externalIdSeller || order?.externalIdBuyer || order?.hashId}.xlsx`,
              );
            },
            image: constants.getLogoFromMime('excel'),
          },
        );
      }
    }

    // Handle non-internal orders (Original File option)
    if (order?.origin !== ORDER_ORIGIN.INTERNAL) {
      items.push({
        key: 'import', // Ensure this key is unique across all options
        value: __('Components.Cart.format_original'),
        action: () =>
          orderOriginalFileDownload(me.id, order?.id, (data: string, contenType: string, filename: string) => {
            const fileName =
              filename ||
              `order-${order?.externalIdSeller || order?.externalIdBuyer || order?.hashId}-${new Date().getTime()}`;
            downloadBinaryFile(data, contenType, fileName);
          }),
        icon: 'File' as IFontIconKeys,
      });
    }

    return items;
  }, [
    order?.id,
    order?.status,
    order?.origin,
    order?.catalogId,
    order?.externalIdSeller,
    order?.externalIdBuyer,
    order?.hashId,
    me.id,
    weAreSeller,
    editAfterAccept,
    previewMode,
    downloadFormats,
    catalogs,
    getExcelOrder,
    orderCustomPdforExcelDownload,
    orderPdfDownload,
    orderOriginalFileDownload,
  ]);
};

import styled from '../../../../../../styled-components';
import { Button } from '../../../../../atoms';

import type { ITheme } from '../../../../../../styled-components';

interface IStyledProps {
  enabled?: boolean;
  disabled?: boolean;
  margin?: string;
  numHeaders?: number;
  theme: ITheme;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 24px;
`;

export const SectionTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.H2};
  font-weight: ${({ theme }) => theme.fontWeight.Bold};
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
`;

export const InfoRow = styled.div`
  display: flex;
  gap: 24px;

  @media (max-width: ${({ theme }) => theme.sizes.desktop_m}px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  flex: 1;
  min-width: 0; // Prevents flex children from overflowing
`;

export const CardItem = styled.div``;

export const PreviewButton = styled(Button)`
  padding-top: 0;
  padding-bottom: 0;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

import { __ } from 'common-services';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'sku',
  rank: 1,
  isVisible: true,
  getTitle: () => __('Components.ProductsList.Table.Sku'),
  minWidth: '70px',
  width: '9%',
  columnValue: () => ({
    getValue: item => item.sku || '-',
  }),
};

import { __ } from 'common-services';
import * as React from 'react';

import { GridLoading } from '../../atoms';

import * as S from './LoadingModal.styled';

import type { IModalExtraInfo } from 'common-services';

const LoadingModal = ({
  loadingTheme,
  extra,
  text,
}: {
  loadingTheme: 'dark' | 'light';
  extra?: IModalExtraInfo;
  text?: string;
}): JSX.Element => {
  const { text2 } = extra || {};
  return (
    <S.Modal
      backgroundColor="transparent"
      color="whitesmoke"
      boxShadow="none"
      showCloseIcon={false}
      toShow={true}
      loadingTheme={loadingTheme}
    >
      <S.Content>
        <S.Col>
          <GridLoading loading={true} />
          <S.LoadingText>{text || __('Components.Modal.loading')}</S.LoadingText>
          {text2 ? <S.LoadingDescription>{text2}</S.LoadingDescription> : null}
        </S.Col>
      </S.Content>
    </S.Modal>
  );
};

export default React.memo(LoadingModal);

import { ORDER_ORIGIN, orderUtils, sellerWorkspaceService } from 'common-services';
import * as React from 'react';

import {
  orderDetailBuyerColumns,
  orderDetailColumns,
  orderDetailBuyerOfferColumns,
  orderDetailSellerOfferColumns,
} from '../../../../../../../../constants';

import type { ColumnConfig } from '../../../../../../../molecules/Table/columns.types';
import type { IOrder, IVisibilityConfig } from 'common-services';

const NOT_HANDLED_COLUMNS = ['pallet-type', 'pallet-height', 'type', 'product-id', 'box-type'];

interface UseTableConfigProps {
  weAreSeller: boolean;
  isQuoterMode: boolean;
  columnConfig: Array<IVisibilityConfig>;
  order: IOrder;
}

interface ProductColumn {
  value: string;
  label: string;
}

interface UseTableConfigReturn {
  configId: string;
  fixedColumns: Array<string>;
  areLinesClickable: boolean;
  productColumns: Array<ProductColumn>;
  columnsConfig: Array<ColumnConfig>;
}

export const useTableConfig = ({
  weAreSeller,
  isQuoterMode,
  columnConfig,
  order,
}: UseTableConfigProps): UseTableConfigReturn => {
  // Memoize config ID based on user type

  const configId = React.useMemo(() => {
    if (weAreSeller) {
      return order.origin === ORDER_ORIGIN.OFFERS ? 'order_detail_seller_offer' : 'order_detail';
    } else {
      return order.origin === ORDER_ORIGIN.OFFERS ? 'order_detail_buyer_offer' : 'order_detail_buyer';
    }
  }, [weAreSeller]);

  // Memoize lines clickable status

  const areLinesClickable =
    configId !== 'order_detail_buyer_offer' ? React.useMemo(() => !orderUtils.isCanceled(order), [order]) : false;

  /**
   * Memoize columns to avoid recreating them
   * when the component re-renders
   * @returns {Array<{value: string; label: string;}>}
   */

  const productColumns = React.useMemo((): Array<ProductColumn> => {
    return weAreSeller
      ? configId === 'order_detail_seller_offer'
        ? orderDetailSellerOfferColumns(isQuoterMode)
        : orderDetailColumns(isQuoterMode).filter(c => !NOT_HANDLED_COLUMNS.includes(c.value))
      : configId === 'order_detail_buyer_offer'
      ? orderDetailBuyerOfferColumns(isQuoterMode)
      : orderDetailBuyerColumns(isQuoterMode).filter(c => !NOT_HANDLED_COLUMNS.includes(c.value));
  }, [isQuoterMode, weAreSeller]);

  // Memoize default column configuration
  const defaultColumnConfig = React.useMemo(
    () => columnConfig?.find(c => c.view === configId),
    [columnConfig, configId],
  );

  // Memoize columns configuration
  const columnsConfig = React.useMemo(
    () =>
      defaultColumnConfig
        ? (defaultColumnConfig?.columns as Array<ColumnConfig>)
        : sellerWorkspaceService.getDefaultColumnConfig(configId),
    [defaultColumnConfig, configId],
  );

  // Memoize filtered columns configuration
  const filteredColumnsConfig = React.useMemo(
    () => columnsConfig.filter(config => !NOT_HANDLED_COLUMNS.includes(config.name)),
    [columnsConfig],
  );

  // Memoize fixed columns
  const fixedColumns = React.useMemo(
    () => [
      'delete',
      'debug',
      'title-with-image',
      'quantity',
      'price',
      'unit-price',
      'served-quantity',
      'ordered-quantity',
      'status',
      'margin',
      'cost',
    ],
    [],
  ); // Empty dependency array as this is a constant

  const fixedColumnsFromOffer = React.useMemo(
    () => ['code', 'origin', 'boxes-per-pallet', 'quantity', 'departure-price', 'delivery-price', 'total-price'],
    [],
  );

  // Memoize final columns configuration with fixed columns
  const finalColumnsConfig = React.useMemo(() => {
    const finalConfig = [...filteredColumnsConfig];
    fixedColumns.forEach(fixedColumn => {
      if (!finalConfig.find(c => c.name === fixedColumn)) {
        finalConfig.push({ name: fixedColumn });
      }
    });
    return finalConfig;
  }, [filteredColumnsConfig, fixedColumns]);

  const finalColumnsConfigOffering = React.useMemo(() => {
    const finalConfig = [...filteredColumnsConfig];
    fixedColumnsFromOffer.forEach(fixedColumn => {
      if (!finalConfig.find(c => c.name === fixedColumn)) {
        finalConfig.push({ name: fixedColumn });
      }
    });
    return finalConfig;
  }, [filteredColumnsConfig, fixedColumnsFromOffer]);

  return {
    configId,
    fixedColumns,
    areLinesClickable,
    productColumns,
    columnsConfig: configId === 'order_detail_buyer_offer' ? finalColumnsConfigOffering : finalColumnsConfig,
  };
};

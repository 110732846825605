import styled from '../../../../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, TextLabel } from '../../../../../atoms';

// Styled components
export const Container = styled(ColumnContainer)`
  padding: ${props => props.theme.paddingSize(4)} 0;
  background: ${props => props.theme.colors.white};
  margin-bottom: ${props => props.theme.paddingSize(3)};
`;

export const Header = styled(RowContainer)`
  padding: 0 ${props => props.theme.paddingSize(4)};
  align-items: center;
`;

export const Title = styled(TextLabel)`
  font-size: ${props => props.theme.fontSize.H3};
  font-weight: ${props => props.theme.fontWeight.Bold};
  color: ${props => props.theme.colors.black};
`;

export const StatsContainer = styled(RowContainer)`
  margin-left: ${props => props.theme.paddingSize(2)};
  align-items: center;
`;

export const StatItem = styled(RowContainer)`
  align-items: center;
  margin-left: ${props => props.theme.paddingSize(2)};
`;

export const StatIcon = styled(FontIcon)`
  font-size: ${props => props.theme.fontSize.normal};
  color: ${props => props.theme.colors.grey1};
  margin-right: ${props => props.theme.paddingSize(1)};
`;

export const StatCount = styled(TextLabel)`
  font-size: ${props => props.theme.fontSize.normal};
  color: ${props => props.theme.colors.grey1};
`;

import { ORDER_ORIGIN, ORDER_STATUS, __ } from 'common-services';
import * as React from 'react';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'delete',
  rank: -1,
  isVisible: true,
  getTitle: (): string => '',
  width: '5%',
  condition: ({ order, isItemDeletable }) => isItemDeletable && order?.status !== ORDER_STATUS.INCOMPLETE,
  columnValue: ({ order, isDeletable, showServedColumn, amEditor, weAreSeller, editAfterAccept, onUpdateItem }) => ({
    getElement: item => {
      return item.servedQuantity ||
        (!item.amount && (order?.origin === ORDER_ORIGIN.IMPORT_UI || order?.origin === ORDER_ORIGIN.EMAIL)) ? (
        isDeletable ? (
          <S.IconWrapper>
            <S.RemoveIcon
              name="Close"
              onClick={e => {
                e.stopPropagation();
                onUpdateItem({
                  ...item,
                  servedQuantity: 0,
                  ...(showServedColumn ? {} : { amount: 0 }),
                });
              }}
            />
          </S.IconWrapper>
        ) : null
      ) : (
        <S.CellContainer>
          <S.DeleteContainer>
            <S.Deleted>{__('Components.ProductDetails.deleted')}</S.Deleted>
            {amEditor && weAreSeller && editAfterAccept ? (
              <S.Undo onClick={() => onUpdateItem({ ...item, servedQuantity: item.amount })}>
                {__('Components.ProductDetails.undo')}
              </S.Undo>
            ) : null}
          </S.DeleteContainer>
        </S.CellContainer>
      );
    },
  }),
};

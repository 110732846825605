import {
  __,
  IGroupedLinesBySeller,
  IOrderBuyerPreparationGroup,
  OrderPreparationLineFilters,
  utils,
} from 'common-services';
import * as React from 'react';

import { FacetFilter } from '../../atoms';
import * as S from './PreparationFilter.styled';
import { search } from 'node-emoji';

function sortFacets(
  facets: Record<string, number>,
  sortBy: 'name' | 'count',
  order: 'asc' | 'desc' = 'asc',
): Record<string, number> {
  return Object.fromEntries(
    Object.entries(facets).sort(([keyA, valueA], [keyB, valueB]) => {
      if (sortBy === 'name') {
        // Check if the keys are strings
        return order === 'asc'
          ? (keyA as string).localeCompare(keyB as string)
          : (keyB as string).localeCompare(keyA as string);
      } else {
        // Compare the numerical values
        return order === 'asc' ? valueA - valueB : valueB - valueA;
      }
    }),
  );
}

export interface IProps {
  changeSearchState: (s: any) => void;
  clearFilters: () => void;
  className?: string;
  preparations?: Array<IOrderBuyerPreparationGroup>;
  numberOfHeaders?: number;
  onHide?: (hide: boolean) => void;
  searchState: OrderPreparationLineFilters;
  showClosed?: boolean;
  showOver?: 'always' | 'never' | 'only-ipad';
}

const PreparationFilter: React.FC<IProps> = ({
  changeSearchState,
  onHide,
  className,
  clearFilters,
  preparations,
  numberOfHeaders,
  searchState,
  showClosed,
  showOver = 'never',
}) => {
  const [hidden, setHidden] = React.useState(showClosed);
  React.useEffect(() => {
    setHidden(showClosed);
  }, [setHidden, showClosed]);

  const hasFilters =
    searchState.types?.length ||
    searchState.suppliers?.length ||
    searchState.warehouses?.length ||
    searchState.comments === true ||
    searchState.comments === false;

  const productKindsFacets: Record<string, number> = preparations.reduce((acc, prep) => {
    const { productKindName, lines } = prep;

    // Translate the product kind name
    const kindName = utils.translateProductKind(productKindName);

    // Create a Set to collect unique seller IDs in the lines
    const uniqueSellerIds = new Set(lines.map(line => line.seller.id));

    // If there are valid sellers (non-empty set), proceed to count
    if (uniqueSellerIds.size > 0) {
      if (!acc[kindName]) {
        acc[kindName] = 0; // Initialize the count if kindName doesn't exist
      }

      // Add the count of unique seller IDs
      acc[kindName] += uniqueSellerIds.size;
    }

    return acc;
  }, {});

  const productKindsNames = preparations.reduce((group, prep) => {
    const kindName = utils.translateProductKind(prep.productKindName);
    group[kindName] = prep.productKindId;
    return group;
  }, {});

  const getProductKindId = (name: string) => {
    return productKindsNames[name];
  };
  const getProductKindsIds = (names: Array<string>) => {
    return names.map(name => productKindsNames[name]);
  };

  const sellerFacets: Record<string, number> = preparations.reduce((acc, prep) => {
    const groupedBySeller = groupPreparationLinesBySeller([prep]);
    groupedBySeller.forEach(group => {
      // Use a Set of unique product kind names
      const uniqueProductKinds = new Set(group.lines.map(line => utils.translateProductKind(prep.productKindName)));

      // Update the count for the seller
      acc[group.sellerName] = (acc[group.sellerName] || 0) + uniqueProductKinds.size;
    });
    return acc;
  }, {});

  const sellerNames: Record<string, number> = preparations.reduce((group, prep) => {
    prep.lines.forEach(line => {
      if (line.seller?.name && line.seller?.id) {
        group[line.seller.name] = line.seller.id;
      }
    });
    return group;
  }, {});

  const getSellerId = (name: string) => {
    return sellerNames[name];
  };
  const getSellersIds = (names: Array<string>) => {
    return names.map(name => sellerNames[name]);
  };

  const whFacets: Record<string, number> = preparations.reduce((acc, prep) => {
    const warehouseSellers: Record<string, Set<string>> = {};

    prep.lines.forEach(line => {
      const warehouseName = line.offeredProduct?.buyerWarehouseName;
      const sellerId = line.seller?.id.toString();

      if (warehouseName && sellerId) {
        if (!warehouseSellers[warehouseName]) {
          warehouseSellers[warehouseName] = new Set();
        }
        warehouseSellers[warehouseName].add(sellerId);
      }
    });

    // Add unique seller counts to the accumulator
    Object.entries(warehouseSellers).forEach(([warehouseName, uniqueSellerIds]) => {
      if (uniqueSellerIds.size > 0) {
        if (!acc[warehouseName]) {
          acc[warehouseName] = 0;
        }
        acc[warehouseName] += uniqueSellerIds.size;
      }
    });

    return acc;
  }, {});
  const warehouseNames: Record<string, number> = preparations.reduce((group, prep) => {
    prep.lines.forEach(line => {
      if (line.offeredProduct?.buyerWarehouseName && line.offeredProduct?.buyerWarehouseId) {
        group[line.offeredProduct.buyerWarehouseName] = line.offeredProduct.buyerWarehouseId;
      }
    });
    return group;
  }, {});

  const getWarehouseId = (name: string) => {
    return warehouseNames[name];
  };
  const getWarehousesIds = (names: Array<string>) => {
    return names.map(name => warehouseNames[name]);
  };

  const commentFacets = preparations.reduce(
    (group, prep) => {
      prep.lines.forEach(line => {
        if (line.comment) {
          group.true += 1;
        } else {
          group.false += 1;
        }
      });
      return group;
    },
    { true: 0, false: 0 },
  );

  const getCommentsChecked = (name: string) => {
    switch (searchState.comments) {
      case null:
        return false;
      case true:
        return name === 'true';
      case false:
        return name === 'false';
    }
  };
  function groupPreparationLinesBySeller(
    preparationGroups: Array<IOrderBuyerPreparationGroup>,
  ): Array<IGroupedLinesBySeller> {
    const groupedLines: Record<string, IGroupedLinesBySeller> = {};

    preparationGroups.forEach(group => {
      group.lines.forEach(line => {
        const { name: sellerName, id: sellerId } = line.seller;

        // Initialize the seller group if it doesn't already exist
        if (!groupedLines[sellerId]) {
          groupedLines[sellerId] = {
            sellerName,
            sellerId,
            lines: [],
          };
        }

        // Add the line to the corresponding seller group
        groupedLines[sellerId].lines.push(line);
      });
    });

    return Object.values(groupedLines);
  }
  const productKindsFilter = React.useMemo(() => {
    if (!Object.keys(productKindsFacets).length) return null;

    return (
      <FacetFilter
        title={__('Components.OffersList.productKind')}
        options={Object.keys(sortFacets(productKindsFacets, 'name', 'asc')).map(productKind => ({
          name: productKind,
          key: productKind,
          count: productKindsFacets[productKind],
          checked: searchState?.types?.includes(getProductKindId(productKind)),
        }))}
        numVisibleOptions={3}
        startFolded={false}
        noCountSort={true}
        onChange={v => changeSearchState({ ...searchState, types: getProductKindsIds(v as Array<string>) })}
      />
    );
  }, [productKindsFacets, searchState, changeSearchState]);

  const warehouseFilter = React.useMemo(() => {
    if (!Object.keys(whFacets).length) return null;

    return (
      <FacetFilter
        title={__('Components.OffersList.address')}
        options={Object.keys(sortFacets(whFacets, 'name', 'asc')).map(warehouse => ({
          name: warehouse,
          key: warehouse,
          count: whFacets[warehouse],
          checked: searchState?.warehouses?.includes(getWarehouseId(warehouse)),
        }))}
        numVisibleOptions={3}
        startFolded={false}
        noCountSort={true}
        onChange={v => changeSearchState({ ...searchState, warehouses: getWarehousesIds(v as Array<string>) })}
      />
    );
  }, [whFacets, searchState, changeSearchState]);

  const sellerFilter = React.useMemo(() => {
    if (!Object.keys(sellerFacets).length) return null;

    return (
      <FacetFilter
        title={__('Components.OffersList.supplier')}
        options={Object.keys(sortFacets(sellerFacets, 'name', 'asc')).map(seller => ({
          name: seller,
          key: seller,
          count: sellerFacets[seller],
          checked: searchState?.suppliers?.includes(getSellerId(seller)),
        }))}
        numVisibleOptions={3}
        startFolded={false}
        noCountSort={true}
        onChange={v => changeSearchState({ ...searchState, suppliers: getSellersIds(v as Array<string>) })}
      />
    );
  }, [sellerFacets, searchState, changeSearchState]);

  const commentFilter = React.useMemo(() => {
    if (!Object.keys(sellerFacets).length) return null;

    return (
      <FacetFilter
        title={__('Components.OffersList.order_preparation.table.comments')}
        options={Object.keys(sortFacets(commentFacets, 'name', 'asc')).map(commentKey => ({
          name:
            commentKey === 'true'
              ? __('Components.OffersList.order_preparation.table.with_comments')
              : __('Components.OffersList.order_preparation.table.without_comments'),
          key: commentKey,
          count: commentFacets[commentKey],
          checked: getCommentsChecked(commentKey),
        }))}
        numVisibleOptions={2}
        startFolded={false}
        noCountSort={true}
        onChange={selectedKeys => {
          const selectedCommentKey = (selectedKeys as Array<string>)[0];
          changeSearchState({
            ...searchState,
            comments: selectedCommentKey === 'true' ? true : selectedCommentKey === 'false' ? false : null,
          });
        }}
      />
    );
  }, [commentFacets, searchState, changeSearchState]);

  return (
    <S.Wrapper hide={hidden} showOver={showOver} numberOfHeaders={numberOfHeaders}>
      <S.FacetsContainer className={className} hide={hidden}>
        <S.Header>
          {hasFilters ? (
            <S.Clear withoutPadding={true} type="skip" hide={hidden} onClick={clearFilters}>
              {__('Facets.clear')}
            </S.Clear>
          ) : null}
          <S.Title>{__('Facets.filter_by')}</S.Title>
          <S.CloseIcon
            name="Close"
            onClick={() => {
              onHide?.(true);
              setHidden(true);
            }}
            hide={hidden}
          />
        </S.Header>
        <S.FiltersBody>
          {productKindsFilter}
          {warehouseFilter}
          {sellerFilter}
          {commentFilter}
        </S.FiltersBody>
        <S.ApplyButton
          type="principal"
          onClick={() => {
            onHide?.(true);
            setHidden(true);
          }}
        >
          {__('Facets.apply_filters')}
        </S.ApplyButton>
      </S.FacetsContainer>
    </S.Wrapper>
  );
};

export default PreparationFilter;

import { __ } from 'common-services';
import * as React from 'react';

import { MessageInput, NavigationTabs } from '../../../../../../molecules';
import { useOrderContext } from '../../../../context';

import * as S from './Comments.styled';
import { useRenders } from './hooks/useRenders';

const Comments: React.FC = React.memo(() => {
  const {
    me,
    order,
    commentsChannel,
    lastReadContactAt,
    commentsSection,
    getCommentFiltersOptions,
    isCommentsBlocked,
    setComment,
    handleFileUpload,
    sendFile,
    sendComments,
    comment,
  } = useOrderContext();

  const { renderAttachmentModal, renderActiveSection } = useRenders({
    ...useOrderContext(),
    lastReadContactAt: lastReadContactAt[commentsChannel?.id], // TODO:3 ugly
  });

  const disabled = false; // TODO:2 check what is this and what's the behavior

  return (
    <>
      <S.NavigationTabsContainer>
        <NavigationTabs selected={commentsSection} tabs={getCommentFiltersOptions()} history={{ push: () => null }} />
      </S.NavigationTabsContainer>
      <S.Container numHeaders={2}>
        {!isCommentsBlocked && !disabled && (
          <MessageInput
            me={me}
            onChangeText={(text: string) => setComment(text)}
            onFileUpload={handleFileUpload}
            onSendFiles={sendFile}
            onSendMessage={sendComments}
            showSendFiles={!!order?.id}
            text={comment}
            placeholder={__('Components.OrderDetails.comments_placeholder')}
          />
        )}
        {renderActiveSection()}
      </S.Container>
      {renderAttachmentModal()}
    </>
  );
});

Comments.displayName = 'Comments';

export default Comments;

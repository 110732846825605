import { __, currency as currencyServices, ORDER_STATUS } from 'common-services';
import * as React from 'react';

import { getDefaultColumns } from '../../../../../../../constants';
import { Table } from '../../../../../../molecules';
import { useTableColumns } from '../../../../../../molecules/Table';
import { useOrderContext } from '../../../../context';

import { AddCustomItemModal } from './AddItem.component';
import { columnSpecs } from './columns';
import * as S from './CustomItems.styled';
import { useTableProps } from './hooks/useTableProps';

import type { CustomItemsContext } from './hooks/useTableProps';
import type { ICustomItem } from 'common-services';

const CustomItems: React.FC = React.memo(() => {
  const {
    order,
    customItems,
    priceMode,
    weAreSeller,
    amEditor,
    canEditAfterAccept: editAfterAccept,
    setCanEditAfterAccept,
    setPreviousOrder,
    handleCustomItemsUpdate,
  } = useOrderContext();

  const [selectedCustomItem, setSelectedCustomItem] = React.useState<ICustomItem>();

  const canEditDraft = weAreSeller && order?.status === ORDER_STATUS.DRAFT;
  const servedFlowEnabled = order?.status === ORDER_STATUS.ACCEPTED && order?.servedFlowEnabled;
  const canServeOrder = React.useMemo(() => {
    return servedFlowEnabled && weAreSeller;
  }, [servedFlowEnabled, weAreSeller]);
  const orderEditable = order?.status === 'pending' || canServeOrder || canEditDraft;
  const canEdit = weAreSeller && amEditor && orderEditable;

  const onModifyAfterAccept = (): void => {
    setCanEditAfterAccept(true);
    if (order) setPreviousOrder({ ...order, items: [...order.items] });
  };

  const customItemUpdate = (ci, idx): void => {
    const customItemsCopy = order?.customItems.slice();
    if (idx >= 0 && customItemsCopy[idx]) {
      customItemsCopy[idx] = ci;
    } else {
      customItemsCopy.push(ci);
    }
    handleCustomItemsUpdate(customItemsCopy);
  };

  const customItemDelete = (ci, idx): void => {
    const customItemsCopy = order?.customItems.slice();
    customItemsCopy.splice(idx, 1);
    handleCustomItemsUpdate(customItemsCopy);
  };

  // TODO:3 Consider extracting these to custom hooks if logic grows
  const handleModalOpen = (ci: ICustomItem): void => {
    setSelectedCustomItem(ci ?? { orderId: order?.id, type: 'custom', title: '', price: 0 });
    if (!editAfterAccept && order?.status === ORDER_STATUS.ACCEPTED) {
      onModifyAfterAccept();
    }
  };

  const handleModalClose = (): void => {
    setSelectedCustomItem(undefined);
  };

  const handleCustomItemSubmit = (description: string, cost: number): void => {
    let newCustomItem: ICustomItem;
    const itemIndex = order?.customItems.findIndex(
      ci =>
        ci.id === selectedCustomItem?.id &&
        ci.type === selectedCustomItem?.type &&
        ci.orderId === selectedCustomItem?.orderId &&
        ci.title === selectedCustomItem?.title,
    );
    if (itemIndex !== undefined && order?.customItems[itemIndex]) {
      newCustomItem = {
        ...order?.customItems[itemIndex],
        title: description,
        price: cost,
      };
    } else {
      newCustomItem = {
        orderId: order?.id,
        title: description,
        price: cost,
        type: 'custom',
      };
    }

    customItemUpdate(newCustomItem, itemIndex);
    handleModalClose();

    if (!editAfterAccept && order?.status === ORDER_STATUS.ACCEPTED) {
      onModifyAfterAccept();
    }
  };

  const columnsConfig = [
    { name: 'close', order: -1 },
    { name: 'description', order: 0 },
    { name: 'cost', order: 1 },
  ];

  const useCustomItemContext = (): CustomItemsContext => {
    const ctx = useOrderContext();
    return {
      ...ctx,
      setSelectedCustomItem,
      customItemDelete,
    };
  };

  const columns = useTableColumns(columnsConfig, columnSpecs, useCustomItemContext, useTableProps);

  const renderTable = (): JSX.Element => {
    if (!customItems.length) return null;

    return (
      <>
        <Table
          className="custom-items-table"
          values={customItems}
          emptyText=""
          columns={columns}
          selectable={false}
          productColumns={getDefaultColumns()}
        />
        {priceMode !== 'none' && order?.price ? (
          <S.FooterRow>
            <S.TextGrey1 textAlign="right">{__('Components.Cart.custom_items.total')}</S.TextGrey1>
            <S.ValueSpan>
              <S.TextPrice>
                {currencyServices.getPrice(order?.currency, order?.price, order?.pricePrecision)}
              </S.TextPrice>
            </S.ValueSpan>
          </S.FooterRow>
        ) : null}
      </>
    );
  };

  return (
    <>
      {canEdit && (
        <S.ActionLink
          id="cta-add-custom-line"
          onClick={() => handleModalOpen(undefined)}
          type="link"
          iconName="Add-more"
          iconSize="18px"
        >
          {__('Components.Cart.custom_items.add_line')}
        </S.ActionLink>
      )}
      {renderTable()}
      {selectedCustomItem && (
        <AddCustomItemModal
          currency={order?.currency}
          customItem={selectedCustomItem}
          onClose={handleModalClose}
          onSubmit={handleCustomItemSubmit}
          order={order}
          pricePrecision={order?.pricePrecision}
        />
      )}
    </>
  );
});

CustomItems.displayName = 'CustomItems';

export default CustomItems;

import styled from '../../../../../../styled-components';

import type { ITheme } from '../../../../../../styled-components';

interface IStyledProps {
  enabled?: boolean;
  disabled?: boolean;
  margin?: string;
  numHeaders?: number;
  theme: ITheme;
}

export const Container = styled.div`
  background-color: ${(p: IStyledProps) => p.theme.colors.light2};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.light1};
  display: flex;
  width: 100%;
  min-height: ${(p: IStyledProps) => p.theme.headerHeight};
  align-items: center;
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

import { __ } from 'common-services';
import * as React from 'react';

import { useOrderContext } from './context';
import { OrderBody, OrderModals, OrderRibbons } from './elements';
import * as S from './OrderContent.styled';

/**
 * OrderContent Component
 *
 * Renders the main content area for order details, including:
 * - Status ribbons
 * - Order header with summary information
 * - Main order body content
 * - Related order modals
 */
const OrderContent: React.FC = React.memo(() => {
  const { handleScroll } = useOrderContext();

  return (
    <S.Container id="order-details" aria-label="Order Details" onScroll={handleScroll}>
      <OrderRibbons />
      <OrderBody />
      <OrderModals />
    </S.Container>
  );
});

OrderContent.displayName = 'OrderContent';

export default OrderContent;

import { __, constants, EventTrack, productService, RenderTrack } from 'common-services';
import * as React from 'react';

import { AddToCart } from './Fragments/AddToCart/AddToCart.component';
import { ProductInfoContent } from './Fragments/ProductInfoContent';
import * as S from './ProductInfo.styled';

import type { modalActions } from 'common-services';

const Traceability = React.lazy(() => import('../Traceability'));

export interface IProps {
  address?: IAddress;
  amSeller: boolean;
  backLiteral?: string;
  cartUpdateItem?: (contactId: number, item: IOrderItem) => void; // TODO: WTF is this name?
  catalogId: number;
  close: () => void;
  contact?: IContact;
  contactId: number;
  contactName: string;
  contacts?: { [id: number]: IContact };
  countries: { [key: string]: ICountry };
  deletable?: boolean;
  disabled?: boolean;
  forceSetPrice?: boolean;
  from: 'order' | 'product' | 'pricelist';
  heightAuto?: boolean;
  history: { push: (path: string) => void };
  isContactUnregistered: boolean;
  isFavorite?: boolean;
  isPublic?: boolean;
  isRecent?: boolean;
  isRecommended?: boolean;
  isServedFlow?: boolean;
  item?: IOrderItem;
  me: IUser;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  priceMode?: IPriceMode;
  pricePrecision: number;
  prodTypes: { [key: string]: IProdType };
  product: GenericProduct;
  sendMessage?: (text: string) => void;
  showBack: boolean;
  showDefaultPicture: boolean;
  showFeatured: boolean;
  showShare: boolean;
  showVarietyAsCharacteristic?: boolean;
  touchImage: typeof modalActions.touchImage;
}

const ProductInfoComponent: React.FC<IProps> = ({
  address,
  amSeller,
  backLiteral,
  cartUpdateItem,
  close,
  contact,
  contactId,
  contactName,
  deletable,
  disabled,
  from,
  heightAuto,
  history,
  isContactUnregistered,
  isPublic,
  isServedFlow,
  item: itemProps,
  me,
  modalOpen,
  modalClose,
  priceMode,
  pricePrecision,
  prodTypes,
  product: productProps,
  sendMessage,
  showBack,
  showDefaultPicture,
  showFeatured,
  showShare,
}) => {
  const [showTraceability, setShowTraceability] = React.useState(false);
  const renderTime = React.useRef(Date.now());
  const [item, setItem] = React.useState<IOrderItem | undefined>(itemProps);
  const [product, setProduct] = React.useState<GenericProduct>(productProps);

  React.useEffect(() => {
    RenderTrack.track('ProductInfo', {
      renderTime: renderTime.current,
      productId: product.productId ? product.productId.toString() : '',
      type: product.type as any,
    });
  }, [product]);

  React.useEffect(() => {
    if (itemProps && (!item || itemProps.id !== item.id)) {
      setItem(itemProps);
    }
  }, [itemProps]);

  React.useEffect(() => {
    if (productProps && (!product || productProps.productId !== product.productId)) {
      setProduct(productProps);
    }
  }, [productProps]);

  const typeVariety = productService.getProductTypeVarietyDisplay(
    product.type,
    prodTypes[product.type] ? prodTypes[product.type].name : '',
    product.variety,
  );

  const getBackMessage = () => {
    if (from === 'product')
      return (
        ' ' +
        (amSeller
          ? __('Components.ProductDetails.back_my')
          : __('Components.ProductDetails.back', { name: contactName }))
      );
    return __('Components.Cart.back_order');
  };

  const handleCartUpdateItem = (updatedItem: IOrderItem, stay?: boolean) => {
    EventTrack.cartAddItem({
      quantity: updatedItem.amount,
      unit: updatedItem.saleUnit,
      price: updatedItem.price,
      sku: updatedItem.sku,
      eanCode: updatedItem.eanCode,
      productId: updatedItem.childId,
      productType: updatedItem.type,
      role: amSeller ? 'seller' : 'buyer',
    });
    cartUpdateItem?.(contactId, updatedItem);
    if (!stay) close();
  };

  return (
    <>
      <S.Container>
        {showBack ? (
          <S.BackContainer>
            <S.Back onClick={close}>
              <S.ChevronIcon name="Back" />
              {backLiteral || getBackMessage()}
            </S.Back>
          </S.BackContainer>
        ) : null}
        <S.Body heightAuto={heightAuto} priceList={from === 'pricelist'} isPublic={isPublic}>
          <ProductInfoContent
            amSeller={amSeller}
            contact={contact}
            contactId={contact?.id}
            contactName={contactName}
            disabled={disabled}
            isPublic={isPublic}
            isContactUnregistered={contact?.isUnregistered}
            catalogId={product.catalogId}
            from="product"
            history={history}
            item={item}
            pricePrecision={constants.PRICE_PRECISION}
            product={product}
            showDefaultPicture={showDefaultPicture}
            showFeatured={showFeatured}
            showShare={showShare}
            typeVariety={typeVariety}
            cartUpdateItem={handleCartUpdateItem}
            showTraceability={() => setShowTraceability(true)}
            sendMessage={sendMessage}
          />
          {item ? (
            <AddToCart
              address={address}
              contactName={contactName}
              isPublic={isPublic}
              isServedFlow={isServedFlow}
              disabled={disabled}
              cartUpdateItem={handleCartUpdateItem}
              deletable={deletable}
              forceSetPrice={!amSeller && isContactUnregistered}
              from={from}
              amSeller={amSeller}
              item={item}
              priceMode={!amSeller && isContactUnregistered ? 'edit' : priceMode}
              pricePrecision={pricePrecision}
              product={product}
              showInIpad={false}
              sendMessage={sendMessage}
              typeVariety={typeVariety}
              modalOpen={modalOpen}
              modalClose={modalClose}
            />
          ) : null}
        </S.Body>
      </S.Container>
      {showTraceability ? (
        <Traceability
          product={product}
          backLiteral={__('ProductEdit.Traceability.back')}
          showBack={true}
          close={() => setShowTraceability(false)}
          company={amSeller ? me.companyName : contact?.companyName || ''}
          myId={me.id}
        />
      ) : null}
    </>
  );
};

export default React.memo(ProductInfoComponent);

import { __, currency } from 'common-services';
import * as React from 'react';

import { ActionsModal } from '../../../../../molecules';
import { useOrderContext } from '../../../context';

import * as S from './PriceModal.styled';

/**
 * PORProduct Component
 *
 * A modal component that allows users to set custom prices for products
 * and optionally update the product's base price.
 *
 * @param props - Component props
 * @returns React component
 */
const PORProduct: React.FC = () => {
  const {
    selectedItem: item,
    pricePrecision,
    closePriceModal: onClose,
    onSubmitPriceModal: onSubmit,
  } = useOrderContext();

  const [price, setPrice] = React.useState<number>(0);
  const [updateProductPrice, setUpdateProductPrice] = React.useState<boolean>(false);

  /**
   * Handles price input changes
   *
   * @param key - Input field key
   * @param value - New price value
   */
  const handlePriceChange = React.useCallback((key: string, value: number) => {
    setPrice(Number(value));
  }, []);

  /**
   * Handles form submission and modal closing
   * Updates the item price and optionally the product base price
   */
  const handleSubmit = React.useCallback(() => {
    onClose();
    onSubmit({ ...item, price }, updateProductPrice);
  }, [item, price, updateProductPrice, onClose, onSubmit]);

  /**
   * Toggles the update product price checkbox
   */
  const toggleUpdateProductPrice = React.useCallback(() => {
    setUpdateProductPrice(prev => !prev);
  }, []);

  // TODO:3 Consider extracting minimum price validation to a constant or configuration
  const minimumPrice = 0.01;
  const isSubmitDisabled = price < minimumPrice;

  return (
    <ActionsModal
      title={__('Components.Cart.por_product.title', { name: item.title })}
      onClose={onClose}
      subtitle={__('Components.Cart.por_product.subtitle')}
      minHeight="300px"
    >
      <S.Container>
        <S.Row paddingMultiplier={3}>
          <S.BigText>{__('Components.Cart.por_product.input_text')}</S.BigText>
          <S.TextInput
            name="price-input"
            type="number"
            precision={pricePrecision}
            onChange={handlePriceChange}
            minValue={currency.getPriceInputMinimumValue(pricePrecision)}
            width="150px"
            textAlign="right"
            variableTextSingular={currency.getPricePerUnit(item.currency, item.priceUnit, item.weightUnit)}
            variableTextPlural={currency.getPricePerUnit(item.currency, item.priceUnit, item.weightUnit)}
          />
        </S.Row>
        <S.Row>
          <S.CheckBox isChecked={updateProductPrice} onClick={toggleUpdateProductPrice} />
          <S.CheckBoxText>{__('Components.Cart.por_product.checkbox')}</S.CheckBoxText>
        </S.Row>
        <S.AcceptButton disabled={isSubmitDisabled} onClick={handleSubmit}>
          {updateProductPrice
            ? __('Components.Cart.por_product.accept_with_checkbox')
            : __('Components.Cart.por_product.accept')}
        </S.AcceptButton>
      </S.Container>
    </ActionsModal>
  );
};

export default React.memo(PORProduct);

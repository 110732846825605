import { CHANNEL_MEMBER_ROLE, CHANNEL_MEMBER_STATUS, ORDER_ORIGIN, __, orderService, utils } from 'common-services';
import { debounce } from 'lodash';
import * as React from 'react';

import config from '../../../../../bindings/config';
import { MAX_FILE_SIZE } from '../../../../constants';
import { convertToIFile } from '../../../../services/file';
import { isElementInViewport } from '../../../../util/utils';

import { ScrollSection } from './useScrollToSection';

import type {
  chatActions,
  orderActions,
  IMessage,
  IChannel,
  IOrder,
  IUser,
  IContact,
  IPriceMode,
  ICart,
  modalActions,
  ORDER_FILE_TYPE,
  IClient,
  IAttachment,
  IFile,
  IMember,
} from 'common-services';

export enum CommentSectionType {
  Messages = 'messages',
  Files = 'files',
  Activity = 'activity',
  All = 'all',
}

interface UseOrderCommentsProps {
  order?: IOrder;
  me: IUser;
  commentsChannel?: IChannel;
  contact?: IContact;
  setCommentsRead: (userId: number, orderId: number) => void;
  getMessages: typeof chatActions.getMessages;
  orderCommentSend: typeof orderActions.orderCommentSend;
  cartUpdateInitialComments: (
    key: string,
    catalogId: number,
    contactId: number,
    comments: Array<IMessage>,
    priceMode?: IPriceMode,
  ) => void;
  cart: ICart;
  lastReadAt?: Record<string, number>;
  lastReadContactAt?: Record<string, number>;
  setCommentsRibbon: (text: string) => void;
  setUpdatesRibbon: (text: string) => void;
  contacts: { [key: number]: IContact };
  client: IClient;
  clients?: Array<IClient>;
  orderAttachmentsGet: typeof orderActions.orderAttachmentsGet;
  orderAttachmentUpload: typeof orderActions.orderAttachmentUpload;
  modalOpen: typeof modalActions.modalOpen;
}

export interface UseOrderCommentsReturn {
  lastMessageAt: number | undefined;
  totalComments: number;
  totalUpdates: number;
  totalAttachments: number;
  addPreviousComment: (message: IMessage) => void;
  sendComment: (text: string) => void;
  handleScroll: () => void;
  unreadsCount: {
    unread_comments?: number;
    unread_updates?: number;
  };
  commentsSection: CommentSectionType;
  setCommentsSection: (section: CommentSectionType) => void;
  attachments: Array<IAttachment>;
  setAttachments: (attachments: Array<IAttachment>) => void;
  comment: string;
  setComment: (comment: string) => void;
  filePreview: IFile | undefined;
  setFilePreview: (file: IFile | undefined) => void;
  isAll: boolean;
  setIsAll: (isAll: boolean) => void;
  messages: Array<IMessage>;
  setMessages: (messages: Array<IMessage>) => void;
  showBannerUnread: number | undefined;
  setShowBannerUnread: (showBannerUnread: number | undefined) => void;
  isCommentsBlocked: boolean;
  getChannelMembers: (messages: Array<IMessage>) => Array<IMember>;
  getSender: (senderId: number) => {
    avatar: string;
    avatarColor: { text: string; background: string };
    name: string;
    companyName: string;
  };
  getMessagesDebounced: (messageId?: number) => void;
  uploadFile: (file: IFile, fileType: ORDER_FILE_TYPE) => void;
  getCommentFiltersOptions: () => Array<{
    id: string;
    label: string;
    action: () => void;
  }>;
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sendFile: (files: Array<File>) => void;
  sendComments: (commentText: string) => void;
  isAutoScrollEnabled: boolean;
  setIsAutoScrollEnabled: (isAutoScrollEnabled: boolean) => void;
  handleMessageVisibility: (messageAt: number) => void;
}

/**
 * Hook to handle order comments functionality
 */
const useOrderComments = ({
  order,
  me,
  commentsChannel,
  contact,
  setCommentsRead,
  getMessages,
  orderCommentSend,
  cartUpdateInitialComments,
  cart,
  lastReadAt,
  lastReadContactAt,
  setCommentsRibbon,
  setUpdatesRibbon,
  contacts,
  client,
  clients,
  orderAttachmentsGet,
  orderAttachmentUpload,
  modalOpen,
}: UseOrderCommentsProps): UseOrderCommentsReturn => {
  // State
  const [lastMessageAt, setLastMessageAt] = React.useState<number>(); // eslint-disable-line
  const scrollTimeout = React.useRef<NodeJS.Timeout>(); // eslint-disable-line
  const [commentsSection, setCommentsSection] = React.useState<CommentSectionType>(CommentSectionType.All);
  const [attachments, setAttachments] = React.useState<Array<IAttachment>>([]);
  // const [categoryFilter, setCategoryFilter] = React.useState<ORDER_FILE_TYPE>();
  const [comment, setComment] = React.useState('');
  const [filePreview, setFilePreview] = React.useState<IFile>();
  const [isAll, setIsAll] = React.useState(true);
  const [messages, setMessages] = React.useState<Array<IMessage>>([]);
  const [showBannerUnread, setShowBannerUnread] = React.useState<number | undefined>(lastReadAt?.[commentsChannel?.id]);
  // State for tracking scroll position and comment reading
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = React.useState(true);
  const [lastVisibleMessageAt, setLastVisibleMessageAt] = React.useState<number | null>(null); // eslint-disable-line

  // Memos
  const totals = React.useMemo(() => commentsChannel?.extraData?.totals || {}, [commentsChannel?.extraData?.totals]);
  const unreads = React.useMemo(() => commentsChannel?.extraData?.unreads || {}, [commentsChannel?.extraData?.unreads]);
  const totalComments = React.useMemo(() => totals.comments || 0, [totals.comments]);
  const totalUpdates = React.useMemo(() => totals.updates || 0, [totals.updates]);
  const totalAttachments = React.useMemo(() => {
    if (!totals.attachments) return 0;
    return Object.values(totals.attachments as { [key: string]: number }).reduce(
      (acc: number, a: number) => acc + a,
      0,
    );
  }, [totals.attachments]);
  const isCommentsBlocked = React.useMemo(() => {
    return contact?.imBlocked || contact?.imBlocking || client?.blockedOrders;
  }, [contact?.imBlocked, contact?.imBlocking, client?.blockedOrders]);

  // Callbacks

  /**
   * Memoize function to avoid recreating it on every render
   */
  const fetchMessages = React.useCallback(
    (channelId: string, userId: number) => {
      getMessages(channelId, userId);
    },
    [getMessages],
  );

  /**
   * Handle scroll with debounce
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = React.useCallback(
    debounce(() => {
      if (commentsChannel && isElementInViewport(ScrollSection.COMMENTS)) {
        setCommentsRead(me.id, order?.id);
      }
    }, 50),
    [commentsChannel, me.id, order?.id, setCommentsRead],
  );

  /**
   * Add a previous comment
   */
  const addPreviousComment = React.useCallback(
    (message: IMessage) => {
      if (!cart) return;
      cartUpdateInitialComments(
        cart.key,
        cart.catalogId,
        cart.contactId,
        [message, ...cart.initialComments],
        cart.priceMode,
      );
    },
    [cart, cartUpdateInitialComments],
  );

  /**
   * Send a new comment
   */
  const sendComment = React.useCallback(
    (text: string) => {
      if (!text.trim() || !order) return;
      const m: IMessage = {
        channelId: 'order_' + order?.id,
        createdAt: new Date().getTime() + 60000,
        extraData: {},
        message: text,
        messageId: 0,
        messageType: 'text' as const,
        reactions: {},
        senderId: me.id,
      };
      orderCommentSend(me.id, order?.id, [m]);
    },
    [me.id, order, orderCommentSend],
  );

  // const removeUnreadBanner = React.useCallback(
  //   debounce(() => {
  //     setShowBannerUnread(undefined);
  //   }, 5000),
  //   [],
  // );

  // Callback for handling message visibility
  const handleMessageVisibility = React.useCallback(
    (messageAt: number) => {
      setLastVisibleMessageAt(messageAt);
      if (isAutoScrollEnabled) {
        // onSetCommentsRead(messageAt);
        // TODO:1 implement this
      }
    },
    [isAutoScrollEnabled],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sendComments = React.useCallback(
    debounce((commentText: string) => {
      if (!commentText.trim()) return;

      const newMessage: IMessage = {
        channelId: 'order_' + order?.id,
        createdAt: new Date().getTime() + 60000,
        extraData: {},
        message: commentText.trim(),
        messageId: 0,
        messageType: 'text' as const,
        reactions: {},
        senderId: me.id,
      };

      if (order?.id) {
        orderCommentSend(me.id, order?.id, [newMessage], (err: Error) => {
          if (!err) {
            setMessages(prev => [newMessage, ...prev]);
            setComment('');
          }
        });
      } else {
        addPreviousComment(newMessage);
        setMessages(prev => [newMessage, ...prev]);
        setComment('');
      }
    }, 500),
    [order?.id, me.id, orderCommentSend, addPreviousComment],
  );

  // Effects
  React.useEffect(
    () => {
      if (order?.commentsChannel) {
        getMessagesDebounced();
      }

      const mockMessage: IMessage = {
        channelId: 'order_' + order?.id,
        createdAt: new Date().getTime() + 60000,
        extraData: {
          code: 'price_disclaimer',
          triggered_by: 123,
          receiver: 456,
        },
        messageId: 0,
        message: 'Mock message',
        messageType: 'admin',
        reactions: {},
        senderId: 1,
      };

      if (order?.id) {
        getAttachmentsDebounced();
      }

      const initialMessages = [
        ...(order?.moreInfo
          ? [
              {
                channelId: '',
                createdAt: new Date(order?.createdAt).getTime(),
                extraData: {},
                message: order?.moreInfo,
                messageId: 0,
                messageType: 'text' as const,
                reactions: {},
                senderId: me.id,
              },
            ]
          : cart?.initialComments || []),
        ...(config.TOGGLE_MO_PRICES_INFO.enabled && order?.origin === ORDER_ORIGIN.IMPORT_UI && mockMessage
          ? [mockMessage]
          : []),
      ];
      setMessages(initialMessages);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [order?.commentsChannel],
  );

  // Helper functions
  const getSender = React.useCallback(
    (
      senderId: number,
    ): {
      avatar: string;
      avatarColor: { text: string; background: string };
      name: string;
      companyName: string;
    } => {
      if (me.id === senderId) {
        return {
          avatar: me.settings.avatar,
          avatarColor: utils.getAvatarColor(me.name),
          name: me.name,
          companyName: me.companyName,
        };
      }

      const sender = contacts[senderId];
      if (sender) {
        return {
          avatar: sender.avatar,
          avatarColor: sender.avatarColor,
          name: sender.name,
          companyName: sender.companyName,
        };
      }

      const senderMember = commentsChannel?.members.find(c => c.id === senderId);
      if (senderMember) {
        return {
          avatar: senderMember.avatar,
          avatarColor: senderMember.avatarColor,
          name: senderMember.name,
          companyName: senderMember.companyName,
        };
      }

      const senderClient = clients?.find(c => c.userId === senderId);
      if (senderClient) {
        return {
          avatar: senderClient.avatar,
          avatarColor: utils.getAvatarColor(senderClient.name),
          name: senderClient.name,
          companyName: senderClient.company || '',
        };
      }

      return {
        avatar: '',
        avatarColor: { text: '', background: '' },
        name: '',
        companyName: '',
      };
    },
    [me, contacts, commentsChannel, clients],
  );

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files && e.target.files.length) {
      if (e.target.files[0].size > MAX_FILE_SIZE) {
        modalOpen(__('Components.Chat.max_size_exceeded', { max: 3 }));
        return;
      }
      sendFile(Array.from(e.target.files));
    }
  };

  const sendFile = (files: Array<File>): void => {
    convertToIFile(files[0], (file: IFile) => setFilePreview(file));
  };

  const uploadFile = (file: IFile, fileType: ORDER_FILE_TYPE): void => {
    const attachment = orderService.emptyAttachment(order?.id, me.id);
    attachment.data = file.content;
    attachment.filename = file.name;
    attachment.mimeType = file.type;
    attachment.size = file.size;
    attachment.type = fileType;

    orderAttachmentUpload(me.id, order?.id, attachment, err => {
      if (!err) {
        setCommentsSection(CommentSectionType.Files);
        setAttachments(prev => [attachment, ...prev]);
      }
    });
    setFilePreview(undefined);
  };

  const getChannelMembers = React.useCallback(
    (messages: Array<IMessage>): Array<IMember> => {
      // Initialize members array with existing channel members
      const members: Array<IMember> = [...(commentsChannel?.members || [])];

      // Add current user to members if not already present
      if (!members.find(m => m.id === me.id)) {
        members.push({
          avatar: me.settings.avatar,
          avatarColor: utils.getAvatarColor(me.name),
          companyName: me.companyName,
          id: me.id,
          name: me.name,
          role: CHANNEL_MEMBER_ROLE.VIEWER,
          status: CHANNEL_MEMBER_STATUS.ACTIVE,
        });
      }

      // Add message senders that aren't already in members list
      messages.forEach(message => {
        const senderId = message.senderId;

        // Skip if member already exists
        if (members.find(m => m.id === senderId)) {
          return;
        }

        // Get sender information using existing getSender function
        const sender = getSender(senderId);

        // Only add member if we have valid sender information
        if (sender.name || sender.companyName) {
          members.push({
            avatar: sender.avatar,
            avatarColor: utils.getAvatarColor(sender.name),
            companyName: sender.companyName,
            id: senderId,
            name: sender.name,
            role: CHANNEL_MEMBER_ROLE.VIEWER,
            status: CHANNEL_MEMBER_STATUS.ACTIVE,
          });
        }
      });

      return members;
    },
    [commentsChannel?.members, me, getSender],
  );

  /**
   * Generates the filter options for comments navigation tabs
   * Memoized to prevent unnecessary recalculations on re-renders
   */
  const getCommentFiltersOptions = React.useCallback(() => {
    // Get totals from channel extra data
    const channelTotals = commentsChannel?.extraData?.totals;

    // Calculate total counts
    const totalComments = channelTotals?.comments || 0;
    const totalUpdates = channelTotals?.updates || 0;

    // Calculate total attachments by summing all attachment types
    const totalAttachments = channelTotals?.attachments
      ? Object.values(channelTotals.attachments).reduce((acc: number, count: number) => acc + count, 0)
      : 0;

    // Calculate grand total for "All" tab
    const grandTotal = totalComments + totalUpdates + totalAttachments;

    return [
      {
        id: 'all',
        label: `${__('Messages.Comments.all')} (${grandTotal})`,
        action: () => setCommentsSection(CommentSectionType.All),
      },
      {
        id: 'messages',
        label: `${__('Messages.Comments.messages')} (${totalComments})`,
        action: () => setCommentsSection(CommentSectionType.Messages),
      },
      {
        id: 'files',
        label: `${__('Messages.Comments.files')} (${totalAttachments})`,
        action: () => setCommentsSection(CommentSectionType.Files),
      },
      {
        id: 'activity',
        label: `${__('Messages.Comments.activity')} (${totalUpdates})`,
        action: () => setCommentsSection(CommentSectionType.Activity),
      },
    ];
  }, [commentsChannel?.extraData?.totals, setCommentsSection]);

  const addMessages = React.useCallback(
    (newIsAll: boolean, newMessages: Array<IMessage>) => {
      if (newMessages?.length) {
        handleMessageVisibility(newMessages[0].createdAt);
      }

      setMessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        newMessages?.forEach(m => {
          const idx = updatedMessages.findIndex(
            item => m.messageId === item.messageId || (item.messageId === 0 && item.message === m.message),
          );
          if (idx !== -1) {
            updatedMessages[idx] = m;
          } else {
            if (updatedMessages.length && m.createdAt < updatedMessages[updatedMessages.length - 1].createdAt) {
              updatedMessages.push(m);
            } else {
              updatedMessages.unshift(m);
            }
          }
        });
        return updatedMessages;
      });

      setIsAll(newIsAll);
    },
    [handleMessageVisibility],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getMessagesDebounced = React.useCallback(
    debounce((messageId?: number) => {
      if (order?.commentsChannel) {
        getMessages(order?.commentsChannel, me.id, messageId, addMessages);
      }
    }, 250),
    [order?.commentsChannel, me.id, getMessages],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getAttachmentsDebounced = React.useCallback(
    debounce((showAttachments?: boolean) => {
      orderAttachmentsGet(me.id, order?.id, newAttachments => {
        setAttachments(newAttachments);
        if (showAttachments) {
          setCommentsSection(CommentSectionType.Files);
        }
      });
    }, 250),
    [me.id, order?.id, orderAttachmentsGet],
  );

  // Effects

  /**
   * Update ribbons when unreads change
   */
  React.useEffect(() => {
    if (!commentsChannel) return;

    // Update ribbons
    if (unreads?.length) {
      // Set appropriate ribbon text
      if (unreads.unread_updates) {
        setUpdatesRibbon(
          __('Components.Cart.unread_updates', {
            count: unreads.unread_updates,
          }),
        );
      } else if (unreads.unread_comments) {
        setCommentsRibbon(
          __('Components.Cart.unread_comments', {
            count: unreads.unread_comments,
          }),
        );
      }
    }
  }, [
    commentsChannel,
    contact,
    me.id,
    lastReadAt,
    lastReadContactAt,
    unreads.unread_comments,
    unreads.unread_updates,
    setUpdatesRibbon,
    setCommentsRibbon,
    unreads.length,
  ]);

  /**
   * Memoize channel ID to prevent unnecessary effect triggers
   */
  const channelId = React.useMemo(() => commentsChannel?.id, [commentsChannel?.id]);

  // Cache the channel ID and me.id as a key for the effect
  const key = React.useRef(channelId + `-${me.id}`);

  /**
   * Load messages when channel changes
   * Add channelId dependency instead of whole commentsChannel object
   */
  React.useEffect(() => {
    if (channelId && me.id) {
      if (key.current !== channelId + me.id) {
        key.current = channelId + me.id;
        fetchMessages(channelId, me.id);
      }
    }
  }, [key, channelId, me.id, fetchMessages]);

  /**
   * Add scroll listener
   */
  React.useEffect(() => {
    const current = scrollTimeout.current;
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
      if (current) {
        clearTimeout(current);
      }
    };
  }, [handleScroll]);

  // React.useEffect(() => {
  //   if (lastVisibleMessageAt && isAutoScrollEnabled) {
  //     // onSetCommentsRead(lastVisibleMessageAt);
  // TODO:1 check this implementation
  //   }
  // }, [lastVisibleMessageAt, isAutoScrollEnabled, onSetCommentsRead]);

  return {
    lastMessageAt,
    totalComments,
    totalUpdates,
    totalAttachments,
    addPreviousComment,
    sendComment,
    handleScroll,
    unreadsCount: {
      unread_comments: unreads.unread_comments,
      unread_updates: unreads.unread_updates,
    },
    commentsSection,
    setCommentsSection,
    attachments,
    setAttachments,
    comment,
    setComment,
    filePreview,
    setFilePreview,
    isAll,
    setIsAll,
    messages,
    setMessages,
    showBannerUnread,
    setShowBannerUnread,
    isCommentsBlocked,
    getChannelMembers,
    getSender,
    getMessagesDebounced,
    uploadFile,
    getCommentFiltersOptions,
    handleFileUpload,
    sendFile,
    sendComments,
    isAutoScrollEnabled,
    setIsAutoScrollEnabled,
    handleMessageVisibility,
  };
};

export default useOrderComments;

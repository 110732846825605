import { __, currency, orderService } from 'common-services';
import * as React from 'react';

import config from '../../../../../../../../../bindings/config';
import { priceUnitTranslate } from '../../../../../../../../util/utils';
import { Tooltip } from '../../../../../../../atoms';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'cost',
  rank: 1002,
  isVisible: true,
  getTitle: () => __('Components.Cart.items.cost'),
  minWidth: '70px',
  width: '8%',
  condition: ({ isQuoterMode }) => isQuoterMode,
  columnValue: ({ order, pricePrecision }) => ({
    getElement: item => {
      const { totalCost } = item;
      const totalItemCost = orderService.getTotalItemCost(item);
      return (
        <S.ProductPriceRow>
          {totalCost ? (
            <S.TooltipContainer>
              <Tooltip position="top" text={__('Components.Cart.tooltip_cost')} themeMode="dark" width="200px">
                <S.InfoIcon disableHover={true} name="Info" />
              </Tooltip>
            </S.TooltipContainer>
          ) : null}
          <S.PriceColumn>
            <S.ItemPrice>
              {totalCost
                ? currency.getPricePerUnit(
                    item.currency,
                    config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId)
                      ? priceUnitTranslate(item.priceUnit)
                      : item.priceUnit,
                    item.weightUnit,
                    totalCost,
                    pricePrecision,
                  )
                : '-'}
            </S.ItemPrice>
            {totalItemCost ? (
              <S.ItemPrice>{currency.getPrice(item.currency, totalItemCost, pricePrecision)}</S.ItemPrice>
            ) : null}
          </S.PriceColumn>
        </S.ProductPriceRow>
      );
    },
  }),
};

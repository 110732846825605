import { __, constants, debounce, orderService } from 'common-services';
import * as React from 'react';

import { Input, RowContainer, Select, TextArea } from '../../../../../../atoms';
import { FormModal } from '../../../../../../molecules';

import * as S from './CustomItems.styled';

import type { CURRENCY_CODES, IOrder, ICustomItem } from 'common-services';

interface AddCustomItemModalProps {
  currency: CURRENCY_CODES;
  customItem?: ICustomItem;
  onClose: () => void;
  onSubmit: (description: string, cost: number) => void;
  order: IOrder;
  pricePrecision: number;
}

export const AddCustomItemModal: React.FC<AddCustomItemModalProps> = React.memo(
  ({ currency, customItem, onClose, onSubmit, order, pricePrecision }) => {
    const [cost, setCost] = React.useState<number | undefined>(customItem ? Math.abs(customItem.price) : undefined);
    const [isDiscount, setIsDiscount] = React.useState<boolean>(customItem ? customItem.price < 0 : true);
    const [description, setDescription] = React.useState<string>(customItem ? customItem.title : '');

    // TODO:3 Consider moving this calculation to a utility function
    let maxDiscountValue = orderService.getTotalPrice(order) - 0.01;
    if (isDiscount && customItem) {
      maxDiscountValue -= customItem.price;
    }

    const setCostWithCheck = (desiredCost: number): void => {
      let costToSet = desiredCost;
      if (!costToSet) {
        costToSet = 0.01;
      }
      if (isDiscount && costToSet > maxDiscountValue) {
        costToSet = maxDiscountValue;
      }
      setCost(costToSet);
    };

    return (
      <FormModal
        className="custom-line-form-modal"
        ctaText={customItem ? __('AddCustomLineModal.cta_edit') : __('AddCustomLineModal.cta_add')}
        ctaDisabled={!description || !cost || Number.isNaN(cost) || (isDiscount && cost > maxDiscountValue)}
        onClose={onClose}
        onSubmit={() => onSubmit(description, (isDiscount ? -1 : 1) * cost)}
        subtitle={customItem ? undefined : __('AddCustomLineModal.subtitle')}
        title={customItem ? __('AddCustomLineModal.title_edit') : __('AddCustomLineModal.title_add')}
      >
        <S.FormContainer>
          <S.InputWithLabel label={__('AddCustomLineModal.description')} isRequired={true}>
            <TextArea
              maxLength={80}
              name="custom-item-description"
              value={description}
              placeholder={__('AddCustomLineModal.description_placeholder')}
              onChange={(name, val) => setDescription(val + '')}
              width="90%"
              rows={5}
            />
          </S.InputWithLabel>
          <S.InputWithLabel label={__('AddCustomLineModal.cost')} isRequired={true}>
            <RowContainer>
              <Select
                name="custom-item-cost-type"
                value={isDiscount ? 'discount' : 'markup'}
                onChange={(name, val) => setIsDiscount(val === 'discount')}
                options={[
                  { value: 'discount', label: __('AddCustomLineModal.cost_options.discount') },
                  { value: 'markup', label: __('AddCustomLineModal.cost_options.markup') },
                ]}
                containerMargin="0 12px 0 0"
                width="150px"
              />
              <Input
                name="custom-item-cost"
                type="number"
                onChange={debounce((_, value) => {
                  setCostWithCheck(value ? Number(value) : 0);
                }, 1500)}
                value={cost}
                minValue={0.01}
                maxValue={isDiscount ? maxDiscountValue : undefined}
                variableTextSingular={constants.CURRENCIES[currency]?.symbol || '€'}
                precision={pricePrecision}
                startText={isDiscount ? '-' : '+'}
                containerMargin="0"
                width="100px"
                onBlur={(_, value) => setCostWithCheck(!Number.isNaN(value) ? Number(value) : 0.01)}
              />
            </RowContainer>
          </S.InputWithLabel>
        </S.FormContainer>
      </FormModal>
    );
  },
);

AddCustomItemModal.displayName = 'AddCustomItemModal';

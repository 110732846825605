import * as React from 'react';

import type { ItemWithIssue } from './hooksTypes';
import type { IOrder, IOrderItem, IProduct } from 'common-services';

export interface UseOrderStateReturn {
  selectedItem?: IOrderItem;
  setSelectedItem: (item?: IOrderItem) => void;
  itemWithIssue: ItemWithIssue;
  setItemWithIssue: (item?: ItemWithIssue) => void;
  productAttributeValue?: unknown;
  setProductAttributeValue: (value: unknown) => void;
  productList: Array<IProduct>;
  setProductList: (products: Array<IProduct>) => void;
  showNewMbppValueInput: boolean;
  setShowNewMbppValueInput: (show: boolean) => void;
  priceEdit: boolean;
  setPriceEdit: (value: boolean) => void;
  discardForever: boolean;
  setDiscardForever: (value: boolean) => void;
  canEditAfterAccept: boolean;
  setCanEditAfterAccept: (value: boolean) => void;
  previousOrder: IOrder | null;
  setPreviousOrder: (value: IOrder | null) => void;
  newExternalId?: string;
  setNewExternalId: (value: string) => void;
  newOrExistingLot?: string;
  setNewOrExistingLot: (value: string) => void;
}

const useOrderState = (): UseOrderStateReturn => {
  const [canEditAfterAccept, setCanEditAfterAccept] = React.useState(false);
  const [previousOrder, setPreviousOrder] = React.useState<IOrder | null>(null);
  const [selectedItem, setSelectedItem] = React.useState<IOrderItem>();
  const [itemWithIssue, setItemWithIssue] = React.useState<ItemWithIssue>();
  const [productAttributeValue, setProductAttributeValue] = React.useState<unknown>();
  const [productList, setProductList] = React.useState<Array<IProduct>>([]);
  const [showNewMbppValueInput, setShowNewMbppValueInput] = React.useState(false);
  const [priceEdit, setPriceEdit] = React.useState<boolean>(false);
  const [discardForever, setDiscardForever] = React.useState(false);
  const [newExternalId, setNewExternalId] = React.useState<string>();
  const [newOrExistingLot, setNewOrExistingLot] = React.useState<string>();
  return {
    canEditAfterAccept,
    setCanEditAfterAccept,
    previousOrder,
    setPreviousOrder,
    selectedItem,
    setSelectedItem,
    itemWithIssue,
    setItemWithIssue,
    productAttributeValue,
    setProductAttributeValue,
    productList,
    setProductList,
    showNewMbppValueInput,
    setShowNewMbppValueInput,
    priceEdit,
    setPriceEdit,
    discardForever,
    setDiscardForever,
    newExternalId,
    setNewExternalId,
    newOrExistingLot,
    setNewOrExistingLot,
  };
};

export default useOrderState;

import styled, { css, ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer, TextLabel, Input as IP } from '../../atoms';

interface IStyledProps {
  hasComment?: boolean;
  hasChanges?: boolean;
  theme: ITheme;
}

export const FlexColumn = styled(ColumnContainer)``;

const Text = styled(TextLabel)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
`;

export const FlexRow = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  position: relative;
  max-width: 250px;
`;

export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;
export const TextSmall = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const CommentIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  height: 13px;
  width: 13px;
  color: ${(p: IStyledProps) => (p.hasComment ? p.theme.colors.blue1 : p.theme.colors.grey2)};
  cursor: pointer;
`;
//
export const CommentBox = styled.div`
  position: absolute;
  overflow: auto;
  left: 230px;
  top: 40px;
  border-radius: 8px;
  width: 180px;
  height: 95px;
  z-index: 100;
`;
export const IconsRow = styled(RowContainer)`
  position: absolute;
  top: 57px;
  left: 140px;
  align-items: center;
  justify-content: end;
  width: 40px;
`;
export const Input = styled(IP)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const RemoveIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.red1};
  font-size: 15px;
  height: 15px;
  width: 15px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
`;
export const SaveIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.white};
  background-color: ${(p: IStyledProps) => p.theme.colors.green1};
  height: 15px;
  width: 15px;

  padding: ${(p: IStyledProps) => p.theme.paddingSize()};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  border-radius: 3px;

  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  ${(p: IStyledProps) =>
    !p.hasChanges &&
    css`
      filter: opacity(0.5);
    `}
`;

import { __, currency, orderService, orderUtils } from 'common-services';
import * as React from 'react';

import config from '../../../../../../../../../bindings/config';
import { calculatePriceServedUnit } from '../../../../../../../../domain/order';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'price',
  rank: 1004,
  isVisible: true,
  getTitle: () => __('Components.Cart.items.price'),
  onClick: e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  },
  minWidth: '120px',
  width: '10%',
  condition: ({ isPrepare }) => !isPrepare,
  columnValue: ({ order, priceMode, pricePrecision }) => ({
    getElement: item => {
      const issuesRelated = order?.issues.filter(issue => issue.orderItemId === item.id);
      const hasAnyIssue = issuesRelated.length > 0;
      const price = issuesRelated.length > 0 ? 0 : orderService.getTotalPriceItem(item);
      const boxesPerPallet = item.boxesPerPallet;

      const hasNotFoundIssue = !!issuesRelated?.find(i =>
        ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
      );
      const isDiscarded = item.servedQuantity === 0;
      return (
        <S.ItemPriceTotal
          isCancelled={orderUtils.isCanceled(order)}
          showGrey={isDiscarded || hasNotFoundIssue || orderUtils.isCanceled(order)}
        >
          {hasAnyIssue // TODO:1 confirm if we want to show the price when there are issues
            ? '-'
            : config.TOGGLE_ORDER_PRICE_UNITS.enabled
            ? priceMode !== 'none' && calculatePriceServedUnit(item, boxesPerPallet)
              ? currency.getPrice(item.currency, calculatePriceServedUnit(item, boxesPerPallet), pricePrecision)
              : '-'
            : priceMode !== 'none' && price
            ? currency.getPrice(item.currency, price, pricePrecision)
            : '-'}
        </S.ItemPriceTotal>
      );
    },
  }),
};

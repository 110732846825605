import styled from '../../../../../../styled-components';
import { ColumnContainer, TextLabel } from '../../../../../atoms';

export const IssuesContainer = styled(ColumnContainer)`
  width: 100%;
  gap: ${props => props.theme.paddingSize(2)};
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const IssueText = styled(TextLabel)`
  font-size: ${props => props.theme.fontSize.normal};
  color: ${props => props.theme.colors.black};
  font-weight: ${props => props.theme.fontWeight.Regular};
  line-height: 0.5;
  width: 100%;
`;

export const TextBold = styled(IssueText)`
  font-weight: ${props => props.theme.fontWeight.Bold};
  display: inline;
`;

// For different status types
export const WarningText = styled(IssueText)`
  color: ${props => props.theme.colors.orange1};
`;

export const ErrorText = styled(IssueText)`
  color: ${props => props.theme.colors.red1};
`;

export const InfoText = styled(IssueText)`
  color: ${props => props.theme.colors.blue1};
`;

// Container for multiple items
export const IssueGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.paddingSize(1)};

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.paddingSize(2)};
  }
`;

// For issue counts
export const IssueCount = styled.span`
  font-weight: ${props => props.theme.fontWeight.Bold};
  margin: 0 ${props => props.theme.paddingSize(1)};
`;

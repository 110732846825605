import styled, { css, ITheme } from '../../../styled-components';
import { lightenDarkenColor, rgbaToHex } from '../../../util/utils';
import { RowContainer } from '../FlexContainer';
import FontIcon from '../FontIcon/FontIcon.component';
import TextLabel from '../TextLabel/TextLabel.styled';
import { BubbleColor } from './TagBubble.component';

interface IStyledProps {
  disabled?: boolean;
  theme: ITheme;
  color?: BubbleColor;
}

export const Container = styled(RowContainer)<IStyledProps>`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  padding-left: 8px;
  background-color: ${getBackgroundColor};
  align-items: center;
  justify-content: center;
  width: max-content;
  ${(p: IStyledProps) =>
    p.disabled &&
    css`
      padding-right: 3px;
    `}
`;

function getBackgroundColor(p: IStyledProps): string {
  let backgrounColor: string;
  switch (p.color) {
    case 'pending':
      backgrounColor = p.theme.colors.purple2;
      break;
    case 'accepted':
      backgrounColor = p.theme.colors.green2;
      break;
    case 'progress':
      backgrounColor = p.theme.colors.green3;
      break;
    case 'incomplete':
      backgrounColor = p.theme.colors.blue2;
      break;
    case 'draft':
      backgrounColor = p.theme.colors.orange2;
      break;
    case 'canceled':
      return p.theme.colors.grey3;
    case 'confirmed':
      return p.theme.colors.blue2;
    case 'error':
      return p.theme.colors.red2;
    case 'sent':
      return p.theme.colors.green1.replace('1)', '0.8)');
    default:
      return p.theme.colors.grey3;
  }
  return backgrounColor.replace('1)', '0.5)');
}

export const Label = styled(TextLabel)`
  color: ${getTextColor};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  padding: 3px 5px;
  padding-left: 0;
  white-space: nowrap;
`;

function getTextColor(p: IStyledProps): string {
  let textColor: string;
  switch (p.color) {
    case 'pending':
      textColor = p.theme.colors.purple1;
      break;
    case 'accepted':
      textColor = p.theme.colors.green1;
      break;
    case 'incomplete':
      textColor = p.theme.colors.blue1;
      break;
    case 'progress':
      textColor = p.theme.colors.white;
      break;
    case 'draft':
      textColor = p.theme.colors.orange1;
      break;
    case 'canceled':
      return p.theme.colors.grey1;
    case 'error':
      return p.theme.colors.black;
    case 'confirmed':
      return p.theme.colors.blue1;
    case 'sent':
      return p.theme.colors.white;
    default:
      return p.theme.colors.grey2;
  }
  return lightenDarkenColor(rgbaToHex(textColor), -25);
}

export const DeleteContainer = styled.div`
  padding: 3px 8px;
  height: 100%;
  cursor: pointer;
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  border-top-right-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  border-bottom-right-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  transform: all 0.6s;

  &:hover {
    background-color: ${(p: IStyledProps) => p.theme.colors.grey2.replace('1)', '0.6)')};
    color: ${(p: IStyledProps) => p.theme.colors.white};
  }
`;

export const DeleteIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
`;

function getBubbleColor(p: IStyledProps): string {
  switch (p.color) {
    case 'pending':
      return p.theme.colors.purple1;
    case 'accepted':
      return p.theme.colors.green1;
    case 'progress':
      return p.theme.colors.green3;
    case 'incomplete':
      return p.theme.colors.blue1;
    case 'canceled':
      return p.theme.colors.grey1;
    case 'error':
      return p.theme.colors.red1;
    case 'confirmed':
      return p.theme.colors.blue1;
    default:
      return p.theme.colors.grey2;
  }
}

export const Bubble = styled.div<IStyledProps>`
  width: ${(p: IStyledProps) => p.theme.fontSize.tiny};
  height: ${(p: IStyledProps) => p.theme.fontSize.tiny};
  border-radius: 50%;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  background-color: ${getBubbleColor};
`;

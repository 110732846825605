import { __, orderBuyerPreparationActions } from 'common-services';
import * as React from 'react';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import OrderCard from '../../OrderPreparationCarts/OrderPreparationCarts.component';
import { OrderPreparationContext } from '../OrderPreparationContext';

import * as S from './OrderPreparationCard.styled';

import type { IPaginatedOrderBuyerPreparationCart } from 'common-services';
import type { Dispatch } from 'redux';
import { EmptyListResource } from '../../../molecules';
import { IMAGES } from '../../../../assets';

interface IProps {
  workspaceId: number;
  deliveryDate: string;
  changeTab: () => void;
}

const OrderPreparationCart: React.FC<IProps> = ({ workspaceId, deliveryDate, changeTab }) => {
  const dispatch = useDispatch<Dispatch<any>>(); // eslint-disable-line @typescript-eslint/no-explicit-any
  const { orderBuyerPreparationId } = useContext(OrderPreparationContext);
  const [cartLines, setCartLines] = React.useState<IPaginatedOrderBuyerPreparationCart>(null);

  React.useEffect(() => {
    if (workspaceId && orderBuyerPreparationId) {
      dispatch(
        orderBuyerPreparationActions.orderBuyerPreparationCartList(
          workspaceId,
          orderBuyerPreparationId,
          '',
          response => {
            setCartLines(response);
          },
        ),
      );
    }
  }, [workspaceId, orderBuyerPreparationId, dispatch]);

  if (!cartLines) {
    return null;
  }

  return (
    <S.Container style={{ backgroundColor: '#fff' }}>
      {cartLines.elements.length > 0 &&
      cartLines.elements.some(cartData => cartData.lines.some(line => line.orderedQuantity > 0)) ? (
        cartLines.elements.map(cartData => (
          <S.ItemsPanel key={`${cartData.buyerWarehouse.id}-${cartData.seller.id}`} className="group-row">
            {cartData.lines.some(line => line.orderedQuantity > 0) && (
              <OrderCard workspaceId={workspaceId} cart={cartData} deliveryDate={deliveryDate} changeTab={changeTab} />
            )}
          </S.ItemsPanel>
        ))
      ) : (
        <S.CenterContainer>
          <EmptyListResource
            text={__('Components.OffersList.order_preparation.zero_case_title')}
            text2={__('Components.OffersList.order_preparation.zero_case_text')}
            imageUrl="https://media.consentio.co/image/upload/v1641830463/ilustrations/Wholesale.png"
            showButton={false}
          />
        </S.CenterContainer>
      )}
    </S.Container>
  );
};

export default OrderPreparationCart;

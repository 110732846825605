import { __ } from 'common-services';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE_PATHS } from '../../../../../../../../../constants';
import getPath from '../../../../../../../../../util/routes';
import { FontIcon, Switch, Tooltip } from '../../../../../../../../atoms';

import * as S from './Header.styled';

import type { IOrder, IUser, IWorkspace } from 'common-services';

export interface HeaderProps {
  /** me */
  me: IUser;
  /** Sort options for dropdown */
  sortOptions: Array<{ key: string; value: string }>;
  /** Currently selected sort option label */
  selectedSortLabel: string;
  /** Callback when sort option changes */
  onSortChange: (value: string) => void;
  /** Whether user has workspace editor permissions */
  amWorkspaceEditor: boolean;
  /** Whether component is in preview mode */
  previewMode?: boolean;
  /** Whether user has editor permissions */
  amEditor: boolean;
  /** Whether order is editable */
  orderEditable: boolean;
  /** Current order */
  order: IOrder;
  /** Whether user is seller */
  weAreSeller: boolean;
  /** Flag for empty prices */
  anyPriceEmpty: boolean;
  /** Whether order is self-made */
  isSelf: boolean;
  /** Current workspace */
  catalog?: IWorkspace;
  /** Callback for favorite check change */
  setIsFavoriteCheck: (checked: boolean) => void;
  /** Current favorite check state */
  isFavoriteCheck: boolean;
}

/**
 * Header component displays sorting options and workspace controls
 */
export const Header: React.FC<HeaderProps> = React.memo(
  ({
    me,
    sortOptions,
    selectedSortLabel,
    onSortChange,
    amWorkspaceEditor,
    previewMode,
    amEditor,
    orderEditable,
    order,
    weAreSeller,
    anyPriceEmpty,
    isSelf,
    catalog,
    setIsFavoriteCheck,
    isFavoriteCheck,
  }) => {
    const history = useHistory();

    /**
     * Handle click on tooltip links
     */
    const handleTooltipClick = React.useCallback(
      (index: number) => {
        if (!amWorkspaceEditor && index === 1) {
          // Navigate to colleagues page
          history.push(
            getPath({
              path: weAreSeller ? ROUTE_PATHS.WORKSPACE_COLLEAGUES_SELLER : ROUTE_PATHS.WORKSPACE_COLLEAGUES_BUYER,
              workspaceId: catalog?.id + '',
            }),
          );
        } else {
          // Navigate to settings page
          history.push(
            getPath({
              path: weAreSeller ? ROUTE_PATHS.WORKSPACE_SETTINGS_SELLER : ROUTE_PATHS.WORKSPACE_SETTINGS_BUYER,
              workspaceId: catalog?.id + '',
              tab: weAreSeller ? 'order' : 'pdf',
            }),
          );
        }
      },
      [amWorkspaceEditor, weAreSeller, catalog?.id, history],
    );

    /**
     * Render favorite switch section
     */
    const renderFavoriteSwitch = (): JSX.Element | null => {
      if (
        !amEditor ||
        !orderEditable ||
        order?.sellerId === me?.id ||
        weAreSeller ||
        anyPriceEmpty ||
        ((order?.changedLogistics || order?.hasChanges || order?.changedItems) && order?.id) ||
        isSelf
      ) {
        return null;
      }

      return (
        <S.FavoriteContainer>
          <S.Text>{__('Components.OrderDetails.favorite_confirm')}</S.Text>
          <Switch
            onChange={(_, checked: boolean) => {
              setIsFavoriteCheck(checked);
            }}
            isChecked={isFavoriteCheck}
            selectable={false}
            containerMargin="0 0 0 20px"
          />
        </S.FavoriteContainer>
      );
    };

    return (
      <S.TableHeadWrapper>
        <S.LeftRow>
          {/* Sort Dropdown */}
          <S.SortByDropdown options={sortOptions} hAlign="left" onSelect={onSortChange} data-testid="sort-dropdown">
            <S.SortButton type="skip" iconName="Sort" iconSize="15px">
              <S.SortBlackText>{__('Components.OrderDetails.sort_products.title')}: </S.SortBlackText>
              <S.SortGreyText>{selectedSortLabel}</S.SortGreyText>
              <FontIcon name="Down" />
            </S.SortButton>
          </S.SortByDropdown>

          {/* Info Tooltip */}
          <Tooltip
            position="right"
            onLinkClick={handleTooltipClick}
            text={
              amWorkspaceEditor
                ? __('Components.OrderDetails.tooltip.edit_text')
                : __('Components.OrderDetails.tooltip.read_text')
            }
            width="300px"
            themeMode="dark"
            data-testid="info-tooltip"
          >
            {previewMode ? null : <S.InfoIcon disableHover={true} name="Info" />}
          </Tooltip>

          {/* Favorite Switch */}
          {renderFavoriteSwitch()}
        </S.LeftRow>
      </S.TableHeadWrapper>
    );
  },
);

Header.displayName = 'Header';

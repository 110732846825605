import { userSelectors } from 'common-services';
import * as React from 'react';
import { useSelector } from 'react-redux';

import config from '../../../../../../../../../bindings/config';

import { useHandlers } from './useHandlers';
import { useProductList } from './useProductList';
import { useRenders } from './useRenders';

import type { ColumnSpec } from '../../../../../../../molecules/Table';
import type { ColumnOptions } from '../../../../../../../molecules/Table/columns.types';
import type { OrderContextValue } from '../../../../../context/OrderContext.types';
import type { ColumnConditionProps, ColumnOptionsProps, ColumnTitleProps } from '../columns/_props';
import type { IOrderItem } from 'common-services';

// Define the column spec type
export type OrderItemColumnSpec = ColumnSpec<ColumnOptions, ColumnTitleProps, ColumnConditionProps, ColumnOptionsProps>;

interface UseColumnPropsReturn {
  columnProps: ColumnOptionsProps;
  titleProps: ColumnTitleProps;
  conditionProps: ColumnConditionProps;
}

export const useColumnProps = (useOrderContext: () => OrderContextValue): UseColumnPropsReturn => {
  const togglePriceMismatchEnable = useSelector(userSelectors.hasToggleEnabled(config.TOGGLE_PRICE_MISMATCH));

  const {
    showSelectWorkspaceModal,
    setShowSelectWorkspaceModal,
    newOrExistingLot,
    setNewOrExistingLot,
    discardLine,
    priceToShow,
    onEnableItem,
    openSearchProductsModal,
    me,
    order,
    cart,
    catalogs,
    catalog,
    contacts,
    priceMode,
    previewMode,
    pricePrecision,
    priceEdit,
    setPriceEdit,
    weAreSeller,
    amSeller,
    isPrepare,
    amEditor,
    canEditAfterAccept: editAfterAccept,
    prodTypes,
    openPriceModal,
    openIssuesModal,
    openBoxesPerPalletConfirmationModal: openBppConfirmationModal,
    openBoxesPerPalletModal,
    orderItemRecover,
    orderUpdatePrepared,
    orderItemStopDiscarding,
    modalOpen,
    touchImage,
    productGetByHashPublic,
    handleItemUpdate: onUpdateItem,
    updateItemPrice,
    servedFlowEnabled,
    isItemDeletable,
    isDeletable,
    showServedColumn,
    isPendingOrder,
    canServeOrder,
    isPrepared,
    isQuoterMode,
    isBuyerOfferingView,
  } = useOrderContext();

  const productList = useProductList({
    items: cart.items,
    catalog,
    order,
    productGetByHashPublic,
    onUpdateItem,
  });

  const { renderArrowPercentage, renderSelectWorkspaceModal, renderTooltip } = useRenders({
    me,
    catalogs,
    contacts,
    newOrExistingLot,
    setShowSelectWorkspaceModal,
    openSearchProductsModal,
  });

  const { handleLotOptions } = useHandlers({
    catalogs,
    setShowSelectWorkspaceModal,
    setNewOrExistingLot,
    openSearchProductsModal,
  });

  const columnProps = React.useMemo(
    () => ({
      me,
      order,
      catalog,
      productList,
      prodTypes,
      pricePrecision,
      priceMode,
      togglePriceMismatchEnable,
      priceEdit,
      isItemDeletable,
      isDeletable,
      showServedColumn,
      amEditor,
      weAreSeller,
      editAfterAccept,
      amSeller,
      isPendingOrder,
      servedFlowEnabled,
      canServeOrder,
      setPriceEdit,
      priceToShow,
      onUpdateItem,
      updateItemPrice,
      discardLine,
      showProductsModal: (item: IOrderItem, e?: React.MouseEvent, originLot?: number, mappingCode?: string) => {
        e.stopPropagation();
        openSearchProductsModal(item, originLot, mappingCode);
      },
      onEnableItem,
      openIssuesModal,
      showSelectWorkspaceModal,
      handleLotOptions,
      renderSelectWorkspaceModal,
      openBppConfirmationModal,
      openBoxesPerPalletModal,
      openPriceModal,
      renderArrowPercentage,
      renderTooltip,
      orderItemRecover,
      orderItemStopDiscarding,
      orderUpdatePrepared,
      modalOpen,
      touchImage,
      isBuyerOfferingView,
    }),
    [
      me,
      order,
      catalog,
      productList,
      prodTypes,
      pricePrecision,
      priceMode,
      togglePriceMismatchEnable,
      priceEdit,
      isItemDeletable,
      isDeletable,
      showServedColumn,
      amEditor,
      weAreSeller,
      editAfterAccept,
      amSeller,
      isPendingOrder,
      servedFlowEnabled,
      canServeOrder,
      setPriceEdit,
      priceToShow,
      discardLine,
      onUpdateItem,
      updateItemPrice,
      openSearchProductsModal,
      onEnableItem,
      openIssuesModal,
      showSelectWorkspaceModal,
      handleLotOptions,
      renderSelectWorkspaceModal,
      openBppConfirmationModal,
      openBoxesPerPalletModal,
      openPriceModal,
      renderArrowPercentage,
      renderTooltip,
      orderItemRecover,
      orderItemStopDiscarding,
      orderUpdatePrepared,
      modalOpen,
      touchImage,
      isBuyerOfferingView,
    ],
  );

  const titleProps = {
    isQuoterMode,
  };
  const conditionProps = {
    order,
    isItemDeletable,
    isPrepared,
    isPrepare,
    weAreSeller,
    previewMode,
    showServedColumn,
    isQuoterMode,
    editAfterAccept,
  };

  return {
    columnProps,
    titleProps,
    conditionProps,
  };
};

import { debounce } from 'lodash';
import * as React from 'react';

import { api } from '../../../../store';

import type {
  buyerWorkspaceActions,
  orderActions,
  sellerWorkspaceActions,
  IAddress,
  IOrder,
  IOrderItem,
  IUser,
} from 'common-services';

interface UseOrderActionsProps {
  me: IUser;
  order?: IOrder;
  contactId: number;
  clientAddressAdd: typeof sellerWorkspaceActions.clientAddressAdd;
  initializeOrder: (order?: IOrder) => void;
  setLogisticAddresses: (addresses: Array<IAddress>) => void;
  setPickupAddresses: (addresses: Array<IAddress>) => void;
  orderItemFixIssue: typeof orderActions.orderItemFixIssue;
  workspaceAddressAdd: typeof buyerWorkspaceActions.workspaceAddressAdd;
  orderFixIssue: typeof orderActions.orderFixIssue;
  delay?: number;
}

export interface UseOrderActionsReturn {
  addDeliveryAddress: (addr: IAddress, cb: (addr: IAddress) => void) => void;
  addPickupAddress: (addr: IAddress, cb: (addr: IAddress) => void) => void;
  debouncedOrderItemFixIssue: typeof orderActions.orderItemFixIssue;
  debouncedOrderFixIssue: typeof orderActions.orderFixIssue;
}

const useOrderActions = ({
  me,
  order,
  contactId,
  clientAddressAdd,
  initializeOrder,
  setLogisticAddresses,
  setPickupAddresses,
  orderItemFixIssue,
  workspaceAddressAdd,
  orderFixIssue,
  delay = 500,
}: UseOrderActionsProps): UseOrderActionsReturn => {
  const paramCache = new Map();

  const createCacheKey = React.useCallback((...args: Array<unknown>) => JSON.stringify(args), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOrderItemFixIssue = React.useCallback(
    debounce(
      (
        myId: number,
        orderId: number,
        item: IOrderItem,
        productId: number,
        issueId: number,
        fixAll: boolean,
        price: number,
        updateProduct: boolean,
      ) => {
        const cacheKey = createCacheKey(myId, orderId, item, productId, issueId, fixAll, price, updateProduct);

        if (paramCache.get(cacheKey)) return;

        paramCache.set(cacheKey, true);
        return orderItemFixIssue(myId, orderId, item, productId, issueId, fixAll, price, updateProduct);
      },
      delay,
      { leading: true, trailing: false },
    ),
    [orderItemFixIssue, createCacheKey, delay],  
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOrderFixIssue = React.useCallback(
    debounce(
      (myId: number, order: IOrder) => {
        const cacheKey = createCacheKey(myId, order);

        if (paramCache.get(cacheKey)) return;

        paramCache.set(cacheKey, true);
        return orderFixIssue(myId, order);
      },
      delay,
      { leading: true, trailing: false },
    ),
    [orderFixIssue, createCacheKey, delay],  
  );

  // Clear cache periodically
  React.useEffect(() => {
    const interval = setInterval(() => paramCache.clear(), 60000);
    return () => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addDeliveryAddress = React.useCallback(
    (addr: IAddress, cb: (addr: IAddress) => void) => {
      if (!me.id || !order?.catalogId || !contactId) return;
      clientAddressAdd(me.id, order?.catalogId, contactId, addr, async (err, address) => {
        await api.user
          .getAddressesBySellerBuyer(order?.buyerId, order?.sellerId, order?.catalogId, order?.buyerWorkspaceId)
          .then(resp => {
            setLogisticAddresses(resp.shippingAddresses);
            setPickupAddresses(resp.pickupAddresses);
          });
        initializeOrder();
        cb(address);
      });
    },
    [
      me?.id,
      contactId,
      clientAddressAdd,
      initializeOrder,
      order?.buyerId,
      order?.sellerId,
      order?.catalogId,
      order?.buyerWorkspaceId,
      setLogisticAddresses,
      setPickupAddresses,
    ],
  );

  const addPickupAddress = React.useCallback(
    (addr: IAddress, cb: (addr: IAddress) => void) => {
      if (!me.id || !order?.catalogId || !contactId) return;
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      workspaceAddressAdd(me.id, order?.catalogId, addr, async (err, address) => {
        await api.user
          .getAddressesBySellerBuyer(order?.buyerId, order?.sellerId, order?.catalogId, order?.buyerWorkspaceId)
          .then(resp => {
            setLogisticAddresses(resp.shippingAddresses);
            setPickupAddresses(resp.pickupAddresses);
          });
        initializeOrder();
        cb(address);
      });
    },
    [
      me?.id,
      contactId,
      initializeOrder,
      order?.buyerId,
      order?.sellerId,
      order?.catalogId,
      order?.buyerWorkspaceId,
      setLogisticAddresses,
      setPickupAddresses,
      workspaceAddressAdd,
    ],
  );

  return {
    addDeliveryAddress,
    addPickupAddress,
    debouncedOrderItemFixIssue,
    debouncedOrderFixIssue,
  };
};

export default useOrderActions;

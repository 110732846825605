import { __, currency, orderService, orderUtils } from 'common-services';
import * as React from 'react';

import config from '../../../../../../../../../bindings/config';
import { calculatePriceServedUnit } from '../../../../../../../../domain/order';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'delivery-price',
  isVisible: true,
  getTitle: () => __('Components.OffersList.table.deliveredPrice'),
  onClick: e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  },
  minWidth: '120px',
  width: '10%',
  condition: ({ isPrepare }) => !isPrepare,
  columnValue: ({ order, priceMode, pricePrecision }) => ({
    getElement: item => {
      return (
        <S.ItemPriceTotal isCancelled={orderUtils.isCanceled(order)} showGrey={false}>
          {item.price
            ? currency.getPricePerUnit(item.currency, item.priceUnit, item.weightUnit, item.price, pricePrecision)
            : '-'}
        </S.ItemPriceTotal>
      );
    },
  }),
};

import { ORDER_STATUS, __ } from 'common-services';
import * as React from 'react';

import config from '../../../../../../../../../bindings/config';
import { SimpleDropdown } from '../../../../../../../atoms';


import { noTitle } from './_props';
import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';


export const column: OrderItemColumnSpec = {
  id: 'actions',
  rank: 2000,
  isVisible: true,
  getTitle: noTitle,
  width: '7%',
  condition: ({ weAreSeller, order, previewMode }) =>
    !weAreSeller && order?.status === ORDER_STATUS.ACCEPTED && !previewMode && config.TOGGLE_PRODUCT_LOT.enabled,
  columnValue: ({ order, weAreSeller, handleLotOptions, showSelectWorkspaceModal, renderSelectWorkspaceModal }) => ({
    getElement: item => {
      return (
        <S.SubtotalTd>
          {!weAreSeller && order?.status === ORDER_STATUS.ACCEPTED && config.TOGGLE_PRODUCT_LOT.enabled ? (
            <SimpleDropdown
              hAlign="right"
              onSelect={(name: string) => handleLotOptions(name, item)}
              options={[
                { key: 'new', value: __('Components.ProductDetails.lot.assign_new') },
                { key: 'existing', value: __('Components.ProductDetails.lot.assign_existing') },
              ]}
            >
              <S.KebabIcon name="Kebab" />
            </SimpleDropdown>
          ) : null}
          {showSelectWorkspaceModal ? renderSelectWorkspaceModal(item) : null}
        </S.SubtotalTd>
      );
    },
  }),
};

import {
  __,
  apiParsers,
  constants,
  debounce,
  EventTrack,
  LOCALE,
  parsers,
  productActions,
  productService,
  RenderTrack,
  sellerWorkspaceService,
} from 'common-services';
import { History } from 'history';
import * as React from 'react';

import config from '../../../../../../bindings/config';
import { NO_TAG, ShowroomPage } from '../../../../../constants';
import { api } from '../../../../../store';
import { VisibilityPricesRibbon } from '../../../../atoms';
import { SectionCard } from '../../../../molecules';
import RowList from '../../../PublicShowroom/RowList.component';
import * as SR from '../../Showroom.styled';
import * as S from './Home.styled';

interface IProps {
  AddressHeader?: React.ReactNode;
  amSeller: boolean;
  cartItems: Array<IOrderItem>;
  cartUpdateItem: (item: IOrderItem) => void;
  catalog: IWorkspace;
  client?: IClient;
  ExcelDropdown?: React.ReactNode;
  featured: { [key: string]: Array<string> };
  featuredToggle: (type: IFeaturedType, productHashId: string) => void;
  from: 'pricelist' | 'showroom';
  hideFeatured: boolean;
  history?: History<any>;
  isServedFlow?: boolean;
  lang: LOCALE;
  me: IUser;
  onNavigate: (page: ShowroomPage, key?: string, query?: string, product?: IProduct) => void;
  onShareSearch?: (product?: GenericProduct, productName?: string) => void;
  priceGroupIds?: Array<string>;
  priceMode?: IPriceMode;
  prices: { [key: number]: IPrice };
  productId?: string;
  showShare: boolean;
}

interface IState {
  addresses?: Array<IAddress>;
  facets: IFacets;
  favorite: Array<IProduct>;
  hasMore?: boolean;
  productId?: string;
  products: Array<IProduct>;
  recent: Array<IProduct>;
  recommended: Array<IProduct>;
  searchId?: string;
  searchState: ISearch;
  totalResults: number;
}

/**
 * Home page for public shop and internal showroom
 */
export default class Home extends React.PureComponent<IProps, IState> {
  /**
   * Send products' search
   */
  private sendProductsSearch = debounce(() => {
    const { searchState } = this.state;
    const { me } = this.props;
    productService
      .productSearch(searchState, searchState.language, config.SEARCH_PREFIX, me.id, api, true)
      .then(this.onReceiveProducts);
  }, 200);
  private t: number;

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    const { lang, priceGroupIds, catalog, productId } = props;
    this.state = {
      productId: productId || '',
      searchState: {
        text: '',
        index: catalog?.hashId || '',
        rate: priceGroupIds,
        language: lang || LOCALE.EN,
        status: ['active', 'unavailable'],
      },
      products: [],
      favorite: [],
      recommended: [],
      recent: [],
      facets: {},
      totalResults: 0,
    };
  }

  public componentDidMount() {
    const { from } = this.props;
    RenderTrack.track(from === 'pricelist' ? 'PublicShopHome' : 'ShowroomHome', { renderTime: this.t });
    this.initSearchProducts();
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { catalog, me, featured, lang, priceGroupIds } = this.props;
    const { searchState } = this.state;
    if (prevProps.lang !== lang) {
      this.setState({
        searchState: {
          ...searchState,
          language: searchState.language,
        },
      });
    }
    const reSearch = searchState !== prevState.searchState && !!searchState.index;
    if (reSearch) {
      this.sendProductsSearch(searchState, me, this.onReceiveProducts);
    }
    if (reSearch || (prevProps.featured.recent !== featured.recent && featured.recent?.length)) {
      productService
        .productSearch(
          { ...searchState, featured: this.getFeatureds('recent') },
          searchState.language,
          config.SEARCH_PREFIX,
          me.id,
          api,
        )
        .then(this.onReceiveRecent);
    }
    if (reSearch || (prevProps.featured.recommended !== featured.recommended && featured.recommended?.length)) {
      productService
        .productSearch(
          { ...searchState, featured: this.getFeatureds('recommended') },
          searchState.language,
          config.SEARCH_PREFIX,
          me.id,
          api,
        )
        .then(this.onReceiveRecommended);
    }
    if (reSearch || (prevProps.featured.favorite !== featured.favorite && featured.favorite?.length)) {
      productService
        .productSearch(
          { ...searchState, featured: this.getFeatureds('favorite') },
          searchState.language,
          config.SEARCH_PREFIX,
          me.id,
          api,
        )
        .then(this.onReceiveFavorite);
    }

    // Control catalog or price groups update to fill the elastic search state
    if (
      (catalog && prevProps.catalog?.id !== catalog.id && catalog.hashId) ||
      priceGroupIds !== prevProps.priceGroupIds
    ) {
      this.setState({ searchState: { ...searchState, rate: priceGroupIds, index: catalog?.hashId || '' } });
    }
  }

  public render() {
    const { AddressHeader, catalog, client, ExcelDropdown, from, onNavigate, amSeller, me } = this.props;
    const { facets, favorite, products, recent, recommended, searchState, totalResults } = this.state;
    const hasSections = catalog?.sectionsEnabled && catalog?.sections.find(s => facets && facets.tags?.[s.tag]);

    return (
      <>
        {AddressHeader || null}

        {amSeller ? (
          <VisibilityPricesRibbon
            priceMode={client?.priceMode}
            myRole={sellerWorkspaceService.getRole(catalog, me.id)}
            client={client}
            myId={me.id}
            catalogId={catalog?.id}
          />
        ) : null}
        <S.Body from={from}>
          {ExcelDropdown ? <S.ExcelDropDownContainer>{ExcelDropdown}</S.ExcelDropDownContainer> : null}
          {hasSections ? (
            <SR.Group>
              <SR.GroupTitle>
                {__('PublicShowroom.Titles.sections')}
                <S.Link onClick={() => onNavigate('sections')}>
                  {__('PublicShowroom.see_all', { count: catalog.sections.length + 1 })}
                </S.Link>
              </SR.GroupTitle>
              <RowList>
                {catalog.sections
                  .filter(s => facets && facets.tags?.[s.tag])
                  .sort((a, b) => a.position - b.position)
                  .map(s => (
                    <SectionCard
                      onClick={() => onNavigate('sections', s.tag)}
                      amount={((facets && facets.tags?.[s.tag]) as number) || 0}
                      key={s.tag}
                      section={s}
                      myLocale={searchState.language}
                    />
                  ))}
                {facets && facets.tags?.[NO_TAG] ? (
                  <SectionCard
                    onClick={() => onNavigate('sections', NO_TAG)}
                    amount={facets.tags?.[NO_TAG] as number}
                    key={NO_TAG}
                    section={sellerWorkspaceService.sectionNew(NO_TAG, catalog.sections.length + 1, 0)}
                    myLocale={searchState.language}
                  />
                ) : null}
              </RowList>
            </SR.Group>
          ) : null}
          {products.length ? (
            <SR.Group>
              <SR.GroupTitle>
                {__('PublicShowroom.Titles.all_products')}
                <S.Link onClick={() => onNavigate('products')}>
                  {__('PublicShowroom.see_all', { count: totalResults })}
                </S.Link>
              </SR.GroupTitle>
              {recommended?.length || favorite?.length || recent?.length ? (
                <RowList>{products.map(p => this.renderProductItem(p))}</RowList>
              ) : (
                <>
                  <SR.GridGroup from={from}>{products.map(p => this.renderProductItem(p))}</SR.GridGroup>
                  <S.LinkMore onClick={() => onNavigate('products')}>
                    {__('PublicShowroom.see_all', { count: totalResults })}
                  </S.LinkMore>
                </>
              )}
            </SR.Group>
          ) : null}
          {recommended?.length ? (
            <SR.Group>
              <SR.GroupTitle>
                {__('PublicShowroom.Titles.recommended')}
                <S.Link onClick={() => onNavigate('recommended')}>
                  {__('PublicShowroom.see_all', { count: recommended?.length })}
                </S.Link>
              </SR.GroupTitle>
              <RowList>{recommended.map(p => this.renderProductItem(p))}</RowList>
            </SR.Group>
          ) : null}
          {favorite?.length ? (
            <SR.Group>
              <SR.GroupTitle>
                {__('PublicShowroom.Titles.favorite')}
                <S.Link onClick={() => onNavigate('favorite')}>
                  {__('PublicShowroom.see_all', { count: favorite?.length })}
                </S.Link>
              </SR.GroupTitle>
              <RowList>{favorite.map(p => this.renderProductItem(p))}</RowList>
            </SR.Group>
          ) : null}
          {recent?.length ? (
            <SR.Group>
              <SR.GroupTitle>
                {__('PublicShowroom.Titles.recent')}
                <S.Link onClick={() => onNavigate('recent')}>
                  {__('PublicShowroom.see_all', { count: recent?.length })}
                </S.Link>
              </SR.GroupTitle>
              <RowList>{recent.map(p => this.renderProductItem(p))}</RowList>
            </SR.Group>
          ) : null}
        </S.Body>
      </>
    );
  }

  /**
   * Render a product card
   */
  private renderProductItem = (product: IProduct) => {
    const {
      amSeller,
      cartItems,
      cartUpdateItem,
      catalog,
      featured,
      featuredToggle,
      hideFeatured,
      isServedFlow,
      onNavigate,
      priceMode,
      prices,
      showShare,
    } = this.props;
    const cartItem = cartItems?.find(c => c.childId === product.id);
    const productData = { ...product, ...(prices?.[product.id] || {}) };
    return (
      <S.ProductItem
        amount={cartItem?.amount || 0}
        amSeller={amSeller}
        onSelectItem={cartUpdateItem}
        data={parsers.productToGenericProduct(productData)}
        featuredToggle={featuredToggle}
        isFavorite={featured?.favorite?.includes(product.hashId)}
        isRecent={featured?.recent?.includes(product.hashId)}
        isRecommended={featured?.recommended?.includes(product.hashId)}
        isSelected={false}
        isServedFlow={isServedFlow}
        key={'_' + product.id}
        navigate={() => onNavigate('products', undefined, undefined, productData)}
        openShareProduct={this.onShareSearch}
        orderItemId={cartItem?.id}
        price={cartItem?.price || productData.price}
        priceMode={priceMode === 'edit' ? 'read' : priceMode || 'none'}
        pricePrecision={catalog ? catalog?.numberOfDecimalsShowed : constants.PRICE_PRECISION}
        saleUnit={cartItem?.saleUnit || product.saleUnits[0]}
        servedQuantity={cartItem?.servedQuantity || 0}
        hideFeatured={hideFeatured}
        showShare={showShare}
      />
    );
  };

  /**
   * Init search product
   */
  private initSearchProducts() {
    const { catalog, featured, from, me } = this.props;
    const { searchState } = this.state;

    if (!catalog || !searchState.index) return;
    if (from === 'showroom' || (catalog.sectionsEnabled && catalog.sections)) {
      this.sendProductsSearch(searchState, me, this.onReceiveProducts);
      if (featured.recent?.length)
        productService
          .productSearch(
            { ...searchState, featured: this.getFeatureds('recent') },
            searchState.language,
            config.SEARCH_PREFIX,
            me.id,
            api,
          )
          .then(this.onReceiveRecent);
      if (featured.recommended?.length)
        productService
          .productSearch(
            { ...searchState, featured: this.getFeatureds('recommended') },
            searchState.language,
            config.SEARCH_PREFIX,
            me.id,
            api,
          )
          .then(this.onReceiveRecommended);
      if (featured.favorite?.length)
        productService
          .productSearch(
            { ...searchState, featured: this.getFeatureds('favorite') },
            searchState.language,
            config.SEARCH_PREFIX,
            me.id,
            api,
          )
          .then(this.onReceiveFavorite);
    }
  }

  /**
   * On share product, opens modal
   */
  private onShareSearch = (product?: GenericProduct, productName?: string) => {
    this.props?.onShareSearch?.(product, productName);
  };

  /**
   * Send search with elastic
   */
  private onReceiveProducts = (res: ISearchData<IProduct>) => {
    if (!res) return;
    const { data, facetsGlobal } = res;
    const { catalog, from } = this.props;
    const { searchState } = this.state;
    const filters = productService.getTrackedFilters(searchState);
    if (filters.length || searchState.text) {
      EventTrack.track(from === 'pricelist' ? 'public_products_filter' : 'showroom_products_filter', {
        workspace_id: catalog.id,
        results: data.totalResults,
        filters,
      });
    }
    this.setState({
      products: data.hits,
      searchId: data.searchId,
      totalResults: data.totalResults,
      hasMore: data.hasMore,
      facets: facetsGlobal,
    });
    if (searchState.text) {
      EventTrack.track(from === 'pricelist' ? 'public_products_search' : 'showroom_products_search', {
        workspace_id: catalog.id,
        results: data.totalResults,
        text: searchState.text,
      });
    }
  };

  /**
   * Send search with elastic
   */
  private onReceiveRecommended = (res: { data: ISearchResponse; replace: boolean; facets: IFacets }) => {
    if (res)
      this.setState({
        recommended: res.data.hits,
      });
  };

  /**
   * Send search with elastic
   */
  private onReceiveFavorite = (res: { data: ISearchResponse; replace: boolean; facets: IFacets }) => {
    if (res)
      this.setState({
        favorite: res.data.hits,
      });
  };

  /**
   * Send search with elastic
   */
  private onReceiveRecent = (res: { data: ISearchResponse; replace: boolean; facets: IFacets }) => {
    if (res)
      this.setState({
        recent: res.data.hits,
      });
  };

  /**
   * return featureds filter for search
   */
  private getFeatureds(listToShow: IFeaturedType) {
    const { prices, featured } = this.props;

    const activePrices = prices ? Object.values(prices) : [];
    switch (listToShow) {
      case 'recommended':
        return activePrices.filter(price => featured.recommended?.includes(price.hashId)).length
          ? [...featured.recommended?.filter(l => !!l)]
          : ['none'];
      case 'favorite':
        return activePrices.filter(price => featured.favorite?.includes(price.hashId)).length
          ? [...featured.favorite?.filter(l => !!l)]
          : ['none'];
      case 'recent':
        return activePrices.filter(price => featured.recent?.includes(price.hashId)).length
          ? [...featured.recent?.filter(l => !!l)]
          : ['none'];
      default:
        return;
    }
  }
}

import * as React from 'react';

import type { IOrder, IOrderItem, IOrderIssue } from 'common-services';

interface UseOrderIssuesProps {
  order?: IOrder;
  selectedItem?: IOrderItem;
}

export interface UseOrderIssuesReturn {
  selectedIssue: IOrderIssue | undefined;
  noClientIssue: IOrderIssue | undefined;
  deliveryAddressIssue: IOrderIssue | undefined;
  pickupAddressIssue: IOrderIssue | undefined;
}

const useOrderIssues = ({ order, selectedItem }: UseOrderIssuesProps): UseOrderIssuesReturn => {
  const selectedIssue = React.useMemo(
    () => (selectedItem ? order?.issues?.find(i => i.orderItemId === selectedItem.id) : undefined),
    [selectedItem, order?.issues],
  );

  const noClientIssue = React.useMemo(
    () => order?.issues.find(i => i.type === 'client-code-not-found'),
    [order?.issues],
  );

  const deliveryAddressIssue = React.useMemo(() => {
    return order?.issues.find(err => ['address-not-found', 'address-code-not-found'].includes(err.type));
  }, [order?.issues]);

  const pickupAddressIssue = React.useMemo(
    () => order?.issues.find(err => ['pickup-not-found', 'pickup-code-not-found'].includes(err.type)),
    [order?.issues],
  );

  return {
    selectedIssue,
    noClientIssue,
    deliveryAddressIssue,
    pickupAddressIssue,
  };
};

export default useOrderIssues;

import { orderService, __, ORDER_STATUS, ORDER_ORIGIN, currency } from 'common-services';
import * as React from 'react';

import config from '../../../../../bindings/config';
import { IMAGES } from '../../../../assets';
import { priceUnitTranslate } from '../../../../util/utils';

import type { ItemWithIssue } from './hooksTypes';
import type {
  IOrder,
  IOrderItem,
  ICustomItem,
  IProduct,
  IUser,
  IPriceMode,
  IOrderIssue,
  orderActions,
  modalActions,
  IWorkspace,
  ICart,
  INotification,
  productActions,
} from 'common-services';

interface UseOrderItemsProps {
  order?: IOrder;
  me: IUser;
  cart: ICart;
  weAreSeller: boolean;
  weAreBuyer: boolean;
  priceMode?: IPriceMode;
  catalog?: IWorkspace;
  modalOpen: typeof modalActions.modalOpen;
  modalClose: typeof modalActions.modalClose;
  cartUpdateItem: typeof orderActions.cartUpdateItem;
  orderUpdateProducts: (items: Array<IOrderItem>, orderId: number, userId: number) => void;
  cartUpdateCustomItems: (
    key: string,
    catalogId: number,
    contactId: number,
    customItems: Array<ICustomItem>,
    priceMode?: IPriceMode,
  ) => void;
  orderUpdateCustomItems: (customItems: Array<ICustomItem>, orderId: number, userId: number) => void;
  productGetByHashPublic: typeof productActions.productGetByHashPublic;
  productAddAttributeValue: (
    productId: number,
    attributeKey: string,
    value: unknown,
    callback: (response: unknown, error?: Error) => void,
  ) => void;
  notificationShow: (notification: INotification, duration?: number) => void;
  orderUpdateIssues: typeof orderActions.orderUpdateIssues;
  orderItemFixIssue: typeof orderActions.orderItemFixIssue;
  orderFixIssue: typeof orderActions.orderFixIssue;
  orderItemIgnore: typeof orderActions.orderItemIgnore;
  servedFlowEnabled: boolean;
  editAfterAccept?: boolean;
  pricePrecision: number;
}

interface UseOrderItemsState {
  selectedItem?: IOrderItem;
  itemWithIssue: ItemWithIssue;
  setItemWithIssue: (item?: ItemWithIssue) => void;
  productList: Array<IProduct>;
  setPriceEdit: (value: boolean) => void;
  discardForever: boolean;
  setDiscardForever: (value: boolean) => void;
}

export interface UseOrderItemsReturn {
  mbppDropdownOptions: Array<{ value: string; label: string }>;
  handleItemUpdate: (item: IOrderItem, issue?: IOrderIssue, updateProduct?: boolean) => void;
  handleCustomItemsUpdate: (customItems: Array<ICustomItem>) => void;
  handleProductChange: (forever: boolean, itemUpdated: IOrderItem) => void;
  handleBoxesPerPalletChange: (item: IOrderItem, newValue: number, applyOnlyToOrder: boolean) => void;
  loadProductDetails: (productHashId: string) => void;
  updateItemPrice: (item: IOrderItem, key?: string, value?: number | string, error?: string) => void;
  handleDiscardItem: (issue: IOrderIssue) => void;
  discardLine: (e: React.MouseEvent, data: IOrderItem) => void;
  priceToShow: (item: IOrderItem) => string;
  onEnableItem: (item: IOrderItem, issue: IOrderIssue) => void;
}

/**
 * Hook to handle order items functionality
 */
const useOrderItems = ({
  order,
  me,
  cart,
  weAreSeller,
  weAreBuyer,
  priceMode,
  catalog,
  cartUpdateItem,
  orderUpdateProducts,
  cartUpdateCustomItems,
  orderUpdateCustomItems,
  productGetByHashPublic,
  productAddAttributeValue,
  notificationShow,
  orderUpdateIssues,
  orderItemFixIssue,
  modalOpen,
  modalClose,
  orderFixIssue,
  orderItemIgnore,
  // States
  selectedItem,
  itemWithIssue,
  setItemWithIssue,
  productList,
  setPriceEdit,
  discardForever,
  setDiscardForever,
  // new
  servedFlowEnabled,
  editAfterAccept,
  pricePrecision,
}: UseOrderItemsProps & UseOrderItemsState): UseOrderItemsReturn => {
  /**
   * Get price formatted or 'P.O.R.' text.
   */
  const priceToShow = React.useCallback(
    (item: IOrderItem): string => {
      const showServedColumn = servedFlowEnabled && (editAfterAccept || orderService.hasServedChanges(order));
      const price = showServedColumn ? item.servedPrice : item.price;
      if (price === 0) return '-';
      if (price) {
        return currency.getPricePerUnit(
          item.currency,
          config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId)
            ? priceUnitTranslate(item.priceUnit)
            : item.priceUnit,
          item.weightUnit,
          price,
          pricePrecision,
        );
      }
      return __('Components.ProductDetails.por_on');
    },
    [order, pricePrecision, servedFlowEnabled, editAfterAccept],
  );

  /**
   * Handle item update
   */
  const handleItemUpdate = React.useCallback(
    (item: IOrderItem, issue?: IOrderIssue, updateProduct?: boolean, fixAll?: boolean) => {
      if (!order) return;

      const contactId = weAreBuyer ? order.sellerId : order.buyerId;
      const newItem = { ...item, totalPrice: 0 }; // Reset total price to force recalculation

      // Update cart item
      cartUpdateItem(cart.key, contactId, newItem, weAreSeller, priceMode);

      // Update order products
      const items = [...order.items];
      const idx = items.findIndex(i => (item.childId ? i.childId === item.childId : i.title === item.title));

      if (idx !== -1) {
        items[idx] = newItem;
      } else {
        items.push(newItem);
      }

      orderUpdateProducts(items, order.id, me.id);

      if (issue) {
        const issuesRelated = order.issues.filter(i => i.orderItemId === item.id && i.type === issue.type);
        issuesRelated.map(i =>
          orderUpdateIssues({ ...i, status: 'solved' }, order.id, me.id, () => {
            orderItemFixIssue(
              me.id,
              order.id,
              item,
              item.childId,
              i.id,
              fixAll ?? false,
              item.price,
              updateProduct ?? false,
            );
          }),
        );
      }
    },
    [
      order,
      me.id,
      cart,
      weAreBuyer,
      weAreSeller,
      priceMode,
      cartUpdateItem,
      orderUpdateProducts,
      orderUpdateIssues,
      orderItemFixIssue,
    ],
  );

  /**
   * Handle enabling item, fixing issue
   * @param item
   * @param issue
   * @returns void
   */
  const onEnableItem = React.useCallback(
    (item: IOrderItem, issue: IOrderIssue): void => {
      orderItemFixIssue(me.id, order?.id, item, 0, issue.id, false, item.price, false);
    },
    [orderItemFixIssue, me.id, order?.id],
  );

  /**
   * Handle updating custom items
   */
  const handleCustomItemsUpdate = React.useCallback(
    (customItems: Array<ICustomItem>) => {
      const contactId = weAreSeller ? order?.buyerId : order?.sellerId;

      cartUpdateCustomItems(cart.key, cart.catalogId, contactId, customItems, priceMode);

      orderUpdateCustomItems(customItems, order?.id, me.id);
    },
    [order, me.id, cart, weAreSeller, priceMode, cartUpdateCustomItems, orderUpdateCustomItems],
  );

  const handleMultiBoxesCase = React.useCallback(
    (itemUpdated: IOrderItem) => {
      if (!order?.buyerId) return;

      try {
        productAddAttributeValue(
          itemUpdated.childId,
          'boxes-per-pallet',
          {
            value: itemUpdated.boxesPerPallet,
            client_id: order?.buyerId,
          },
          (_, err) => {
            if (err) console.error(err);
          },
        );
      } catch (_) {
        notificationShow({
          title: __('Components.OrderDetails.notification.error'),
          subtitle: __('Components.OrderDetails.notification.error_description'),
          closable: true,
          style: 'error',
        });
      }
    },
    [order?.buyerId, productAddAttributeValue, notificationShow],
  );

  const updateItemPrice = React.useCallback(
    (item: IOrderItem, key?: string, value?: number | string, error?: string) => {
      const v = Number(value);
      if (!error && v > 0) {
        const invalidPriceIssue = order?.issues?.find(i => i.orderItemId === item.id && i.type === 'price-not-valid');
        handleItemUpdate({ ...item, [key]: v, isPor: false, servedPrice: v }, invalidPriceIssue);
      }
      setPriceEdit(false);
    },
    [order, setPriceEdit, handleItemUpdate],
  );

  /**
   * Handle product change with better error handling and state management
   */
  const handleProductChange = React.useCallback(
    (fixAll: boolean, itemUpdated: IOrderItem) => {
      if (!itemWithIssue || !order?.id) {
        console.warn('Missing required data for product change');
        return;
      }

      // Find related issue
      const issueRelated = order?.issues.find(
        issue =>
          issue.orderItemId === itemWithIssue.item.id &&
          [
            'product-not-found',
            'code-not-found',
            'product-unavailable',
            'many-internal-codes-for-external',
            'no-pieces-per-box',
            'no-boxes-per-pallet',
            'no-box-weight',
            'multi-boxes-per-pallet-without-buyer',
          ].includes(issue.type),
      );

      if (!issueRelated) {
        console.warn('No related issue found for item:', itemWithIssue.item);
        return;
      }

      try {
        // Create updated item with all required fields
        const updatedItem = {
          ...itemWithIssue.item,
          boxWeight: itemUpdated.boxWeight,
          boxesPerPallet: itemUpdated.boxesPerPallet,
          piecesPerBox: itemUpdated.piecesPerBox,
          childId: itemUpdated.childId,
          price: itemUpdated.price,
        };

        const isDistributionIssue = ['no-pieces-per-box', 'no-boxes-per-pallet', 'no-box-weight'].includes(
          issueRelated.type,
        );

        // Batch state updates

        // Update item and issue
        handleItemUpdate(updatedItem, issueRelated, isDistributionIssue, fixAll);
        // Handle multi-boxes case if needed
        if (issueRelated.type === 'multi-boxes-per-pallet-without-buyer') handleMultiBoxesCase(itemUpdated); // TODO:2 are we sure we want to execute this?

        // Show success notification
        notificationShow(
          {
            style: 'success',
            title: __('Components.OrderDetails.issues_modal.success'),
            subtitle: '',
            closable: true,
          },
          5000,
        );

        // Reset item with issue
        setItemWithIssue(undefined);
      } catch (error) {
        console.error('Error in handleProductChange:', error);
        notificationShow({
          style: 'error',
          title: __('Error.generic'),
          subtitle: __('Error.generic_description'),
          closable: true,
        });
      }
    },
    [
      itemWithIssue,
      order?.id,
      order?.issues,
      handleMultiBoxesCase,
      notificationShow,
      handleItemUpdate,
      setItemWithIssue,
    ],
  );

  /**
   * Handle discarding order item
   */
  const handleDiscardItem = React.useCallback(
    (issue: IOrderIssue) => {
      setDiscardForever(false);

      if (config?.TOGGLE_DISCARD_MO?.enabled) {
        if (order)
          modalOpen(
            __('Components.Cart.discard.message'),
            () => {
              const isForever = discardForever;
              orderItemIgnore(catalog.id, me.id, order?.id, issue.orderItemId, issue.id, isForever, updatedOrder => {
                const item = updatedOrder.items.find(i => i.id === issue.orderItemId);
                handleItemUpdate({
                  ...item,
                  alwaysIgnored: isForever,
                  servedQuantity: 0,
                });
              });
              setDiscardForever(false);
              modalClose();
            },
            {
              icon: IMAGES.informativePineapple,
              buttonText: __('Components.Cart.discard.cta'),
              showCancelButton: true,
              buttonCancelText: __('ContactInfo.Menu.workspace.modal.cancel'),
              checkBox: __('Components.Cart.discard.checkbox'),
              checkBoxAction: () => setDiscardForever(!discardForever),
              cancelAction: () => {
                setDiscardForever(false);
                modalClose();
              },
            },
            'nice',
            true,
          );
      } else {
        modalOpen(
          __('Components.Cart.discard.message'),
          () => {
            orderUpdateIssues(issue, order?.id, me.id, o => orderFixIssue(me.id, o));
            modalClose();
          },
          {
            buttonText: __('Components.Cart.discard.cta'),
            showCancelButton: true,
          },
        );
      }
    },
    [
      order,
      me.id,
      catalog,
      discardForever,
      modalOpen,
      modalClose,
      orderUpdateIssues,
      orderFixIssue,
      handleItemUpdate,
      orderItemIgnore,
      setDiscardForever,
    ],
  );

  /**
   * Discard line (remove + solve issue)
   */
  const discardLine = React.useCallback(
    (e, data: IOrderItem): void => {
      e.stopPropagation();
      const issuesRelated = order?.issues.filter(issue => issue.orderItemId === data.id);
      issuesRelated.forEach(i => handleDiscardItem({ ...i, status: 'ignored' }));
    },
    [order, handleDiscardItem],
  );

  /**
   * Handle boxes per pallet change
   */
  const handleBoxesPerPalletChange = React.useCallback(
    (item: IOrderItem, newValue: number, applyOnlyToOrder: boolean) => {
      // Update item
      handleItemUpdate({
        ...item,
        boxesPerPallet: newValue,
      });

      // Update product if needed
      if (!applyOnlyToOrder) {
        try {
          productAddAttributeValue(
            item.childId,
            'boxes-per-pallet',
            {
              value: newValue,
              client_id: order?.buyerId,
            },
            (_, err) => {
              if (err) console.error(err);
            },
          );
        } catch (_) {
          notificationShow({
            title: __('Components.OrderDetails.notification.error'),
            subtitle: __('Components.OrderDetails.notification.error_description'),
            closable: true,
            style: 'error',
          });
        }
      }
    },
    [handleItemUpdate, productAddAttributeValue, notificationShow, order?.buyerId],
  );

  /**
   * Cache to track requested and loaded products
   */
  const requestedProducts = React.useRef<Set<string>>(new Set());
  const loadedProducts = React.useRef<Set<string>>(new Set());

  /**
   * Memoized function to add a product to the list
   */
  const addProductToList = React.useCallback(
    (product: IProduct) => {
      if (!product?.id) return;

      if (!productList.some(p => p.id === product.id)) {
        loadedProducts.current.add(product.id.toString());
        return [...productList, product];
      }
    },
    [productList],
  );

  /**
   * Memoized function to load a single product
   */
  const loadProductDetail = React.useCallback(
    (productHashId: string) => {
      if (!productHashId || requestedProducts.current.has(productHashId)) {
        return;
      }

      // Mark as requested before making the API call
      requestedProducts.current.add(productHashId);

      productGetByHashPublic(
        productHashId,
        product => {
          addProductToList(product);
        },
        false,
      );
    },
    [productGetByHashPublic, addProductToList],
  );

  const cartDeps = React.useMemo(() => JSON.stringify(cart.items?.map(i => i.productHashId)), [cart.items]);
  const orderDeps = React.useMemo(() => JSON.stringify(order?.items?.map(i => i.productHashId)), [order?.items]);
  /**
   * Single effect to handle all product loading scenarios
   */
  React.useEffect(
    () => {
      // Helper to extract valid product hash IDs
      const getValidHashIds = (items: Array<IOrderItem> = []): Array<string> =>
        items
          .filter(item => item?.productHashId?.length > 1)
          .map(item => item.productHashId)
          .filter(Boolean);

      // Get all potential items that need products loaded
      const orderItems = order?.items || [];
      const cartItems = cart?.items || [];
      const mandatoryPalletItems =
        order?.status === ORDER_STATUS.INCOMPLETE &&
        catalog?.mandatoryPalletizationByClient &&
        order?.issues.some(o => o.type === 'multi-boxes-per-pallet-without-buyer')
          ? cartItems.filter(
              i =>
                order?.servedFlowEnabled ||
                i.amount ||
                order?.origin === ORDER_ORIGIN.IMPORT_UI ||
                order?.origin === ORDER_ORIGIN.EMAIL,
            )
          : [];

      // Combine and deduplicate all hash IDs
      // Combine all hash IDs and deduplicate using Array methods instead of Set
      const hashIds = getValidHashIds(orderItems)
        .concat(getValidHashIds(cartItems))
        .concat(getValidHashIds(mandatoryPalletItems));

      // Deduplicate array
      const allHashIds = Array.from(new Set(hashIds));

      // Load any products that haven't been requested yet
      allHashIds.forEach(hashId => {
        if (!requestedProducts.current.has(hashId)) {
          loadProductDetail(hashId);
        }
      });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // Dependencies for order items
      orderDeps,
      // Dependencies for cart items
      cartDeps,
      // Dependencies for mandatory palletization
      catalog?.mandatoryPalletizationByClient,
      // Other dependencies
      loadProductDetail,
    ],
  );

  // Remove old effects and simplify the loadProductDetails function
  const loadProductDetails = React.useCallback(
    (productHashId: string) => {
      loadProductDetail(productHashId);
    },
    [loadProductDetail],
  );
  const mbppDropdownOptions = React.useMemo(() => {
    const options =
      productList
        ?.find(p => p.id === selectedItem?.childId)
        ?.boxesPerPalletValues?.map(v => ({
          value: v.value.toString(),
          label: v.value.toString(),
        })) || [];
    options.push({
      value: 'add_option',
      label: __('Components.Cart.boxesPerPallet.add_option'),
    });
    return options;
  }, [productList, selectedItem]);

  return {
    mbppDropdownOptions,
    handleItemUpdate,
    handleCustomItemsUpdate,
    handleProductChange,
    handleBoxesPerPalletChange,
    loadProductDetails,
    updateItemPrice,
    handleDiscardItem,
    discardLine,
    priceToShow,
    onEnableItem,
  };
};

export default useOrderItems;

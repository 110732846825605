import styled from '../../../../../../../../styled-components';
import { FontIcon, RowContainer } from '../../../../../../../atoms';

import type { ITheme } from '../../../../../../../../styled-components';

interface IStyledProps {
  numHeaders?: number;
  theme: ITheme;
  selected?: boolean;
  isDragActive?: boolean;
  hasText?: boolean;
}

export const UnreadMessage = styled(RowContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  justify-content: center;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  height: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  align-items: center;
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  margin: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  margin-bottom: 0;
`;

export const UpIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.black};
  transform: rotate(90deg);
  font-size: 14px;
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
`;

import { __, productService } from 'common-services';
import * as React from 'react';

import { IMAGES } from '../../../../assets';

import type { AxiosError } from 'axios';
import type {
  notificationsActions,
  IOrder,
  IOrderItem,
  IOrderIssue,
  ICustomItem,
  IPriceMode,
  modalActions,
  orderActions,
  IUser,
  ICart,
  IContact,
  IWorkspace,
  IClient,
  IPrice,
} from 'common-services';

interface UseOrderUpdatesProps {
  order?: IOrder;
  me: IUser;
  weAreSeller: boolean;
  weAreBuyer: boolean;
  cart: ICart;
  contact?: IContact;
  catalog?: IWorkspace;
  clients?: Array<IClient>;
  modalOpen: typeof modalActions.modalOpen;
  modalClose: typeof modalActions.modalClose;
  orderUpdate: (order: IOrder, userId: number, callback?: () => void) => void;
  orderUpdateIssues: (issue: IOrderIssue, orderId: number, userId: number, callback: (order: IOrder) => void) => void;
  orderItemFixIssue: typeof orderActions.orderItemFixIssue;
  cartUpdateItem: typeof orderActions.cartUpdateItem;
  notificationShow: typeof notificationsActions.notificationShow;
  setShowReferenceErrorTooltip: (show: boolean) => void;
  closeCart: () => void;
  cartClean: typeof orderActions.cartClean;
  orderGet: typeof orderActions.orderGet;
  orderClone: typeof orderActions.orderClone;
  orderUpdateExternal: typeof orderActions.orderUpdateExternal;
  cartSet: typeof orderActions.cartSet;
  prices?: { [key: number]: IPrice };
  navCloneAction: () => void;
}

interface UseOrderUpdatesState {
  canEditAfterAccept: boolean;
  setCanEditAfterAccept: (value: boolean) => void;
}

export interface UseOrderUpdatesReturn {
  updateOrder: (newOrder: IOrder) => void;
  updateOrderItem: (item: IOrderItem, issue?: IOrderIssue) => void;
  showSuccessUpdateModal: (order: IOrder) => void;
  showSuccessUpdateNotification: (order: IOrder) => void;

  resetOrder: () => void;
  cloneOrder: () => void;
  cleanCart: (dontUpdate?: boolean) => void;
  handleUpdateExternal: (newId: string) => void;
  cartKey: string | null;
}

/**
 * Hook to handle order updates and modifications
 */
const useOrderUpdates = ({
  order,
  me,
  weAreSeller,
  weAreBuyer,
  cart,
  contact,
  cartSet,
  clients,
  modalOpen,
  modalClose,
  orderUpdate,
  orderUpdateIssues,
  orderItemFixIssue,
  orderUpdateExternal,
  cartUpdateItem,

  notificationShow,
  closeCart,
  cartClean,
  orderGet,
  orderClone,
  prices,
  navCloneAction,
  setShowReferenceErrorTooltip,
  // State
  canEditAfterAccept,
  setCanEditAfterAccept,
}: UseOrderUpdatesProps & UseOrderUpdatesState): UseOrderUpdatesReturn => {
  // Memos

  /**
   * Memoize cart key calculation to avoid recreating it
   * when the component re-renders
   * @returns {string | null}
   */
  const cartKey = React.useMemo(() => {
    if (!me?.id || !order?.sellerId || !order?.buyerId) return null;

    return productService.getKey(weAreSeller ? me.id : order?.sellerId, weAreBuyer ? me.id : order?.buyerId);
  }, [me.id, order?.sellerId, order?.buyerId, weAreSeller, weAreBuyer]);

  // Callbacks

  const cleanCart = React.useCallback(
    (dontUpdate?: boolean) => {
      cartClean(productService.getKey(weAreBuyer ? order?.sellerId : me.id, order?.buyerId), dontUpdate);
    },
    [cartClean, weAreBuyer, order, me.id],
  );

  const resetOrder = React.useCallback(() => {
    orderGet(me.id, order?.id);
    setCanEditAfterAccept(false);
  }, [me.id, order?.id, orderGet, setCanEditAfterAccept]);

  const cloneOrder = React.useCallback(() => {
    {
      orderClone(
        {
          ...order,
          items: order.items.filter(i => i.servedQuantity > 0),
          externalIdSeller: '',
          externalIdBuyer: '',
        },
        contact,
        prices,
        () => {
          navCloneAction();
        },
      );
    }
  }, [order, contact, prices, navCloneAction, orderClone]);

  /**
   * Show success update modal
   */
  const showSuccessUpdateModal = React.useCallback(
    (updatedOrder: IOrder) => {
      const contactId = weAreSeller ? order?.buyerId : order?.sellerId;
      const client = clients?.find(c => c.userId === contactId);

      modalOpen(
        __('Components.OrderDetails.success_update.title', {
          hashId: updatedOrder.externalIdSeller || order?.externalIdBuyer || '#' + updatedOrder.hashId,
        }),
        modalClose,
        {
          text2: __('Components.OrderDetails.success_update.description', {
            name: contact?.name || client?.name,
          }),
          buttonText: __('Components.OrderDetails.success_update.cta'),
          icon: IMAGES.checkOK,
        },
        'nice',
      );
    },
    [order, weAreSeller, contact, clients, modalOpen, modalClose],
  );

  /**
   * Show success update notification
   */
  const showSuccessUpdateNotification = React.useCallback(
    (_: IOrder) => {
      notificationShow(
        {
          style: 'success',
          title: __('Components.OrderDetails.success_serve_update.title'),
          subtitle: '',
          closable: true,
        },
        5000,
      );
    },
    [notificationShow],
  );

  /**
   * Handle main order update
   */
  const updateOrder = React.useCallback(
    (newOrder: IOrder) => {
      const contactId = weAreSeller ? order?.buyerId : order?.sellerId;

      // Handle price mismatch issues first
      const mismatchPriceIssues = order?.issues.filter(i => i.type === 'no-pricegroup-matching');
      mismatchPriceIssues.forEach(mismatchPriceIssue => {
        const item = order?.items.find(oi => mismatchPriceIssue.orderItemId === oi.id && oi.price !== oi.servedPrice);
        if (item) {
          orderUpdateIssues({ ...mismatchPriceIssue, status: 'solved' }, order?.id, me.id, () => {
            orderItemFixIssue(me.id, order?.id, item, 0, mismatchPriceIssue.id, false, item.price, false);
          });
        }
      });

      // Update order
      orderUpdate(newOrder, me.id, () => {
        if (contactId && (order?.changedItems || order?.changedCustomItems)) {
          if (canEditAfterAccept) {
            setCanEditAfterAccept(false);
            showSuccessUpdateNotification(order);
            return;
          }
          closeCart();
          showSuccessUpdateModal(order);
        }
      });
    },
    [
      order,
      me.id,
      weAreSeller,
      canEditAfterAccept,
      orderUpdate,
      orderUpdateIssues,
      orderItemFixIssue,
      closeCart,
      showSuccessUpdateModal,
      showSuccessUpdateNotification,
      setCanEditAfterAccept,
    ],
  );

  /**
   * Handle order item update
   */
  const updateOrderItem = React.useCallback(
    (item: IOrderItem, issue?: IOrderIssue) => {
      if (!order) return;

      const contactId = weAreBuyer ? order?.sellerId : order?.buyerId;
      const newItem = { ...item, totalPrice: 0 }; // Reset total price to force recalculation

      // Update cart item
      cartUpdateItem(cart.key, contactId, newItem, weAreSeller, cart.priceMode);

      // Update order products
      const items = [...order.items];
      const idx = items.findIndex(i => (item.childId ? i.childId === item.childId : i.title === item.title));

      if (idx !== -1) {
        items[idx] = newItem;
      } else {
        items.push(newItem);
      }

      orderUpdate({ ...order, items }, me.id);

      // Handle issue if provided
      if (issue) {
        orderUpdateIssues({ ...issue, status: 'solved' }, order?.id, me.id, _ => {
          orderItemFixIssue(me.id, order?.id, item, 0, issue.id, false, item.price, false);
        });
      }
    },
    [order, me.id, weAreBuyer, weAreSeller, cart, cartUpdateItem, orderUpdate, orderUpdateIssues, orderItemFixIssue],
  );

  const handleUpdateExternal = React.useCallback(
    (newId: string) => {
      if (order?.id && order?.id !== 0) {
        orderUpdateExternal(me.id, order?.id, newId, (err: AxiosError) => {
          if (!err) {
            if (weAreSeller) {
              order.externalIdSeller = newId;
            } else {
              order.externalIdBuyer = newId;
            }
            notificationShow(
              {
                style: 'success',
                title: __('Components.OrderDetails.update_hash'),
                subtitle: '',
                closable: true,
              },
              5000,
            );
          } else {
            setShowReferenceErrorTooltip(true);
            // Handle error
            notificationShow({
              style: 'error',
              title: __('Error.generic'),
              subtitle: err.message,
              closable: true,
            });
          }
        });
      } else {
        const contactId = weAreBuyer ? order?.sellerId : order?.buyerId;
        const newOrder = {
          ...order,
          externalIdSeller: weAreSeller ? newId : order?.externalIdSeller,
          externalIdBuyer: weAreSeller ? order?.externalIdBuyer : newId,
        };
        cartSet(cartKey, newOrder, contactId);
      }
    },
    [
      order,
      weAreSeller,
      me.id,
      orderUpdateExternal,
      notificationShow,
      setShowReferenceErrorTooltip,
      cartSet,
      weAreBuyer,
      cartKey,
    ],
  );

  return {
    cleanCart,
    resetOrder,
    cloneOrder,
    updateOrder,
    updateOrderItem,
    showSuccessUpdateModal,
    showSuccessUpdateNotification,
    handleUpdateExternal,
    cartKey,
  };
};

export default useOrderUpdates;

import {
  __,
  addresses,
  buyerWorkspaceActions,
  chatSelectors,
  constants,
  countrySelectors,
  orderActions,
  orderSelectors,
  productSelectors,
  sellerWorkspaceActions,
  sellerWorkspaceSelectors,
  userSelectors,
} from 'common-services';
import { addBusinessDays } from 'date-fns';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import config from '../../../../bindings/config';
import { THIRD_COLUMN_SECTIONS } from '../../../constants';
import { Button, DatePicker, ExpansionPanel, Radio, Select, TextArea } from '../../atoms';
import { ActionsModal, AddressAutoComplete, InputWithLabel, ThirdColumnHeader } from '../../molecules';

import * as S from './OrderLogistics.styled';

import type { IAddress, IContact, ILogistics, IOrder } from 'common-services';
import type { Dispatch } from 'redux';

export interface IProps {
  contact?: IContact;
  match: {
    params?: { channelId?: string };
  };
  navChannelAction?: (channelId: string, section?: string, sectionId?: number) => void;
  navOrderAction?: (section?: THIRD_COLUMN_SECTIONS, orderId?: number) => void;
  orderId: number;
  weAreSeller: boolean;
}

const selectFromOpt = Array.from(Array(24)).map((_, i) => {
  const v = ('0' + i + ':00').substring(i >= 10 ? 1 : 0);
  return {
    value: v,
    label: v,
  };
});
const selectToOpt = Array.from(Array(24)).map((_, i) => {
  const v = ('0' + i + ':59').substring(i >= 10 ? 1 : 0);
  return {
    value: v,
    label: v,
  };
});

const OrderLogistics: React.FC<IProps> = ({ match, orderId, navChannelAction, navOrderAction, weAreSeller }) => {
  const dispatch = useDispatch<Dispatch<any>>(); // eslint-disable-line @typescript-eslint/no-explicit-any

  const channelId = match?.params?.channelId;

  const order: IOrder = useSelector(
    orderSelectors.getOrder(orderId) ||
      ((channelId ? chatSelectors.getOrder(channelId, orderId) : () => undefined) as any), // eslint-disable-line @typescript-eslint/no-explicit-any
  );
  const countries = useSelector(countrySelectors.getCountries);
  const me = useSelector(userSelectors.getUser);
  const myId = me?.id;
  const catalog = useSelector(
    weAreSeller ? sellerWorkspaceSelectors.getCatalog(order?.catalogId) : productSelectors.getCatalog(order?.catalogId),
  );
  const { buyerId, sellerId } = order || {};
  const role = useSelector(sellerWorkspaceSelectors.getRoleInCatalog(order?.catalogId, me?.id));

  const [shippingAddressesState, setShippingAddressesState] = React.useState<Array<IAddress>>([]);
  const [pickupLocationsState, setPickupLocationsState] = React.useState<Array<IAddress>>([]);
  const [showRange, setShowRange] = React.useState<boolean>();
  const [minDate, setMinDate] = React.useState<{ deliveryAt: number; shippingAt: number }>({
    deliveryAt: 0,
    shippingAt: 0,
  });
  const [newShippingAddress, setNewShippingAddress] = React.useState<IAddress>();
  const [newPickupAddress, setNewPickupAddress] = React.useState<IAddress>();
  React.useEffect(() => setShowAddressModal(undefined), [orderId]);
  const [updatedLogistics, setUpdatedLogistics] = React.useState<ILogistics>(getInitialLogistic(order));
  const [hideDates, setHideDates] = React.useState<boolean>(true);
  const [showAddressModal, setShowAddressModal] = React.useState<'shipping' | 'pickup' | undefined>();
  const {
    deliverTo,
    deliveryEstimatedAt,
    loadEstimatedAt,
    deliveryEstimatedTimeRange,
    deliveryMethod = 'shipping',
    incoterms,
    pickupEstimatedAt,
    pickupEstimatedTimeRange,
    pickupFrom,
    transportDetails,
  } = updatedLogistics;

  const updateLogistics = React.useCallback(
    (data: Partial<ILogistics>) => {
      setUpdatedLogistics({ ...updatedLogistics, ...data });
      dispatch(orderActions.orderUpdateLogistics({ ...updatedLogistics, ...data }, orderId, myId));
    },
    [dispatch, myId, orderId, updatedLogistics],
  );

  React.useEffect(
    () => {
      getDefaultAddress(!deliverTo && !pickupFrom);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const catalogId = order?.catalogId;
  React.useEffect(() => {
    dispatch(
      sellerWorkspaceActions.getMinimunDeliveryDate(
        order?.catalogId,
        order?.sellerId,
        Date.now(),
        order?.deliveryMethod,
        min => {
          if (!deliveryEstimatedAt) {
            updateLogistics({ deliveryEstimatedAt: new Date(min.shippingAt || Date.now()).toISOString() });
          }
          if (!pickupEstimatedAt) {
            updateLogistics({ pickupEstimatedAt: new Date(min.deliveryAt || Date.now()).toISOString() });
          }
          if (!loadEstimatedAt) {
            updateLogistics({ loadEstimatedAt: new Date(min.deliveryAt || Date.now()).toISOString() });
          }
          setMinDate(min);
          setHideDates(false);
        },
      ),
    );
  }, [
    catalogId,
    sellerId,
    deliveryMethod,
    dispatch,
    order?.catalogId,
    order?.sellerId,
    order?.deliveryMethod,
    deliveryEstimatedAt,
    pickupEstimatedAt,
    loadEstimatedAt,
    updateLogistics,
  ]);

  const close = (): void =>
    showAddressModal
      ? setShowAddressModal(undefined)
      : channelId
      ? navChannelAction(channelId, THIRD_COLUMN_SECTIONS.ORDER_DETAILS, orderId)
      : navOrderAction(THIRD_COLUMN_SECTIONS.ORDER_DETAILS, order?.id);
  const shippingAddressesMerged = getShippingAddresses(order, shippingAddressesState);

  if (newShippingAddress && !shippingAddressesMerged.find(adr => addresses.addressEquals(adr, newShippingAddress))) {
    shippingAddressesMerged.push(newShippingAddress);
  }
  const shippingAddressesSelect = getShippingAddressesSelect();
  const shippingAddressOpt = deliverTo
    ? shippingAddressesSelect.find(opt => opt.labelForOrder === addresses.getAddressName(deliverTo, countries, false))
    : undefined;
  const pickupAddresses = getPickupLocations(order, pickupLocationsState);
  if (newPickupAddress && !pickupAddresses.find(adr => addresses.addressEquals(adr, newPickupAddress))) {
    pickupAddresses.push(newPickupAddress);
  }
  const pickupLocationsSelect = getPickupLocationsSelect();
  const pickupLocationOpt = pickupFrom
    ? pickupLocationsSelect.find(opt => opt.labelForOrder === addresses.getAddressName(pickupFrom, countries, false))
    : undefined;
  const initDeliveryDate = new Date(deliveryEstimatedAt ? Date.parse(deliveryEstimatedAt) : Date.now());
  const initPickupDate = new Date(pickupEstimatedAt ? Date.parse(pickupEstimatedAt) : Date.now());
  const initLoadDate = new Date(loadEstimatedAt ? Date.parse(loadEstimatedAt) : Date.now());
  const getDefaultAddress = React.useCallback(
    (update: boolean) => {
      dispatch(
        orderActions.getLogisticsAddresses(buyerId, sellerId, (pickupAddrs, shpAddrs) => {
          setShippingAddressesState(shpAddrs);
          setPickupLocationsState(pickupAddrs);
          if (update) {
            if (shippingAddressesSelect.length <= 1 && shpAddrs.length === 1) {
              updateLogistics({ deliverTo: shpAddrs[0] });
            }
            if (pickupLocationsSelect.length <= 1 && pickupAddrs.length === 1) {
              updateLogistics({ pickupFrom: pickupAddrs[0] });
            }
          }
        }),
      );
    },
    [buyerId, dispatch, pickupLocationsSelect.length, sellerId, shippingAddressesSelect.length, updateLogistics],
  );
  return (
    <S.Container>
      <ThirdColumnHeader
        id="third-column-header"
        title={showAddressModal ? __('Components.AddNewAddress.title') : __('Components.OrderLogistics.title')}
        closeAction={close}
      />

      <S.ScrollContainer>
        <S.MinContentContainer minHeight="min-content" width="100%" flexWrap="nowrap">
          <S.BodyContainer>
            <ExpansionPanel title={__('OrderLogistics.delivery_type.title')} iconPosition="left" defaultExpanded={true}>
              <S.SectionContainer>
                <Radio
                  name="delivery_type"
                  direction="column"
                  options={[
                    {
                      label: __('OrderLogistics.delivery_type.delivery_label'),
                      value: 'shipping',
                    },
                    {
                      label: __('OrderLogistics.delivery_type.pickup_label'),
                      value: 'pickup',
                    },
                  ]}
                  containerMargin="4px 0"
                  tooltipType="image"
                  alignItems="flex-start"
                  styleLabel={{ margin: '5px 0' }}
                  itemsChecked={[deliveryMethod]}
                  onChange={(key, value: 'pickup' | 'shipping') => {
                    getDefaultAddress(true);
                    if (value === 'shipping') {
                      updateLogistics({
                        deliveryMethod: 'shipping',
                        deliverTo: shippingAddressesState.length === 1 ? shippingAddressesState[0] : undefined,
                      });
                    } else {
                      updateLogistics({
                        deliverTo: undefined,
                        deliveryEstimatedAt: undefined,
                        deliveryMethod: 'pickup',
                        pickupFrom: pickupLocationsState.length === 1 ? pickupLocationsState[0] : undefined,
                      });
                    }
                    setShowRange(false);
                  }}
                />
              </S.SectionContainer>
            </ExpansionPanel>
            {deliveryMethod === 'shipping' ? (
              <ExpansionPanel
                title={__('OrderLogistics.delivery_order.title')}
                iconPosition="left"
                defaultExpanded={true}
              >
                <S.SectionContainer>
                  <InputWithLabel
                    isRequired={true}
                    label={__('OrderLogistics.delivery_order.delivery_address')}
                    width="100%"
                    align="flex-start"
                  >
                    {shippingAddressesSelect?.length > 1 ? (
                      <Select
                        name="deliverTo"
                        value={shippingAddressOpt?.value || ''}
                        options={shippingAddressesSelect.map(
                          (opt: { label: string; tooltip?: string; tooltip_position?: string }) => {
                            if (opt.label.length > 35) {
                              opt.tooltip = opt.label;
                              opt.tooltip_position = 'left';
                            }
                            return opt;
                          },
                        )}
                        onChange={(name, value) => {
                          const addressSelected = shippingAddressesMerged.find(
                            adr => addresses.getAddressKey(adr) === value,
                          );
                          updateLogistics({ deliverTo: addressSelected });
                        }}
                        width="100%"
                      />
                    ) : null}
                    {role !== 'viewer' ? (
                      <Button
                        id="show-address-link"
                        onClick={() => setShowAddressModal('shipping')}
                        type="link"
                        iconName="Add-more"
                        iconSize="18px"
                        withoutPadding={true}
                      >
                        {__('Components.OrderLogistics.add_new_address')}
                      </Button>
                    ) : null}
                  </InputWithLabel>
                  {config.TOGGLE_LOAD_DATE?.enabled ? (
                    <S.LoadRow>
                      <InputWithLabel
                        isRequired={true}
                        label={__('Components.OrderDetails.transport.Load.date')}
                        width="100%"
                        align="flex-start"
                      >
                        {hideDates ? null : (
                          <DatePicker
                            id="load_date"
                            initDate={initLoadDate}
                            minDate={new Date(Date.now() > minDate.deliveryAt ? Date.now() : minDate.deliveryAt)}
                            onDateChange={newDate => {
                              updateLogistics({ loadEstimatedAt: newDate ? newDate : '' });
                            }}
                            openDate={addBusinessDays(Date.now(), 1)}
                          />
                        )}
                      </InputWithLabel>
                    </S.LoadRow>
                  ) : null}
                  <InputWithLabel
                    isRequired={true}
                    label={__('OrderLogistics.delivery_order.delivery_date')}
                    width="100%"
                    align="flex-start"
                  >
                    {hideDates ? null : (
                      <DatePicker
                        id="delivery_date"
                        initDate={initDeliveryDate}
                        minDate={new Date(Date.now() > minDate.deliveryAt ? Date.now() : minDate.deliveryAt)}
                        onDateChange={newDate => {
                          const newPickupEstimatedAt = new Date(newDate);
                          newPickupEstimatedAt.setDate(
                            newPickupEstimatedAt.getDate() - catalog?.estimatedTransportTime,
                          );
                          updateLogistics({
                            deliveryEstimatedAt: newDate ? newDate : '',
                            pickupEstimatedAt: newDate ? newPickupEstimatedAt.toISOString() : '',
                          });
                        }}
                        openDate={addBusinessDays(Date.now(), 1)}
                      />
                    )}
                  </InputWithLabel>
                  {weAreSeller ? (
                    deliveryEstimatedTimeRange.length || showRange ? (
                      <S.Row>
                        <InputWithLabel
                          isRequired={true}
                          label={__('OrderLogistics.time_from')}
                          width="45%"
                          align="flex-start"
                        >
                          <Select
                            name="deliverFrom"
                            value={deliveryEstimatedTimeRange[0]}
                            options={selectFromOpt.filter(
                              d =>
                                !deliveryEstimatedTimeRange[1] ||
                                Number(d.value.substring(0, 2)) <=
                                  Number(deliveryEstimatedTimeRange[1]?.substring(0, 2)),
                            )}
                            onChange={(name, value) => {
                              updateLogistics({
                                deliveryEstimatedTimeRange: [value as string, deliveryEstimatedTimeRange[1]],
                              });
                            }}
                            width="100%"
                          />
                        </InputWithLabel>
                        <InputWithLabel
                          isRequired={true}
                          label={__('OrderLogistics.time_to')}
                          width="45%"
                          align="flex-start"
                        >
                          <Select
                            name="deliverTo"
                            value={deliveryEstimatedTimeRange[1]}
                            options={selectToOpt.filter(
                              d =>
                                !deliveryEstimatedTimeRange?.[0] ||
                                Number(d.value.substring(0, 2)) >=
                                  Number(deliveryEstimatedTimeRange[0]?.substring(0, 2)),
                            )}
                            onChange={(name, value) => {
                              updateLogistics({
                                deliveryEstimatedTimeRange: [deliveryEstimatedTimeRange[0], value as string],
                              });
                            }}
                            width="100%"
                          />
                        </InputWithLabel>
                      </S.Row>
                    ) : (
                      <S.ScheduleButton
                        id="show_range_delivery"
                        onClick={() => setShowRange(true)}
                        type="link"
                        iconName="Add-more"
                        iconSize="18px"
                        withoutPadding={true}
                      >
                        {__('OrderLogistics.delivery_order.range')}
                      </S.ScheduleButton>
                    )
                  ) : null}
                </S.SectionContainer>
              </ExpansionPanel>
            ) : (
              <ExpansionPanel
                title={__('OrderLogistics.pickup_order.title')}
                iconPosition="left"
                defaultExpanded={true}
              >
                <S.SectionContainer>
                  {weAreSeller ? (
                    <InputWithLabel
                      isRequired={true}
                      label={__('OrderLogistics.pickup_order.pickup_address')}
                      width="100%"
                      align="flex-start"
                    >
                      {pickupLocationsSelect?.length > 1 ? (
                        <Select
                          name="pickupFrom"
                          value={pickupLocationOpt?.value || ''}
                          options={pickupLocationsSelect.map(
                            (opt: { label: string; tooltip?: string; tooltip_position?: string }) => {
                              if (opt.label.length > 35) {
                                opt.tooltip = opt.label;
                                opt.tooltip_position = 'left';
                              }
                              return opt;
                            },
                          )}
                          onChange={(name, value) => {
                            const addressSelected = pickupAddresses.find(adr => addresses.getAddressKey(adr) === value);
                            updateLogistics({
                              pickupFrom: addressSelected,
                            });
                          }}
                          width="100%"
                        />
                      ) : null}
                      <Button
                        id="show-address-link"
                        onClick={() => setShowAddressModal('pickup')}
                        type="link"
                        iconName="Add-more"
                        iconSize="18px"
                        withoutPadding={true}
                      >
                        {__('Components.OrderLogistics.add_new_address')}
                      </Button>
                    </InputWithLabel>
                  ) : null}
                  <InputWithLabel
                    isRequired={true}
                    label={__('OrderLogistics.pickup_order.pickup_date')}
                    width="100%"
                    align="flex-start"
                  >
                    {hideDates ? null : (
                      <DatePicker
                        id="pickup_date"
                        initDate={initPickupDate}
                        minDate={new Date(Date.now() > minDate.shippingAt ? Date.now() : minDate.shippingAt)}
                        onDateChange={newDate => updateLogistics({ pickupEstimatedAt: newDate ? newDate : '' })}
                        openDate={addBusinessDays(Date.now(), 1)}
                      />
                    )}
                  </InputWithLabel>
                  {weAreSeller ? (
                    pickupEstimatedTimeRange.length || showRange ? (
                      <S.Row>
                        <InputWithLabel
                          isRequired={true}
                          label={__('OrderLogistics.time_from')}
                          width="45%"
                          align="flex-start"
                        >
                          <Select
                            name="pickupFrom"
                            value={pickupEstimatedTimeRange[0]}
                            options={selectFromOpt}
                            onChange={(name, value) => {
                              updateLogistics({
                                pickupEstimatedTimeRange: [value as string, pickupEstimatedTimeRange[1]],
                              });
                            }}
                            width="100%"
                          />
                        </InputWithLabel>
                        <InputWithLabel
                          isRequired={true}
                          label={__('OrderLogistics.time_to')}
                          width="45%"
                          align="flex-start"
                        >
                          <Select
                            name="pickupTo"
                            value={pickupEstimatedTimeRange[1]}
                            options={selectToOpt}
                            onChange={(name, value) => {
                              updateLogistics({
                                pickupEstimatedTimeRange: [pickupEstimatedTimeRange[0], value as string],
                              });
                            }}
                            width="100%"
                          />
                        </InputWithLabel>
                      </S.Row>
                    ) : (
                      <Button
                        id="show_range_pickup"
                        onClick={() => setShowRange(true)}
                        type="link"
                        iconName="Add-more"
                        iconSize="18px"
                        withoutPadding={true}
                      >
                        {__('OrderLogistics.pickup_order.range')}
                      </Button>
                    )
                  ) : null}
                </S.SectionContainer>
              </ExpansionPanel>
            )}
            <ExpansionPanel
              title={__('OrderLogistics.transport_details.title')}
              iconPosition="left"
              defaultExpanded={true}
            >
              <S.SectionContainer>
                <InputWithLabel
                  isRequired={true}
                  description={__('OrderLogistics.transport_details.label')}
                  width="100%"
                  align="flex-start"
                >
                  <TextArea
                    name="details"
                    value={transportDetails || ''}
                    onChange={(name, value) => updateLogistics({ transportDetails: value as string })}
                    placeholder={__('OrderLogistics.transport_details.placeholder')}
                    width="100%"
                    rows={3}
                  />
                </InputWithLabel>
              </S.SectionContainer>
            </ExpansionPanel>
            <ExpansionPanel title={__('OrderLogistics.incoterms.title')} iconPosition="left" defaultExpanded={true}>
              <S.SectionContainer>
                <InputWithLabel
                  isRequired={true}
                  description={__('OrderLogistics.incoterms.label')}
                  width="100%"
                  align="flex-start"
                >
                  <Select
                    name="incoterms"
                    value={incoterms.length ? incoterms[0] : ''}
                    options={getIncotermsSelect()}
                    onChange={(name, val) => updateLogistics({ incoterms: [val] })}
                    width="100%"
                    dropUp={true}
                  />
                </InputWithLabel>
              </S.SectionContainer>
            </ExpansionPanel>
          </S.BodyContainer>
        </S.MinContentContainer>
      </S.ScrollContainer>
      {showAddressModal ? renderAddressModal() : null}
    </S.Container>
  );

  /**
   * Renders address form in the third column
   */
  function renderAddressModal(): JSX.Element {
    const address = deliveryMethod === 'pickup' ? newPickupAddress : newShippingAddress;
    return createPortal(
      <ActionsModal
        onClose={() => setShowAddressModal(undefined)}
        title={address?.id ? __('Components.AddNewAddress.title_edit') : __('Components.AddNewAddress.title')}
      >
        <AddressAutoComplete
          countries={countries}
          me={me}
          deliveryMethod={deliveryMethod as 'shipping' | 'pickup'}
          onSubmit={(addr: IAddress) => onSubmitNewAddress(addr)}
          onCancel={() => setShowAddressModal(undefined)}
          weightUnit={order?.weightUnit}
        />
      </ActionsModal>,
      document.body,
    );
  }

  /**
   * On submit new address.
   * Update order address (pickup / shipping).
   * Might save it to personal addresses in some cases explained below.
   */
  function onSubmitNewAddress(address: IAddress): void {
    if (address.type === 'pickup') {
      updateLogistics({
        pickupFrom: address,
      });
      setNewPickupAddress(address);
    } else if (address.type === 'shipping') {
      updateLogistics({ deliverTo: address });
      setNewShippingAddress(address);
    }
    // Save address in my settings in these cases:
    // 1. Adding pickup address and is seller
    // 2. Adding shipping address and is buyer
    if (
      (!addresses.isAddressEmpty(address) && address.type === 'pickup' && weAreSeller) ||
      (address.type === 'shipping' && !weAreSeller)
    ) {
      dispatch(
        buyerWorkspaceActions.workspaceAddressAdd(
          myId,
          weAreSeller ? order?.catalogId : order?.buyerWorkspaceId,
          address,
        ),
      );
    } else if (address.type === 'shipping' && weAreSeller) {
      dispatch(sellerWorkspaceActions.clientAddressAdd(myId, order?.catalogId, order?.buyerId, address));
    }
    setShowAddressModal(undefined);
  }

  /**
   * Get shipping locations for select with newAddress and default option.
   * If the address belongs to my addresses, show displayName otherwise show the concatenated version.
   * Sort them alphabetically on their label.
   */
  function getShippingAddressesSelect(): Array<{
    label: string;
    value: string;
    labelForOrder: string;
    isDefault: boolean;
  }> {
    const result = shippingAddressesMerged
      .filter((addr, idx) => shippingAddressesMerged.findIndex(a => a.id === addr.id) === idx)
      .map(adr => ({
        value: addresses.getAddressKey(adr),
        labelForOrder: addresses.getAddressName(adr, countries, false),
        label:
          addresses.getAddressName(adr, countries) + (adr.isDefault ? ` (${__('Components.Settings.default')})` : ''),
        isDefault: adr.isDefault,
      }));
    result.sort((a1, a2) => {
      if (a1.label > a2.label) return 1;
      if (a1.label < a2.label) return -1;
      return 0;
    });
    return [
      {
        label: __('Components.OrderLogistics.select_option'),
        value: '',
        labelForOrder: __('Components.OrderLogistics.select_option'),
        isDefault: false,
      },
      ...result,
    ];
  }

  /**
   * Get pickup locations for select with newAddress and default option.
   * Sort them alphabetically on their label.
   */
  function getPickupLocationsSelect(): Array<{ label: string; value: string; labelForOrder: string }> {
    const result = pickupAddresses.map(adr => ({
      value: addresses.getAddressKey(adr),
      labelForOrder: addresses.getAddressName(adr, countries, false),
      label: addresses.getAddressName(adr, countries),
    }));
    result.sort((a1, a2) => {
      if (a1.label > a2.label) return 1;
      if (a1.label < a2.label) return -1;
      return 0;
    });
    return [
      ...[
        {
          label: __('Components.OrderLogistics.select_option'),
          value: '',
          labelForOrder: __('Components.OrderLogistics.select_option'),
        },
      ],
      ...result,
    ];
  }
};

/**
 * Get initial logistic object from order
 */
function getInitialLogistic(order: IOrder): ILogistics {
  const shipping = (order?.customItems || []).find(ci => ci.type === 'shipping');
  return {
    shippingCost: (shipping && shipping.price) || 0,
    deliverTo: order?.deliverTo,
    incoterms: order?.incoterms || [],
    deliveryMethod: order?.deliveryMethod,
    pickupFrom: order?.pickupFrom,
    deliveryEstimatedAt: order?.deliveryEstimatedAt,
    deliveryEstimatedSchedule: order?.deliveryEstimatedSchedule,
    deliveryEstimatedTimeRange: order?.deliveryEstimatedTimeRange || [],
    loadEstimatedAt: order?.loadEstimatedAt,
    pickupEstimatedAt: order?.pickupEstimatedAt,
    pickupEstimatedSchedule: order?.pickupEstimatedSchedule,
    pickupEstimatedTimeRange: order?.pickupEstimatedTimeRange || [],
    transportDetails: order?.transportDetails,
  };
}

/**
 * Get pickup locations from state & order
 */
function getPickupLocations(order: IOrder, addrs: Array<IAddress>): Array<IAddress> {
  const { pickupFrom } = order || {};

  const pickupLocations = [...addrs];
  if (pickupFrom && !pickupLocations.find(loc => addresses.addressEquals(loc, pickupFrom)))
    pickupLocations.push({ ...pickupFrom, displayName: '' });

  return pickupLocations;
}

/**
 * Get shipping locations from state & order
 */
function getShippingAddresses(order: IOrder, addrs: Array<IAddress>): Array<IAddress> {
  const { deliverTo } = order || {};

  const shippingAddresses = [...addrs];
  if (deliverTo && !shippingAddresses.find(adr => addresses.addressEquals(adr, deliverTo)))
    shippingAddresses.push({ ...deliverTo, displayName: '' });

  return shippingAddresses;
}

/**
 * Get list of incoterms for select options
 */
function getIncotermsSelect(): Array<{
  label: string;
  value: string;
}> {
  return [{ label: __('Components.OrderLogistics.select_option'), value: '' }, ...constants.INCOTERMS_SHIPPING];
}

export default React.memo(OrderLogistics);

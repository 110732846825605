import * as React from 'react';

// Define possible scroll targets
export enum ScrollSection {
  COMMENTS = 'cart-comments',
  HEADER = 'cart-header',
  ITEMS = 'cart-items',
  SUMMARY = 'cart-summary',
  // Add more sections as needed
}

interface ScrollOptions {
  behavior?: ScrollBehavior;
  block?: ScrollLogicalPosition;
  inline?: ScrollLogicalPosition;
}

interface UseScrollToSectionProps<T = unknown> {
  // Generic type T for callback parameters
  sectionId: ScrollSection;
  containerId?: string;
  offset?: number;
  callback?: (args: T) => void | Promise<void>;
  scrollOptions?: ScrollOptions;
  // Optional dependencies to trigger scroll ref update
  deps?: React.DependencyList;
}

export interface UseScrollToSectionReturn<T = unknown> {
  navigateToSection: (args: T) => () => Promise<boolean>;
  targetElement: HTMLElement;
}

function useScrollToSection<T>({
  sectionId,
  containerId = 'order-body-scroll', // Default to your main container ID
  offset = 40, // Increase default offset
  scrollOptions = {
    behavior: 'smooth',
    block: 'nearest',
    inline: 'start',
  },
  deps = [],
  callback,
}: UseScrollToSectionProps<T>): UseScrollToSectionReturn {
  // Keep reference to the element
  const elementRef = React.useRef<HTMLElement | null>(null);

  // Update ref when dependencies change
  React.useEffect(() => {
    elementRef.current = document.getElementById(sectionId);
  }, [sectionId, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps

  // Scroll handler
  const navigateToSection = React.useCallback(
    (args: T) => async () => {
      const element = elementRef.current || document.getElementById(sectionId);
      const container = document.getElementById(containerId);
      if (element) {
        try {
          // Get container's current scroll position
          const containerScrollTop = container.scrollTop;
          // Get element's position relative to the container
          const elementTop = element.getBoundingClientRect().top;
          const containerTop = container.getBoundingClientRect().top;
          const relativeElementTop = elementTop - containerTop;

          // Calculate the new scroll position
          const scrollPosition = containerScrollTop + relativeElementTop - offset;

          const scroll = {
            top: Math.max(0, scrollPosition),
            behavior: scrollOptions.behavior || 'smooth',
          };
          // Scroll with offset
          container.scrollTo(scroll);
        } catch (error) {
          console.error('Error during scroll operation:', error);
          return false;
        }

        if (callback) {
          await callback(args);
        }
      }
      return false;
    },
    [sectionId, offset, callback, scrollOptions, containerId],
  );

  return {
    navigateToSection,
    targetElement: elementRef.current,
  };
}

export default useScrollToSection;

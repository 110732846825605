import {
  __,
  addresses,
  currency,
  CURRENCY_CODES,
  date,
  DATE_TYPE,
  ORDER_ORIGIN,
  ORDER_STATUS,
  orderService,
  SCHEDULE,
  utils,
  WEIGHT_UNIT,
} from 'common-services';
import * as React from 'react';

import config from '../../../../../../../../bindings/config';
import { CHANNEL_SECTIONS } from '../../../../../../../constants';
import { DatePicker } from '../../../../../../atoms';
import { ActionsModal, AddressAutoComplete, AddressModal, UpdateConfirmationModal } from '../../../../../../molecules';
import { useOrderContext } from '../../../../context';
import * as Common from '../../../commons.styled';

import * as S from './TransportCard.styled';

import type { IAddress, IDeliveryMethodType, IOrderIssue } from 'common-services';

interface AddressState {
  id?: number;
  section?: CHANNEL_SECTIONS;
}

const TransportCard: React.FC = () => {
  const {
    me,
    order,
    contact,
    catalog,
    countries,
    amEditor,
    amViewer,
    hasItems,
    addDeliveryAddress,
    addPickupAddress,
    logisticAddresses,
    pickupAddresses,
    setShowLogistic, // TODO:2 rename to openLogisticCard
    deliveryAddressIssue,
    pickupAddressIssue,
    orderUpdateIssues,
    orderAddressFixIssue,
    orderDateFixIssue,
    customItems,
  } = useOrderContext();

  const showLogisticCard = (): void => setShowLogistic(true);

  // State management
  const [addressSelected, setAddressSelected] = React.useState<string>();
  const [pickupSelected, setPickupSelected] = React.useState<string>();
  const [showUpdateConfirmation, setShowUpdateConfirmation] = React.useState(false);
  const [deliveryAddress, setDeliveryAddress] = React.useState<AddressState>({});
  const [pickupAddress, setPickupAddress] = React.useState<AddressState>({});
  const [showSearchAddress, setShowSearchAddress] = React.useState(false);

  const currencyCode = order?.currency || CURRENCY_CODES.EUR;
  const weightUnit = catalog?.defaultWeightUnit || WEIGHT_UNIT.KG;

  // Calculate transport state
  // const isTransportEditable = React.useMemo(() => {
  //   if (!catalog) return false;
  //   if (order?.status === ORDER_STATUS.DRAFT) return true;
  //   return false;
  // }, [catalog, order?.status]);

  // Check if addresses are required
  // const isAddressRequired = React.useMemo(() => {
  //   return catalog?.mandatoryDeliveryAddressByClient || catalog?.mandatoryPickupAddressByClient;
  // }, [catalog]);

  // Memoized values
  const transportAdded = React.useMemo(() => {
    if (!order) return false;
    const { customItems, pickupFrom, deliverTo, incoterms, deliveryEstimatedAt, pickupEstimatedAt } = order;
    const shipping = (customItems || []).find(ci => ci.type === 'shipping');
    return (
      pickupFrom ||
      deliverTo ||
      deliveryEstimatedAt ||
      pickupEstimatedAt ||
      (shipping && shipping.price) ||
      (incoterms.length && incoterms[0])
    );
  }, [order]);

  const canEdit = React.useMemo(() => {
    return (
      (!transportAdded && order?.status !== 'canceled' && contact && !contact.imBlocking && !contact.imBlocked) ||
      (order?.status !== 'canceled' &&
        (contact || order?.origin === ORDER_ORIGIN.IMPORT_UI) &&
        !contact?.imBlocking &&
        !contact?.imBlocked &&
        !deliveryAddressIssue &&
        !pickupAddressIssue)
    );
  }, [transportAdded, order?.status, order?.origin, contact, deliveryAddressIssue, pickupAddressIssue]);

  // Handlers
  const handleAddressChange = React.useCallback(
    (fixAll: boolean, address: IAddress, addressType: 'shipping' | 'pickup') => {
      const addressIssue = addressType === 'shipping' ? deliveryAddressIssue : pickupAddressIssue;

      orderUpdateIssues({ ...addressIssue, status: 'solved' }, order?.id, me.id, o => {
        orderAddressFixIssue(me.id, o.id, address, addressType, addressIssue.id, fixAll);
      });

      setShowUpdateConfirmation(false);
      if (addressType === 'shipping') {
        setAddressSelected(undefined);
      } else {
        setPickupSelected(undefined);
      }
    },
    [deliveryAddressIssue, pickupAddressIssue, order?.id, me.id, orderUpdateIssues, orderAddressFixIssue],
  );

  const renderAddressIssue = React.useCallback(
    (
      addressType: 'shipping' | 'pickup',
      addressIssue: IOrderIssue,
      estimatedAt: string,
      estimatedSchedule: SCHEDULE,
      addressSelected?: string,
    ): JSX.Element => {
      // Format dates using memoization
      const estimatedDate = estimatedAt ? utils.capitalizeWords(date.formatLongDate(estimatedAt)) : '';

      const estimatedScheduleDisplay =
        !estimatedAt || !estimatedSchedule || estimatedSchedule === SCHEDULE.UNKNOWN
          ? ''
          : ` · ${orderService.getScheduleLabel(estimatedSchedule).toLocaleLowerCase()}`;

      const addressesList = addressType === 'shipping' ? logisticAddresses : pickupAddresses;

      // Find date issue
      const dateIssue = order?.issues.find(
        issue =>
          issue.type === 'date-not-valid' &&
          issue.code === (addressType === 'shipping' ? 'delivery_date' : 'pickup_date'),
      );

      // Handlers
      const handleAddressSelection = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const value = event.target.value;
        if (value === 'create-address') {
          if (addressType === 'shipping') {
            setDeliveryAddress({ section: CHANNEL_SECTIONS.ADDRESS_EDIT });
          } else {
            setPickupAddress({ section: CHANNEL_SECTIONS.ADDRESS_EDIT });
          }
        } else {
          if (addressType === 'shipping') {
            setAddressSelected(value);
            setShowUpdateConfirmation(true);
          } else {
            setPickupSelected(value);
            setShowUpdateConfirmation(true);
          }
        }
      };

      const handleSearchAddressClick = (): void => {
        setShowSearchAddress(true);
      };

      const handleDateChange = (newDate: string): void => {
        if (dateIssue) {
          orderDateFixIssue(
            me.id,
            order?.id,
            newDate,
            dateIssue.id,
            false,
            addressType === 'shipping' ? DATE_TYPE.DELIVERY : DATE_TYPE.PICKUP,
          );
        }
      };

      // Render helper functions
      const renderAddressSelector = (): JSX.Element | null => {
        if (!amEditor || order?.status === ORDER_STATUS.CANCELED) {
          return (
            <Common.IssueText>
              {addressType === 'shipping'
                ? __('Components.Cart.errors.delivery_address_not_assigned', { code: addressIssue.code })
                : __('Components.Cart.errors.pickup_address_not_assigned', { code: addressIssue.code })}
            </Common.IssueText>
          );
        }

        if (config.TOGGLE_SEARCH_ADDRESS.enabled) {
          return (
            <Common.IssueCTA onClick={handleSearchAddressClick}>
              {addressType === 'shipping'
                ? __('Components.Cart.errors.delivery_address_not_assigned', { code: addressIssue.code })
                : __('Components.Cart.errors.pickup_address_not_assigned', { code: addressIssue.code })}
            </Common.IssueCTA>
          );
        }

        return (
          <S.AddressesSelect onChange={handleAddressSelection} value={addressSelected || ''}>
            <S.SelectOption value="" disabled>
              {addressType === 'shipping'
                ? __('Components.Cart.errors.delivery_address_not_assigned', { code: addressIssue.code })
                : __('Components.Cart.errors.pickup_address_not_assigned', { code: addressIssue.code })}
            </S.SelectOption>
            {addressesList
              .sort((a, b) => {
                return a.displayName.localeCompare(b.displayName);
              })
              .map(addr => (
                <S.SelectOption key={addresses.getAddressKey(addr)} value={addresses.getAddressKey(addr)}>
                  {addresses.getAddressName(addr, countries)}
                </S.SelectOption>
              ))}
            {!amViewer && (
              <S.SelectOption value="create-address">
                {__('Components.OrderDetails.transport.create_address')}
              </S.SelectOption>
            )}
          </S.AddressesSelect>
        );
      };

      const renderDatePicker = (): JSX.Element | null => {
        if (!dateIssue) {
          return estimatedDate && <S.TextBlack>{estimatedDate + estimatedScheduleDisplay}</S.TextBlack>;
        }

        return (
          <DatePicker
            id={`${addressType}-estimated-at`}
            initDate={new Date(order?.deliveryEstimatedAt || Date.now())}
            onDateChange={handleDateChange}
            customInput={
              <Common.IssueText>
                {addressType === 'shipping'
                  ? __('Components.Cart.delivery_date_issue')
                  : __('Components.Cart.pickup_date_issue')}{' '}
                <Common.IssueIcon name="Down" />
              </Common.IssueText>
            }
          />
        );
      };

      return (
        <S.AddressIssueRow>
          <S.AddressIssueCard>
            <S.Text>
              {addressType === 'shipping'
                ? __('Components.OrderDetails.transport.shipping')
                : __('Components.OrderDetails.transport.pickup')}
              :{renderAddressSelector()}
            </S.Text>
            <S.Text>
              {__('Components.OrderDetails.transport.estimated_date')}
              {renderDatePicker()}
            </S.Text>
          </S.AddressIssueCard>
        </S.AddressIssueRow>
      );
    },
    [
      logisticAddresses,
      pickupAddresses,
      countries,
      order,
      me.id,
      amEditor,
      amViewer,
      orderDateFixIssue,
      setDeliveryAddress,
      setPickupAddress,
      setAddressSelected,
      setPickupSelected,
      setShowUpdateConfirmation,
      setShowSearchAddress,
    ],
  );

  const renderDeliveryMethod = React.useCallback(
    (
      deliveryMethod: IDeliveryMethodType,
      address: IAddress,
      estimatedAt: string,
      estimatedSchedule: Array<string>,
      transportDetails: string,
    ): JSX.Element | null => {
      // Early return if no data
      if (!address && !estimatedAt) {
        return null;
      }

      // Memoize formatted dates

      const estimatedDate = estimatedAt ? utils.capitalizeWords(date.formatLongDate(estimatedAt)) : '';

      const loadDate = order?.loadEstimatedAt ? utils.capitalizeWords(date.formatLongDate(order?.loadEstimatedAt)) : '';

      const scheduleDisplay =
        estimatedAt && estimatedSchedule?.length ? ` ${estimatedSchedule[0]} - ${estimatedSchedule[1]}` : '';

      const formattedDates = {
        estimatedDate,
        loadDate,
        scheduleDisplay,
      };

      // Find date issue
      const dateIssue = order?.issues.find(
        issue =>
          issue.type === 'date-not-valid' &&
          issue.code === (deliveryMethod === 'shipping' ? 'delivery_date' : 'pickup_date'),
      );

      // Event handlers
      const handleDateChange = (newDate: string): void => {
        if (dateIssue) {
          orderDateFixIssue(
            me.id,
            order?.id,
            newDate,
            dateIssue.id,
            false,
            deliveryMethod === 'shipping' ? DATE_TYPE.DELIVERY : DATE_TYPE.PICKUP,
          );
        }
      };

      // Render helper functions
      const renderAddress = (): JSX.Element | null => {
        if (!address) return null;

        const displayNameOrRest = address.displayName || address.rest;
        const prefix = displayNameOrRest ? `${displayNameOrRest} · ` : '';
        const formattedAddress = addresses.getAddressName(address, countries, false);

        return (
          <S.Text>
            {deliveryMethod === 'pickup'
              ? __('Components.OrderDetails.transport.Pickup.address')
              : __('Components.OrderDetails.transport.Delivery.address')}
            :
            <S.TextBlack>
              {prefix}
              {formattedAddress}
            </S.TextBlack>
          </S.Text>
        );
      };

      const renderLoadDate = (): JSX.Element | null => {
        if (!config.TOGGLE_LOAD_DATE?.enabled || !formattedDates.loadDate) {
          return null;
        }

        return (
          <S.Text>
            {__('Components.OrderDetails.transport.Load.date')}:<S.TextBlack>{formattedDates.loadDate}</S.TextBlack>
          </S.Text>
        );
      };

      const renderEstimatedDate = (): JSX.Element | null => (
        <S.Text>
          {deliveryMethod === 'pickup'
            ? __('Components.OrderDetails.transport.Pickup.date')
            : __('Components.OrderDetails.transport.Delivery.date')}
          :
          {dateIssue ? (
            <DatePicker
              id={`${deliveryMethod}-estimated-at`}
              initDate={new Date(order?.deliveryEstimatedAt)}
              onDateChange={handleDateChange}
              customInput={
                <Common.IssueText>
                  {deliveryMethod === 'shipping'
                    ? __('Components.Cart.delivery_date_issue')
                    : __('Components.Cart.pickup_date_issue')}{' '}
                  <Common.IssueIcon name="Down" />
                </Common.IssueText>
              }
            />
          ) : (
            formattedDates.estimatedDate && <S.TextBlack>{formattedDates.estimatedDate}</S.TextBlack>
          )}
        </S.Text>
      );

      const renderSchedule = (): JSX.Element | null => {
        if (!formattedDates.scheduleDisplay) return null;

        return (
          <S.Text>
            {deliveryMethod === 'pickup'
              ? __('Components.OrderDetails.transport.Pickup.time')
              : __('Components.OrderDetails.transport.Delivery.time')}
            :<S.TextBlack>{formattedDates.scheduleDisplay}</S.TextBlack>
          </S.Text>
        );
      };

      const renderTransportDetails = (): JSX.Element | null => {
        if (!transportDetails) return null;

        return (
          <S.Text>
            {__('Components.OrderDetails.transport.transport')}:<S.TextBlack>{transportDetails}</S.TextBlack>
          </S.Text>
        );
      };

      return (
        <S.Card>
          {deliveryMethod === 'pickup' ? (
            // Pickup delivery method
            <>
              {renderAddress()}
              {formattedDates.estimatedDate && renderEstimatedDate()}
              {renderSchedule()}
            </>
          ) : (
            // Shipping delivery method
            <>
              {renderAddress()}
              {renderLoadDate()}
              {renderEstimatedDate()}
              {renderSchedule()}
            </>
          )}
          {renderTransportDetails()}
        </S.Card>
      );
    },
    [countries, me.id, order?.deliveryEstimatedAt, order?.issues, orderDateFixIssue, order?.id, order?.loadEstimatedAt],
  );

  // Render sub-components
  const renderAddDeliveryAddress = (): JSX.Element | null => {
    if (!deliveryAddress.section) return null;
    return (
      <ActionsModal
        onClose={() => setDeliveryAddress({})}
        title={deliveryAddress.id ? __('Components.AddNewAddress.title_edit') : __('Components.AddNewAddress.title')}
      >
        <AddressAutoComplete
          countries={countries}
          me={me}
          onCancel={() => setDeliveryAddress({})}
          onSubmit={(adr: IAddress) => {
            addDeliveryAddress(adr, address => {
              setAddressSelected(addresses.getAddressKey(address));
              setShowUpdateConfirmation(true);
              setShowSearchAddress(false);
              setDeliveryAddress({});
            });
          }}
          tradeCurrency={currencyCode}
          weightUnit={weightUnit}
        />
      </ActionsModal>
    );
  };

  const renderAddPickupAddress = React.useCallback(() => {
    if (!pickupAddress.section) return null;

    return (
      <ActionsModal
        onClose={() => setPickupAddress({})}
        title={pickupAddress.id ? __('Components.AddNewAddress.title_edit') : __('Components.AddNewAddress.title')}
      >
        <AddressAutoComplete
          countries={countries}
          me={me}
          onCancel={() => setPickupAddress({})}
          onSubmit={(address: IAddress) => {
            addPickupAddress(address, newAddress => {
              setPickupSelected(addresses.getAddressKey(newAddress));
              setShowUpdateConfirmation(true);
              setShowSearchAddress(false);
              setPickupAddress({});
            });
          }}
          tradeCurrency={currencyCode}
          weightUnit={weightUnit}
        />
      </ActionsModal>
    );
  }, [
    pickupAddress.section,
    pickupAddress.id,
    countries,
    me,
    addPickupAddress,
    currencyCode,
    weightUnit,
    setPickupSelected,
    setShowUpdateConfirmation,
    setShowSearchAddress,
    setPickupAddress,
  ]);

  // Add this before the return statement
  const renderTransport = React.useCallback((): JSX.Element | null => {
    const {
      deliveryMethod,
      pickupFrom,
      deliverTo,
      pickupEstimatedAt,
      pickupEstimatedSchedule,
      pickupEstimatedTimeRange,
      deliveryEstimatedAt,
      deliveryEstimatedSchedule,
      deliveryEstimatedTimeRange,
      transportDetails,
    } = order;

    if (deliveryMethod === 'pickup') {
      return pickupAddressIssue
        ? renderAddressIssue('pickup', pickupAddressIssue, pickupEstimatedAt, pickupEstimatedSchedule, pickupSelected)
        : renderDeliveryMethod('pickup', pickupFrom, pickupEstimatedAt, pickupEstimatedTimeRange, transportDetails);
    }

    return deliveryAddressIssue
      ? renderAddressIssue(
          'shipping',
          deliveryAddressIssue,
          deliveryEstimatedAt,
          deliveryEstimatedSchedule,
          addressSelected,
        )
      : renderDeliveryMethod('shipping', deliverTo, deliveryEstimatedAt, deliveryEstimatedTimeRange, transportDetails);
  }, [
    order,
    pickupAddressIssue,
    deliveryAddressIssue,
    pickupSelected,
    addressSelected,
    renderAddressIssue,
    renderDeliveryMethod,
  ]);

  // Let's also add some utility functions to clean up the render
  const renderLoadInformation = (): JSX.Element | null => {
    if (!hasItems) return null;

    return (
      <S.Text>
        {__('Components.Cart.load')}
        <S.TextBlack>
          {config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId)
            ? orderService.getCartLoadSummaryAltUnits(order?.items)
            : orderService.getCartLoadSummaryText(order?.items)}
        </S.TextBlack>
      </S.Text>
    );
  };

  const renderShippingCost = (): JSX.Element | null => {
    const shippingItem = customItems?.find(item => item.type === 'shipping' && item.price > 0);
    if (!shippingItem) return null;

    return (
      <S.Text>
        {__('Components.OrderDetails.transport.transport_cost')}
        <S.TextBlack>{currency.getPrice(currencyCode, shippingItem.price)}</S.TextBlack>
      </S.Text>
    );
  };

  const renderIncoterms = (): JSX.Element | null => {
    if (!order?.incoterms?.length || !order?.incoterms[0]) return null;

    return (
      <S.Text>
        {__('Components.Cart.incoterms')}
        <S.TextBlack>{order?.incoterms.join(', ')}</S.TextBlack>
      </S.Text>
    );
  };

  const renderEditButton = (): JSX.Element | null => {
    if (!canEdit || !amEditor) return null;

    return (
      <S.AddAddressLink
        id="edit_transport_button"
        onClick={showLogisticCard}
        type="link"
        iconName="Add-more"
        iconSize="18px"
        withoutPadding={true}
      >
        {__('Components.OrderDetails.transport.add_edit_information')}
      </S.AddAddressLink>
    );
  };

  const selectedAddress = React.useMemo(() => {
    let result;
    if (addressSelected) {
      result = logisticAddresses.find(addr => addresses.getAddressKey(addr) === addressSelected);
    }
    if (pickupSelected) {
      result = pickupAddresses.find(addr => addresses.getAddressKey(addr) === pickupSelected);
    }
    return result;
  }, [addressSelected, pickupSelected, logisticAddresses, pickupAddresses]);

  const currentAddressType = React.useMemo((): 'shipping' | 'pickup' | undefined => {
    if (addressSelected) return 'shipping';
    if (pickupSelected) return 'pickup';
    return undefined;
  }, [addressSelected, pickupSelected]);

  return (
    <>
      <S.Row>
        <S.SectionTitle>{__('Components.OrderDetails.transport.title')}</S.SectionTitle>
        {(deliveryAddressIssue || pickupAddressIssue) && <S.InfoIcon name="Warning" disableHover={true} />}
      </S.Row>

      <S.CardItem>
        {transportAdded ? (
          renderTransport()
        ) : !deliveryAddressIssue && !pickupAddressIssue ? (
          <S.TextBlack>{__('Components.OrderDetails.transport.not_included')}</S.TextBlack>
        ) : (
          <>
            {pickupAddressIssue &&
              renderAddressIssue(
                'pickup',
                pickupAddressIssue,
                order?.pickupEstimatedAt,
                order?.pickupEstimatedSchedule,
                pickupSelected,
              )}
            {deliveryAddressIssue &&
              renderAddressIssue(
                'shipping',
                deliveryAddressIssue,
                order?.deliveryEstimatedAt,
                order?.deliveryEstimatedSchedule,
                addressSelected,
              )}
          </>
        )}

        {renderLoadInformation()}
        {renderShippingCost()}
        {renderIncoterms()}
      </S.CardItem>

      {renderEditButton()}

      {/* Modals */}
      {showUpdateConfirmation && selectedAddress && currentAddressType && (
        <UpdateConfirmationModal
          checkLabel={__('Components.OrderDetails.transport.update_confirmation.check')}
          onClose={() => {
            setShowUpdateConfirmation(false);
            setAddressSelected(undefined);
            setPickupSelected(undefined);
          }}
          ctaChecked={__('Components.OrderDetails.transport.update_confirmation.cta2')}
          ctaNotChecked={__('Components.OrderDetails.transport.update_confirmation.cta')}
          defaultCheck={!selectedAddress.externalId || amViewer}
          disableCheck={!selectedAddress.externalId || amViewer}
          onConfirm={isChecked => handleAddressChange(!isChecked, selectedAddress, currentAddressType)}
          title={__('Components.OrderDetails.transport.update_confirmation.title', {
            name: addresses.getAddressName(selectedAddress, countries),
          })}
        />
      )}

      {deliveryAddress.section && renderAddDeliveryAddress()}
      {pickupAddress.section && renderAddPickupAddress()}

      {showSearchAddress && (
        <AddressModal
          addresses={order?.deliveryMethod === 'shipping' ? logisticAddresses : pickupAddresses}
          catalogId={order?.catalogId}
          createAddress={() => {
            setShowSearchAddress(false);
            setDeliveryAddress(order?.deliveryMethod === 'shipping' ? { section: CHANNEL_SECTIONS.ADDRESS_EDIT } : {});
            setPickupAddress(order?.deliveryMethod === 'pickup' ? { section: CHANNEL_SECTIONS.ADDRESS_EDIT } : {});
          }}
          onClose={() => setShowSearchAddress(false)}
          title={__('Components.OrderDetails.transport.address_title')}
          subtitle={__('Components.OrderDetails.transport.address_subtitle', {
            code: deliveryAddressIssue?.code || pickupAddressIssue?.code || '',
          })}
          onSelectAddress={(address: IAddress) => {
            const isShipping = order?.deliveryMethod === 'shipping';
            if (isShipping) {
              setAddressSelected(addresses.getAddressKey(address));
            } else {
              setPickupSelected(addresses.getAddressKey(address));
            }
            setShowUpdateConfirmation(true);
            setShowSearchAddress(false);
          }}
        />
      )}
    </>
  );
};

export default React.memo(TransportCard);

import { __ } from 'common-services';
import * as React from 'react';

import * as S from './UpdatesRibbon.styled';

interface UpdatesRibbonProps {
  count: number;
  message?: string;
  onNavigateToComments: () => void;
}

const UpdatesRibbon: React.FC<UpdatesRibbonProps> = React.memo(({ count, message, onNavigateToComments }) => {
  if (!message) return null;

  return (
    <S.Container>
      <S.IconWrapper>
        <S.UpdateIcon name="Recent" disableHover />
      </S.IconWrapper>

      <S.MessageText>{message}</S.MessageText>

      <S.NavigationLink
        onClick={onNavigateToComments}
        role="button"
        aria-label={__('Components.Cart.see_updates_aria_label')}
      >
        {__('Components.Cart.see_updates', { count })}
      </S.NavigationLink>
    </S.Container>
  );
});

// For debugging and dev tools
UpdatesRibbon.displayName = 'UpdatesRibbon';

export default UpdatesRibbon;

import { __, ORDER_STATUS } from 'common-services';
import * as React from 'react';

import { useOrderContext } from '../../../context';

import * as S from './GeneralInfo.styled';
import { OrderInfo } from './OrderInfo';
import { TransportCard } from './TransportCard';

export const GeneralInfo: React.FC = React.memo(() => {
  const { order, weAreSeller, setShowClientViewModal, isBuyerOfferingView } = useOrderContext();

  // TODO:2 convert to onOpenClientsModal
  const onShowClientView = (): void => {
    setShowClientViewModal(true);
  };

  return (
    <S.Container>
      <S.InfoRow>
        {/* Order Info Card */}
        <S.Column>
          <S.CardItem>
            <OrderInfo />
          </S.CardItem>
        </S.Column>

        {/* Transport Info Card */}
        <S.Column>
          <TransportCard />
        </S.Column>

        {/* Preview Button */}
        {weAreSeller && order?.status !== ORDER_STATUS.INCOMPLETE && !isBuyerOfferingView ? (
          <S.PreviewButton
            id="preview_button"
            type="link"
            iconType="background"
            iconName="Info"
            withoutPadding={true}
            onClick={onShowClientView}
          >
            {order?.id && order?.status !== 'draft'
              ? __('Components.Cart.preview')
              : __('Components.Cart.preview_unsend')}
          </S.PreviewButton>
        ) : null}
      </S.InfoRow>
    </S.Container>
  );
});

GeneralInfo.displayName = 'GeneralInfo';

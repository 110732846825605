import { __ } from 'common-services';
import * as React from 'react';

import * as S from './Unread.styled';

export const UnreadMessage: React.FC<{
  unreadCommentsCount: number;
  unreadUpdatesCount: number;
}> = React.memo(({ unreadCommentsCount, unreadUpdatesCount }): JSX.Element | null => {
  let text = '';
  if (unreadCommentsCount) {
    text = unreadUpdatesCount
      ? __('Components.Chat.unread_comments_updates', {
          count_comments: unreadCommentsCount,
          count_updates: unreadUpdatesCount,
        })
      : __('Components.Chat.unread_comments', { count: unreadCommentsCount });
  } else if (unreadUpdatesCount) {
    text = __('Components.Chat.unread_updates', { count: unreadUpdatesCount });
  }
  return text ? (
    <S.UnreadMessage>
      <S.UpIcon name="Arrow" />
      {text.toUpperCase()}
      <S.UpIcon name="Arrow" />
    </S.UnreadMessage>
  ) : null;
});

UnreadMessage.displayName = 'UnreadMessage';

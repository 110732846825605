import styled from '../../../styled-components';
import { Button, ColumnContainer, RowContainer } from '../../atoms';

import type { ITheme } from '../../../styled-components';

interface IStyledProps {
  theme: ITheme;
  margin?: string;
}

export const Container = styled(ColumnContainer)`
  width: 100%;
  height: calc(${(p: IStyledProps) => p.theme.contentHeight} - ${(p: IStyledProps) => p.theme.headerHeight});
  position: relative;
`;

export const ScrollContainer = styled(ColumnContainer)`
  width: 100%;
  overflow: auto;
  flex-wrap: no-wrap;
  flex: 1;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
`;

export const MinContentContainer = styled(ColumnContainer)`
  width: 100%;
  min-height: min-content;
  flex-wrap: nowrap;
`;

export const BodyContainer = styled(ColumnContainer)`
  width: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const SectionContainer = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => `${p.theme.paddingSize()} ${p.theme.paddingSize(2)} ${p.theme.paddingSize(3)} 0`};
`;
export const LoadRow = styled(RowContainer)`
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const Row = styled(RowContainer)`
  justify-content: space-between;
`;
export const ScheduleButton = styled(Button)`
  padding: 0px;
`;

import { ORDER_ORIGIN, ORDER_STATUS, PRODUCT_UNIT, __, orderUtils, parsers } from 'common-services';
import * as React from 'react';

import config from '../../../../../../../../../bindings/config';
import { priceUnitTranslate } from '../../../../../../../../util/utils';
import { Select } from '../../../../../../../atoms';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'quantity',
  rank: 1001,
  isVisible: true,
  getTitle: () => __('Components.Cart.items.quantity'),
  minWidth: '220px',
  width: '12%',
  condition: ({ showServedColumn }) => !showServedColumn,
  columnValue: ({ order, isPendingOrder, amEditor, editAfterAccept, canServeOrder, onUpdateItem }) => ({
    getElement: item => {
      const editable = amEditor && (isPendingOrder || (canServeOrder && editAfterAccept));
      const issuesRelated = order?.issues.filter(issue => issue.orderItemId === item.id);
      const invalidAmountIssue = issuesRelated?.find(i => i.type === 'amount-not-valid');

      const showEdit =
        (!orderUtils.isCanceled(order) &&
          ((editable && editAfterAccept) ||
            (!item.amount && (order?.origin === ORDER_ORIGIN.IMPORT_UI || order?.origin === ORDER_ORIGIN.EMAIL)) ||
            (order?.origin === ORDER_ORIGIN.OFFERS && order.status === ORDER_STATUS.PENDING))) ||
        invalidAmountIssue;

      const hasNotFoundIssue = !!issuesRelated?.find(i =>
        ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
      );
      const isDiscarded = item.servedQuantity === 0;

      return (
        <S.AmountRow onClick={e => e.stopPropagation()}>
          <S.QuantityRow>
            {invalidAmountIssue ? <S.WarningIcon name="Warning" disableHover={true} /> : null}
            {showEdit ? (
              <>
                <S.AmountInput
                  id={`amount-${item.id}`}
                  name="amount"
                  onBlur={(key: string, value: string) => {
                    const v = Number(value);
                    if (isNaN(v) || v === 0) return;
                    const i = { ...item, amount: v, servedQuantity: v };
                    onUpdateItem(i, invalidAmountIssue?.type === 'amount-not-valid' ? invalidAmountIssue : undefined);
                  }}
                  value={item.servedQuantity || 0}
                  minValue={0}
                  precision={item.saleUnit === PRODUCT_UNIT.KG ? 2 : 0}
                  width="70px"
                  type="number"
                  textAlign="right"
                  // autoFocus={false}
                  hasError={!item.servedQuantity}
                  containerMargin="0 6px 0 0"
                  isIssue={!!invalidAmountIssue}
                />
                <Select
                  containerMargin="0"
                  value={item.servedSaleUnit}
                  name={`served-sale-unit-${item.id}`}
                  options={item.saleUnits?.map(s => ({
                    value: s,
                    label: parsers.getUnitText(
                      config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId)
                        ? priceUnitTranslate(s)
                        : s,
                      item.weightUnit,
                      0,
                    ),
                  }))}
                  onChange={(n, v) => {
                    onUpdateItem({
                      ...item,
                      saleUnit: v as PRODUCT_UNIT,
                      servedSaleUnit: v as PRODUCT_UNIT,
                    });
                  }}
                  width="100px"
                />
              </>
            ) : (
              <>
                <S.Amount showGrey={hasNotFoundIssue || isDiscarded || orderUtils.isCanceled(order)}>
                  {item.amount}
                </S.Amount>
                <S.Amount showGrey={hasNotFoundIssue || isDiscarded || orderUtils.isCanceled(order)}>
                  {' '}
                  {parsers.getUnitText(
                    config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId)
                      ? priceUnitTranslate(item.saleUnit)
                      : item.saleUnit,
                    item.weightUnit,
                    item.amount,
                  )}
                </S.Amount>
              </>
            )}
          </S.QuantityRow>
        </S.AmountRow>
      );
    },
  }),
};

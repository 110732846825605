import * as React from 'react';

import { useOrderContext } from '../../context';

import { BoxesPerPalletModal } from './BoxesPerPalletModal';
import { ClientsModal } from './ClientsModal';
import { ClientViewModal } from './ClientViewModal';
import { IssuesModal } from './IssuesModal';
import { PriceModal } from './PriceModal';
import { ProductInfoModal } from './ProductInfoModal';
import { SearchProductsModal } from './SearchProductsModal';

const OrderModals: React.FC = React.memo(() => {
  const {
    productPageProps,
    itemWithIssue,
    showPriceModal,
    showClientsModal,
    showClientViewModal,
    showBoxesPerPalletModal,
    showIssuesModal,
    selectedItem,
  } = useOrderContext();

  return (
    <>
      {productPageProps && <ProductInfoModal />}
      {itemWithIssue && !showPriceModal && <SearchProductsModal />}
      {showPriceModal && <PriceModal />}
      {showClientsModal && <ClientsModal />}
      {showClientViewModal && <ClientViewModal />}
      {showBoxesPerPalletModal && <BoxesPerPalletModal />}
      {showIssuesModal && selectedItem && <IssuesModal />}
    </>
  );
});

OrderModals.displayName = 'OrderModals';

export default OrderModals;

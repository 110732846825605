import { __, utils, productService } from 'common-services';
import * as React from 'react';

import config from '../../../../../../../bindings/config';
import { Ribbon } from '../../../../../atoms';

import * as S from './IssuesRibbon.styled';

import type { IOrderItem, IOrderIssue, IProdType, IWorkspace } from 'common-services';

interface IssuesRibbonProps {
  issues: Array<IOrderIssue>;
  items: Array<IOrderItem>;
  prodTypes: Record<string, IProdType>;
  pricesEmpty: Array<IOrderItem>;
  catalog?: IWorkspace;
  weAreSeller: boolean;
}

export const IssuesRibbon: React.FC<IssuesRibbonProps> = ({
  issues,
  items,
  prodTypes,
  pricesEmpty,
  catalog,
  weAreSeller,
}) => {
  const itemsWithIssue = items.filter(item =>
    issues.some(
      err =>
        ['no-pieces-per-box', 'no-boxes-per-pallet', 'no-box-weight', 'multi-boxes-per-pallet-without-buyer'].includes(
          err.type,
        ) &&
        !issues.find(e => ['product-not-found', 'code-not-found'].includes(e.type) && item.id === e.orderItemId) &&
        item.id === err.orderItemId,
    ),
  );

  const productNames = itemsWithIssue.map(p => {
    return (
      `${productService.getProductTypeVarietyDisplay(
        p.type,
        prodTypes[p.type] ? prodTypes[p.type].name : '',
        p.title,
      )}` +
      ' ' +
      `${p.size}`
    );
  });

  // Count different types of issues
  const hasMissingPickupAddress = React.useMemo(
    () => issues.some(err => ['pickup-not-found', 'pickup-code-not-found'].includes(err.type)),
    [issues],
  );

  const hasMissingDeliveryAddress = React.useMemo(
    () => issues.some(err => ['address-not-found', 'address-code-not-found'].includes(err.type)),
    [issues],
  );

  const hasMissingDateCreate = React.useMemo(
    () => issues.some(err => err.type === 'date-not-valid' && err.code === 'order_date'),
    [issues],
  );

  const hasMissingDateDelivery = React.useMemo(
    () => issues.some(err => err.type === 'date-not-valid' && err.code === 'delivery_date'),
    [issues],
  );

  const hasMissingDatePickup = React.useMemo(
    () => issues.some(err => err.type === 'date-not-valid' && err.code === 'pickup_date'),
    [issues],
  );

  const manyInternalsCodesCount = React.useMemo(
    () => issues.filter(err => 'many-internal-codes-for-external' === err.type).length,
    [issues],
  );

  const missingProductsCount = React.useMemo(
    () => issues.filter(err => ['product-not-found', 'code-not-found'].includes(err.type)).length,
    [issues],
  );

  const noClientIssue = React.useMemo(() => issues.find(err => err.type === 'client-code-not-found'), [issues]);

  const unavailableProducts = React.useMemo(
    () => issues.filter(err => ['product-unavailable'].includes(err.type)),
    [issues],
  );

  const invalidAmountCount = React.useMemo(
    () => issues.filter(err => err.type === 'amount-not-valid').length,
    [issues],
  );

  const invalidPriceCount = React.useMemo(() => issues.filter(err => err.type === 'price-not-valid').length, [issues]);

  const unexpectedErrorsCount = React.useMemo(() => issues.filter(err => err.type === 'unexpected').length, [issues]);

  const unknownErrorsCount = React.useMemo(() => issues.filter(err => err.type === 'unknown').length, [issues]);

  const productIssues = React.useMemo(
    () =>
      issues.find(err =>
        ['no-pieces-per-box', 'no-boxes-per-pallet', 'no-box-weight', 'multi-boxes-per-pallet-without-buyer'].includes(
          err.type,
        ),
      ),
    [issues],
  );

  const mbppIssues = React.useMemo(
    () => issues.filter(err => err.type === 'multi-boxes-per-pallet-without-buyer'),
    [issues],
  );

  const hasPriceEmptyIssue = React.useMemo(
    () => pricesEmpty.some(p => !issues.find(i => p.id === i.orderItemId && i.type !== 'product-no-price')),
    [pricesEmpty, issues],
  );

  return (
    <Ribbon
      type="warning"
      text={
        <S.IssuesContainer>
          {hasPriceEmptyIssue && weAreSeller && <S.IssueText>{__('Components.OrderDetails.por_message')}</S.IssueText>}

          {hasMissingPickupAddress && (
            <S.IssueText>{__('Components.Cart.errors.pickup_address_not_found')}</S.IssueText>
          )}

          {hasMissingDeliveryAddress && (
            <S.IssueText>{__('Components.Cart.errors.delivery_address_not_found')}</S.IssueText>
          )}

          {hasMissingDateCreate && <S.IssueText>{__('Components.Cart.errors.date_create')}</S.IssueText>}

          {hasMissingDateDelivery && <S.IssueText>{__('Components.Cart.errors.date_delivery')}</S.IssueText>}

          {hasMissingDatePickup && <S.IssueText>{__('Components.Cart.errors.date_pickup')}</S.IssueText>}

          {manyInternalsCodesCount > 0 && (
            <S.IssueText>
              {__('Messages.ImportJobFile.errors.many_internal_codes_for_external', {
                errorCode: issues.find(i => i.type === 'many-internal-codes-for-external')?.code,
              })}
            </S.IssueText>
          )}

          {missingProductsCount > 0 && (
            <S.IssueText>
              {__('Components.Cart.errors.missing_products', {
                count: missingProductsCount,
              })}
            </S.IssueText>
          )}

          {noClientIssue && (
            <S.IssueText>
              {
                utils.formatText(__('Components.Cart.errors.no_client'), (text, i) => (
                  <S.TextBold key={text + i}>{text}</S.TextBold>
                )) as any // eslint-disable-line @typescript-eslint/no-explicit-any
              }
            </S.IssueText>
          )}

          {unavailableProducts.length > 0 && (
            <S.IssueText>
              {__('Components.Cart.errors.unavailable_products', {
                count: unavailableProducts.length,
                name: getUnavailableProductName(unavailableProducts, items, prodTypes),
              })}
            </S.IssueText>
          )}

          {invalidAmountCount > 0 && (
            <S.IssueText>
              {__('Components.Cart.errors.invalid_amount', {
                count: invalidAmountCount,
              })}
            </S.IssueText>
          )}

          {invalidPriceCount > 0 && (
            <S.IssueText>
              {__('Components.Cart.errors.invalid_price', {
                count: invalidPriceCount,
              })}
            </S.IssueText>
          )}

          {unexpectedErrorsCount > 0 && (
            <S.IssueText>
              {__('Components.Cart.errors.unexpected', {
                count: unexpectedErrorsCount,
              })}
            </S.IssueText>
          )}

          {unknownErrorsCount > 0 && (
            <S.IssueText>
              {__('Components.Cart.errors.unknown', {
                count: unknownErrorsCount,
              })}
            </S.IssueText>
          )}

          {productIssues && productNames.length > 0 && config.TOGGLE_ORDER_ITEM_ISSUE.enabled && (
            <S.IssueText>
              {__('Components.Cart.errors.product_issue', {
                name: productNames,
              })}
            </S.IssueText>
          )}

          {mbppIssues?.length > 0 &&
            productNames.length > 0 &&
            config.TOGGLE_BOXES_PER_PALLET.enabled &&
            !config.TOGGLE_ORDER_ITEM_ISSUE.enabled &&
            catalog?.mandatoryPalletizationByClient && (
              <S.IssueText>
                {__('Components.Cart.errors.product_issue', {
                  name: productNames,
                })}
              </S.IssueText>
            )}
        </S.IssuesContainer>
      }
    />
  );
};

// Helper function to get unavailable product name
const getUnavailableProductName = (
  unavailableProducts: Array<IOrderIssue>,
  items: Array<IOrderItem>,
  prodTypes: Record<string, IProdType>,
): string => {
  if (unavailableProducts.length > 1) return '';

  const item = items.find(i => i.id === unavailableProducts[0].orderItemId);
  if (!item) return '';

  const typeVariety = productService.getProductTypeVarietyDisplay(
    item.type,
    prodTypes[item.type]?.name || '',
    item.title,
  );

  return `${item.productTitle || typeVariety + ' ' + item.size}`;
};

export default React.memo(IssuesRibbon);

import { __ } from 'common-services';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'updated',
  isVisible: true,
  getTitle: () => __('Components.ProductsList.Table.UpdatedAt'),
  minWidth: '60px',
  width: '8%',
};

import { PRODUCT_UNIT, __, orderUtils } from 'common-services';
import * as React from 'react';

import { Input } from '../../../../../../../atoms';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'weight',
  isVisible: true,
  getTitle: () => __('Components.Cart.items.weight'),
  minWidth: '70px',
  width: '9%',
  condition: ({ isPrepared }) => isPrepared,
  columnValue: ({ me, order, onUpdateItem, orderUpdatePrepared }) => ({
    getElement: item => {
      const issuesRelated = order?.issues.filter(issue => issue.orderItemId === item.id);
      const hasNotFoundIssue = !!issuesRelated?.find(i =>
        ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
      );
      const isDiscarded = item.servedQuantity === 0;
      const isPrepared = !!order?.items.find(i => i.isPrepared);
      return (
        <S.CellContainer showGrey={isDiscarded || hasNotFoundIssue || orderUtils.isCanceled(order)}>
          <S.AmountRow onClick={e => e.stopPropagation()}>
            {isPrepared ? (
              item.isPrepared ? (
                <S.Row>
                  <S.Amount>{item.servedWeight || 0}</S.Amount>
                  <S.Amount> {item.weightUnit}</S.Amount>
                </S.Row>
              ) : (
                __('Components.Cart.items.prepare')
              )
            ) : (
              <Input
                id={`served-weight-${item.id}`}
                name="servedWeight"
                onBlur={(key: string, value: string, error: string) => {
                  const v = Number(value);
                  if (!error && !isNaN(v) && v !== item.servedWeight) {
                    orderUpdatePrepared(me.id, item.orderId, item.id, item.catalogId, v, item.isPrepared, () => {
                      onUpdateItem({ ...item, servedWeight: v });
                    });
                  }
                }}
                value={item.servedWeight || 0}
                minValue={0}
                precision={item.saleUnit === PRODUCT_UNIT.KG ? 2 : 0}
                width="80px"
                type="number"
                textAlign="right"
                // autoFocus={false}
                containerMargin="0 6px 0 0"
                variableTextSingular={item.weightUnit}
                variableTextPlural={item.weightUnit}
                disabled={isPrepared}
              />
            )}
          </S.AmountRow>
        </S.CellContainer>
      );
    },
  }),
};

import { ORDER_STATUS } from 'common-services';
import * as React from 'react';

import config from '../../../../../../../../../bindings/config';

import type { productActions, IProduct, IOrderItem, IOrderIssue, IOrder, IWorkspace } from 'common-services';

interface UseProductListProps {
  items: Array<IOrderItem>;
  catalog?: IWorkspace;
  order: IOrder;
  productGetByHashPublic: typeof productActions.productGetByHashPublic;
  onUpdateItem: (item: IOrderItem, issue?: IOrderIssue) => void;
}

export function useProductList({
  items,
  catalog,
  order,
  productGetByHashPublic,
  onUpdateItem,
}: UseProductListProps): Array<IProduct> {
  const [productList, setProductList] = React.useState<Array<IProduct>>([]);

  const updateBoxesPerPallet = React.useCallback(
    (
      product: IProduct,
      buyerId: number,
      items: Array<IOrderItem>,
      onUpdateItem: (item: IOrderItem, issue?: IOrderIssue) => void,
    ) => {
      const boxesPerPalletValues = product.boxesPerPalletValues;
      const defaultValue = boxesPerPalletValues?.find(b => b.isDefault)?.value;
      const clientValue = (boxesPerPalletValues?.find(item => item.clientId === buyerId) || {}).value;
      const item = items.find(item => item.productHashId === product.hashId);
      if (clientValue || defaultValue || boxesPerPalletValues?.length > 0) {
        onUpdateItem({
          ...item,
          boxesPerPallet: clientValue || defaultValue || boxesPerPalletValues[0]?.value,
        });
      }
    },
    [],
  );

  React.useEffect(
    () => {
      const productHashIds = items.map(item => item.productHashId);
      const newProductList: Array<IProduct> = [];

      if (
        config.TOGGLE_BOXES_PER_PALLET.enabled &&
        catalog?.mandatoryPalletizationByClient &&
        order?.status === ORDER_STATUS.PENDING &&
        order?.id === 0
      ) {
        productHashIds.forEach((hashId: string) => {
          productGetByHashPublic(
            hashId,
            (product: IProduct) => {
              newProductList.push(product);
              updateBoxesPerPallet(product, order?.buyerId, items, onUpdateItem);
            },
            false,
          );
        });
      }
      setProductList(newProductList);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [order?.id],
  );

  return productList;
}

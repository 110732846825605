import * as React from 'react';

import type { OrderContextValue } from '../../../../../context/OrderContext.types';
import type { ColumnOptionsProps, ColumnTitleProps, ColumnConditionProps } from '../columns';
import type { ICustomItem } from 'common-services';

export interface CustomItemsContext extends OrderContextValue {
  customItemDelete: (item: ICustomItem, index: number) => void;
  setSelectedCustomItem: (item: ICustomItem) => void;
}

interface UseTablePropsReturn {
  columnProps: ColumnOptionsProps;
  titleProps: ColumnTitleProps;
  conditionProps: ColumnConditionProps;
}

export const useTableProps = (useContext: () => CustomItemsContext): UseTablePropsReturn => {
  const { order, priceMode, canEdit, setSelectedCustomItem, customItemDelete } = useContext();

  const columnProps = React.useMemo(
    () => ({
      order,
      canEdit,
      priceMode,
      setSelectedCustomItem,
      customItemDelete,
    }),
    [order, canEdit, priceMode, setSelectedCustomItem, customItemDelete],
  );

  const titleProps = {};

  const conditionProps = {
    canEdit,
  };

  return {
    columnProps,
    titleProps,
    conditionProps,
  };
};

import { __, type IOrderItem } from 'common-services';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import SelectWorkspaceModal from '../../../../../../../molecules/SelectWorkspaceModal';
import * as S from '../OrderItems.styled';

import type { IContact, IWorkspace, IUser } from 'common-services';

interface UseRendersProps {
  me: IUser;
  contacts: { [key: number]: IContact };
  catalogs: { [key: string]: IWorkspace };
  newOrExistingLot: string;
  setShowSelectWorkspaceModal: (show: boolean) => void;
  openSearchProductsModal: (item: IOrderItem, originLot?: number, mappingCode?: string) => void;
}

interface UseRendersReturn {
  renderArrowPercentage: (priceDiff: number, from: 'input' | 'price', item: IOrderItem) => JSX.Element | null;
  renderSelectWorkspaceModal: (item: IOrderItem) => JSX.Element;
  renderTooltip: () => JSX.Element;
}

export const useRenders = ({
  me,
  catalogs,
  contacts,
  newOrExistingLot,
  setShowSelectWorkspaceModal,
  openSearchProductsModal,
}: UseRendersProps): UseRendersReturn => {
  const history = useHistory();

  /**
   * Render arrow percentage for price mismatch
   */
  const renderArrowPercentage = React.useCallback(
    (priceDiff: number, from: 'input' | 'price', item: IOrderItem): JSX.Element | null => {
      if (!priceDiff) return null;
      return (
        <S.ArrowPercentage
          currency={item.currency}
          isDiscount={priceDiff < 0}
          mode="currency"
          rightPosition={`-${from === 'input' ? 30 : 15}%`}
          value={priceDiff}
        />
      );
    },
    [],
  );

  /**
   * Render assign lot modal
   */
  const renderSelectWorkspaceModal = React.useCallback(
    (item: IOrderItem): JSX.Element => {
      return (
        <SelectWorkspaceModal
          catalogs={Object.values(catalogs).filter(c => c.members.find(m => m.userId === me.id && m.role !== 'viewer'))}
          contacts={contacts}
          history={history}
          item={item}
          location={history.location}
          me={me}
          newOrExistingLot={newOrExistingLot}
          close={() => setShowSelectWorkspaceModal(false)}
          openProductSelectionModal={() => openSearchProductsModal(item)}
        />
      );
    },
    [catalogs, contacts, history, me, newOrExistingLot, openSearchProductsModal, setShowSelectWorkspaceModal],
  );
  /**
   * Render tooltip for mismatch price
   */
  const renderTooltip = React.useCallback((): JSX.Element => {
    return (
      <S.TooltipContainer>
        <S.TooltipPrice
          position="bottom"
          text={__('Components.ProductDetails.mismatch_price.tooltip')}
          width="200px"
          themeMode="dark"
        >
          <S.InfoIconBlue disableHover={true} name="Info" />
        </S.TooltipPrice>
      </S.TooltipContainer>
    );
  }, []);

  return {
    renderArrowPercentage,
    renderSelectWorkspaceModal,
    renderTooltip,
  };
};

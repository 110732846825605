import {
  __,
  colors,
  constants,
  currency,
  debounce,
  EventTrack,
  parsers,
  PRICE_ADJUSTMENT_TYPE,
  PRODUCT_UNIT,
  productService,
  RenderTrack,
  sellerWorkspaceService,
  utils,
} from 'common-services';
import * as React from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import config from '../../../../bindings/config';
import * as webConstants from '../../../constants';
import { AllProductUnitsArray } from '../../../domain/product';
import { convertToIFile } from '../../../services/file';
import { resizeImage } from '../../../services/image';
import { logError } from '../../../services/log';
import getPath from '../../../util/routes';
import { priceUnitTranslate } from '../../../util/utils';
import {
  Button,
  ColumnContainer,
  DatePicker,
  FontIcon,
  Input,
  Radio,
  Ribbon,
  Select,
  SelectWorkingStatus,
  TextArea,
  Tooltip,
} from '../../atoms';
import { FormSection, InputWithLabel, PriceItemTable } from '../../molecules';

import AdditionalFiles from './AdditionalFiles.component';
import { AddToPriceGroup } from './AddToPriceGroup.component';
import { NumberInput } from './NumberInput';
import PlasticModal from './PlasticModal.component';
import {
  getCategories,
  getCountries,
  getOrganicTypes,
  getProductTypes,
  MAX_TAG_LENGTH,
  MAX_UPLOAD_PICTURES,
} from './ProductEdit.constants';
import * as S from './ProductEdit.styled';
import { validateProductForm } from './ProductEdit.utils';
import Sustainability from './Sustainability';

import type * as userAppActions from '../../../actions/user';
import type { Sections } from '../../molecules/FormMenu/FormMenu.component';
import type {
  buyerWorkspaceActions,
  IBoxesPerPalletValue,
  imageActions,
  modalActions,
  notificationsActions,
  sellerWorkspaceActions,
  tagActions,
} from 'common-services';
import type { RouteComponentProps } from 'react-router-dom';

const Traceability = React.lazy(() => import('../Traceability'));

interface IProductEditActions {
  mediaUpload: typeof imageActions.mediaUploadWithProgress;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  notificationShow: typeof notificationsActions.notificationShow;
  priceGroupAddPrices: typeof sellerWorkspaceActions.priceGroupAddPrices;
  priceGroupDeletePrice: typeof sellerWorkspaceActions.priceGroupDeletePrice;
  pricesAddProduct: typeof sellerWorkspaceActions.pricesAddProduct;
  pricesGetFromProduct: typeof sellerWorkspaceActions.pricesGetFromProduct;
  supportAction: typeof userAppActions.supportAction;
  tagsGet: typeof tagActions.tagsGet;
  touchImage: typeof modalActions.touchImage;
  workspaceAddressesGet: typeof buyerWorkspaceActions.workspaceAddressesGet;
  requestProInfo: (userId: number, catalogId: number, reason: string) => void;
  productCreate: (workspaceId: number, product: IProduct, cb?: (p?: IProduct) => void) => void;
  productRemove: (workspaceId: number, productId: number, cb?: () => void) => void;
  productUpdate: (workspaceId: number, product: IProduct, cb?: () => void) => void;
  setShowPriceGroups: (show: boolean) => void;
  productClone: () => void;
}

interface IProductEditData {
  addresses: Array<IAddress>;
  boxTypes: Array<ICustomBoxType>;
  catalog: IWorkspace;
  catalogId: number;
  contacts: Record<number, IContact>;
  countries: { [k: string]: ICountry };
  hasChanges?: boolean;
  me: IUser;
  palletTypes: Array<ICustomPalletType>;
  priceGroups: { [priceGroupId: string]: IPriceGroup };
  prices: Record<number, IPrice>;
  prodTypes: { [key: string]: IProdType };
  product: IProduct;
  showPriceGroups: boolean;
  tags: Array<string>;
}

export type IProductEditProps = IProductEditActions & IProductEditData & RouteComponentProps<{ productId: string }>;

const ProductEdit1: React.FC<IProductEditProps> = ({
  mediaUpload,
  modalClose,
  modalOpen,
  notificationShow,
  priceGroupAddPrices,
  priceGroupDeletePrice,
  pricesAddProduct,
  pricesGetFromProduct,
  supportAction,
  tagsGet,
  workspaceAddressesGet,
  requestProInfo,
  productCreate,
  productRemove,
  productUpdate,
  setShowPriceGroups,
  addresses,
  boxTypes,
  catalog,
  catalogId,
  contacts,
  countries,
  hasChanges,
  me,
  palletTypes,
  priceGroups,
  prices,
  prodTypes,
  product,
  showPriceGroups,
  tags,
  productClone,
}) => {
  // const [countriesList, setCountriesList] = React.useState<Array<{ label: string; value: string }>>(() =>
  //   getCountries(countries),
  // );
  // const [files, setFiles] = React.useState<Array<File>>([]);
  // const [prodTypesList, setProdTypesList] = React.useState<Array<{ label: string; value: string }>>(() =>
  //   getProductTypes(prodTypes),
  // );

  const [editMode, setEditMode] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<Map<string, string>>(new Map());
  const [kebabBoxVisibility, setKebabBoxVisibility] = React.useState<{ [key: string]: boolean }>({});
  const [hasChangesState, setHasChangesState] = React.useState<boolean>(hasChanges || false);
  const [isCloning, setIsCloning] = React.useState<boolean | undefined>(undefined);
  const [priceGroupItems, setPriceGroupItems] = React.useState<Array<IPriceGroupItem>>([]);
  const [productState, setProductState] = React.useState<IProduct>(product);
  const [searchText, setSearchText] = React.useState<string>('');
  const [showAddToPriceGroup, setShowAddToPriceGroup] = React.useState<boolean | undefined>(undefined);
  const [showPlasticModal, setShowPlasticModal] = React.useState<boolean | undefined>(undefined);
  const [showTraceability, setShowTraceability] = React.useState<boolean | undefined>(undefined);
  const [viewing, setViewing] = React.useState<string | undefined>(undefined);
  const history = useHistory();
  const sectionsContainerRef = React.useRef<HTMLDivElement>(null);
  const imagesRef = React.useRef<HTMLDivElement>(null);
  const characteristicsRef = React.useRef<HTMLDivElement>(null);
  const distributionRef = React.useRef<HTMLDivElement>(null);
  const sustainabilityRef = React.useRef<HTMLDivElement>(null);
  const additionalFilesRef = React.useRef<HTMLDivElement>(null);
  const tagsRef = React.useRef<HTMLDivElement>(null);
  const priceRef = React.useRef<HTMLDivElement>(null);
  const priceGroupsRef = React.useRef<HTMLDivElement>(null);
  const deleteRef = React.useRef<HTMLDivElement>(null);
  const visibilityRef = React.useRef<HTMLDivElement>(null);
  const t = Date.now();

  const initProductState = (product: IProduct) => {
    const errors = new Map();
    const countriesList = getCountries(countries);
    if (!product.id) {
      product.type.origin = countriesList[0].value;
      product.type.type = getProductTypes(prodTypes)[0].value;
    }
    return {
      countries: countriesList,
      editMode: true,
      errors,
      hasChanges,
      prodTypes: getProductTypes(prodTypes),
      product,
      kebabBoxVisibility: {},
      priceGroupItems: [],
      searchText: '',
      files: [],
    };
  };

  const [state, setState] = React.useState(() => initProductState(product));

  React.useEffect(() => {
    if (productState.id) {
      const initState = initProductState(productState);
      setState(prevState => ({
        ...prevState,
        ...initState,
      }));
    }

    if (product.id && priceGroups !== undefined) {
      setState(prevState => ({
        ...prevState,
        priceGroupItems: prevState.priceGroupItems.map(pgi =>
          priceGroups[pgi.priceGroupId]?.prices?.find(p => p.productId === product.id),
        ),
        showAddToPriceGroup: !Object.keys(priceGroups).length || !product.priceGroups?.length,
      }));
    }
  }, [productState, priceGroups]);

  React.useEffect(() => {
    tagsGet(me.id, catalogId);
    setViewing('characteristics');
  }, [me.id, catalogId, tagsGet]);

  React.useEffect(() => {
    setViewing('characteristics');
    RenderTrack.track('ProductEdit', {
      renderTime: t,
      productId: productState.id ? product.id.toString() : '',
      type: productState.type,
    });
    const offset = 100;
    const handleScroll = () => {
      if (productState.id && sectionsContainerRef.current.scrollTop + offset >= deleteRef.current?.offsetTop) {
        setViewing('delete');
      } else if (
        config.TOGGLE_SUSTAINABILITY.enabled &&
        !catalog.promotedBy &&
        sectionsContainerRef.current.scrollTop + offset >= sustainabilityRef.current?.offsetTop
      ) {
        setViewing('sustainability');
      } else if (
        productState.id &&
        sectionsContainerRef.current.scrollTop + offset >= additionalFilesRef.current?.offsetTop
      ) {
        setViewing('additional-files');
      } else if (
        !catalog.promotedBy &&
        sectionsContainerRef.current.scrollTop + offset >= distributionRef.current?.offsetTop
      ) {
        setViewing('distribution');
      } else if (sectionsContainerRef.current.scrollTop + offset >= priceRef.current?.offsetTop) {
        setViewing('price-section');
      } else if (sectionsContainerRef.current.scrollTop + offset >= imagesRef.current?.offsetTop) {
        setViewing('images-section');
      } else if (sectionsContainerRef.current.scrollTop + offset >= characteristicsRef.current?.offsetTop) {
        setViewing('characteristics');
      }
    };

    if (sectionsContainerRef.current) {
      sectionsContainerRef.current.addEventListener('scroll', handleScroll);
    }
    workspaceAddressesGet(me.id, catalogId);

    return () => {
      if (sectionsContainerRef.current) {
        sectionsContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [productState.id, catalog.promotedBy, catalogId, me.id, workspaceAddressesGet]);

  React.useEffect(() => {
    workspaceAddressesGet(me.id, catalogId);
  }, [me.id, catalogId]);

  const getSections = (): Sections => {
    const result: Sections = [
      {
        id: 'characteristics',
        title: __('ProductEdit.Characteristics.title'),
        icon: 'Edit',
      },
      {
        id: 'images-section',
        title: __('ProductEdit.Visibility.title'),
        icon: 'Clip',
      },
      {
        id: 'price-section',
        title: __('ProductEdit.Price.title'),
        icon: 'Price',
      },
      !catalog.promotedBy
        ? {
            id: 'distribution',
            title: __('ProductEdit.Distribution.title'),
            icon: 'Pickup-package',
          }
        : null,
      ...(config.TOGGLE_SUSTAINABILITY.enabled && !catalog.promotedBy
        ? ([
            {
              id: 'sustainability',
              title: __('ProductEdit.Sustainability.left_title'),
              icon: 'Producer',
            },
          ] as Sections)
        : []),
      ...(product.id
        ? ([
            {
              id: 'additional-files',
              title: __('ProductEdit.additionalFiles.title'),
              icon: 'Document',
            },
          ] as Sections)
        : []),

      ...(product.id
        ? ([
            {
              id: 'delete',
              title: __('Components.ProductDetails.other_options'),
              icon: 'Kebab',
            },
          ] as Sections)
        : []),
    ];

    return result;
  };

  const renderCharacteristics = () => {
    return (
      <FormSection
        id="characteristics"
        title={__('ProductEdit.Characteristics.title')}
        sectionRef={characteristicsRef}
        withoutPaddingBottom={true}
      >
        <S.FormCol className="product-edit-characteristics">{renderCharacteristicsForm()}</S.FormCol>
      </FormSection>
    );
  };

  const renderPrice = () => {
    return (
      <FormSection
        id="price-section"
        title={__('ProductEdit.Price.title')}
        sectionRef={priceRef}
        withoutPaddingBottom={true}
      >
        <S.FormCol className="product-edit-price">{renderPriceForm()}</S.FormCol>
      </FormSection>
    );
  };

  const renderDistribution = () => {
    return (
      <FormSection
        id="distribution"
        title={__('ProductEdit.Distribution.title')}
        sectionRef={distributionRef}
        withoutPaddingBottom={true}
        numHeaders={product.id || config.TOGGLE_SUSTAINABILITY.enabled ? undefined : 2}
      >
        <S.FormCol className="product-edit-distribution">
          {config?.TOGGLE_BOXES_PER_PALLET?.enabled && catalog.mandatoryPalletizationByClient
            ? renderNewDistributionForm()
            : renderDistributionForm()}
        </S.FormCol>
      </FormSection>
    );
  };

  const renderSustainability = () => {
    const sustainability = Sustainability[productState.type?.type];
    const co2Max = sustainability?.co2Max?.toFixed(2);
    const co2Min = sustainability?.co2Min?.toFixed(2);
    const waterMax = (sustainability?.waterMax * 1000)?.toFixed(2);
    const waterMin = (sustainability?.waterMin * 1000)?.toFixed(2);
    const name = prodTypes[productState.type?.type]?.name;

    return (
      <FormSection
        id="sustainability"
        title={__('ProductEdit.Sustainability.title')}
        sectionRef={sustainabilityRef}
        withoutPaddingTop={true}
        numHeaders={productState.id ? undefined : 2}
      >
        <S.GreenSpan>
          <FontIcon name="Producer-solid" disableHover={true} />
          <S.GreenSubtitle>{__('ProductEdit.Sustainability.green_subtitle')}</S.GreenSubtitle>
        </S.GreenSpan>
        {productState.type?.type ? (
          sustainability ? (
            <>
              {co2Max
                ? renderSustainabilityItem(
                    '🌤',
                    'sun',
                    __('ProductEdit.Sustainability.co2.title'),
                    co2Max === co2Min
                      ? __('ProductEdit.Sustainability.co2.similar_data', { product: name, co2Max })
                      : __('ProductEdit.Sustainability.co2.data', { product: name, co2Max, co2Min }),
                    '(1)',
                  )
                : null}
              {waterMax
                ? renderSustainabilityItem(
                    '💧',
                    'water',
                    __('ProductEdit.Sustainability.water.title'),
                    waterMax === waterMin
                      ? __('ProductEdit.Sustainability.water.similar_data', { product: name, waterMax })
                      : __('ProductEdit.Sustainability.water.data', { product: name, waterMax, waterMin }),
                    '(1)',
                  )
                : null}

              <S.Sustainabilitytext>
                {__('ProductEdit.Sustainability.own_data')}{' '}
                <S.ModifyLink onClick={showSustainabilityModal}>
                  {__('ProductEdit.Sustainability.modify_cta')}
                </S.ModifyLink>
              </S.Sustainabilitytext>
            </>
          ) : (
            <>
              {renderSustainabilityItem(
                '🌤',
                'sun',
                __('ProductEdit.Sustainability.co2.title'),
                __('ProductEdit.Sustainability.no_data'),
                undefined,
                <S.ModifyLink onClick={showSustainabilityModal}>
                  {__('ProductEdit.Sustainability.add_cta')}
                </S.ModifyLink>,
              )}
              {renderSustainabilityItem(
                '💧',
                'water',
                __('ProductEdit.Sustainability.water.title'),
                __('ProductEdit.Sustainability.no_data'),
                undefined,
                <S.ModifyLink onClick={showSustainabilityModal}>
                  {__('ProductEdit.Sustainability.add_cta')}
                </S.ModifyLink>,
              )}
            </>
          )
        ) : (
          <>
            {renderSustainabilityItem(
              '🌤',
              'sun',
              __('ProductEdit.Sustainability.co2.title'),
              __('ProductEdit.Sustainability.co2.subtitle'),
            )}
            {renderSustainabilityItem(
              '💧',
              'water',
              __('ProductEdit.Sustainability.water.title'),
              __('ProductEdit.Sustainability.water.subtitle'),
            )}
          </>
        )}
        {renderSustainabilityItem(
          '🥤',
          'plastic',
          __('ProductEdit.Sustainability.plastic.title'),
          productState.plasticType
            ? __('ProductEdit.Sustainability.plastic.data', {
                plastic_type: constants.getPlasticLiteral(productState.plasticType),
                plastic_unit: constants
                  .getPriceUnitLabels(productState.plasticUnit, me.settings.language, catalog.defaultWeightUnit)
                  .toLocaleLowerCase(),
                plastic_amount: productState.plasticAmount,
              })
            : null,
          undefined,
          undefined,
          true,
        )}
        <S.PlasticButton
          iconName={productState.plasticType ? 'Edit' : 'Add-more'}
          type="link"
          onClick={() => setShowPlasticModal(true)}
          withoutPadding
        >
          {productState.plasticType
            ? __('ProductEdit.Sustainability.plastic.cta')
            : __('ProductEdit.Sustainability.plastic.cta_add')}
        </S.PlasticButton>
      </FormSection>
    );
  };

  const renderSustainabilityItem = (
    icon: string,
    label: string,
    title: string,
    subtitle: string,
    sup?: string,
    Cta?: React.ReactNode,
    withoutPadding?: boolean,
  ) => {
    return (
      <>
        <S.Sustainabilitytitle>
          <span role="img" aria-label={label}>
            {icon}
          </span>{' '}
          {title}
        </S.Sustainabilitytitle>
        {subtitle ? (
          <S.SubRow withoutPaddingBottom={true}>
            <S.Sustainabilitytext withoutPaddingBottom={withoutPadding}>
              <>
                {subtitle ? utils.formatText(subtitle, (s, idx) => <S.TextBold key={idx}>{s}</S.TextBold>) : ''}
                {sup ? <S.Sup>{sup}</S.Sup> : null} {Cta}
              </>
            </S.Sustainabilitytext>
          </S.SubRow>
        ) : null}
      </>
    );
  };

  const showSustainabilityModal = () => {
    modalOpen(
      __('ProductEdit.Sustainability.modal.title'),
      supportAction,
      {
        buttonText: __('ProductEdit.Sustainability.modal.cta'),
        text2: __('ProductEdit.Sustainability.modal.text'),
        showCancelButton: true,
      },
      'nice',
    );
  };

  const renderDeleteProduct = () => {
    return (
      <FormSection
        id="delete"
        title={__('Components.ProductDetails.other_options')}
        sectionRef={deleteRef}
        numHeaders={2}
      >
        <S.SubRow>
          <S.FormCol className="product-delete">
            <S.DeleteButton type="delete" iconName="Trash" iconSize="16px" onClick={() => onRemove(productState)}>
              {__('Components.ProductDetails.remove_action')}
            </S.DeleteButton>
          </S.FormCol>
          <S.FormCol>
            {!catalog.promotedBy ? (
              <S.CloneButton
                type="secondary"
                onClick={() => {
                  modalOpen(
                    __('Components.ProductDetails.clone_title'),
                    () => {
                      setProductState(prevState => ({
                        ...prevState,
                        id: undefined,
                        images: product.images.map(img => ({ image: { url: img.image.url }, imageId: 0 })),
                        sku: undefined,
                        userId: me.id,
                      }));
                      setHasChangesState(true);
                      setIsCloning(true);
                      setViewing('characteristics');
                      sectionsContainerRef.current.scrollTo(0, 0);
                      productClone();
                    },
                    {
                      buttonText: __('Components.ProductDetails.clone_cta'),
                      text2: __('Components.ProductDetails.clone_description', {
                        name: productService.getProductTypeVarietyDisplay(
                          productState.type.type,
                          prodTypes[productState.type.type] ? prodTypes[productState.type.type].name : '',
                          productState.type.variety,
                        ),
                      }),
                      showCancelButton: true,
                    },
                    'nice',
                  );
                }}
              >
                {__('Components.ProductDetails.clone_action')}
              </S.CloneButton>
            ) : null}
          </S.FormCol>
        </S.SubRow>
        {config.TOGGLE_SUSTAINABILITY.enabled && Sustainability[productState.type.type] ? (
          <S.SubRowInfo>
            <S.SupInfo>(1)</S.SupInfo>
            <S.Text>
              {
                utils.formatText(__('ProductEdit.Sustainability.data_origin'), (s, idx) =>
                  idx === 1 ? (
                    <S.ExtLink
                      key={idx}
                      target="_blank"
                      href="https://doc.agribalyse.fr/documentation/methodologie-acv"
                    >
                      {s}
                    </S.ExtLink>
                  ) : (
                    <S.ExtLink key={idx} target="_blank" href="https://www.iso.org/standard/38498.html">
                      {s}
                    </S.ExtLink>
                  ),
                ) as any // TYPEERROR
              }
            </S.Text>
          </S.SubRowInfo>
        ) : null}
      </FormSection>
    );
  };

  const renderVisibility = () => {
    let subtitle = '';
    switch (productState.status) {
      case webConstants.AVAILABILITY.ACTIVE:
        subtitle = __('ProductEdit.Visibility.subtitle');
        break;
      case webConstants.AVAILABILITY.INACTIVE:
        subtitle = __('ProductEdit.Visibility.subtitle_inactive');
        break;
      case webConstants.AVAILABILITY.UNAVAILABLE:
        subtitle = __('ProductEdit.Visibility.subtitle_out_of_stock');
        break;
    }
    return (
      <FormSection
        id="visibility"
        title={__('ProductEdit.Visibility.availability')}
        sectionRef={visibilityRef}
        withoutPaddingBottom={true}
      >
        {renderProductVisibleSwitch()}
        <S.TextPlaceholder>{subtitle}</S.TextPlaceholder>

        {productState.status === webConstants.AVAILABILITY.UNAVAILABLE ? (
          <>
            <S.StatusRow>
              <S.CalendarTitle>{__('ProductEdit.Visibility.estimated_date')}</S.CalendarTitle>
              <Tooltip position="bottom" text={__('ProductEdit.Visibility.tooltip')} width="200px" themeMode="dark">
                <S.InfoIcon name="Info" disableHover={true} />
              </Tooltip>
            </S.StatusRow>
            <DatePicker
              id="availability-date"
              minDate={new Date(Date.now())}
              initDate={
                productState.availableSoon?.availableFrom
                  ? new Date(productState.availableSoon.availableFrom)
                  : undefined
              }
              onDateChange={newDate => {
                const date = new Date(newDate).getTime();
                changeProduct('availableSoon', { ...productState.availableSoon, availableFrom: date });
              }}
            />
            <S.StatusCheckBoxRow>
              <S.CheckBox
                className="product-availability"
                isChecked={productState.availableSoon?.transitionOnAvailable}
                disabled={false}
                onClick={checked =>
                  changeProduct('availableSoon', { ...productState.availableSoon, transitionOnAvailable: checked })
                }
              />
              <S.StatusCheckBox>{__('ProductEdit.Visibility.checkbox_label')}</S.StatusCheckBox>
            </S.StatusCheckBoxRow>
            <S.TextPlaceholder>{__('ProductEdit.Visibility.date_unavailable')}</S.TextPlaceholder>
          </>
        ) : null}
      </FormSection>
    );
  };

  const renderLot = () => {
    if (!config.TOGGLE_PRODUCT_LOT.enabled || !(productState.id || productState.originLots?.length)) {
      return null;
    }

    return (
      <FormSection id="visibility" title={__('ProductEdit.Lot.Lot')} sectionRef={visibilityRef}>
        <S.FormCol className="product-edit-lot">
          {productState.id && (
            <>
              <S.Lot>LT-{productState.id}</S.Lot>
              <Button
                type="link"
                iconName="Add-more"
                withoutPadding
                onClick={() => {
                  setHasChangesState(true);
                  setIsCloning(true);
                  setViewing('characteristics');
                  sectionsContainerRef.current.scrollTo(0, 0);
                  productClone();
                }}
              >
                {__('ProductEdit.Lot.Cta')}
              </Button>
            </>
          )}
          {productState.originLots?.length ? (
            <>
              <S.AssociatedLot>{__('ProductEdit.Lot.Associated')}</S.AssociatedLot>
              {productState.originLots.map((l, i) => (
                <S.Lot key={l + i}>LT-{l}</S.Lot>
              ))}
              <Button type="link" withoutPadding onClick={() => setShowTraceability(true)}>
                {__('ProductEdit.Lot.Traceability_cta')}
              </Button>
            </>
          ) : null}
        </S.FormCol>
      </FormSection>
    );
  };

  const renderPictures = () => {
    const productImages: Array<ISizeImage> = productState.images ? productState.images.filter(i => i.image?.url) : [];
    const [image0, image1, image2, image3] = productImages.map(img => img.image?.url);

    return (
      <ColumnContainer>
        <S.TextPlaceholder>{__('Components.ProductDetails.allowed_format')}</S.TextPlaceholder>
        <S.SubRow alignItems="flex-end">
          <S.Picture
            editable={editMode}
            imageUrl={image0}
            onDelete={() => onPicturedDelete(0)}
            onFileChange={file => onPictureChange(file, 0)}
            pictureMode={true}
            picturePlaceholder={__('Components.Picture.placeholder.product')}
            relation={constants.IMAGE_RELATION.PRODUCT}
            size="big"
            withCrop={true}
          />
          <S.Picture
            editable={editMode}
            imageUrl={image1}
            onDelete={() => onPicturedDelete(1)}
            onFileChange={file => onPictureChange(file, 1)}
            pictureMode={true}
            picturePlaceholder={__('Components.Picture.placeholder.product')}
            relation={constants.IMAGE_RELATION.PRODUCT}
            size="big"
            withCrop={true}
          />
          <S.Picture
            editable={editMode}
            imageUrl={image2}
            onDelete={() => onPicturedDelete(2)}
            onFileChange={file => onPictureChange(file, 2)}
            pictureMode={true}
            picturePlaceholder={__('Components.Picture.placeholder.product')}
            relation={constants.IMAGE_RELATION.PRODUCT}
            size="big"
            withCrop={true}
          />
          <S.Picture
            editable={editMode}
            imageUrl={image3}
            onDelete={() => onPicturedDelete(3)}
            onFileChange={file => onPictureChange(file, 3)}
            pictureMode={true}
            picturePlaceholder={__('Components.Picture.placeholder.product')}
            relation={constants.IMAGE_RELATION.PRODUCT}
            size="big"
            withCrop={true}
          />
        </S.SubRow>
      </ColumnContainer>
    );
  };

  const renderImages = () => {
    return (
      <FormSection
        id="images-section"
        title={__('ProductEdit.Visibility.title')}
        sectionRef={imagesRef}
        withoutPaddingBottom={true}
      >
        <S.FormCol className="product-images">{renderPictures()}</S.FormCol>
      </FormSection>
    );
  };

  const renderPriceGroups = (isQuoterMode: boolean) => {
    const title = isQuoterMode
      ? __('ProductEdit.Margins.product_margins', { count: productState.priceGroups?.length || 0 })
      : __('ProductEdit.PriceGroups.product_price_groups', { count: productState.priceGroups?.length || 0 });

    const description = isQuoterMode
      ? __('ProductEdit.Margins.description', { count: productState.priceGroups?.length || 0 })
      : __('ProductEdit.PriceGroups.description', { count: productState.priceGroups?.length || 0 });

    const link = isQuoterMode
      ? __('ProductEdit.Margins.link', { count: productState.priceGroups?.length || 0 })
      : __('ProductEdit.PriceGroups.link', { count: productState.priceGroups?.length || 0 });

    const zeroCaseLink = isQuoterMode
      ? __('ProductEdit.Margins.link', { count: productState.priceGroups?.length || 0 })
      : __('ProductEdit.NoPriceGroups.link');

    const zeroCase = isQuoterMode
      ? __('ProductEdit.Margins.description', { count: productState.priceGroups?.length || 0 })
      : __('ProductEdit.NoPriceGroups.description');

    return (
      <FormSection
        id="price-groups"
        title={title}
        sectionRef={priceGroupsRef}
        tag={sellerWorkspaceService.isProPlan(catalog?.plan) ? undefined : 'PRO'}
        tagColor={colors.blue1}
      >
        <S.FormCol className="product-edit-price-groups">
          <S.TextPlaceholder>
            {priceGroups && Object.keys(priceGroups)?.length ? description : zeroCase}
          </S.TextPlaceholder>
          <S.TextLink onClick={showPricegroupsModal}>
            {priceGroups && Object.keys(priceGroups)?.length ? link : zeroCaseLink}
          </S.TextLink>
        </S.FormCol>
      </FormSection>
    );
  };

  const renderSections = () => {
    return (
      <FormSection id="tags" title={__('ProductEdit.Tags.title')} sectionRef={tagsRef} withoutPaddingBottom={true}>
        <S.FormCol className="product-edit-tags">{renderSectionsForm()}</S.FormCol>
      </FormSection>
    );
  };

  const renderPriceGroupsModal = (isQuoterMode: boolean) => {
    const productName = productService.getProductTypeVarietyDisplay(
      productState.type.type,
      prodTypes[productState.type.type] ? prodTypes[productState.type.type].name : '',
      productState.type.variety,
    );
    const pricePrecision = catalog ? catalog?.numberOfDecimalsShowed : constants.PRICE_PRECISION;
    const itemsToShow = priceGroupItems?.filter(
      i => !searchText || priceGroups[i.priceGroupId]?.name.toLowerCase().includes(searchText.toLowerCase()),
    );
    return showPriceGroups ? (
      <S.Modal>
        <S.PriceGroupSection>
          <S.PriceGroupHeader>
            <S.Title>{productName}</S.Title>
            <InputWithLabel isRequired={true} disabled={true} label={__('Components.ProductDetails.base_price')}>
              {productState.isPor ? (
                <S.POR>{__('Components.ProductsList.Table.POR')}</S.POR>
              ) : (
                <Input
                  name="price"
                  type="number"
                  value={typeof productState.price === 'number' ? productState.price.toString() : ''}
                  hasError={!!state.errors.get('price')}
                  onBlur={changeProduct}
                  isRequired={true}
                  disabled={true}
                  minValue={currency.getPriceInputMinimumValue(pricePrecision)}
                  precision={pricePrecision}
                  variableTextSingular={constants.CURRENCIES[productState.currency].symbol}
                  variableTextPlural={constants.CURRENCIES[productState.currency].symbol}
                />
              )}
            </InputWithLabel>
            <InputWithLabel
              isRequired={true}
              label={
                isQuoterMode
                  ? __('Components.ProductDetails.margins_applied', {
                      name: productName,
                      count: priceGroupItems?.length,
                    })
                  : __('Components.ProductDetails.pricegroups_applied', {
                      name: productName,
                      count: priceGroupItems?.length,
                    })
              }
            >
              <Button
                type="link"
                onClick={() => setShowAddToPriceGroup(true)}
                iconName="Add-more"
                withoutPadding={true}
                iconSize="18px"
              >
                {isQuoterMode
                  ? __('Components.ProductDetails.add_to_margins')
                  : __('Components.ProductDetails.add_to_pricegroups')}
              </Button>
              {priceGroupItems?.length ? (
                <S.SearchWrapper id="search-products-wrapper">
                  <S.PriceGroupSearch
                    id="search-products-price-group"
                    query={searchText}
                    onChange={(text: string) => setSearchText(text)}
                    placeHolder={
                      isQuoterMode
                        ? __('Components.ProductDetails.search.placeholder_margin')
                        : __('Components.ProductDetails.search.placeholder')
                    }
                  />
                </S.SearchWrapper>
              ) : null}
            </InputWithLabel>
          </S.PriceGroupHeader>

          {priceGroupItems?.length ? (
            <PriceItemTable
              catalog={catalog}
              className="prices-items-table"
              contacts={contacts}
              disabled={false}
              fromProduct={true}
              hasMore={false}
              isQuoterMode={isQuoterMode}
              me={me}
              modalClose={modalClose}
              modalOpen={modalOpen}
              onRemovePrice={onRemovePrice}
              onUpdatePrice={onUpdatePrice}
              priceGroupItems={itemsToShow}
              priceGroups={priceGroups}
              prices={prices}
              products={[{ ...productState, id: productState.id || 0 }]}
              searchMore={() => null}
              showLoading={0}
              showStickyHeader={false}
              touchImage={() => null}
            />
          ) : null}
        </S.PriceGroupSection>
        {showAddToPriceGroup ? (
          <AddToPriceGroup
            contacts={contacts}
            isQuoterMode={isQuoterMode}
            me={me}
            setShowAddToPriceGroup={setShowAddToPriceGroup}
            priceGroups={
              priceGroups
                ? Object.values(priceGroups).filter(p => !priceGroupItems?.find(i => i.priceGroupId === p.priceGroupId))
                : []
            }
            pricesAddProduct={pricesAddProductMetod}
            product={productState}
          />
        ) : null}
      </S.Modal>
    ) : null;
  };

  const onUpdatePrice = (priceGroupItem: IPriceGroupItem) => {
    EventTrack.track('workspace_pricegroup_update_price', {
      workspace_id: catalogId,
      pricegroup_id: priceGroupItem.priceGroupId,
      product_id: priceGroupItem.productId,
    });

    const { priceGroupId } = priceGroupItem;

    if (product.id) {
      // If product has an ID, update the price group with the new price
      priceGroupAddPrices(catalogId, priceGroupId, [priceGroupItem], catalog.plan.addons?.quoterMarginsEnabled);
    } else {
      // If product doesn't have an ID, manage price groups locally
      const { priceGroups = [] } = product;
      const idx = priceGroups.findIndex(p => p === priceGroupId);

      if (idx !== -1) {
        // If priceGroupId already exists, replace it
        priceGroups[idx] = priceGroupId;
      } else {
        // If priceGroupId does not exist, add it
        priceGroups.push(priceGroupId);
      }

      setProductState({ ...product, priceGroups });
    }
  };

  const onRemovePrice = React.useCallback(
    (priceGroupItem: IPriceGroupItem) => {
      EventTrack.track('workspace_pricegroup_delete_price', {
        workspace_id: catalogId,
        pricegroup_id: priceGroupItem.priceGroupId,
        product_id: priceGroupItem.productId,
      });

      if (product.id) {
        priceGroupDeletePrice(
          catalogId,
          priceGroupItem.priceGroupId,
          priceGroupItem.productId,
          priceGroupItem.sku,
          (err?: Error) => {
            if (!err) {
              const newPriceGroupItems = priceGroupItems.filter(
                item => item.priceGroupId !== priceGroupItem.priceGroupId,
              );
              setPriceGroupItems(newPriceGroupItems);
              setProductState(prevProduct => ({
                ...prevProduct,
                priceGroups: newPriceGroupItems.map(item => item.priceGroupId),
              }));
            }
          },
        );
      } else {
        setProductState(prevProduct => {
          const updatedPriceGroups = (prevProduct.priceGroups || []).filter(id => id !== priceGroupItem.priceGroupId);
          return {
            ...prevProduct,
            priceGroups: updatedPriceGroups,
          };
        });
      }
    },
    [catalogId, priceGroupDeletePrice, product.id, priceGroupItems],
  );

  const fixBoxPerPalletValues = React.useCallback((product: IProduct) => {
    if (!product?.boxesPerPalletValues) return;
    const fixedValues = product.boxesPerPalletValues
      .filter(e => e.value !== 0)
      .map(e => (typeof e.value === 'string' ? { ...e, value: parseFloat(e.value) } : e));
    setProductState(prevProduct => ({ ...prevProduct, boxesPerPalletValues: fixedValues }));
  }, []);

  const save = React.useCallback(() => {
    if (!hasChangesState) return;
    fixBoxPerPalletValues(productState);
    const validationErrors = validateProductForm(productState);
    setErrors(validationErrors);

    if (validationErrors.size === 0) {
      setHasChangesState(false);
      const cloneProductState = {
        ...productState,
        price: Number(productState.price),
        box_weight: Number(productState.boxWeight),
      };
      if (productState.id) {
        productUpdate(catalogId, productState, () => showSaveSuccessModal('updated'));
      } else {
        productCreate(catalogId, cloneProductState, (p?: IProduct) => {
          if (cloneProductState.priceGroups?.length) {
            pricesAddProduct(p.catalogId, p.id, cloneProductState.priceGroups, pgis => {
              setPriceGroupItems(prevItems => [
                ...prevItems,
                ...pgis.filter(pgi => !prevItems.find(pg => pg.priceGroupId === pgi.priceGroupId)),
              ]);
              showSaveSuccessModal('created');
            });
          } else {
            showSaveSuccessModal('created');
          }
        });
      }
    } else {
      if (validationErrors.has('type') || validationErrors.has('variety')) {
        scrollToSection('characteristics');
      } else if (validationErrors.has('price')) {
        scrollToSection('price-section');
      }
    }
  }, [hasChangesState, productState, catalogId, pricesAddProduct, productUpdate, productCreate, fixBoxPerPalletValues]);

  const scrollToSection = React.useCallback((id: string) => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const setError = React.useCallback((key: string, error?: string) => {
    setErrors(prevErrors => {
      const newErrors = new Map(prevErrors);
      if (error) {
        newErrors.set(key, error);
      } else {
        newErrors.delete(key);
      }
      return newErrors;
    });
  }, []);

  const changeProduct = React.useCallback(
    (key: string, value: any, error?: string) => {
      setError(key, error);
      setProductState(prevProduct => ({
        ...prevProduct,
        [key]: value === '' ? '' : value,
      }));
      setHasChangesState(true);
    },
    [setError],
  );

  const changeProductMultiple = React.useCallback(
    (changes: Array<{ key: string; value: string | number | boolean | Array<PRODUCT_UNIT>; error?: string }>) => {
      setHasChangesState(true);
      setProductState(prevProduct => {
        const updatedProduct = { ...prevProduct };
        const options = AllProductUnitsArray;
        changes.forEach(({ key, value, error }) => {
          setError(key, error);
          updatedProduct[key] = value;
        });
        updatedProduct.saleUnits = updatedProduct.saleUnits.filter(u =>
          productService.isAllowedSaleUnit(updatedProduct, u, catalog.mandatoryPalletizationByClient),
        );
        if (!updatedProduct.saleUnits.length) {
          updatedProduct.saleUnits.push(
            options.find(u =>
              productService.isAllowedSaleUnit(updatedProduct, u, catalog.mandatoryPalletizationByClient),
            ),
          );
        }
        return updatedProduct;
      });
    },
    [catalog],
  );

  const changeProductType = React.useCallback((key: string, value: string, error?: string) => {
    setError(key, error);
    setHasChangesState(true);
    setProductState(prevProduct => ({
      ...prevProduct,
      type: { ...prevProduct.type, [key]: value },
    }));
  }, []);

  const changeProductImage = React.useCallback((product: IProduct, url?: string, index: number = 0) => {
    if (!url) return;

    setProductState(prevState => {
      const imagesUpdate = [...(prevState.images || [])];

      if (index < imagesUpdate.length) {
        imagesUpdate[index] = {
          image: { url },
          imageId: 0,
        };
      } else {
        imagesUpdate.push({
          image: { url },
          imageId: 0,
        });
      }

      return {
        ...prevState,
        images: imagesUpdate,
      };
    });

    setHasChangesState(true);
  }, []);

  const changeProductDebounce = React.useCallback(debounce(changeProduct, 500), []);

  const renderCharacteristicsForm = () => {
    return (
      <>
        <S.SubRow withoutPaddingBottom={true}>
          <S.SubColumn>
            {renderSelectInput(
              'type',
              __('Components.ProductDetails.product.type'),
              true,
              getProductTypes(prodTypes),
              changeProductType,
              productState.type.type,
            )}
          </S.SubColumn>
          <S.EmptyColumn />
          <S.SubColumn>
            {renderTextInput(
              'variety',
              __('Components.ProductDetails.product.variety'),
              changeProductType,
              product.type.variety,
              true,
            )}
          </S.SubColumn>
        </S.SubRow>
        <S.Row>
          <InputWithLabel
            label={__('Components.ProductDetails.product.name')}
            isRecommended={true}
            isRequired={false}
            width="100%"
          >
            <TextArea
              name="productTitle"
              containerMargin="4px 0"
              width="100%"
              value={productState.productTitle}
              onChange={changeProduct}
              disabled={!editMode}
              rows={3}
              placeholder={
                productState.productTitle ||
                productService.getProductTypeVarietyDisplay(
                  productState.type.type,
                  prodTypes[productState.type.type]?.name || '',
                  productState.type.variety,
                )
              }
            />
          </InputWithLabel>
        </S.Row>
        <S.SubRow withoutPaddingBottom={true}>
          <S.SubColumn>
            {renderSelectInput(
              'origin',
              __('Components.ProductDetails.product.origin'),
              false,
              getCountries(countries),
              changeProductType,
              productState.type.origin,
            )}
            {renderTextInput(
              'packaging',
              __('Components.ProductDetails.distribution.packaging'),
              changeProductDebounce,
              productState.packaging,
            )}
            {!catalog.promotedBy && (
              <>
                {renderTextInput(
                  'size',
                  __('Components.ProductDetails.product.size'),
                  changeProductDebounce,
                  productState.size,
                )}
                {renderTextInput(
                  'brand',
                  __('Components.ProductDetails.product.brand'),
                  changeProductDebounce,
                  productState.brand,
                )}
                <S.SubRow>
                  {renderSelectInput(
                    'eanType',
                    __('Components.ProductDetails.metadata.eanCode'),
                    true,
                    [
                      { value: 'EAN', label: 'EAN (GTIN)' },
                      { value: 'PLU', label: 'PLU' },
                      { value: 'UPC', label: 'UPC' },
                    ],
                    changeProductDebounce,
                    productState.eanType,
                    { padding: '-1px 0 0 6px', width: '65%', marginRight: '6px' },
                  )}
                  <S.RightElement>
                    {renderTextInput(
                      'eanCode',
                      ' ',
                      changeProductDebounce,
                      productState.eanCode,
                      undefined,
                      undefined,
                      255,
                    )}
                  </S.RightElement>
                </S.SubRow>
              </>
            )}
            {config.TOGGLE_CONSENTIO_PRODUCT_ID.enabled && catalog.promotedBy && product.id && (
              <S.IdColumn>
                <S.BoldText>{__('ProductEdit.unique_id.title')}</S.BoldText>
                <S.GreyText>
                  {product.hashId}
                  <Tooltip position="right" text={__('ProductEdit.unique_id.tooltip')} width="200px" themeMode="dark">
                    <S.GreyInfoIcon name="Info" disableHover={true} />
                  </Tooltip>
                </S.GreyText>
              </S.IdColumn>
            )}
          </S.SubColumn>
          <S.EmptyColumn />
          <S.SubColumn>
            {renderTextInput(
              'region',
              __('Components.ProductDetails.product.region'),
              changeProduct,
              productState.region,
            )}
            {!catalog.promotedBy && (
              <>
                {renderSelectInput(
                  'category',
                  __('Components.ProductDetails.product.category'),
                  false,
                  getCategories(),
                  changeProduct,
                  productState.category,
                )}
                {renderSelectInput(
                  'organic',
                  __('Components.ProductDetails.product.organic'),
                  false,
                  getOrganicTypes(),
                  changeProduct,
                  productState.organic,
                )}
                {renderTextInput(
                  'sku',
                  __('Components.ProductDetails.metadata.sku'),
                  changeProductDebounce,
                  productState.sku,
                )}
              </>
            )}
            {catalog.promotedBy &&
              renderTextInput(
                'brand',
                __('Components.ProductDetails.product.brand'),
                changeProductDebounce,
                productState.brand,
              )}
            {config.TOGGLE_CONSENTIO_PRODUCT_ID.enabled && !catalog.promotedBy && product.id && (
              <S.IdColumn>
                <S.BoldText>{__('ProductEdit.unique_id.title')}</S.BoldText>
                <S.GreyText>
                  {product.hashId}
                  <Tooltip position="right" text={__('ProductEdit.unique_id.tooltip')} width="200px" themeMode="dark">
                    <S.GreyInfoIcon name="Info" disableHover={true} />
                  </Tooltip>
                </S.GreyText>
              </S.IdColumn>
            )}
          </S.SubColumn>
        </S.SubRow>
        <InputWithLabel label={__('Components.ProductDetails.more_info.title')} isRequired={false} width="100%">
          <TextArea
            containerMargin="4px 0"
            maxLength={2048}
            name="moreInfo"
            onBlur={changeProduct}
            onChange={changeProduct}
            placeholder={__('Components.ProductDetails.more_info.placeholder')}
            disabled={!editMode}
            value={productState.moreInfo || ''}
          />
        </InputWithLabel>
      </>
    );
  };

  const renderDistributionForm = () => {
    // const pricePrecision = catalog ? catalog.numberOfDecimalsShowed : constants.PRICE_PRECISION;
    const options = config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
      ? [PRODUCT_UNIT.PIECE, PRODUCT_UNIT.BOX, PRODUCT_UNIT.PALLET]
      : AllProductUnitsArray;

    return (
      <>
        <InputWithLabel label={__('Components.ProductDetails.sale_units.title')} isRequired={true} width="100%">
          <S.CheckBoxRow>
            {options.map((el, i) => (
              <React.Fragment key={el}>
                <S.CheckBox
                  className="sale-units"
                  isChecked={productState.saleUnits.includes(el)}
                  disabled={
                    !productService.isAllowedSaleUnit(productState, options[i], catalog.mandatoryPalletizationByClient)
                  }
                  onClick={() =>
                    changeProductMultiple([
                      {
                        key: 'saleUnits',
                        value: [...productService.toggleSaleUnit(product.saleUnits, options[i])],
                      },
                    ])
                  }
                />
                <S.CheckBoxLabel>
                  {constants.getPriceUnitLabels(
                    config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId) ? priceUnitTranslate(el) : el,
                    me.settings.language,
                    catalog.defaultWeightUnit,
                  )}
                </S.CheckBoxLabel>
              </React.Fragment>
            ))}
          </S.CheckBoxRow>
        </InputWithLabel>

        <S.SubRow withoutPaddingBottom={true}>
          <S.SubColumn>
            {renderNumberInput(
              'boxWeight',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.shelfWeight')
                : __('Components.ProductDetails.distribution.boxWeight'),
              (key, value, error) =>
                changeProductMultiple([
                  { key, value, error },
                  {
                    key: 'saleUnits',
                    value: productService.clearSaleUnits(productState, catalog.mandatoryPalletizationByClient),
                  },
                ]),
              productState.boxWeight,
              undefined,
              0.01,
              undefined,
              2,
              catalog?.defaultWeightUnit,
              catalog?.defaultWeightUnit,
            )}
            {renderNumberInput(
              'piecesPerBox',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.piecesPerShelf')
                : __('Components.ProductDetails.distribution.piecesPerBox'),
              (key, value, error) =>
                changeProductMultiple([
                  { key, value, error },
                  {
                    key: 'saleUnits',
                    value: productService.clearSaleUnits(productState, catalog.mandatoryPalletizationByClient),
                  },
                ]),
              productState.piecesPerBox,
              undefined,
              1,
            )}
            {renderNumberInput(
              'boxesPerPallet',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.shelvesPerCart')
                : __('Components.ProductDetails.distribution.boxesPerPallet'),
              (key, value, error) =>
                changeProductMultiple([
                  { key, value, error },
                  {
                    key: 'saleUnits',
                    value: productService.clearSaleUnits(productState, catalog.mandatoryPalletizationByClient),
                  },
                ]),
              productState.boxesPerPallet,
              undefined,
              1,
            )}
            {renderNumberInput(
              'palletHeight',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.cartHeight')
                : __('Components.ProductDetails.distribution.palletHeight'),
              changeProduct,
              productState.palletHeight,
              undefined,
              0,
              undefined,
              2,
              catalog?.lengthUnit,
            )}
          </S.SubColumn>
          <S.EmptyColumn />
          <S.SubColumn>
            {renderSelectInput(
              'boxId',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.shelfType')
                : __('Components.ProductDetails.distribution.boxType'),
              false,
              boxTypes?.map(b => ({ value: b.id + '', label: b.name })) || [],
              (key, value, error) => changeProduct(key, Number(value), error),
              productState.boxId + '',
            )}
            {renderSelectInput(
              'palletId',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.cartType')
                : __('Components.ProductDetails.distribution.palletType'),
              false,
              palletTypes?.map(b => ({ value: b.id + '', label: b.name })) || [],
              (key, value, error) => changeProduct(key, Number(value), error),
              productState.palletId + '',
            )}
            {config.TOGGLE_PRODUCT_WAREHOUSE.enabled &&
              renderSelectInput(
                'warehouseId',
                __('Components.ProductDetails.distribution.warehouse'),
                false,
                [
                  { value: '', label: __('Facets.unspecified') },
                  ...addresses.map(a => ({
                    value: a.id,
                    label: a.displayName + (a.city || a.zip ? ` - ${a.city || ''} ${a.zip || ''}` : ''),
                  })),
                ],
                changeProduct,
                productState.warehouseId,
              )}
          </S.SubColumn>
        </S.SubRow>
      </>
    );
  };

  const renderNewDistributionForm = () => {
    const options = config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
      ? [PRODUCT_UNIT.PIECE, PRODUCT_UNIT.BOX, PRODUCT_UNIT.PALLET]
      : AllProductUnitsArray;

    return (
      <>
        <InputWithLabel label={__('Components.ProductDetails.sale_units.title')} isRequired={true} width="100%">
          <S.CheckBoxRow>
            {options.map((el, i) => (
              <React.Fragment key={el}>
                <S.CheckBox
                  className="sale-units"
                  isChecked={productState.saleUnits.includes(el)}
                  disabled={
                    !productService.isAllowedSaleUnit(productState, options[i], catalog.mandatoryPalletizationByClient)
                  }
                  onClick={() =>
                    changeProductMultiple([
                      {
                        key: 'saleUnits',
                        value: [...productService.toggleSaleUnit(productState.saleUnits, options[i] as PRODUCT_UNIT)],
                      },
                    ])
                  }
                />
                <S.CheckBoxLabel>
                  {constants.getPriceUnitLabels(
                    config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId) ? priceUnitTranslate(el) : el,
                    me.settings.language,
                    catalog.defaultWeightUnit,
                  )}
                </S.CheckBoxLabel>
              </React.Fragment>
            ))}
          </S.CheckBoxRow>
        </InputWithLabel>

        <S.SubRow withoutPaddingBottom={true}>
          <S.SubColumn>
            {renderNumberInput(
              'boxWeight',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.shelfWeight')
                : __('Components.ProductDetails.distribution.boxWeight'),
              (key, value, error) =>
                changeProductMultiple([
                  { key, value, error },
                  {
                    key: 'saleUnits',
                    value: productService.clearSaleUnits(productState, catalog.mandatoryPalletizationByClient),
                  },
                ]),
              productState.boxWeight,
              undefined,
              0.01,
              undefined,
              2,
              catalog?.defaultWeightUnit,
              catalog?.defaultWeightUnit,
            )}
            {renderNumberInput(
              'piecesPerBox',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.piecesPerShelf')
                : __('Components.ProductDetails.distribution.piecesPerBox'),
              (key, value, error) =>
                changeProductMultiple([
                  { key, value, error },
                  {
                    key: 'saleUnits',
                    value: productService.clearSaleUnits(productState, catalog.mandatoryPalletizationByClient),
                  },
                ]),
              productState.piecesPerBox,
              undefined,
              1,
            )}
            {renderNumberInput(
              'palletHeight',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.cartHeight')
                : __('Components.ProductDetails.distribution.palletHeight'),
              changeProduct,
              productState.palletHeight,
              undefined,
              0,
              undefined,
              2,
              catalog?.lengthUnit,
            )}
            {config.TOGGLE_BOXES_PER_PALLET.enabled && catalog.mandatoryPalletizationByClient
              ? renderBoxPerPalletSection(
                  'boxesPerPalletValues',
                  config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                    ? __('Components.ProductDetails.distribution.shelvesPerCart')
                    : __('Components.ProductDetails.distribution.boxesPerPallet'),
                  (key, index, value, isDefault, error) => {
                    changeBoxPerPallet(key, index, value, isDefault, error);
                  },
                  0,
                  undefined,
                  1,
                )
              : renderNumberInput(
                  'boxesPerPallet',
                  config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                    ? __('Components.ProductDetails.distribution.shelvesPerCart')
                    : __('Components.ProductDetails.distribution.boxesPerPallet'),
                  (key, value, error) =>
                    changeProductMultiple([
                      { key, value, error },
                      {
                        key: 'saleUnits',
                        value: productService.clearSaleUnits(productState, catalog.mandatoryPalletizationByClient),
                      },
                    ]),
                  productState.boxesPerPallet,
                  undefined,
                  1,
                )}
          </S.SubColumn>
          <S.EmptyColumn />
          <S.SubColumn>
            {renderSelectInput(
              'boxId',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.shelfType')
                : __('Components.ProductDetails.distribution.boxType'),
              false,
              boxTypes?.map(b => ({ value: b.id + '', label: b.name })) || [],
              (key, value, error) => changeProduct(key, Number(value), error),
              productState.boxId + '',
            )}
            {renderSelectInput(
              'palletId',
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
                ? __('Components.ProductDetails.distribution.cartType')
                : __('Components.ProductDetails.distribution.palletType'),
              false,
              palletTypes?.map(b => ({ value: b.id + '', label: b.name })) || [],
              (key, value, error) => changeProduct(key, Number(value), error),
              productState.palletId + '',
            )}
            {config.TOGGLE_PRODUCT_WAREHOUSE.enabled &&
              renderSelectInput(
                'warehouseId',
                __('Components.ProductDetails.distribution.warehouse'),
                false,
                [
                  { value: '', label: __('Facets.unspecified') },
                  ...addresses.map(a => ({
                    value: a.id,
                    label: a.displayName + (a.city || a.zip ? ` - ${a.city || ''} ${a.zip || ''}` : ''),
                  })),
                ],
                changeProduct,
                productState.warehouseId,
              )}
          </S.SubColumn>
        </S.SubRow>
      </>
    );
  };

  const renderSectionsForm = () => {
    const tagsOptions = tags
      .reduce((acc, tag) => {
        if (!productState.tags.includes(tag)) acc.push(tag);
        return acc;
      }, [])
      .sort();

    return (
      <S.SubRow>
        <ColumnContainer width="100%">
          <S.TextPlaceholder>{__('Components.ProductDetails.tag.input_label')}</S.TextPlaceholder>
          <S.TagsSelectRow>
            <Select
              containerMargin="0 0px 4px 0"
              creatable={true}
              hasError={!!errors.get('tags')}
              name="tags"
              noOptionsMessage={__('Components.Settings.sections.no_tag_available')}
              onChange={(name, value) => addNewTag(value)}
              options={tagsOptions}
              value=""
              width="100%"
              placeholder={__('Components.ProductDetails.tag.placeholder')}
            />
          </S.TagsSelectRow>
          {productState.tags.length ? (
            <S.TagsRow>
              {productState.tags.map((tag, i) =>
                webConstants.NO_TAG !== tag ? (
                  <S.ProductTag
                    label={tag}
                    className="product-tag"
                    key={tag + i}
                    onDelete={label => {
                      const tagsCopy = [...productState.tags];
                      tagsCopy.splice(tagsCopy.indexOf(label), 1);
                      changeProduct('tags', tagsCopy);
                    }}
                  />
                ) : null,
              )}
            </S.TagsRow>
          ) : null}
        </ColumnContainer>
      </S.SubRow>
    );
  };

  const addNewTag = (newTag: string) => {
    if (newTag && newTag.length > MAX_TAG_LENGTH) {
      return modalOpen(__('Components.ProductDetails.tag.error_max_length', { maxLength: MAX_TAG_LENGTH }), () => {
        modalClose();
      });
    }
    if (newTag && !productState.tags.includes(newTag)) {
      setProductState(prevProduct => ({
        ...prevProduct,
        tags: [...prevProduct.tags, newTag],
      }));
    }
  };

  const renderPriceForm = () => {
    // Move hooks outside of conditional rendering
    const pricePrecision = catalog ? catalog?.numberOfDecimalsShowed : constants.PRICE_PRECISION;
    const options = React.useMemo(
      () =>
        config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId)
          ? [PRODUCT_UNIT.PIECE, PRODUCT_UNIT.BOX, PRODUCT_UNIT.PALLET]
          : AllProductUnitsArray,
      [catalogId],
    );

    return (
      <>
        <S.SubRow>
          <S.SubColumn>
            <Radio
              name="is_por"
              direction="column"
              options={[
                {
                  label: __('Components.ProductDetails.por_off'),
                  value: __('Components.ProductDetails.por_off'),
                },
                {
                  label: __('Components.ProductDetails.por_on'),
                  value: __('Components.ProductDetails.por_on'),
                },
              ]}
              containerMargin="4px 0"
              alignItems="flex-start"
              styleLabel={{ margin: '5px 0' }}
              itemsChecked={
                productState.isPor
                  ? [__('Components.ProductDetails.por_on')]
                  : [__('Components.ProductDetails.por_off')]
              }
              onChange={(key, value, error) =>
                changeProductMultiple([
                  { key, value: !!value },
                  { key: 'isPor', value: !productState.isPor },
                  { key: 'price', value: value ? 0 : productState.price },
                  {
                    key: 'saleUnits',
                    value: productService.clearSaleUnits(productState, catalog.mandatoryPalletizationByClient),
                  },
                ])
              }
              disabled={!editMode}
            />
          </S.SubColumn>
        </S.SubRow>
        <S.SubRow withoutPaddingBottom={true}>
          {/* Always render the columns but conditionally show content */}
          <S.SubColumn style={{ display: productState.isPor ? 'none' : 'block' }}>
            {!productState.isPor &&
              renderNumberInput(
                'price',
                __('Components.ProductDetails.price.price'),
                changeProduct,
                typeof productState.price === 'number' ? productState.price : '',
                true,
                currency.getPriceInputMinimumValue(pricePrecision),
                undefined,
                pricePrecision,
                constants.CURRENCIES[productState.currency].symbol,
                constants.CURRENCIES[productState.currency].symbol,
              )}
          </S.SubColumn>

          {!productState.isPor && <S.EmptyColumn />}

          <S.SubColumn style={productState.isPor ? { maxWidth: '50%' } : undefined}>
            {renderSelectInput(
              'priceUnit',
              __('Components.ProductDetails.price.priceUnit'),
              true,
              options.map(u => ({
                label: constants.getPriceUnitLabels(
                  config.TOGGLE_NEW_SALES_UNITS.organizations.includes(catalogId) ? priceUnitTranslate(u) : u,
                  me.settings.language,
                  catalog.defaultWeightUnit,
                ),
                value: u,
              })),
              (key, value, error) =>
                changeProductMultiple([
                  { key, value, error },
                  { key: 'saleUnits', value: [value as PRODUCT_UNIT] },
                ]),
              productState.priceUnit,
            )}
          </S.SubColumn>
        </S.SubRow>
      </>
    );
  };

  const renderProductVisibleSwitch = () => (
    <S.SubRow>
      <S.SubColumn>
        <SelectWorkingStatus
          name="status"
          value={productState.status as webConstants.AVAILABILITY}
          onChange={(name, value) => changeProduct(name, value)}
          options={[
            { label: __('Components.Switch.available'), value: webConstants.AVAILABILITY.ACTIVE },
            { label: __('Components.Switch.unavailable'), value: webConstants.AVAILABILITY.UNAVAILABLE },
            { label: __('Components.Switch.hidden'), value: webConstants.AVAILABILITY.INACTIVE },
          ]}
        />
      </S.SubColumn>
    </S.SubRow>
  );

  const renderTextInput = React.useCallback(
    (name, label, changeProduct, value?: string | number, isRequired?, isRecommended?, maxLength?, placeholder?) => (
      <S.Row key={name}>
        <InputWithLabel label={label} isRequired={isRequired} isRecommended={isRecommended} width="100%">
          <Input
            name={name}
            containerMargin="4px 0"
            width="100%"
            type="text"
            value={value}
            hasError={!!errors.get(name)}
            onChange={changeProduct}
            isRequired={isRequired}
            disabled={!editMode}
            maxLength={maxLength}
            placeholder={placeholder}
          />
        </InputWithLabel>
      </S.Row>
    ),
    [errors, editMode, changeProduct],
  );

  const changeBoxPerPallet = (
    key: string,
    index: number,
    value: number | string,
    isDefault: boolean,
    error?: string,
  ) => {
    if (value === '') {
      value = '0';
    }
    const numValue = typeof value === 'string' ? parseFloat(value) : value;
    if (isNaN(numValue)) {
      return;
    }

    setProductState(prevProduct => {
      const newBoxesPerPalletValues = [...(prevProduct.boxesPerPalletValues || [])];
      newBoxesPerPalletValues[index] = {
        value: numValue,
        isDefault,
      };
      return {
        ...prevProduct,
        boxesPerPalletValues: newBoxesPerPalletValues,
      };
    });
    setHasChangesState(true);
  };

  const replaceOrAppend = (
    array: Array<IBoxesPerPalletValue>,
    index: number,
    value: IBoxesPerPalletValue,
  ): Array<IBoxesPerPalletValue> => {
    if (!array) {
      array = [];
    }
    if (index >= 0 && index < array.length) {
      array[index] = value;
    } else {
      array.push(value);
    }
    return array;
  };

  const toggleKebabBox = (index: number) => {
    setKebabBoxVisibility(prevVisibility => ({
      ...prevVisibility,
      [index]: !prevVisibility[index],
    }));
  };

  const addNewInputBoxPerPallet = () => {
    const newBoxPerPalletValue: IBoxesPerPalletValue = {
      value: 0,
      isDefault: false,
    };

    setProductState(prevProduct => {
      const updatedBoxesPerPalletValues = replaceOrAppend(
        [...prevProduct.boxesPerPalletValues],
        prevProduct.boxesPerPalletValues.length,
        newBoxPerPalletValue,
      );

      setHasChangesState(true);
      return {
        ...prevProduct,
        boxesPerPalletValues: updatedBoxesPerPalletValues,
      };
    });
  };

  const deleteBoxPerPalletValue = (index: number) => {
    setProductState(prevProduct => {
      const updatedBoxesPerPalletValues = prevProduct.boxesPerPalletValues.filter((_, i) => i !== index);

      setHasChangesState(true);
      return {
        ...prevProduct,
        boxesPerPalletValues: updatedBoxesPerPalletValues,
      };
    });
  };

  const makePredefinedBoxPerPalletValue = (index: number) => {
    setProductState(prevProduct => {
      const updatedBoxesPerPalletValues = prevProduct.boxesPerPalletValues.map((el, i) => {
        return {
          ...el,
          isDefault: i === index,
        };
      });

      setHasChangesState(true);
      return {
        ...prevProduct,
        boxesPerPalletValues: updatedBoxesPerPalletValues,
      };
    });
  };

  const renderBoxPerPalletSection = (
    name,
    label,
    changeProduct,
    value?,
    isRequired?,
    minValue?,
    maxValue?,
    precision?,
  ) => {
    return (
      <S.Col>
        <InputWithLabel label={label} isRequired={isRequired} width="100%">
          {productState.boxesPerPalletValues && productState.boxesPerPalletValues.length > 0
            ? productState.boxesPerPalletValues.map((el, i) =>
                renderBoxPerPalletInput(
                  name,
                  changeProduct,
                  editMode,
                  el?.value ? el.value.toString() : '0',
                  isRequired,
                  minValue,
                  maxValue,
                  precision,
                  '',
                  '',
                  i,
                  kebabBoxVisibility[i],
                  toggleKebabBox,
                  el.isDefault,
                ),
              )
            : renderBoxPerPalletInput(
                name,
                changeProduct,
                editMode,
                '0',
                isRequired,
                minValue,
                maxValue,
                precision,
                '',
                '',
                0,
                kebabBoxVisibility[0],
                toggleKebabBox,
                true,
              )}
        </InputWithLabel>

        <S.BlueText onClick={addNewInputBoxPerPallet}>
          {__('Components.ProductDetails.box_per_pallet.add_option')}
        </S.BlueText>
      </S.Col>
    );
  };

  const renderBoxPerPalletInput = (
    name,
    changeProduct,
    editMode,
    value,
    isRequired,
    minValue,
    maxValue,
    precision,
    variableTextSingular,
    variableTextPlural,
    index,
    showKebabBox,
    toggleKebabBox,
    isDefault,
  ) => {
    return (
      <Input
        key={index}
        name={name}
        containerMargin="4px 0"
        width="100%"
        type="number"
        value={value}
        hasError={!!errors.get(name)}
        onChange={(key, newValue) => {
          changeProduct(name, index, newValue, isDefault);
        }}
        isRequired={isRequired}
        disabled={!editMode}
        minValue={minValue}
        maxValue={maxValue}
        precision={precision}
        variableTextSingular={variableTextSingular}
        variableTextPlural={variableTextPlural}
      >
        <S.Row>
          {isDefault ? (
            <S.Label>{__('Components.ProductDetails.box_per_pallet.predefined')}</S.Label>
          ) : (
            <>
              <S.Kebab name="Kebab" onClick={() => toggleKebabBox(index)} />
              {showKebabBox && (
                <S.KebabBox>
                  <S.KebabRow
                    onClick={() => {
                      makePredefinedBoxPerPalletValue(index);
                      toggleKebabBox(index);
                    }}
                  >
                    <S.InfoText>{__('Components.ProductDetails.box_per_pallet.make_predefined')}</S.InfoText>

                    <Tooltip
                      disabled={false}
                      position="right"
                      text={__('Components.ProductDetails.box_per_pallet.tooltip')}
                      width="300px"
                      themeMode="dark"
                    >
                      <S.InfoKebab name="Info" />
                    </Tooltip>
                  </S.KebabRow>
                  <S.KebabRow
                    onClick={() => {
                      deleteBoxPerPalletValue(index);
                      toggleKebabBox(index);
                    }}
                  >
                    <S.DeleteText>{__('Components.ProductDetails.box_per_pallet.delete_option')}</S.DeleteText>
                  </S.KebabRow>
                </S.KebabBox>
              )}
            </>
          )}
        </S.Row>
      </Input>
    );
  };

  // Then in your main component, modify renderNumberInput to use the new component:
  const renderNumberInput = (
    name: string,
    label: string,
    changeProduct: (key: string, value: any, error?: string) => void,
    value?: string | number,
    isRequired?: boolean,
    minValue?: number,
    maxValue?: number,
    precision?: number,
    variableTextSingular?: string,
    variableTextPlural?: string,
  ) => {
    const handleChange = (key: string, value: string | number, error?: string) => {
      if (typeof changeProduct === 'function') {
        const parseValue = value === '' ? null : value;
        changeProduct(key, parseValue, error);
      }
    };

    return (
      <NumberInput
        key={name}
        name={name}
        label={label}
        changeProduct={handleChange}
        value={value}
        isRequired={isRequired}
        minValue={minValue}
        maxValue={maxValue}
        precision={precision}
        variableTextSingular={variableTextSingular}
        variableTextPlural={variableTextPlural}
        editMode={editMode}
        errors={errors}
      />
    );
  };

  const renderSelectInput = (
    name,
    label,
    isRequired,
    options,
    onChange: (name: string, value: string, error?: string) => void,
    value?,
    style?,
  ) => (
    <S.Row style={style}>
      <InputWithLabel label={label} isRequired={isRequired} width="100%">
        <Select
          containerMargin="4px 0"
          hasError={!!errors.get(name)}
          name={name}
          onChange={onChange}
          options={options}
          disabled={!editMode}
          value={value || ''}
          width="100%"
        />
      </InputWithLabel>
    </S.Row>
  );

  const showSaveSuccessModal = React.useCallback(
    mode => {
      notificationShow(
        {
          title:
            mode === 'updated'
              ? __('Components.ProductDetails.success_update')
              : __('Components.ProductDetails.success_create'),
          subtitle: '',
          closable: true,
          style: 'success',
        },
        5000,
      );
    },
    [notificationShow],
  );

  const onRemove = React.useCallback(
    product => {
      modalOpen(
        __('Components.ProductDetails.confirm_remove'),
        () =>
          productRemove(catalogId, product.id!, () => {
            modalClose();
          }),
        {
          buttonText: __('Components.Modal.delete'),
        },
      );
    },
    [catalogId, modalOpen, modalClose, productRemove],
  );

  const onPictureChange = React.useCallback(
    (file, index) => {
      if (index > MAX_UPLOAD_PICTURES - 1) return;
      const product = { ...productState };

      convertToIFile(
        file,
        (file: IFile, fileString: string) => {
          if (fileString) {
            resizeImage(fileString, (imageResized: string) =>
              mediaUpload({ ...file, content: imageResized }, result =>
                changeProductImage(product, result ? result.secure_url : undefined, index),
              ),
            );
          }
        },
        (code: string) => {
          logError(new Error('File could not be read! Code ' + code), 'upload.image');
        },
      );
    },
    [mediaUpload],
  );

  const deleteProductPicture = React.useCallback(index => {
    setProductState(prevProduct => {
      const imagesUpdate = Array.isArray(prevProduct.images) ? [...prevProduct.images] : [];
      if (index < imagesUpdate.length) {
        imagesUpdate.splice(index, 1);
        setHasChangesState(true);
        return { ...prevProduct, images: imagesUpdate };
      }
      return prevProduct;
    });
  }, []);

  const onPicturedDelete = React.useCallback(
    index => {
      modalOpen(
        __('Components.ProductDetails.confirm_remove_picture'),
        () => {
          deleteProductPicture(index);
          modalClose();
        },
        {
          buttonText: __('Components.Modal.delete'),
        },
      );
    },
    [modalOpen, modalClose, deleteProductPicture],
  );

  const showPricegroupsModal = () => {
    if (!sellerWorkspaceService.isProPlan(catalog?.plan)) {
      modalOpen('pricelist', () => requestProInfo(me.id, catalog.id, 'pricelist'), {}, 'consentiopro');
    } else {
      if (priceGroups && Object.keys(priceGroups).length) {
        if (productState.id) {
          pricesGetFromProduct(catalog.id, productState.id, priceGroupItems => {
            setPriceGroupItems(priceGroupItems);
            setShowAddToPriceGroup(
              (priceGroups && !Object.keys(priceGroups).length) || !productState.priceGroups?.length,
            );
            setShowPriceGroups(true);
          });
        } else {
          setShowAddToPriceGroup(
            (priceGroups && !Object.keys(priceGroups).length) || !productState.priceGroups?.length,
          );
          setShowPriceGroups(true);
        }
      } else {
        history.push(
          getPath({
            path: webConstants.ROUTE_PATHS.WORKSPACE_PRICE_GROUP_EDIT,
            workspaceId: catalog.id + '',
            priceGroupId: 'new',
          }),
        );
      }
    }
  };

  const pricesAddProductMetod = selecteds => {
    if (productState.id) {
      pricesAddProduct(productState.catalogId, productState.id, selecteds, pgis => {
        setPriceGroupItems(prevItems => [
          ...prevItems,
          ...pgis.filter(p => !prevItems.find(pg => pg.priceGroupId === p.priceGroupId)),
        ]);
        setProductState(prevProduct => ({
          ...prevProduct,
          priceGroups: [
            ...(prevProduct.priceGroups || []),
            ...selecteds.filter(p => !prevProduct.priceGroups?.includes(p)),
          ],
        }));
      });
    } else {
      setPriceGroupItems(prevItems => [
        ...prevItems,
        ...Object.keys(priceGroups)
          .filter(p => selecteds.includes(p) && !prevItems.find(pg => pg.priceGroupId === p))
          .map(p => ({
            ...productState,
            percentage: 0,
            priceGroupId: p,
            productId: 0,
            productImageUrl: '',
            productType: '',
            type: PRICE_ADJUSTMENT_TYPE.BASE,
            useBase: true,
            rank: 0,
          })),
      ]);
      setProductState(prevProduct => ({
        ...prevProduct,
        priceGroups: [
          ...(prevProduct.priceGroups || []),
          ...selecteds.filter(p => !prevProduct.priceGroups?.includes(p)),
        ],
      }));
    }
  };

  return (
    <>
      <Prompt when={hasChangesState} message={__('Components.ProductDetails.confirm_exit_changes')} />
      <S.Container>
        {isCloning && !productState.id && <Ribbon type="info" text={__('Components.ProductDetails.clone')} />}
        <S.ContentContainer className="product-edit-content-container">
          <S.MenuLeft sections={getSections()} selected={viewing} />
          <S.FormContainer
            canSave={hasChangesState}
            className="product-edit-form-container"
            cta={productState.id ? __('Components.ProductDetails.save') : __('Components.ProductDetails.create')}
            save={save}
            sectionsRef={sectionsContainerRef}
          >
            {renderCharacteristics()}
            {renderImages()}
            {renderPrice()}
            {!catalog.promotedBy && renderDistribution()}
            {config.TOGGLE_SUSTAINABILITY.enabled && !catalog.promotedBy && renderSustainability()}
            {productState.id && (
              <AdditionalFiles
                me={me}
                product={productState}
                additionalFilesRef={additionalFilesRef}
                updateProduct={p => setState(prevState => ({ ...prevState, product: p }))}
              />
            )}
            {productState.id && renderDeleteProduct()}
          </S.FormContainer>

          <S.ColumnRight>
            {renderVisibility()}
            {renderLot()}
            {renderPriceGroups(config.TOGGLE_MARGINS.enabled && catalog?.plan?.addons?.quoterMarginsEnabled)}
            {renderSections()}
          </S.ColumnRight>
          {renderPriceGroupsModal(config.TOGGLE_MARGINS.enabled && catalog?.plan?.addons?.quoterMarginsEnabled)}
        </S.ContentContainer>
      </S.Container>
      {showPlasticModal && (
        <PlasticModal
          product={productState}
          close={() => setShowPlasticModal(false)}
          save={p => {
            setProductState(p);
            setHasChangesState(true);
          }}
          language={me.settings.language}
          defaultWeightUnit={catalog.defaultWeightUnit}
          prodTypes={prodTypes}
        />
      )}
      {showTraceability && (
        <Traceability
          product={parsers.productToGenericProduct(productState)}
          backLiteral={__('ProductEdit.Traceability.back')}
          showBack={true}
          close={() => setShowTraceability(false)}
          company={me.companyName}
          myId={me.id}
        />
      )}
    </>
  );
};

export default ProductEdit1;

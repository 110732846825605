import styled, { css } from '../../../../../styled-components';
import { ColumnContainer, RowContainer } from '../../../../atoms';

import type { ITheme } from '../../../../../styled-components';

// Define styled props interface
interface IStyledProps {
  enabled?: boolean;
  disabled?: boolean;
  margin?: string;
  numHeaders?: number;
  theme: ITheme;
  isVisible?: boolean;
}

// Main container
export const Container = styled(ColumnContainer)`
  width: 100%;
  max-height: 100%;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

export const Row = styled(RowContainer)`
  width: 100%;
  flex: 1;
  max-height: 100%;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

// Scrollable container
export const ScrollContainer = styled.div`
  justify-content: space-between;
  width: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  position: relative;
  flex: 1;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

// Content layout
export const Content = styled.div`
  width: 100%;
  min-height: 100%;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  @media (max-width: ${({ theme }) => theme.sizes.desktop_m}px) {
    flex-direction: column;
    padding: 16px;
    gap: 16px;
  }
`;

// Main section wrapper
export const MainSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

// Empty state styling
export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  box-shadow: ${({ theme }) => theme.boxShadow};
`;

// Right sidebar with animation
export const RightCard = styled(ColumnContainer)<IStyledProps>`
  max-width: 360px;
  min-width: 360px;
  height: 100%;
  position: relative;
  max-height: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-left: 1px solid ${({ theme }) => theme.colors.grey3};
  transform: translateX(${({ isVisible }) => (isVisible ? '0' : '100%')});
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;

  /* Add subtle shadow for depth */
  box-shadow: ${({ isVisible, _ }) => (isVisible ? '-4px 0 6px rgba(0, 0, 0, 0.05)' : 'none')};

  /* Smooth transition for shadow */
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  ${({ theme }) => css`
    @media screen and (max-width: calc(${theme.sizes.desktop_m}px + 275px)) {
      position: fixed;
      height: 100vh;
      border-right: 1px solid ${theme.colors.grey3};
    }
  `}
`;

import { __, currency, CURRENCY_CODES, WEIGHT_UNIT } from 'common-services';
import * as React from 'react';

import { unitTranslator } from '../../../../util/unit';
import { RowContainer } from '../../../atoms';
import { ContactCell } from '../../../molecules';
import AddressCell from '../../../molecules/AddressCell';
import Table from '../../../molecules/Table/Table.component';

import * as S from './OrderPreparationTable.styled';

import type { IColumn } from '../../../molecules/Table/Table.component';
import type { IGroupedLinesBySeller, IOrderBuyerPreparationLine, PRODUCT_UNIT } from 'common-services';

export interface IProps {
  linesBySeller?: Array<IGroupedLinesBySeller>;
  footerChildren?: React.ReactElement;
  warehouses: Array<{ buyerWarehouseId: number; buyerWarehouseName: string }>;
  updateBuyerPreparationLine: (
    line: IOrderBuyerPreparationLine,
    commentOrQuantity: 'quantity' | 'comment',
    value: number,
  ) => void;
  orderBuyerPreparationId: number;
  onDelete: (lines: Array<number>) => void;
}
const GroupedLines: React.FC<IProps> = ({
  linesBySeller,
  footerChildren,
  warehouses,
  updateBuyerPreparationLine,
  onDelete,
}) => {
  const warehouseColumns = warehouses.map(warehouse => {
    return {
      value: warehouse.buyerWarehouseName,
      label: warehouse.buyerWarehouseName,
    };
  });

  const columns = [
    { value: 'supplier', label: 'supplier' },
    { value: 'quantity', label: 'quantity' },
    { value: 'price', label: 'price' },
    { value: 'address1', label: 'address1' },
  ];
  columns.push(...warehouseColumns);

  function getColumns(): Array<IColumn> {
    return [
      {
        title: '',
        id: 'delete',
        width: '30px',
        element: (data: IGroupedLinesBySeller) => {
          const lines = data.lines.map(line => line.id);
          return (
            <S.IconWrapper>
              <S.RemoveIcon name="Close" onClick={() => onDelete(lines)} />
            </S.IconWrapper>
          );
        },
      },
      {
        title: __('Components.OffersList.order_preparation.table.supplier'),
        id: 'supplier',
        width: '120px',
        minWidth: '180px',
        element: (data: IGroupedLinesBySeller) => {
          return <ContactCell avatar="" primaryText={data?.sellerName} />;
        },
      },
      {
        title: __('Components.OffersList.order_preparation.table.quantity'),
        id: 'quantity',
        width: '90px',
        minWidth: '90px',
        element: (data: IGroupedLinesBySeller) => {
          const sumAllOfferedQuantities = (lines): number => {
            const sum = lines.reduce((acc, el) => {
              return acc + (el.hasDirectWarehouseAssignment ? el.offeredProduct.offeredQuantity : 0);
            }, 0);
            return sum;
          };
          const getMaxTotalQuantity = (lines): number => {
            const max = lines.reduce((acc, el) => {
              return el.offeredProduct.totalQuantity > acc ? el.offeredProduct.totalQuantity : acc;
            }, 0);
            return max;
          };

          const maxTotalQuantities = getMaxTotalQuantity(data.lines);
          const sumOfferedQuantities = sumAllOfferedQuantities(data.lines);

          const quantity = maxTotalQuantities === 0 ? sumOfferedQuantities : maxTotalQuantities;

          return (
            <S.NameText>
              {`${quantity} ${unitTranslator(data.lines[0]?.offeredProduct.totalQuantityUnit, quantity)}`}
            </S.NameText>
          );
        },
      },
      {
        title: __('Components.OffersList.order_preparation.table.price'),
        id: 'price',
        width: '90px',
        element: (data: IGroupedLinesBySeller) => {
          const getDeparturePrice = (lines): number => {
            const max = lines.reduce((acc, el) => {
              return el.offeredProduct.offeredDeparturePrice > acc ? el.offeredProduct.offeredDeparturePrice : acc;
            }, 0);
            return max;
          };
          const priceUnit = data.lines[0]?.offeredProduct.priceUnit; // all lines have the same price unit
          const price = getDeparturePrice(data.lines);
          const pricePerUnit = currency.getPricePerUnit(
            CURRENCY_CODES.EUR,
            priceUnit as PRODUCT_UNIT,
            WEIGHT_UNIT.KG,
            price,
          );

          return <S.NameText>{price > 0 ? pricePerUnit : '-'}</S.NameText>;
        },
      },

      ...warehouses.map(warehouse => {
        return {
          title: warehouse.buyerWarehouseName || __('Components.OffersList.order_preparation.table.no_address'),
          id: warehouse.buyerWarehouseName,
          width: '250px',
          minWidth: '250px',
          maxWidth: '250px',
          withLeftBorder: true,
          noRightPadding: true,
          renderCell: () => {
            return (
              <RowContainer>
                <S.AddressIcon name="Address" />
                <S.TextHeader>
                  {warehouse.buyerWarehouseName || __('Components.OffersList.order_preparation.table.no_address')}
                </S.TextHeader>
              </RowContainer>
            );
          },
          element: (data: IGroupedLinesBySeller) => {
            const orderBuyPreparationLine: IOrderBuyerPreparationLine = data.lines.find(
              line => line.buyerWarehouseId === warehouse.buyerWarehouseId,
            );

            const quantity =
              orderBuyPreparationLine?.offeredProduct?.offeredQuantity ||
              orderBuyPreparationLine?.offeredProduct?.totalQuantity;

            const orderedQuantity = orderBuyPreparationLine?.orderedQuantity
              ? orderBuyPreparationLine?.orderedQuantity
              : 0;

            const totalOrderedQuantity = data.lines.reduce((acc, line) => {
              return acc + line.orderedQuantity;
            }, 0);

            const totalAvailability = orderBuyPreparationLine?.offeredProduct.offeredQuantity === 0;

            const remainingQuantity = totalAvailability
              ? quantity - totalOrderedQuantity || 0
              : quantity - orderedQuantity || 0;

            // If there is offeredQuantity, quantityUnit is used, if not totalQuantityUnit
            const unit = orderBuyPreparationLine?.offeredProduct.offeredQuantity
              ? orderBuyPreparationLine?.offeredProduct.quantityUnit
              : orderBuyPreparationLine?.offeredProduct.totalQuantityUnit;

            return orderBuyPreparationLine ? (
              <AddressCell
                changeQuantity={value => updateBuyerPreparationLine(orderBuyPreparationLine, 'quantity', value)}
                saveComment={value => updateBuyerPreparationLine(orderBuyPreparationLine, 'comment', value)}
                orderedQuantity={orderedQuantity}
                comment={orderBuyPreparationLine?.comment}
                remainingQuantity={orderBuyPreparationLine?.hasDirectWarehouseAssignment ? remainingQuantity : 0}
                price={
                  orderBuyPreparationLine?.hasDirectWarehouseAssignment
                    ? orderBuyPreparationLine?.offeredProduct?.offeredPrice
                    : '-'
                }
                priceUnit={orderBuyPreparationLine?.offeredProduct?.priceUnit}
                quantityUnit={unit}
                hasComment={orderBuyPreparationLine?.comment !== ''}
              />
            ) : null;
          },
        };
      }),
    ];
  }
  return (
    <S.Container style={{ flex: 1 }}>
      <Table
        selectable={false}
        columns={getColumns()}
        emptyText=""
        values={linesBySeller}
        onClickRow={() => null}
        productColumns={columns}
        showStickyHeader={true}
        footerChildren={footerChildren}
        rowCursor="default"
      />
    </S.Container>
  );
};

export default GroupedLines;

import * as React from 'react';

import * as S from './TagBubble.styled';

export type BubbleColor =
  | 'pending'
  | 'accepted'
  | 'canceled'
  | 'confirmed'
  | 'incomplete'
  | 'error'
  | 'sent'
  | 'draft'
  | 'progress';

export interface IProps {
  label: string;
  className?: string;
  onDelete?: (label: string) => void;
  disabled?: boolean;
  color?: BubbleColor;
  showIcon?: boolean;
}

const TagBubble: React.FC<IProps> = ({ className, label, onDelete, disabled, color, showIcon }) => {
  return (
    <S.Container color={color} className={className} disabled={disabled}>
      {showIcon ? <S.Bubble color={color} /> : null}
      <S.Label color={color} className="tag-bubble-label">
        {label}
      </S.Label>
      {disabled ? null : (
        <S.DeleteContainer onClick={() => onDelete(label)}>
          <S.DeleteIcon name="Close" disableHover={true} />
        </S.DeleteContainer>
      )}
    </S.Container>
  );
};

export default React.memo(TagBubble);

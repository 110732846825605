import styled from '../../../../../../styled-components';
import { FontIcon } from '../../../../../atoms';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: ${props => props.theme.headerHeight};
  padding: ${props => props.theme.paddingSize(2)} ${props => props.theme.paddingSize(3)};
  background-color: ${props => props.theme.colors.blue2};
  border-bottom: 1px solid ${props => props.theme.colors.blue1};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${props => props.theme.paddingSize(2)};
`;

export const UpdateIcon = styled(FontIcon)`
  color: ${props => props.theme.colors.black};
  font-size: 22px;
  height: 22px;
  width: 22px;
  padding-bottom: 2px;
`;

export const MessageText = styled.span`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fontSize.normal};
  font-weight: ${props => props.theme.fontWeight.Regular};
  margin-right: ${props => props.theme.paddingSize(1)};
`;

export const NavigationLink = styled.button`
  color: ${props => props.theme.colors.blue1};
  font-size: ${props => props.theme.fontSize.normal};
  font-weight: ${props => props.theme.fontWeight.Regular};
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
    text-decoration: underline;
  }

  &:focus-visible {
    outline: 2px solid ${props => props.theme.colors.blue1};
    outline-offset: 2px;
  }
`;

import { __, orderUtils } from 'common-services';
import * as React from 'react';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'units-per-box',
  isVisible: true,
  getTitle: () => __('Components.ProductsList.Table.UnitsPerBox'),
  minWidth: '70px',
  width: '9%',
  columnValue: ({ order }) => ({
    getElement: item => {
      const issuesRelated = order?.issues.filter(issue => issue.orderItemId === item.id);
      const hasNotFoundIssue = !!issuesRelated?.find(i =>
        ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
      );
      const isDiscarded = item.servedQuantity === 0;
      return (
        <S.CellContainer showGrey={isDiscarded || hasNotFoundIssue || orderUtils.isCanceled(order)}>
          {item.piecesPerBox}
        </S.CellContainer>
      );
    },
  }),
};

import * as React from 'react';

import type { IOrderBuyerPreparationGroup } from 'common-services';

export const OrderPreparationContext = React.createContext<{
  orderBuyerPreparationId: number | null;
  groupedOrderPreparationLines: Array<IOrderBuyerPreparationGroup>;
}>({
  orderBuyerPreparationId: null,
  groupedOrderPreparationLines: [],
});

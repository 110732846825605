import * as React from 'react';

import getEnvironments from '../../../../../../../../../getEnvironments';
import DebugModal from '../../../../../../../atoms/Debug/DebugModal.component';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'debug',
  rank: -2,
  isVisible: true,
  getTitle: () => 'Debug',
  minWidth: '70px',
  width: '9%',
  columnValue: ({ order }) => ({
    getElement: item => {
      const issuesRelated = order?.issues.filter(issue => issue.orderItemId === item.id);
      return (
        <S.CellContainer>
          <DebugModal
            triggerText="Debug item & related issues"
            title={`Item ${item.id} data`}
            data={{ item, issuesRelated, env: getEnvironments() }}
            withSections
          />
        </S.CellContainer>
      );
    },
  }),
};

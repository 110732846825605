import { withActionLogger } from './logger';
import { default as OrderDetails } from './OrderDetails.component';
export * from './OrderDetails.props';
export { default as OrderDetails } from './OrderDetails.component';

const OrderDetailsWithLogging = withActionLogger(OrderDetails, {
  //   excludeActions: ['closeCart'],
});

export default OrderDetailsWithLogging;

import {
  __,
  currency,
  date,
  parsers,
  prodTypeSelectors,
  PRODUCT_UNIT,
  productService,
  userSelectors,
  utils,
} from 'common-services';
import { History } from 'history';
import * as React from 'react';
import { useSelector } from 'react-redux';

import config from '../../../../bindings/config';
import { IMAGES } from '../../../assets';
import { AVAILABILITY, getCountryFlag, IMAGE_DEFAULT, ROUTE_PATHS } from '../../../constants';
import theme from '../../../theme';
import getPath from '../../../util/routes';
import { priceUnitTranslate } from '../../../util/utils';
import { FontIcon, RowContainer, Tooltip } from '../../atoms';
import Sustainability from '../../organisms/ProductEdit/Sustainability';
import * as S from './ProductCard.styled';

export interface IProps {
  amount?: number;
  amSeller?: boolean;
  onSelectItem: (item: IOrderItem) => void;
  className?: string;
  color?: string;
  data: GenericProduct;
  featuredToggle?: (type: IFeaturedType, productHashId: string, catalogId: number) => void;
  fromMessage?: boolean;
  hideFeatured: boolean;
  history?: History<any>;
  ipadMargin?: string;
  isFavorite?: boolean;
  isRecent?: boolean;
  isRecommended?: boolean;
  isSelected?: boolean;
  isServedFlow?: boolean;
  navigate: (item: IOrderItem, isToggled?: boolean) => void;
  openShareProduct?: (product: GenericProduct, productName: string) => void;
  orderItemId: number;
  originLot?: number;
  price?: number;
  priceMode?: IPriceMode;
  pricePrecision?: number;
  productMappingStrategy?: 'ean_code' | 'sku';
  saleUnit?: PRODUCT_UNIT;
  selectMode?: 'integration' | 'multiple' | 'offers';
  servedQuantity?: number;
  showCTA?: boolean; // Default: true
  showProductReference?: boolean; // SKU or EAN
  showShare: boolean;
}

export const ProductCard: React.FC<IProps> = ({
  amount,
  amSeller,
  onSelectItem,
  className,
  color,
  data,
  featuredToggle,
  fromMessage,
  hideFeatured,
  history,
  ipadMargin,
  isFavorite,
  isRecent,
  isRecommended,
  isSelected,
  isServedFlow,
  navigate,
  openShareProduct,
  orderItemId,
  originLot,
  price,
  priceMode,
  pricePrecision,
  productMappingStrategy = 'ean_code',
  saleUnit,
  selectMode,
  servedQuantity,
  showCTA = true,
  showProductReference = false,
  showShare,
}) => {
  const prodTypes = useSelector(prodTypeSelectors.getProdTypes);

  const typeVariety = productService.getProductTypeVarietyDisplay(
    data.type,
    prodTypes[data.type] ? prodTypes[data.type].name : '',
    data.variety,
  );
  const pToShow = priceToShow(pricePrecision);

  const sustainability = Sustainability[data.type];
  const co2Max = sustainability?.co2Max;
  const co2Min = sustainability?.co2Min;
  const waterMax = sustainability?.waterMax * 1000;
  const waterMin = sustainability?.waterMin * 1000;
  const co2Average = +((co2Max + co2Min) / 2).toFixed(2);
  const waterAverage = Math.round((waterMax + waterMin) / 2);

  const { plasticAmount } = data;
  let productReference;
  if (showProductReference) {
    productReference = productMappingStrategy === 'ean_code' ? data.eanCode : data.sku;
  }

  let packagingContent = productReference
    ? `${productMappingStrategy === 'sku' ? __('Components.ProductCard.sku') : 'EAN (GTIN)'} - ${productReference}`
    : data.packaging || '';

  const originContent = productReference || !data.origin ? '' : `${getCountryFlag(data.origin)} ${data.origin}`;

  if (originContent) {
    packagingContent = packagingContent.length > 20 ? packagingContent.slice(0, 20) + '...' : packagingContent;
  }

  return (
    <S.Container
      className={className}
      fromMessage={fromMessage}
      ipadMargin={ipadMargin}
      onClick={navigateToProductInfo}
      selected={!!servedQuantity}
      showCTA={showCTA}
      data-cy="productcard"
    >
      <S.Header>
        <S.ProductImage
          src={(
            (data.images && data.images.length && data.images[0].image.url) ||
            (prodTypes[data.type] && prodTypes[data.type].defaultImageUrl) ||
            IMAGE_DEFAULT
          ).replace('/f_auto/', '/f_auto,w_400,c_limit/')}
        />
        {renderMarkFeatured()}
        {data.organic === 'organic' ? <S.Organic>{parsers.getOrganicText(data.organic)}</S.Organic> : null}
        {config.TOGGLE_SUSTAINABILITY_HOVER.enabled && (co2Average || waterAverage || plasticAmount) ? (
          <>
            <S.SustainabilityDataClosed className="short-display">
              {co2Average ? (
                <>
                  <S.BlackText>{'🌤'}</S.BlackText>
                  <S.TextBold>{co2Average}</S.TextBold>
                  <S.BlackText>{' kg'}</S.BlackText>
                </>
              ) : null}{' '}
              {waterAverage ? (
                <>
                  <S.BlackText>{'💧'}</S.BlackText>
                  <S.TextBold>{waterAverage}</S.TextBold>
                  <S.BlackText>{' L'}</S.BlackText>
                </>
              ) : null}{' '}
              {plasticAmount ? (
                <>
                  <S.BlackText>{'🥤'}</S.BlackText>
                  <S.TextBold>{plasticAmount}</S.TextBold>
                  <S.BlackText>{' g'}</S.BlackText>
                </>
              ) : null}{' '}
            </S.SustainabilityDataClosed>

            {co2Average || waterAverage || plasticAmount ? (
              <S.SustainabilityDataOpen className="full-display">
                <S.Row>
                  <S.GreyText>
                    {
                      utils.formatText(__('Components.ProductCard.sustainability.title'), s => (
                        <S.GreyBoldText key={s}>{s}</S.GreyBoldText>
                      )) as any // TYPEERROR
                    }
                  </S.GreyText>
                  <S.LeafIcon name="Producer-solid" />
                </S.Row>
                {co2Average ? (
                  <S.Row>
                    <S.BlackText>{'🌤 '} </S.BlackText>
                    <S.BlackText>
                      {
                        utils.formatText(__('Components.ProductCard.sustainability.co2', { co2: co2Average }), s => (
                          <S.TextBold key={s}>{s}</S.TextBold>
                        )) as any // TYPEERROR
                      }
                    </S.BlackText>
                  </S.Row>
                ) : null}{' '}
                {waterAverage ? (
                  <S.Row>
                    <S.BlackText>{'💧 '} </S.BlackText>
                    <S.BlackText>
                      {
                        utils.formatText(
                          __('Components.ProductCard.sustainability.water', { water: waterAverage }),
                          s => <S.TextBold key={s}>{s}</S.TextBold>,
                        ) as any // TYPEERROR
                      }
                    </S.BlackText>
                  </S.Row>
                ) : null}{' '}
                {plasticAmount ? (
                  <S.Row>
                    <S.BlackText>{'🥤 '} </S.BlackText>

                    <S.BlackText>
                      {
                        utils.formatText(
                          __('Components.ProductCard.sustainability.plastic', { plastic: plasticAmount }),
                          s => <S.TextBold key={s}>{s}</S.TextBold>,
                        ) as any // TYPEERROR
                      }
                    </S.BlackText>
                  </S.Row>
                ) : null}{' '}
              </S.SustainabilityDataOpen>
            ) : null}
          </>
        ) : null}
      </S.Header>
      <S.Body>
        {config.TOGGLE_PRODUCT_TOOLTIP.enabled ? (
          <Tooltip
            text={utils.firstToUpperCase(data.productTitle || `${typeVariety} ${data.size}`)}
            themeMode="dark"
            position="top"
            keepPropagation={true}
          >
            <S.Type>{utils.firstToUpperCase(data.productTitle || `${typeVariety} ${data.size}`)}</S.Type>
          </Tooltip>
        ) : (
          <S.Type>{utils.firstToUpperCase(data.productTitle || `${typeVariety} ${data.size}`)}</S.Type>
        )}

        <S.Packaging>
          {packagingContent}
          {(packagingContent && originContent ? ' · ' : '') + originContent}
        </S.Packaging>
        {fromMessage ? null : data.status === AVAILABILITY.UNAVAILABLE ? (
          <S.StockColumn>
            <S.TextRed>{__('Components.ProductCard.out_of_stock')}</S.TextRed>
            {data.status === AVAILABILITY.UNAVAILABLE && data.availableSoon?.availableFrom ? (
              <S.TextGray>
                {__('Components.ProductCard.estimated_date', {
                  date: date.formatLongDate(data.availableSoon.availableFrom, undefined, undefined, 'EEE, dd MMM yyyy'),
                })}
              </S.TextGray>
            ) : null}
          </S.StockColumn>
        ) : (
          <S.PriceRow>
            {pToShow && '-' !== pToShow && 'P.O.R' !== pToShow ? (
              <React.Fragment>
                <S.Price>{pToShow}</S.Price>
                <S.PriceUnit>
                  {'/' +
                    parsers.getUnitText(
                      config.TOGGLE_NEW_SALES_UNITS.organizations.includes(data.catalogId)
                        ? priceUnitTranslate(data.priceUnit)
                        : data.priceUnit,
                      data.weightUnit,
                    )}
                </S.PriceUnit>
              </React.Fragment>
            ) : null}
            {'P.O.R' === pToShow ? (
              <S.POR>{utils.firstToUpperCase(__('Components.ProductDetails.por_on').toLowerCase())}</S.POR>
            ) : null}
            {showShare && amSeller && data.productHashId ? (
              <S.ShareTouchable
                onClick={e => {
                  e.stopPropagation();
                  openShareProduct(data, `${typeVariety} ${data.size}`);
                }}
              >
                <S.ShareIcon name="Share" />
              </S.ShareTouchable>
            ) : null}{' '}
          </S.PriceRow>
        )}
        {servedQuantity ? (
          <ProductAmountAdd
            amount={amount}
            cartUpdateItem={onSelectItem}
            data={data}
            isServedFlow={isServedFlow}
            orderItemId={orderItemId}
            price={price}
            saleUnit={saleUnit}
            servedQuantity={servedQuantity}
          />
        ) : showCTA ? (
          <S.AddRow onClick={e => e.stopPropagation()}>{renderAddButton()}</S.AddRow>
        ) : null}
      </S.Body>
      {renderFeaturedFlags()}
    </S.Container>
  );

  /**
   * Microinteraction for mark/umark featured (if showMarkFeatured is true)
   * - if seller -> recommended
   * - if buyer -> favorites
   */
  function renderMarkFeatured() {
    if (hideFeatured || !featuredToggle) {
      return null;
    }

    return (
      <React.Fragment>
        {!amSeller ? (
          <S.Featured
            isFeatured={isFavorite}
            featuredColor={theme.colors.secondary}
            onClick={e => {
              if (!amSeller) {
                e.stopPropagation();
                featuredToggle('favorite', data.productHashId, data.catalogId);
              }
            }}
          >
            <S.FeaturedIcon>
              <FontIcon name="Favorits" disableHover={true} />
            </S.FeaturedIcon>
            <S.FeaturedText>
              {!isFavorite ? __('Components.ProductCard.favorite_mark') : __('Components.ProductCard.favorite_unmark')}
            </S.FeaturedText>
          </S.Featured>
        ) : null}
        {amSeller ? (
          <S.Featured
            isFeatured={isRecommended}
            featuredColor={theme.colors.green1}
            onClick={e => {
              e.stopPropagation();
              featuredToggle('recommended', data.productHashId, data.catalogId);
            }}
          >
            <S.FeaturedIcon>
              <FontIcon name={'Recommended'} disableHover={true} />
            </S.FeaturedIcon>
            <S.FeaturedText>
              {!isRecommended
                ? __('Components.ProductCard.recommended_mark')
                : __('Components.ProductCard.recommended_unmark')}
            </S.FeaturedText>
          </S.Featured>
        ) : null}
      </React.Fragment>
    );
  }

  /**
   * Render featured flags: favorite, recommended and/or recent
   * They are not actuable, are only intended to inform user.
   *
   * Seller can only view here fav and recent
   * Buyer can only vbiew here recommended and recent
   */
  function renderFeaturedFlags() {
    if (!isFavorite && !isRecent && !isRecommended) {
      return null;
    }
    return (
      <S.FeaturedFlagList>
        {isRecent && (
          <S.FeaturedItem src={IMAGES.productCardFlagRecent} title={__('Components.ProductCard.recent_tooltip')} />
        )}
        {amSeller && isFavorite && (
          <S.FeaturedItem src={IMAGES.productCardFlagFavorite} title={__('Components.ProductCard.favorite_tooltip')} />
        )}
        {!amSeller && isRecommended && (
          <S.FeaturedItem
            src={IMAGES.productCardFlagRecommended}
            title={__('Components.ProductCard.recommended_tooltip')}
          />
        )}
      </S.FeaturedFlagList>
    );
  }

  /**
   * Render add button.
   * If select mode check EAN code, If missing, print message
   */
  function renderAddButton() {
    if (selectMode === 'integration' && !originLot) {
      if (productMappingStrategy === 'sku') {
        if (!data.sku) {
          return (
            <RowContainer alignItems="center">
              <S.ErrorIcon name="Error" disableHover={true} />
              <S.TextGrey>{__('Components.ProductCard.sku_missing')}</S.TextGrey>
            </RowContainer>
          );
        }
      } else if (!data.eanCode) {
        return (
          <RowContainer alignItems="center">
            <S.ErrorIcon name="Error" disableHover={true} />
            <S.TextGrey>{__('Components.ProductCard.code_missing')}</S.TextGrey>
          </RowContainer>
        );
      }
    }

    return (
      <S.AddButton
        data-testid="add-button"
        disabled={data.status === AVAILABILITY.UNAVAILABLE}
        selected={isSelected}
        color={color}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          if (originLot && history) {
            history.push(
              getPath({
                path: ROUTE_PATHS.WORKSPACE_PRODUCTS,
                workspaceId: data.catalogId + '',
                productId: data.productId + '',
              }) + `?originLot=${originLot}`,
            );
          } else {
            if (data.isPor && amSeller && !selectMode) navigateToProductInfo();
            else
              onSelectItem(
                parsers.genericProductToOrderItem(
                  data,
                  price || data.price,
                  1,
                  data.baseMarginPercentage,
                  isServedFlow,
                  orderItemId,
                ),
              );
          }
        }}
      >
        {getButtonCTA()}
      </S.AddButton>
    );
  }

  /**
   * Get CTA literal
   */
  function getButtonCTA() {
    if (selectMode) return isSelected ? __('Components.ProductCard.selected') : __('Components.ProductCard.select');
    return fromMessage ? __('Components.ProductCard.check_price') : __('Components.ProductCard.add');
  }

  /**
   * Get price formated or 'P.O.R.' text.
   */
  function priceToShow(precision: number) {
    if (priceMode === 'none') return '-';
    if (price || data.price) {
      return currency.getPrice(data.currency, price || data.price, precision);
    }
    return 'P.O.R';
  }

  /**
   * Handler to on click in the CTA.
   */
  function navigateToProductInfo() {
    navigate(
      parsers.genericProductToOrderItem(
        data,
        data.price,
        0,
        data.baseMarginPercentage,
        isServedFlow,
        orderItemId,
        amount,
      ),
      true,
    );
  }
};

interface ProductAmountAddProps {
  amount?: number;
  cartUpdateItem: (item: IOrderItem) => void;
  data: GenericProduct;
  isServedFlow: boolean;
  orderItemId: number;
  price?: number;
  saleUnit: PRODUCT_UNIT;
  servedQuantity: number;
}

/**
 * Component to add or remove products from cart with a sale unit selector
 */
export const ProductAmountAdd = ({
  amount,
  cartUpdateItem,
  data,
  isServedFlow,
  orderItemId,
  price,
  saleUnit,
  servedQuantity,
}: ProductAmountAddProps) => {
  return (
    <S.AmountRow onClick={e => e.stopPropagation()}>
      <S.Amount>{servedQuantity}</S.Amount>
      {data.saleUnits.length > 1 ? (
        renderSelectSaleUnit()
      ) : (
        <S.Amount>
          {parsers.getUnitText(
            config.TOGGLE_NEW_SALES_UNITS.organizations.includes(data.catalogId)
              ? priceUnitTranslate(saleUnit)
              : saleUnit,
            data.weightUnit,
            servedQuantity,
          )}
        </S.Amount>
      )}
      <S.IncDecButtons>
        <S.IncDecButton
          amount={servedQuantity}
          onClick={e => {
            e.stopPropagation();
            cartUpdateItem(
              parsers.genericProductToOrderItem(
                { ...data, saleUnit },
                price || data.price,
                --servedQuantity,
                data.baseMarginPercentage,
                isServedFlow,
                orderItemId,
                amount,
              ),
            );
          }}
        >
          {servedQuantity === 1 ? <S.TrashIcon name="Trash" disableHover={true} /> : <S.IncDecText>-</S.IncDecText>}
        </S.IncDecButton>
        <S.IncDecButton
          onClick={e => {
            e.stopPropagation();
            cartUpdateItem(
              parsers.genericProductToOrderItem(
                { ...data, saleUnit },
                price || data.price,
                ++servedQuantity,
                data.baseMarginPercentage,
                isServedFlow,
                orderItemId,
                amount,
              ),
            );
          }}
        >
          <S.IncDecText>+</S.IncDecText>
        </S.IncDecButton>
      </S.IncDecButtons>
    </S.AmountRow>
  );

  /**
   * Render selector of sale units
   */
  function renderSelectSaleUnit() {
    return (
      <S.Select
        id={'saleUnit_' + data.productId}
        name="saleUnit"
        value={saleUnit}
        onChange={e => {
          const item = parsers.genericProductToOrderItem(
            { ...data, saleUnit: e.currentTarget.value as PRODUCT_UNIT },
            price || data.price,
            servedQuantity,
            data.baseMarginPercentage,
            isServedFlow,
            orderItemId,
            amount,
          );
          cartUpdateItem(item);
        }}
      >
        {data.saleUnits.map(s => (
          <option key={s} value={s}>
            {parsers.getUnitText(
              config.TOGGLE_NEW_SALES_UNITS.organizations.includes(data.catalogId) ? priceUnitTranslate(s) : s,
              data.weightUnit,
              amount,
            )}
          </option>
        ))}
      </S.Select>
    );
  }
};

export default React.memo(ProductCard);

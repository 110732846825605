import { __, currency, orderUtils } from 'common-services';
import * as React from 'react';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'departure-price',
  isVisible: true,
  getTitle: () => __('Components.OffersList.table.departurePrice'),
  onClick: e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  },
  minWidth: '120px',
  width: '10%',
  condition: ({ isPrepare }) => !isPrepare,
  columnValue: ({ order, pricePrecision }) => ({
    getElement: item => {
      return (
        <S.ItemPriceTotal isCancelled={orderUtils.isCanceled(order)} showGrey={false}>
          {item.departurePrice === 0
            ? '-'
            : currency.getPricePerUnit(
                item.currency,
                item.priceUnit,
                item.weightUnit,
                item.departurePrice,
                pricePrecision,
              )}
        </S.ItemPriceTotal>
      );
    },
  }),
};

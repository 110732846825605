import styled from '../../../../../../styled-components';

export const SearchContainer = styled.div`
  display: flex;
  padding: ${props => props.theme.paddingSize(2)} 0;
  margin-bottom: ${props => props.theme.paddingSize(2)};
  border-bottom: 1px solid ${props => props.theme.colors.grey3};
`;

export const ListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: ${props => props.theme.paddingSize(2)};
`;

export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.paddingSize(4)};
  color: ${props => props.theme.colors.grey1};
  text-align: center;
`;

// Optional: Additional styled components for enhanced UI
export const ListItem = styled.div<{ isSelected?: boolean }>`
  padding: ${props => props.theme.paddingSize(2)};
  background-color: ${props => (props.isSelected ? props.theme.colors.blue2 : 'transparent')};
  border-radius: ${props => props.theme.borderRadius};
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${props => (props.isSelected ? props.theme.colors.blue2 : props.theme.colors.grey4)};
  }
`;

export const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

import styled from '../../../styled-components';
import { Button, ColumnContainer } from '../../atoms';
import ActionsModal from '../ActionsModal';

import type { ITheme } from '../../../styled-components';

interface IStyledProps {
  theme: ITheme;
}

export const Modal = styled(ActionsModal)`
  :first-child {
    max-width: 95%;
    width: 90vw;
    height: 95%;
  }
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const CTAWrapper = styled.div`
  position: absolute;
  bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  border-radius: 4px;
`;

export const CTA = styled(Button)``;

export const Container = styled(ColumnContainer)`
  flex: 1;
  width: 100%;
  overflow: hidden;
`;

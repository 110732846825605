import styled from '../../../../../../styled-components';
import { ColumnContainer, TextLabel } from '../../../../../atoms';

export const Container = styled(ColumnContainer)`
  width: 100%;
  gap: ${props => props.theme.paddingSize(2)};
`;

export const Text = styled(TextLabel)`
  font-size: ${props => props.theme.fontSize.normal};
  color: ${props => props.theme.colors.black};
  font-weight: ${props => props.theme.fontWeight.Regular};
  line-height: 1.4;
  width: 100%;

  /* Handle long text gracefully */
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
`;

export const TextBold = styled(Text)`
  font-weight: ${props => props.theme.fontWeight.Bold};
  display: inline;
`;

// For price-specific styling
export const PriceText = styled(Text)`
  color: ${props => props.theme.colors.blue1};
`;

export const ErrorPrice = styled(Text)`
  color: ${props => props.theme.colors.red1};
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.paddingSize(2)};
  padding: ${props => props.theme.paddingSize(1)} 0;
`;

export const PriceLabel = styled.span`
  font-size: ${props => props.theme.fontSize.small};
  color: ${props => props.theme.colors.grey1};
`;

export const PriceValue = styled.span`
  font-weight: ${props => props.theme.fontWeight.Bold};
  color: ${props => props.theme.colors.black};
`;

// For the LostLines component specific styling
export const LostLinesContainer = styled(ColumnContainer)`
  padding: ${props => props.theme.paddingSize(2)};
  background-color: ${props => props.theme.colors.blue2};
  border-radius: ${props => props.theme.borderRadius};
  margin-top: ${props => props.theme.paddingSize(1)};
`;

export const LostLinesTitle = styled(Text)`
  font-weight: ${props => props.theme.fontWeight.Bold};
  margin-bottom: ${props => props.theme.paddingSize(1)};
`;

export const LostLinesRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.theme.paddingSize(1)} 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.blue1};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: ${props => props.theme.paddingSize(2)};
`;

// For responsive design
export const ResponsiveContainer = styled(Container)`
  @media (max-width: ${props => props.theme.sizes.ipad}) {
    padding: ${props => props.theme.paddingSize(2)};
  }
`;

// For accessibility
export const ScreenReaderOnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

// For animations
export const AnimatedPrice = styled(PriceText)`
  transition: color 0.2s ease;

  &:hover {
    color: ${props => props.theme.colors.blue2};
  }
`;

// For error states
export const ErrorContainer = styled(Container)`
  border-left: 3px solid ${props => props.theme.colors.red1};
  padding-left: ${props => props.theme.paddingSize(2)};
`;

// For success states
export const SuccessContainer = styled(Container)`
  border-left: 3px solid ${props => props.theme.colors.green1};
  padding-left: ${props => props.theme.paddingSize(2)};
`;

import { ROUTE_PATHS } from '../constants';

interface IRouteWithoutParams {
  path: Exclude<
    ROUTE_PATHS,
    | ROUTE_PATHS.BROADCAST
    | ROUTE_PATHS.CHAT
    | ROUTE_PATHS.CONTACT_MAGIC_ORDERS
    | ROUTE_PATHS.CONTACT_MAGIC_ORDERS_NEW
    | ROUTE_PATHS.CONTACT_PURCHASES
    | ROUTE_PATHS.CONTACT_SALES
    | ROUTE_PATHS.ORDER_LIST
    | ROUTE_PATHS.ORDER_UNREAD
    | ROUTE_PATHS.PRICELIST
    | ROUTE_PATHS.PRODUCT
    | ROUTE_PATHS.PRODUCTS
    | ROUTE_PATHS.PURCHASES
    | ROUTE_PATHS.PURCHASES_AGGREGATE
    | ROUTE_PATHS.PURCHASES_AGGREGATE_WORKSPACE
    | ROUTE_PATHS.PURCHASES_UNREAD
    | ROUTE_PATHS.PURCHASES_WORKSPACE
    | ROUTE_PATHS.SALES
    | ROUTE_PATHS.SALES_AGGREGATE
    | ROUTE_PATHS.SALES_AGGREGATE_WORKSPACE
    | ROUTE_PATHS.SALES_UNREAD
    | ROUTE_PATHS.SALES_WORKSPACE
    | ROUTE_PATHS.SETTINGS
    | ROUTE_PATHS.WORKSPACE_CARTS
    | ROUTE_PATHS.WORKSPACE_CARTS_DETAIL
    | ROUTE_PATHS.WORKSPACE_CLIENT_EDIT
    | ROUTE_PATHS.WORKSPACE_CLIENT_EXISTING_REFERENCE_DETAILS
    | ROUTE_PATHS.WORKSPACE_CLIENT_MAGIC_ORDERS
    | ROUTE_PATHS.WORKSPACE_CLIENT_NEW_REFERENCE_DETAILS
    | ROUTE_PATHS.WORKSPACE_CLIENT_REFERENCES
    | ROUTE_PATHS.WORKSPACE_CLIENT_SALES
    | ROUTE_PATHS.WORKSPACE_CLIENT_SELL
    | ROUTE_PATHS.WORKSPACE_CLIENT_SELL_ITEMS
    | ROUTE_PATHS.WORKSPACE_CLIENTS
    | ROUTE_PATHS.WORKSPACE_COLLEAGUES_BUYER
    | ROUTE_PATHS.WORKSPACE_COLLEAGUES_SELLER
    | ROUTE_PATHS.WORKSPACE_EXISTING_REFERENCE_DETAILS
    | ROUTE_PATHS.WORKSPACE_MAGIC_ORDERS
    | ROUTE_PATHS.WORKSPACE_MAGIC_ORDERS_NEW
    | ROUTE_PATHS.WORKSPACE_NEW_REFERENCE_DETAILS
    | ROUTE_PATHS.WORKSPACE_PDF_FORMATS
    | ROUTE_PATHS.WORKSPACE_PREPARE_ORDER_DETAILS
    | ROUTE_PATHS.WORKSPACE_PREPARE_ORDERS
    | ROUTE_PATHS.WORKSPACE_PRICE_GROUP_EDIT
    | ROUTE_PATHS.WORKSPACE_PRICE_GROUPS
    | ROUTE_PATHS.WORKSPACE_PRODUCTS
    | ROUTE_PATHS.WORKSPACE_PRODUCTS_BUYER
    | ROUTE_PATHS.WORKSPACE_PRODUCTS_BUYER_PRODUCT
    | ROUTE_PATHS.WORKSPACE_OFFERS
    | ROUTE_PATHS.WORKSPACE_REFERENCE_LIST
    | ROUTE_PATHS.WORKSPACE_REFERENCE_LIST_SELECT
    | ROUTE_PATHS.WORKSPACE_OFFER_EDIT
    | ROUTE_PATHS.WORKSPACE_REFERENTIAL
    | ROUTE_PATHS.WORKSPACE_OFFER_TO_ORDER_PREPARATION
    | ROUTE_PATHS.WORKSPACE_REFERENCE_SCREEN
    | ROUTE_PATHS.WORKSPACE_PURCHASES
    | ROUTE_PATHS.WORKSPACE_PURCHASES_AGGREGATE
    | ROUTE_PATHS.WORKSPACE_PURCHASES_BUY
    | ROUTE_PATHS.WORKSPACE_PURCHASES_SUPPLIER
    | ROUTE_PATHS.WORKSPACE_REFERENCES
    | ROUTE_PATHS.WORKSPACE_SALES
    | ROUTE_PATHS.WORKSPACE_SALES_AGGREGATE
    | ROUTE_PATHS.WORKSPACE_SALES_CLIENT
    | ROUTE_PATHS.WORKSPACE_SALES_SELL
    | ROUTE_PATHS.WORKSPACE_SALES_SELL_ITEMS
    | ROUTE_PATHS.WORKSPACE_SECTIONS
    | ROUTE_PATHS.WORKSPACE_SETTINGS_BUYER
    | ROUTE_PATHS.WORKSPACE_SETTINGS_SELLER
    | ROUTE_PATHS.WORKSPACE_SUPPLIER_CONTRACT_EDIT
    | ROUTE_PATHS.WORKSPACE_SUPPLIER_EDIT
    | ROUTE_PATHS.WORKSPACE_SUPPLIER_PURCHASES
    | ROUTE_PATHS.WORKSPACE_SUPPLIERS
    | ROUTE_PATHS.WORKSPACE_UNREAD_PURCHASES
    | ROUTE_PATHS.WORKSPACE_UNREAD_SALES
  >;
}
interface IRouteClientReferenceList {
  path:
    | ROUTE_PATHS.WORKSPACE_CLIENT_REFERENCES
    | ROUTE_PATHS.WORKSPACE_CLIENT_NEW_REFERENCE_DETAILS
    | ROUTE_PATHS.WORKSPACE_CLIENT_EXISTING_REFERENCE_DETAILS;
  clientId: string;
  workspaceId: string;
  codeId?: string;
  referenceType?: string;
}
interface IRouteMOReferenceList {
  path: ROUTE_PATHS.WORKSPACE_EXISTING_REFERENCE_DETAILS | ROUTE_PATHS.WORKSPACE_NEW_REFERENCE_DETAILS;
  workspaceId: string;
  codeId: string;
  referenceType?: string;
}
interface IRouteReferenceList {
  path: ROUTE_PATHS.WORKSPACE_REFERENCES;
  workspaceId: string;
}

interface IRouteClientMagicOrders {
  path: ROUTE_PATHS.WORKSPACE_CLIENT_MAGIC_ORDERS;
  clientId: string;
  workspaceId: string;
  jobId?: string;
  hashId?: string;
}
interface IRouteContactMagicOrders {
  path: ROUTE_PATHS.CONTACT_MAGIC_ORDERS;
  contactId: string;
  workspaceId: string;
  jobId?: string;
  hashId?: string;
}
interface IRouteWorkspaceMagicOrders {
  path: ROUTE_PATHS.WORKSPACE_MAGIC_ORDERS;
  workspaceId: string;
  jobId?: string;
  hashId?: string;
}

interface IRouteProduct {
  path: ROUTE_PATHS.PRODUCT;
  productId: string;
}

interface IRouteOld {
  path:
    | ROUTE_PATHS.PURCHASES
    | ROUTE_PATHS.SALES
    | ROUTE_PATHS.PURCHASES_UNREAD
    | ROUTE_PATHS.SALES_UNREAD
    | ROUTE_PATHS.PRODUCTS;
  hashId?: string;
  productId?: string;
}

interface IRouteOrdersWorkspace {
  path: ROUTE_PATHS.SALES_WORKSPACE | ROUTE_PATHS.PURCHASES_WORKSPACE;
  catalogHash: string;
  hashId?: string;
}

interface IRouteWorkspaceClient {
  path:
    | ROUTE_PATHS.WORKSPACE_CLIENT_EDIT
    | ROUTE_PATHS.WORKSPACE_MAGIC_ORDERS_NEW
    | ROUTE_PATHS.WORKSPACE_CLIENT_SELL
    | ROUTE_PATHS.WORKSPACE_SALES_SELL
    | ROUTE_PATHS.WORKSPACE_SALES_SELL_ITEMS
    | ROUTE_PATHS.WORKSPACE_CLIENT_SALES
    | ROUTE_PATHS.WORKSPACE_CLIENT_SELL_ITEMS
    | ROUTE_PATHS.WORKSPACE_SALES_CLIENT;
  workspaceId: string;
  clientId: string;
  productId?: string;
  hashId?: string;
}
interface IRouteWorkspaceOffers {
  path: ROUTE_PATHS.WORKSPACE_OFFERS;
  workspaceId: string;
}
interface IRouteWorkspaceReferential {
  path: ROUTE_PATHS.WORKSPACE_REFERENTIAL;
  workspaceId: string;
}
interface IRouteWorkspaceOfferToOrderPreparation {
  path: ROUTE_PATHS.WORKSPACE_OFFER_TO_ORDER_PREPARATION;
  workspaceId: string;
}
interface IRouteWorkspaceReferentialEdit {
  path: ROUTE_PATHS.WORKSPACE_REFERENCE_SCREEN;
  workspaceId: string;
  referentialId: string;
}

interface IRouteWorkspaceOfferEdit {
  path: ROUTE_PATHS.WORKSPACE_OFFER_EDIT;
  workspaceId: string;
  offerId: string;
}

interface IRouteWorkspaceReferenceList {
  path: ROUTE_PATHS.WORKSPACE_REFERENCE_LIST;
  workspaceId: string;
}

interface IRouteWorkspaceReferenceListSelect {
  path: ROUTE_PATHS.WORKSPACE_REFERENCE_LIST_SELECT;
  workspaceId: string;
  referenceId: string;
}

interface IRouteWorkspaceProductsBuyerProduct {
  path: ROUTE_PATHS.WORKSPACE_PRODUCTS_BUYER_PRODUCT;
  workspaceId: string;
  supplierId: string;
  productId: string;
}

interface IRouteWorkspace {
  path:
    | ROUTE_PATHS.WORKSPACE_SUPPLIERS
    | ROUTE_PATHS.WORKSPACE_COLLEAGUES_SELLER
    | ROUTE_PATHS.WORKSPACE_COLLEAGUES_BUYER
    | ROUTE_PATHS.WORKSPACE_PRODUCTS_BUYER
    | ROUTE_PATHS.WORKSPACE_CLIENTS
    | ROUTE_PATHS.WORKSPACE_PREPARE_ORDERS
    | ROUTE_PATHS.WORKSPACE_PRICE_GROUPS
    | ROUTE_PATHS.WORKSPACE_SECTIONS
    | ROUTE_PATHS.PRODUCTS_IMPORT
    | ROUTE_PATHS.COST_IMPORT
    | ROUTE_PATHS.WORKSPACE_SALES
    | ROUTE_PATHS.WORKSPACE_PURCHASES
    | ROUTE_PATHS.WORKSPACE_UNREAD_SALES
    | ROUTE_PATHS.WORKSPACE_UNREAD_PURCHASES
    | ROUTE_PATHS.WORKSPACE_PREPARE_ORDER_DETAILS
    | ROUTE_PATHS.WORKSPACE_PRICE_GROUP_EDIT
    | ROUTE_PATHS.WORKSPACE_PRODUCTS
    | ROUTE_PATHS.WORKSPACE_CARTS
    | ROUTE_PATHS.WORKSPACE_CARTS_DETAIL
    | ROUTE_PATHS.WORKSPACE_PDF_FORMATS;
  workspaceId: string;
  productId?: string;
  key?: string;
  hashId?: string;
  priceGroupId?: string;
  formatId?: string;
}
interface IRouteWorkspaceContactOrders {
  path:
    | ROUTE_PATHS.CONTACT_SALES
    | ROUTE_PATHS.CONTACT_PURCHASES
    | ROUTE_PATHS.CONTACT_MAGIC_ORDERS
    | ROUTE_PATHS.CONTACT_MAGIC_ORDERS_NEW;
  workspaceId: string;
  contactId: string;
  hashId?: string;
}

interface IRouteWorkspaceSupplierOrders {
  path: ROUTE_PATHS.WORKSPACE_SUPPLIER_PURCHASES | ROUTE_PATHS.WORKSPACE_PURCHASES_SUPPLIER;
  workspaceId: string;
  supplierId: string;
  hashId?: string;
}

interface IRouteWorkspaceSupplier {
  path: ROUTE_PATHS.WORKSPACE_SUPPLIER_EDIT | ROUTE_PATHS.WORKSPACE_SUPPLIER_BUY | ROUTE_PATHS.WORKSPACE_PURCHASES_BUY;
  workspaceId: string;
  supplierId: string;
  productId?: string;
}

interface IRouteWorkspaceSupplierContractEdit {
  path: ROUTE_PATHS.WORKSPACE_SUPPLIER_CONTRACT_EDIT;
  workspaceId: string;
  supplierId: string;
  contractId: string;
}

interface IRouteWorkspaceSettings {
  path: ROUTE_PATHS.WORKSPACE_SETTINGS_SELLER | ROUTE_PATHS.WORKSPACE_SETTINGS_BUYER;
  workspaceId: string;
  tab: 'team' | 'pdf' | 'order' | 'shop_online' | 'currency' | 'measures' | 'prices' | 'addresses' | 'shipping';
}

interface IRoutePricelist {
  path: ROUTE_PATHS.PRICELIST;
  customUrl: string;
}

interface IRouteChat {
  path: ROUTE_PATHS.CHAT | ROUTE_PATHS.GROUP | ROUTE_PATHS.PUBLIC_CHANNEL | ROUTE_PATHS.PUBLIC_CHANNEL_POST;
  channelId: string;
  postId?: string;
}

interface IRouteChatInfo {
  path:
    | ROUTE_PATHS.GROUP_INFO
    | ROUTE_PATHS.PUBLIC_CHANNEL_INFO
    | ROUTE_PATHS.CONTACT_INFO
    | ROUTE_PATHS.BROADCAST
    | ROUTE_PATHS.BROADCAST_INFO
    | ROUTE_PATHS.CONTACT
    | ROUTE_PATHS.CONTACT_BUY
    | ROUTE_PATHS.CONTACT_SELL
    | ROUTE_PATHS.CONTACT_CART_SELL
    | ROUTE_PATHS.CONTACT_CART_SELL_ITEMS
    | ROUTE_PATHS.CONTACT_CART_BUY
    | ROUTE_PATHS.CONTACT_CART_BUY_ITEMS
    | ROUTE_PATHS.CONTACT_ORDERS;
  channelId: string;
  hashId?: string;
  productHash?: string;
}

interface IRouteOrderUnread {
  path: ROUTE_PATHS.ORDER_UNREAD | ROUTE_PATHS.ORDER_LIST;
  hashId?: string;
}
interface IRouteOrdersAggregate {
  path: ROUTE_PATHS.SALES_AGGREGATE | ROUTE_PATHS.PURCHASES_AGGREGATE;
  orderSelectedIds: Array<number>;
}
interface IRouteOrdersAggregateWorkspace {
  path: ROUTE_PATHS.SALES_AGGREGATE_WORKSPACE | ROUTE_PATHS.PURCHASES_AGGREGATE_WORKSPACE;
  workspaceHash: string;
  orderSelectedIds: Array<number>;
}
interface IRouteWorkspaceOrdersAggregate {
  path: ROUTE_PATHS.WORKSPACE_SALES_AGGREGATE | ROUTE_PATHS.WORKSPACE_PURCHASES_AGGREGATE;
  workspaceId: string;
  orderSelectedIds: Array<number>;
}

interface IRouteSetting {
  path: ROUTE_PATHS.SETTINGS; // '/settings/:tab',
  tab: 'profile' | 'working-status' | 'config' | 'notifications' | 'addresses' | 'security';
}

type IParams =
  | IRouteChat
  | IRouteChatInfo
  | IRouteClientMagicOrders
  | IRouteClientReferenceList
  | IRouteContactMagicOrders
  | IRouteMOReferenceList
  | IRouteReferenceList
  | IRouteOrdersAggregate
  | IRouteOrdersAggregateWorkspace
  | IRouteOrdersWorkspace
  | IRouteOrderUnread
  | IRoutePricelist
  | IRouteProduct
  | IRouteOld
  | IRouteSetting
  | IRouteWithoutParams
  | IRouteWorkspace
  | IRouteWorkspaceClient
  | IRouteWorkspaceOfferEdit
  | IRouteWorkspaceReferenceList
  | IRouteWorkspaceReferenceListSelect
  | IRouteWorkspaceOffers
  | IRouteWorkspaceReferential
  | IRouteWorkspaceReferentialEdit
  | IRouteWorkspaceOfferToOrderPreparation
  | IRouteWorkspaceMagicOrders
  | IRouteWorkspaceContactOrders
  | IRouteWorkspaceSupplierOrders
  | IRouteWorkspaceOrdersAggregate
  | IRouteWorkspaceProductsBuyerProduct
  | IRouteWorkspaceSettings
  | IRouteWorkspaceSupplier
  | IRouteWorkspaceSupplierContractEdit;

export default function getPath(params: IParams): string {
  switch (params.path) {
    case ROUTE_PATHS.CHAT:
    case ROUTE_PATHS.BROADCAST:
    case ROUTE_PATHS.BROADCAST_INFO:
      return params.path.replace(':channelId?', (params as IRouteChat).channelId);
    case ROUTE_PATHS.WORKSPACE_CLIENT_REFERENCES:
    case ROUTE_PATHS.WORKSPACE_CLIENT_NEW_REFERENCE_DETAILS:
    case ROUTE_PATHS.WORKSPACE_CLIENT_EXISTING_REFERENCE_DETAILS:
    case ROUTE_PATHS.WORKSPACE_EXISTING_REFERENCE_DETAILS:
    case ROUTE_PATHS.WORKSPACE_NEW_REFERENCE_DETAILS:
      return params.path
        .replace(':clientId', (params as IRouteClientReferenceList).clientId)
        .replace(':workspaceId', params.workspaceId)
        .replace(':referenceType', params.referenceType)
        .replace(':codeId', params.codeId);
    case ROUTE_PATHS.WORKSPACE_CLIENT_MAGIC_ORDERS:
    case ROUTE_PATHS.WORKSPACE_MAGIC_ORDERS:
    case ROUTE_PATHS.CONTACT_MAGIC_ORDERS:
    case ROUTE_PATHS.CONTACT_MAGIC_ORDERS_NEW:
      return params.path
        .replace(':clientId', (params as IRouteClientMagicOrders).clientId || '')
        .replace(':contactId', (params as IRouteContactMagicOrders).contactId || '')
        .replace(':workspaceId', params.workspaceId)
        .replace(':hashId?', params.hashId || '')
        .replace(
          ':jobId?/',
          (params as IRouteContactMagicOrders).jobId ? `${(params as IRouteContactMagicOrders).jobId}/` : '',
        );
    case ROUTE_PATHS.WORKSPACE_CARTS:
    case ROUTE_PATHS.PRODUCTS_IMPORT:
    case ROUTE_PATHS.COST_IMPORT:
    case ROUTE_PATHS.WORKSPACE_PRODUCTS_BUYER:
    case ROUTE_PATHS.WORKSPACE_REFERENCES:
    case ROUTE_PATHS.WORKSPACE_CARTS_DETAIL:
    case ROUTE_PATHS.WORKSPACE_CLIENT_EDIT:
    case ROUTE_PATHS.WORKSPACE_MAGIC_ORDERS_NEW:
    case ROUTE_PATHS.WORKSPACE_CLIENT_SELL:
    case ROUTE_PATHS.WORKSPACE_SALES_SELL:
    case ROUTE_PATHS.WORKSPACE_SALES_SELL_ITEMS:
    case ROUTE_PATHS.WORKSPACE_PDF_FORMATS:
    case ROUTE_PATHS.WORKSPACE_PRODUCTS:
    case ROUTE_PATHS.WORKSPACE_SUPPLIER_BUY:
    case ROUTE_PATHS.WORKSPACE_SUPPLIER_EDIT:
    case ROUTE_PATHS.WORKSPACE_PURCHASES_BUY:
    case ROUTE_PATHS.WORKSPACE_SUPPLIER_CONTRACT_EDIT:
    case ROUTE_PATHS.WORKSPACE_PRODUCTS_BUYER_PRODUCT:
      return params.path
        .replace(':workspaceId', (params as IRouteWorkspace).workspaceId)
        .replace(':clientId', (params as IRouteWorkspaceClient).clientId)
        .replace(':supplierId', (params as IRouteWorkspaceSupplier).supplierId)
        .replace(':contractId', (params as IRouteWorkspaceSupplierContractEdit).contractId)
        .replace(':key', (params as IRouteWorkspace).key)
        .replace(':productId?', (params as IRouteWorkspace).productId || '')
        .replace(':formatId?', (params as IRouteWorkspace).formatId || '');
    case ROUTE_PATHS.WORKSPACE_OFFER_EDIT:
      return params.path
        .replace(':workspaceId', (params as IRouteWorkspaceOfferEdit).workspaceId)
        .replace(':offerId', (params as IRouteWorkspaceOfferEdit).offerId);
    case ROUTE_PATHS.WORKSPACE_REFERENCE_LIST:
      return params.path.replace(':workspaceId', (params as IRouteWorkspaceReferenceList).workspaceId);

    case ROUTE_PATHS.WORKSPACE_REFERENCE_LIST_SELECT:
      return params.path
        .replace(':workspaceId', (params as IRouteWorkspaceReferenceListSelect).workspaceId)
        .replace(':referenceId', (params as IRouteWorkspaceReferenceListSelect).referenceId);
    case ROUTE_PATHS.WORKSPACE_OFFERS:
      return params.path.replace(':workspaceId', (params as IRouteWorkspaceOffers).workspaceId);
    case ROUTE_PATHS.WORKSPACE_REFERENTIAL:
      return params.path.replace(':workspaceId', (params as IRouteWorkspaceReferential).workspaceId);
    case ROUTE_PATHS.WORKSPACE_OFFER_TO_ORDER_PREPARATION:
      return params.path.replace(':workspaceId', (params as IRouteWorkspaceOfferToOrderPreparation).workspaceId);
    case ROUTE_PATHS.WORKSPACE_REFERENCE_SCREEN:
      return params.path
        .replace(':workspaceId', (params as IRouteWorkspaceReferentialEdit).workspaceId)
        .replace(':referentialId', (params as IRouteWorkspaceReferentialEdit).referentialId);
    case ROUTE_PATHS.WORKSPACE_SETTINGS_SELLER:
    case ROUTE_PATHS.WORKSPACE_SETTINGS_BUYER:
      return params.path
        .replace(':workspaceId', params.workspaceId)
        .replace(':tab', (params as IRouteWorkspaceSettings).tab || 'team');
    case ROUTE_PATHS.WORKSPACE_SECTIONS:
    case ROUTE_PATHS.WORKSPACE_PRICE_GROUPS:
    case ROUTE_PATHS.WORKSPACE_CLIENTS:
    case ROUTE_PATHS.WORKSPACE_PREPARE_ORDERS:
    case ROUTE_PATHS.WORKSPACE_PREPARE_ORDER_DETAILS:
    case ROUTE_PATHS.WORKSPACE_SUPPLIERS:
    case ROUTE_PATHS.WORKSPACE_COLLEAGUES_SELLER:
    case ROUTE_PATHS.WORKSPACE_COLLEAGUES_BUYER:
    case ROUTE_PATHS.WORKSPACE_PURCHASES:
    case ROUTE_PATHS.WORKSPACE_SALES:
    case ROUTE_PATHS.WORKSPACE_UNREAD_PURCHASES:
    case ROUTE_PATHS.WORKSPACE_UNREAD_SALES:
    case ROUTE_PATHS.WORKSPACE_PRICE_GROUP_EDIT:
      return params.path
        .replace(':workspaceId', params.workspaceId)
        .replace(':hashId?', params.hashId || '')
        .replace(':priceGroupId?', params.priceGroupId || '');
    case ROUTE_PATHS.CONTACT_SALES:
    case ROUTE_PATHS.CONTACT_PURCHASES:
    case ROUTE_PATHS.WORKSPACE_CLIENT_SALES:
    case ROUTE_PATHS.WORKSPACE_SALES_CLIENT:
    case ROUTE_PATHS.WORKSPACE_CLIENT_SELL_ITEMS:
    case ROUTE_PATHS.WORKSPACE_SUPPLIER_PURCHASES:
    case ROUTE_PATHS.WORKSPACE_PURCHASES_SUPPLIER:
      return params.path
        .replace(':workspaceId', params.workspaceId)
        .replace(':contactId', (params as IRouteWorkspaceContactOrders).contactId || '')
        .replace(':clientId', (params as IRouteWorkspaceClient).clientId || '')
        .replace(':supplierId', (params as IRouteWorkspaceSupplierOrders).supplierId || '')
        .replace(':hashId', (params as IRouteWorkspaceClient).hashId || '');
    case ROUTE_PATHS.PRODUCT:
      return params.path.replace(':productId', params.productId);
    case ROUTE_PATHS.PRODUCTS:
      return params.path.replace(':productId?', params.productId || '');
    case ROUTE_PATHS.PURCHASES:
    case ROUTE_PATHS.PURCHASES_UNREAD:
    case ROUTE_PATHS.ORDER_LIST:
    case ROUTE_PATHS.ORDER_UNREAD:
    case ROUTE_PATHS.SALES:
    case ROUTE_PATHS.SALES_UNREAD:
      return params.path.replace(':hashId?', params.hashId || '');

    case ROUTE_PATHS.SALES_WORKSPACE:
    case ROUTE_PATHS.PURCHASES_WORKSPACE:
      return params.path.replace(':catalogHash', params.catalogHash || '').replace(':hashId?', params.hashId || '');

    case ROUTE_PATHS.PRICELIST:
      return params.path.replace(':customUrl', params.customUrl);

    case ROUTE_PATHS.SALES_AGGREGATE:
    case ROUTE_PATHS.PURCHASES_AGGREGATE:
      return params.path.replace(
        ':orderSelectedIds',
        params.orderSelectedIds.length ? params.orderSelectedIds.join(',') : 'all',
      );
    case ROUTE_PATHS.SALES_AGGREGATE_WORKSPACE:
    case ROUTE_PATHS.PURCHASES_AGGREGATE_WORKSPACE:
      return params.path
        .replace(':workspaceHash', params.workspaceHash || '')
        .replace(':orderSelectedIds', params.orderSelectedIds.length ? params.orderSelectedIds.join(',') : 'all');
    case ROUTE_PATHS.WORKSPACE_SALES_AGGREGATE:
    case ROUTE_PATHS.WORKSPACE_PURCHASES_AGGREGATE:
      return params.path
        .replace(':workspaceId', params.workspaceId || '')
        .replace(':orderSelectedIds', params.orderSelectedIds.length ? params.orderSelectedIds.join(',') : 'all');
    case ROUTE_PATHS.CONTACT:
    case ROUTE_PATHS.CONTACT_BUY:
    case ROUTE_PATHS.CONTACT_CART_BUY:
    case ROUTE_PATHS.CONTACT_CART_BUY_ITEMS:
    case ROUTE_PATHS.CONTACT_CART_SELL:
    case ROUTE_PATHS.CONTACT_CART_SELL_ITEMS:
    case ROUTE_PATHS.CONTACT_INFO:
    case ROUTE_PATHS.CONTACT_ORDERS:
    case ROUTE_PATHS.CONTACT_SELL:
    case ROUTE_PATHS.GROUP:
    case ROUTE_PATHS.GROUP_INFO:
    case ROUTE_PATHS.PUBLIC_CHANNEL:
    case ROUTE_PATHS.PUBLIC_CHANNEL_POST:
    case ROUTE_PATHS.PUBLIC_CHANNEL_INFO:
      return params.path
        .replace(':channelId', (params as IRouteChat).channelId)
        .replace(':productHash?', (params as any).productHash || '')
        .replace(':postId?', (params as any).postId || '')
        .replace(':hashId?', (params as any).hashId || '')
        .replace(':jobId?/', (params as any).jobId ? `${(params as any).jobId}/` : '');
    case ROUTE_PATHS.SETTINGS:
      return params.path.replace(':tab', params.tab);

    default:
      return params.path;
  }
}

import styled from '../../../../../../styled-components';
import { Button } from '../../../../../atoms';

export const InputTitle = styled.div`
  margin-top: ${props => props.theme.paddingSize(2)};
  font-size: ${props => props.theme.fontSize.normal};
  color: ${props => props.theme.colors.black};
  font-weight: ${props => props.theme.fontWeight.Semi};
  margin-bottom: ${props => props.theme.paddingSize(2)};
`;

export const ModalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${props => props.theme.paddingSize(4)};
  gap: ${props => props.theme.paddingSize(2)};
`;

export const ModalButton = styled(Button)`
  width: 45%;
  justify-content: center;
`;

export const CtaButton = styled(ModalButton)`
  background-color: ${props => (props.disabled ? props.theme.colors.green2 : props.theme.colors.green1)};
`;

export const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.red1};
  font-size: ${props => props.theme.fontSize.small};
  margin-top: ${props => props.theme.paddingSize(1)};
`;

export const InputWrapper = styled.div`
  margin-bottom: ${props => props.theme.paddingSize(3)};
`;

export const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

import { ORDER_ORIGIN, ORDER_STATUS } from 'common-services';
import * as React from 'react';

import type { IOrder, IOrderIssue, EXPORT_ORDER_ERROR, IWorkspace } from 'common-services';

interface RibbonStates {
  hasPendingIssues: boolean;
  showExportErrorRibbon: EXPORT_ORDER_ERROR | undefined;
  showSendToERPRibbon: boolean;
  exportErrorSelected: EXPORT_ORDER_ERROR | undefined;
}

// Memoized computed values hooks
const useRibbonStates = (
  order: IOrder,
  issues: Array<IOrderIssue>,
  catalog?: IWorkspace,
  weAreSeller?: boolean,
): RibbonStates => {
  return React.useMemo(
    () => ({
      hasPendingIssues: issues?.some(i => i.status === 'pending' || i.status === 'unknown'),

      showExportErrorRibbon: weAreSeller ? order?.exportErrorSeller : order?.exportErrorBuyer,

      showSendToERPRibbon: !!(
        catalog?.hasIntegrationExport &&
        catalog?.manuallyPushOrdersToERP &&
        weAreSeller &&
        [ORDER_STATUS.ACCEPTED].includes(order?.status) &&
        [ORDER_ORIGIN.INTERNAL, ORDER_ORIGIN.IMPORT_UI, ORDER_ORIGIN.EMAIL].includes(order?.origin) &&
        !order?.sentToERP &&
        !order?.issues?.length
      ),

      exportErrorSelected: weAreSeller ? order?.exportErrorSeller : order?.exportErrorBuyer,
    }),
    [order, issues, catalog, weAreSeller],
  );
};

export default useRibbonStates;

import { ChevronDown, ChevronRight } from 'lucide-react';
import * as React from 'react';

import Modal from '../Modal';

import * as S from './DebugModal.styled';

/**
 * Props for the Debug Modal component
 */
interface DebugModalProps {
  /** Title for the modal */
  title?: string;
  /** The data to display in the modal */
  data: unknown;
  /** Text to show in the trigger link */
  triggerText?: string;
  /** Whether to show data in collapsible sections */
  withSections?: boolean;
}

interface SectionProps {
  title: string;
  content: unknown;
}

const Section: React.FC<SectionProps> = ({ title, content }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div>
      <S.SectionHeader onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
        <S.SectionTitle>{title}</S.SectionTitle>
      </S.SectionHeader>
      <S.SectionContent isOpen={isOpen}>
        <S.Pre>{JSON.stringify(content, null, 2)}</S.Pre>
      </S.SectionContent>
    </div>
  );
};

/**
 * DebugModal component that uses the custom modalOpen function
 * to display formatted JSON data in a modal
 */
export const DebugModal: React.FC<DebugModalProps> = ({
  data,
  title = '',
  triggerText = 'Debug',
  withSections = false,
}) => {
  const [showModal, setShowModal] = React.useState(false);

  const handleClick = (): void => {
    setShowModal(true);
  };

  const renderContent = (): JSX.Element => {
    if (!withSections) {
      return <S.Pre>{JSON.stringify(data, null, 2)}</S.Pre>;
    }

    return (
      <S.SectionsContainer>
        {Object.entries(data).map(([key, value]) => (
          <Section key={key} title={key} content={value} />
        ))}
      </S.SectionsContainer>
    );
  };

  return (
    <>
      <S.TextLink onClick={handleClick} role="button" tabIndex={0}>
        {triggerText}
      </S.TextLink>
      {showModal && (
        <Modal closeOnClickOutside={true} onCloseClick={() => setShowModal(false)} minWidth="800px">
          <S.Container>
            {title && <S.Title>{title}</S.Title>}
            <S.Content>{renderContent()}</S.Content>
          </S.Container>
        </Modal>
      )}
    </>
  );
};

export default DebugModal;

import { currency, orderService } from 'common-services';

import config from '../../../../../../../../../bindings/config';

import type { IOrder, IOrderItem } from 'common-services';

interface UsePriceCalculationReturn {
  totalPrice: string | number;
  shippingCost: number;
  price: number;
}

export const usePriceCalculation = (
  order: IOrder,
  items: Array<IOrderItem>,
  pricePrecision: number,
): UsePriceCalculationReturn => {
  const shipping = (order?.customItems || []).find(ci => ci.type === 'shipping');
  const shippingCost = (shipping && shipping.price) || 0;
  const itemWithOutPrice = items.find(i => !i.totalPrice);
  // TODO:1 confirm we want to filter
  const filteredItems = items.filter(i => {
    const hasAnyIssue = order?.issues.some(issue => issue.orderItemId === i.id);
    return !hasAnyIssue;
  });
  const price = orderService.getTotalPrice({
    ...order,
    items: filteredItems,
    price: itemWithOutPrice ? 0 : order?.price,
  });

  const totalPrice = config.TOGGLE_ORDER_PRICE_UNITS.enabled
    ? currency.getPrice(order?.currency, price, pricePrecision)
    : price
    ? currency.getPrice(order?.currency, price, pricePrecision)
    : '-';

  return {
    totalPrice,
    shippingCost,
    price,
  };
};

import type {
  modalActions,
  orderActions,
  IOrderItem,
  IOrderIssue,
  IOrder,
  IWorkspace,
  IProduct,
  IProdType,
  IUser,
  ICountry,
  IPriceMode,
  IAddress,
} from 'common-services';

export interface ColumnOptionsProps {
  me: IUser;
  order: IOrder;
  catalog?: IWorkspace;
  productList: Array<IProduct>;
  prodTypes: { [key: string]: IProdType };
  addresses?: Array<IAddress>;
  countries?: { [key: string]: ICountry };

  pricePrecision: number;
  priceMode: IPriceMode;

  togglePriceMismatchEnable: boolean;
  isItemDeletable: boolean;
  isDeletable: boolean;
  showServedColumn: boolean;
  amEditor: boolean;
  weAreSeller: boolean;
  editAfterAccept: boolean;
  amSeller: boolean;
  showSelectWorkspaceModal: boolean;
  isPendingOrder: boolean;
  canServeOrder: boolean;
  servedFlowEnabled: boolean;
  priceEdit: boolean;

  isBuyerOfferingView: boolean; // TODO: this is a temporary fix for isBuyerOfferingView

  updateItemPrice: (item: IOrderItem, key?: string, value?: number | string, error?: string) => void;
  setPriceEdit: (value: boolean) => void;
  priceToShow: (item: IOrderItem) => string;
  onUpdateItem: (item: IOrderItem, issue?: IOrderIssue) => void;
  discardLine: (e, item: IOrderItem) => void;
  showProductsModal: (item: IOrderItem, e?: React.MouseEvent, originLot?: number, mappingCode?: string) => void;
  onEnableItem: (item: IOrderItem, issue: IOrderIssue) => void;
  openIssuesModal: (orderItem: IOrderItem, issue?: IOrderIssue) => void;
  openBppConfirmationModal: (orderItem: IOrderItem, newBppValue?: number) => void;
  openBoxesPerPalletModal?: (orderItem: IOrderItem) => void;
  openPriceModal: (item: IOrderItem) => void;
  handleLotOptions: (op: string, item: IOrderItem) => void;

  renderSelectWorkspaceModal: (item: IOrderItem) => JSX.Element;
  renderTooltip: () => JSX.Element;
  renderArrowPercentage: (priceDiff: number, from: 'input' | 'price', item: IOrderItem) => JSX.Element;

  orderUpdatePrepared: typeof orderActions.orderUpdatePrepared;
  orderItemRecover: typeof orderActions.orderItemRecover;
  orderItemStopDiscarding: typeof orderActions.orderItemStopDiscarding;
  modalOpen: typeof modalActions.modalOpen;
  touchImage: typeof modalActions.touchImage;
}

export interface ColumnTitleProps {
  isQuoterMode: boolean;
}

export interface ColumnConditionProps {
  order: IOrder;
  isItemDeletable: boolean;
  isPrepared: boolean;
  isPrepare: boolean;
  weAreSeller: boolean;
  previewMode: boolean;
  showServedColumn: boolean;
  isQuoterMode: boolean;
  editAfterAccept: boolean;
}

export const noTitle = (): string => '';

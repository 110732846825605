import * as React from 'react';

import { downloadBinaryFile } from '../../../../services/file';

import type { orderActions, IUser, IOrder } from 'common-services';

interface UseDownloadProps {
  me: IUser;
  order?: IOrder;
  orderOriginalFileDownload: typeof orderActions.orderOriginalFileDownload;
}

export interface UseDownloadReturn {
  downloadFile: () => void;
  isLoading: boolean;
  error: Error | null;
}

const useDownload = ({ me, order, orderOriginalFileDownload }: UseDownloadProps): UseDownloadReturn => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);

  const downloadFile = React.useCallback(() => {
    setIsLoading(true);
    setError(null);

    try {
      orderOriginalFileDownload(me.id, order?.id, (data: string, contentType: string, filename: string) => {
        const fileName = filename || generateFileName(order);
        downloadBinaryFile(data, contentType, fileName);
        setIsLoading(false);
      });
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Download failed'));
      setIsLoading(false);
    }
  }, [me.id, order, orderOriginalFileDownload]);

  // Helper function to generate filename
  const generateFileName = (order: UseDownloadProps['order']): string => {
    const identifier = order?.externalIdSeller || order?.externalIdBuyer || order?.hashId;
    const timestamp = new Date().getTime();
    return `order-${identifier}-${timestamp}`;
  };

  return {
    downloadFile,
    isLoading,
    error,
  };
};

export default useDownload;

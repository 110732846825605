import { __, orderUtils } from 'common-services';
import * as React from 'react';

import { ColumnContainer, Tooltip } from '../../../../../../../atoms';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'contracts',
  isVisible: true,
  getTitle: () => __('WorkspaceSupplierEdit.Menu.contracts.ref'),
  minWidth: '70px',
  width: '9%',
  columnValue: ({ order }) => ({
    getElement: item => {
      const displayedRefs = item.contracts?.length > 2 ? 1 : 2;
      const issuesRelated = order?.issues.filter(issue => issue.orderItemId === item.id);
      const hasNotFoundIssue = !!issuesRelated?.find(i =>
        ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
      );
      const isDiscarded = item.servedQuantity === 0;
      return (
        <ColumnContainer>
          {item.contracts?.slice(0, displayedRefs).map(c => (
            <S.Reference key={c.id} showGrey={hasNotFoundIssue || isDiscarded || orderUtils.isCanceled(order)}>
              {c.reference}
            </S.Reference>
          ))}
          {item.contracts?.length > 2 ? (
            <Tooltip
              component={
                <ColumnContainer>
                  {item.contracts?.slice(1).map(c => (
                    <S.Reference key={c.id} showGrey={hasNotFoundIssue || isDiscarded || orderUtils.isCanceled(order)}>
                      {'· ' + c.reference}
                    </S.Reference>
                  ))}
                </ColumnContainer>
              }
              themeMode="dark"
            >
              <S.BlueText>
                {__('WorkspaceSupplierEdit.Menu.contracts.see_more', {
                  count: item.contracts?.length - 1,
                })}
              </S.BlueText>
            </Tooltip>
          ) : null}
        </ColumnContainer>
      );
    },
  }),
};
